import { CheckCircle, KeyboardArrowDown, RadioButtonUnchecked } from '@mui/icons-material';
import { Box, Checkbox } from '@mui/material';
import { calculationsApi, useChangeLevelNameMutation } from 'api/calculations';
import Tooltip from 'components/Tooltip';
import { useCalcId } from 'hooks/useCalcId';
import { useDebounce } from 'hooks/useDebounce';
import { ChangeEvent, FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch } from 'store/store';
import { useTreeNodeContext } from '../../context';
import { BodyStyledRow, BodyTdStyled, InputStyled, StyledCheckbox, StyledRow, StyledRowMenu } from './Row.styles';
import { RowProps } from './Row.types';
import { CreateLevelButton, GroupRow } from './components';

export const Row: FC<RowProps> = memo(
  ({ index, parentGroupIds, parentChildrenLength, row, selectedIds, checkboxShowed, onSetSelectedIds }) => {
    const { rowType, level, isExpanded, maxDeep, onToggleFormVisible, onToggleExpanded } = useTreeNodeContext();
    const calcID = useCalcId();
    const dirtyRef = useRef<boolean>(false);
    const [value, setValue] = useState<string>(row.title || '');
    const debouncedValue = useDebounce(value, 500);
    const [hover, setHover] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState(false);
    const [changeLevelName, { isSuccess }] = useChangeLevelNameMutation();
    const dispatch = useAppDispatch();

    const isRowMenuShowed = rowType === 'group';
    const isCreateLevelButtonShowed = hover && rowType !== 'position' && !checkboxShowed;
    const isCheckboxShowed = checkboxShowed && !(level === 1 && rowType === 'group');

    useEffect(() => {
      if (dirtyRef.current) {
        console.log('value>>>', value);
        changeLevelName({
          params: {
            calcID,
            groupID: row.id.toString(),
          },
          body: {
            title: value || '',
            type: 'folded',
          },
        }).then(() => {});
      }
    }, [debouncedValue]);

    useEffect(() => {
      if (isSuccess) {
        // dispatch(calculationsApi.util.updateQueryData('getGroupPosition', { calcID }, (draft) => {
        //   if (draft && draft.data) {
        //     const target = draft.data.findIndex((f) => f.id === row.id);
        //     if (target !== undefined) {
        //       if (draft.data[target]) {
        //         (draft.data[target] as any).title = value || '';
        //       }
        //     }
        //   }
        // }));
        dispatch(
          calculationsApi.util.updateQueryData('getVorPositions', { calcId: calcID }, (draft) => {
            if (draft && draft.formed) {
              const target = draft.formed.findIndex((f) => f.id === row.id);
              if (target !== undefined && target >= 0) {
                if (draft.formed[target]) {
                  (draft.formed[target] as any).title = value || '';
                }
              }
            }
          }),
        );
      }
    }, [isSuccess, value]);

    const changeValueHandler = (e: ChangeEvent<HTMLInputElement>) => {
      if (!dirtyRef.current) dirtyRef.current = true;

      setValue(e.target.value);
    };

    const checkboxChangeHandler = () => {
      onSetSelectedIds([row.id], rowType);
    };
    // console.log('value>>>',value);
    // console.log('debouncedValue>>>',debouncedValue);
    const levelDisabled = useMemo(
      () =>
        rowType === 'group'
          ? !Array.isArray(maxDeep) && maxDeep <= 15
          : rowType === 'folded'
          ? !Array.isArray(maxDeep) && (maxDeep <= 15 || index > 0)
          : true,
      [maxDeep],
    );

    return (
      <>
        <StyledRow
          textAlign="center"
          $rowType={rowType}
          $isHover={hover}
          onMouseEnter={() => levelDisabled && setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={onToggleExpanded}>
          {isCheckboxShowed && (
            <StyledCheckbox
              checked={[...selectedIds.childrenIds, ...selectedIds.groupIds].includes(row.id)}
              onChange={checkboxChangeHandler}
              onClick={(e) => e.stopPropagation()}
            />
          )}
          {isRowMenuShowed && <StyledRowMenu onHover={() => setHover(false)} onRenameGroup={() => setIsEdit(true)} />}
          {rowType === 'folded' || rowType === 'position' ? (
            <BodyStyledRow>
              <Box sx={{ textAlign: 'right', cursor: 'pointer' }}>
                {rowType === 'folded' ? (
                  <KeyboardArrowDown
                    sx={
                      isExpanded
                        ? {
                            transform: 'rotateZ(180deg)',
                            transition: 'all, .2s',
                          }
                        : { transition: 'all, .2s' }
                    }
                  />
                ) : (
                  <Box pl={10}>
                    <Checkbox
                      color={row.subType === 'main' || row.subType === 'target' ? 'info' : 'warning'}
                      icon={<RadioButtonUnchecked />}
                      checkedIcon={<CheckCircle />}
                      checked={true}
                      size="small"
                    />
                  </Box>
                )}
              </Box>
              <Tooltip title={row.numbering ?? ''}>
                <BodyTdStyled>{row.numbering}</BodyTdStyled>
              </Tooltip>
              <Tooltip title={row.lsr || ''}>
                <BodyTdStyled textAlign="center">{row.lsr}</BodyTdStyled>
              </Tooltip>
              <Tooltip title={row.chapter || ''}>
                <BodyTdStyled>{row.chapter}</BodyTdStyled>
              </Tooltip>
              <Tooltip title={row.header || ''}>
                <BodyTdStyled>{row.header}</BodyTdStyled>
              </Tooltip>
              <Tooltip title={row.workType || ''}>
                <BodyTdStyled>{row.workType}</BodyTdStyled>
              </Tooltip>
              <Tooltip title={row.number && row.number !== 'null' ? row.number : ''}>
                <BodyTdStyled>{row.number && row.number !== 'null' ? row.number : ''}</BodyTdStyled>
              </Tooltip>
              <Tooltip title={row.code || ''}>
                <BodyTdStyled>{row.code}</BodyTdStyled>
              </Tooltip>
              <BodyTdStyled>
                {rowType === 'position' ? (
                  <Tooltip title={row.title || ''}>
                    <BodyTdStyled textAlign="left">{row.title}</BodyTdStyled>
                  </Tooltip>
                ) : (
                  <Tooltip title={row.title || ''}>
                    <InputStyled
                      onClick={(e) => e.stopPropagation()}
                      value={value}
                      onChange={changeValueHandler}
                      fullWidth
                    />
                  </Tooltip>
                )}
              </BodyTdStyled>
              <Tooltip title={row.unit || ''}>
                <BodyTdStyled>{row.unit}</BodyTdStyled>
              </Tooltip>
              <Tooltip
                //     valueToRender = value.toLocaleString('ru-RU', {
                //   minimumFractionDigits: 2,
                //   maximumFractionDigits: 2,
                // });
                title={row.quantity?.toString()?.replace(/\./, ',')}
                // title={row.quantity?.toLocaleString('ru-RU', {
                //   minimumFractionDigits: 0,
                //   maximumFractionDigits: 8,
                // })}
              >
                <BodyTdStyled textAlign="center">
                  {row.quantity?.toString()?.replace(/\./, ',')}
                  {/*{row.quantity?.toLocaleString('ru-RU', {*/}
                  {/*  minimumFractionDigits: 0,*/}
                  {/*  maximumFractionDigits: 8,*/}
                  {/*})}*/}
                </BodyTdStyled>
              </Tooltip>
              <Tooltip
                title={row.constructionEffortByUnit?.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}>
                <Box textAlign="right">
                  {row.constructionEffortByUnit?.toLocaleString('ru-RU', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Box>
              </Tooltip>
              <Tooltip
                title={row.constructionEffort?.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}>
                <Box textAlign="right">
                  {row.constructionEffort?.toLocaleString('ru-RU', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Box>
              </Tooltip>
              <Tooltip
                title={row.mechanicsEffortByUnit?.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}>
                <Box textAlign="right">
                  {row.mechanicsEffortByUnit?.toLocaleString('ru-RU', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Box>
              </Tooltip>
              <Tooltip
                title={row.mechanicsEffort?.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}>
                <Box textAlign="right">
                  {row.mechanicsEffort?.toLocaleString('ru-RU', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Box>
              </Tooltip>
              <Tooltip
                title={row.totalWorkCostByUnit?.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}>
                <Box textAlign="right">
                  {row.totalWorkCostByUnit?.toLocaleString('ru-RU', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Box>
              </Tooltip>
              <Tooltip
                title={row.totalWorkCost?.toLocaleString('ru-RU', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}>
                <Box textAlign="right" pr={2}>
                  {row.totalWorkCost?.toLocaleString('ru-RU', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Box>
              </Tooltip>
            </BodyStyledRow>
          ) : (
            <GroupRow
              parentGroupIds={parentGroupIds}
              parentChildrenLength={parentChildrenLength}
              key={`${row.id}-${isEdit}`}
              index={index}
              rowChildren={row.children}
              isHover={hover}
              isEdit={isEdit}
              onClose={() => setIsEdit(false)}
            />
          )}
          {isCreateLevelButtonShowed && (
            <CreateLevelButton
              onClick={(e) => {
                e.stopPropagation();
                onToggleFormVisible('create');
              }}
            />
          )}
        </StyledRow>
      </>
    );
  },
);

Row.displayName = 'Row';
