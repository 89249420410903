import { Divider } from "components/Divider";
import useBreadcrumbs from "hooks/useBreadcrumbs";
import FullVor from "pages/FullVor/FullVor";
import React, { FC, ReactElement, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  calculationsApi,
  useApplyFiltersMutation,
  useClearEmptyLevelsMutation,
  useGetFiltersMutation,
  useGetVorPositionsQuery,
  useGetVorQuery
} from "../../api/calculations";
import {
  CommonFiltersRequest,
  CommonFiltersResponse,
  Filter,
  FiltersVorBody,
  PositionVOR,
  PositionVORWithTarget
} from "../../api/calculations/types";
import {
  changeSavingData,
  clearAddinVor,
  clearDeletedRows,
  filterWorks,
  handleTableVariant,
  setSelectedVor as setSelectedRedux,
  updateDeletedRows
} from "../../store/slices/vor/vor";
import { RootState, useAppDispatch } from "../../store/store";
import { vorTableVariantLabels } from "./Vor.constants";
import { VorPageWrapper } from "./Vor.styles";
import {
  initialChecks,
  initialFilters,
  TFunctionSelectPosition,
  TSelectPositionArr,
  VorFilters,
  VorTableVariant
} from "./Vor.types";
import { ModalVOR } from "./components/ModalVOR";
import { PositionsTable } from "./components/PositionsTable";
import { TableLegend } from "./components/TableLegend";
import { useMutationHandlers } from "../../hooks/useMutationHandlers";


export const VorPage: FC = () => {

  const container = useRef<HTMLDivElement>(null);

  const { calcID: calcIdStr } = useParams<{ calcID: string }>();
  const calcId = Number(calcIdStr);
  const dispatch = useAppDispatch();
  const [selectedTableVariant, setSelectedTableVariant] = useState<VorTableVariant>("positions");
  const [selectedVor, setSelectedVor] = useState<number | null>(null);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [delArrId, setDelArrId] = React.useState<number[]>([]);
  const shouldClearEmptyLevels = React.useRef(false);
  const selectPositionsStorage = React.useRef<TSelectPositionArr>([]);
  const [selectPositions, setSelectPositions] = React.useState<TSelectPositionArr>([]);

  const selectPosition: TFunctionSelectPosition = React.useCallback((target, type, arrSelected) => {
    // let newArray: TSelectPositionArr = JSON.parse(JSON.stringify(selectPositions.length?selectPositions:selectPositionsStorage.current));
    let newArray: TSelectPositionArr = JSON.parse(JSON.stringify(arrSelected));
    if (Array.isArray(target)) {
      if (type === undefined) {
        newArray = target
      }else{
        target.forEach(item => {
          const findPosition = newArray.findIndex((p) => item.id === p.id);
          if (findPosition >= 0) {
            const itemArray = newArray[findPosition];
            if (type !== null && type !== undefined) {
              newArray[findPosition] = { ...itemArray, type };
            } else {
              newArray.filter((_) => _.id !== item.id);
            }
          } else {
            if (type !== undefined) {
              newArray.push({ ...item, type });
            }
          }
        });
      }
    } else {
      const findPosition = newArray.findIndex((p) => target.id === p.id);
      if (findPosition >= 0) {
        if (type !== null && type !== undefined) {
          newArray.splice(findPosition, 1, { ...target, type });
        } else {
          if (type === undefined) {
            // newArray[findPosition] = {...target, type}
          } else {
            newArray.splice(findPosition, 1);
          }
        }
      } else {
        if (type !== undefined) {
          newArray.push({ ...target, type });
        }
      }

    }
    setSelectPositions(newArray);
    selectPositionsStorage.current = newArray;
  }, [selectPositionsStorage.current, selectPositions]);
  const onTableVariantChange = (value: VorTableVariant) => {
    if (value && value !== selectedTableVariant) {
      setSelectedTableVariant(value);
    }
  };

  const { isAddingVor, selectedPositions, grouped, vorId, deletedRows } = useSelector(
    (state: RootState) => state.vor.table
  );

  const [clearEmptyLevels] = useClearEmptyLevelsMutation();

  const [activeFilters, setActiveFilters] = useState<VorFilters>(initialFilters);

  const [checkedFilters, setCheckedFilters] = useState<Record<keyof FiltersVorBody, Record<keyof CommonFiltersResponse, Filter[]>>>(initialChecks);

  const checkFilter = useCallback((type: keyof FiltersVorBody, key: keyof CommonFiltersResponse, item: Filter | Filter[] | null, isActiveClear = false) => {
    const names: Record<keyof CommonFiltersResponse, keyof CommonFiltersRequest> = {
      names     : "nameIDs",
      lsrNums   : "lsrNumIDs",
      priceCodes: "priceCodeIDs",
      lsrCodes  : "lsrCodeIDs",
      chapters  : "chapterIDs",
      titles    : "titleIDs"
    };
    let body: FiltersVorBody | undefined;
    setCheckedFilters(prevState => {
      const targetArr = prevState[type][key];
      const titles = targetArr.map(_ => _.title);
      const newState = {
        ...prevState,
        [type]: {
          ...prevState[type],
          [key]: item === null ? [] : Array.isArray(item) ? [...prevState[type][key],...item] : titles.includes(item.title) ? targetArr.filter(_ => _.title !== item.title) : [...targetArr, item]
        }
      };
      body = {
        formedFilter: {
          chapterIDs  : newState.formedFilter.chapters.flatMap((item) => item.ids || []).flat(),
          lsrCodeIDs  : newState.formedFilter.lsrCodes.flatMap((item) => item.ids || []).flat(),
          lsrNumIDs   : newState.formedFilter.lsrNums.flatMap((item) => item.ids || []).flat(),
          nameIDs     : newState.formedFilter.names.flatMap((item) => item.ids || []).flat(),
          titleIDs    : newState.formedFilter.titles.flatMap((item) => item.ids || []).flat(),
          priceCodeIDs: newState.formedFilter.priceCodes.flatMap((item) => item.ids || []).flat()
        },
        rowsFilter  : {
          chapterIDs  : newState.rowsFilter.chapters.flatMap((item) => item.ids || []).flat(),
          lsrCodeIDs  : newState.rowsFilter.lsrCodes.flatMap((item) => item.ids || []).flat(),
          lsrNumIDs   : newState.rowsFilter.lsrNums.flatMap((item) => item.ids || []).flat(),
          nameIDs     : newState.rowsFilter.names.flatMap((item) => item.ids || []).flat(),
          titleIDs    : newState.rowsFilter.titles.flatMap((item) => item.ids || []).flat(),
          priceCodeIDs: newState.rowsFilter.priceCodes.flatMap((item) => item.ids || []).flat()
        }
      };
      return newState;
    });
    if (isActiveClear && body) {
      body[type][names[key]] = [];
      setActiveFilters(prevState => ({
        ...prevState,
        activeFilters: body!
      }));
      // const data = type === 'group'?
      //   Object.keys(checkedFilters.formedFilter)
      //     .map(_=>checkedFilters.formedFilter[_ as keyof typeof checkedFilters.formedFilter]?.flatMap(item=>JSON.parse(item||'[]')))
      //     .flat()
      //   :
      //   Object.keys(checkedFilters.rowsFilter)
      //     .map(_=>checkedFilters.rowsFilter[_ as keyof typeof checkedFilters.rowsFilter]?.flatMap(item=>JSON.parse(item||'[]')))
      //     .flat();
      getFilters({ calcID: calcId, body }).then(() => {
        body && applyFilters({ calcID: calcId, body });
      });
    }
  }, []);

  // const [vorPositionsData, setVorPositionsData] = useState<GetVorPositionsResponse>({
  //   formed: [],
  //   data  : []
  // });

  const getVorStatus = useGetVorPositionsQuery({
    calcId
  });
  const { data: vorPositionsData } = getVorStatus;

  const [getFilters, getFiltersStatus] = useGetFiltersMutation();
  const [applyFilters, applyFiltersStatus] = useApplyFiltersMutation();

  const resetFilters = useCallback(() => {
    setActiveFilters(initialFilters);
    setCheckedFilters(initialChecks);
    getFilters({ calcID: calcId, body: initialFilters.activeFilters }).then(() => {
      applyFilters({ calcID: calcId, body: initialFilters.activeFilters });
    });
  }, [calcId]);

  const applyFiltersHandler = useCallback(() => {
    const body: FiltersVorBody = {
      formedFilter: {
        chapterIDs  : checkedFilters.formedFilter.chapters.flatMap((item) => item.ids || []).flat(),
        lsrCodeIDs  : checkedFilters.formedFilter.lsrCodes.flatMap((item) => item.ids || []).flat(),
        lsrNumIDs   : checkedFilters.formedFilter.lsrNums.flatMap((item) => item.ids || []).flat(),
        nameIDs     : checkedFilters.formedFilter.names.flatMap((item) => item.ids || []).flat(),
        titleIDs    : checkedFilters.formedFilter.titles.flatMap((item) => item.ids || []).flat(),
        priceCodeIDs: checkedFilters.formedFilter.priceCodes.flatMap((item) => item.ids || []).flat()
      },
      rowsFilter  : {
        chapterIDs  : checkedFilters.rowsFilter.chapters.flatMap((item) => item.ids || []).flat(),
        lsrCodeIDs  : checkedFilters.rowsFilter.lsrCodes.flatMap((item) => item.ids || []).flat(),
        lsrNumIDs   : checkedFilters.rowsFilter.lsrNums.flatMap((item) => item.ids || []).flat(),
        nameIDs     : checkedFilters.rowsFilter.names.flatMap((item) => item.ids || []).flat(),
        titleIDs    : checkedFilters.rowsFilter.titles.flatMap((item) => item.ids || []).flat(),
        priceCodeIDs: checkedFilters.rowsFilter.priceCodes.flatMap((item) => item.ids || []).flat()
      }
    };


    // const data = type === 'group'?
    //   Object.keys(checkedFilters.formedFilter)
    //     .map(_=>checkedFilters.formedFilter[_ as keyof typeof checkedFilters.formedFilter]?.flatMap(item=>JSON.parse(item||'[]')))
    //     .flat()
    //   :
    //   Object.keys(checkedFilters.rowsFilter)
    //     .map(_=>checkedFilters.rowsFilter[_ as keyof typeof checkedFilters.rowsFilter]?.flatMap(item=>JSON.parse(item||'[]')))
    //     .flat();
    setActiveFilters(prevState => ({
      ...prevState,
      activeFilters: body
    }));
    getFilters({ calcID: calcId, body }).then(() => {

      applyFilters({ calcID: calcId, body });
    });
  }, [calcId, checkedFilters]);

  useLayoutEffect(() => {
    getFilters({ calcID: calcId, body: activeFilters.activeFilters }).then(() => {
      applyFilters({ calcID: calcId, body: activeFilters.activeFilters });
    });
  }, [calcId]);

  useMutationHandlers(getFiltersStatus, (response) => {
    setActiveFilters(prevState => ({ ...prevState, viewFilters: response }));
  });
  // useMutationHandlers(getVorStatus, (response) => {
  //   setActiveFilters(prevState => ({ ...prevState, viewFilters: response }));
  // });
  useMutationHandlers(applyFiltersStatus, (response) => {
    // setVorPositionsData(response);
    dispatch(
      calculationsApi.util.updateQueryData("getVorPositions", { calcId }, (draft) => {
        // const d = response.data?.map(item => {
        //   const find = draft.data.find(_ => _.id === item.id);
        //   if (find) {
        //     return { ...item, ...find };
        //   }
        //   return item;
        // });
        // draft.data = d;
        // draft.formed = response.formed;
        Object.assign(draft, response);
        // if (draft && draft.data) {
        //   const changedVorIndex = draft.data.findIndex((thisVor) => thisVor.id === vor.id);
        //   if (changedVorIndex !== undefined && changedVorIndex >= 0) {
        //     if (draft.data[changedVorIndex]) {
        //       (draft.data[changedVorIndex] as any).type =  null ;
        //     }
        //   }
        // }
      })
    );
  });
  // useMutationHandlers(applyFiltersStatus, (response) => {
  //   // setVorPositionsData(response);
  //   dispatch(
  //     calculationsApi.util.updateQueryData("getVorPositions", { calcId }, (draft) => {
  //       Object.assign(draft, response);
  //       // if (draft && draft.data) {
  //       //   const changedVorIndex = draft.data.findIndex((thisVor) => thisVor.id === vor.id);
  //       //   if (changedVorIndex !== undefined && changedVorIndex >= 0) {
  //       //     if (draft.data[changedVorIndex]) {
  //       //       (draft.data[changedVorIndex] as any).type =  null ;
  //       //     }
  //       //   }
  //       // }
  //     })
  //   );
  // });

  const { data: vorPositions = [], formed: groupedPositions = [] } = vorPositionsData || {};

  const { data: currentVor } = useGetVorQuery(
    {
      calcId,
      vorId
    },
    { skip: !calcId || !vorId }
  );

  useEffect(() => {
    if (!applyFiltersStatus.isLoading && shouldClearEmptyLevels.current && calcIdStr && vorPositionsData?.formed.length === 0) {
      clearEmptyLevels({ calcID: +calcIdStr });
    }

    if (!applyFiltersStatus.isLoading) {
      shouldClearEmptyLevels.current = true;
    }
  }, [applyFiltersStatus.isLoading]);

  useEffect(() => {
    if (!openModal && !isAddingVor) {
      dispatch(clearDeletedRows());
    }
  }, [openModal, isAddingVor]);

  const { filteredMainWorksOnDelete, filteredExtraWorksOnDelete } = useMemo(() => {
    return {
      filteredMainWorksOnDelete: selectPositions
        .filter((item: PositionVOR) => {
          return item.type === "main";
        })
        .filter((item: PositionVOR) => {
          return !delArrId.includes(item.id);
        }),

      filteredExtraWorksOnDelete: selectPositions
        .filter((item: PositionVOR) => {
          return item.type === "extra";
        })
        .filter((item: PositionVOR) => {
          return !delArrId.includes(item.id);
        })
    };
  }, [selectPositions, delArrId]);

  const handleOpenModal = useCallback(() => {
    return setOpenModal(true);
  }, [filteredMainWorksOnDelete]);

  const countPositions = vorPositions.length;
  const countGroupedPositions = groupedPositions.length;
  const contentByTab: Record<VorTableVariant, ReactElement> = useMemo(
    () => ({
      positions: (
        <PositionsTable
          selectPosition={selectPositions}
          setSelectPosition={selectPosition}
          key={"list"}
          checkFilter={checkFilter}
          checkedData={checkedFilters}
          container={container}
          applyedFilter={activeFilters.activeFilters}
          handleApplyFilters={applyFiltersHandler}
          viewFilters={activeFilters.viewFilters}
          data={vorPositions}
          type={"list"}
          setSelectedVor={setSelectedVor}
          handleOpenModal={() => setOpenModal(true)}
          delArrId={delArrId}
        />
      ),
      grouped  : (
        <PositionsTable
          selectPosition={selectPositions}
          setSelectPosition={selectPosition}
          key={"group"}
          checkFilter={checkFilter}
          checkedData={checkedFilters}
          container={container}
          applyedFilter={activeFilters.activeFilters}
          handleApplyFilters={applyFiltersHandler}
          viewFilters={activeFilters.viewFilters}
          type={"group"}
          data={groupedPositions}
          delArrId={delArrId}
          setSelectedVor={setSelectedVor}
          handleOpenModal={() => setOpenModal(true)}
        />
      ),
      fullVor  : <FullVor />
    }),
    [vorPositionsData, delArrId, activeFilters.viewFilters, applyFiltersHandler, selectPositions, selectPosition]
  );

  const isMainWorks = useMemo(() => {
    return selectPositions.filter((item) => {
      return item.type === "main";
    }).length;
  }, [selectPositions]);

  const selectedWorks = useMemo(() => {
    return selectPositions.filter((item) => {
      return item.type === "main" || item.type === "extra";
    }).length;
  }, [selectPositions]);

  useEffect(() => {
    if (!openModal) {
      setDelArrId([]);
    }
  }, [openModal]);

  useEffect(() => {
    dispatch(clearAddinVor());
  }, []);

  useEffect(() => {
    if (selectedTableVariant === "grouped") {
      dispatch(handleTableVariant("grouped"));
    } else if (selectedTableVariant === "positions") {
      dispatch(handleTableVariant("positions"));
    }
  }, [selectedTableVariant]);

  const dispatchVor = useCallback((vor: PositionVORWithTarget, cash: boolean) => {
    selectPosition(vor as PositionVOR, cash ? null : vor.type, selectPositions);
    // dispatch(
    //   calculationsApi.util.updateQueryData("getVorPositions", { calcId }, (draft) => {
    //     console.log("clear");
    //     if (draft && draft.data) {
    //       const changedVorIndex = draft.data.findIndex((thisVor) => thisVor.id === vor.id);
    //       if (changedVorIndex !== undefined && changedVorIndex >= 0) {
    //         if (draft.data[changedVorIndex]) {
    //           (draft.data[changedVorIndex] as any).type = cash ? null : vor.type;
    //         }
    //       }
    //     }
    //   })
    // );
  }, [activeFilters.activeFilters, selectPositions]);

  const clearWorksInRTK = useCallback(() => {
    dispatch(
      calculationsApi.util.updateQueryData("getVorPositions", { calcId }, (draft) => {
        if (draft && draft.data) {
          draft.data.forEach((vor) => {
            if (vor.type) {
              selectPosition({ ...vor }, null, selectPositions);
            }
          });
          // const filterWorks = draft.data.filter((_) => _.type);
          // filterWorks.forEach((_) => {
          //   const changedVorIndex = draft.data.findIndex((thisVor) => thisVor.id === _.id);
          //
          //   if (changedVorIndex !== undefined) {
          //     if (draft.data[changedVorIndex]) {
          //       delete (draft.data[changedVorIndex] as any).type;
          //     }
          //   }
          // });
        }
      })
    );
    setSelectPositions([]);
  }, [selectPositions]);

  useEffect(() => {
    if (isAddingVor) {
      const checked = [...grouped.extraWorks, ...grouped.mainWorks];
      const compileArr = [...filteredMainWorksOnDelete, ...filteredExtraWorksOnDelete];
      dispatch(
        setSelectedRedux(
          compileArr.filter((_) => !checked.map((item) => item.id).includes(_.id)) as PositionVORWithTarget[]
        )
      );

      compileArr.forEach((vor) => {
        dispatchVor(vor, true);
      });
      !deletedRows.length && clearWorksInRTK();
      checked.forEach((vor) => {
        dispatchVor(vor, false);
      });
    } else {
      if (!applyFiltersStatus.isLoading) {
        selectedPositions.forEach((vor) => {
          dispatchVor(vor, false);
        });
      }
    }
  }, [isAddingVor, applyFiltersStatus.isLoading, grouped.extraWorks, grouped.mainWorks, deletedRows]);

  const handleDelRow = useCallback(
    (vor: PositionVOR) => {
      dispatch(changeSavingData(vor.id));
      dispatch(filterWorks(vor.id));

      const work = vorPositions.find((_) => _.id === vor.id);
      if (work) {
        dispatchVor(vor, true);
      }
      setDelArrId((prevArr) => [...prevArr, vor.id]);

      let deletedRow = currentVor?.rows.find((position: PositionVOR) => position.id === vor.id);
      // console.log(deletedRow);
      // if (deletedRow) {
      //   dispatchVor(deletedRow, true);
      // }
      if (deletedRow) {
        dispatch(updateDeletedRows({ rows: [deletedRow] }));

      }
    },
    [vorPositions, currentVor]
  );

  useBreadcrumbs(
    [
      {
        title: `Формирование укрупненных расценок / ${vorTableVariantLabels[selectedTableVariant]}`
      }
    ],
    [selectedTableVariant]
  );

  useEffect(() => {
    if (!selectPositions.length && selectPositionsStorage.current.length) {
      setSelectPositions(selectPositionsStorage.current);
    }
  }, []);

  return (
    <>
      <div ref={container} />
      <VorPageWrapper flex={1}>
        <TableLegend
          redirect={() => {
            setSelectedTableVariant("grouped");
            setOpenModal(true);
          }}
          clearWorksInRTK={clearWorksInRTK}
          selectedTableVariant={selectedTableVariant}
          onTableVariantChange={onTableVariantChange}
          handleOpenModal={handleOpenModal}
          works={[filteredMainWorksOnDelete, filteredExtraWorksOnDelete]}
          countPositions={countPositions}
          countGroupedPositions={countGroupedPositions}
          isMainWorks={isMainWorks}
          selected={selectedWorks}
        />
        <ModalVOR
          selectPosition={selectPositions}
          setSelectPosition={selectPosition}
          resetFilters={resetFilters}
          dispatchVor={dispatchVor}
          setSelectedTableVariant={setSelectedTableVariant}
          openModal={openModal}
          delArrId={delArrId}
          setOpenModal={setOpenModal}
          extraWorks={filteredExtraWorksOnDelete!}
          mainWorks={filteredMainWorksOnDelete!}
          handleDelRow={handleDelRow}
          selectedVor={selectedVor}
          isEdit={selectedTableVariant === "grouped"}
          vorPositions={vorPositions}
        />
        <Divider />

        {contentByTab[selectedTableVariant]}
      </VorPageWrapper>
    </>
  );
};
