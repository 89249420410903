import { Box, Grid } from '@mui/material';
import { EmptyPage } from 'components/EmptyPage';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CalculationStatusesTabLabels, CalculationTabLabels } from 'types';
import { ButtonData, EmptyPageData } from '../../components/EmptyPage/EmptyPage.types';
import { TabData } from '../../components/Tabs/Tabs.types';
import useBreadcrumbs from '../../hooks/useBreadcrumbs';
import useConfirmDialog, { UseExitConfirmProps } from '../../hooks/useConfirmDialog';
import { useAppContext } from '../../layouts/Layout/context';
import CalculationDrawer from '../Calculations/components/CalculationDrawer';
import HomeLegend from './Legend';

export const getEmptyPageData = (text: React.ReactNode, buttons?: ButtonData[]): EmptyPageData => ({
  text,
  buttons:
    buttons?.map((button) => ({
      text: button.text,
      icon: button.icon,
      onClick: button.onClick,
    })) || [],
});

const Home: React.FC = () => {
  const navigate = useNavigate();
  const { profile } = useAppContext();
  const { firstName, role } = profile;

  const [currentTab, setCurrentTab] = useState<CalculationTabLabels>('Все расчеты');
  const tabNames: CalculationTabLabels[] = Object.values(CalculationStatusesTabLabels);

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [chosenCalculationId, setChosenCalculationId] = useState<number | null>(null);

  const onTabChange = useCallback(
    (e: React.SyntheticEvent, tabValue: CalculationTabLabels) => {
      setCurrentTab(tabValue);
    },
    [setCurrentTab],
  );

  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      setIsDrawerOpen(false);
    }
  }, []);

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,
  };

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog);

  const onDrawerClose = useCallback((dirty: boolean, immediately?: boolean) => {
    immediately || !dirty ? setIsDrawerOpen(false) : openConfirm();
  }, []);

  const onAddCalculationClick = useCallback(() => {
    setIsDrawerOpen(true);
    setChosenCalculationId(null);
  }, []);

  const statisticAverage = {
    agreed: 33,
    agreement: 0,
    correction: 0,
    development: 17,
    inWork: 17,
    notInWork: 50,
  };

  useBreadcrumbs([{ title: 'Расчеты смет / Все расчеты' }]);

  const tabsData: TabData<CalculationTabLabels>[] = useMemo(() => {
    return tabNames.map((tabName) => ({ value: tabName, label: tabName }));
  }, [tabNames]);

  const emptyPageDataForAdmin: EmptyPageData = getEmptyPageData(
    <>
      Здравствуйте, {firstName}, у Вас еще нет проектов для отображения.
      <br />
      Давайте создадим ваш первый проект.
    </>,
    [
      /*  {
        text: 'Добавить проект',
        icon: AddIcon,
        onClick: () => navigate('/administration/projects?open=true'),
      }, */
    ],
  );

  // если нет проектов
  const emptyPageDataForUser: EmptyPageData = getEmptyPageData(
    <>
      Здравствуйте, {firstName}, Вам еще не был предоставлен доступ к проектам,
      <br />
      либо проекты в системе отсутствуют.
    </>,
  );

  return (
    <Box className="Home" flex={1} style={{ width: '100%', height: 'calc(100% - 66px)' }}>
      {
        /* 'isLoading' || 'isFetching' ? (
        <Progress />
      ) :  */ 'filteredProjectsData?.length' ? (
          <>
            <HomeLegend<CalculationTabLabels>
              currentTab={currentTab}
              onTabChange={onTabChange}
              tabsData={tabsData}
              onAddClick={onAddCalculationClick}
            />
            <Grid sx={{ py: 1.25 }} spacing={'20px'} container>
              {Array.from(Array(26)).map((_, index) => (
                <Grid
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={2.4}
                  container
                  justifyContent="center"
                  item
                  key={index + 'project.project.id'}></Grid>
              ))}
            </Grid>
          </>
        ) : (
          <EmptyPage data={role === 'admin' ? emptyPageDataForAdmin : emptyPageDataForUser} />
        )
      }
      <CalculationDrawer open={isDrawerOpen} onClose={onDrawerClose} calculationId={chosenCalculationId} />
      <ConfirmDialog />
    </Box>
  );
};

export default Home;
