import { api } from "api/api";
import { UnitWeight } from "../../../store/slices/references/prices/types";
import {
  ICreateFolderOrRateRequest,
  ICreateFolderRequest,
  ICreateKFOGroup,
  ICreateRootFolderRequest,
  ICreateUnitWeight,
  IDeleteKFOGroup,
  IDeleteRateParams,
  IDeleteUnitWeight,
  IEstimateReference,
  IGetFilters,
  IKFOGroup,
  IParamsGetDataWithFilters,
  IPositionEstimate,
  IRateEstimate,
  IRequestUpdateCoefficients,
  IResponseCoefficients,
  IUpdateFolder,
  IUpdateRate,
  IUpdateRatePosition
} from "./estimates.types";

// const template: IEstimateReference = {
//   create: true,
//   id: -100,
//   hash: -100,
//   uuid: '-100',
//   parentID: null,
//   title: '',
//   lvl: null,
//   rowType: 'RATE_POSITION',
//   unit: '',
//   // unit: UnitType,
//   code: null,
//   qty: null,
//   pz: null,
//   fot: null,
//   mim: null,
//   mtr: null,
//   totalWorkTime: null,
//   kfoUnit: null,
//   kfoName: null,
//   kfoCoefficient: null,
//   description: '',
//   resourceType: null,
//   children: [],
// };

export const estimatesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEstimateReference: build.query<IEstimateReference[], unknown>({
      query: () => ({
        url: '/handbook/get',
        method: 'GET',
      }),
      // transformResponse: (resp: IEstimateReference[]) => {
      //   const copyResponse: IEstimateReference[] = JSON.parse(JSON.stringify(resp));
      //
      //   const map = new Map<number, IEstimateReference>();
      //
      //   function flat(target: IEstimateReference) {
      //     if (target.rowType === 'RATE') {
      //       const update = {
      //         ...template,
      //         parentID: target.id,
      //         id: template.id - target.id,
      //         uuid: template.uuid + (template.id - target.id),
      //         folder: target.parentID ?? undefined,
      //       };
      //       map.set(target.id, update);
      //       target.children.push(update);
      //     } else {
      //       target.children.forEach(flat);
      //     }
      //   }
      //   function flatCount(item: IEstimateReference) {
      //     let num = item.children.length;
      //     const f = (el: IEstimateReference) => {
      //       num += el.children.length;
      //       el.children.forEach(f);
      //     };
      //     item.children.forEach(f);
      //     return num;
      //   }
      //   function mutation(item: IEstimateReference, key: number) {
      //     const index = copyResponse.findIndex((_) => _.id === key && _.rowType === 'RATE');
      //     const count = flatCount(copyResponse[index]);
      //     index >= 0 && copyResponse.splice(index + count, 0, item);
      //   }
      //
      //   copyResponse.forEach(flat);
      //   // console.log(copyResponse);
      //   // console.log(map);
      //   // map.forEach(mutation);
      //   console.group('<<<response price reference>>>');
      //   console.log(copyResponse);
      //   console.groupEnd();
      //   return copyResponse;
      // },
      providesTags: ['PriceReference'],
    }),

    createRoot: build.mutation<unknown, ICreateRootFolderRequest>({
      query: (body) => ({
        url: '/handbook/create-root',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PriceReferenceWithFilters'],
    }),
    createFolder: build.mutation<unknown, ICreateFolderRequest>({
      query: (body) => ({
        url: '/handbook/v2/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PriceReferenceWithFilters'],
    }),
    createFolderOrRate: build.mutation<unknown, { body: ICreateFolderOrRateRequest; folderID: number }>({
      query: ({ body, folderID }) => ({
        url: `/handbook/v2/${folderID}/create`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PriceReferenceWithFilters'],
    }),
    createOther: build.mutation<unknown, { body: ICreateRootFolderRequest; folderID: number }>({
      query: ({ body, folderID }) => ({
        url: `/handbook/${folderID}/create`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PriceReferenceWithFilters'],
    }),
    createEstimate: build.mutation<unknown, { body: IRateEstimate; folderID: number }>({
      query: ({ body, folderID }) => ({
        url: `/handbook/${folderID}/rates/create`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PriceReferenceWithFilters'],
    }),
    createPosition: build.mutation<unknown, { body: IPositionEstimate; folderID: number; rateID: number }>({
      query: ({ body, folderID, rateID }) => ({
        url: `/handbook/${folderID}/rates/${rateID}/positions/create`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PriceReferenceWithFilters'],
    }),
    updateRatePosition: build.mutation<
      IEstimateReference[],
      { positionID: number; folderID: number; rateID: number; body: IUpdateRatePosition }
    >({
      query: ({ positionID, folderID, rateID, body }) => ({
        url: `/handbook/${folderID}/rates/${rateID}/positions/${positionID}/update`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['PriceReferenceWithFilters'],
    }),
    updateRate: build.mutation<IEstimateReference, { folderID: number; rateID: number; body: IUpdateRate }>({
      query: ({ folderID, rateID, body }) => ({
        url: `/handbook/${folderID}/rates/${rateID}/update`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['PriceReferenceWithFilters'],
    }),
    updateFolder: build.mutation<IEstimateReference, { folderID: number; body: IUpdateFolder }>({
      query: ({ folderID, body }) => ({
        url: `/handbook/${folderID}/update`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['PriceReferenceWithFilters'],
    }),
    deleteFolder: build.mutation<void, number>({
      query: (folderID) => ({
        // url: `/handbook/${folderID}/delete`,
        url: `/handbook/v2/${folderID}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PriceReference', 'PriceReferenceWithFilters'],
    }),

    deleteRates: build.mutation<unknown, IDeleteRateParams>({
      query: (body) => ({
        // url: `/handbook/rates/delete`,
        url: `/handbook/v2/rates/delete`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['PriceReference', 'PriceReferenceWithFilters'],
    }),

    getKFOGroup: build.query<IKFOGroup[], { nameLike?: string }>({
      query: ({ nameLike }) => ({
        url: '/handbook/groups/get',
        method: 'GET',
        params: {
          nameLike,
        },
      }),
      providesTags: ['PriceReferenceKFOGroup'],
    }),
    createKFOGroup: build.mutation<IKFOGroup, ICreateKFOGroup>({
      query: (body) => ({
        url: '/handbook/groups/add',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PriceReferenceKFOGroup', 'PriceReferenceWithFilters'],
    }),
    deleteKFOGroup: build.mutation<unknown, IDeleteKFOGroup>({
      query: (params) => ({
        url: '/handbook/groups/delete',
        method: 'DELETE',
        params,
      }),
      // invalidatesTags: ['PriceReferenceKFOGroup', 'PriceReference'],
    }),
    getUnitList: build.query<UnitWeight[], { nameLike?: string }>({
      query: ({ nameLike }) => ({
        url: '/handbook/units/get',
        method: 'GET',
        params: {
          nameLike,
        },
      }),
      providesTags: ['PriceReferenceUnit'],
    }),

    createUnitWeight: build.mutation<UnitWeight, ICreateUnitWeight>({
      query: (body) => ({
        url: '/handbook/units/add',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PriceReferenceUnit'],
    }),

    deleteUnitWeight: build.mutation<unknown, IDeleteUnitWeight>({
      query: (params) => ({
        url: '/handbook/units/delete',
        method: 'DELETE',
        params,
      }),
      // invalidatesTags: ['PriceReference'],
    }),

    exportPriceReference: build.query<string, void>({
      query: () => ({
        url: '/handbook/export',
        method: 'GET',
        responseHandler: 'text',
      }),
    }),

    getTemplatePriceReference: build.query<string, void>({
      query: () => ({
        url: '/handbook/import/template',
        method: 'GET',
        responseHandler: 'text',
      }),
    }),

    getDataWithFilters: build.mutation<IEstimateReference[], IParamsGetDataWithFilters>({
      query: (body) => ({
        url: '/handbook/filter/get-filtered-rates',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['PriceReferenceWithFilters'],
    }),
    getFiltersRate: build.query<IGetFilters[], unknown>({
      query: () => ({
        url: '/handbook/filter/rate-titles',
        method: 'GET',
      }),

      providesTags: ['PriceReferenceWithFilters'],
    }),
    getCoefficientsCalculation: build.query<IResponseCoefficients, number>({
      query: (calcID) => ({
        url: `/calculation/${calcID}/handbook/coefficients`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      providesTags: ['CalculationCoefficients'],
    }),
    updateCoefficientsCalculation: build.mutation<IResponseCoefficients, IRequestUpdateCoefficients>({
      query: ({ calcID, coefficients }) => ({
        url: `/calculation/${calcID}/handbook/coefficients`,
        method: 'POST',
        body: { coefficients },
      }),

      invalidatesTags: ['CalculationCoefficients', 'CalculationDictionary'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCoefficientsCalculationQuery,
  useLazyGetCoefficientsCalculationQuery,
  useUpdateCoefficientsCalculationMutation,

  useGetEstimateReferenceQuery,
  useLazyExportPriceReferenceQuery,
  useLazyGetTemplatePriceReferenceQuery,
  useCreateRootMutation,
  useCreateFolderMutation,
  useCreateFolderOrRateMutation,
  useCreateOtherMutation,
  useDeleteFolderMutation,
  useCreateEstimateMutation,
  useCreatePositionMutation,
  useUpdateRateMutation,
  useUpdateRatePositionMutation,
  useUpdateFolderMutation,
  useCreateUnitWeightMutation,
  useDeleteUnitWeightMutation,
  useGetUnitListQuery,
  useDeleteRatesMutation,
  useGetKFOGroupQuery,
  useCreateKFOGroupMutation,
  useDeleteKFOGroupMutation,
  useGetDataWithFiltersMutation,
  useGetFiltersRateQuery,
} = estimatesApi;
