import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { api } from '../../api/api'
import { AuthEndpoints, LoginResponse } from '../../api/auth/types'

let initialState: Partial<LoginResponse>

try {
  initialState = JSON.parse(
    localStorage.getItem('auth') || '{}'
  ) as Partial<LoginResponse>
} catch (error) {
  initialState = {}
}

const updateState = (
  prevState: Partial<LoginResponse>,
  payload: Partial<LoginResponse>
) => {
  const { refreshToken, token, refreshDurationMs, tokenDurationMs } = payload
  prevState.token = token
  prevState.tokenDurationMs = tokenDurationMs
  prevState.refreshToken = refreshToken
  prevState.refreshDurationMs = refreshDurationMs
  localStorage.setItem('auth', JSON.stringify(payload))
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, { payload }) => {
      updateState(state, payload)
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (props) =>
          !!(api.endpoints as AuthEndpoints).loginByToken?.matchFulfilled(
            props
          ),
        (state, { payload }) => {
          if (payload?.token) {
            updateState(state, payload)
          }
        }
      )
  },

},)

export default slice.reducer

export const { setCredentials } = slice.actions
export const isAuthSelector = (state: RootState) => !!state.auth.token

