/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import React, { FC, Fragment, useContext, useMemo, useState } from 'react';
import { Box, Collapse, Radio, Typography } from '@mui/material';
import { ITangleFolders, ITangleProjectResponse } from '../../../../../../api/tangl/tangl.types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Tooltip from '../../../../../../components/Tooltip';
import { TanglContext, TParent } from '../../CalculationDrawerForm';

const RowTangle: FC<{ folder: ITangleProjectResponse | ITangleFolders; padding: number; parent: TParent }> = ({
  folder,
  padding,
  parent,
}) => {
  const { setCurrentModel, currentModel } = useContext(TanglContext);
  const [expanded, setExpanded] = useState(false);
  const mappedData = useMemo(() => {
    return 'folders' in folder ? folder.folders ?? [] : folder.child ?? [];
  }, []);
  const calcPadding = useMemo(() => {
    return padding * 8 + 8;
  }, []);
  return (
    <Fragment>
      <Box
        overflow={'hidden'}
        textOverflow={'ellipsis'}
        onClick={() => setExpanded((prevState) => !prevState)}
        sx={{
          padding: `11px 16px 11px ${calcPadding}px`,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
        }}>
        <Typography variant={'subtitle1'} maxWidth={'100%'} textOverflow={'ellipsis'} overflow={'hidden'}>
          {folder.name}
        </Typography>

        <KeyboardArrowDownIcon sx={{ transition: 'all linear 0.2s', rotate: expanded ? '180deg' : 0 }} />
      </Box>
      {/*{mappedData && (*/}
      <Collapse sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} in={expanded}>
        {(folder.models || []).map((model) => {
          return (
            <>
              <Box
                component={'label'}
                sx={{
                  // display: 'flex',
                  cursor: 'pointer',
                  whiteSpace: 'nowrap',
                  display: 'flex',
                  padding: `11px 16px 11px ${calcPadding}px`,
                  alignItems: 'center',
                }}>
                <Radio
                  name={model.name}
                  checked={currentModel?.module?.id === model.id}
                  onChange={() => setCurrentModel({ module: model, parent })}
                />
                <Tooltip title={model.name}>
                  <Typography variant={'subtitle2'} maxWidth={'100%'} textOverflow={'ellipsis'} overflow={'hidden'}>
                    {model.name}
                  </Typography>
                </Tooltip>
              </Box>
            </>
          );
        })}
        {mappedData.map((innerFolder, index) => (
          <Fragment key={index}>
            <RowTangle folder={innerFolder} padding={padding + 1} parent={parent} />
          </Fragment>
        ))}
      </Collapse>
      {/*)}*/}
    </Fragment>
  );
};

export default RowTangle;
