import { darken, inputBaseClasses, outlinedInputClasses, TextField } from '@mui/material';
import styled from 'styled-components';

interface StyledBaseTextFieldProps {
  $textAlign?: 'left' | 'center' | 'right';
}

export const StyledBaseTextField = styled(TextField)<StyledBaseTextFieldProps>(({ theme, $textAlign }) => ({
  // borderBottom: '1px solid #0044B4',
  [`& .${inputBaseClasses.root}`]: {
    backgroundColor: theme.palette.bg.shades,
    borderRadius: theme.spacing(1),
    color: theme.palette.text.dark,
  },

  [`& .${inputBaseClasses.input}`]: {
    textAlign: $textAlign ?? 'left',

    '&::placeholder': {
      fontSize: '14px',
      color: theme.palette.legends.disabled,
      opacity: 1,
    },
  },

  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: darken(theme.palette.bg.shades, 0.05),
    borderRadius: theme.spacing(1),
  },

  [`& .${inputBaseClasses.disabled} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: darken(theme.palette.bg.shades, 0.03),
  },
}));
export const PRStyledBaseTextField = styled(TextField)<StyledBaseTextFieldProps>(({ theme, $textAlign, error }) => ({
  width: '100%',
  height: '100%',
  ...(error ? { border: '1px solid red !important' } : { borderBottom: '1px solid #0044B4 !important' }),
  [`& .${inputBaseClasses.root}`]: {
    // border: 'none',
    // borderBottom: '1px solid #0044B4',
    width: '100%',
    height: '100%',
    ...(error
      ? { color: theme.palette.error.main }
      : { backgroundColor: theme.palette.bg.shades, color: theme.palette.text.dark }),

    outline: 'none',
    borderRadius: 0,
  },

  [`& .${inputBaseClasses.input}`]: {
    border: 'none',
    textAlign: $textAlign ?? 'left',
    outline: 'none',
    width: '100%',
    height: '100%',

    '&::placeholder': {
      border: 'none',
      fontSize: '14px',
      color: theme.palette.legends.disabled,
      opacity: 1,
      outline: 'none',
    },
  },

  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: 'none',
    borderRadius: 0,
    outline: 'none',
    width: '100%',
    height: '100%',
  },

  [`& .${inputBaseClasses.disabled} .${outlinedInputClasses.notchedOutline}`]: {
    border: 'none',
    outline: 'none',
    width: '100%',
    height: '100%',
  },
}));
