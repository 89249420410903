import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, Menu, Stack } from '@mui/material';
import { FC, MouseEvent, useState } from 'react';
import { StyledMenuItem } from '../../Table.styles';
import { MenuItemAction } from '../../Table.types';
import { columnMenuItems } from './ColumnMenu.constants';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { GridRowId, GridValidRowModel, useGridApiContext } from '@mui/x-data-grid-premium';
import { useDeleteLSRFilesMutation, useDownloadLSRFilesMutation, useUpdateLSRFilesStatusMutation } from 'api/lsr';
import { useProjectId } from 'hooks/useProjectId';
import { LSRFileStatus } from 'types';
import AddStatusModal from '../AddStatusModal/AddStatusModal';
import { useUpdateTableLoading } from '../../hooks';
import { downloadBase64File } from '../../../../../../utils/downloadBase64File';


export const ColumnMenu: FC = () => {
  const projectID = useProjectId();

  const [deleteLSRFiles, { isLoading: isDeleteFilesLoading, isUninitialized: isUninitializedDeleteFiles }] =
    useDeleteLSRFilesMutation();
  const [updateLSRFiles, { isLoading: isUpdateFilesLoading, isUninitialized: isUninitializedUpdateFiles }] =
    useUpdateLSRFilesStatusMutation();
  const [downloadLSRFiles, { isLoading: isDownloadFilesLoading, isUninitialized: isUninitializedDownloadFiles }] =
    useDownloadLSRFilesMutation();

  useUpdateTableLoading(isDeleteFilesLoading, isUninitializedDeleteFiles);
  useUpdateTableLoading(isUpdateFilesLoading, isUninitializedUpdateFiles);

  const { getSelectedRows,setRowSelectionModel,selectRows } = useGridApiContext().current;

  const [isVisibleStatusesModal, setIsVisibleStatusesModal] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<LSRFileStatus | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const openMenuClickHandler = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const downloadFiles = () => {
    const rows = getSelectedRows();
    const fileIDS: number[] = [];

    for (const [_, value] of rows.entries()) {
      fileIDS.push(value.id);
    }

    downloadLSRFiles({ projectID, fileIDS })
      //@ts-ignore
      .then(({ data }) => {
        downloadBase64File(data, 'download.zip')
      });
  }

  const menuItemClickHandler = (action: MenuItemAction) => {
    switch (action) {
      case 'status': {
        setIsVisibleStatusesModal(true);
        break;
      }
      case 'download': {
        downloadFiles()
        break;
      }
      case 'delete': {
        openConfirmDelete();
        break;
      }
    }

    closeMenuHandler();
  };

  const closeMenuHandler = () => {
    setAnchorEl(null);
  };

  const handleSelectStatus = (status: LSRFileStatus) => {
    setSelectedStatus(status);
  };

  const addStatuses = () => {
    if (selectedStatus) {
      const rows = getSelectedRows();
      const fileIDS: number[] = [];

      for (const [_, value] of rows.entries()) {
        fileIDS.push(value.id);
      }

      updateLSRFiles({
        params: { projectID },
        body: { data: fileIDS.map((id) => ({ fileID: id, status: selectedStatus })) },
      }).then(() => {
        setRowSelectionModel([])
      });

      setIsVisibleStatusesModal(false);
    }
  };

  const deleteFiles = (confirm: boolean) => {
    if (confirm) {
      const rows = getSelectedRows();
      const fileIDS: number[] = [];

      for (const [_, value] of rows.entries()) {
        fileIDS.push(value.id);
      }

      deleteLSRFiles({ projectID, fileIDS });
    }
  };

  const { openConfirm: openConfirmDelete, ConfirmDialog: ConfirmDialogDelete } = useConfirmDialog({
    title: 'Удалить файлы?',
    body: 'Выбранные файлы будут удалены безвозвратно',
    confirmButtonText: 'Да',
    denyButtonText: 'Нет',
    handleConfirm: deleteFiles,
  });

  return (
    <>
      <Box>
        <IconButton color="inherit" onClick={openMenuClickHandler}>
          <MoreVert />
        </IconButton>
        <Menu anchorEl={anchorEl} open={open} onClose={closeMenuHandler}>
          {columnMenuItems.map((menuItem) => (
            <StyledMenuItem
              key={menuItem.name}
              onClick={() => menuItemClickHandler(menuItem.action)}
              disabled={!getSelectedRows().size}>
              <Stack direction="row" alignItems="center" spacing={2}>
                {menuItem.icon}
                <span>{menuItem.name}</span>
              </Stack>
            </StyledMenuItem>
          ))}
        </Menu>
      </Box>

      <AddStatusModal
        handleSelectStatus={handleSelectStatus}
        open={isVisibleStatusesModal}
        selectedStatus={selectedStatus}
        addStatuses={addStatuses}
        close={() => setIsVisibleStatusesModal(false)}
      />
      <ConfirmDialogDelete />
    </>
  );
};
