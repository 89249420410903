import styled from 'styled-components'

interface AccessStatusBlockProps {
  color?: string
}

export const AccessStatusBlock = styled.div<AccessStatusBlockProps>`
  align-self: flex-end;
  max-width: 276px;
  width: 100%;
  padding: 9px 16px;
  text-align: left;
  background-color: ${props => props.theme.palette.bg.shades};
  border: 1px solid rgba(92, 110, 140, 0.3);
  border-radius: 6px;
  
  & .MuiTypography-root {
    color: ${props => props.color || props.theme.palette.primary.main};
  }
`
