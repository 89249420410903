import * as yup from "yup";

export const validationForm = yup.object({
  //title: yup.string().trim().required(`common:${""}${""}`),
  // targetID: yup.number().required(`common:errors.required`),
  // title: yup.string().trim().required("common:errors.required"),
  // chapter: yup.string().trim().required(`common:errors.required`),
  // quantity: yup
  //   .string()
  //   .matches(
  //     /^(0$|-?[1-9]\d*([\.\,]\d*[1-9]$)?|-?0[\.\,]\d*[1-9])$/,
  //     `common:errors.onlyNumber`
  //   ),
    // .required(`common:errors.required`),
  // unit: yup.string().trim().required(`common:${""}${""}`),
  // header: yup.string().trim().required(`common:errors.required`),
});
