import { CalcData } from '../../../../types';
import useBreadcrumbs from '../../../../hooks/useBreadcrumbs';
import { useProjectId } from '../../../../hooks/useProjectId';
import { useCalcId } from '../../../../hooks/useCalcId';
import React, { createContext, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { ColDef, ColGroupDef, ICellRendererParams, RowClassParams } from 'ag-grid-community';
import Cell from '../Accomplishment/components/Cell';
import { PageStyled, WrapperAgGrid } from '../Accomplishment/Accomplishment.styles';
import { AgGridReact } from 'ag-grid-react';
import Progress from '../../../../components/Progress';
import { useGetTanglTableQuery } from '../../../../api/tangl';
import { NoRows } from '../../../Administration/AdminReferences/Prices/useTableData';
import { ITangleTableResponse } from '../../../../api/tangl/tangl.types';
import { Box, Menu, MenuItem } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Button from '../../../../components/Button';
import { useDeleteCalculationMutation } from '../../../../api/calculations';
import useConfirmDialog from '../../../../hooks/useConfirmDialog';
import { deleteData, openDB } from '../../../../utils/indexDB';
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Level from './components/Level';

/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
interface ITanglTable {
  calculation?: CalcData;
  openEdit: () => void;
}

interface ITangleTableContext {
  collapse?: (id: number) => void;
  hiddenRowsIds?: number[];
}
export const TangleTableContext = createContext<ITangleTableContext>({});
const TanglTable: React.FC<ITanglTable> = ({ calculation, openEdit }) => {
  const projectID = useProjectId();
  const calcId = useCalcId();
  const { data, isFetching } = useGetTanglTableQuery(calcId, { skip: !calcId });
  const Ref = useRef<any>(null);
  const { t } = useTranslation('user');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [deleteCalculation, deleteCalculationResponse] = useDeleteCalculationMutation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [hiddenRowsIds, setHiddenRowsIds] = useState<number[]>([]);
  const [filteredData, setFilteredData] = useState<ITangleTableResponse[] | undefined>(undefined);

  const handleBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const handleConfirmDelete = useCallback((confirm: boolean) => {
    if (confirm) {
      deleteCalculation({ projectID, calcID: calculation?.id || 0 }).then(() => {
        openDB().then((db) => deleteData(db, calculation!.id));
      });
    }
  }, []);
  const handleDeleteCalculationClick = useCallback(
    (actionName: string) => {
      openConfirmDelete(actionName);
    },
    [calculation?.id],
  );

  const { ConfirmDialog: ConfirmDiaologDelete, openConfirm: openConfirmDelete } = useConfirmDialog({
    title: 'Вы уверены?',
    body: 'Расчёт будет удалён и восстановить его будет невозможно',
    handleConfirm: handleConfirmDelete,
  });

  const MenuOpen = Boolean(anchorEl);

  const columnsDef: (ColDef<ITangleTableResponse, any> | ColGroupDef<ITangleTableResponse>)[] = useMemo(() => {
    return [
      {
        field: 'lvl',
        headerName: 'Уровень',
        width: 120,
        cellRenderer: (params: ICellRendererParams<ITangleTableResponse, any, any>) => {
          return <Level {...params} />;
        },
      },
      {
        field: 'catalog',
        headerName: 'Справочник',
        width: 140,
        cellRenderer: (params: any) => {
          return (
            <Cell
              color={params.data.lvl === 1 || params.data.lvl === 0 ? '#0044B4' : undefined}
              weight={params.data.lvl === 1 || params.data.lvl === 0}
              float={'center'}>
              {params.value}
            </Cell>
          );
        },
      },
      {
        field: 'code',
        headerName: 'Номер позици',
        width: 140,

        cellRenderer: (params: any) => {
          return (
            <Cell
              color={params.data.lvl === 1 || params.data.lvl === 0 ? '#0044B4' : undefined}
              weight={params.data.lvl === 1 || params.data.lvl === 0}
              float={'center'}>
              {params.value}
            </Cell>
          );
        },
      },
      {
        field: 'name',
        headerName: 'Наименование',
        width: 452,

        cellRenderer: (params: any) => {
          return (
            <Cell
              color={params.data.lvl === 1 || params.data.lvl === 0 ? '#0044B4' : undefined}
              weight={params.data.lvl === 1 || params.data.lvl === 0}>
              {params.value}
            </Cell>
          );
        },
      },
      {
        field: 'unit',
        headerName: 'Ед. изм.',
        width: 203,
        cellRenderer: (params: any) => {
          return (
            <Cell
              color={params.data.lvl === 1 || params.data.lvl === 0 ? '#0044B4' : undefined}
              weight={params.data.lvl === 1 || params.data.lvl === 0}
              float={'center'}>
              {params.value}
            </Cell>
          );
        },
      },
      {
        field: 'qty',
        headerName: 'Количество',
        width: 203,
        cellRenderer: (params: any) => {
          return (
            <Cell
              color={params.data.lvl === 1 || params.data.lvl === 0 ? '#0044B4' : undefined}
              weight={params.data.lvl === 1 || params.data.lvl === 0}
              isHide={!params.value}
              float={'center'}>
              {params.value?.toLocaleString('ru-RU', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Cell>
          );
        },
      },
      {
        field: 'price',
        headerName: 'Цена за единицу',
        width: 203,
        cellRenderer: (params: any) => {
          return (
            <Cell
              color={params.data.lvl === 1 || params.data.lvl === 0 ? '#0044B4' : undefined}
              weight={params.data.lvl === 1 || params.data.lvl === 0}
              isHide={!params.value}
              float={'right'}>
              {params.value?.toLocaleString('ru-RU', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Cell>
          );
        },
      },
      {
        field: 'cost',
        headerName: 'Стоимость',
        width: 203,
        cellRenderer: (params: any) => {
          return (
            <Cell
              color={params.data.lvl === 1 || params.data.lvl === 0 ? '#0044B4' : undefined}
              weight={params.data.lvl === 1 || params.data.lvl === 0}
              isHide={!params.value}
              float={'right'}>
              {params.value?.toLocaleString('ru-RU', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Cell>
          );
        },
      },
      {
        field: 'total',
        headerName: 'Полная стоимость',
        width: 203,
        cellRenderer: (params: any) => {
          return (
            <Cell
              color={params.data.lvl === 1 || params.data.lvl === 0 ? '#0044B4' : undefined}
              weight={params.data.lvl === 1 || params.data.lvl === 0}
              isHide={!params.value}
              float={'right'}>
              {params.value?.toLocaleString('ru-RU', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Cell>
          );
        },
      },
    ];
  }, [data]);

  const rowTopPinned: ITangleTableResponse[] = useMemo(() => {
    return [
      {
        lvl: 0,
        catalog: '',
        code: null,
        cost: null,
        children: null,
        type: 'total',
        name: null,
        id: -1,
        qty: null,
        parentID: null,
        price: null,
        unit: null,
        total: data?.total ?? 0,
      },
    ];
  }, [data?.total]);

  const collapse = (id: number) => {
    // if (setHiddenRowsIds) {
    setHiddenRowsIds((d) => {
      const copy = [...d];
      const addIndex = copy.findIndex((hrId) => hrId === id);
      if (addIndex >= 0) {
        copy.splice(addIndex, 1);
      } else {
        copy.push(id);
      }
      return copy;
    });
    // }
  };
  useMutationHandlers(deleteCalculationResponse, () => {
    navigate(`/projects/${projectID}/calculations`);
    enqueueSnackbar(t('success.calculationDelete'), {
      variant: 'success',
    });
  });

  useBreadcrumbs(
    [
      {
        title: 'Расчеты',
        url: `/projects/${projectID}/calculations`,
      },
      {
        title: calculation?.title ?? 'Предпросмотр',
        url: `projects/${projectID}/calculation/${calculation?.id}/edit`,
      },
    ],
    [calculation?.title],
  );

  useEffect(() => {
    if (!isFetching) {
      setFilteredData(() => {
        if (data?.data) {
          const indexes: number[] = [];
          const filledArr = data?.data.reduce((acc: ITangleTableResponse[], curr) => {
            const newItem: ITangleTableResponse = Object.assign({}, curr);
            let idx = false;
            if (newItem && newItem.parentID && newItem.id) {
              idx = hiddenRowsIds.includes(newItem.parentID) || indexes.includes(newItem.id);
              if (idx && newItem.children?.length) {
                indexes.push(...newItem.children.map((item) => item.id));
              }
            }
            return !idx ? [...acc, newItem] : acc;
          }, []);

          Ref.current?.api.hideOverlay();

          // setUpdateLoader(false);
          return filledArr?.length ? filledArr : data.data;
        } else {
          Ref.current?.api.hideOverlay();
          // setUpdateLoader(false);
          return [];
        }
      });
    }
  }, [hiddenRowsIds, data?.data, isFetching, Ref.current]);

  useEffect(() => {
    if (filteredData?.length && Ref.current) {
      // gridRef?.api?.applyTransaction({ update: filteredData });
      Ref.current?.api?.refreshCells({ force: true });
    }
  }, [filteredData, Ref.current]);

  useLayoutEffect(() => {
    Ref.current?.api?.showLoadingOverlay();
    if (data?.data) {
      setHiddenRowsIds((d) => {
        const copy = [...d];
        if (!copy.length) {
          data.data
            .filter((item) => item.type === 'folder' || item.type === 'work')
            .forEach((file) => copy.push(file.id));
        }
        return copy;
      });
    }
  }, [data?.data]);

  return (
    <TangleTableContext.Provider value={{ collapse, hiddenRowsIds }}>
      <Box display={'flex'} flexDirection={'row-reverse'} alignItems={'center'} pt={'10px'}>
        <Button
          onClick={handleBtnClick}
          style={{
            padding: '8px',
            minWidth: '40px',
          }}>
          <MoreHorizIcon />
        </Button>
      </Box>

      <PageStyled>
        <span />
        <WrapperAgGrid className="ag-theme-material accompishment">
          {/*{isLoading && <Progress />}*/}
          <AgGridReact
            ref={Ref}
            enableCellTextSelection={true}
            ensureDomOrder={true}
            maintainColumnOrder={true}
            suppressScrollOnNewData={true}
            defaultColDef={{
              resizable: false,
            }}
            columnDefs={columnsDef}
            gridOptions={{
              // suppressDragLeaveHidesColumns: true,
              navigateToNextHeader: () => null,
              tabToNextHeader: () => null,
            }}
            pinnedTopRowData={rowTopPinned}
            rowData={filteredData}
            suppressCellFocus={true}
            // onFirstDataRendered={(event) => {
            //   event.api.sizeColumnsToFit();
            // }}
            // onGridSizeChanged={(event: GridSizeChangedEvent) => {
            //   event.api.sizeColumnsToFit();
            // }}
            // onViewportChanged={(event) => {
            //   event.api.sizeColumnsToFit();
            // }}
            // getRowId={(params) => {
            //   return params.data.id.toString();
            // }}
            // getRowHeight={(params) => {
            //   if (params.node.rowPinned === 'top') {
            //     return 50;
            //   }
            //   return 55;
            // }}
            rowStyle={{
              padding: '0 !important',
            }}
            getRowStyle={(params: RowClassParams<any>) => {
              if (params.data.lvl === 0) {
                return { background: '#1976D214' };
              }
              return { background: 'inherit' };
            }}
            rowHeight={55}
            headerHeight={80}
            loadingOverlayComponent={Progress}
            noRowsOverlayComponent={NoRows}></AgGridReact>
        </WrapperAgGrid>
      </PageStyled>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={MenuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem onClick={() => handleDeleteCalculationClick('Delete')} disabled={deleteCalculationResponse.isLoading}>
          Удалить расчет
        </MenuItem>
        <MenuItem
          onClick={() => {
            openEdit();
            setAnchorEl(null);
          }}>
          Редактировать
        </MenuItem>
      </Menu>
      <ConfirmDiaologDelete />
    </TangleTableContext.Provider>
  );
};

export default TanglTable;
