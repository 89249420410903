import { Box, Tooltip } from '@mui/material';
import { FormikProvider, Form } from 'formik';
import { FocusEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useEditComplexRowMutation } from '../../../../../../../../api/calculations';
import { BasePricesItem } from '../../../../../../../../api/calculations/types';
import { ReactComponent as RowIcon } from '../../../../../../../../assets/icons/LeveLicon/RowIcon.svg'; // assets/icons/LeveLicon/ FirstLevelicon.svg
import { useForm } from '../../../../../../../../hooks/useForm';
import {
  StyledRow,
  StyledContractStack,
  StyledFieldFormMin,
  StyledTitleNormative,
  StyledTypographyRow,
  StyledRowTd,
} from '../styles';
import { LaborIntensityEditFormData } from './LaborIntensityEditForm.types';
import { validationNormativeRow } from './validation';

type LaborIntensityRowPropsType = {
  row: BasePricesItem;
  calculationId: number;
};

export const LaborIntensityRow: React.FC<LaborIntensityRowPropsType> = ({ row, calculationId }) => {
  const {
    hourlyCostGen,
    hourlyCostSub,
    machinesPerHourGen,
    machinesPerHourSub,
    peoplePerHourGen,
    peoplePerHourSub,
    title,
    id: rowId,
  } = row;

  /********************************* Edit Row ******************************/

  const [editRow, editRowResponse] = useEditComplexRowMutation();

  const initialValues: LaborIntensityEditFormData = useMemo(
    () => ({
      hourlyCostGen: hourlyCostGen ?? 0,
      hourlyCostSub: hourlyCostSub ?? 0,
      machinesPerHourGen: machinesPerHourGen ?? 0,
      machinesPerHourSub: machinesPerHourSub ?? 0,
      peoplePerHourGen: peoplePerHourGen ?? 0,
      peoplePerHourSub: peoplePerHourSub ?? 0,
      title,
    }),
    [] //calculation
  );
  const { formik } = useForm({
    validationSchema: validationNormativeRow,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values: LaborIntensityEditFormData) => {
      onSubmit(values);
    },
  });

  const onSubmit = useCallback(
    (values: LaborIntensityEditFormData) => {
      editRow({
        calculationId,
        rowId,
        hourlyCostGen: parseFloat(
          values.hourlyCostGen.toString().replace(',', '.').replace(' ', '')
        ),
        hourlyCostSub: parseFloat(
          values.hourlyCostSub.toString().replace(',', '.').replace(' ', '')
        ),
        machinesPerHourGen: parseFloat(
          values.machinesPerHourGen.toString().replace(',', '.').replace(' ', '')
        ),
        machinesPerHourSub: parseFloat(
          values.machinesPerHourSub.toString().replace(',', '.').replace(' ', '')
        ),
        peoplePerHourGen: parseFloat(
          values.peoplePerHourGen.toString().replace(',', '.').replace(' ', '')
        ),
        peoplePerHourSub: parseFloat(
          values.peoplePerHourSub.toString().replace(',', '.').replace(' ', '')
        ),
      });
    },
    [] //calculation
  );
  const { values, handleSubmit } = formik;
  const formRef = useRef<HTMLFormElement>(null);

  /************************************** onKeyDown Enter **********************************/

  const enterPressHandler = () => {
    handleSubmit();
  };

  /************************************** Out number *****************************************/

  let valuePeoplePerHourGen = values.peoplePerHourGen.toString().replace('.', ',').replace(' ', '');

  let valueMachinesPerHourGen = values.machinesPerHourGen
    .toString()
    .replace('.', ',')
    .replace(' ', '');

  let valueHourlyCostGen = values.hourlyCostGen.toString().replace('.', ',').replace(' ', '');

  let valuePeoplePerHourSub = values.peoplePerHourSub.toString().replace('.', ',').replace(' ', '');

  let valueMachinesPerHourSub = values.machinesPerHourSub
    .toString()
    .replace('.', ',')
    .replace(' ', '');

  let valueHourlyCostSub = values.hourlyCostSub.toString().replace('.', ',').replace(' ', '');

  const handleOnBlur = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    handleSubmit();
  };

  const handleChange = () => {
    setTimeout(function () {
      handleSubmit();
    }, 500);
  };

  /************************************************************************************/

  return (
    <Box className="LaborIntensityRow" sx={{ background: '#FFFFFF' }}>
      <FormikProvider value={formik}>
        <Form
          ref={formRef}
          onSubmit={formik.handleSubmit}
          onChange={handleChange}
          onKeyDown={(e: { key: string }) => {
            if (e.key === 'Enter') {
              enterPressHandler();
            }
          }}
          style={{
            display: 'grid',
            gridTemplateColumns:
              '100px minmax(400px,100%) minmax(636px,1fr) 64px minmax(636px,1fr)',
            gridAutoRows: '50px',
          }}
        >
          <StyledRowTd
            style={{
              paddingLeft: '13px',
              justifyContent: 'flex-start',
              height: '50px',
              minWidth: '60px',
            }}
          >
            <Box
              sx={{
                minWidth: '40px',
                marginRight: '16px',
              }}
            ></Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                minWidth: 24,
              }}
            >
              <RowIcon style={{ width: 16, height: 16 }} />
            </Box>
          </StyledRowTd>
          <StyledTitleNormative>
            <Tooltip title={title}>
              <StyledTypographyRow sx={{ marginLeft: '12px' }}>{title}</StyledTypographyRow>
            </Tooltip>
          </StyledTitleNormative>
          <StyledContractStack className="GeneralContract" direction="row">
            <StyledFieldFormMin
              onBlur={handleOnBlur}
              // onFocus={(event) => handleOnFocus(event)}
              value={valuePeoplePerHourGen}
              name="peoplePerHourGen"
            ></StyledFieldFormMin>
            {/* Норм. трудоемкость чел.- час */}
            <StyledFieldFormMin
              onBlur={handleOnBlur}
              //   onFocus={(event) => handleOnFocus(event)}
              value={valueMachinesPerHourGen}
              name="machinesPerHourGen"
            ></StyledFieldFormMin>
            {/* Норм. трудоемкость маш. час*/}
            <StyledFieldFormMin
              onBlur={handleOnBlur}
              //   onFocus={(event) => handleOnFocus(event)}
              value={valueHourlyCostGen}
              name="hourlyCostGen"
            ></StyledFieldFormMin>
            {/* Отпускная стоимость часа */}
          </StyledContractStack>
          <Box
            sx={{ background: '#FFFFFF', boxShadow: 'inset 0px -1px 0px rgba(0, 36, 95, 0.1)' }}
          ></Box>
          <StyledContractStack className="Subcontracting" direction="row">
            <StyledFieldFormMin
              onBlur={handleOnBlur}
              //  onFocus={(event) => handleOnFocus(event)}
              name="peoplePerHourSub"
              value={valuePeoplePerHourSub}
            ></StyledFieldFormMin>
            <StyledFieldFormMin
              onBlur={handleOnBlur}
              //    onFocus={(event) => handleOnFocus(event)}
              name="machinesPerHourSub"
              value={valueMachinesPerHourSub}
            ></StyledFieldFormMin>
            <StyledFieldFormMin
              onBlur={handleOnBlur}
              //onFocus={(event) => handleOnFocus(event)}
              name="hourlyCostSub"
              value={valueHourlyCostSub}
            ></StyledFieldFormMin>
          </StyledContractStack>
        </Form>
      </FormikProvider>
    </Box>
  );
};
