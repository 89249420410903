/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Box, IconButton } from "@mui/material";
import { FC, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  getCalculationDictionaryState
} from "../../../../../../store/slices/calculationDictionary/calculationDictionary";
import { getPricesState } from "../../../../../../store/slices/references/prices/prices";
import { PricesReferenceContext } from "../../PricesReference";

export interface MyCustomHeaderProps {
  menuIcon: string;
  displayName: string;
  column: {
    colDef: {
      field: string;
    };
  };
  context: any;
}
const HeaderComponent: FC<MyCustomHeaderProps> = (props) => {
  const { changeDialog } = useContext(PricesReferenceContext);
  const { activeFilters: activeFiltersPrice } = useSelector(getPricesState);
  const { activeFilters: activeFiltersCalculation } = useSelector(getCalculationDictionaryState);

  const activeFilters = useMemo(() => {
    return props?.context?.changeDialog ? activeFiltersCalculation : activeFiltersPrice;
  }, [props?.context?.changeDialog, activeFiltersCalculation, activeFiltersPrice]);

  const name = useMemo(() => {
    if (props.column.colDef.field === 'kfoName' || props.column.colDef.field === 'kfoGroups') {
      return 'groups';
    } else {
      return props.column.colDef.field + 's';
    }
  }, [props.column.colDef.field]);
  
  const isActive = useMemo(() => {
    return !!activeFilters?.[name === 'kfoGroups'?'groups':name as keyof typeof activeFilters]?.length;
  }, [name, activeFilters]);

  const onClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.column && props.column.colDef && props.column.colDef.field) {
      if (props.column.colDef.field === 'kfoName') {
        props?.context?.changeDialog?.('groups' as any) || changeDialog?.('groups' as any);
      } else {
        props?.context?.changeDialog?.((props.column.colDef.field + 's') as any) ||
          changeDialog((props.column.colDef.field + 's') as any);
      }
    }
  };
  return (
    <Box position={'relative'} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      {props.displayName}

      <Box position={'absolute'} right={5}>
        <IconButton tabIndex={-1} onClick={onClick}>
          <FilterAltIcon fontSize={'small'} sx={{ fill: isActive ? '#0044B4' : '#9AA2B0' }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default HeaderComponent;
