/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import { Controller, useFormContext } from 'react-hook-form';
import { IconButton, InputAdornment, InputLabel } from '@mui/material';
import { BaseTextField } from '../../../../../../components/BaseTextField';
import { ITangleData } from '../../../../../../api/tangl/tangl.types';
import { FC, useMemo, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface IName {
  name: string;
  key: 'credentials.clientID' | 'credentials.password' | 'credentials.secret' | 'credentials.username';
  type: 'text' | 'password';
}
interface IFieldBlock {
  names: IName[];
  disabled: boolean;
}

const FieldBlock: FC<IFieldBlock> = ({ names, disabled }) => {
  return (
    <div className={'flex'}>
      {names.map((prop, index) => (
        <Field key={index} disabled={disabled} prop={prop} />
      ))}
    </div>
  );
};
interface IField {
  prop: IName;
  disabled: boolean;
}
const Field: FC<IField> = ({ prop, disabled }) => {
  const { control } = useFormContext<Required<ITangleData>>();

  const [showPass, setShowPass] = useState(false);

  const Icon = useMemo(() => {
    if (prop.type === 'password') {
      if (showPass) {
        return <VisibilityIcon />;
      } else {
        return <VisibilityOffIcon />;
      }
    }
    return null;
  }, [showPass]);

  const changeShowingPass = () => {
    setShowPass((prevState) => !prevState);
  };

  return (
    <div>
      <Controller
        name={prop.key}
        control={control}
        render={({ field, fieldState }) => (
          <>
            <InputLabel error={!!fieldState.error} htmlFor={prop.key + '-api'}>
              {prop.name}
            </InputLabel>
            <BaseTextField
              error={!!fieldState.error}
              disabled={disabled}
              helperText={fieldState.error?.message}
              fullWidth
              InputProps={{
                ...(prop.type === 'password' && {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        disabled={disabled}
                        aria-label="toggle password visibility"
                        onClick={changeShowingPass}
                        edge="end">
                        {showPass ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }),
              }}
              id={prop.key + '-api'}
              type={prop.type === 'password' ? (showPass ? 'text' : 'password') : prop.type}
              placeholder={'Введите логин'}
              {...field}
            />
          </>
        )}
      />
    </div>
  );
};

export default FieldBlock;
