import { Box, Collapse, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { FC, useMemo, useState } from 'react';
import { getFormatNumber } from 'utils/getFormatNumber';
import { StyledArrow } from './Row.styles';
import { GroupedSubRowsRowsEntries, RowProps } from './Row.types';
import { GroupedRow, SplittedRow } from './components';

export const Row: FC<RowProps> = ({ row, tableColumns }) => {
  const [open, setOpen] = useState(false);

  const groupedRowsByTag = useMemo(() => _.groupBy(row?.rows, 'type'), [row?.rows]);

  const setOpenHandler = () => {
    setOpen((prev) => !prev);
  };
  const headerWidth = useMemo(
    () =>
      tableColumns.reduce((accumulator, current) => {
        if (current.field === 'title') {
          return current?.width ? accumulator + ' ' + `minmax(${current.width}px,auto)` : accumulator;
        } else {
          return current?.width ? accumulator + ' ' + current.width + 'px' : accumulator;
        }
      }, ''),
    [tableColumns],
  );

  return (
    <Box>
      <div
        onClick={setOpenHandler}
        style={{
          display: 'grid',
          alignItems: 'center',
          gridTemplateColumns: headerWidth,
          height: '44px',
          background: '#e8f1fb',
          borderBottom: '1px solid #D2DCFF',
        }}>
        <div
          style={{ padding: '12px 16px 12px 20px', gridArea: '1 / 1 / 2 / 7 ', display: 'flex', alignItems: 'center' }}>
          <StyledArrow $open={open} />
          <Stack direction="row" justifyContent="space-between" pl={2} spacing={1}>
            <Typography variant="body2" color="primary.main">
              {row?.title}
            </Typography>
          </Stack>
        </div>
        {/*<StyledBlueTotal>*/}
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <div />
          <Typography variant="body2" color="primary.main" textAlign="center" paddingLeft={1}>
            {getFormatNumber(row?.baseTotal)}
          </Typography>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <div />
          {row?.currTotal && (
            <Typography variant="body2" color="primary.main" textAlign="center" paddingLeft={1}>
              {getFormatNumber(row?.currTotal)}
            </Typography>
          )}
        </div>
        <div />
        <div />
        <div />
        <div />
      </div>

      <Collapse in={open}>
        {(Object.entries(groupedRowsByTag) as GroupedSubRowsRowsEntries)
          .sort()
          .reverse()
          .map(([key, subRows]) => (
            <GroupedRow key={key} rows={subRows} type={key} tableColumns={tableColumns} />
          ))}

        {row?.splitRows.length > 0 && (
          <SplittedRow tableColumns={tableColumns} splittedRows={row.splitRows} groupedRowByTag={groupedRowsByTag} />
        )}
      </Collapse>
    </Box>
  );
};
