import styled from 'styled-components';
import { Stack , Box, Typography } from '@mui/material';


export const StyledTypography = styled(Typography)`
  color: ${props => props.theme.palette.text.main};
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  font-weight: 500;
`
export const StyledLevel = styled(Stack)`
  align-items: center;
  height: 50px;
  width: 100%;
  display: grid;
  grid-auto-flow: column dense;
  grid-template-rows: 1fr;
  gap: 0px 0px;
`
export const StyledTitle = styled(Stack)`
  align-items: start;
  min-width: 400px;
  width: 100%;
  height: 100%;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
`

export const StyledLevelTd= styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
  height: 100%;
`