import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Button, ClickAwayListener, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { HeaderProps } from './Header.types';
import { DropDown } from './components';
import { StyledTooltip } from '../../../Accomplishment/components/CellEditorSergey/CellEditor.style';

export const Header: FC<HeaderProps> = ({
  selectedValues,
  isResetButton,
  disabledButton,
  disableSelect,
  onSetSelectedValues,
  onSetFormPositions,
  onResetFormPositions,
}) => {
  const [open, setOpen] = useState(false);

  const handleTooltip = (b: boolean) => {
    setOpen(b);
  };
  return (
    <Stack
      sx={{ borderBottom: '1px solid #d2dcff', background: '#FFF', zIndex: 10 }}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      padding="7px 20px 7px 13px"
      position="sticky"
      spacing={2}
      top={0}>
      {/*<Stack direction="row" alignItems="center" spacing={2}>*/}
      <DropDown selectedValues={selectedValues} onSetSelectedValues={onSetSelectedValues} disabled={disableSelect} />
      <Button
        color={isResetButton && !disabledButton ? 'warning' : 'success'}
        size="small"
        disableElevation
        disabled={disabledButton}
        onClick={isResetButton ? onResetFormPositions : onSetFormPositions}>
        {isResetButton && !disabledButton ? 'Сбросить' : 'Сформировать'}
      </Button>
      {/*</Stack>*/}
      <ClickAwayListener onClickAway={() => handleTooltip(false)}>
        <StyledTooltip
          onClose={() => handleTooltip(false)}
          open={open}
          PopperProps={{ disablePortal: false }}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement={'top'}
          title={
            <Typography color="white" fontSize={12}>
              Для отображения сводного расчета выберите интервал формирования ведомостей
            </Typography>
          }>
          <HelpOutlineIcon cursor={'pointer'} onClick={() => handleTooltip(true)} color={'info'} fontSize="small" />
        </StyledTooltip>
      </ClickAwayListener>
      {/*<Stack direction="row" alignItems="center" spacing={1} color="info.main">*/}
      {/*  <Typography color="info.main" fontSize={12}>*/}
      {/*    Для отображения сводного расчета выберите интервал формирования ведомостей*/}
      {/*  </Typography>*/}
      {/*</Stack>*/}
    </Stack>
  );
};
