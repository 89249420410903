import { fullVorColumns } from '../../Table.service';
import { RowMenu } from '../TreeNodeRow/components';
import { HeadStyled, HeadTdStyled } from './Head.styles';

export const Head: React.FC = () => {
  return (
    <HeadStyled>
      <HeadTdStyled>
        <RowMenu />
      </HeadTdStyled>
      <HeadTdStyled>№</HeadTdStyled>
      {fullVorColumns.map((column) => (
        <HeadTdStyled key={column.id} $align={column.align}>
          {column.title}
        </HeadTdStyled>
      ))}
    </HeadStyled>
  );
};
