import styled from 'styled-components'
import { FormTitledWrapper } from '../FormLeftBlock/FormLeftBlock.styles'

export const ImagesTitledWrapper = styled(FormTitledWrapper)`
  width: fit-content;
  
  & .stack-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
    
    & > div {
      margin: 0;
    }
  }
`
