import { Box } from '@mui/material';
import { Coefficients } from '../../../../../../../../api/calculations/types';
import {
  StyledContractTd,
  StyledTypographyHeadTd,
  StyledCoefficient,
  StyledCoefficients,
  StyledGencontract,
  StyledGencontractColumns,
  StyledGencontractTitle,
  StyledGenIncludingLaborIntensity,
  StyledGenIncludingMaterials,
} from '../../styles';

export type GencontractProps = {
  countColumnsAnalysis: string;
  isShowAnalysis: boolean;
  coefficients: Coefficients;
};
export const Gencontract: React.FC<GencontractProps> = ({
  countColumnsAnalysis,
  isShowAnalysis,
  coefficients,
}) => {
  const {
    mainSalary,
    mimExploitation,
    materials,
    basicMaterials,
    auxiliaryMaterials,
    equipment,
    overheads,
    estimatedProfit,
    machineOperatorSalary,
  } = coefficients!;
  return (
    <StyledGencontract className="gencontract-header">
      <StyledGencontractTitle className="gencontractTitle StyledGencontractTitle" sx={{ background: '#0044B4' }} >
        Генподряд
      </StyledGencontractTitle>
      <StyledGencontractColumns
        style={{ gridTemplateColumns: `repeat(${countColumnsAnalysis},170px)`, }}
        className="gencontractColumns-header"
      >
        <StyledContractTd
          className="gen-total"
          style={{ gridArea: 'gen-total', boxShadow: 'rgb(0 26 77 / 8%) 1px 0px 0px inset' }}
        >
          ВСЕГО
        </StyledContractTd>
        {!isShowAnalysis && (
          <>
            <StyledContractTd
              className="mainSalary"
              style={{
                gridArea: 'gen-mainSalary',
                background: '#7890B2',
                //  boxShadow: 'rgb(0 26 77 / 8%) 0px 0px 0px inset',
              }}
            >
              <StyledTypographyHeadTd>Основная заработная плата</StyledTypographyHeadTd>
              <StyledCoefficients direction="row">
                <StyledCoefficient
                  style={{
                    background: `${mainSalary.firstGen === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                  }}
                >
                  {(mainSalary.firstGen ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
                <StyledCoefficient
                  style={{
                    background: `${mainSalary.secondGen === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                  }}
                >
                  {(mainSalary.secondGen ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
                <StyledCoefficient
                  style={{
                    background: `${mainSalary.thirdGen === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                  }}
                >
                  {(mainSalary.thirdGen ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
              </StyledCoefficients>
            </StyledContractTd>
            <StyledContractTd
              className="mimExploitation"
              style={{
                gridArea: 'gen-mimExploitation',
                paddingTop: '20px',
                boxShadow: 'rgb(0 26 77 / 8%) 1px 0px 0px inset',
              }}
            >
              <StyledTypographyHeadTd>
                Эксплуатация <br /> МиМ
              </StyledTypographyHeadTd>
              <StyledCoefficients direction="row">
                <StyledCoefficient
                  style={{
                    background: `${mimExploitation.firstGen === null ? 'rgba(43, 54, 72, 0.1)' : ''
                      }`,
                  }}
                >
                  {(mimExploitation.firstGen ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
                <StyledCoefficient
                  style={{
                    background: `${mimExploitation.secondGen === null ? 'rgba(43, 54, 72, 0.1)' : ''
                      }`,
                  }}
                >
                  {(mimExploitation.secondGen ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
                <StyledCoefficient
                  style={{
                    background: `${mimExploitation.thirdGen === null ? 'rgba(43, 54, 72, 0.1)' : ''
                      }`,
                  }}
                >
                  {(mimExploitation.thirdGen ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
              </StyledCoefficients>
            </StyledContractTd>
            <StyledContractTd
              className="machineOperatorSalary"
              style={{
                gridArea: 'gen-machineOperatorSalary',
                boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)',
                padding: '0',
              }}
            >
              <Box
                className="materialsTitle"
                sx={{
                  boxShadow: 'inset 0px -1px 0px rgba(0, 36, 95, 0.1)',
                  width: '100%',
                }}
              >
                в том числе
              </Box>
              <StyledTypographyHeadTd sx={{ boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)' }}>
                Зарплата <br /> механизаторов
              </StyledTypographyHeadTd>
              <StyledCoefficients
                direction="row"
                sx={{
                  padding: '4px 8px',
                  boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)',
                }}
              >
                <StyledCoefficient
                  style={{
                    background: `${machineOperatorSalary.firstGen === null ? 'rgba(43, 54, 72, 0.1)' : ''
                      }`,
                  }}
                >
                  {(machineOperatorSalary.firstGen ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
                <StyledCoefficient
                  style={{
                    background: `${machineOperatorSalary.secondGen === null ? 'rgba(43, 54, 72, 0.1)' : ''
                      }`,
                  }}
                >
                  {(machineOperatorSalary.secondGen ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
                <StyledCoefficient
                  style={{
                    background: `${machineOperatorSalary.thirdGen === null ? 'rgba(43, 54, 72, 0.1)' : ''
                      }`,
                  }}
                >
                  {(machineOperatorSalary.thirdGen ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
              </StyledCoefficients>
            </StyledContractTd>
            <StyledContractTd
              className="materials"
              style={{
                gridArea: 'gen-materials',
                paddingTop: '20px',
                boxShadow: 'rgb(0 26 77 / 8%) 1px 0px 0px inset',
              }}
            >
              <StyledTypographyHeadTd>Материалы</StyledTypographyHeadTd>
              <StyledCoefficients direction="row">
                <StyledCoefficient
                  style={{
                    background: `${materials.firstGen === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                  }}
                >
                  {(materials.firstGen ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
                <StyledCoefficient
                  style={{
                    background: `${materials.secondGen === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                  }}
                >
                  {(materials.secondGen ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
                <StyledCoefficient
                  style={{
                    background: `${materials.thirdGen === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                  }}
                >
                  {(materials.thirdGen ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
              </StyledCoefficients>
            </StyledContractTd>
            <StyledGenIncludingMaterials
              className="includingMaterials"
              style={{ gridArea: 'gen-includingMaterials', background: '#6F88AB' }}
            >
              <Box
                className="gen-materialsTitle"
                sx={{
                  gridArea: 'gen-materialsTitle',
                  boxShadow: 'inset 0px -1px 0px rgba(0, 36, 95, 0.1)',
                }}
              >
                в том числе
              </Box>
              <StyledContractTd
                className="gen-basicMaterials"
                style={{
                  gridArea: 'gen-basicMaterials',
                  justifyContent: 'space-between',
                  paddingTop: '6px',
                  boxShadow: 'rgb(0 26 77 / 8%) 1px 0px 0px inset',
                }}
              >
                <StyledTypographyHeadTd style={{ height: '32px' }}>Основные</StyledTypographyHeadTd>
                <StyledCoefficients direction="row">
                  <StyledCoefficient
                    style={{
                      background: `${basicMaterials.firstGen === null ? 'rgba(43, 54, 72, 0.1)' : ''
                        }`,
                    }}
                  >
                    {(basicMaterials.firstGen ?? '').toLocaleString('ru-RU') || '-'}
                  </StyledCoefficient>
                  <StyledCoefficient
                    style={{
                      background: `${basicMaterials.secondGen === null ? 'rgba(43, 54, 72, 0.1)' : ''
                        }`,
                    }}
                  >
                    {(basicMaterials.secondGen ?? '').toLocaleString('ru-RU') || '-'}
                  </StyledCoefficient>
                  <StyledCoefficient
                    style={{
                      background: `${basicMaterials.thirdGen === null ? 'rgba(43, 54, 72, 0.1)' : ''
                        }`,
                    }}
                  >
                    {(basicMaterials.thirdGen ?? '').toLocaleString('ru-RU') || '-'}
                  </StyledCoefficient>
                </StyledCoefficients>
              </StyledContractTd>
              <StyledContractTd
                className="gen-auxiliaryMaterials"
                style={{
                  gridArea: 'gen-auxiliaryMaterials',
                  justifyContent: 'space-between',
                  paddingTop: '6px',
                  boxShadow: 'rgb(0 26 77 / 8%) 1px 0px 0px inset',
                }}
              >
                <StyledTypographyHeadTd style={{ height: '32px' }}>
                  Вспомогательные
                </StyledTypographyHeadTd>
                <StyledCoefficients direction="row">
                  <StyledCoefficient
                    style={{
                      background: `${auxiliaryMaterials.firstGen === null ? 'rgba(43, 54, 72, 0.1)' : ''
                        }`,
                    }}
                  >
                    {(auxiliaryMaterials.firstGen ?? '').toLocaleString('ru-RU') || '-'}
                  </StyledCoefficient>
                  <StyledCoefficient
                    style={{
                      background: `${auxiliaryMaterials.secondGen === null ? 'rgba(43, 54, 72, 0.1)' : ''
                        }`,
                    }}
                  >
                    {(auxiliaryMaterials.secondGen ?? '').toLocaleString('ru-RU') || '-'}
                  </StyledCoefficient>
                  <StyledCoefficient
                    style={{
                      background: `${auxiliaryMaterials.thirdGen === null ? 'rgba(43, 54, 72, 0.1)' : ''
                        }`,
                    }}
                  >
                    {(auxiliaryMaterials.thirdGen ?? '').toLocaleString('ru-RU') || '-'}
                  </StyledCoefficient>
                </StyledCoefficients>
              </StyledContractTd>
            </StyledGenIncludingMaterials>
            <StyledContractTd
              className="equipment"
              style={{
                gridArea: 'gen-equipment',
                background: '#7890B2',
                boxShadow: 'rgb(0 26 77 / 8%) 1px 0px 0px inset',
              }}
            >
              <StyledTypographyHeadTd>Оборудование</StyledTypographyHeadTd>
              <StyledCoefficients direction="row">
                <StyledCoefficient
                  style={{
                    background: `${equipment.firstGen === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                  }}
                >
                  {(equipment.firstGen ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
                <StyledCoefficient
                  style={{
                    background: `${equipment.secondGen === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                  }}
                >
                  {(equipment.secondGen ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
                <StyledCoefficient
                  style={{
                    background: `${equipment.thirdGen === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                  }}
                >
                  {(equipment.thirdGen ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
              </StyledCoefficients>
            </StyledContractTd>
            <StyledContractTd
              className="overheads"
              style={{
                gridArea: 'gen-overheads',
                background: '#7890B2',
                boxShadow: 'rgb(0 26 77 / 8%) 1px 0px 0px inset',
              }}
            >
              <StyledTypographyHeadTd>Накладные расходы</StyledTypographyHeadTd>
              <StyledCoefficients direction="row">
                <StyledCoefficient
                  style={{
                    background: `${overheads.firstGen === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                  }}
                >
                  {(overheads.firstGen ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
                <StyledCoefficient
                  style={{
                    background: `${overheads.secondGen === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                  }}
                >
                  {(overheads.secondGen ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
                <StyledCoefficient
                  style={{
                    background: `${overheads.thirdGen === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                  }}
                >
                  {(overheads.thirdGen ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
              </StyledCoefficients>
            </StyledContractTd>
            <StyledContractTd
              className="estimatedProfit"
              style={{
                gridArea: 'gen-estimatedProfit',
                background: '#7890B2',
                boxShadow: 'rgb(0 26 77 / 8%) 1px 0px 0px inset',
              }}
            >
              <StyledTypographyHeadTd>Сметная прибыль</StyledTypographyHeadTd>
              <StyledCoefficients direction="row">
                <StyledCoefficient
                  style={{
                    background: `${estimatedProfit.firstGen === null ? 'rgba(43, 54, 72, 0.1)' : ''
                      }`,
                  }}
                >
                  {(estimatedProfit.firstGen ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
                <StyledCoefficient
                  style={{
                    background: `${estimatedProfit.secondGen === null ? 'rgba(43, 54, 72, 0.1)' : ''
                      }`,
                  }}
                >
                  {(estimatedProfit.secondGen ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
                <StyledCoefficient
                  style={{
                    background: `${estimatedProfit.thirdGen === null ? 'rgba(43, 54, 72, 0.1)' : ''
                      }`,
                  }}
                >
                  {(estimatedProfit.thirdGen ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
              </StyledCoefficients>
            </StyledContractTd>
            <StyledGenIncludingLaborIntensity
              className="gen-includingLaborIntensity"
              style={{ gridArea: 'gen-includingLaborIntensity', background: '#7890B2' }}
            >
              <Box
                className="gen-laborIntensityTitle"
                sx={{
                  gridArea: 'gen-laborIntensityTitle',
                  background: '#7890B2',
                  boxShadow: 'inset 1px -1px 0px rgba(0, 36, 95, 0.1)',
                }}
              >
                Норматив трудоемкость
              </Box>
              <StyledContractTd
                className="gen-peoplePerHour"
                style={{
                  gridArea: 'gen-peoplePerHour',
                  background: '#7890B2',
                  paddingTop: '6px',
                  justifyContent: 'space-between',
                  boxShadow: 'rgb(0 26 77 / 8%) 1px 0px 0px inset',
                }}
              >
                <StyledTypographyHeadTd style={{ height: '32px' }}>
                  чел. -час
                </StyledTypographyHeadTd>
                <StyledCoefficients direction="row">
                  <StyledCoefficient
                    style={{
                      background: `${mainSalary.firstGen === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                    }}
                  >
                    {(mainSalary.firstGen ?? '').toLocaleString('ru-RU') || '-'}
                  </StyledCoefficient>
                  <StyledCoefficient
                    style={{
                      background: `${mainSalary.secondGen === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                    }}
                  >
                    {(mainSalary.secondGen ?? '').toLocaleString('ru-RU') || '-'}
                  </StyledCoefficient>
                  <StyledCoefficient
                    style={{
                      background: `${mainSalary.thirdGen === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                    }}
                  >
                    {(mainSalary.thirdGen ?? '').toLocaleString('ru-RU') || '-'}
                  </StyledCoefficient>
                </StyledCoefficients>
              </StyledContractTd>
              <StyledContractTd
                className="gen-machinesPerHour"
                style={{
                  gridArea: 'gen-machinesPerHour',
                  background: '#7890B2',
                  paddingTop: '6px',
                  justifyContent: 'space-between',
                  boxShadow: 'rgb(0 26 77 / 8%) 1px 0px 0px inset',
                }}
              >
                <StyledTypographyHeadTd style={{ height: '32px' }}>
                  маш. -час
                </StyledTypographyHeadTd>
                <StyledCoefficients direction="row">
                  <StyledCoefficient
                    style={{
                      background: `${mimExploitation.firstGen === null ? 'rgba(43, 54, 72, 0.1)' : ''
                        }`,
                    }}
                  >
                    {(mimExploitation.firstGen ?? '').toLocaleString('ru-RU') || '-'}
                  </StyledCoefficient>
                  <StyledCoefficient
                    style={{
                      background: `${mimExploitation.secondGen === null ? 'rgba(43, 54, 72, 0.1)' : ''
                        }`,
                    }}
                  >
                    {(mimExploitation.secondGen ?? '').toLocaleString('ru-RU') || '-'}
                  </StyledCoefficient>
                  <StyledCoefficient
                    style={{
                      background: `${mimExploitation.thirdGen === null ? 'rgba(43, 54, 72, 0.1)' : ''
                        }`,
                    }}
                  >
                    {(mimExploitation.thirdGen ?? '').toLocaleString('ru-RU') || '-'}
                  </StyledCoefficient>
                </StyledCoefficients>
              </StyledContractTd>
            </StyledGenIncludingLaborIntensity>
            <StyledContractTd
              className="hourlyCost"
              style={{
                gridArea: 'gen-hourlyCost',
                background: '#7890B2',
                boxShadow: 'rgb(0 26 77 / 8%) 1px 0px 0px inset',
              }}
            >
              <StyledTypographyHeadTd>Отпускная стоимость часа, без НДС</StyledTypographyHeadTd>
            </StyledContractTd>
          </>
        )}
      </StyledGencontractColumns>
    </StyledGencontract>
  );
};
