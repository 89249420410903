import React, { useCallback, useState } from 'react';
import { UserForm } from './components/UserForm';
import { UserManagementWrapper } from './UserManagement.styles';
import { useNavigate } from 'react-router-dom';
import { UserManagementProps } from './UserManagement.types';
import { UserTopBar } from './components/UserTopBar';
import { PublicUserProfile } from 'types';
import { useBanUserInModuleMutation } from 'api/users/users';
import { useMutationHandlers } from 'hooks/useMutationHandlers';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import useConfirmDialog, { UseExitConfirmProps } from 'hooks/useConfirmDialog';

export const UserManagement: React.FC<UserManagementProps> = ({ profile = {} as PublicUserProfile }) => {
  const { t } = useTranslation('user');
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const isEditUser = !!profile?.company;
  const userID = profile.id;
  const companyID = profile?.company?.companyID;

  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<'delete' | 'exit'>('exit');

  const onFormChange = (dirty: boolean) => {
    setIsFormDirty(dirty);
  };

  const [banUserInModule, banUserInModuleResponse] = useBanUserInModuleMutation();

  const handleExitConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      navigate('/administration/users');
    }
  }, []);

  const handleDeleteConfirm = useCallback((confirm: boolean) => {
    if (confirm && companyID) {
      banUserInModule({ userID, companyID });
    }
  }, []);

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    delete: {
      handleConfirm: handleDeleteConfirm,
      title: 'Удалить пользователя?',
      body: 'Пользователь будет удален из модуля Estimation.',
    },
    exit: {
      handleConfirm: handleExitConfirm,
    },
  };

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger]);

  const onReturnClick = useCallback(() => {
    setConfirmDialogTrigger('exit');

    isFormDirty ? openConfirm() : navigate('/administration/users');
  }, [isFormDirty]);

  const onDeleteClick = useCallback(() => {
    setConfirmDialogTrigger('delete');
    openConfirm();
  }, []);

  useMutationHandlers(banUserInModuleResponse, () => {
    navigate('/administration/users');
    enqueueSnackbar(t('success.profileDelete'), {
      variant: 'success',
    });
  });

  return (
    <>
      <UserManagementWrapper flex={1}>
        <UserTopBar onReturnClick={onReturnClick} onDeleteClick={onDeleteClick} isEditUser={isEditUser} />
        <UserForm userProfileToChange={profile} isEditUser={isEditUser} onFormChange={onFormChange} />
      </UserManagementWrapper>
      <ConfirmDialog />
    </>
  );
};
