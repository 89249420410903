/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box, createFilterOptions, Divider, IconButton, MenuItem } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { IEstimateReference } from "../../../../../../api/references/estimates/estimates.types";
import { PRStyledBaseTextField } from "../../../../../../components/BaseTextField/BaseTextField.styles";
import { getPricesState } from "../../../../../../store/slices/references/prices/prices";
import { UnitWeight } from "../../../../../../store/slices/references/prices/types";
import Cell from "../../../../../Calculations/components/Accomplishment/components/Cell";
import { PricesReferenceContext } from "../../PricesReference";
import { modifyValue, PopperComponent } from "../../useTableData";
import { PRNumericField, StyledAutocomplete } from "../PricesReferences.style";

const filterUnit = createFilterOptions<UnitWeight & { label: string }>();


const UnitCell: FC<{ props: ICellRendererParams<IEstimateReference, any, any>; setter: any }> = ({
  props: { data, ...rest },
  setter,
}) => {
  const { unitList } = useSelector(getPricesState);
  const [showInput, setShowInput] = useState(false);
  const { errors, deleteUnit, methodsPosition, methodsRate } = useContext(PricesReferenceContext);
  // const disabled = methodsPosition?.watch(`${Math.abs(data!.id)}.blockedInput` as never);
  const name = -100 > data!.id ? `${Math.abs(data!.id)}.unit` : 'unit';

  const refPopper = useRef<HTMLDivElement | null>(null);
  // const refPopperQTY = useRef<HTMLInputElement | null>(null);


  let error: boolean = false;

  switch (data!.rowType) {
    case 'RATE':
      error = !!data!.create && errors.rate.unit;
      break;
    case 'RATE_POSITION':
      error = !!data!.create && !!errors.position[Math.abs(data!.id) as any]?.unit;
      break;
  }

  useEffect(() => {
    if (data?.rowType === 'RATE' && showInput) {
      methodsRate?.setValue('unit' as any, data?.unit || null);
    }
  }, [methodsRate, data,showInput]);
  switch (data?.rowType) {
    case 'RATE':
      return showInput ? (
        <Box
          component={'form'}
          onSubmit={methodsRate?.handleSubmit((v) => {
            setter?.({ data, ...rest, newValue: v.unit, colDef: { ...rest.colDef, field: 'unit1' } });
            setShowInput(false);
          })}
          display={'flex'}
          p={'0 !important'}
          alignItems={'center'}
          width={'100%'}
          height={'100%'}>
          <Controller
            control={methodsRate?.control as any}
            name={'unit'}
            rules={{
              validate: {
                maxLength(v) {
                  if (v && v.length > 10) {
                    return 'максимум 10 символов';
                  }
                  return true;
                },
              },
            }}
            render={({ field: { ref, ...props }, formState }) => {
              const errorMessage =
                formState.errors?.[name]?.message ||
                // @ts-ignore
                formState.errors?.[Math.abs(data?.id!)]?.unit?.message;
              console.log(props)
              return (
                <>
                  <StyledAutocomplete
                    ref={refPopper}
                    itemRef={refPopper.current as any}
                    {...props}
                    // disabled={!!disabled || !isCreated}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    options={unitList.map((_) => ({ ..._, label: _.name }))}
                    componentsProps={{
                      paper: {
                        sx: {
                          width: 'min-content',
                          minWidth:'100%'
                        },
                      },
                    }}
                    // onSelect={props.onChange}
                    aria-valuemax={10}
                    filterOptions={(options: any[], params) => {
                      const filtered = filterUnit(options, params);
                      const { inputValue } = params;
                      const isExisting = options.some((option) => inputValue === option.name);
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          id: -1,
                          name: 'Нет совпадений',
                          label: 'Нет совпадений',
                        });
                      }
                      return filtered;
                    }}
                    freeSolo={true}
                    autoFocus={true}
                    noOptionsText="Будет создана новая запись"
                    disableClearable={true}
                    clearOnBlur={false}
                    autoSelect={false}
                    onChange={(event, val) => {
                      if (typeof val === 'string') {
                        props.onChange(val);
                      }
                    }}
                    defaultValue={data?.unit}
                    openOnFocus={true}
                    onHighlightChange={() => {}}
                    onBlur={(event: any) => {
                      setter?.({
                        data,
                        ...rest,
                        newValue: event.target.value,
                        colDef: { ...rest.colDef, field: 'unit1' },
                      });
                    }}
                    onInputChange={(event, val) => {
                      if (typeof val === 'string') {
                        props.onChange(val);
                      }
                    }}
                    onKeyDown={(event) => {
                      if (event.code === 'Escape') {
                        setShowInput(false);
                      }
                    }}
                    renderInput={(p) => (
                      <PRStyledBaseTextField
                        {...p}
                        hiddenLabel
                        placeholder={'Не выбрано'}
                        $textAlign={'center'}
                        autoFocus={true}
                        defaultValue={data?.unit}
                        InputProps={{
                          ...p.InputProps,
                          ...((error || !!errorMessage) && { sx: { background: '#FFB1B126 !important' } }),
                        }}
                        inputProps={{
                          ...p?.inputProps,
                        }}
                        size="small"
                        error={error || !!errorMessage}
                      />
                    )}
                    renderOption={(props, option: any) => {
                      return (
                        <MenuItem sx={{ width: '100%' }} {...props} disabled={option.id < 0} key={option.id}>
                          <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                            {option.name}
                            {option.id > 10 ? (
                              <IconButton
                                onClick={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  setShowInput(false)
                                  deleteUnit(option.id);
                                }}>
                                <DeleteOutlineIcon fontSize={'small'} />
                              </IconButton>
                            ) : (
                              <span />
                            )}
                          </Box>
                        </MenuItem>
                      );
                    }}
                  />
                  <PopperComponent message={errorMessage} refItem={refPopper.current} />
                </>
              );
            }}
          />
        </Box>
      ) : (
        <Cell
          className={data?.rowType === 'RATE' ? 'cell-hover' : ''}
          click={() => setShowInput(true)}
          float={'center'}>
          {data?.unit}
        </Cell>
      );
    case 'RATE_POSITION':
      return (
        <Box
          display={'grid'}
          height={'100%'}
          width={'100%'}
          p={'0'}
          alignItems={'center'}
          justifyItems={'center'}
          gridTemplateColumns={'1fr min-content 1fr'}>
          {Selector(data, false, (v) => {
            setter({ data, ...rest, colDef: { ...rest.colDef, field: 'unit1' }, newValue: v });
          })}
          <Divider orientation="vertical" flexItem />
          {Selector(data, true, (v) => {
            setter({ data, ...rest, colDef: { ...rest.colDef, field: 'unit2' }, newValue: v });
          })}
        </Box>
      );
    default:
      return null;
  }
};

const Selector = (data: IEstimateReference | undefined, isQty: boolean, setter: (v: any) => void) => {
  const [showInput, setShowInput] = useState(false);

  const { errors, deleteUnit, methodsPosition } = useContext(PricesReferenceContext);
  const name = `${Math.abs(data!.id)}.unit`;
  const refPopper = useRef<HTMLDivElement | null>(null);
  const refPopperQTY = useRef<HTMLInputElement | null>(null);
  const { unitList, isCreated } = useSelector(getPricesState);
  let error: boolean = false;

  switch (data!.rowType) {
    case 'RATE':
      error = !!data!.create && !!errors.rate.unit;
      break;
    case 'RATE_POSITION':
      error = !!data!.create && !!errors.position[Math.abs(data!.id) as any]?.unit;
      break;
  }

  useEffect(() => {
    if (showInput) {
      methodsPosition?.setValue(`${Math.abs(data?.id!)}.qty` as never, (modifyValue(data?.qty, false) || null) as never);
      methodsPosition?.setValue(`${Math.abs(data?.id!)}.unit` as never, (data?.unit || null) as never);
    }
  }, [data, methodsPosition, showInput]);

  // const formStateQTY = useMemo(() => {
  //   return methodsPosition?.getFieldState(`${Math.abs(data?.id!)}.qty` as never);
  // }, [methodsPosition?.getFieldState(`${Math.abs(data?.id!)}.qty` as never), showInput]);
  //
  // const formStateUNIT = useMemo(() => {
  //   return methodsPosition?.getFieldState(`${Math.abs(data?.id!)}.unit` as never);
  // }, [methodsPosition?.getFieldState(`${Math.abs(data?.id!)}.unit` as never), showInput]);

  // useEffect(() => {
  //   if (!showInput && formStateQTY?.isDirty && formStateUNIT?.isDirty) {
  //     console.log('calling');
  //     methodsPosition?.setValue(`${Math.abs(data?.id!)}.qty` as never, '' as never);
  //     methodsPosition?.setValue(`${Math.abs(data?.id!)}.unit` as never, '' as never);
  //   }
  // }, [formStateQTY, formStateUNIT]);

  if (isQty) {
    return showInput ? (
      <Box
        component={'form'}
        display={'flex'}
        alignItems={'center'}
        p={'0'}
        width={'100%'}
        height={'100%'}
        id="references-estimate"
        onSubmit={methodsPosition?.handleSubmit((v: any) => {
          console.log(v);
          setter(v[Math.abs(data?.id!)]?.qty || 0);
          setShowInput(false);
        })}>
        <Controller
          control={methodsPosition?.control as any}
          name={`${Math.abs(data?.id!)}.qty` as never}
          rules={{
            validate: {
              max: (v: string) => {
                const num = Number((v?.toString() || '')?.replace(/\s/g, '')?.replace(/,/g, '.'));
                if (num >= 999999999.999999) {
                  return 'Максимальное значение 999 999 999,999 999';
                }
                return true;
              },
              min: (v: string) => {
                const num = Number((v?.toString() as string)?.replace(/\s/g, '').replace(/,/g, '.'));
                if (num <= -999999999.999999) {
                  return 'Минимальное значение -999 999 999,999 999';
                }
                return true;
              },
            },
          }}
          render={({ field: { ref, onChange, ...props }, formState, fieldState }) => {
            console.log(formState);
            console.log(props.value);
            let val: number | undefined;
            if (typeof props.value === 'string') {
              val = Number((props.value as any).replace(/\s/g, '').replace(/,/g, '.'));
              // val = Number(props.value?.trim());
            } else if (typeof props.value === 'number') {
              val = props.value;
            }
            const showError = val && (val > 999999999.999999 || val < -999999999.999999);
            //@ts-ignore
            const errorMessage = formState.errors?.[Math.abs(data?.id!)]?.qty?.message;
            const errorQTY = !!errors.position[Math.abs(data!.id) as any]?.qty;

            return (
              <>
                <PRNumericField
                  getInputRef={(input: any) => {
                    refPopperQTY.current = input;
                  }}
                  {...props}
                  // value={props.value || ''}
                  onValueChange={(event) => {
                    onChange(event.formattedValue.replace(/\./, ','));
                    // setFormValue('input', event.floatValue, { shouldValidate: true });
                  }}
                  defaultValue={data?.qty || ''}
                  style={{
                    textAlign: 'center',
                    ...(showError && (errorQTY || !!errorMessage)
                      ? {
                          background: '#FFB1B126',
                          borderColor: '#F46B6B',
                        }
                      : {}),
                  }}
                  onKeyDown={(event) => {
                    if (event.code === 'Escape') {
                      setShowInput(false);
                    }
                  }}
                  onBlur={(event: any) => {
                    // setter?.({ data, ...rest, newValue: v.unit, colDef: { ...rest.colDef, field: 'unit1' } });
                    setter?.(event.target.value);
                  }}
                  // onFocus={(e: { target: { select: () => void } }) => e.target.select()}
                  allowedDecimalSeparators={[',', '.']}
                  autoFocus={true}
                  allowNegative={true}
                  decimalSeparator={','}
                  decimalScale={6}
                  thousandSeparator={' '}
                />
                <PopperComponent message={showError && errorMessage} refItem={refPopperQTY.current} />
              </>
            );
          }}
        />
      </Box>
    ) : (
      <Cell
        className={'cell-hover'}
        click={() => {
          !showInput && setShowInput(true);
        }}
        float={'center'}>
        {modifyValue(data?.qty, false)}
      </Cell>
    );
  }
  const disabled =
    data?.resourceType === 'MiM' || data?.resourceType === 'MACHINE_OPERATORS' || data?.resourceType === 'WORKERS';

  return showInput ? (
    <Box
      component={'form'}
      onSubmit={methodsPosition?.handleSubmit((v: any) => {
        setter(v[Math.abs(data?.id!)]!.unit);
        setShowInput(false);
      })}
      display={'flex'}
      alignItems={'center'}
      p={'0'}
      width={'100%'}
      height={'100%'}>
      <Controller
        control={methodsPosition?.control as any}
        name={name}
        rules={{
          validate: {
            maxLength(v) {
              if (v && v.length > 10) {
                return 'максимум 10 символов';
              }
              return true;
            },
          },
        }}
        render={({ field: { ref, ...props }, formState }) => {
          const errorMessage =
            formState.errors?.[name]?.message ||
            // @ts-ignore
            formState.errors?.[Math.abs(data?.id!)]?.unit?.message;
          return (
            <>
              <StyledAutocomplete
                ref={refPopper}
                itemRef={refPopper.current as any}
                {...props}

                sx={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
                componentsProps={{
                  paper: {

                    sx: {
                      width: 'min-content',
                      minWidth:'100%'
                    },
                  },
                }}
                disabled={!!disabled}
                onKeyDown={(event) => {
                  if (event.code === 'Escape') {
                    setShowInput(false);
                    return;
                  }
                }}
                options={unitList.map((_) => ({ ..._, label: _.name }))}
                aria-valuemax={10}
                filterOptions={(options: any[], params) => {
                  const filtered = filterUnit(options, params);
                  const { inputValue } = params;
                  const isExisting = options.some((option) => inputValue === option.name);
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      id: -1,
                      name: 'Нет совпадений',
                      label: 'Нет совпадений',
                    });
                  }
                  return filtered;
                }}
                value={props.value || ''}
                inputValue={props.value || ''}
                freeSolo={true}
                noOptionsText="Будет создана новая запись"
                disableClearable={true}
                clearOnBlur={false}
                autoSelect={false}
                autoFocus={true}
                onBlur={(event: any) => {
                  setter?.(event.target.value);
                }}
                onChange={(event, val) => {
                  if (typeof val === 'string') {
                    props.onChange(val);
                  }
                }}
                openOnFocus={true}
                onHighlightChange={(event, option, reason) => {}}
                onInputChange={(event, val) => {
                  if (typeof val === 'string') {
                    props.onChange(val);
                  }
                }}
                renderInput={(p) => (
                  <PRStyledBaseTextField
                    {...p}
                    hiddenLabel
                    placeholder={'Не выбрано'}
                    $textAlign={'center'}
                    autoFocus={true}
                    value={props.value || ''}
                    defaultValue={props.value || ''}
                    InputProps={{
                      ...p.InputProps,
                      sx: {
                        ...((error || !!errorMessage) && { background: '#FFB1B126 !important' }),
                        height: '100%',
                      },
                    }}
                    inputProps={{
                      ...p?.inputProps,
                      style: { height: '100%' },
                    }}
                    size="small"
                    error={error || !!errorMessage}
                  />
                )}
                renderOption={(props, option: any) => {
                  return (
                    <MenuItem sx={{ width: '100%' }} {...props} disabled={option.id < 0} key={option.id}>
                      <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        {option.name}
                        {option.id > 10 ? (
                          <IconButton
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                              setShowInput(false)
                              deleteUnit(option.id);
                            }}>
                            <DeleteOutlineIcon fontSize={'small'} />
                          </IconButton>
                        ) : (
                          <span />
                        )}
                      </Box>
                    </MenuItem>
                  );
                }}
              />
              <PopperComponent message={errorMessage} refItem={refPopper.current} />
            </>
          );
        }}
      />
    </Box>
  ) : (
    <Cell
      className={!disabled ? 'cell-hover' : ''}
      click={() => {
        !showInput && !disabled && setShowInput(true);
      }}
      float={'center'}>
      {data?.unit}
    </Cell>
  );
};

export default UnitCell;
