/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import React, { useContext, useMemo } from "react";
import { DrawerType, PositionVorContext } from "../../PositionsTable";
import { Box, IconButton } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { CommonFiltersRequest } from "../../../../../../api/calculations/types";

interface MyCustomHeaderProps {
  menuIcon: string;
  displayName: string;
  column: {
    colDef: {
      field: string;
    };
  };
  context: any;
}
const filterView: Record<Exclude<DrawerType,null>,keyof CommonFiltersRequest> = {
  title:'nameIDs',
  number:'lsrNumIDs',
  header:'titleIDs',
  code:'priceCodeIDs',
  lsr:'lsrCodeIDs',
  chapter:'chapterIDs'
}
const HeaderComponent:React.FC<MyCustomHeaderProps> = (props) => {
  const { toggleDrawer,checkedData } = useContext(PositionVorContext);

  const onClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.column && props.column.colDef && props.column.colDef.field) {
      toggleDrawer(props.column.colDef.field as DrawerType)
      // if (props.column.colDef.field === 'kfoName') {
      //   props?.context?.changeDialog?.('groups' as any) || changeDialog?.('groups' as any);
      // } else {
      //   props?.context?.changeDialog?.((props.column.colDef.field + 's') as any) ||
      //   changeDialog((props.column.colDef.field + 's') as any);
      // }
    }
  };
  const isActive = useMemo(()=>{
    return !!checkedData?.[filterView[props.column.colDef.field as keyof typeof filterView]]?.length || false;
  },[checkedData,props.column.colDef.field])
  return (
    <Box position={'relative'} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      {props.displayName}

      <Box position={'absolute'} right={5}>
        <IconButton tabIndex={-1} onClick={onClick}>
          <FilterAltIcon fontSize={'small'}
                         sx={{ fill: isActive ? '#0044B4' : '#9AA2B0' }}
                         // sx={{ fill: '#9AA2B0' }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default HeaderComponent;
