import React, { FocusEventHandler, useCallback, useMemo, useRef, useState } from 'react';
import { Box, IconButton, Menu, MenuItem, Stack, Tooltip } from '@mui/material/';
import { Form, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';
import useConfirmDialogСonfidence, {
  UseExitConfirmPropsConfidence,
} from '../../../../../../hooks/useConfirmDialogСonfidence';
import { useMutationHandlers } from '../../../../../../hooks/useMutationHandlers';

import { StyledFieldFormMin, StyledRow, StyledRowTd, StyledTypographyRow } from '../styles';
import { ReactComponent as RowIcon } from '../../../../../../assets/icons/LeveLicon/RowIcon.svg'; // assets/icons/LeveLicon/ FirstLevelicon.svg
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useCreateRowMutation, useDeleteRowMutation, useEditRowMutation } from '../../../../../../api/calculations';
import { useForm } from '../../../../../../hooks/useForm';
import { BasicRowType } from '../../../../../../api/calculations/types';
import { useTranslation } from 'react-i18next';
import { RowEditFormData } from './RowEditForm.types';
import { validationRow } from './validation';

export type RowPropsType = {
  row: BasicRowType;
  calculationId: number;
  handleShowFormAddRow: (isShowFormRowAdd: boolean) => void;
};
const BasicRow: React.FC<RowPropsType> = ({ handleShowFormAddRow, row, calculationId }) => {
  const { id: rowId, price, quantity, title, unit, unitPrice, parent: parentId } = row;

  const [isAddSave, setIsAddSave] = useState('');

  const [isInput, setIsInput] = useState(title === '');

  const initialValues: RowEditFormData = useMemo(
    () => ({
      price: price,
      quantity: quantity ?? 0, // кол-во
      title: title,
      unit: unit, // ед .изм
      unitPrice: unitPrice, // цена за ед.
    }),
    [], //calculation
  );

  const { formik, isSubmitDisabled } = useForm({
    validationSchema: validationRow,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values: RowEditFormData) => {
      onSubmit(values);
    },
  });

  const { values, handleSubmit } = formik;

  /************************************ Calculate Row  ************************************/

  const outQuantityNum = parseFloat((values.quantity ?? 0).toString().replace(',', '.').replace(' ', ''));
  const outUnitPriceNum = parseFloat((values.unitPrice ?? 0).toString().replace(',', '.').replace(' ', ''));
  const outPriceNum = outQuantityNum * outUnitPriceNum;

  const [editRow, editRowResponse] = useEditRowMutation();

  const onSubmit = useCallback(
    (values: RowEditFormData) => {
      editRow({
        calculationId,
        rowId,
        title: values.title,
        price: outPriceNum, //outPrice,
        quantity: outQuantityNum,
        unit: values.unit,
        unitPrice: outUnitPriceNum,
      });
    },
    [outPriceNum], //calculation
  );

  /************************************ Row Menu ************************************/

  const anchorEl = useRef<null | HTMLElement>(null);

  const [addRow, addRowResponse] = useCreateRowMutation();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('user');

  const handleClose = () => {
    anchorEl.current = null;
    setMenuRowOpened(false);
  };
  const [isMenuRowOpened, setMenuRowOpened] = useState(false);

  const handleOpenMenuRowClick = (event: React.MouseEvent<HTMLElement>) => {
    anchorEl.current = event.currentTarget;
    if (event.currentTarget) setMenuRowOpened(true);
  };

  const handleSaveRow = () => {
    handleShowFormAddRow(false);
    handleSubmit();
    anchorEl.current = null;
    setMenuRowOpened(false);
  };

  const handleSaveAddRow = () => {
    setIsAddSave('SaveAdd');
    handleSubmit();
    anchorEl.current = null;
    setMenuRowOpened(false);
  };

  useMutationHandlers(editRowResponse, () => {
    setIsInput(false);
    setMenuRowOpened(false);
    if (isAddSave === 'SaveAdd') {
      handleShowFormAddRow(true);
      setIsAddSave('');
    }
  });

  const handleClearForm = () => {
    setIsInput(true);
    formik.resetForm({
      values: {
        price: 0,
        quantity: 0,
        title: '',
        unit: '',
        unitPrice: 0,
      },
    });
    anchorEl.current = null;
    setMenuRowOpened(false);
  };

  /************************************ Copy Row ************************************/
  const handleCopyRowClick = () => {
    addRow({
      id: calculationId,
      parent: parentId, // id parent row
      price: price, //,
      quantity: quantity,
      title: title,
      type: 'row',
      unit: unit, // ед.изм
      unitPrice: unitPrice,
    });
  };

  useMutationHandlers(addRowResponse, () => {
    setMenuRowOpened(false);
  });

  // ******* Delete Row *******
  const [deleteRow, deleteRowResponse] = useDeleteRowMutation();

  const handleDeleteRowClick = useCallback((actionName: string) => {
    openConfirm(actionName);
  }, []);

  useMutationHandlers(deleteRowResponse, () => {
    enqueueSnackbar(t('success.rowDelete'), {
      variant: 'success',
    });
  });

  // ******* Dialog Confirm *******

  const handleConfirmDelete = useCallback((confirm: boolean) => {
    if (confirm) {
      deleteRow({ calculationId, rowId });
    }
  }, []);

  const handleConfirmCopy = useCallback((confirm: boolean) => {
    if (confirm) {
    }
  }, []);

  const dialogTitle = 'Вы действительно хотите удалить статью?';

  const dataForConfirmDialog: UseExitConfirmPropsConfidence = {
    handleConfirmDelete,
    handleConfirmCopy,
    dialogTitle,
  };
  const { ConfirmDialogConfidence, openConfirmConfidence: openConfirm } = useConfirmDialogСonfidence(
    dataForConfirmDialog,
    dialogTitle,
  );

  /****************************** Menu Button Level****************************************/

  const clickAddRow = useCallback((level: string) => {
    switch (level) {
      case 'row':
        handleShowFormAddRow(true);
        break;
      default:
        break;
    }
  }, []);

  /******************************** auto Focus *************************************/

  const titleRef = useRef<HTMLDivElement>(null);
  const unitRef = useRef<HTMLDivElement>(null);
  const quantityRef = useRef<HTMLDivElement>(null);
  const unitPriceRef = useRef<HTMLDivElement>(null);

  const handleSetIsInput = (nameInput: string) => {
    setIsInput(true);
    setTimeout(() => {
      if (titleRef.current) {
        if (titleRef.current.querySelector('input')?.name === nameInput)
          titleRef.current.querySelector('input')?.focus();
      }
      if (unitRef.current) {
        if (unitRef.current.querySelector('input')?.name === nameInput) unitRef.current.querySelector('input')?.focus();
      }
      if (quantityRef.current) {
        if (quantityRef.current.querySelector('input')?.name === nameInput)
          quantityRef.current.querySelector('input')?.focus();
      }
      if (unitPriceRef.current) {
        if (unitPriceRef.current.querySelector('input')?.name === nameInput)
          unitPriceRef.current.querySelector('input')?.focus();
      }
    }, 1);
  };

  /************************************** onKeyDown Enter **********************************/

  const enterPressHandler = (e: { key: string }) => {
    if (e.key === 'Enter') {
      setIsInput(false);
      handleSubmit();
    }
  };

  /******************************** Focus Out *************************************/

  const formRef = useRef<HTMLFormElement>(null);

  const handleFocusOut: FocusEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      // @ts-ignore
      if (event.relatedTarget && formRef.current?.contains(event.relatedTarget)) return;

      if (!isMenuRowOpened) {
        return handleSubmit();
      }
    },
    [isMenuRowOpened],
  );

  /************************************************************************************/

  return (
    <>
      <StyledRow colorBgRow={isInput ? 'shades' : 'white'} className="BasicRow">
        <StyledRowTd
          style={{
            width: '100px',
          }}>
          <Box
            sx={{
              minWidth: '24px',
              display: 'flex',
              justifyContent: 'center',
              marginRight: '26px',
            }}></Box>
          <IconButton onClick={() => clickAddRow('row')} sx={{ width: 32, height: 32 }} size="small">
            <RowIcon style={{ width: 16, height: 16 }} />
          </IconButton>
        </StyledRowTd>
        <FormikProvider value={formik}>
          <Form
            ref={formRef}
            onKeyDown={enterPressHandler}
            onBlur={isInput ? handleFocusOut : undefined}
            style={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: 'minmax(206px,1fr) repeat(4, minmax(135px, 206px)) 30px',
              gridAutoRows: '50px',
            }}>
            <StyledRowTd style={{ justifyContent: 'flex-start' }}>
              <Stack onDoubleClick={() => handleSetIsInput('title')} direction="row" sx={{ width: '100%' }}>
                {isInput ? (
                  <StyledFieldFormMin
                    ref={titleRef}
                    version="calculation"
                    name="title"
                    value={values.title}
                    placeholder={formik.isValid ? 'Укажите название работ' : 'Поле обязательно к заполнению'}
                    sx={{
                      '& .MuiFilledInput-input': {
                        '&.MuiInputBase-input': {
                          '&.MuiInputBase-input': {
                            textAlign: 'left',
                          },
                        },
                      },
                    }}
                  />
                ) : (
                  <Tooltip title={title}>
                    <StyledTypographyRow sx={{ marginLeft: '12px' }}>{title}</StyledTypographyRow>
                  </Tooltip>
                )}
              </Stack>
            </StyledRowTd>
            <StyledRowTd
              onDoubleClick={() => handleSetIsInput('unit')}
              style={{ justifyContent: 'center', width: '100%' }}>
              <Stack sx={{ alignItems: 'center' }} direction="row">
                {isInput ? (
                  <StyledFieldFormMin
                    ref={unitRef}
                    version="calculation"
                    name="unit"
                    placeholder={formik.isValid ? '' : 'Поле обязательно'}
                    value={values.unit}
                    sx={{
                      '& .MuiFilledInput-input': {
                        '&.MuiInputBase-input': {
                          '&.MuiInputBase-input': {
                            textAlign: 'center',
                          },
                        },
                      },
                    }}></StyledFieldFormMin>
                ) : (
                  <StyledTypographyRow style={{ textAlign: 'center' }}>{unit}</StyledTypographyRow>
                )}
              </Stack>
            </StyledRowTd>
            <StyledRowTd onDoubleClick={() => handleSetIsInput('quantity')} style={{ justifyContent: 'flex-end' }}>
              <Stack direction="row">
                {isInput ? (
                  <StyledFieldFormMin
                    ref={quantityRef}
                    version="calculation"
                    name="quantity" //количество
                    placeholder={formik.isValid ? '' : 'Поле обязательно'}
                    value={values.quantity.toLocaleString('ru-RU').replace(/\s/g, '')}></StyledFieldFormMin>
                ) : (
                  <StyledTypographyRow style={{ padding: '0px 12px' }}>
                    {outQuantityNum.toLocaleString('ru-RU', {
                      minimumFractionDigits: 3,
                      maximumFractionDigits: 3,
                    })}
                  </StyledTypographyRow>
                )}
              </Stack>
            </StyledRowTd>
            <StyledRowTd onDoubleClick={() => handleSetIsInput('unitPrice')} style={{ justifyContent: 'flex-end' }}>
              <Stack direction="row">
                {isInput ? (
                  <StyledFieldFormMin
                    ref={unitPriceRef}
                    version="calculation"
                    name="unitPrice"
                    placeholder={formik.isValid ? '' : 'Поле обязательно'}
                    value={values.unitPrice.toLocaleString('ru-RU').replace(/\s/g, '')}></StyledFieldFormMin>
                ) : (
                  <StyledTypographyRow style={{ padding: '0px 12px' }}>
                    {outUnitPriceNum.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                )}
              </Stack>
            </StyledRowTd>
            <StyledRowTd style={{ justifyContent: 'flex-end' }}>
              <Stack direction="row">
                <StyledTypographyRow style={{ padding: '0px' }}>
                  {outPriceNum.toLocaleString('ru-RU', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </StyledTypographyRow>
              </Stack>
            </StyledRowTd>

            <StyledRowTd style={{ padding: 0, width: 30 }}>
              <IconButton onClick={handleOpenMenuRowClick} sx={{ width: 30, height: 30 }} size="small">
                <MoreVertIcon />
              </IconButton>
            </StyledRowTd>
          </Form>
        </FormikProvider>
      </StyledRow>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl.current}
        open={isMenuRowOpened}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {isInput && <MenuItem onClick={handleSaveRow}>Сохранить </MenuItem>}
        {isInput && <MenuItem onClick={handleSaveAddRow}>Сохранить и доб. статью </MenuItem>}
        {/* {!isInput && <MenuItem onClick={handleCopyRowClick}>Копировать </MenuItem>} */}
        <MenuItem onClick={handleClearForm}>Очистить </MenuItem>
        <MenuItem onClick={() => handleDeleteRowClick('Delete')}>Удалить</MenuItem>
      </Menu>
      <ConfirmDialogConfidence />
    </>
  );
};

export default BasicRow;
