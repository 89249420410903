import { Stack, Typography } from "@mui/material";
import { FC, PropsWithChildren, useContext } from "react";
import { MenuPaperProps } from "./MenuPaper.types";
import { ActListContext } from "../../../../../CalculationСomplicated";

export const MenuPaper: FC<PropsWithChildren<MenuPaperProps>> = ({ title, children, onResetFrom }): JSX.Element => {
  const {calculation} = useContext(ActListContext)

  return (
    <Stack py={3.75} pr={3.75} pl={5} flexGrow={1} overflow="auto">
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={4}>
        <Typography fontSize="32px" fontWeight={300}>
          {title}
        </Typography>
        {!!calculation?.integrationInfo && (
          <Stack direction={'row'} alignItems={'center'} gap={'12px'}>
            {/*<Tooltip placement={'bottom-end'} title={'Кнопка будет доступна после удаления или изменения данных периодов. Модуль RM получит актуальную информацию о периодах после нажатия на кнопку'}>*/}
            {/*  <InfoIcon color={'primary'}/>*/}
            {/*</Tooltip>*/}
            {/*<Button sx={{maxWidth:'100% !important'}}>*/}
            {/*  <Cloud />*/}
            {/*  Синхронизировать периоды*/}
            {/*</Button>*/}
          </Stack>
        )}
      </Stack>
      <Stack flex={1}>{children}</Stack>
    </Stack>
  );
};
