import { useCallback, useRef, useState } from 'react';
import { CalculationComplexProps } from '../..';
import { useGetComplexRowsListQuery } from '../../../../../../api/calculations';
import ComplexFormAddLevel from '../ComplexFormAddLevel';
import ComplexLevel from '../ComplexLevel';
import { LevelSummBasePrice } from '../LevelSummBasePrice/LevelSummBasePrice';
import { StyledHeadTd, StyledTableBodyContainer, StyledTableContainer, StyledTableHead } from '../styles';

export const BasePrices = ({ calculation }: CalculationComplexProps) => {
  const { title, createdAt, id: calculationId } = calculation;

  /*****************************Scroll Body & Head ****************************************/

  const headRef = useRef<HTMLDivElement | null>(null);
  const bodyRef = useRef<HTMLTableRowElement | null>(null);

  const handleScrollHead = (scroll: React.UIEvent<HTMLElement>): void => {
    if (bodyRef.current === null) return;
    const target = scroll.target as HTMLDivElement;
    bodyRef.current.scroll({ left: target.scrollLeft });
  };
  const handleScrollBody = (scroll: React.UIEvent<HTMLElement>): void => {
    if (headRef.current === null) return;
    const target = scroll.target as HTMLDivElement;
    headRef.current.scroll({ left: target.scrollLeft });
  };

  /***************************************** Complex List   ****************************************/
  const { data, isFetching, isLoading } = useGetComplexRowsListQuery({ id: calculationId });

  const isDataLoading = isFetching || isLoading;

  const basePrices = data?.rows ? data?.rows : [];

  /*****************************Show/Hide Form Add Adjacent/соседний Row****************************************/

  const [isShowFormAddAdjacentLevel, setIsShowFormAddAdjacentLevel] = useState(false);

  const handleShowFormAdjacentLevel = useCallback((isShowFormRowAdd: boolean) => {
    setIsShowFormAddAdjacentLevel(isShowFormRowAdd);
  }, []);

  /*****************************Show/Hide Form Add Nested/вложенный Row****************************************/

  const [isShowFormAddNestedLevel, setIsShowFormAddNestedLevel] = useState(false);

  const handleShowFormNestedLevel = useCallback((isShowFormRowAdd: boolean) => {
    setIsShowFormAddNestedLevel(isShowFormRowAdd);
  }, []);

  /************************************************************************/

  return (
    <>
      {!isDataLoading && (
        <StyledTableContainer className="Complex-StyledTableContainer">
          <StyledTableHead className="head" ref={headRef} onScroll={handleScrollHead}>
            <StyledHeadTd></StyledHeadTd>
            <StyledHeadTd style={{ justifyContent: 'flex-start', padding: '8px 16px' }}>
              Смета или наименование работ
            </StyledHeadTd>
            <StyledHeadTd>Всего</StyledHeadTd>
            <StyledHeadTd>Основная з/п</StyledHeadTd>
            <StyledHeadTd
              sx={{
                background: '#6F88AB',
                height: '100%',
                boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)',
              }}>
              Эксплуатация МиМ
            </StyledHeadTd>
            <StyledHeadTd sx={{ background: '#6F88AB', height: '100%' }}>
              Зарплата <br /> механизаторов
            </StyledHeadTd>
            <StyledHeadTd
              sx={{
                boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)',
                height: '100%',
                background: '#6F88AB',
              }}>
              Материалы
            </StyledHeadTd>
            <StyledHeadTd sx={{ background: '#6F88AB', height: '100%' }}>Основные</StyledHeadTd>
            <StyledHeadTd
              sx={{
                background: '#6F88AB',
                height: '100%',
                boxShadow: 'inset -1px 0px 0px rgb(0 36 95 / 10%)',
              }}>
              Вспомогательные
            </StyledHeadTd>
            <StyledHeadTd>Оборудование</StyledHeadTd>
            <StyledHeadTd>Накладные расходы</StyledHeadTd>
            <StyledHeadTd>Сметная прибыль</StyledHeadTd>
            <StyledHeadTd style={{ maxWidth: 30 }}></StyledHeadTd>
          </StyledTableHead>
          <StyledTableBodyContainer className="body" ref={bodyRef} onScroll={handleScrollBody}>
            <LevelSummBasePrice basePrices={basePrices}></LevelSummBasePrice>
            {basePrices.length > 0 ? (
              basePrices?.map((rowLevel1) => {
                return (
                  <ComplexLevel
                    key={rowLevel1.id}
                    level={rowLevel1}
                    levelNumber={1}
                    calculationId={calculationId}
                    handleShowFormAdjacent={handleShowFormAdjacentLevel}
                    handleShowFormNested={handleShowFormNestedLevel}
                  />
                );
              })
            ) : (
              <ComplexFormAddLevel
                levelNumber={0}
                calculationId={calculationId}
                parentId={null}
                firstForm={'firstForm'}
                handleShowFormNestedLevel={handleShowFormNestedLevel}
                handleShowFormAdjacentLevel={handleShowFormAdjacentLevel}></ComplexFormAddLevel>
            )}

            {isShowFormAddAdjacentLevel && (
              <ComplexFormAddLevel
                levelNumber={0}
                calculationId={calculationId}
                parentId={null}
                handleShowFormNestedLevel={handleShowFormNestedLevel}
                handleShowFormAdjacentLevel={handleShowFormAdjacentLevel}></ComplexFormAddLevel>
            )}
          </StyledTableBodyContainer>
        </StyledTableContainer>
      )}
    </>
  );
};
