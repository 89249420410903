import { useGridApiContext } from '@mui/x-data-grid';
import { useEffect } from 'react';

export const useUpdateTableLoading = (loading: boolean, isUninitialized: boolean) => {
  const { current: gridApi } = useGridApiContext();

  useEffect(() => {
    if (isUninitialized) return;

    gridApi.setState((prev) => ({
      ...prev,
      rows: {
        ...prev.rows,
        loading,
      },
    }));
  }, [loading, isUninitialized]);
};
