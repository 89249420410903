import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { FC } from "react";

export const MoveGroup: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="16" height="20" viewBox="0 0 16 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4.43333L4.57143 0L9.14286 4.43333H5.71429V12.2222H3.42857V4.43333H0ZM12.5705 7.77781V15.5667H15.9991L11.4277 20L6.85625 15.5667H10.2848V7.77781H12.5705Z"
        fill="#0044B4"
      />
    </SvgIcon>
  );
};
