import { FC } from 'react';
import { Body } from './components';
import { TableStyled } from './Table.styles';

export const Table: FC = () => {
  return (
    <TableStyled>
      <Body />
    </TableStyled>
  );
};
