/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmial.com]
 */
import React, { ReactElement } from "react";
import { isAfter, isBefore } from "date-fns";
import { CreateActForm } from "./ActDialog.types";

export type TDisableDate = { start: Date; end: Date }

export type TReturnValidate = {
  start: null | ReactElement,
  end: null | ReactElement,
  on: null | ReactElement,
  split: null | ReactElement
}

export function validateDates(targetDate: CreateActForm, disabledDatesArray: TDisableDate[]): TReturnValidate {
  let errorText: TReturnValidate = { start: null, end: null, on: null, split: null };
  if (targetDate.startDate && isNaN(targetDate.startDate.getTime())) {
    errorText.start = <span>Дата начала некорректна</span>;
  }
  if (targetDate.endDate && isNaN(targetDate.endDate.getTime())) {
    errorText.end = <span>Дата окончания некорректна</span>;
  }
  if (targetDate.onDate && isNaN(targetDate.onDate.getTime())) {
    errorText.on = <span>Дата формирования некорректна</span>;
  }
  if (
    (targetDate.startDate && !isNaN(targetDate.startDate.getTime())) ||
    (targetDate.endDate && !isNaN(targetDate.endDate.getTime())) ||
    (targetDate.onDate && !isNaN(targetDate.onDate.getTime()))
  ) {
    for (let { start, end } of disabledDatesArray) {
      if (
        (!errorText.start &&
          (isSameDate(targetDate.startDate!, start) || isAfter(targetDate.startDate!, start)) &&
          (isSameDate(targetDate.startDate!, end) || isBefore(targetDate.startDate!, end))) ||
        (isBefore(targetDate.startDate!, start) && isAfter(targetDate.endDate!, end))
      ) {
        console.log(1);
        errorText.start = <span>Дата начала пересекает существующие акты</span>;
      }
      if (
        !errorText.end &&
        (isSameDate(targetDate.endDate!, start) || isAfter(targetDate.endDate!, start)) &&
        (isSameDate(targetDate.endDate!, end) || isBefore(targetDate.endDate!, end))
      ) {
        console.log(2);
        errorText.end = <span>Дата окончания пересекает существующие акты</span>;
      }
      if (!errorText.on && isSameDate(targetDate.startDate!, targetDate.endDate!)) {
        console.log(3);
        errorText.on = <span>Даты не могут быть установлены одним днем</span>;
      }
      if (
        !errorText.split &&
        (isBefore(targetDate.endDate!, targetDate.startDate!) || isAfter(targetDate.startDate!, targetDate.endDate!))
      ) {
        console.log(4);
        errorText.split = <span>Дата начала не может идти после даты окончания</span>;
      }
    }
  }
  return errorText;
}
export function checkDate(inputDate: Date | null, start: Date | null, end: Date | null) {
  if (!inputDate || !start || !end) return false;
  return isSameDate(inputDate, start) || (inputDate > start && inputDate < end) || isSameDate(inputDate, end);
}

export function isSameDate(dateA: Date, dateB: Date) {
  return dateA.getDate() === dateB.getDate() &&
  dateA.getMonth() === dateB.getMonth() &&
  dateA.getFullYear() === dateB.getFullYear();
}
