import React, { FC, useCallback, useMemo } from 'react'
import ReactInputMask from 'react-input-mask'
import { useField } from 'formik'
import { FieldHookConfig } from 'formik/dist/Field'
import { TextField, TextFieldProps } from '@mui/material'
import { formatMaskPhone, formatPhone, PHONE_MASK } from './utils'
import { useValidatePhone } from './validation'
import { useTranslation } from 'react-i18next'

export interface PhoneFieldFormProps extends Omit<TextFieldProps, 'name'> {
  name: string;
  disabled?: boolean;
  fieldProps?: Partial<FieldHookConfig<string>>;
}

export const PhoneFieldForm: FC<PhoneFieldFormProps> =
  ({
    fieldProps,
    disabled,
    ...props
  }) => {
    const { t } = useTranslation()
    const validate = useValidatePhone()
    const [{ onChange, value, ...field }, { touched, error }, { setValue }] =
      useField({
        name: props.name,
        validate,
        ...fieldProps,
      })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!value.includes('+')) {
        setValue(`+7${value}`)
      }

      if ((e.nativeEvent as any).inputType !== 'insertFromPaste') {
        setValue(formatPhone(e.target.value))
      }
    }

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
      const number = e.clipboardData.getData('Text')
      const formattedNumber = `${formatPhone(number.replace(/^\+7|^[78]/i, ''))}`
      setValue(formattedNumber)
    }

    const valueFormatPhone = useMemo(() => {
      return formatMaskPhone(value)
    }, [value])

    const isError = touched && !!error

    return (
      <ReactInputMask
        disabled={disabled}
        {...field}
        value={valueFormatPhone}
        onChange={handleChange}
        onPaste={handlePaste}
        mask={PHONE_MASK}
      >
        <TextField
          error={isError}
          helperText={isError ? t(error) : undefined}
          {...props}
        />
      </ReactInputMask>
    )
  }
