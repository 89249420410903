import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, Menu, Stack } from '@mui/material';
import { useDeleteLSRFileMutation } from 'api/lsr';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { useProjectId } from 'hooks/useProjectId';
import { FC, MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledMenuItem } from '../../Table.styles';
import { MenuItemAction } from '../../Table.types';
import { useUpdateTableLoading } from '../../hooks';
import { rowMenuItems } from './RowMenu..constants';
import { RowMenuProps } from './RowMenu.types';

export const RowMenu: FC<RowMenuProps> = ({ fileID, filePath, name }) => {
  const navigate = useNavigate();
  const projectID = useProjectId();

  const [deleteLSRFile, { isLoading, isUninitialized }] = useDeleteLSRFileMutation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  useUpdateTableLoading(isLoading, isUninitialized);

  const openMenuClickHandler = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const menuItemClickHandler = (action: MenuItemAction) => {
    switch (action) {
      case 'open': {
        navigate(`/projects/${projectID}/calculation/${fileID}/edit?tab=0&state=preview`);
        break;
      }
      case 'download': {
        fetch(filePath)
          .then((res) => res.blob())
          .then((blob) => {
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `${name}`);
            document.body.appendChild(link);
            link.click();
          });
        break;
      }
      case 'delete': {
        openConfirm();
        break;
      }
    }

    closeMenuHandler();
  };

  const deleteFileConfirmHandler = (confirm: boolean) => {
    if (confirm) {
      deleteLSRFile({ projectID, fileID });
    }
  };

  const { openConfirm, ConfirmDialog } = useConfirmDialog({
    title: 'Удалить файл?',
    body: 'Выбранный файл будет удален безвозвратно',
    denyButtonText: 'Нет',
    handleConfirm: deleteFileConfirmHandler,
  });

  const closeMenuHandler = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box>
        <IconButton color="primary" onClick={openMenuClickHandler}>
          <MoreVert />
        </IconButton>
        <Menu anchorEl={anchorEl} open={open} onClose={closeMenuHandler}>
          {rowMenuItems.map((menuItem) => (
            <StyledMenuItem key={menuItem.name} onClick={() => menuItemClickHandler(menuItem.action)}>
              <Stack direction="row" alignItems="center" spacing={2}>
                {menuItem.icon}
                <span>{menuItem.name}</span>
              </Stack>
            </StyledMenuItem>
          ))}
        </Menu>
      </Box>
      <ConfirmDialog />
    </>
  );
};
