/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import React from 'react';
import Button from '../../../../../../components/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Typography } from '@mui/material';
import { HeaderParameters } from '../Parameters.style';

const Header: React.FC<{ close: () => void }> = ({ close }) => {
  return (
    <HeaderParameters>
      <div className={'box'}>
        <Button onClick={close} variant="text" type="button" sx={{ fontWeight: 500, fontSize: '13px' }}>
          <span style={{ display: 'flex', marginRight: '8px' }}>
            <ArrowBackIcon sx={{ height: '25px', width: '25px' }} />
          </span>
          Назад
        </Button>
        <Typography variant="h1" color="primary.light">
          Изменение параметров расчета смет
        </Typography>
      </div>
      <div>
        <Button type={'submit'} width={'180px'} color={'success'}>
          применить
        </Button>
      </div>
    </HeaderParameters>
  );
};

export default Header;
