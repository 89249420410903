import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Box, Stack, Typography } from "@mui/material";
import { Tabs } from "components/Tabs";
import { ChangeEvent, ReactNode, useCallback } from "react";
import { CalculationStatuses } from "types";
import { useEditCalculationStatusMutation } from "../../../../../../api/calculations";
import Button from "../../../../../../components/Button";
import { TabsEntities } from "../../../../../../components/Tabs/Tabs.types";
import { theme } from "../../../../../../styles/theme";
import { SwitchingLevels } from "../SwitchingLevels/SwitchingLevels";
import { CalculationComplexLegendProps } from "./CalculationComplexLegend.types";
import { StyledToggleButton, StyledToggleButtonGroup } from "./style";
import DownloadIcon from "@mui/icons-material/Download";

const CalculationComplexLegend = <T extends TabsEntities>({
  handleOpenMenuCalculation,
  calculationStatus,
  calculationId,
  currentTab,
  currentContract,
  tabsData,
  onContractChange,
  onTabChange,
  setNumberLevel,
  numberLevel,
  handleOpenImportDrawer
}: CalculationComplexLegendProps<T> & { children?: ReactNode }) => {
  const [editCalculationStatus, editCalculationStatusResponse] = useEditCalculationStatusMutation();
  const colorTheme = theme.palette;

  const onStatusChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    editCalculationStatus({
      status: e.target.value as CalculationStatuses,
      id: calculationId,
    });
  }, []);

  return (
    <Box className="CalculationComplexLegend">
      <Stack className="LegendTabs" direction="row" justifyContent="space-between" alignItems="center" sx={{ py: 1.5 }}>
        <Tabs<T> currentTab={currentTab} onTabChange={onTabChange} tabsData={tabsData} />
        <Stack className="StatusCalculation" direction="row">
          <Stack spacing={'10px'} direction="row" justifyContent="flex-end" alignItems="center">
            <Button
              variant="outlined"
              onClick={()=>handleOpenImportDrawer?.()}
              style={{
                minWidth: '40px',
              }}>
              <DownloadIcon fontSize={'small'} color={'primary'}/>
              ЗАГРУЗИТЬ .XLS
            </Button>
            <Button
              onClick={handleOpenMenuCalculation}
              style={{
                padding: '8px',
                minWidth: '40px',
              }}>
              <MoreHorizIcon />
            </Button>
          </Stack>
        </Stack>
      </Stack>
      {currentTab === 'Просмотр расчета' && (
        <Stack
          className="ButtonsContract"
          direction="row"
          alignItems="center"
          sx={{ justifyContent: 'space-between', minWidth: '515px', margin: '6px 0 10px 0' }}>
          <StyledToggleButtonGroup
            className="FilteredContracting"
            value={currentContract}
            exclusive
            onChange={(e, newValue) => {
              onContractChange(newValue);
            }}>
            <StyledToggleButton value="ГЕНПОДРЯД" variant="primary">
              <Typography variant="buttonMedium" lineHeight="22px">
                ГЕНПОДРЯД
              </Typography>
            </StyledToggleButton>
            <StyledToggleButton value="СУБПОДРЯД" disabled={currentTab !== 'Базовые цены' ? false : true}>
              <Typography variant="buttonMedium" lineHeight="22px">
                СУБПОДРЯД
              </Typography>
            </StyledToggleButton>
            {currentTab !== 'Базовые цены' && (
              <StyledToggleButton value="АНАЛИЗ">
                <Typography variant="buttonMedium" lineHeight="22px">
                  АНАЛИЗ
                </Typography>
              </StyledToggleButton>
            )}
          </StyledToggleButtonGroup>
          <SwitchingLevels
            calculationId={calculationId}
            setNumberLevel={setNumberLevel}
            numberLevel={numberLevel}></SwitchingLevels>
        </Stack>
      )}
    </Box>
  );
};

export default CalculationComplexLegend;
