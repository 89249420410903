import styled from 'styled-components'
import { Stack } from '@mui/material'

export const UserManagementWrapper = styled(Stack)`
  margin-top: ${props => props.theme.spacing(1.25)};
  margin-right: 20px;
  background-color: ${props => props.theme.palette.bg.white};
  border-radius: 8px;
  overflow: auto;
`
