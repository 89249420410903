import { Box, IconButton, MobileStepper, Modal, ModalProps, Typography } from "@mui/material";
import { theme } from "../../styles/theme";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import React, { Fragment } from "react";
import Button from "../Button";
import CloseIcon from "@mui/icons-material/Close";

export interface IUserGuideStep {
  title: React.ReactNode
  content: React.ReactNode
  img: string
}

interface IUserGuideProps extends Partial<ModalProps> {
  steps: IUserGuideStep[],
  setOpenUserGuide: (value: boolean) => void;
}

export const UserGuide: React.FC<IUserGuideProps> = ({ steps, open, setOpenUserGuide }) => {

  const [activeStep, setActiveStep] = React.useState<number>(0)

  const goNext = () => {
    if (activeStep < 2) {
      setActiveStep(activeStep + 1)
    }
  }

  const goBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1)
    }
  }

  return (
    <Modal
      sx={{ display: "flex", borderRadius: theme.spacing(1), "::focused": { bgcolor: "red" }, m: 10 }}
      open={open as boolean}>
      <Box
        margin="auto"
        width={600}
        height={'auto'}
        borderRadius={theme.spacing(1)}
        bgcolor="white"
        padding={theme.spacing(3.75)}
        display="flex"
        flexDirection="column"
        justifyContent='space-between'
        sx={{ position: 'relative' }}
      >
        <IconButton
          sx={{ position: 'absolute', right: '10px', top: '10px' }}
          onClick={() => setOpenUserGuide(false)}
        >
          <CloseIcon />
        </IconButton>
        {steps?.map((step: IUserGuideStep, index: number) => {
          return (
            <Fragment key={index}>
              {activeStep === index &&
                <Box
                  className="className-step"
                  display="flex"
                  // height="220px"

                >
                  <Box minWidth="160px" height="160px" margin={theme.spacing(3.75, 3.75, 3.75, 0)} >
                    <img height="100%" width="100%" style={{ objectFit: "fill" }} src={step.img} />
                  </Box>
                  <Box display="flex" flexDirection="column" gap={1.5} textAlign={"left"} alignItems="flex-start"
                    sx={{ ".MuiTypography-root": { textAlign: "left" } }}>
                    <Typography textAlign={"left"} variant="h1" color={"primary"} >
                      {step.title}
                    </Typography>
                    <Box display="flex" flexDirection="column" gap={1.5} textAlign={"left"} alignItems="flex-start"
                      sx={{ ".MuiTypography-root": { textAlign: "left" } }} >
                      {step.content}
                    </Box>
                  </Box>
                </Box>}
            </Fragment>
          )
        })
        }
        <MobileStepper
          variant="dots"
          position="static"
          activeStep={activeStep}
          sx={{/*  mt: 2.5, */ p: 0, bgcolor: "white" }}
          backButton={<Box>
            {activeStep === 0 ? <Button
              variant="text"
              size="small"
              color="secondary"
              style={{ height: '30px', width: '102px' }}
              onClick={() => setOpenUserGuide(false)}
            >
              {"Закрыть"}
            </Button>
              : <Button
                variant="text"
                size="small"
                color="primary"
                startIcon={<ArrowBack style={{ width: '18px', height: '18px' }} />}
                style={{ height: '30px' }}
                onClick={goBack}
              >
                {"Назад"}
              </Button>}
          </Box>}
          nextButton={
            <Box>
              {activeStep === 2
                ? <Button
                  variant="text"
                  size="small"
                  color="primary"
                  style={{ height: '30px', width: '102px' }}
                  onClick={() => setOpenUserGuide(false)}
                >
                  {"Готово"}
                </Button>
                : <Button
                  variant="text"
                  size="small"
                  color="primary"
                  endIcon={<ArrowForward />}
                  style={{ height: '30px' }}
                  onClick={goNext}
                >
                  {"Далее"}
                </Button>
              }
            </Box>}
          steps={steps.length}
        />
      </Box>
    </Modal >
  )
}
{/*  <MyModal.Wrapper
            open={open as boolean}
            {...props}
        >
            <MyModal.Inner width={600} height={320}>
                <MyStepper.Wrapper>
                    {steps?.map((step: IUserGuideStep, index: number) => {
                        return (
                            <MyStepper.Step
                                key={index}
                                stepNumber={index}
                            >
                                <UserGuideStep.Wrapper>
                                    <UserGuideStep.Image
                                        src={step.img}
                                    />
                                    <UserGuideStep.Content>
                                        <UserGuideStep.Title>
                                            {step.title}
                                        </UserGuideStep.Title>
                                        <UserGuideStep.Content>
                                            {step.content}
                                        </UserGuideStep.Content>
                                    </UserGuideStep.Content>
                                </UserGuideStep.Wrapper>
                            </MyStepper.Step>
                        )
                    })}
                    <MyStepper.Pagination
                        steps={steps.length}
                        backButton={<MyStepper.BackBtn />}
                        nextButton={<MyStepper.NextBtn />}
                    />
                </MyStepper.Wrapper>
            </MyModal.Inner>
        </MyModal.Wrapper> */}
