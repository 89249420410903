import { Grid, Stack } from "@mui/material";
import { EmptyPage } from "components/EmptyPage";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Limit, UserRoleByTabLabel, UserRoleTabLabels, userTabNamesArray as tabNames } from "types";
import { useGetUsersQuery } from "../../../api/users/users";
import { GetUsersResponse } from "../../../api/users/users.types";
import Progress from "../../../components/Progress";
import { TabData } from "../../../components/Tabs/Tabs.types";
import useBreadcrumbs from "../../../hooks/useBreadcrumbs";
import useSearch from "../../../hooks/useSearch";
import { useAppContext } from "../../../layouts/Layout/context";
import { NUMBER_OF_ROWS_PER_USERS_PAGE } from "../../../utils/constants";
import AdminLegend from "../components/AdminLegend";
import UserCard from "../components/UserCard";
import { useDebounce } from "../../../hooks/useDebounce";

const AdminUsers: React.FC = () => {
  const navigate = useNavigate();

  const { currentCompanyId } = useAppContext();

  const { searchValue } = useSearch();

  const debounceValue = useDebounce(searchValue,300)

  const [currentTab, setCurrentTab] = useState<UserRoleTabLabels>('Все пользователи');

  const [limitValue, setLimitValue] = useState<Limit['value']>(NUMBER_OF_ROWS_PER_USERS_PAGE[0].value);
  const [page, setPage] = useState(1);

  const offset = useMemo(() => limitValue * (page - 1), [limitValue, page]);

  const { data, isLoading, isFetching } = useGetUsersQuery({
    companyId: currentCompanyId,
    limit: limitValue,
    offset: offset,
    search:debounceValue,
    filter: currentTab !== 'Все пользователи' ? UserRoleByTabLabel[currentTab] : undefined,
  });

  const { data: usersData, total } = data || ({} as GetUsersResponse);

  // const filteredUserData = useMemo(() => {
  //   const filteredDataByRole =
  //     currentTab === 'Все пользователи'
  //       ? usersData
  //       : usersData?.filter((user) => user.role === UserRoleByTabLabel[currentTab]);
  //   if (searchValue && filteredDataByRole?.length) {
  //     setPage(1);
  //     return filterByFieldNames<PublicUserProfile>(
  //       filteredDataByRole,
  //       [
  //         'email',
  //         'phone',
  //         'fullName' as keyof PublicUserProfile,
  //         'company.userCompanyName' as keyof PublicUserProfile,
  //         'company.userPosition' as keyof PublicUserProfile,
  //         /*        'projects' as keyof PublicUserProfile,
  //         'objects' as keyof PublicUserProfile, */
  //       ],
  //       searchValue,
  //     );
  //   }
  //   return filteredDataByRole;
  // }, [usersData, currentTab, searchValue]);

  const onTabChange = useCallback((e: React.SyntheticEvent, tabValue: UserRoleTabLabels) => {
    setCurrentTab(tabValue);
  }, []);

  const onAddUserClick = useCallback(() => {
    navigate('add');
  }, []);

  const onUserCardClick = useCallback((userId: number) => {
    navigate(`edit/${userId}`);
  },[]);

  const handleChangeLimit = useCallback(
    (limit: number) => {
      setLimitValue(Number(limit));
      setPage(1);
    },
    [setLimitValue, setPage],
  );

  const handleChangePage = useCallback((page: number) => setPage(page), [setPage]);

  const countPagination = useMemo(() => Math.ceil(total / limitValue) || 1, [total, limitValue]);

  const tabsData: TabData<UserRoleTabLabels>[] = useMemo(() => {
    return tabNames.map((tabName) => ({ value: tabName, label: tabName }));
  }, [tabNames]);

  useEffect(() => {
    if (debounceValue) {
      setPage(1);
    }
  }, [debounceValue]);

  useBreadcrumbs([{ title: `Пользователи / ${currentTab}` }], [currentTab]);

  return (
    <Stack flex={1}>
      <AdminLegend<UserRoleTabLabels>
        currentTab={currentTab}
        pagination
        tabsData={tabsData}
        onTabChange={onTabChange}
        onAddClick={onAddUserClick}
        countPagination={countPagination}
        limit={limitValue}
        onChangeLimit={handleChangeLimit}
        onChangePage={handleChangePage}
        page={page}
        numberRows={NUMBER_OF_ROWS_PER_USERS_PAGE}
      />
      {isLoading || isFetching ? (
        <Progress />
      ) : (
        <Grid sx={{ py: 1.25, overflowWrap: 'anywhere' }} spacing={'20px'} container>
          {usersData.length === 0 ? (
            <EmptyPage data={{ text: 'Отсутствуют пользователи, добавьте новых', buttons: [] }} />
          ) : (
            <>
              {usersData?.length > 0 ? (
                <>
                  {usersData?.map((user) => (
                    <Grid item xs={12} md={6} lg={4} xl={3} container justifyContent="center" key={user.id}>
                      <UserCard data={user} onClick={onUserCardClick} />
                    </Grid>
                  ))}
                </>
              ) : (
                <EmptyPage
                  data={{ text: 'Отсутствуют пользователи, соответствующие результатам запроса.', buttons: [] }}
                />
              )}
            </>
          )}
        </Grid>
      )}
    </Stack>
  );
};

export default AdminUsers;
