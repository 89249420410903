import { FC, useEffect, useState } from "react";
// import { ReactComponent as ArrowDownSvg } from "../../../app/assets/images/icons/arrow-down.svg";
import styles from "./Select.module.css";
import { Box } from "@mui/material";
interface SelectOption {
  id: number | string;
  name: string;
  [key: string]: any;
}
interface SelectProps {
  selectedOption?: string;
  multiply?: boolean;
  options?: SelectOption[];
  label?: string;
  placeholder?: string;
  onSelect: (option: SelectOption) => void;
  className?: string;
  error?: boolean;
}

const Select: FC<SelectProps> = ({
  selectedOption,
  label,
  options,
  placeholder,
  error,
  onSelect,
  className,
}) => {
  const [active, setActive] = useState<boolean>(false);

  useEffect(() => {
    const hide = () => setActive(false);

    document.addEventListener("click", hide);

    return () => {
      document.removeEventListener("click", hide);
    };
  }, []);

  const handleSelect = (option: SelectOption) => {
    onSelect(option);
    setActive(false);
  };

  return (
    <Box
      onClick={(e) => e.stopPropagation()}
      sx={{
        width: 210,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
      }}
    >
      <span className={styles.label}>{label}</span>
      <div
        className={`${styles.select} ${className} ${
          active ? styles.active : ""
        } ${error ? styles.error : ""}`}
        onClick={() => setActive((prevState) => !prevState)}
      >
        {selectedOption ? (
          <span className={styles.selected}>{selectedOption}</span>
        ) : placeholder ? (
          <span className={styles.placeholder}>{placeholder}</span>
        ) : (
          "Выберите опцию"
        )}

        {/*<ArrowDownSvg className={styles.arrowDownIcon} />*/}

        {active && (
          <div className={styles.dropDown} onClick={(e) => e.stopPropagation()}>
            {options?.map((option) => (
              <div
                className={`${styles.option} ${
                  selectedOption === option.name ? styles.selected : ""
                }`}
                onClick={() => handleSelect(option)}
              >
                {option.name}
              </div>
            ))}
          </div>
        )}
      </div>
    </Box>
  );
};

export default Select;
