import styled from 'styled-components';
import { StyledStatusSelect } from 'pages/Lsr/components/UploadLSRFilesDrawer/UploadLSRFilesDrawer.styles';
import { Box, Stack } from '@mui/material';

export const StyledTitle = styled(Box)`
  color: #0044b4;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin-bottom: 12px;
  text-align: center;
`;

export const StyledContent = styled(Stack)`
  position: absolute;
  width: 400px;
  height: 200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 4px;
  padding: 30px;
`;

export const StyledStatusSelectModal = styled(StyledStatusSelect)(({ theme }) => ({
  display: 'block',
  textAlign: 'left',
  overflow: 'hidden',
})) as typeof StyledStatusSelect;
