import { useCreateLevelMutation } from 'api/calculations';
import { useMutationHandlers } from 'hooks/useMutationHandlers';
import { useSnackbar } from 'notistack';
import { getSlicedChildrenIds } from './hooks.service';
import { CreateLevelPayload } from './hooks.types';

export const useCreateLevel = () => {
  const [createLevelMutation, createLevelResponse] = useCreateLevelMutation();
  const { enqueueSnackbar } = useSnackbar();

  useMutationHandlers(
    createLevelResponse,
    () => {
      enqueueSnackbar('Уровень создан', {
        variant: 'success',
      });
    },
    () => {
      enqueueSnackbar('Ошибка при создании уровня', {
        variant: 'error',
      });
    },
  );

  const createLevel = ({
    calcID,
    childrenIds,
    groupIds,
    title,
    rowType,
    level,
    rowID,
    levelIds,
  }: CreateLevelPayload) => {
    const mergedIds = [...childrenIds, ...groupIds];
    const index = mergedIds.findIndex((id) => id === rowID);

    if (index === -1) return;

    createLevelMutation({
      params: { calcID },
      body: {
        title,
        children: getSlicedChildrenIds(mergedIds, index, rowType === 'folded'),
        groupChildren: getSlicedChildrenIds(mergedIds, index, rowType === 'group'),
        parentID: rowType === 'group' ? levelIds[level - 1] : index !== 0 ? levelIds[level - 1] : levelIds[level],
        level: (rowType === 'folded' && index === 0 && levelIds[level - 1] !== null) || level === 0 ? level + 1 : level,
        recursiveLevelIncrease: true,
      },
    });
  };

  return { createLevel };
};
