import { Box } from '@mui/material';
import { Coefficients } from '../../../../../../../../api/calculations/types';
import {
  StyledContractTd,
  StyledTypographyHeadTd,
  StyledCoefficient,
  StyledCoefficients,
  StyledSubcontract,
  StyledSubcontractColumns,
  StyledSubcontractTitle,
  StyledSubIncludingLaborIntensity,
  StyledSubIncludingMaterials,
} from '../../styles';

export type SubcontractProps = {
  countColumnsAnalysis: string;
  isShowAnalysis: boolean;
  coefficients: Coefficients;
};
export const Subcontract: React.FC<SubcontractProps> = ({
  countColumnsAnalysis,
  isShowAnalysis,
  coefficients,
}) => {
  const {
    mainSalary,
    mimExploitation,
    materials,
    basicMaterials,
    auxiliaryMaterials,
    equipment,
    overheads,
    estimatedProfit,
    machineOperatorSalary,
  } = coefficients!;
  return (
    <StyledSubcontract className="subcontract">
      <StyledSubcontractTitle sx={{ background: '#0288D1' }} className="ContractTitle">
        Субподряд
      </StyledSubcontractTitle>
      <StyledSubcontractColumns
        style={{
          gridTemplateColumns: `repeat(${countColumnsAnalysis},170px)`, // repeat(13, 150px);
        }}
      >
        <StyledContractTd
          className="sub-total"
          style={{ gridArea: 'sub-total', boxShadow: 'rgb(0 26 77 / 8%) 1px 0px 0px inset' }}
        >
          ВСЕГО
        </StyledContractTd>
        {!isShowAnalysis && (
          <>
            <StyledContractTd
              className="mainSalary"
              style={{
                gridArea: 'sub-mainSalary',
                background: '#7890B2',
                // boxShadow: 'rgb(0 26 77 / 8%) 0px 0px 0px inset',
              }}
            >
              <StyledTypographyHeadTd>Основная заработная плата</StyledTypographyHeadTd>
              <StyledCoefficients direction="row">
                <StyledCoefficient
                  style={{
                    background: `${mainSalary.firstSub === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                  }}
                >
                  {(mainSalary.firstSub ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
                <StyledCoefficient
                  style={{
                    background: `${mainSalary.secondSub === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                  }}
                >
                  {(mainSalary.secondSub ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
                <StyledCoefficient
                  style={{
                    background: `${mainSalary.thirdSub === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                  }}
                >
                  {(mainSalary.thirdSub ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
              </StyledCoefficients>
            </StyledContractTd>
            <StyledContractTd
              className="mimExploitation"
              style={{
                gridArea: 'sub-mimExploitation',
                paddingTop: '20px',
                boxShadow: 'rgb(0 26 77 / 8%) 1px 0px 0px inset',
              }}
            >
              <StyledTypographyHeadTd>
                Эксплуатация <br /> МиМ
              </StyledTypographyHeadTd>
              <StyledCoefficients direction="row">
                <StyledCoefficient
                  style={{
                    background: `${
                      mimExploitation.firstSub === null ? 'rgba(43, 54, 72, 0.1)' : ''
                    }`,
                  }}
                >
                  {(mimExploitation.firstSub ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
                <StyledCoefficient
                  style={{
                    background: `${
                      mimExploitation.secondSub === null ? 'rgba(43, 54, 72, 0.1)' : ''
                    }`,
                  }}
                >
                  {(mimExploitation.secondSub ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
                <StyledCoefficient
                  style={{
                    background: `${
                      mimExploitation.thirdSub === null ? 'rgba(43, 54, 72, 0.1)' : ''
                    }`,
                  }}
                >
                  {(mimExploitation.thirdSub ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
              </StyledCoefficients>
            </StyledContractTd>
            <StyledContractTd
              className="machineOperatorSalary"
              style={{
                gridArea: 'sub-machineOperatorSalary',
                boxShadow: 'inset 0px 0px 0px rgb(0 36 95 / 10%)',
                padding: '0',
              }}
            >
              <Box
                className="materialsTitle"
                sx={{
                  boxShadow: 'inset 0px -1px 0px rgba(0, 36, 95, 0.1)',
                  width: '100%',
                }}
              >
                в том числе
              </Box>
              <StyledTypographyHeadTd sx={{ boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)' }}>
              Зарплата <br /> механизаторов
              </StyledTypographyHeadTd>
              <StyledCoefficients
                direction="row"
                sx={{
                  padding: '4px 8px',
                  boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)',
                }}
              >
                <StyledCoefficient
                  style={{
                    background: `${
                      machineOperatorSalary.firstSub === null ? 'rgba(43, 54, 72, 0.1)' : ''
                    }`,
                  }}
                >
                  {(machineOperatorSalary.firstSub ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
                <StyledCoefficient
                  style={{
                    background: `${
                      machineOperatorSalary.secondSub === null ? 'rgba(43, 54, 72, 0.1)' : ''
                    }`,
                  }}
                >
                  {(machineOperatorSalary.secondSub ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
                <StyledCoefficient
                  style={{
                    background: `${
                      machineOperatorSalary.thirdSub === null ? 'rgba(43, 54, 72, 0.1)' : ''
                    }`,
                  }}
                >
                  {(machineOperatorSalary.thirdSub ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
              </StyledCoefficients>
            </StyledContractTd>
            <StyledContractTd
              className="materials"
              style={{
                gridArea: 'sub-materials',
                paddingTop: '20px',
                boxShadow: 'rgb(0 26 77 / 8%) 1px 0px 0px inset',
              }}
            >
              <StyledTypographyHeadTd>Материалы</StyledTypographyHeadTd>
              <StyledCoefficients direction="row">
                <StyledCoefficient
                  style={{
                    background: `${materials.firstSub === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                  }}
                >
                  {(materials.firstSub ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
                <StyledCoefficient
                  style={{
                    background: `${materials.secondSub === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                  }}
                >
                  {(materials.secondSub ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
                <StyledCoefficient
                  style={{
                    background: `${materials.thirdSub === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                  }}
                >
                  {(materials.thirdSub ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
              </StyledCoefficients>
            </StyledContractTd>
            <StyledSubIncludingMaterials
              className="sub-includingMaterials"
              style={{ gridArea: 'sub-includingMaterials', background: '#6F88AB' }}
            >
              <Box
                className="sub-materialsTitle"
                sx={{
                  gridArea: 'sub-materialsTitle',
                  boxShadow: 'inset 0px -1px 0px rgba(0, 36, 95, 0.1)',
                }}
              >
                в том числе
              </Box>
              <StyledContractTd
                style={{
                  gridArea: 'sub-basicMaterials',
                  justifyContent: 'space-between',
                  paddingTop: '6px',
                  boxShadow: 'rgb(0 26 77 / 8%) 1px 0px 0px inset',
                }}
              >
                <StyledTypographyHeadTd style={{ height: '32px' }}>Основные</StyledTypographyHeadTd>
                <StyledCoefficients direction="row">
                  <StyledCoefficient
                    style={{
                      background: `${
                        basicMaterials.firstSub === null ? 'rgba(43, 54, 72, 0.1)' : ''
                      }`,
                    }}
                  >
                    {(basicMaterials.firstSub ?? '').toLocaleString('ru-RU') || '-'}
                  </StyledCoefficient>
                  <StyledCoefficient
                    style={{
                      background: `${
                        basicMaterials.secondSub === null ? 'rgba(43, 54, 72, 0.1)' : ''
                      }`,
                    }}
                  >
                    {(basicMaterials.secondSub ?? '').toLocaleString('ru-RU') || '-'}
                  </StyledCoefficient>
                  <StyledCoefficient
                    style={{
                      background: `${
                        basicMaterials.thirdSub === null ? 'rgba(43, 54, 72, 0.1)' : ''
                      }`,
                    }}
                  >
                    {(basicMaterials.thirdSub ?? '').toLocaleString('ru-RU') || '-'}
                  </StyledCoefficient>
                </StyledCoefficients>
              </StyledContractTd>
              <StyledContractTd
                className="auxiliaryMaterials"
                style={{
                  gridArea: 'sub-auxiliaryMaterials',
                  justifyContent: 'space-between',
                  paddingTop: '6px',
                  boxShadow: 'rgb(0 26 77 / 8%) 1px 0px 0px inset',
                }}
              >
                <StyledTypographyHeadTd style={{ height: '32px' }}>
                  Вспомогательные
                </StyledTypographyHeadTd>
                <StyledCoefficients direction="row">
                  <StyledCoefficient
                    style={{
                      background: `${
                        auxiliaryMaterials.firstSub === null ? 'rgba(43, 54, 72, 0.1)' : ''
                      }`,
                    }}
                  >
                    {(auxiliaryMaterials.firstSub ?? '').toLocaleString('ru-RU') || '-'}
                  </StyledCoefficient>
                  <StyledCoefficient
                    style={{
                      background: `${
                        auxiliaryMaterials.secondSub === null ? 'rgba(43, 54, 72, 0.1)' : ''
                      }`,
                    }}
                  >
                    {(auxiliaryMaterials.secondSub ?? '').toLocaleString('ru-RU') || '-'}
                  </StyledCoefficient>
                  <StyledCoefficient
                    style={{
                      background: `${
                        auxiliaryMaterials.thirdSub === null ? 'rgba(43, 54, 72, 0.1)' : ''
                      }`,
                    }}
                  >
                    {(auxiliaryMaterials.thirdSub ?? '').toLocaleString('ru-RU') || '-'}
                  </StyledCoefficient>
                </StyledCoefficients>
              </StyledContractTd>
            </StyledSubIncludingMaterials>
            <StyledContractTd
              className="equipment"
              style={{
                gridArea: 'sub-equipment',
                background: '#7890B2',
                boxShadow: 'rgb(0 26 77 / 8%) 1px 0px 0px inset',
              }}
            >
              <StyledTypographyHeadTd>Оборудование</StyledTypographyHeadTd>
              <StyledCoefficients direction="row">
                <StyledCoefficient
                  style={{
                    background: `${equipment.firstSub === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                  }}
                >
                  {(equipment.firstSub ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
                <StyledCoefficient
                  style={{
                    background: `${equipment.secondSub === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                  }}
                >
                  {(equipment.secondSub ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
                <StyledCoefficient
                  style={{
                    background: `${equipment.thirdSub === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                  }}
                >
                  {(equipment.thirdSub ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
              </StyledCoefficients>
            </StyledContractTd>
            <StyledContractTd
              className="overheads"
              style={{
                gridArea: 'sub-overheads',
                background: '#7890B2',
                boxShadow: 'rgb(0 26 77 / 8%) 1px 0px 0px inset',
              }}
            >
              <StyledTypographyHeadTd>Накладные расходы</StyledTypographyHeadTd>
              <StyledCoefficients direction="row">
                <StyledCoefficient
                  style={{
                    background: `${overheads.firstSub === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                  }}
                >
                  {(overheads.firstSub ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
                <StyledCoefficient
                  style={{
                    background: `${overheads.secondSub === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                  }}
                >
                  {(overheads.secondSub ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
                <StyledCoefficient
                  style={{
                    background: `${overheads.thirdSub === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                  }}
                >
                  {(overheads.thirdSub ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
              </StyledCoefficients>
            </StyledContractTd>
            <StyledContractTd
              className="estimatedProfit"
              style={{
                gridArea: 'sub-estimatedProfit',
                background: '#7890B2',
                boxShadow: 'rgb(0 26 77 / 8%) 1px 0px 0px inset',
              }}
            >
              <StyledTypographyHeadTd>Сметная прибыль</StyledTypographyHeadTd>
              <StyledCoefficients direction="row">
                <StyledCoefficient
                  style={{
                    background: `${
                      estimatedProfit.firstSub === null ? 'rgba(43, 54, 72, 0.1)' : ''
                    }`,
                  }}
                >
                  {(estimatedProfit.firstSub ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
                <StyledCoefficient
                  style={{
                    background: `${
                      estimatedProfit.secondSub === null ? 'rgba(43, 54, 72, 0.1)' : ''
                    }`,
                  }}
                >
                  {(estimatedProfit.secondSub ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
                <StyledCoefficient
                  style={{
                    background: `${
                      estimatedProfit.thirdSub === null ? 'rgba(43, 54, 72, 0.1)' : ''
                    }`,
                  }}
                >
                  {(estimatedProfit.thirdSub ?? '').toLocaleString('ru-RU') || '-'}
                </StyledCoefficient>
              </StyledCoefficients>
            </StyledContractTd>
            <StyledSubIncludingLaborIntensity
              className="includingLaborIntensity"
              style={{ gridArea: 'sub-includingLaborIntensity', background: '#7890B2' }}
            >
              <Box
                className="laborIntensityTitle"
                sx={{
                  gridArea: 'sub-laborIntensityTitle',
                  background: '#7890B2',
                  boxShadow: 'inset 1px -1px 0px rgba(0, 36, 95, 0.1)',
                }}
              >
                Норматив трудоемкость
              </Box>
              <StyledContractTd
                className="peoplePerHour"
                style={{
                  gridArea: 'sub-peoplePerHour',
                  background: '#7890B2',
                  paddingTop: '6px',
                  justifyContent: 'space-between',
                  boxShadow: 'rgb(0 26 77 / 8%) 1px 0px 0px inset',
                }}
              >
                <StyledTypographyHeadTd style={{ height: '32px' }}>
                  чел. -час
                </StyledTypographyHeadTd>
                <StyledCoefficients direction="row">
                  <StyledCoefficient
                    style={{
                      background: `${mainSalary.firstSub === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                    }}
                  >
                    {(mainSalary.firstSub ?? '').toLocaleString('ru-RU') || '-'}
                  </StyledCoefficient>
                  <StyledCoefficient
                    style={{
                      background: `${mainSalary.secondSub === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                    }}
                  >
                    {(mainSalary.secondSub ?? '').toLocaleString('ru-RU') || '-'}
                  </StyledCoefficient>
                  <StyledCoefficient
                    style={{
                      background: `${mainSalary.thirdSub === null ? 'rgba(43, 54, 72, 0.1)' : ''}`,
                    }}
                  >
                    {(mainSalary.thirdSub ?? '').toLocaleString('ru-RU') || '-'}
                  </StyledCoefficient>
                </StyledCoefficients>
              </StyledContractTd>
              <StyledContractTd
                className="machinesPerHour"
                style={{
                  gridArea: 'sub-machinesPerHour',
                  background: '#7890B2',
                  paddingTop: '6px',
                  justifyContent: 'space-between',
                  boxShadow: 'rgb(0 26 77 / 8%) 1px 0px 0px inset',
                }}
              >
                <StyledTypographyHeadTd style={{ height: '32px' }}>
                  маш. -час
                </StyledTypographyHeadTd>
                <StyledCoefficients direction="row">
                  <StyledCoefficient
                    style={{
                      background: `${
                        mimExploitation.firstSub === null ? 'rgba(43, 54, 72, 0.1)' : ''
                      }`,
                    }}
                  >
                    {(mimExploitation.firstSub ?? '').toLocaleString('ru-RU') || '-'}
                  </StyledCoefficient>
                  <StyledCoefficient
                    style={{
                      background: `${
                        mimExploitation.secondSub === null ? 'rgba(43, 54, 72, 0.1)' : ''
                      }`,
                    }}
                  >
                    {(mimExploitation.secondSub ?? '').toLocaleString('ru-RU') || '-'}
                  </StyledCoefficient>
                  <StyledCoefficient
                    style={{
                      background: `${
                        mimExploitation.thirdSub === null ? 'rgba(43, 54, 72, 0.1)' : ''
                      }`,
                    }}
                  >
                    {(mimExploitation.thirdSub ?? '').toLocaleString('ru-RU') || '-'}
                  </StyledCoefficient>
                </StyledCoefficients>
              </StyledContractTd>
            </StyledSubIncludingLaborIntensity>
            <StyledContractTd
              className="hourlyCost"
              style={{
                gridArea: 'sub-hourlyCost',
                background: '#7890B2',
                boxShadow: 'rgb(0 26 77 / 8%) 1px 0px 0px inset',
              }}
            >
              <StyledTypographyHeadTd>Отпускная стоимость часа, без НДС</StyledTypographyHeadTd>
            </StyledContractTd>
          </>
        )}
      </StyledSubcontractColumns>
    </StyledSubcontract>
  );
};
