import { Stack } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import { FC } from 'react';
import { ProjectFormData } from '../../ProjectManagement.types';
import { ImageField } from '../ImageField';
import { ImageItem } from '../ImageItem';
import { ImagesTitledWrapper } from './FormRightBlock.styles';

export const FormRightBlock: FC = () => {
  const { values: formValues, setFieldValue } = useFormikContext<ProjectFormData>();

  return (
    <Stack flex={1}>
      <ImagesTitledWrapper title="Визуализация объекта" variant="primary" contentWrapper="stack" bordered="border">
        <FieldArray
          name="initialImages"
          render={({ remove }) => (
            <>
              {formValues.initialImages.map((image, index) => (
                <ImageItem
                  key={image.id}
                  url={image.path}
                  onClick={() => {
                    setFieldValue('imagesIdsToDelete', [...formValues.imagesIdsToDelete, image.id]);
                    remove(index);
                  }}
                />
              ))}
            </>
          )}
        />
        <FieldArray
          name="imagesForCreate"
          render={({ insert, remove }) => (
            <>
              {formValues.imagesForCreate.map((file, index) => (
                <ImageItem key={index} url={URL.createObjectURL(file)} onClick={() => remove(index)} />
              ))}

              {formValues.initialImages.length + formValues.imagesForCreate.length < 1 && (
                <ImageField insert={insert} />
              )}
            </>
          )}
        />
      </ImagesTitledWrapper>
    </Stack>
  );
};
