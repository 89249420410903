/**
 * @author Mr_FabiozZz[fabiozzz.dev@gmail.com]
 */

import React, { memo } from "react";
import { IEstimateReference } from "../../../../../../../api/references/estimates/estimates.types";
import RowReference from "./RowReference";

const ListReference: React.FC<{ table: IEstimateReference[]; defaultOpenRow: boolean; isRoot: boolean }> = memo(
  ({ table, defaultOpenRow, isRoot }) => {
    return (
      <>
        {table.map((row) => (
          <RowReference key={row.uuid} row={row} defaultOpenRow={defaultOpenRow} isRoot={isRoot} />
        ))}
      </>
    );
  },
);
ListReference.displayName = 'ListReference';

export default ListReference;
