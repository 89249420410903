/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */

import React, { useCallback, useContext, useMemo } from "react";
import { Drawer } from "../../../../../../components/Drawer";
import { ActList, EPragmaModules } from "../../../../../../types";
import { StyledTitle } from "../../../../../../components/Drawer/Drawer.styles";
import { formatDate } from "../../../Accomplishment/components/CaptionTable/CaptionTable";
import { ContentWrapper, LogoWrapper, TitleWrapper, Wrapper } from "./drawer.style";
import ModuleIcon from "../../../../../../assets/SVG/ModuleIcon";
import { Box, Typography } from "@mui/material";
import Button from "../../../../../../components/Button";
import { formatDateToString } from "../../../../../../utils/formatDate";
import { getMinutes } from "date-fns";
import { ActListContext } from "../../index";
import {
  useSyncActMutation,
  useSyncCloseActMutation,
  useSyncGetFactMutation
} from "../../../../../../api/calculations";
import { useCalcId } from "../../../../../../hooks/useCalcId";
import useConfirmDialog from "../../../../../../hooks/useConfirmDialog";
import Content from "./components/Content";

interface PropsDrawer {
  act: ActList | null;
  open: boolean;
  close: () => void;
}

interface PropsTitle {
  act: ActList;
}

const Title: React.FC<PropsTitle> = ({ act }) => {
  return <StyledTitle sx={{ padding: "0 !important" }} variant={"h1"}>Синхронизация факта <br /> {formatDate(act)}
  </StyledTitle>;
};

const IntegrateDrawer: React.FC<PropsDrawer> = ({ act, open, close }) => {
  if (!act) return null;

  const { status, responsible, syncedAt } = act;

  const { calculation,data } = useContext(ActListContext);

  const calcID = useCalcId();

  const { ConfirmDialog, openConfirm } = useConfirmDialog({
    handleConfirm: (confirm, fn) => {
      if (confirm) {
        fn?.();
      }
    },
    title        : "Подтвердить закрытие периода",
    body         : `Период будет закрыт. Получение данных в закрытый период из модуля ${EPragmaModules[calculation?.integrationInfo?.module || "resource_managment"]} будет невозможно.`
  });

  /* Request's block */

  const [syncAct, informSyncActMutation] = useSyncActMutation();
  const handleSyncAct = useCallback(() => {
    syncAct({ actID: act.id, calcID });
  }, [act, calcID]);

  const [getFact, informGetFctMutation] = useSyncGetFactMutation();
  const handleGetFact = useCallback(() => {
    getFact({ actID: act.id, calcID });
  }, [act, calcID]);

  const [closeAct, informCloseActMutation] = useSyncCloseActMutation();
  const handleCloseAct = useCallback(() => {
    openConfirm(() => closeAct({ actID: act.id, calcID }));
  }, [act, calcID]);

  /*******************/

  const syncDate = useMemo(() => {
    return syncedAt && (formatDateToString(new Date(syncedAt), "dd.MM.yyyy hh:") + getMinutes(new Date(syncedAt)));
  }, [syncedAt]);

  return !calculation?.integrationInfo ? null : (
    <Drawer title={<Title act={act!} /> as any} open={open} onClose={close}>
      <Wrapper>
        <Box display={"flex"} flexDirection={"column"} width={"100%"} justifySelf={"flex-start"}
             justifyContent={"flex-start"}>
          <LogoWrapper>
            <TitleWrapper>
              <Typography variant={"h2"} color={"white"} fontSize={"12px"}>ДОСТУПНО</Typography>
            </TitleWrapper>
            <ModuleIcon width={"100px"} height={"100px"} module={"resource_managment"} />
            <Typography variant={"h1"} lineHeight={"18px"} fontWeight={800} fontSize={"12px"}>
              Pragma.{EPragmaModules[calculation!.integrationInfo!.module!]}
            </Typography>
          </LogoWrapper>

          <ContentWrapper>
            <Content act={act} sync={handleSyncAct} closeAct={handleCloseAct} getFact={handleGetFact} />
          </ContentWrapper>
        </Box>
        <ConfirmDialog />
        <Button fullWidth sx={{ maxWidth: "100% !important", justifySelf: "flex-end" }} onClick={close}>
          закрыть
        </Button>
      </Wrapper>
    </Drawer>
  );
};

export default React.memo(IntegrateDrawer);
