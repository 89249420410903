import { Menu, MenuItem, Stack } from "@mui/material";
import { useProjectId } from "hooks/useProjectId";
import { useSnackbar } from "notistack";
import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ActList, ActResponse, CalcData, GetExecutionCalculationData, LevelNameTypeEn } from "types";
import {
  useDeleteCalculationMutation,
  useGetActListQuery,
  useLazyGetExecutionCalculationQuery
} from "../../../../api/calculations";
import useBreadcrumbs from "../../../../hooks/useBreadcrumbs";
import useConfirmDialog, { UseExitConfirmProps } from "../../../../hooks/useConfirmDialog";
import { useMutationHandlers } from "../../../../hooks/useMutationHandlers";
import CalculationLegend from "../CalculationBasic/components/CalculationLegend/CalculationLegend";
import CalculationDrawer from "../CalculationDrawer";
import Resources from "../Resources/Resources";
import { EstimateComplicated } from "./components/EstimateСomplicated";
import { VorPage } from "pages/Vor";
import Accomplishment from "../Accomplishment";
import { useTypedSelector } from "store/store";
import { useCalcId } from "../../../../hooks/useCalcId";
import { addData, deleteData, getData, openDB } from "../../../../utils/indexDB";
import IntegrateDrawer from "./components/IntegrateDrawer";

export type LevelNameShowType = {
  name: LevelNameTypeEn;
  show: boolean;
};
export type CalculationComplicatedProps = {
  calculation?: CalcData;
  openEdit: (b: boolean) => void;
};
interface IActListContext {
  data?: ActResponse;
  calculation?: CalcData;
  current: ActList | null;
  setCurrent?: (act: ActList | null) => void;
  getTable?: (id: number) => void;
  table?: GetExecutionCalculationData;
  isFetching?: boolean;
  refetchActs?:()=>void
}
export const ActListContext = createContext<IActListContext>({ data: undefined, current: null });

const CalculationComplicated = ({ calculation, openEdit }: CalculationComplicatedProps) => {

  const navigate = useNavigate();

  const projectID = useProjectId();
  const calcId = useCalcId();

  const { t } = useTranslation('user');

  const { enqueueSnackbar } = useSnackbar();


  const [updateTable, { data: tableExecution, isFetching }] = useLazyGetExecutionCalculationQuery();
  const getDataExecution = (id: number) => {
    updateTable({ calcID: id });
  };

  const [integrateDrawer, setIntegrateDrawer] = useState(false);
  const toggleDrawer = useCallback((flag = false)=>{
    setIntegrateDrawer(flag)
  },[])

  const [searchParams, setSearchParams] = useSearchParams();
  const params = new URLSearchParams(useLocation().search);
  const isPreview = params.get('state') === 'preview';

  const hideButtons = useTypedSelector((state) => state.vor.table.isAddingVor);

  const [selectedTab, setSelectedTab] = useState<number>(Number(searchParams.get('tab')));

  // ******* Act list *******

  const { data,refetch } = useGetActListQuery({ calcID: calculation?.id! });
  const [currentAct, setCurrentAct] = useState<ActList | null>(null);

  useEffect(() => {
    if (data && calcId && data?.actList) {
      openDB().then(async (db) => {
        let act = await getData(db, calcId);
        if (act) {
          const find = data.actList.find(_ => _?.id === act?.act?.id);
          if (find) {
            changeCurrentAct(find);
          }else{
            changeCurrentAct(act.act);
          }
        } else {
          const lastAct = data.actList[data.actList.length - 1];
          addData(db, { id: Number(calcId), act: lastAct }).then(() => {
            setCurrentAct(lastAct);
          });
        }
      });
    }
  }, [data, calcId]);

  const changeCurrentAct = useCallback(
    (act: ActList | null) => {
      openDB().then(async (db) => {
        if (act !== null) {
          addData(db, { id: Number(calcId), act }).then(() => {
            setCurrentAct(act);
          });
        } else {
          deleteData(db, calcId).then(() => {
            setCurrentAct(null);
          });
        }
      });
    },
    [currentAct, calcId],
  );

  // ************************

  const handleSelectedTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    setSearchParams(
      (prev) => {
        let newParams;
        if (prev.get('state')) {
          newParams = {
            state: prev.get('state'),
            tab: String(newValue),
          };
        } else {
          newParams = { tab: String(newValue) };
        }

        return newParams as any;
      },
      { replace: true },
    );
  };

  useEffect(() => {
    if (!Number(searchParams.get('tab'))) {
      setSearchParams(
        (prev) => {
          let newParams;

          if (prev.get('state')) {
            newParams = {
              state: prev.get('state'),
              tab: '0',
            };
          } else {
            newParams = { tab: '0' };
          }

          return newParams as any;
        },
        { replace: true },
      );
    }
    setSelectedTab(Number(searchParams.get('tab')));
  }, [searchParams]);

  // ******* Menu Calculation *******
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const MenuOpen = Boolean(anchorEl);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOpenMenuCalculation = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  // ******* Delete Calculation *******
  const [deleteCalculation, deleteCalculationResponse] = useDeleteCalculationMutation();

  const handleDeleteCalculationClick = useCallback(
    (actionName: string) => {
      openConfirmDelete(actionName);
    },
    [calculation?.id],
  );

  useMutationHandlers(deleteCalculationResponse, () => {
    navigate(`/projects/${projectID}/calculations`);
    enqueueSnackbar(t('success.calculationDelete'), {
      variant: 'success',
    });
  });

  // ******* dialog Confirm *******
  const handleConfirmDelete = useCallback((confirm: boolean) => {
    if (confirm) {
      deleteCalculation({ projectID, calcID: calculation?.id || 0 }).then(() => {
        openDB().then((db) => deleteData(db, calculation!.id));
      });
    }
  }, []);

  const { ConfirmDialog: ConfirmDiaologDelete, openConfirm: openConfirmDelete } = useConfirmDialog({
    title: 'Вы уверены?',
    body: 'Расчёт будет удалён и восстановить его будет невозможно',
    handleConfirm: handleConfirmDelete,
  });

  /***************************** Drawer Edit Calculation ****************************************/
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      setIsDrawerOpen(false);
    }
  }, []);

  const dataForConfirmDialog: UseExitConfirmProps = {
    title: 'Выйти из расчета?',
    body: 'Внесенные изменения не сохранятся. Продолжить?',
    confirmButtonText: 'Да',
    denyButtonText: 'Нет',
    handleConfirm,
  };

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog);

  const onDrawerClose = useCallback((dirty: boolean, immediately?: boolean) => {
    immediately || !dirty ? setIsDrawerOpen(false) : openConfirm();
  }, []);

  // ******* Edit Calculation  *******

  useBreadcrumbs(
    [
      {
        title: 'Расчеты',
        url: `/projects/${projectID}/calculations`,
      },
      {
        title: calculation?.title ?? 'Предпросмотр',
        url: `projects/${projectID}/calculation/${calculation?.id}/edit`,
      },
    ],
    [calculation?.title],
  );

  const Content = useMemo(()=>{
    switch (selectedTab) {
      case 0:
        return EstimateComplicated
      case 1:
        return VorPage
      case 2:
        return Resources
      default:
        return Accomplishment
    }
  },[selectedTab])

  return (
    <Stack flex={1} maxWidth="100%" sx={{ height: '100%' }}>
      <ActListContext.Provider
        value={{
          data,
          current: currentAct,
          setCurrent: changeCurrentAct,
          table: tableExecution,
          isFetching,
          refetchActs:refetch,
          getTable: getDataExecution,
          calculation
        }}>
        {calculation && !isPreview && (
          <CalculationLegend
            openDrawer={()=>toggleDrawer(true)}
            hideButton={hideButtons}
            currentAct={currentAct}
            calculation={calculation}
            handleOpenMenuCalculation={handleOpenMenuCalculation}
            selectedTab={selectedTab}
            calculationStatus={status}
            handleSelectedTab={handleSelectedTab}
          />
        )}

        <Stack flexGrow={1} overflow="auto">
          <Content/>
        </Stack>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={MenuOpen}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}>
          <MenuItem
            onClick={() => handleDeleteCalculationClick('Delete')}
            disabled={deleteCalculationResponse.isLoading}>
            Удалить расчет
          </MenuItem>
          <MenuItem
            onClick={() => {
              openEdit(true);
              setAnchorEl(null);
            }}>
            Редактировать
          </MenuItem>
        </Menu>
        <IntegrateDrawer act={currentAct} open={integrateDrawer} close={()=>{toggleDrawer()}}/>
      </ActListContext.Provider>
      <CalculationDrawer open={isDrawerOpen} onClose={onDrawerClose} calculationId={calculation?.id || 0} />
      <ConfirmDialog />
      <ConfirmDiaologDelete />
    </Stack>
  );
};

export default CalculationComplicated;
