import React, { useEffect, useMemo } from 'react';
import { Box, Stack } from '@mui/material';
import { StyledHeadTd, StyledTableBody, StyledTableContainer, StyledTableHead } from './styles';
import { PositionVOR } from '../../../api/calculations/types';
import { TitleTable } from './TitleTable';
import { RowTable } from './RowTable';
import { RootState, useAppDispatch } from '../../../store/store';
import { setTarget } from '../../../store/slices/vor/vor';
import { useSelector } from 'react-redux';

export interface TableModalProps {
  mainWorks: PositionVOR[];
  extraWorks: PositionVOR[];
  handleDelRow: (delId: PositionVOR) => void;
  keyWorkId: any;
}

export const TableModal: React.FC<TableModalProps> = ({ mainWorks, extraWorks, handleDelRow, keyWorkId }) => {
  const { changeableFields: fieldState } = useSelector((state: RootState) => state.vor.table);

  const arrIdx = useMemo(() => {
    if (fieldState.selectUnit.value) {
      return mainWorks
        .filter((item) => {
          if (fieldState.selectUnit.value === 'kilogram' || fieldState.selectUnit.value === 'tonn') {
            if (item.unitType === 'kilogram') {
              return false;
            }
            if (item.unitType === 'tonn') {
              return false;
            }
          }

          return item.unitType !== fieldState.selectUnit.value;
        })
        .map((item) => {
          return Number(item.number);
        });
    } else return [];
  }, [fieldState.selectUnit.value, mainWorks]);

  const dispatch = useAppDispatch();

  const handleChangeKeyWork = (work: PositionVOR) => {
    dispatch(setTarget(work));
  };

  useEffect(() => {
    if (mainWorks.length === 1) {
      dispatch(setTarget(mainWorks[0]));
    }
  }, [mainWorks.length]);
  return (
    <Box sx={{ width: '1860px', padding: '20px' }}>
      <StyledTableContainer className="StyledTableContainer">
        <Stack direction="row">
          <StyledTableHead className="TableHead">
            <StyledHeadTd></StyledHeadTd>
            <StyledHeadTd>№ п/п</StyledHeadTd>
            <StyledHeadTd>Шифр ЛСР</StyledHeadTd>
            <StyledHeadTd>№ по ЛСР</StyledHeadTd>
            <StyledHeadTd>Раздел</StyledHeadTd>
            <StyledHeadTd>Заголовок</StyledHeadTd>
            <StyledHeadTd>Код расценки</StyledHeadTd>
            <StyledHeadTd>Наименование</StyledHeadTd>
            <StyledHeadTd>Ед. изм</StyledHeadTd>
            <StyledHeadTd>Кол-во</StyledHeadTd>
            <StyledHeadTd>ТЗ ОР, чел-ч</StyledHeadTd>
            <StyledHeadTd>ТЗ М, чел-ч</StyledHeadTd>
            <StyledHeadTd style={{ borderRight: 'none' }}>Всего стоимость</StyledHeadTd>
            <StyledHeadTd></StyledHeadTd>
          </StyledTableHead>
          <StyledHeadTd
            style={{
              padding: 0,
              width: '12px',
              height: '30px',
              borderBottom: '1px solid #d2dcff',
              borderRight: 'none',
            }}></StyledHeadTd>
        </Stack>
        <StyledTableBody className="TableBody">
          <TitleTable title={'Основные позиции'} arrIdx={arrIdx} count={mainWorks.length} />
          {mainWorks.map((element) => {
            return (
              <RowTable
                key={element.id}
                level={element}
                keyWorkId={keyWorkId}
                handleChangeKeyWork={() => handleChangeKeyWork(element)}
                handleDelRow={handleDelRow}
              />
            );
          })}
          <TitleTable title={'Вспомогательные позиции'} count={extraWorks.length} />
          {extraWorks.map((element) => {
            return (
              <RowTable
                key={element.id}
                level={element}
                mode={'notCheckbox'}
                handleChangeKeyWork={() => handleChangeKeyWork(element)}
                keyWorkId={keyWorkId}
                handleDelRow={handleDelRow}
              />
            );
          })}
        </StyledTableBody>
      </StyledTableContainer>
    </Box>
  );
};
