import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/material';
import { useGetUsersQuery } from 'api/users';
import { AutocompleteField } from 'components/AutocompleteField';
import { FieldArray, useFormikContext } from 'formik';
import { useAppContext } from 'layouts/Layout/context';
import { FC } from 'react';
import { ProjectFormData } from '../../ProjectManagement.types';
import { FormItem } from '../FormItem';
import { FormTextField, FormTitledWrapper } from '../FormLeftBlock';
import { MapFormButton } from '../MapForm';
import { ContractorWrapper, EmployeesContractorsDelete, EmployeesContractorsWrapper } from './EmployeesBlock.styles';

export const EmployeesBlock: FC = () => {
  const { currentCompanyId, profile } = useAppContext();
  const { values: formValues, setFieldValue, initialValues } = useFormikContext<ProjectFormData>();

  const initialContractorCount = initialValues.contractors.length;

  const { data } = useGetUsersQuery({ companyId: currentCompanyId });

  const onResponsibleClick = () => {
    setFieldValue('customer.data', `${profile.id}&${profile.firstName} ${profile.lastName}`);
  };

  return (
    <>
      <FormTitledWrapper title="Заказчик" variant="primary" contentWrapper="stack" bordered="border">
        <Stack alignItems="end" spacing={1.5}>
          <FormItem
            title="Компания"
            input={
              <FormTextField version="project" name="customer.companyName" placeholder="Введите название компании" />
            }
          />
          <FormItem
            title="Куратор"
            input={
              <AutocompleteField
                fieldName="customer.data"
                placeholder="Введите ФИО или выберите из списка"
                data={
                  data?.data.map(({ id, firstName, lastName }) => ({
                    value: `${id}&${firstName} ${lastName}`,
                    label: `${firstName} ${lastName}`,
                    key: id,
                  })) || []
                }
                freeSolo
              />
            }
          />
          <MapFormButton onClick={onResponsibleClick} variant="text">
            Я ответственный за проект
          </MapFormButton>
        </Stack>
      </FormTitledWrapper>
      <EmployeesContractorsWrapper title="Подрядчики" variant="primary" contentWrapper="stack" bordered="none">
        <FieldArray
          name="contractors"
          validateOnChange={false}
          render={({ push, remove }) => {
            return formValues.contractors.map((contractor, index) => (
              <ContractorWrapper key={index} title="" variant="primary" contentWrapper="stack" bordered="border">
                {index > initialContractorCount - 1 && (
                  <EmployeesContractorsDelete onClick={() => remove(index)}>
                    <DeleteIcon fontSize="medium" color="error" />
                  </EmployeesContractorsDelete>
                )}
                <FormItem
                  title="Компания"
                  input={
                    <FormTextField
                      version="project"
                      name={`contractors[${index}].companyName`}
                      placeholder="Введите название компании"
                    />
                  }
                />
                <FormItem
                  title="Ответственный"
                  input={
                    <Stack flex={1} alignItems="end" spacing={1}>
                      <AutocompleteField
                        fieldName={`contractors[${index}].data`}
                        placeholder="Введите ФИО или выберите из списка"
                        data={
                          data?.data.map((user) => ({
                            value: `${user.id}&${user.firstName} ${user.lastName}`,
                            label: `${user.firstName} ${user.lastName}`,
                            key: user.id,
                          })) || []
                        }
                        groupBy={(item) => item.group}
                        freeSolo
                      />
                      {index === formValues.contractors.length - 1 && (
                        <MapFormButton onClick={() => push({ data: '', companyName: '' })} variant="text">
                          Добавить подрядчика
                        </MapFormButton>
                      )}
                    </Stack>
                  }
                />
              </ContractorWrapper>
            ));
          }}
        />
      </EmployeesContractorsWrapper>
    </>
  );
};
