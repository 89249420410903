/**
 * @author Mr_fabiozZz[mr.fabiozzz@gmail.com]
 */
import React, { useCallback, useEffect } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import {
  CreateActForm,
  Field
} from "pages/Calculations/components/Accomplishment/components/ActDialog/ActDialog.types";
import {
  DateWrapper,
  IconDate,
  Label,
  LabelWrapper
} from "pages/Calculations/components/Accomplishment/components/ActDialog/ActDialog.style";
import { DatePickerRenderInput } from "components/FieldForm/styles";
import { addDays, isAfter, isBefore, isDate, lastDayOfMonth, startOfMonth, sub } from "date-fns";
import { formatToDate } from "../../../../../../utils/formatDate";
import { checkDate } from "./utils";

interface ICreateActInput extends Omit<Field, 'id'> {
  disabledDates: {
    start: Date;
    end: Date;
  }[];
  setValidate: (key: 'startDate' | 'endDate' | 'onDate', str: string) => void;
}

const CreateActInput: React.FC<ICreateActInput> = ({ render, disabledDates, label, Icon, setValidate }) => {
  const { control, setValue, getValues } = useFormContext<CreateActForm>();
  const watchStartDate = useWatch({
    control,
    name: 'startDate',
  });
  const watchEndDate = useWatch({
    control,
    name: 'endDate',
  });
  const watchOnDate = useWatch({
    control,
    name: 'onDate',
  });


  /**
   * Здесь идет расчет
   * вычисляется какое это поле и устанавливает максимальную и минимальную даты
   */
  const disabledRange = useCallback(
    (type: 'startDate' | 'endDate' | 'onDate', date?: Date | null) => {
      let startD = getValues('startDate') || null;
      let endD = getValues('endDate') || null;

      if (render === 'startDate' && date) {
        startD = date;
      }
      if (render === 'endDate' && date) {
        endD = date;
      }

      const sortedDates = [...disabledDates, {} as any]
        .sort((a, b) => a?.start?.getTime() - b?.start?.getTime())
        .map((d, i, array) => {
          if (i == 0) {
            return { start: null, end: d.start ?? null };
          } else
            return {
              start: array[i - 1].end,
              end: d?.start ?? null,
            };
        });
      const dates: { minDate: null | Date; maxDate: null | Date } = {
        minDate: null, 
        maxDate: null,
      };

      if ((!startD && !endD) || type === 'onDate') return dates;
      if (sortedDates.length === 1) {
        if (type === 'startDate') {
          if (endD) {
            const date = endD.getDate();
            const year = endD.getFullYear();
            const month = endD.getMonth();
            dates.maxDate = new Date(year, month, date - 1);
            return dates;
          }
        }
        if (type === 'endDate') {
          if (startD) {
            const date = startD.getDate();
            const year = startD.getFullYear();
            const month = startD.getMonth();
            dates.minDate = new Date(year, month, date + 1);
            return dates;
          }
        }
      }

      for (let { start, end } of sortedDates) {
        if (type === 'startDate') {
          let d: Date | null = null;
          if (endD) {
            const date = endD.getDate();
            const year = endD.getFullYear();
            const month = endD.getMonth();
            d = new Date(year, month, date - 1);
          }
          if (start === null && endD && isBefore(endD, end)) {
            dates.minDate = start;
            dates.maxDate = d ?? end;
            return dates;
          } else if (end === null && isAfter(endD as Date, start)) {
            dates.minDate = start;
            dates.maxDate = d ?? end;
            return dates;
          } else {
            if (isAfter(endD as Date, start) && isBefore(endD as Date, end)) {
              dates.minDate = start;
              dates.maxDate = d ?? end;
              return dates;
            }
          }
        }
        if (type === 'endDate') {
          let d: Date | null = null;
          if (startD) {
            const date = startD.getDate();
            const year = startD.getFullYear();
            const month = startD.getMonth();
            d = new Date(year, month, date + 1);
          }
          if (start === null && startD && isBefore(startD, end)) {
            dates.minDate = d ?? start;
            dates.maxDate = end;
            return dates;
          } else if (end === null && isAfter(startD as Date, start)) {
            dates.minDate = d ?? start;
            dates.maxDate = end;
            return dates;
          } else {
            if (isAfter(startD as Date, start) && isBefore(startD as Date, end)) {
              dates.minDate = d ?? start;
              dates.maxDate = end;
              return dates;
            }
          }
        }
      }
      return dates;
    },
    [disabledDates, watchEndDate, watchStartDate],
  );

  const defaultMonth = useCallback(
    (inputKey: typeof render) => {
      if (inputKey === 'startDate') {
        if (watchEndDate && isNaN(watchEndDate.getTime())) {
          return null;
        }
        return watchEndDate;
      }
      if (inputKey === 'endDate') {
        if (watchStartDate && isNaN(watchStartDate.getTime())) {
          return null;
        }
        return watchStartDate;
      }
      if (inputKey === 'onDate') {
        if (watchEndDate && !isNaN(watchEndDate.getTime())) {
          return watchEndDate;
        }
        if (watchStartDate && !isNaN(watchStartDate.getTime())) {
          return watchStartDate;
        }
        return null;
      }
      return null;
    },
    [watchEndDate, watchStartDate],
  );

  const val = useCallback(
    (value: any) => {
      return render === 'startDate'
        ? watchStartDate || value || null
        : render === 'endDate'
        ? watchEndDate || value || null
        : isDate(value)
        ? value
        : null;
    },
    [watchEndDate, watchStartDate, render],
  );

  const shouldDisabled = (dayOrMonth: unknown) => {
    if (render === 'onDate') return false;
    if (dayOrMonth) {
      if (disabledDates.length) {
        if (render === 'startDate') {
          return disabledDates.some(({ start, end }) => checkDate(dayOrMonth as Date, start, end));
        }
        if (render === 'endDate') {
          return disabledDates.some(({ start, end }) => checkDate(dayOrMonth as Date, start, end));
        }
      }
    }
    return false;
  };

  useEffect(() => {
    if (!watchOnDate && watchEndDate && watchStartDate) {
      setValue('onDate', new Date(), { shouldValidate: true });
      setValidate('onDate', new Date().toLocaleDateString('ru-RU'));
    }
  }, [watchEndDate, watchStartDate, watchOnDate]);

  return (
    <LabelWrapper>
      <Label>
        <Icon />
        {label}
      </Label>
      <Controller
        name={render as keyof CreateActForm}
        control={control}
        render={({ field: { ref, value, ...props } }) => {
          return (
            <DateWrapper
              {...props}
              inputRef={ref}
              value={val(value)}
              components={{
                OpenPickerIcon: IconDate,
              }}
              defaultCalendarMonth={defaultMonth(render)}
              maxDate={
                render === 'startDate' ? null : disabledRange(render as 'startDate' | 'endDate' | 'onDate').maxDate
              }
              minDate={
                render === 'startDate' ? null : disabledRange(render as 'startDate' | 'endDate' | 'onDate').minDate
              }
              openTo="year"
              views={['year', 'month', 'day']}
              onChange={(e: any, keyboardInputValue) => {
                const userValue = keyboardInputValue
                  ? keyboardInputValue
                  : e && !isNaN(e.getTime()) && !keyboardInputValue
                  ? e.toLocaleDateString('ru-RU')
                  : '';
                setValidate(render as any, userValue);
                if (/^\d{2}\.\d{2}\.\d{4}/.test(userValue)) {
                  setValue(render as any, e, { shouldValidate: true });
                  if (render === 'startDate') {
                    const lastDay = lastDayOfMonth(e);
                    const endDate =
                      disabledRange('endDate', e).maxDate &&
                      sub(disabledRange('endDate', e).maxDate as Date, { days: 1 });
                    if (endDate && isDate(endDate) && endDate.getMonth() === e.getMonth()) {
                      setValue('endDate', endDate, { shouldValidate: true });
                      setValidate('endDate', endDate.toLocaleDateString('ru-RU'));
                    } else {
                      if (!isNaN(lastDay.getTime())) {
                        setValue('endDate', lastDay, { shouldValidate: true });
                        setValidate('endDate', lastDay.toLocaleDateString('ru-RU'));
                      } else {
                        setValue('endDate', null, { shouldValidate: true });
                        setValidate('endDate', '');
                      }
                    }
                  }
                  if (render === 'endDate' && !watchStartDate) {
                    const lastDay = startOfMonth(e);
                    const endDate =
                      disabledRange('startDate', e).minDate &&
                      addDays(disabledRange('startDate', e).minDate as Date, 1);
                    if (endDate && endDate.getMonth() === e.getMonth()) {
                      setValue('startDate', endDate, { shouldValidate: true });
                      setValidate('startDate', endDate.toLocaleDateString('ru-RU'));
                    } else {
                      setValue('startDate', lastDay, { shouldValidate: true });
                      setValidate('startDate', lastDay.toLocaleDateString('ru-RU'));
                    }
                  }
                }
              }}
              shouldDisableMonth={shouldDisabled}
              shouldDisableDate={shouldDisabled}
              renderInput={(params) => (
                <DatePickerRenderInput
                  // helperText={isError ? t(error as string, { maxLength }) : undefined}
                  inputRef={ref}
                  {...props}
                  {...params}
                  value={val(value)}
                  // error={isError}
                  onChange={(e) => {
                    e.stopPropagation();
                    if (!e.target.value) {
                      setValue(render as any, null);
                      return;
                    }
                    if (/\^(\d{2})\.(\d{2})\.(\d{4})$/.test(e.target.value)) {
                      const date = new Date(formatToDate(e!.target!.value! as string, 'yyyy-MM-dd')!);
                      // props.onChange(!isNaN(date.getTime()) ? date : e.target.value);
                      setValue(render as any, !isNaN(date.getTime()) ? date : e.target.value);
                    }
                  }}
                  inputProps={{
                    ...params.inputProps,
                    style: { padding: '6px 6px 6px 8px' },
                    placeholder: 'дд.мм.гггг',
                    // maxLength,
                    // minLength,
                    autoComplete: 'off',
                  }}
                />
              )}
            />
          );
        }}
      />
    </LabelWrapper>
  );
};

export default CreateActInput;
