/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box, createFilterOptions, Divider, IconButton, MenuItem } from "@mui/material";
import { ICellEditorParams } from "ag-grid-community";
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { IEstimateReference, resourceRus } from "../../../../../../api/references/estimates/estimates.types";
import { getPricesState } from "../../../../../../store/slices/references/prices/prices";
import { UnitWeight } from "../../../../../../store/slices/references/prices/types";
import {
  PRStyledSelect
} from "../../../../../Calculations/components/Accomplishment/components/CaptionTable/CaptionTable.style";
import { PricesReferenceContext } from "../../PricesReference";
import { modifyValue, PopperComponent } from "../../useTableData";
import { PRNumericField, StyledAutocomplete } from "../PricesReferences.style";
import { PRStyledBaseTextField } from "../../../../../../components/BaseTextField/BaseTextField.styles";

const filterUnit = createFilterOptions<UnitWeight & { label: string }>();
const filterGroup = createFilterOptions<{ title: string; id: number } & { label: string }>();
const disabledFields = ['MiM', 'WORKERS', 'MACHINE_OPERATORS'];
const requiredNamesForNum = ['pz', 'fot', 'mim', 'mtr', 'totalWorkTime', 'kfoCoefficient'];
const requiredNamesForNumExclude = ['pz', 'fot', 'mim', 'mtr', 'totalWorkTime'];

const CellEditor = forwardRef<any, ICellEditorParams<IEstimateReference, any, any>>((gridCell, ref) => {
  const { unitList, groupList } = useSelector(getPricesState);
  const { deleteUnit, deleteGroup } = useContext(PricesReferenceContext);


  const [value, setValue] = useState<{ input0: string | number; input1: string }>({ input0: '', input1: '' });
  // resourceRus[props.value?.toString()?.replace(/\./, ',') as keyof typeof resourceRus] || '',

  useEffect(() => {
    if (requiredNamesForNumExclude.includes(gridCell.colDef.field as string)) {
      const v = modifyValue(gridCell.value) || '';
      // const v = props.value?.toString()?.replace('.', ',');

      setValue((prevState) => ({ ...prevState, input0: v || '' }));
    } else {
      if (gridCell.colDef.field === 'resourceType') {
        // setValue(resourceRus[props.value as keyof typeof resourceRus] || '');
        setValue((prevState) => ({ ...prevState, input0: resourceRus[gridCell.value as keyof typeof resourceRus] || '' }));
      } else if (gridCell.colDef.field === 'kfoCoefficient') {
        setValue((prevState) => ({
          ...prevState,
          input0: modifyValue(gridCell.data!.kfoCoefficient, false) || '',
        }));
      } else if (gridCell.colDef.field === 'unit') {
        setValue({
          input0: gridCell.data!.unit!,
          input1: modifyValue(gridCell.data!.qty, false) || '',
        });
      } else {
        setValue((prevState) => ({ ...prevState, input0: gridCell.value }));
      }
    }
  }, [gridCell]);
  // const { control: controlRate, setValue: setValueRate } = useForm<FormPriceReference>();
  // const { control: controlPosition, setValue: setValuePosition } = useForm<FormPriceReferencePosition>();

  const {
    control,
    setValue: setFormValue,
    formState,
    reset
  } = useForm<{ input0: string | number; input1: string | number }>({
    mode: 'all',
    defaultValues: {
      input0: '',
      input1: '',
    },
  });

  useImperativeHandle(
    ref,
    () => ({
      getValue: () => {
        const copyValue = { ...value };
        if (requiredNamesForNum.includes(gridCell.colDef.field as string)) {
          const valReplaced = Number((value.input0 as string)?.replace(/\s/g, '').replace(/,/g, '.'));
          if (gridCell.colDef.field === 'unit') {
            return { ...copyValue, input0: valReplaced };
          } else if (gridCell.colDef.field === 'pz') {
            return { ...copyValue, input0: valReplaced || null };
          } else {
            return { ...copyValue, input0: copyValue.input0 === '' ? null : valReplaced ?? null };
          }
        }
        return copyValue;
      },
      isCancelAfterEnd() {
        return!formState.isValid && !formState.isDirty;
      },
    }),
    [formState.isValid, value],
  );
  useEffect(() => {
    if (gridCell.colDef.field !== 'unit') {
      gridCell?.eGridCell?.getElementsByTagName('input')[0]?.select();
      gridCell?.eGridCell?.getElementsByTagName('input')[0]?.focus();
    }
    // }
  }, []);

  const isKFOGroup = useMemo(() => {
    return gridCell.colDef.field === 'kfoName';
  }, [gridCell]);

  const deleteItem = isKFOGroup ? deleteGroup : deleteUnit;

  const renderIcon = useCallback(
    (id: number) => {
      return isKFOGroup ? (
        <IconButton
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            deleteItem(id);
          }}>
          <DeleteOutlineIcon fontSize={'small'} />
        </IconButton>
      ) : (
        <span />
      );
    },
    [isKFOGroup, deleteItem],
  );

  switch (gridCell.colDef.field) {
    case 'kfoName': {
      const refPopperUnit = useRef<HTMLDivElement | null>(null);
      const refPopperQTY = useRef<HTMLInputElement | null>(null);
      const deleteGroupOrUnit = deleteGroup;
      const optionList = isKFOGroup ? groupList.map((_) => ({ ..._, name: _.title })) : unitList;
      switch (gridCell.data?.rowType) {
        case 'RATE':
          return (
            <Box display={'flex'} alignItems={'center'} /*p={'0 8px'}*/ width={'100%'} height={'100%'}>
              <Controller
                control={control as any}
                name={'input0'}
                rules={{
                  validate: {
                    maxLength(v) {
                      if (v && v.length > 20) {
                        return 'максимум 20 символов';
                      }
                      return true;
                    },
                  },
                }}
                render={({ field: { ref, ...propsController }, formState }) => {
                  const errorMessage = formState.errors?.input0?.message || '';
                  return (
                    <>
                      <StyledAutocomplete
                        ref={refPopperUnit}
                        itemRef={refPopperUnit.current as any}
                        {...propsController}
                        sx={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        componentsProps={{
                          paper: {
                            sx: {
                              width: 'min-content',
                              minWidth:'100%'
                            },
                          },
                        }}
                        options={optionList.map((_) => ({ ..._, label: _.name }))}
                        // onSelect={props.onChange}
                        aria-valuemax={20}
                        filterOptions={(options: any[], params) => {
                          const filtered = filterUnit(options, params);
                          const { inputValue } = params;
                          const isExisting = options.some((option) => inputValue === option.name);
                          if (inputValue !== '' && !isExisting) {
                            filtered.push({
                              id: -1,
                              name: 'Нет совпадений',
                              label: 'Нет совпадений',
                            });
                          }
                          return filtered;
                        }}
                        // componentsProps={{
                        //   paper: {
                        //     sx: {
                        //       width: 200,
                        //     },
                        //   },
                        // }}
                        value={(value.input0 as string) || ''}
                        inputValue={(value.input0 as string) || ''}
                        freeSolo={true}
                        noOptionsText="Будет создана новая запись"
                        disableClearable={true}
                        clearOnBlur={false}
                        autoSelect={false}
                        onChange={(event, val) => {
                          if (typeof val === 'string') {
                            let value = val;
                            // if (props.colDef.field === 'kfoName') {
                              // value = value.toLowerCase();
                            // }
                            setValue((prevState) => ({ ...prevState, input0: value }));
                            setFormValue('input0', value, { shouldValidate: true });
                          }
                        }}
                        autoFocus={true}
                        onBlur={() => gridCell.stopEditing(true)}
                        openOnFocus={true}
                        onHighlightChange={(event, option, reason) => {}}
                        // onBlur={() => {
                        //   props.onChange(value === '' ? props.value : value);
                        // }}
                        // onClose={() => {
                        //   props.onChange(value);
                        // }}
                        onInputChange={(event, val) => {
                          if (typeof val === 'string') {
                            let value = val;
                            // if (props.colDef.field === 'kfoName') {
                            //   value = value.toLowerCase();
                            // }
                            setValue((prevState) => ({ ...prevState, input0: value }));
                            setFormValue('input0', value, { shouldValidate: true });
                          }
                        }}
                        renderInput={(p) => (
                          <PRStyledBaseTextField
                            {...p}
                            hiddenLabel
                            placeholder={'Не выбрано'}
                            $textAlign={'center'}
                            InputProps={{
                              ...p.InputProps,
                              ...((!!errorMessage) && { sx: { background: '#FFB1B126 !important' } }),
                            }}
                            inputProps={{
                              ...p?.inputProps,
                            }}
                            size="small"
                            error={!!errorMessage}
                          />
                        )}
                        renderOption={(props, option: any) => {
                          return (
                            <MenuItem sx={{ width: '100%' }} {...props} disabled={option.id < 0} key={option.id}>
                              <Box
                                width={'100%'}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'space-between'}>
                                <span style={{ whiteSpace: 'break-spaces', wordWrap: 'break-word' }}>
                                  {option.name}
                                </span>
                                {option.id > 10 ? (
                                  <IconButton
                                    onClick={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();

                                      // reset();
                                      // gridCell.stopEditing();
                                      // refPopperUnit.current?.focus()
                                      // document.body.dispatchEvent(new KeyboardEvent('keydown', {'key': 'Escape'||'Esc'}));
                                      // document.dispatchEvent(new Event('keydown',{cancelable:true,bubbles:true}));
                                      gridCell.api.stopEditing(true)
                                      // setTimeout(()=>{
                                         deleteGroupOrUnit(option.id);
                                      // },300)
                                    }}>
                                    <DeleteOutlineIcon fontSize={'small'} />
                                  </IconButton>
                                ) : (
                                  <span />
                                )}
                              </Box>
                            </MenuItem>
                          );
                        }}
                      />
                      <PopperComponent message={errorMessage as string} refItem={refPopperUnit.current} />
                    </>
                  );
                }}
              />
            </Box>
          );
        case 'RATE_POSITION':
          return (
            <Box
              // borderLeft={'1px solid #e2e2e2'}
              // borderRight={'1px solid #e2e2e2'}
              display={'grid !important'}
              height={'100% !important'}
              p={'0 !important'}
              alignItems={'center'}
              boxSizing={'border-box'}
              justifyItems={'center'}
              gridTemplateColumns={'139.5px 1px 139.5px'}>
              <Box
                // component={'form'}
                // onSubmit={handleSubmit}
                display={'flex'}
                // p={'0 8px'}
                alignItems={'center'}
                width={'100%'}
                height={'100%'}>
                <Controller
                  control={control as any}
                  name={'input0'}
                  rules={{
                    validate: {
                      maxLength(v) {
                        if (v && v.length > 20) {
                          return 'максимум 20 символов';
                        }
                        return true;
                      },
                    },
                  }}
                  render={({ field: { ref, ...propsControl }, formState }) => {
                    const errorMessage = formState.errors?.input0?.message;
                    //   // @ts-ignore
                    //   formState.errors?.[Math.abs(data?.id!)]?.unit?.message;
                    // console.log(unitList);
                    // console.log(props.value);
                    return (
                      <>
                        <StyledAutocomplete
                          ref={ref}
                          itemRef={refPopperUnit.current as any}
                          {...propsControl}
                          disabled={disabledFields.includes(gridCell.data?.resourceType || '')}
                          sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          componentsProps={{
                            paper: {
                              sx: {
                                width: 'min-content',
                                minWidth:'100%'
                              },
                            },
                          }}

                          options={unitList.map((_) => ({ ..._, label: _.name }))}
                          // onSelect={props.onChange}
                          aria-valuemax={20}
                          filterOptions={(options: any[], params) => {
                            const filtered = filterUnit(options, params);
                            const { inputValue } = params;
                            const isExisting = options.some((option) => inputValue === option.name);
                            if (inputValue !== '' && !isExisting) {
                              filtered.push({
                                id: -1,
                                name: 'Нет совпадений',
                                label: 'Нет совпадений',
                              });
                            }
                            return filtered;
                          }}
                          // componentsProps={{
                          //   paper: {
                          //     sx: {
                          //       width: 200,
                          //     },
                          //   },
                          // }}

                          value={(value.input0 as string) || ''}
                          inputValue={(value.input0 as string) || ''}
                          freeSolo={true}
                          noOptionsText="Будет создана новая запись"
                          disableClearable={true}
                          clearOnBlur={false}
                          autoSelect={false}
                          onBlur={() => !formState.isDirty && formState.isValid && gridCell.stopEditing(true)}
                          onChange={(event, val) => {
                            if (typeof val === 'string') {
                              setValue((prevState) => ({ ...prevState, input0: val }));
                              setFormValue('input0', val, { shouldValidate: true });
                            }
                          }}
                          openOnFocus={true}
                          onHighlightChange={(event, option, reason) => {}}
                          // onBlur={() => {
                          //   props.onChange(value === '' ? props.value : value);
                          // }}
                          // onClose={() => {
                          //   props.onChange(value);
                          // }}
                          onInputChange={(event, val) => {
                            if (typeof val === 'string') {
                              setValue((prevState) => ({ ...prevState, input0: val }));
                              setFormValue('input0', val, { shouldValidate: true });
                            }
                          }}
                          renderInput={(p) => (
                            <PRStyledBaseTextField
                              {...p}
                              hiddenLabel
                              placeholder={'Не выбрано'}
                              $textAlign={'center'}
                              InputProps={{
                                ...p.InputProps,
                                // ...((error || !!errorMessage) && { sx: { background: '#FFB1B126 !important' } }),
                              }}
                              inputProps={{
                                ...p?.inputProps,
                              }}
                              size="small"
                              // error={error || !!errorMessage}
                            />
                          )}
                          renderOption={(props, option: any) => {
                            return (
                              <MenuItem sx={{ width: '100%' }} {...props} disabled={option.id < 0} key={option.id}>
                                <Box
                                  width={'100%'}
                                  display={'flex'}
                                  alignItems={'center'}
                                  justifyContent={'space-between'}>
                                  {option.name}
                                  {option.id > 10 ? (
                                    <IconButton
                                      onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        gridCell.api.stopEditing(true)
                                        deleteGroupOrUnit(option.id);
                                      }}>
                                      <DeleteOutlineIcon fontSize={'small'} />
                                    </IconButton>
                                  ) : (
                                    <span />
                                  )}
                                </Box>
                              </MenuItem>
                            );
                          }}
                        />
                        <PopperComponent message={errorMessage as string} refItem={refPopperUnit.current} />
                      </>
                    );
                  }}
                />
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box
                // component={'form'}
                display={'flex'}
                alignItems={'center'}
                // p={'0 8px'}
                width={'100%'}
                height={'100%'}
                // id="references-estimate"
                // onSubmit={handleSubmit}
              >
                <Controller
                  control={control as any}
                  name={'input1'}
                  rules={{
                    validate: {
                      max: (v: string) => {
                        const num = Number((v as string)?.replace(/\s/g, '').replace(/,/g, '.'));
                        if (num >= 999999999.999999) {
                          return 'Максимальное значение 999 999 999,999 999';
                        }
                        return true;
                      },
                      min: (v: string) => {
                        const num = Number((v as string)?.replace(/\s/g, '').replace(/,/g, '.'));
                        if (num <= -999999999.999999) {
                          return 'Минимальное значение -999 999 999,999 999';
                        }
                        return true;
                      },
                    },
                  }}
                  render={({ field: { ref, onChange, ...propsController }, formState }) => {
                    //@ts-ignore
                    const errorMessage = formState.errors?.input1?.message || '';
                    return (
                      <>
                        <PRNumericField
                          getInputRef={(input: any) => {
                            refPopperQTY.current = input;
                          }}
                          // disabled={isCreated && data!.id < -100}
                          {...propsController}
                          value={value.input1}
                          onValueChange={(event) => {
                            // onChange(event.formattedValue.replace(/\./, ','));
                            setValue((prevState) => ({
                              ...prevState,
                              input1: event.formattedValue.replace(/\./, ','),
                            }));
                            setFormValue('input1', event.formattedValue.replace(/\./, ','), { shouldValidate: true });
                          }}
                          style={{
                            textAlign: 'center',
                            // ...((errorQTY || !!errorMessage) && {
                            //   background: '#FFB1B126',
                            //   borderColor: '#F46B6B',
                            // }),
                          }}
                          onBlur={() => {
                            !formState.isDirty && formState.isValid && gridCell.stopEditing(true);
                          }}
                          onFocus={(e: { target: { select: () => void } }) => e.target.select()}
                          allowedDecimalSeparators={[',', '.']}
                          allowNegative={true}
                          decimalSeparator={','}
                          decimalScale={6}
                          thousandSeparator={' '}
                        />
                        <PopperComponent message={errorMessage as string} refItem={refPopperQTY.current} />
                      </>
                    );
                  }}
                />
              </Box>
            </Box>
          );
        default:
          return null;
      }
    }
    case 'kfoUnit':
    case 'unit': {
      const refPopperUnit = useRef<HTMLDivElement | null>(null);
      const refPopperQTY = useRef<HTMLInputElement | null>(null);
      const deleteGroupOrUnit = deleteUnit;
      const optionList = isKFOGroup ? groupList.map((_) => ({ ..._, name: _.title })) : unitList;
      switch (gridCell.data?.rowType) {
        case 'RATE':
          return (
            <Box display={'flex'} alignItems={'center'} /*p={'0 8px'}*/ width={'100%'} height={'100%'}>
              <Controller
                control={control as any}
                name={'input0'}
                rules={{
                  validate: {
                    maxLength(v) {
                      if (v && v.length > 10) {
                        return 'максимум 10 символов';
                      }
                      return true;
                    },
                  },
                }}
                render={({ field: { ref, ...propsController }, formState }) => {
                  const errorMessage = formState.errors?.input0?.message || '';
                  return (
                    <>
                      <StyledAutocomplete
                        ref={refPopperUnit}
                        itemRef={refPopperUnit.current as any}
                        {...propsController}
                        sx={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        componentsProps={{
                          paper: {
                            sx: {
                              width: 'min-content',
                              minWidth:'100%'
                            },
                          },
                        }}

                        options={optionList.map((_) => ({ ..._, label: _.name }))}
                        // onSelect={props.onChange}
                        aria-valuemax={10}
                        filterOptions={(options: any[], params) => {
                          const filtered = filterUnit(options, params);
                          const { inputValue } = params;
                          const isExisting = options.some((option) => inputValue === option.name);
                          if (inputValue !== '' && !isExisting) {
                            filtered.push({
                              id: -1,
                              name: 'Нет совпадений',
                              label: 'Нет совпадений',
                            });
                          }
                          return filtered;
                        }}
                        // componentsProps={{
                        //   paper: {
                        //     sx: {
                        //       width: 200,
                        //     },
                        //   },
                        // }}
                        value={(value.input0 as string) || ''}
                        inputValue={(value.input0 as string) || ''}
                        freeSolo={true}
                        noOptionsText="Будет создана новая запись"
                        disableClearable={true}
                        clearOnBlur={false}
                        autoSelect={false}
                        onChange={(event, val) => {
                          if (typeof val === 'string') {
                            let value = val;
                            // if (props.colDef.field === 'kfoName') {
                            //   value = value.toLowerCase();
                            // }
                            setValue((prevState) => ({ ...prevState, input0: value }));
                            setFormValue('input0', value, { shouldValidate: true });
                          }
                        }}
                        autoFocus={true}
                        onBlur={() => gridCell.stopEditing(true)}
                        openOnFocus={true}
                        onHighlightChange={(event, option, reason) => {}}
                        // onBlur={() => {
                        //   props.onChange(value === '' ? props.value : value);
                        // }}
                        // onClose={() => {
                        //   props.onChange(value);
                        // }}
                        onInputChange={(event, val) => {
                          if (typeof val === 'string') {
                            let value = val;
                            // if (props.colDef.field === 'kfoName') {
                            //   value = value.toLowerCase();
                            // }
                            setValue((prevState) => ({ ...prevState, input0: value }));
                            setFormValue('input0', value, { shouldValidate: true });
                          }
                        }}
                        renderInput={(p) => (
                          <PRStyledBaseTextField
                            {...p}
                            hiddenLabel
                            placeholder={'Не выбрано'}
                            $textAlign={'center'}
                            InputProps={{
                              ...p.InputProps,
                              ...((!!errorMessage) && { sx: { background: '#FFB1B126 !important' } }),
                            }}
                            inputProps={{
                              ...p?.inputProps,
                            }}
                            size="small"
                            error={!!errorMessage}
                          />
                        )}
                        renderOption={(props, option: any) => {
                          return (
                            <MenuItem sx={{ width: '100%' }} {...props} disabled={option.id < 0} key={option.id}>
                              <Box
                                width={'100%'}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'space-between'}>
                                <span style={{ whiteSpace: 'break-spaces', wordWrap: 'break-word' }}>
                                  {option.name}
                                </span>
                                {option.id > 10 ? (
                                  <IconButton
                                    onClick={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();
                                      gridCell.api.stopEditing(true)
                                      deleteGroupOrUnit(option.id);
                                    }}>
                                    <DeleteOutlineIcon fontSize={'small'} />
                                  </IconButton>
                                ) : (
                                  <span />
                                )}
                              </Box>
                            </MenuItem>
                          );
                        }}
                      />
                      <PopperComponent message={errorMessage as string} refItem={refPopperUnit.current} />
                    </>
                  );
                }}
              />
            </Box>
          );
        case 'RATE_POSITION':
          return (
            <Box
              // borderLeft={'1px solid #e2e2e2'}
              // borderRight={'1px solid #e2e2e2'}
              display={'grid !important'}
              height={'100% !important'}
              p={'0 !important'}
              alignItems={'center'}
              boxSizing={'border-box'}
              justifyItems={'center'}
              gridTemplateColumns={'139.5px 1px 139.5px'}>
              <Box
                // component={'form'}
                // onSubmit={handleSubmit}
                display={'flex'}
                // p={'0 8px'}
                alignItems={'center'}
                width={'100%'}
                height={'100%'}>
                <Controller
                  control={control as any}
                  name={'input0'}
                  rules={{
                    validate: {
                      maxLength(v) {
                        if (v && v.length > 10) {
                          return 'максимум 10 символов';
                        }
                        return true;
                      },
                    },
                  }}
                  render={({ field: { ref, ...propsControl }, formState }) => {
                    const errorMessage = formState.errors?.input0?.message;
                    //   // @ts-ignore
                    //   formState.errors?.[Math.abs(data?.id!)]?.unit?.message;
                    // console.log(unitList);
                    // console.log(props.value);
                    return (
                      <>
                        <StyledAutocomplete
                          ref={ref}
                          itemRef={refPopperUnit.current as any}
                          {...propsControl}
                          disabled={disabledFields.includes(gridCell.data?.resourceType || '')}
                          sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          componentsProps={{
                            paper: {
                              sx: {
                                width: 'min-content',
                                minWidth:'100%'
                              },
                            },
                          }}

                          options={unitList.map((_) => ({ ..._, label: _.name }))}
                          // onSelect={props.onChange}
                          aria-valuemax={10}
                          filterOptions={(options: any[], params) => {
                            const filtered = filterUnit(options, params);
                            const { inputValue } = params;
                            const isExisting = options.some((option) => inputValue === option.name);
                            if (inputValue !== '' && !isExisting) {
                              filtered.push({
                                id: -1,
                                name: 'Нет совпадений',
                                label: 'Нет совпадений',
                              });
                            }
                            return filtered;
                          }}
                          // componentsProps={{
                          //   paper: {
                          //     sx: {
                          //       width: 200,
                          //     },
                          //   },
                          // }}

                          value={(value.input0 as string) || ''}
                          inputValue={(value.input0 as string) || ''}
                          freeSolo={true}
                          noOptionsText="Будет создана новая запись"
                          disableClearable={true}
                          clearOnBlur={false}
                          autoSelect={false}
                          onBlur={() => !formState.isDirty && formState.isValid && gridCell.stopEditing(true)}
                          onChange={(event, val) => {
                            if (typeof val === 'string') {
                              setValue((prevState) => ({ ...prevState, input0: val }));
                              setFormValue('input0', val, { shouldValidate: true });
                            }
                          }}
                          openOnFocus={true}
                          onHighlightChange={(event, option, reason) => {}}
                          // onBlur={() => {
                          //   props.onChange(value === '' ? props.value : value);
                          // }}
                          // onClose={() => {
                          //   props.onChange(value);
                          // }}
                          onInputChange={(event, val) => {
                            if (typeof val === 'string') {
                              setValue((prevState) => ({ ...prevState, input0: val }));
                              setFormValue('input0', val, { shouldValidate: true });
                            }
                          }}
                          renderInput={(p) => (
                            <PRStyledBaseTextField
                              {...p}
                              hiddenLabel
                              placeholder={'Не выбрано'}
                              $textAlign={'center'}
                              InputProps={{
                                ...p.InputProps,
                                // ...((error || !!errorMessage) && { sx: { background: '#FFB1B126 !important' } }),
                              }}
                              inputProps={{
                                ...p?.inputProps,
                              }}
                              size="small"
                              // error={error || !!errorMessage}
                            />
                          )}
                          renderOption={(props, option: any) => {
                            return (
                              <MenuItem sx={{ width: '100%' }} {...props} disabled={option.id < 0} key={option.id}>
                                <Box
                                  width={'100%'}
                                  display={'flex'}
                                  alignItems={'center'}
                                  justifyContent={'space-between'}>
                                  {option.name}
                                  {option.id > 10 ? (
                                    <IconButton
                                      onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        gridCell.api.stopEditing(true)
                                        deleteGroupOrUnit(option.id);
                                      }}>
                                      <DeleteOutlineIcon fontSize={'small'} />
                                    </IconButton>
                                  ) : (
                                    <span />
                                  )}
                                </Box>
                              </MenuItem>
                            );
                          }}
                        />
                        <PopperComponent message={errorMessage as string} refItem={refPopperUnit.current} />
                      </>
                    );
                  }}
                />
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box
                // component={'form'}
                display={'flex'}
                alignItems={'center'}
                // p={'0 8px'}
                width={'100%'}
                height={'100%'}
                // id="references-estimate"
                // onSubmit={handleSubmit}
              >
                <Controller
                  control={control as any}
                  name={'input1'}
                  rules={{
                    validate: {
                      max: (v: string) => {
                        const num = Number((v as string)?.replace(/\s/g, '').replace(/,/g, '.'));
                        if (num >= 999999999.999999) {
                          return 'Максимальное значение 999 999 999,999 999';
                        }
                        return true;
                      },
                      min: (v: string) => {
                        const num = Number((v as string)?.replace(/\s/g, '').replace(/,/g, '.'));
                        if (num <= -999999999.999999) {
                          return 'Минимальное значение -999 999 999,999 999';
                        }
                        return true;
                      },
                    },
                  }}
                  render={({ field: { ref, onChange, ...propsController }, formState }) => {
                    //@ts-ignore
                    const errorMessage = formState.errors?.input1?.message || '';
                    return (
                      <>
                        <PRNumericField
                          getInputRef={(input: any) => {
                            refPopperQTY.current = input;
                          }}
                          // disabled={isCreated && data!.id < -100}
                          {...propsController}
                          value={value.input1}
                          onValueChange={(event) => {
                            // onChange(event.formattedValue.replace(/\./, ','));
                            setValue((prevState) => ({
                              ...prevState,
                              input1: event.formattedValue.replace(/\./, ','),
                            }));
                            setFormValue('input1', event.formattedValue.replace(/\./, ','), { shouldValidate: true });
                          }}
                          style={{
                            textAlign: 'center',
                            // ...((errorQTY || !!errorMessage) && {
                            //   background: '#FFB1B126',
                            //   borderColor: '#F46B6B',
                            // }),
                          }}
                          onBlur={() => {
                            console.log(formState);
                            !formState.isDirty && formState.isValid && gridCell.stopEditing(true);
                          }}
                          onFocus={(e: { target: { select: () => void } }) => e.target.select()}
                          allowedDecimalSeparators={[',', '.']}
                          allowNegative={true}
                          decimalSeparator={','}
                          decimalScale={6}
                          thousandSeparator={' '}
                        />
                        <PopperComponent message={errorMessage as string} refItem={refPopperQTY.current} />
                      </>
                    );
                  }}
                />
              </Box>
            </Box>
          );
        default:
          return null;
      }
    }
    // case 'qty': {
    //   return (
    //     <Box
    //       p={'8px'}
    //       justifyContent={'flex-start'}
    //       display={'flex'}
    //       alignItems={'center'}
    //       width={'100%'}
    //       height={'100%'}>
    //       <Controller
    //         control={control as any}
    //         render={({ field: { ref: refInput, ...propsController } }) => {
    //           return (
    //             <NumericField
    //               // textAlign={'center'}
    //               {...propsController}
    //               value={value}
    //               onValueChange={(event) => {
    //                 setValue(() => event.formattedValue.replace(/\./, ','));
    //                 setFormValue('input', event.formattedValue.replace(/\./, ','), { shouldValidate: true });
    //               }}
    //               maxLength={12}
    //               style={{ textAlign: 'center' }}
    //               onFocus={(e: { target: { select: () => void } }) => e.target.select()}
    //               allowedDecimalSeparators={[',', '.']}
    //               allowNegative={true}
    //               decimalSeparator={','}
    //               autoFocus={true}
    //               decimalScale={2}
    //               thousandSeparator={' '}
    //               // onBlur={() => props.stopEditing(true)}
    //             />
    //           );
    //         }}
    //         name={'input' as any}
    //       />
    //     </Box>
    //   );
    // }
    case 'fot':
    case 'mim':
    case 'mtr':
    case 'pz':
    case 'totalWorkTime': {
      const refPopper = useRef<HTMLDivElement | null>(null);

      return (
        <Box
          // p={'2px'}
          justifyContent={'flex-start'}
          display={'flex'}
          alignItems={'center'}
          width={'100%'}
          height={'100%'}>
          <Controller
            control={control as any}
            rules={{
              validate: {
                max: (v: string) => {
                  const num = Number(v?.replace?.(/\s/g, '')?.replace?.(/,/g, '.') || '0');
                  if (num > 999999999.99) {
                    return 'Максимальное значение 999 999 999,99';
                  }
                  return true;
                },
                min: (v: string) => {
                  const num = Number(v?.replace?.(/\s/g, '')?.replace?.(/,/g, '.') || '0');
                  if (num < -999999999.99) {
                    return 'Минимальное значение -999 999 999,99';
                  }
                  return true;
                },
              },
            }}
            render={({ field: { ref: refInput, ...propsController }, formState }) => {
              const errorMessage = formState.errors?.input0?.message || '';
              return (
                <>
                  <PRNumericField
                    // textAlign={'center'}
                    getInputRef={(ref: any) => {
                      refPopper.current = ref;
                    }}
                    {...propsController}
                    value={value.input0}
                    onValueChange={(event) => {
                      setValue((prevState) => ({ ...prevState, input0: event.formattedValue.replace(/\./, ',') }));
                      setFormValue('input0', event.formattedValue, { shouldValidate: true });
                    }}
                    style={{ textAlign: 'right' }}
                    onFocus={(e: { target: { select: () => void } }) => e.target.select()}
                    allowedDecimalSeparators={[',', '.']}
                    allowNegative={true}
                    decimalSeparator={','}
                    autoFocus={true}
                    decimalScale={2}
                    thousandSeparator={' '}
                    onBlur={() => !formState.isDirty && gridCell.stopEditing(true)}
                  />
                  <PopperComponent message={errorMessage as string} refItem={refPopper.current} />
                </>
              );
            }}
            name={'input0' as any}
          />
        </Box>
      );
    }
    case 'kfoCoefficient': {
      const refPopper = useRef<HTMLDivElement | null>(null);

      return (
        <Box
          // p={'2px'}
          justifyContent={'flex-start'}
          display={'flex'}
          alignItems={'center'}
          width={'100%'}
          height={'100%'}>
          <Controller
            control={control as any}
            rules={{
              validate: {
                max: (v: string) => {
                  const num = Number(v?.replace?.(/\s/g, '')?.replace?.(/,/g, '.') || '0');
                  if (num > 999999999.999999) {
                    return 'Максимальное значение 999 999 999,999 999';
                  }
                  return true;
                },
                min: (v: string) => {
                  const num = Number(v?.replace?.(/\s/g, '')?.replace?.(/,/g, '.') || '0');
                  if (num < -999999999.999999) {
                    return 'Минимальное значение -999 999 999,999 999';
                  }
                  return true;
                },
              },
            }}
            render={({ field: { ref: refInput, ...propsController }, formState }) => {
              const errorMessage = formState.errors?.input0?.message || '';
              return (
                <>
                  <PRNumericField
                    // textAlign={'center'}
                    getInputRef={(ref: any) => {
                      refPopper.current = ref;
                    }}
                    {...propsController}
                    value={value.input0}
                    onValueChange={(event) => {
                      setValue((prevState) => ({ ...prevState, input0: event.formattedValue.replace(/\./, ',') }));
                      setFormValue('input0', event.formattedValue, { shouldValidate: true });
                    }}
                    style={{ textAlign: 'right' }}
                    onFocus={(e: { target: { select: () => void } }) => e.target.select()}
                    allowedDecimalSeparators={[',', '.']}
                    allowNegative={true}
                    decimalSeparator={','}
                    autoFocus={true}
                    decimalScale={6}
                    thousandSeparator={' '}
                    onBlur={() => !formState.isDirty && gridCell.stopEditing(true)}
                  />
                  <PopperComponent message={errorMessage as string} refItem={refPopper.current} />
                </>
              );
            }}
            name={'input0' as any}
          />
        </Box>
      );
    }
    case 'description': {
      const refPopper = useRef<HTMLDivElement | null>(null);
      return (
        <Box
          // px={'16px'}
          // py={'2px'}
          justifyContent={'flex-start'}
          display={'flex'}
          alignItems={'center'}
          width={'100%'}
          height={'100%'}>
          <Controller
            control={control}
            rules={{
              validate: {
                maxLength(v) {
                  if (v && (v as string).length > 500) {
                    return 'Максимально 500 символов';
                  }
                  return true;
                },
              },
            }}
            render={({ field: { ref: refInput, ...propsController }, formState }) => {
              const errorMessage = formState.errors?.input0?.message;
              return (
                <>
                  <PRStyledBaseTextField
                    error={!!formState.errors?.input0}
                    inputRef={refPopper}
                    {...propsController}
                    value={value.input0}
                    onChange={(event) => {
                      setValue((prevState) => ({ ...prevState, input0: event.target.value }));
                      setFormValue('input0', event.target.value, { shouldValidate: true });
                    }}
                    onBlur={() => !formState.isDirty && gridCell.stopEditing(true)}
                  />
                  <PopperComponent message={errorMessage as string} refItem={refPopper.current} />
                </>
              );
            }}
            name={'input0' as any}
          />
        </Box>
      );
    }
    case 'title': {
      const refPopper = useRef<HTMLDivElement | null>(null);
      return (
        <Box
          // p={'2px'}
          // justifyContent={'flex-start'}
          // display={'flex'}
          // alignItems={'center'}
          width={'100%'}
          height={'100%'}>
          <Controller
            rules={{
              validate: {
                maxLength(v) {
                  if (v && v.length > 300) {
                    return 'максимум 300 символов';
                  }
                  return true;
                },
              },
            }}
            control={control as any}
            render={({ field: { ref: refInput, ...propsController }, formState }) => {
              const errorMessage = formState.errors?.input0?.message || '';
              return (
                <>
                  <PRStyledBaseTextField
                    // error={
                    //   !isPosition &&
                    //   formState.errors?.[props.name as keyof typeof formState.errors] &&
                    //   !!formState.errors?.[props.name as keyof typeof formState.errors]
                    // }
                    inputRef={refPopper}
                    {...propsController}
                    value={value.input0}
                    onChange={(event) => {
                      setValue((prevState) => ({ ...prevState, input0: event.target.value }));
                      setFormValue('input0', event.target.value, { shouldValidate: true });
                    }}
                    onBlur={() => !formState.isDirty && gridCell.stopEditing(true)}
                  />
                  <PopperComponent message={errorMessage as string} refItem={refPopper.current} />
                </>
              );
            }}
            name={'input0' as any}
          />
        </Box>
      );
    }
    case 'resourceType': {
      return (
        <Box
          // p={'2px'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          width={'100%'}
          height={'100%'}>
          <Controller
            control={control}
            name={'input0' as any}
            render={({ field: { ref: refInput, ...propsController } }) => {
              return (
                <PRStyledSelect
                  ref={refInput}
                  currentValue={value}
                  {...propsController}
                  style={{ width: '100%' }}
                  nullableValues={[value, 'Не выбрано']}
                  value={value}
                  SelectProps={{
                    renderValue: () => value.input0 || 'Не выбрано',
                    defaultOpen: true,
                  }}
                  onClear={() => {}}
                  onBlur={() => !formState.isDirty && gridCell.stopEditing(true)}
                  onChange={(event) => {
                    setValue((prevState) => ({ ...prevState, input0: event.target.value || '' }));
                    setFormValue('input0', event.target.value, { shouldValidate: true });
                  }}
                  select>
                  <MenuItem value={'Рабочие'}>Рабочие</MenuItem>
                  <MenuItem value={'Механизаторы'}>Механизаторы</MenuItem>
                  <MenuItem value={'МиМ'}>МиМ</MenuItem>
                  <MenuItem value={'Материалы'}>Материалы</MenuItem>
                  <MenuItem value={'Оборудование'}>Оборудование</MenuItem>
                  <MenuItem value={'Услуги'}>Услуги</MenuItem>
                </PRStyledSelect>
              );
            }}
          />
        </Box>
      );
    }
    default:
      return null;
  }
});
CellEditor.displayName = 'CellEditor';
export default CellEditor;
