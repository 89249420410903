import { Add, FeaturedPlayListOutlined, Remove } from '@mui/icons-material';
import { TreeItem, TreeView } from '@mui/lab';
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Menu, Stack, Typography } from '@mui/material';
import { useGetPositionListQuery } from 'api/calculations';
import { useCalcId } from 'hooks/useCalcId';
import _ from 'lodash';
import { SelectedValues } from 'pages/Calculations/components/Resources/Resources.types';
import { FC, useRef, useState } from 'react';
import { DropDownProps } from './DropDown.types';

export const DropDown: FC<DropDownProps> = ({ selectedValues, onSetSelectedValues, disabled }) => {
  const calcID = useCalcId();
  const { data, isLoading, isSuccess } = useGetPositionListQuery({ calcID });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const isSuccessLoad = !isLoading && isSuccess;
  const selectedTotalCount = selectedValues.position.length;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onFileChange = (id: number, checked: boolean) => {
    if (!data) return;

    const res: Pick<SelectedValues, 'chapter' | 'position'> = {
      chapter: [],
      position: [],
    };

    const file = data.find((file) => file.id === id);

    if (file) {
      file.children.forEach((chapter) => {
        res.chapter.push(`${chapter.title}-${id}`);
        chapter.children.forEach((position) => res.position.push(position.id));
      });

      onSetSelectedValues((prev) => ({
        file: _.xor(prev.file, [id]),
        chapter: checked
          ? _.uniq([...prev.chapter, ...res.chapter])
          : prev.chapter.filter((chapter) => !res.chapter.includes(chapter)),
        position: checked
          ? _.uniq([...prev.position, ...res.position])
          : prev.position.filter((position) => !res.position.includes(position)),
      }));
    }
  };

  const onChapterChange = (fileId: number, title: string, checked: boolean) => {
    if (!data) return;

    const res: number[] = [];

    const file = data.find((file) => file.id === fileId);

    if (file) {
      const chapter = file.children.find((chapter) => chapter.title === title);

      if (chapter) {
        chapter.children.forEach((position) => res.push(position.id));

        const allSelected = [...file.children.map((chapter) => chapter.title)].every((chapter) =>
          _.xor(selectedValues.chapter, [`${title}-${file.id}`]).includes(`${chapter}-${file.id}`),
        );

        onSetSelectedValues((prev) => ({
          file: allSelected ? [...prev.file, fileId] : prev.file.filter((file) => file !== fileId),
          chapter: _.xor(prev.chapter, [`${title}-${file.id}`]),
          position: checked ? [...prev.position, ...res] : prev.position.filter((position) => !res.includes(position)),
        }));
      }
    }
  };

  const onPositionChange = (fileId: number, chapterTitle: string, positionId: number) => {
    if (!data) return;

    const file = data.find((file) => file.id === fileId);

    if (file) {
      const chapter = file.children.find((chapter) => chapter.title === chapterTitle);

      if (chapter) {
        const allChapterSelected = [...file.children.map((chapter) => chapter.title)].every((chapter) =>
          _.xor(selectedValues.chapter, [`${chapterTitle}-${file.id}`]).includes(`${chapter}-${file.id}`),
        );

        const allPositionSelected = [...chapter.children.map((position) => position.id)].every((position) =>
          _.xor(selectedValues.position, [positionId]).includes(position),
        );

        onSetSelectedValues((prev) => ({
          file:
            allPositionSelected && allChapterSelected
              ? [...prev.file, fileId]
              : prev.file.filter((file) => file !== fileId),
          chapter: allPositionSelected
            ? [...prev.chapter, `${chapterTitle}-${file.id}`]
            : prev.chapter.filter((chapter) => chapter !== `${chapterTitle}-${file.id}`),
          position: _.xor(prev.position, [positionId]),
        }));
      }
    }
  };

  return (
    <>
      <Button ref={buttonRef} variant="text" onClick={handleClick} disabled={disabled}>
        <Stack direction="row" alignItems="center" pt={0.25} spacing={1}>
          <FeaturedPlayListOutlined color="secondary" fontSize="small" />
          <Typography variant="body2">Интервал формирования ведомостей:</Typography>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography variant="body2" color="text.dark" fontWeight={500}>
              Выбрано {isSuccessLoad && selectedTotalCount}
            </Typography>
            {!isSuccessLoad && <CircularProgress color="secondary" size={18} />}
          </Stack>
        </Stack>
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Box textAlign="left" overflow="auto" minWidth={buttonRef.current?.clientWidth} maxHeight={500}>
          <TreeView defaultCollapseIcon={<Remove />} defaultExpandIcon={<Add />}>
            {data && data?.length !== 0 ? (
              data.map((file) => (
                <TreeItem
                  key={file.id}
                  nodeId={file.id.toString()}
                  label={
                    <FormControlLabel
                      onClick={(e) => e.stopPropagation()}
                      control={
                        <Checkbox
                          checked={selectedValues.file.indexOf(file.id) !== -1}
                          indeterminate={
                            file.children.some(
                              (chapter) =>
                                selectedValues.chapter.includes(`${chapter.title}-${file.id}`) ||
                                chapter.children.some((position) => selectedValues.position.includes(position.id)),
                            ) && selectedValues.file.indexOf(file.id) === -1
                          }
                        />
                      }
                      label={file.title}
                      onChange={(_, checked) => onFileChange(file.id, checked)}
                    />
                  }>
                  {file.children
                    .filter((chapter) => !chapter.isUnaccepted)
                    .map((chapter) => (
                      <TreeItem
                        key={chapter.title}
                        nodeId={chapter.title}
                        label={
                          <FormControlLabel
                            onClick={(e) => e.stopPropagation()}
                            control={
                              <Checkbox
                                checked={selectedValues.chapter.indexOf(`${chapter.title}-${file.id}`) !== -1}
                                indeterminate={
                                  chapter.children.some((position) => selectedValues.position.includes(position.id)) &&
                                  selectedValues.chapter.indexOf(`${chapter.title}-${file.id}`) === -1
                                }
                              />
                            }
                            label={chapter.title}
                            onChange={(_, checked) => onChapterChange(file.id, chapter.title, checked)}
                          />
                        }>
                        {chapter.children.map((position) => (
                          <TreeItem
                            key={position.id}
                            nodeId={position.id.toString()}
                            label={
                              <FormControlLabel
                                onClick={(e) => e.stopPropagation()}
                                control={<Checkbox checked={selectedValues.position.indexOf(position.id) !== -1} />}
                                label={position.title}
                                onChange={() => onPositionChange(file.id, chapter.title, position.id)}
                              />
                            }
                          />
                        ))}
                      </TreeItem>
                    ))}
                </TreeItem>
              ))
            ) : (
              <Typography variant="body2" textAlign="center">
                Нет опций
              </Typography>
            )}
          </TreeView>
        </Box>
      </Menu>
    </>
  );
};
