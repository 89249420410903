import { ToggleButton, darken, lighten, toggleButtonClasses } from '@mui/material';
import styled from 'styled-components';

export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: theme.palette.primary.light,
  border: 0,
  position: 'relative',
  textTransform: 'none',

  '&:not(:first-child)::after': {
    content: "''",
    position: 'absolute',
    backgroundColor: lighten(theme.palette.primary.light, 0.6),
    width: '1px',
    height: '16px',
    top: '50%',
    left: '-2px',
    transform: 'translateY(-50%)',
  },

  [`&.${toggleButtonClasses.selected}`]: {
    color: theme.palette.bg.white,
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[2],

    '&:hover': {
      backgroundColor: darken(theme.palette.primary.main, 0.2),
    },
  },

  [`&.${toggleButtonClasses.disabled}`]: {
    border: 0,
  },
}));
