/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */

import { Form } from "formik";
import styled from "styled-components";

export const WrapperParameters = styled(Form)`
  display: grid;
  height: 100%;
    overflow: hidden;
  grid-template-columns: minmax(328px, 17.6%) 1fr;
  grid-template-rows: 64px 1fr;
  grid-template-areas: 'header header' 'left right';
`;
export const HeaderParameters = styled.div`
  width: 100%;
    position: sticky;
    top:0;
  height: 100%;
    background: #fff;
    z-index: 99;
  border-bottom: 1px solid #d1d8fa;
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  .box {
    display: flex;
    align-items: center;
    gap: 40px;
  }
`;
export const LeftMenu = styled.div`
  width: 100%;
    position: sticky;
    top:0;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 30px;
  border-right: 1px solid #d1d8fa;
  grid-area: left;
`;
export const CoeffParameters = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px;
  grid-area: right;
    overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const Table = styled.div<{ grid?: string; widthTable?: string; overflow?: boolean }>`
  width: 100%;
  grid-template-columns: ${({ grid }) => grid || '58.56% 6.36% repeat(4, 7.47%) 5.2%'};
  grid-auto-rows: min-content;
  gap: ${({ grid }) => (grid ? '16px 12px' : '16px 0')};
  display: grid;
`;
export const DelimetrRow = styled.div<{ gridArea?: boolean }>`
  height: 1px;
  grid-area: ${({ gridArea }) => (gridArea ? '2 / 1 / 3 / span 9' : '2 / 1 / 3 / span 7')};
  background: #d1d8fa;
`;
export const Badge = styled.div<{ isGlobal?: boolean }>`
  display: flex;
  align-items: center;
    white-space: nowrap;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.5px;
  color: white;
  border-radius: 4px;
  padding: 4px;
  background: ${({ isGlobal, theme: { palette } }) => (isGlobal ? palette.success.main : palette.primary.main)};
`;
export const Item = styled.div<{ active: boolean }>`
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  padding: 12px 20px;
  background: ${({ active }) => (active ? '#1976D214' : '')};
  color: ${({
    active,
    theme: {
      palette: { primary },
    },
  }) => (active ? primary.main : primary.gray)};
`;
