import { FC } from 'react';
import { BodyTextProps } from './BodyText.types';

export const BodyText: FC<BodyTextProps> = ({ level, title }) => {
  return (
    <span>
      <b>{`Уровень ${level}, ${title}`} </b>
      <span>будет разгруппирован, а все укрупненные расценки, будут перемещены в несортированный список.</span>
    </span>
  );
};
