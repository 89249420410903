/**
 * @author Mr_FabiozZz[mr,fabiozzz@gmail.com]
 */
import React from 'react';
import { Item, LeftMenu } from '../Parameters.style';
import { TTab } from '../Parameters.types';

const tabs: { name: 'indirect_costs' | 'value_changes'; value: string }[] = [
  {
    name: 'indirect_costs',
    value: 'Косвенные затраты',
  },
  {
    name: 'value_changes',
    value: 'Коэффициент изменения стоимости',
  },
];

const Menu: React.FC<{ tab: TTab; setTab: (str: TTab) => void }> = React.memo(({ tab, setTab }) => {
  return (
    <LeftMenu>
      {tabs.map((item) => {
        return (
          <Item active={item.name === tab} onClick={() => item.name !== tab && setTab(item.name)}>
            {item.value}
          </Item>
        );
      })}
    </LeftMenu>
  );
});

export default Menu;
