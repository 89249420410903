import { FocusEventHandler, useCallback, useMemo, useRef, useState } from 'react';

import { Box, IconButton, Menu, MenuItem, TableRowProps as MuiTableRowProps } from '@mui/material';
import { Form, FormikProvider } from 'formik';
import { StyledFieldForm, StyledLevelTd, StyledTypographyForm } from '../styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useCreateComplexLevelMutation } from '../../../../../../api/calculations';

import { useForm } from '../../../../../../hooks/useForm';
import { validationTitleEditRow } from './validation';
import { AddLevelFormData } from './AddLevelForm.types';
import { useMutationHandlers } from '../../../../../../hooks/useMutationHandlers';
import { LevelIcon, LevelNameType } from '../../../LevelIcon';

export interface ComplexFormAddLevelPropsType extends MuiTableRowProps {
  calculationId: number;
  levelNumber: number;
  parentId: number | null;
  firstForm?: string;
  handleShowFormNestedLevel: (isShowFormRowAdd: boolean) => void;
  handleShowFormAdjacentLevel: (isShowFormRowAdd: boolean) => void;
}

const ComplexFormAddLevel: React.FC<ComplexFormAddLevelPropsType> = ({
  calculationId,
  parentId,
  firstForm,
  levelNumber,
  handleShowFormNestedLevel,
  handleShowFormAdjacentLevel,
}) => {
  const [isAddSave, setIsAddSave] = useState('');
  const levelName = `level${levelNumber + 1}` as LevelNameType;

  /************************************ Add Level  ************************************/

  const initialValues: AddLevelFormData = useMemo(
    () => ({
      auxiliaryMaterials: 0, //вспомогательные материалы
      basicMaterials: 0, // основные материалы
      equipment: 0, //оборудование
      estimatedProfit: 0, // сметная прибыль
      machineOperatorSalary: 0, // зарплата механизаторов
      mainSalary: 0, //основная зарплата
      materials: 0, // материалы
      mimExploitation: 0, //Эксплуатация МиМ
      overheads: 0, //накладные расходы
      title: '',
      total: 0,
    }),
    [], //calculation
  );
  const [addComplexLevel, addComplexLevelResponse] = useCreateComplexLevelMutation();

  const onSubmit = useCallback(
    (values: AddLevelFormData) => {
      addComplexLevel({
        id: calculationId,
        auxiliaryMaterials: 0,
        basicMaterials: 0,
        equipment: 0,
        estimatedProfit: 0,
        machineOperatorSalary: 0,
        mainSalary: 0,
        materials: 0,
        mimExploitation: 0,
        overheads: 0,
        parent: parentId, // id parent row
        title: values.title,
        total: 0,
        type: 'level',
      });
    },
    [], //calculation
  );

  useMutationHandlers(addComplexLevelResponse, () => {
    if (isAddSave === 'SaveAdd') {
      handleShowFormAdjacentLevel(true);
      setIsAddSave('');
      handleClearForm();
    } else {
      handleShowFormNestedLevel(false);
      handleShowFormAdjacentLevel(false);
    }
  });

  const { formik, isSubmitDisabled } = useForm({
    validationSchema: validationTitleEditRow,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      onSubmit(values);
      //    setIsInput(false);
    },
  });
  const { values, handleReset, setFieldValue, setFieldError, dirty, handleSubmit } = formik;

  /************************************ Row Menu ************************************/
  const [isMenuRowOpened, setMenuRowOpened] = useState(false);
  const anchorEl = useRef<null | HTMLElement>(null);

  const handleClose = () => {
    anchorEl.current = null;
    setMenuRowOpened(false);
  };

  // Сохранить и добавить новую стр
  const handleSaveAddLevel = () => {
    setIsAddSave('SaveAdd');
    if (!formik.isValid) {
      setIsAddSave('');
    }
    anchorEl.current = null;
    setMenuRowOpened(false);
    handleSubmit();
  };

  // Сохранить стр
  const handleSaveLevel = () => {
    handleSubmit();
    setIsAddSave('');
  };

  const handleClearForm = () => {
    formik.resetForm({
      values: {
        title: '',
        auxiliaryMaterials: 0,
        basicMaterials: 0,
        equipment: 0,
        estimatedProfit: 0,
        machineOperatorSalary: 0,
        mainSalary: 0,
        materials: 0,
        mimExploitation: 0,
        overheads: 0,
        total: 0,
      },
    });
    anchorEl.current = null;
    setMenuRowOpened(false);
  };

  const handleDeleteFormAddLevel = () => {
    handleShowFormNestedLevel(false);
    handleShowFormAdjacentLevel(false);
  };

  const handleOpenMenuRowClick = (event: React.MouseEvent<HTMLElement>) => {
    anchorEl.current = event.currentTarget;
    if (event.currentTarget) setMenuRowOpened(true);
  };

  /************************************** onKeyDown Enter **********************************/
  const [pressEnter, setPressEnter] = useState(false);

  const enterPressHandler = () => {
    if (formik.isValid) {
      setPressEnter(true);
    }
  };

  /******************************** Focus Out *************************************/

  const formRef = useRef<HTMLFormElement>(null);

  const handleFocusOut: FocusEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      // @ts-ignore
      if (event.relatedTarget && formRef.current?.contains(event.relatedTarget)) return;

      if (!isMenuRowOpened) {
        return handleSubmit();
      }
    },
    [isMenuRowOpened],
  );

  /*************************************************************************/

  return (
    <>
      <Box sx={{ display: 'flex' }} className="ComplexFormAddLevel">
        <StyledLevelTd
          borderColorLevel={levelName}
          colorsBgLevel={levelName}
          style={{
            minWidth: '100px',
            paddingLeft: '9px',
            justifyContent: 'flex-start',
            height: '50px',
          }}>
          <Box
            style={{
              display: 'flex',
              minWidth: 24,
              // marginRight: `${8 + levelNumber * 6}px`,
              marginRight: levelNumber <= 5 ? `${4 + (levelNumber - 1) * 6}px` : `${4 + 5 * 6}px`,
            }}></Box>
          <IconButton disabled sx={{ width: 32, height: 32, marginRight: '0px' }} size="small">
            <LevelIcon levelName={levelName} />
          </IconButton>
        </StyledLevelTd>
        <FormikProvider value={formik}>
          <Form
            style={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: 'minmax(320px,1fr) repeat(10,minmax(140px, 190px)) 30px',
              gridAutoRows: '50px',
            }}
            ref={formRef}
            onBlur={handleFocusOut}
            onKeyDown={enterPressHandler}>
            <StyledLevelTd
              colorsBgLevel={levelName}
              borderColorLevel={levelName}
              style={{ justifyContent: 'flex-start' }}>
              <StyledFieldForm
                autoFocus
                version="calculation"
                name="title"
                placeholder={
                  formik.isValid ? 'Укажите название работ' : 'Поле обязательно к заполнению'
                }></StyledFieldForm>
            </StyledLevelTd>
            <StyledLevelTd colorsBgLevel={levelName} borderColorLevel={levelName}>
              <StyledTypographyForm>0</StyledTypographyForm>
            </StyledLevelTd>
            <StyledLevelTd colorsBgLevel={levelName} borderColorLevel={levelName}>
              <StyledTypographyForm>0</StyledTypographyForm>
            </StyledLevelTd>
            <StyledLevelTd
              colorsBgLevel={levelName}
              borderColorLevel={levelName}
              style={{ boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)' }}>
              <StyledTypographyForm>0</StyledTypographyForm>
            </StyledLevelTd>
            <StyledLevelTd colorsBgLevel={levelName} borderColorLevel={levelName}>
              <StyledTypographyForm>0</StyledTypographyForm>
            </StyledLevelTd>
            <StyledLevelTd
              colorsBgLevel={levelName}
              borderColorLevel={levelName}
              style={{ boxShadow: 'inset 1px 0px 0px rgb(0 36 95 / 10%)' }}>
              <StyledTypographyForm>0</StyledTypographyForm>
            </StyledLevelTd>
            <StyledLevelTd colorsBgLevel={levelName} borderColorLevel={levelName}>
              <StyledTypographyForm>0</StyledTypographyForm>
            </StyledLevelTd>
            <StyledLevelTd
              colorsBgLevel={levelName}
              borderColorLevel={levelName}
              style={{ boxShadow: 'inset -1px 0px 0px rgb(0 36 95 / 10%)' }}>
              <StyledTypographyForm>0</StyledTypographyForm>
            </StyledLevelTd>
            <StyledLevelTd colorsBgLevel={levelName} borderColorLevel={levelName}>
              <StyledTypographyForm>0</StyledTypographyForm>
            </StyledLevelTd>
            <StyledLevelTd colorsBgLevel={levelName} borderColorLevel={levelName}>
              <StyledTypographyForm>0</StyledTypographyForm>
            </StyledLevelTd>
            <StyledLevelTd colorsBgLevel={levelName} borderColorLevel={levelName}>
              <StyledTypographyForm>0</StyledTypographyForm>
            </StyledLevelTd>
            <StyledLevelTd colorsBgLevel={levelName} borderColorLevel={levelName} style={{ padding: 0, width: 30 }}>
              <IconButton
                onClick={(event) => handleOpenMenuRowClick(event)}
                sx={{ width: 30, height: 30 }}
                size="small">
                <MoreVertIcon />
              </IconButton>
            </StyledLevelTd>
          </Form>
        </FormikProvider>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl.current}
        open={isMenuRowOpened}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem onClick={handleSaveLevel}>Сохранить </MenuItem>
        <MenuItem onClick={handleSaveAddLevel}>Сохранить и доб. статью </MenuItem>
        <MenuItem onClick={handleClearForm}>Очистить </MenuItem>
        <MenuItem onClick={handleDeleteFormAddLevel}>Удалить </MenuItem>
      </Menu>
    </>
  );
};

export default ComplexFormAddLevel;
