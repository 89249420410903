import styled from "styled-components";
import { Box, Button, Stack, Typography, ListItem } from "@mui/material";

export const StyledListItem = styled(ListItem)`
  width: 320px;
  height: 40px;
  background: #ffffff;
  border-radius: 6px;
  margin-bottom: 12px;

  & .MuiListItemIcon-root {
    min-width: 16px;
    margin-right: 8px;
  }

  &:hover {
    .MuiListItemButton-root {
      background-color: #ffffff;
      cursor: default;
    }
  }

  & .MuiTypography-root {
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 400;
    font-size: 16px;
  }

  & .MuiSvgIcon-root {
    color: #5c6e8c;
    &:hover {
      color: #f46b6b;
      cursor: pointer;
    }
  }

  & .MuiButtonBase-root.MuiIconButton-root {
    &:hover {
      background-color: #ffffff;
    }
  }
`;
export const StyledButtonListItem = styled(ListItem)`
  width: 320px;
  height: 40px;
  background: rgba(25, 118, 210, 0.08);
  border-radius: 6px;
  margin-bottom: 12px;
  justify-content: center;
  flex-grow: inherit;

  &:hover {
    background-color: #0044b4;
    border-radius: 6px;
    cursor: pointer;

    & .MuiTypography-root {
      color: #ffffff;
    }
    & .MuiSvgIcon-root {
      color: #ffffff;
    }
  }

  & .MuiListItemIcon-root {
    min-width: 16px;
    margin-right: 8px;
  }

  & .MuiTypography-root {
    display: inline-flex;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    color: #0044b4;

    &:hover {
      color: #ffffff;
    }
  }

  & .MuiSvgIcon-root {
    height: auto;
    color: #0044b4;
    font-size: 23px;
    &:hover {
      color: #ffffff;
    }
  }

  & .MuiButtonBase-root.MuiListItemButton-root {
    width: 100%;
    height: 100%;
    padding: 0;
    justify-content: center;

    &:hover {
      background: none;
      border-radius: 6px;
    }
  }
`;
