import React, { ReactNode } from 'react';
import { Box, Pagination, Stack } from '@mui/material';
import { AdminLegendProps } from './AdminLegend.types';
import AddIcon from '@mui/icons-material/Add';
import { Tabs } from 'components/Tabs';
import Button from '../../../../components/Button';
import FilterSelect from '../../../../components/FilterSelect';
import { theme } from '../../../../styles/theme';
import { useTranslation } from 'react-i18next';
import { TabsEntities } from '../../../../components/Tabs/Tabs.types';
import { useLocation } from 'react-router-dom';

const AdminLegend = <T extends TabsEntities>({
  currentTab,
  pagination = false,
  tabsData,
  onTabChange,
  onAddClick,
  page,
  countPagination,
  numberRows,
  onChangeLimit,
  onChangePage,
  limit,
}: AdminLegendProps<T> & { children?: ReactNode }) => {
  const { t } = useTranslation('user');
  const { pathname } = useLocation();

  return (
    <Box className="AdminLegend">
      <Stack className="Stack" direction="row" justifyContent="space-between" alignItems="center" sx={{ py: 1 }}>
        <Tabs<T> currentTab={currentTab} onTabChange={onTabChange} tabsData={tabsData} />

        <Stack className="Stack-pagination" spacing={5} direction="row" justifyContent="center" alignItems="center">
          {pagination ? (
            <>
              <FilterSelect items={numberRows!} value={limit!} onChange={onChangeLimit!} startIcon={t('header.rows')} />
              <Pagination
                onChange={(e, v) => onChangePage!(v)}
                count={countPagination}
                shape="rounded"
                page={page}
                sx={{
                  '& .MuiPaginationItem-root': {
                    '&.Mui-selected': {
                      background: 'rgba(109, 154, 220, 0.2)',
                      color: theme.palette.primary.main,
                      border: `1px solid ${theme.palette.primary.main}`,
                    },
                  },
                }}
              />
              <Button startIcon={<AddIcon />} onClick={onAddClick}>
                Добавить
              </Button>
            </>
          ) : (
            pathname !== '/administration/projects' && (
              <Box display={'flex'} alignItems={'center'} gap={'12px'}>
                {/*<StyledBtn integrate onClick={onAddClick}>*/}
                {/*  <IntegrateIcon />*/}
                {/*</StyledBtn>*/}
                <Button startIcon={<AddIcon />} onClick={onAddClick}>
                  Создать
                </Button>
              </Box>
            )
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default AdminLegend;
