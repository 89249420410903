import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import 'yup-phone-lite';

/** TODO: убрал .phone() */
export const validationPhoneSchema = yup.string();

export const useValidatePhone = () => {
  const { t } = useTranslation();

  return useCallback(
    (value: string) => (validationPhoneSchema.isValidSync(value) ? '' : t('common:errors.auth.invalid_phone')),
    [t],
  );
};
