import { LimitedCostField } from 'api/params/params.types';
import { ChapterName } from './LimitedCostForm.types';

export const chapterNames: ChapterName[] = [
  {
    name: '8',
    title: 'Временные здания и сооружения',
  },
  {
    name: '9',
    title: 'Прочие работы и затраты',
  },
  {
    name: '10',
    title: 'Содержание службы заказчика. Строительный контроль',
  },
  {
    name: '11',
    title: 'Подготовка эксплуатационных кадров для строящегося объекта капитального строительства',
  },
  {
    name: '12',
    title: 'Публичный технологический и ценовой аудит, подготовка обоснования инвестиций. ',
  },
  {
    name: '13',
    title: 'Непредвиденные затраты',
  },
  {
    name: '14',
    title: 'Дополнительные работы и затраты',
  },
  {
    name: '15',
    title: 'Налоги и обязательные платежи',
  },
];

export const limitedCostFieldOrder: Array<keyof LimitedCostField> = ['title', 'type', 'value'];
