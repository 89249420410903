import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { ReactElement, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import Button from '../components/Button';
import { theme } from '../styles/theme';

export interface UseExitConfirmProps {
  title?: string;
  body?: string | ReactElement<{ children: string }>;
  confirmButtonText?: string;
  denyButtonText?: string;
  closeDeps?: any[];
  handleConfirm: (confirm: boolean, ...params: any) => void;
  handleClose?: () => void;
  isRedButton?: boolean;
  abortHandler?: () => void;
}

export interface UseExitConfirmData {
  ConfirmDialog: React.FC;
  openConfirm: (...params: any) => void;
}

const useConfirmDialog = (data: UseExitConfirmProps): UseExitConfirmData => {
  const { handleConfirm, closeDeps, confirmButtonText, denyButtonText, abortHandler, title, body, handleClose } = data;

  const [open, setOpen] = useState(false);
  const [params, setParams] = useState<any[]>([]);

  const openConfirm = useCallback((...params: any) => {
    setOpen(true);
    setParams(params);
  }, []);

  const onClose = useCallback(
    (confirm: boolean) => {
      setOpen(false);
      handleConfirm(confirm, ...params);
    },
    [params, ...(closeDeps || [])],
  );

  useEffect(() => {
    return () => {
      if (open) {
        setParams([]);
      }
    };
  }, [open]);

  const closeModal = () => {
    handleClose && handleClose();
    onClose(true);
  };

  const ConfirmDialog: React.FC = () => (
    <Dialog
      open={open}
      onClose={(e: SyntheticEvent, reason) => {
        if (reason === 'backdropClick') {
          e.stopPropagation();
        }
        onClose(false);
        abortHandler?.();
      }}
      PaperProps={{
        style: {
          width: 400,
          textAlign: 'center',
        },
      }}>
      <DialogTitle variant="subtitle1" justifyContent="center" sx={{ pt: 3.75, pb: 1.5 }}>
        {title || 'Подтвердить закрытие формы?'}
      </DialogTitle>
      <DialogContent sx={{ wordBreak: 'break-word' }}>
        <Typography variant="body2" color={theme.palette.text.dark}>
          {body || 'Форма будет закрыта, а все введенные данные безвозвратно утеряны.'}
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', paddingBottom: 30 }}>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            closeModal();
          }}
          autoFocus
          color={data?.isRedButton ? 'error' : 'success'}
          fullWidth
          style={{ maxWidth: 165, ...(data?.isRedButton && { color: 'white' }) }}>
          {confirmButtonText || 'Да'}
        </Button>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onClose(false);
            abortHandler?.();
          }}
          fullWidth
          style={{ maxWidth: 165 }}>
          {denyButtonText || 'Отмена'}
        </Button>
      </DialogActions>
    </Dialog>
  );
  return {
    ConfirmDialog,
    openConfirm,
  };
};

export default useConfirmDialog;
