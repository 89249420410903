import ListIcon from "@mui/icons-material/List";
import { IconButton, Menu, MenuItem, Stack } from "@mui/material";
import { useProjectId } from "hooks/useProjectId";
import { useSnackbar } from "notistack";
import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CalcData } from "types";
import {
  useCopyCalculationMutation,
  useDeleteCalculationMutation,
  useDownloadCalculationMutation,
  useGetBasicRowsListQuery
} from "../../../../api/calculations";
import useBreadcrumbs from "../../../../hooks/useBreadcrumbs";
import useConfirmDialog, { UseExitConfirmProps } from "../../../../hooks/useConfirmDialog";
import useConfirmDialogConfidence, {
  UseExitConfirmPropsConfidence
} from "../../../../hooks/useConfirmDialogСonfidence";
import { useMutationHandlers } from "../../../../hooks/useMutationHandlers";
import { theme } from "../../../../styles/theme";
import { downloadBase64File } from "../../../../utils/downloadCalculation";
import CalculationDrawer from "../../../Calculations/components/CalculationDrawer";
import BasicLevel from "./components/BasicLevel";
import CalculationLegend from "./components/CalculationLegend/CalculationLegend";
import FormAddLevel from "./components/FormAddLevel";
import { LevelBasicSum } from "./components/LevelBasicSum";
import { StyledHeadTd, StyledTableBodyContainer, StyledTableContainer, StyledTableHead } from "./styles";

export type CalculationBasicProps = {
  calculation: CalcData;
  openEdit: (b: boolean) => void;
};

const CalculationBasic: React.FC<CalculationBasicProps> = ({ calculation, openEdit }) => {
  const { title, id: calculationId } = calculation;
  const projectID = useProjectId();

  const { t } = useTranslation('user');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useBreadcrumbs([{ title: `Реестр ЛСР`, url: `/projects/${projectID}/lsr` }, { title }], [title]);

  // ******* Menu Calculation *******
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const MenuOpen = Boolean(anchorEl);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOpenMenuCalculation = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  // ******* Delete Calculation *******
  const [deleteCalculation, deleteCalculationResponse] = useDeleteCalculationMutation();

  const handleDeleteCalculationClick = useCallback(
    (actionName: string) => {
      openConfirmConfidence(actionName);
    },
    [calculationId],
  );

  useMutationHandlers(deleteCalculationResponse, () => {
    navigate(`/projects/${projectID}/calculations`);
    enqueueSnackbar(t('success.calculationDelete'), {
      variant: 'success',
    });
  });

  // ******* Copy Calculation *******
  const [copyCalculation, copyCalculationResponse] = useCopyCalculationMutation();

  // const handleCopyCalculationClick = useCallback(() => {
  //   copyCalculation({ projectID, calcID: calculationId });
  // }, [calculationId]);

  useMutationHandlers(copyCalculationResponse, () => {
    navigate('/calculations');
    enqueueSnackbar(t('success.calculationCopy'), {
      variant: 'success',
    });
  });

  // ******* Download Calculation *******
  const [downloadCalculation, downloadCalculationResponse] = useDownloadCalculationMutation();

  const handleClickDownloadReport = useCallback(() => {
    downloadCalculation({ projectID, calcID: calculationId });

    setAnchorEl(null);
  }, [calculationId]);

  useMutationHandlers(
    downloadCalculationResponse,
    (data: string) => {
      downloadBase64File(data, `${'Смета ' + title + '.xlsx'}`);
    },
    () => {
      enqueueSnackbar('Не удалось скачать пустой расчет', { variant: 'error' });
    },
  );

  // ******* dialog Confirm *******
  const handleConfirmDelete = useCallback((confirm: boolean) => {
    if (confirm) {
      deleteCalculation({ projectID, calcID: calculationId });
    }
  }, []);
  const handleConfirmCopy = useCallback((confirm: boolean) => {
    if (confirm) {
      copyCalculation({ projectID, calcID: calculationId });
    }
  }, []);

  const dialogTitle = 'Вы уверенны?';

  const dataForConfirmDialogConfidence: UseExitConfirmPropsConfidence = {
    handleConfirmDelete,
    handleConfirmCopy,
    dialogTitle,
  };
  const { ConfirmDialogConfidence, openConfirmConfidence } = useConfirmDialogConfidence(
    dataForConfirmDialogConfidence,
    dialogTitle,
  );

  /*****************************Show/Hide Form Add Adjacent/соседний Row****************************************/

  const [isShowFormAddAdjacentLevel, setIsShowFormAddAdjacentLevel] = useState(false);

  const handleShowFormAdjacentLevel = useCallback((isShowFormRowAdd: boolean) => {
    setIsShowFormAddAdjacentLevel(isShowFormRowAdd);
  }, []);

  /*****************************Show/Hide Form Add Nested/вложенный Row****************************************/

  const [, setIsShowFormAddNestedLevel] = useState(false);

  const handleShowFormNestedLevel = useCallback((isShowFormRowAdd: boolean) => {
    setIsShowFormAddNestedLevel(isShowFormRowAdd);
  }, []);

  /***************************** Drawer Edit Calculation ****************************************/
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      setIsDrawerOpen(false);
    }
  }, []);

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,
  };

  const { openConfirm } = useConfirmDialog(dataForConfirmDialog);

  const onDrawerClose = useCallback((dirty: boolean, immediately?: boolean) => {
    immediately || !dirty ? setIsDrawerOpen(false) : openConfirm();
  }, []);

  // ******* Edit Calculation  *******

  // const handleEditCalculationClick = useCallback(() => {
  //   setAnchorEl(null);
  //   setIsDrawerOpen(true);
  // }, [calculationId]);

  /********************************* Scroll Body & Head ****************************************/

  const headRef = useRef<HTMLDivElement | null>(null);
  const bodyRef = useRef<HTMLTableRowElement | null>(null);

  const handleScrollHead = (scroll: React.UIEvent<HTMLElement>): void => {
    if (bodyRef.current === null) return;
    const target = scroll.target as HTMLDivElement;
    bodyRef.current.scroll({ left: target.scrollLeft });
  };
  const handleScrollBody = (scroll: React.UIEvent<HTMLElement>): void => {
    if (headRef.current === null) return;
    const target = scroll.target as HTMLDivElement;
    headRef.current.scroll({ left: target.scrollLeft });
  };

  /********************************* Rows List ****************************************/

  const { data: rowsListData, isFetching, isLoading } = useGetBasicRowsListQuery({ id: calculationId });

  const isDataLoading = isFetching || isLoading;

  const rowsList = rowsListData ? rowsListData?.data : [];

  const colorTheme = theme.palette;

  /* ********************************************************************** */

  return (
    <>
      {!isDataLoading && (
        <Stack flex={1} maxWidth="100%" sx={{ height: '100%' }}>
          <>
            <CalculationLegend
              calculation={calculation}

              hideButton={true}
              calculationStatus={status}
              handleOpenMenuCalculation={handleOpenMenuCalculation}
            />
            <StyledTableContainer className="Basic">
              <StyledTableHead className="head" ref={headRef} onScroll={handleScrollHead}>
                <StyledHeadTd style={{ paddingLeft: '12px', justifyContent: 'flex-start' }}>
                  <IconButton sx={{ width: 28, height: 28, padding: '0px' }} size="small">
                    <ListIcon
                      sx={{
                        color: colorTheme.text.white,
                        width: '20px',
                        height: '20px',
                      }}
                    />
                  </IconButton>
                </StyledHeadTd>
                <StyledHeadTd style={{ justifyContent: 'flex-start', paddingLeft: 26 }}>
                  Наименование работ (Генподряд)
                </StyledHeadTd>
                <StyledHeadTd>Ед. изм.</StyledHeadTd>
                <StyledHeadTd style={{ justifyContent: 'flex-end', paddingRight: 30 }}>Количество</StyledHeadTd>
                <StyledHeadTd style={{ justifyContent: 'flex-end', paddingRight: 30 }}>Цена за ед.</StyledHeadTd>
                <StyledHeadTd style={{ justifyContent: 'flex-end', paddingRight: 18 }}>Стоимость</StyledHeadTd>
                <StyledHeadTd style={{ maxWidth: 30 }}></StyledHeadTd>
              </StyledTableHead>
              <StyledTableBodyContainer className="body" ref={bodyRef} onScroll={handleScrollBody}>
                <LevelBasicSum rowsList={rowsList} />
                {rowsList.length > 0 ? (
                  rowsList?.map((rowLevel1) => {
                    return (
                      <BasicLevel
                        key={rowLevel1.id}
                        level={rowLevel1}
                        levelNumber={1}
                        calculationId={calculationId}
                        handleShowFormAdjacent={handleShowFormAdjacentLevel}
                        handleShowFormNested={handleShowFormNestedLevel}
                      />
                    );
                  })
                ) : (
                  <FormAddLevel
                    levelNumber={0}
                    calculationId={calculationId}
                    parentId={null}
                    firstForm={'firstForm'}
                    handleShowFormNestedLevel={handleShowFormNestedLevel}
                    handleShowFormAdjacentLevel={handleShowFormAdjacentLevel}></FormAddLevel>
                )}
                {isShowFormAddAdjacentLevel && (
                  <FormAddLevel
                    levelNumber={0}
                    calculationId={calculationId}
                    parentId={null}
                    handleShowFormNestedLevel={handleShowFormNestedLevel}
                    handleShowFormAdjacentLevel={handleShowFormAdjacentLevel}></FormAddLevel>
                )}
              </StyledTableBodyContainer>
            </StyledTableContainer>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={MenuOpen}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}>
              <MenuItem onClick={handleClickDownloadReport}>Скачать расчет</MenuItem>
              {/* <MenuItem onClick={handleCopyCalculationClick}>Копировать расчет</MenuItem> */}
              <MenuItem onClick={() => handleDeleteCalculationClick('Delete')}>Удалить расчет</MenuItem>
              <MenuItem
                onClick={() => {
                  openEdit(true);
                  setAnchorEl(null);
                }}>
                Редактировать название
              </MenuItem>
            </Menu>
          </>
          <CalculationDrawer open={isDrawerOpen} onClose={onDrawerClose} calculationId={calculationId} />
          <ConfirmDialogConfidence />
        </Stack>
      )}
    </>
  );
};

export default CalculationBasic;
