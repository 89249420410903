/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { Box, ClickAwayListener, IconButton, Menu, MenuItem } from "@mui/material";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { ICellRendererParams, IRowNode, RowClassParams, ValueSetterParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useDeleteCalculationMutation } from "api/calculations";
import { ReactComponent as ImportIcon } from "assets/icons/price-reference/upload-xls.svg";
import useBreadcrumbs from "hooks/useBreadcrumbs";
import { useProjectId } from "hooks/useProjectId";
import { enqueueSnackbar } from "notistack";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  calculationDictionaryApi,
  ICalculationDictionary,
  IDeleteRateWithRatePositions,
  IUpdateCalcRowRequest,
  TResourceType,
  useApplyFilterDataMutation,
  useCreateRowHandbkMutation,
  useDeleteRatesWithPositionsMutation,
  useLazyExportCalculationQuery,
  useLazyGetCaclQuery,
  useSortTableMutation,
  useUpdateRowHandbkMutation
} from "../../../../api/calculationDictionary";
import {
  useCreateKFOGroupMutation,
  useCreateUnitWeightMutation,
  useGetEstimateReferenceQuery,
  useGetKFOGroupQuery,
  useGetUnitListQuery
} from "../../../../api/references/estimates";
import { resourceEng } from "../../../../api/references/estimates/estimates.types";
import Button from "../../../../components/Button";
import Progress from "../../../../components/Progress";
import { useCalcId } from "../../../../hooks/useCalcId";
import useConfirmDialog from "../../../../hooks/useConfirmDialog";
import { useMutationHandlers } from "../../../../hooks/useMutationHandlers";
import {
  changeApplyed,
  clearFilter,
  getCalculationDictionaryState,
  setTableFilter
} from "../../../../store/slices/calculationDictionary/calculationDictionary";
import {
  addGroup,
  addUnit,
  getPricesState,
  initialGroup,
  initialUnit
} from "../../../../store/slices/references/prices/prices";
import { useAppDispatch } from "../../../../store/store";
import { diffArray } from "../../../Administration/AdminReferences/Prices/PricesReference";
import {
  filterChips,
  IErrorData,
  TTabActive
} from "../../../Administration/AdminReferences/Prices/PricesReference.types";
import DialogFilters from "../../../Administration/AdminReferences/Prices/components/DialogFilters";
import {
  Chip,
  ChipWrapper,
  Devide,
  ImportButton
} from "../../../Administration/AdminReferences/Prices/components/PricesReferences.style";
import { PageStyled, WrapperAgGrid } from "../Accomplishment/Accomplishment.styles";
import {
  fieldsGroupTyping,
  flatChildren,
  getNameField,
  ICalculationDirectory,
  ICalculationDirectoryContext,
  rootRow,
  useTable
} from "./CalculationDirectory.types";
import Parameters from "./Parameters";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { deleteData, openDB } from "../../../../utils/indexDB";
import { useLocation, useNavigate } from "react-router-dom";
import DrawerInsertRow from "./components/DrawerInsertRow";
import { downloadBase64File } from "../../../../utils/downloadBase64File";
import Tooltip from "../../../../components/Tooltip";
import FilterListIcon from "@mui/icons-material/Sort";

const defaultColDef = {
  width: 124,
  resizable: true,
};

export const CalculationDirectoryContext = React.createContext<ICalculationDirectoryContext>({
  confirmCreateRate:()=>{},
  setDeletedLvlTitle: () => {
    return;
  },
  clearRemovedIDs: () => {
    return;
  },
  setSelectRow:(row:null | ICalculationDictionary)=>{},
  selectRow: null,
  tableData: [],
  rows: [],
  deleteFolderConfirm: () => {
    return;
  },
  checkboxClick: () => {
    return;
  },
  removedIds: [],
  collapse: () => {},
  unCollapseRows: () => {},
});

const CalculationDirectory: React.FC<ICalculationDirectory> = ({ openEdit, calculation }) => {
  /* Получение глобальных элементов управления */

  // CORE
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation()

  // переводчик
  const { t } = useTranslation('references');
  const { t:translateDelete } = useTranslation('calculation');

  // данные с Redux
  const { activeFilters, applyed, filteredData } = useSelector(getCalculationDictionaryState);
  const { unitList, groupList } = useSelector(getPricesState);

  // id проекта и расчета
  const projectId = useProjectId();
  const calcID = useCalcId();

  /* Локальные состояния */

  // стейты

  /**
   * строки к удалению
   */
  const [removedIds, setRemovedIds] = useState<{ type: string; id: number; parent: number | null }[]>([]);

  /**
   * настройка окна предупреждения об удалении расценок\ресурсов
   */
  const removedState = useMemo(() => {
    const isRate = removedIds.some((el) => el.type === 'RATE');
    const countResources = removedIds.filter((el) => el.type === 'RATE_POSITION' && el.id > 0).length;
    return {
      title: isRate ? 'Подтвердите удаление расценки' : 'Подтвердите удаление ресурсов',
      body: isRate
        ? `Выбранная Вами расценка удалится вместе с ресурсами безвозвратно.
Количество ресурсов в расценке: ${countResources}.`
        : `Выбранные Вами ресурсы удалятся безвозвратно.\nКоличество: ${countResources}.`,
    };
  }, [removedIds]);

  const [deletedLvl, setDeletedLvl] = useState<null | number>(null);
  const lvlDeleteTitle = useMemo(() => {
    return `Подтвердите удаление папки ${deletedLvl}-го уровня с ресурсами`;
  }, [deletedLvl]);

  // ссылки

  /**
   * ссылка для первой отрисовки таблицы
   * и для отслеживания получения данных и раскрытия уровней
   */
  const firstLoad = useRef(true);

  /**
   * то же самое, за исключением того что меняется один раз
   */
  const firstLoadGeneral = useRef(true);

  /**
   * ссылка на таблицу для управления AgGrid
   */
  const Ref = useRef<AgGridReact<ICalculationDictionary> | null>(null);

  /**
   * ссылка для хранения скрытых id для просмотра отфильтрованного списка, логика следующая:
   * основные id свернутых уровней хранится в hiddenRowsIds
   * при фильтрации таблицы, hiddenRowsIds попадает в сылку, а сам очищается, и отфильтрованный список становится развернутым
   * после отмены режима фильтрации данные с сылки попадают обратно
   */
  const localHiddenRows = useRef<number[]>([])

  /**
   * в этой сылке хранится данные таблицы для поиска нового добавленного элемента
   * раскрытия уровня, в котором новый элемент лежит и плюс к этому к нему проматывается таблица
   */
  const prevData = useRef<ICalculationDictionary[] | undefined>(undefined);

  /* Request's block */

  const [sortTable] = useSortTableMutation()

  // применение фильтров
  const [applyFiltersReq, {isLoading}] = useApplyFilterDataMutation();

  // запрос за справочником
  const {
    data: referenceData,
    isFetching: isFetchingReference,
    refetch: refreshReference,
  } = useGetEstimateReferenceQuery({});

  // CRU по расчету
  // const { data, isFetching } = useGetCaclQuery({ calcID }, { skip: !calcID });
  const [getCalc,{ data, isFetching }] = useLazyGetCaclQuery();
  const [createRow, createdResponse] = useCreateRowHandbkMutation();
  const [updateRow, updatedResponse] = useUpdateRowHandbkMutation();

  // скачать расчет
  const [exportCalc] = useLazyExportCalculationQuery()

  useEffect(() => {
    if (calcID || (!data &&!isFetching)) {
      sortTable(calcID).finally(()=>{
        getCalc({ calcID })
      })
    }
    }, [calcID]);

  // управление списками единицами измерений и группами КФО
  const { data: unitWeightData } = useGetUnitListQuery({ nameLike: '' });
  const { data: groupData } = useGetKFOGroupQuery({ nameLike: '' });
  const [createGroup] = useCreateKFOGroupMutation();
  const [createUnit] = useCreateUnitWeightMutation();

  // удаление расчета
  const [deleteCalculation, deleteCalculationResponse] = useDeleteCalculationMutation();

  const handleConfirmDelete = useCallback((confirm: boolean) => {
    if (confirm) {
      deleteCalculation({ projectID, calcID: calculation?.id || 0 }).then(() => {
        openDB().then((db) => deleteData(db, calculation!.id));
      });
    }
  }, []);


  const { openConfirm: openConfirmCreateRatePosition, ConfirmDialog: CreateRatePositionConfirm } = useConfirmDialog({
    handleConfirm: (confirm, fn) => {
      if (confirm) {
        fn?.();
      }
    },
    title: 'Подтвердите создание ресурса',
    denyButtonText: 'Отменить',
    confirmButtonText: 'Создать',
    body: (
      <span>
        Данные расценки в столбцах “Тип ресурса”
        <br /> “ФОТ ОР”, “МиМ”, “МТР”,
        <br /> “Общее рабочее время” перезапишутся
      </span>
    ),
  });

  const { openConfirm: openDeleteRateConfirm, ConfirmDialog: DeleteRateConfirmDialog } = useConfirmDialog({
    isRedButton: true,
    handleConfirm: (confirm, fn) => {
      if (confirm) {
        fn?.();
      } else {
        setRemovedIds([]);
      }
    },
    ...removedState,

    abortHandler: () => {
      setRemovedIds([]);
    },
  });
  const { openConfirm: openDeleteFolderSecondLevelConfirm, ConfirmDialog: DeleteFolderSecondLevelConfirmDialog } =
    useConfirmDialog({
      isRedButton: true,
      handleConfirm(confirm, fn, lvl) {
        if (confirm) {
          fn?.();
        }
      },
      title: lvlDeleteTitle,
      body: 'Выбранная Вами папка удалится безвозвратно',
    });

  const { ConfirmDialog: ConfirmDiaologDelete, openConfirm: openConfirmDelete } = useConfirmDialog({
    title: 'Вы уверены?',
    body: 'Расчёт будет удалён и восстановить его будет невозможно',
    handleConfirm: handleConfirmDelete,
  });

  const { ConfirmDialog: ConfirmDiaologUpdate, openConfirm: openConfirmUpdate } = useConfirmDialog({
    title: 'Вы уверены?',
    body: 'При добавлении типа ресурса, данные ФОТ, МиМ и МТР, не соответствующие типу ресурса, будут потеряны',
    handleConfirm: (confirm,fn)=>{
      if (confirm) {
        fn()
      }
    },
  });




  const [active, setActive] = useState<(TTabActive | 'kfoGroups')>(null);

  const changeDialog = (key: (TTabActive | 'kfoGroups') = null) => {
    setActive(key);
  };
  /**
   * Функция применения фильтров
   * берутся данные с redux и "мапятся" определенным образом
   * обновляется при обновлении активных фильтров
   */
  const applyFilters:(exclude?:keyof typeof applyFilters)=>void = useCallback((exclude?:keyof typeof applyFilters) => {
    let filters = {
      titles: activeFilters.titles.map((_) => _.id) as number[],
      codes: activeFilters.codes.map((_) => _.id) as number[],
      groups: activeFilters.groups.map((_) => _.id) as number[],
      types: activeFilters.resourceTypes.map((_) => resourceEng[_.name as keyof typeof resourceEng]) as TResourceType[],
    };
    if(exclude){
      if (exclude === "resourceTypes") {
        filters.types = [];
      }
      filters[exclude as keyof typeof filters] = [];
    }
    // console.log(
    //   Object.entries(filters).some((entry) => {
    //     const [, arr] = entry;
    //     return !!arr.length;
    //   }),
    // );
    const isLength = Object.entries(filters).some((entry) => {
      const [, arr] = entry;
      return !!arr.length;
    });
    if (!isLength) {
      dispatch(clearFilter('codes'));
      dispatch(clearFilter('groups'));
      dispatch(clearFilter('resourceTypes'));
      dispatch(clearFilter('titles'));
      changeDialog();
      return;
    }
    // console.log(filters);
    applyFiltersReq({ body: filters, calcID }).then((response) => {
      if ('data' in response) {
        dispatch(setTableFilter(response.data));
        dispatch(changeApplyed(true));
        changeDialog();
      }
    });
    // getFilteredData(filters).then((response) => {
    //   if ('data' in response) {
    //     dispatch(setTableFilter(response.data));
    //     changeDialog();
    //   }
    // });
  }, [activeFilters, calcID]);

  // const responseFilters = useGetFilterDataQuery(calcID, { skip: !calcID });

  // useMutationHandlers(responseFilters, (response) => {
  //   dispatch(setInitialFilters(response));
  // });


  const [createDrawer, setCreateDrawer] = useState(false);

  const [selectedRow, setSelectedRow] = useState<null | ICalculationDictionary>(null);

  const [parametersDialogOpen, setParametersDialogOpen] = useState(false);

  const updateGrid = useCallback(
    (update: ICalculationDictionary[]) => {
      if (Ref.current) {
        const updateData = new Map();
        update.forEach((updated) => {
          updateData.set(updated.id, updated);
        });
        const rowData: ICalculationDictionary[] = [];
        Ref.current?.api.forEachNode(function (node) {
          if (node.data) {
            if (updateData.has(node.data.id)) {
              rowData.push(updateData.get(node.data.id));
            } else {
              rowData.push(node.data!);
            }
          }
        });
        Ref.current.api.applyTransaction({ update: rowData });
        Ref.current?.api.refreshCells({ force: true });
      }
    },
    [Ref.current],
  );
  const [deleteRates] = useDeleteRatesWithPositionsMutation();

  const findById = useCallback(
    (id: number) => {
      if (!data) return null;
      const find = data.rows.find((d) => d.id === id);
      if (!find) return null;
      if (find.rowType !== 'FOLDER') {
        findById(find.parentID!);
      } else {
        return find.id;
      }
    },
    [data],
  );

  /**
   * Функция удаления расценки и позиции расценки
   */
  const deleteRatesWithPositions = useCallback(() => {
    const folderID = findById(removedIds[0].id);
    const body: IDeleteRateWithRatePositions = {
      ids: [],
      calcID,
      folderID: folderID || 0,
    };
    openDeleteRateConfirm(() => {
      removedIds.forEach((candidate) => {
        switch (candidate.type) {
          case 'RATE':
            break;
          default:
            body.ids.push(candidate.id);
            break;
        }
      });

      deleteRates(body).then((resp) => {
        let refetch = true;
        if ('error' in resp) {
          refetch = false;
          const message = (resp as IErrorData<string>)?.error?.data;
          if (message)
            enqueueSnackbar({ variant: 'error', message: t(`estimates.${message}`), autoHideDuration: 5000 });
        } else {
          enqueueSnackbar({ variant: 'success', message: 'Успешно удалено' });
        }
        // dispatch(removeParentLevel());
        setRemovedIds([]);
        if (selectedRow && body.ids.includes(selectedRow.id)) {
          setSelectedRow(null)
        }
        // console.log(refetch);
        // if (applyedFiltersData && refetch) {
        //   setTimeout(() => setClickRefetch(true));
        // }
      });
    });
  }, [
    removedIds,
    calcID,
    selectedRow
    // applyedFiltersData
  ]);


  const [hiddenRows, setHiddenRows] = React.useState<number[]>([]);


  const handleRefreshTable = useCallback(()=>{
    if (calcID) {
      sortTable(calcID)
    }
  },[calcID])

  const exportCalculation =  useCallback(()=>{
      exportCalc(calcID).then(response=>{
        if ('data' in response && response.data) {
        console.log(response)
        downloadBase64File(response.data, `расчет${calculation?.title?' '+calculation?.title :''}.xlsx`);
        }

      });
  },[calcID])


  const unCollapse = useCallback(
    ({
      data,
      diffRow,
      id,
      onlyRate,
    }: {
      onlyRate?: boolean;
      data: ICalculationDictionary[] | undefined;
      id?: number;
      diffRow?: ICalculationDictionary;
    }) => {
      if (!data) return;
      let searchedRow: ICalculationDictionary | undefined = diffRow;
      if (id === undefined) {
        if (!prevData.current && data) {
          prevData.current = data;
          return;
        } else if (prevData.current && data) {
          let diff:ICalculationDictionary|undefined;
          try{
            diff = (diffArray(data, prevData.current, true) as ICalculationDictionary[])[0];
          }finally {
            prevData.current = data;
            if (diff) {
              searchedRow = searchedRow ?? data.find((item) => item.id === diff!.id);
              if (diff.parentID !== null) {
                unCollapse({ data, diffRow: diff, id: diff.parentID });
              }
            }
          }
        }
      } else {
        const target = data?.find((item) => item.id === id);
        if (target) {
          if (hiddenRows.includes(target.id)) {
            collapse({ data: target } as any);
          }
          if (target.parentID !== null) {
            unCollapse({ data, id: target.parentID, diffRow });
          }
        }
      }
      return searchedRow;
    },
    [prevData.current, hiddenRows],
  );

  /**
   * Функция создания единицы измерения
   */
  const createUnitFn = useCallback(
    (unit: string) => {
      const index = unitList.findIndex((_) => _.name.toLowerCase() === unit.toLowerCase());
      if (index < 0) {
        createUnit({ name: unit }).then((response) => {
          if ('data' in response) {
            dispatch(addUnit(response.data));
          }
        });
      }
    },
    [unitList],
  );

  /**
   * Функция клика по чекбоксу, в зависимости от условий
   * либо добавляет в массив к удалению расценку\позицию, либо убирает
   */
  const clickCheckbox = useCallback(
    (id: number, type: string, parent: number | null) => {
      setRemovedIds((prevState) => {
        const candidate = data?.rows?.find((_) => _.id === id);
        const removedCandidates: typeof prevState = [];

        const indexId = prevState.findIndex((_) => _.id === id);
        let copyRemoved = [...prevState];

        if (candidate?.rowType === 'RATE') {
          removedCandidates.push({ id: candidate!.id, type, parent });
          // candidate.children?.forEach((_) => removedCandidates.push({ id: _.id, type: _!.rowType, parent: _.parentID }));
          if (indexId >= 0) {
            copyRemoved = copyRemoved.filter((el) => {
              return removedCandidates.findIndex((_) => el.id === _.id) < 0;
            });
          } else {
            copyRemoved = [...new Set([...copyRemoved, ...removedCandidates])];
          }
        } else {
          if (indexId >= 0) {
            copyRemoved.splice(indexId, 1);
          } else {
            copyRemoved.push({ id, type, parent });
          }
        }
        copyRemoved = copyRemoved.filter((_) => _.id > 0);
        return copyRemoved;
      });
    },
    [data],
  );

  const updateThenHandler = (
    resp:
      | { data: ICalculationDictionary | ICalculationDictionary[] }
      | { error: FetchBaseQueryError | SerializedError },
    name?: string,
    value?: string | number,
  ) => {
    if ('data' in resp) {
      if (Array.isArray(resp.data)) {
        updateGrid(resp.data);
      } else {
        updateGrid([resp.data]);
      }
      if ((name === 'unit' || name === 'kfoUnit') && value !== undefined) createUnitFn(value as string);
      return true;
    } else {
      // console.log(resp);
      // const message = (resp as IErrorData<string>)?.error?.data;
      // if (message) enqueueSnackbar({ variant: 'error', message: t(`estimates.${message}`), autoHideDuration: 5000 });
      return false;
    }
  };

  // /**
  //  * Функция которая будет отрабатывать после успешной отправки данных на сервер при создании
  //  */
  // const submitThenHandler = useCallback(
  //   (
  //     resp: { data: any } | { error: FetchBaseQueryError | SerializedError },
  //     unit?: string,
  //     keyForm?: string,
  //     isPosition: boolean = false,
  //   ) => {
  //     if ('data' in resp) {
  //       // if (!isPosition) {
  //       //   methods.reset(resetGenForm);
  //       //   dispatch(changeCreated(false));
  //       // } else {
  //       //   methodsPositions.setValue(`${keyForm}` as never, positionPlaceholder as never);
  //       // }
  //       unit && createUnitFn(unit);
  //       // dispatch(removeParentLevel());
  //       const result: ICalculationDictionary[] = [];
  //       const flatChildren = (data: ICalculationDictionary) => {
  //         result.push(data);
  //         if (data.children?.length) {
  //           data.children.forEach((child) => flatChildren(child));
  //         }
  //       };
  //       // dispatch(changeCreated(false));
  //       refetch().then((response) => {
  //         flatChildren(resp.data as ICalculationDictionary);
  //         let index: number | undefined;
  //         Ref.current?.api?.applyTransaction({ update: result.flat() });
  //         if (Array.isArray(resp.data)) {
  //           index = response.data?.findIndex((_) => _.id === resp.data[0]?.id) + resp.data[0]?.children?.length;
  //           const targetParent = data?.find((_) => _.id === resp.data[0]?.id);
  //           if (targetParent && targetParent.id === resp.data[0]?.id) {
  //             // console.log(targetParent, resp.data);
  //             const createdObject = diffArray<ICalculationDictionary>(resp.data.slice(1), targetParent!.children) as
  //               | ICalculationDictionary
  //               | undefined;
  //             if (createdObject) {
  //               index = response.data?.findIndex((item) => item.id === createdObject.id);
  //               // console.log(response.data?.[index!], index);
  //             }
  //           }
  //         } else {
  //           // index = response.data?.findIndex((_) => _.id === ) + resp.data?.children?.length;
  //           const targetParent = data?.find((_) => _.id === resp.data?.id);
  //           if (targetParent && targetParent.id === resp.data?.id) {
  //             // console.log(targetParent, resp.data);
  //             const createdObject = diffArray<ICalculationDictionary>(resp.data!.children, targetParent!.children) as
  //               | ICalculationDictionary
  //               | undefined;
  //             if (createdObject) {
  //               index = response.data?.findIndex((item) => item.id === createdObject.id);
  //               // console.log(response.data?.[index!], index);
  //             }
  //           }
  //
  //           // console.log(resp.data);
  //         }
  //         if (index! >= 0) {
  //           setTimeout(() => {
  //             Ref.current?.api.ensureIndexVisible(index!, 'middle');
  //             const target = response.data![index!];
  //             console.log(target);
  //             if (target && hiddenRows.includes(target.parentID!)) {
  //               // dispatch(changeUUID(target.id!));
  //               target.parentID && collapse(target as any);
  //               // setTimeout(() => dispatch(changeUUID(null)), 3000);
  //             }
  //           }, 200);
  //         }
  //         Ref.current?.api?.refreshCells({ force: true, suppressFlash: true });
  //       });
  //     } else {
  //       const message = (resp as IErrorData<string>)?.error?.data;
  //       if (message) enqueueSnackbar({ variant: 'error', message: t(`estimates.${message}`), autoHideDuration: 5000 });
  //     }
  //   },
  //   [createUnitFn, refetch, Ref.current, data, hiddenRows],
  // );

  const valueSetter = useCallback(
    (params: ValueSetterParams<ICalculationDictionary>) => {
      // console.log(params);
      let flag = false;
      if (!params.data) return false;
      const { children, calcID, id, ...otherFields } = params.data;
      let kfoGroupID: number | null = null;
      const rest = {
        ...otherFields,
        fotPerUnit: params.data?.base?.fotPerUnit || null,
        mimPerUnit: params.data?.base?.mimPerUnit || null,
        mtrPerUnit: params.data?.base?.mtrPerUnit || null,
      };
      (async () => {
        try {
          let body:IUpdateCalcRowRequest = {...rest}
          if (params.data.rowType === 'RATE' && params.colDef.field === 'kfoGroup' && params.newValue) {
            const candidate = groupList.find((_) => _.title === params.newValue);
            if (candidate) {
              kfoGroupID = candidate.id;
            } else {
              kfoGroupID = await createGroup({ name: params.newValue }).then((response) => {
                if ('data' in response) {
                  dispatch(addGroup({ title: response.data.name, id: response.data.id! }));
                  return response.data.id!;
                }
                return kfoGroupID;
              });
            }
          } else if (params.data?.kfoGroup) {
            const candidate = groupList.find((_) => _.title === params.data.kfoGroup);
            if (candidate) {
              kfoGroupID = candidate.id;
            }
          }
          switch (params.colDef.field) {
            case 'kfoGroup': {
              updateRow({
                body: {
                  ...(rest as any)!,
                  rowType: params.data!.rowType,
                  kfoGroupID: params.newValue?kfoGroupID || null:null,
                },
                calcID,
                rowID: params.data!.id,
              }).then((response) => {
                flag = updateThenHandler(response);
              });
              break;
            }
            // case 'kfoUnit': {
            //   updateRow({
            //     body: {
            //       ...(rest as any)!,
            //       rowType: params.data!.rowType,
            //       kfoUnit:params.newValue,
            //     },
            //     calcID,
            //     rowID: params.data!.id,
            //   }).then((response) => {
            //     flag = updateThenHandler(response,'kfoUnit',params.newValue);
            //   });
            //   break;
            // }
            case 'qtyPerUnit': {
              if (params.data.rowType === 'RATE') {
                updateRow({
                  body: {
                    ...(rest as any)!,
                    qtyTotal: params.newValue||null,
                    rowType: params.data!.rowType,
                    kfoGroupID: kfoGroupID || null,
                  },
                  calcID,
                  rowID: params.data!.id,
                }).then((response) => {
                  flag = updateThenHandler(response);
                });
              } else {
                updateRow({
                  body: {
                    ...(rest as any)!,
                    rowType: params.data!.rowType,
                    kfoGroupID: kfoGroupID || null,
                    [params.colDef!.field as any]: params.newValue || null,
                  },
                  calcID,
                  rowID: params.data!.id,
                }).then((response) => {
                  flag = updateThenHandler(response);
                });
              }
              break;
            }
            case 'resourceType': {
              const resource = resourceEng[params.newValue as keyof typeof resourceEng]
              const nameResourceField = getNameField(resource);
              const valueResourceField = rest?.[nameResourceField as keyof typeof rest];
              const humanHours = ['WORKERS', 'MACHINE_OPERATORS'];
              const machineHours = ['MiM'];
              // const workTime = !['WORKERS', 'MACHINE_OPERATORS','MiM','SERVICES'].includes(resource)?{workTimePerUnit:null}:{}
              // const tzAndTzm = {
              //   tzmPerUnit: resource === 'MACHINE_OPERATORS' ? rest.tzmTotal:null,
              //   tzPerUnit: resource === 'WORKERS'?rest.tzTotal:null
              // };
              const unitVal =
                 humanHours.includes(resource)
                    ? 'чел.час'
                    : machineHours.includes(resource)
                      ? 'маш.час'
                      : rest.unit! || null;
                  // : unit! || null;
              console.group('enter resource')
              console.log(fieldsGroupTyping[nameResourceField as keyof typeof fieldsGroupTyping]);
              console.log(fieldsGroupTyping[nameResourceField as keyof typeof fieldsGroupTyping]?.includes(resource) && (rest?.[nameResourceField as keyof typeof rest]));
              console.log(nameResourceField);
              console.log(rest?.[nameResourceField as keyof typeof rest]);
              console.groupEnd()
              let condition = false
              if (rest.fotPerUnit && !fieldsGroupTyping.fotPerUnit.includes(resource)) {
                condition = true
              } else if (rest.mimPerUnit && !fieldsGroupTyping.mimPerUnit.includes(resource)) {
                condition = true
              }else if (rest.mtrPerUnit && !fieldsGroupTyping.mtrPerUnit.includes(resource)) {
                condition = true
              }
              if (condition) {
                openConfirmUpdate(()=>{
                  updateRow({
                    body: {
                      ...(rest as any)!,
                      // ...workTime,
                      mimPerUnit:null,
                      mtrPerUnit:null,
                      fotPerUnit:null,
                      unit: unitVal,
                      resourceType: resource || rest.resourceType,
                      rowType: params.data!.rowType,
                      kfoGroupID: kfoGroupID || null,
                      ...(nameResourceField ? {[nameResourceField]: valueResourceField ?? null} : {}),
                      // ...tzAndTzm
                    },
                    calcID,
                    rowID: params.data!.id,
                  }).then((response) => {
                    flag = updateThenHandler(response);
                  });

                })
              }else{
                updateRow({
                  body: {
                    ...(rest as any)!,
                    // ...workTime,
                    mimPerUnit:null,
                    mtrPerUnit:null,
                    fotPerUnit:null,
                    unit: unitVal,
                    resourceType: resource || rest.resourceType,
                    rowType: params.data!.rowType,
                    kfoGroupID: kfoGroupID || null,
                    ...(nameResourceField ? {[nameResourceField]: valueResourceField ?? null} : {}),
                    // ...tzAndTzm
                  },
                  calcID,
                  rowID: params.data!.id,
                }).then((response) => {
                  flag = updateThenHandler(response);
                });

              }
              console.log(unitVal);
              break;
            }
            case 'base.mtrPerUnit':
            case 'base.mimPerUnit':
            case 'base.fotPerUnit': {
              const name = params.colDef.field?.split('.')[1];
              console.log(name,params.newValue);
              updateRow({
                body: {
                  ...(rest as any)!,
                  // resourceType: resourceEng[params.newValue as keyof typeof resourceEng] || rest.resourceType,
                  rowType: params.data!.rowType,
                  kfoGroupID: kfoGroupID || null,
                  [name]:typeof params.newValue === 'number'?params.newValue:Number(params.newValue?.replace(/\s/, '')?.replace(/,/, '.')) ?? null,
                },
                calcID,
                rowID: params.data!.id,
              }).then((response) => {
                flag = updateThenHandler(response);
              });
              break;
            }
            case 'title': {
              updateRow({
                body: {
                  ...(rest as any)!,
                  rowType: params.data!.rowType,
                  kfoGroupID: kfoGroupID || null,
                  title: params.newValue || '',
                },
                calcID,
                rowID: params.data!.id,
              }).then((response) => {
                flag = updateThenHandler(response);
              });
              break;
            }
            default: {
              console.log(params.newValue)
              updateRow({
                body: {
                  ...(rest as any)!,
                  rowType: params.data!.rowType,
                  kfoGroupID: kfoGroupID || null,
                  [params.colDef!.field as any]: params.newValue || null,
                },
                calcID,
                rowID: params.data!.id,
              }).then((response) => {
                flag = updateThenHandler(response,params.colDef.field,params.newValue ?? undefined);
              });
            }
          }
        } catch (e) {
          // console.log(e);
        }
      })();
      return flag;
    },
    [groupList, calcID],
  );

  const collapse = (event: ICellRendererParams<ICalculationDictionary, any, any>) => {
    setHiddenRows((d) => {
      const copy = [...d];
      if (event.data?.id) {
        const addIndex = copy.findIndex((hrId) => hrId === event.data!.id);
        if (addIndex >= 0) {
          copy.splice(addIndex, 1);
          return copy;
        } else {
          return Array.from(new Set([...flatChildren(event.data!), ...copy]));
        }
      } else {
        return copy;
      }
    });
    // Ref.current?.api?.onFilterChanged();
    // console.log(flatChildren(event.data));
  };
  console.log(data)
  useLayoutEffect(() => {
    if (data !== undefined && !isFetching && calcID) {
      if (!data.rows.length) {
        createRow({ body: rootRow, calcID });
      }
    }
  }, [data, isFetching, calcID]);


  const table = useMemo(() => {
    if (!data || isLoading || !calcID) return { rows: undefined, total: [] };

    const { codes, groups, resourceTypes, titles } = activeFilters;
    const isAnyFilters = codes.length || groups.length || resourceTypes.length || titles.length;
    if ((filteredData.length || isAnyFilters) && applyed) {
      setHiddenRows([])
      return {
        rows: filteredData,
        total: [],
      };
    }
    setHiddenRows(localHiddenRows.current)
    return {
      rows: data.rows || undefined,
      total: [data.total],
    };
  }, [filteredData, activeFilters, data, applyed,isLoading,calcID]);

  const doesExternalFilterPass =
    (params: IRowNode<ICalculationDictionary>) => {
      Ref.current?.api.setIsExternalFilterPresent(() => false);
      return params.data?.parentID ? !hiddenRows.includes(params.data.parentID) : true;
    }

  const { columnsDef } = useTable(valueSetter);

  const getConfirmDialog = async (type: 'FOLDER' | 'RATE' | 'RATE_POSITION', fn: () => void) => {
    if (type === 'FOLDER') {
      openDeleteFolderSecondLevelConfirm(fn);
    } else {
      openDeleteRateConfirm(fn);
    }
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const projectID = useProjectId();


  const handleDeleteCalculationClick = useCallback(
    (actionName: string) => {
      openConfirmDelete(actionName);
    },
    [calculation?.id],
  );


  useMutationHandlers(createdResponse, (data) => {
    Ref.current?.api?.applyTransaction({ add: [data] });
  });
  useMutationHandlers(deleteCalculationResponse, () => {
    navigate(`/projects/${projectID}/calculations`, { replace: true });
    enqueueSnackbar(translateDelete('success.calculationDelete'), {
      variant: 'success',
    });
  });

  useEffect(() => {
    Ref.current?.api?.setIsExternalFilterPresent(() => true);
    Ref.current?.api?.onFilterChanged();
  }, [doesExternalFilterPass, Ref.current,location.pathname]);


  useEffect(() => {
    if (isFetching === false) {
      Ref.current?.api?.refreshCells({ force: true });
    }
  }, [isFetching, Ref.current]);
  useEffect(() => {
    Ref.current?.api?.refreshCells({ force: true });
  }, [table, Ref.current]);
  // useEffect(() => {
  //   if (selectedRow && table.rows) {
  //     const index = table.rows.findIndex(item => item.id === selectedRow.id);
  //     console.log(index)
  //     if (index < 0) {
  //       setSelectedRow(null)
  //     }
  //   }
  // }, [table, selectedRow]);

  useEffect(() => {
    if (Ref.current && !firstLoad.current) {
      const row = unCollapse({ data: data?.rows });
      if (row) {
        let index: number | undefined;
        setTimeout(() => {
          index = Ref.current?.api.getRowNode(row.id.toString())?.rowIndex ?? undefined;
          if (index !== undefined && index >= 0) {
            setTimeout(() => {
              Ref.current?.api.ensureIndexVisible(index!, 'middle');
            }, 300);
          }
        }, 200);
      }
    }
  }, [data, Ref.current, firstLoad.current]);
  useEffect(() => {
    if (!filteredData.length) {
      localHiddenRows.current = hiddenRows;
    }
  }, [hiddenRows,filteredData]);

  useLayoutEffect(() => {
    if (unitWeightData) {
      dispatch(initialUnit(unitWeightData));
    }
  }, [unitWeightData]);
  useLayoutEffect(() => {
    if (groupData) {
      dispatch(initialGroup(groupData));
    }
  }, [groupData]);

  useLayoutEffect(() => {
    if (groupData) {
      dispatch(initialGroup(groupData));
    }
  }, [groupData]);
  useLayoutEffect(() => {
    if (table.rows && table.rows.length && firstLoadGeneral.current) {
      dispatch(calculationDictionaryApi.endpoints.getCacl.initiate({calcID}));
      firstLoad.current = false;
      firstLoadGeneral.current = false;
      console.log(table.rows)
      setHiddenRows(table.rows.map((r) => r.id));
    }
  }, [table.rows, firstLoadGeneral.current]);

  useEffect(()=>{
    if (Ref.current) {
      if(isLoading){
        Ref.current?.api?.showLoadingOverlay()

      }else{
        Ref.current?.api?.hideOverlay()
        Ref.current?.api?.refreshCells({ force: true });
        Ref.current?.api?.refreshHeader();
      }
    }
  },[isLoading,Ref.current])

  useEffect(() => {
    const { codes, groups, resourceTypes, titles } = activeFilters;
    const isAnyFilters = codes.length || groups.length || resourceTypes.length || titles.length;

    if ((filteredData.length || isAnyFilters) && applyed){
      applyFilters()
    }
  }, [data]);

  useEffect(()=>{
    if (!calcID) {
      Ref.current = null
    }
  },[calcID])

  useBreadcrumbs(
    [{ title: `Расчеты`, url: `/projects/${projectId}/calculations` }, { title: `${calculation?.title}` }],
    [calculation, projectId],
  );
  return (
    <>
      <Box display={'flex'} justifyContent={'space-between'} mt={'12px'} alignItems={'center'}>
        <ImportButton
          data-dictionary={true}
          disabled={!selectedRow}
          variant={'text'}
          onClick={() => setCreateDrawer(true)}>
          <div data-dictionary={true}>
            <EditIcon data-dictionary={true} />
            Справочник
          </div>
        </ImportButton>
        {/*<Tabs value={selectedTab} onChange={handleSelectedTab}>*/}
        {/*  <Tab label="Расценки" />*/}
        {/*  /!*<Tab label="Операции" />*!/*/}
        {/*  /!*<Tab label="Ед. изм." />*!/*/}
        {/*  /!*<Tab label="еще что-то" />*!/*/}
        {/*</Tabs>*/}
        <Box display={'flex'} gap={'12px'} alignItems={'center'}>
          <Tooltip title={'Отсортировать ресурсы'}>
          <IconButton
            // onClick={() => openDeleteRateConfirm(deleteRatesWithPositions)}
            onClick={handleRefreshTable}
            >
              <FilterListIcon color={'primary'} fontSize={'small'}/>
          </IconButton>
          </Tooltip>
          <Button
            // onClick={() => openDeleteRateConfirm(deleteRatesWithPositions)}
            onClick={deleteRatesWithPositions}
            disabled={!removedIds.length}
            variant={'text'}>
            <Box display={'flex'} gap={'8px'} alignItems={'center'}>
              <DeleteOutlineIcon fontSize={'small'} />
              Удалить
            </Box>
          </Button>
          <ImportButton
            variant={'text'}
            onClick={exportCalculation}
          >
            <div>
              <ImportIcon />
              экспорт
            </div>
          </ImportButton>
          <ImportButton
            variant={'text'}
            onClick={() => {
              setParametersDialogOpen(true);
            }}>
            <div>
              <FormatListBulletedIcon />
              параметры
            </div>
          </ImportButton>
          <Button
            onClick={(e) => setAnchorEl(e.currentTarget)}
            style={{
              padding: '8px',
              minWidth: '40px',
            }}>
            <MoreHorizIcon />
          </Button>
        </Box>
      </Box>
      {/* блок для chips */}
      <ChipWrapper>
        {filterChips.map((_) => {
          const currentFilters = activeFilters[_.code as keyof typeof activeFilters];
          const name = currentFilters.map((_) => _.name).join(', ')
          return currentFilters?.length ? (
            <Tooltip title={name}>
            <Chip onClick={() => setActive(_.code as any)}>
              {_.name}
              <Devide />
              <span>{name}</span>
              <CloseIcon
                fontSize={'small'}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  dispatch(clearFilter(_.code as any));
                  applyFilters(_.code as any)
                }}
              />
            </Chip>
            </Tooltip>
          ) : (
            <></>
          );
        })}
      </ChipWrapper>
      <CalculationDirectoryContext.Provider
        value={{
          setDeletedLvlTitle: (lvl: number) => setDeletedLvl(lvl),
          clearRemovedIDs: () => setRemovedIds([]),
          selectRow: selectedRow,
          setSelectRow:(row)=>setSelectedRow(row),
          rows: hiddenRows,
          removedIds,
          confirmCreateRate:openConfirmCreateRatePosition,
          checkboxClick: clickCheckbox,
          deleteFolderConfirm: getConfirmDialog,
          collapse,
          tableData: data?.rows || [],
          unCollapseRows: () => {},
        }}>
        <PageStyled>
          <span></span>
          <ClickAwayListener
            onClickAway={(ev: any) => {
              ev.preventDefault();
              ev.stopPropagation();
              const target = ev.target;
              if (target && target?.dataset?.dictionary) {
                return;
              } else {
                if (selectedRow) {
                  Ref.current?.api?.deselectAll();
                  setSelectedRow(() => null);
                }
              }
            }}>
            <>
              <WrapperAgGrid className="ag-theme-material reference-prices">
                <AgGridReact
                  ref={Ref}
                  enableCellTextSelection={true}
                  ensureDomOrder={true}
                  maintainColumnOrder={true}
                  defaultColDef={defaultColDef}
                  // rowSelection={'single'}
                  // onRowSelected={(event) => {
                  //}}
                  onCellFocused={(event) => {
                    let row: any;
                    if (event.rowPinned === 'top') return;
                    Ref.current?.api.forEachNode((_) => {
                      if (_.rowIndex === event.rowIndex) {
                        console.log(_.data);
                        row = _.data;
                      }
                    }),
                      row && setSelectedRow(row as ICalculationDictionary);
                  }}
                  columnDefs={columnsDef}
                  groupHeaderHeight={40}
                  singleClickEdit
                  // isRowExpanded={isRowExpanded}
                  getRowClass={(params: RowClassParams<ICalculationDictionary, any>) => {
                    return params.data?.rowType === 'FOLDER' ? `level-${params.data?.lvl}` : params.data?.rowType === 'RATE'?'rate':'level-12';
                    // return `level-${params.data?.lvl}`;
                  }}
                  gridOptions={{
                    // suppressDragLeaveHidesColumns: true,
                    suppressAnimationFrame: true,
                    navigateToNextHeader: () => null,
                    tabToNextHeader: () => null,
                  }}
                  // onRowClicked={handleRowClicked}
                  // isRowExpanded={isRowExpanded}
                  pinnedTopRowData={table.total}
                  rowData={table.rows}
                  suppressCellFocus={false}
                  getRowId={(params) => {
                    return params.data.id.toString();
                  }}
                  getRowHeight={(params) => {
                    if (params.node.rowPinned === 'top') {
                      return 40;
                    }
                    return 80;
                  }}
                  rowStyle={{
                    padding: '0 !important',
                  }}
                  // onGridReady={onGridReady}
                  // onCellClicked={onCellClicked}
                  // getRowStyle={(params: any) => {
                  //   return params.data.lvl === 1 ? { color: '#0044B4' } : undefined;
                  // }}
                  rowHeight={80}
                  headerHeight={40}
                  context={{ rows: hiddenRows, changeDialog }}
                  doesExternalFilterPass={doesExternalFilterPass}
                  loadingOverlayComponent={Progress}
                  noRowsOverlayComponent={() => <>нет данных</>}></AgGridReact>
              </WrapperAgGrid>
              <DrawerInsertRow
                close={() => setCreateDrawer(false)}
                createDrawer={createDrawer}
                referenceData={referenceData}
                isRoot={true}
              />
              <DialogFilters variant={active === 'kfoGroups'?'groups':active} apply={applyFilters} close={() => changeDialog()} />
            </>
          </ClickAwayListener>
        </PageStyled>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}>
          <MenuItem
            onClick={() => handleDeleteCalculationClick('Delete')}
            disabled={deleteCalculationResponse.isLoading}>
            Удалить расчет
          </MenuItem>
          <MenuItem
            onClick={() => {
              openEdit();
              setAnchorEl(null);
            }}>
            Редактировать
          </MenuItem>
        </Menu>
        {/* Блок модалок предупреждений */}
        <DeleteFolderSecondLevelConfirmDialog />
        <DeleteRateConfirmDialog />
        <CreateRatePositionConfirm/>
        <ConfirmDiaologUpdate/>
        <ConfirmDiaologDelete />
        {/* *************************** */}
        <Parameters open={parametersDialogOpen} close={() => setParametersDialogOpen(false)} />
      </CalculationDirectoryContext.Provider>
    </>
  );
};

export default CalculationDirectory;
