import { LegendColors } from 'types';
import {
  ProjectFinType,
  ProjectStatus,
  ProjectStatusForCard,
  ProjectStatusForTabLabel,
  ProjectTabLabel,
  ProjectType,
} from './project';

export const projectTabLabels: ProjectTabLabel[] = ['Все проекты', 'Активные', 'Приостановленные', 'Завершенные'];

export const projectStatuses: ProjectStatus[] = ['Активный', 'Завершенный', 'Приостановленный'];

export const projectTypes: ProjectType[] = ['Линейные объекты', 'Производственного и непроизводственного назначения'];

export const projectFinTypes: ProjectFinType[] = ['Инвестиционный', 'Подрядный', 'Смешанный'];

export const projectStatusByTabLabel: Record<ProjectStatusForTabLabel, ProjectStatus> = {
  Активные: 'Активный',
  Приостановленные: 'Приостановленный',
  Завершенные: 'Завершенный',
};

export const projectStatusesColors: Record<ProjectStatus, LegendColors> = {
  Активный: 'lightgreen',
  Приостановленный: 'purple',
  Завершенный: 'disabled',
};

export const projectStatusesForCard: Record<ProjectStatus, ProjectStatusForCard> = {
  Активный: 'Активный',
  Приостановленный: 'Приостановлен',
  Завершенный: 'Завершенный',
};

export const projectTypeShort: Record<ProjectType, string> = {
  'Линейные объекты': 'Линейные объекты',
  'Производственного и непроизводственного назначения': 'Произв. и непроизв. назначения',
};
