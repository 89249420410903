/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import styled from "styled-components";

export const Wrapper = styled.div`
    display:flex;
    flex-direction:column;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    padding: 20px;
    border-radius: 8px;
`
export const LogoWrapper = styled(Wrapper)`
    justify-content: flex-start;
    background: #F6F7FB;
    padding: 10px 0 20px;
    
`
export const TitleWrapper = styled.div`
    padding: 0px 8px;
    border-radius: 100px;
    background: #6D9ADC;
    position: absolute;
    top:-8px;
`
export const ContentWrapper = styled(Wrapper)`
    margin-top: 20px;
    padding: 20px;
    background: #1976D214;
    gap: 16px;
    text-align: center;
`
export const LinkAct = styled.span`
    color: ${({theme:{palette}})=>palette.primary.main};
    cursor: pointer;
`
