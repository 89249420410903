import { Portal, Stack } from "@mui/material";
import { GridReadyEvent, GridSizeChangedEvent, ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import React, { createContext, FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import {
  CommonFiltersRequest,
  CommonFiltersResponse,
  FiltersVorBody,
  PositionVOR
} from "../../../../api/calculations/types";
import Progress from "../../../../components/Progress";
import { setVor } from "../../../../store/slices/vor/vor";
import { WrapperAgGrid } from "../../../Calculations/components/Accomplishment/Accomplishment.styles";
import Cell from "../../../Calculations/components/Accomplishment/components/Cell";
import { CheckboxCell } from "../TableLegend/cells/CheckboxCell";
import {
  CHAPTER_CELL_WIDTH,
  CHECKBOX_CELL_WIDTH,
  CODE_CELL_WIDTH,
  CONSTRUCTIONS_CELL_WIDTH,
  LSR_CELL_WIDTH,
  MECHANICS_CELL_WIDTH,
  NUMBER_CELL_WIDTH,
  PositionsTableProps,
  QUANTITY_CELL_WIDTH,
  SelectedPositionType,
  TITLE_CELL_WIDTH,
  TOTAL_COST_CELL_WIDTH,
  UNIT_CELL_WIDTH
} from "./PositionsTable.types";
import { NoRows } from "../../../Administration/AdminReferences/Prices/useTableData";
import HeaderComponent from "./components/HeaderComponent/HeaderComponent";
import { OrNull } from "../../../../api/references/estimates/estimates.types";
import DialogFilters from "./components/DialogFilters";
import {
  Chip,
  ChipWrapper,
  Devide
} from "../../../Administration/AdminReferences/Prices/components/PricesReferences.style";
import Tooltip from "../../../../components/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import { TSelectPositionArr } from "../../Vor.types";

export type DrawerType = OrNull<
  "lsr" |
  "number" |
  "chapter" |
  "header" |
  "code" |
  "title"
>

interface ContextVor {
  toggleDrawer: (flag: DrawerType) => void;
  checkedData?: Record<keyof CommonFiltersRequest, number[]>;
  selectPositions: TSelectPositionArr
}

export const filterView: Record<Exclude<DrawerType, null>, keyof CommonFiltersResponse> = {
  lsr    : "lsrCodes",
  number : "lsrNums",
  chapter: "chapters",
  header : "titles",
  code   : "priceCodes",
  title  : "names"
};

export const PositionVorContext = createContext<ContextVor>({
  toggleDrawer: () => {
  },
  selectPositions:[]
});
export const PositionsTable: FC<PositionsTableProps> = ({
  data,
  checkedData,
  applyedFilter,
  handleOpenModal,
  setSelectedVor,
  container,
  type,
  handleApplyFilters,
  checkFilter,
  viewFilters,
  selectPosition,setSelectPosition
}) => {
  const dispatch = useAppDispatch();
  const currentFilters = useMemo(() => {
    return type === "list" ? viewFilters.rowsFilter : viewFilters.formedFilter;
  }, [viewFilters, type]);

  const { savingData, deletedRows } = useSelector((state: RootState) => state.vor.table);

  const Ref = useRef<AgGridReact<PositionVOR> | null>(null);
  const [, setGridRef] = useState<GridReadyEvent<PositionVOR, unknown> | null>(null);

  const [openDrawer, setOpenDrawer] = useState<DrawerType>(null);
  const toggleDrawer = useCallback((mode: DrawerType) => {
    setOpenDrawer(mode);
  }, []);

  const update = useCallback(
    (vor: PositionVOR, type: SelectedPositionType | "secondary" | null) => {
      if (!Ref.current) return false;
      console.log(vor,type);
      setSelectPosition(vor,type,selectPosition)
      // Ref.current?.api.applyTransaction({ update: [{ ...vor, type }] });
      // Ref.current?.api?.refreshCells({ force: true });
    },
    [Ref.current,selectPosition]
  );

  useEffect(() => {
    if (!savingData?.calcId) return;

    if (deletedRows.length && savingData?.calcId && deletedRows.length) {
      setSelectPosition(deletedRows,null,selectPosition)
      // // dispatch(
      // //   calculationsApi.util.updateQueryData("getVorPositions", { calcId: savingData.calcId }, (draft) => {
      // //     draft.data = [
      // //       ...(deletedRows.map((value) => ({
      // //         ...value,
      // //         type: null
      // //       })) as PositionVOR[]),
      // //       ...draft.data
      // //     ];
      // //   })
      // );
    }

    return () => {
      // dispatch(
      //   calculationsApi.util.updateQueryData("getVorPositions", { calcId: savingData.calcId }, (draft) => {
      //     Object.assign(draft.data, data);
      //   })
      // );
    };
  }, [deletedRows, deletedRows]);

  const columnsDef = useMemo(() => {
    return [
      {
        headerName     : "Осн",
        hide           : type === "group",
        field          : "main",
        minWidth       : 55,
        maxWidth       : 55,
        width          : 55,
        suppressMovable: true,
        cellRenderer   : ({ data }: ICellRendererParams<PositionVOR, unknown, unknown>) => {
          const rowColor = (data?.type === "main" && "info") || (data?.type === "extra" && "warning") || undefined;
          const props = {
            label: "Осн",
            style: {
              minWidth: CHECKBOX_CELL_WIDTH,
              maxWidth: CHECKBOX_CELL_WIDTH,
              width   : CHECKBOX_CELL_WIDTH
            }
          };
          return (
            <Stack display={"flex"} key={'main'} alignItems={"center"} justifyContent={"center"}>
              {data && (
                <CheckboxCell
                  align={"center"}
                  type={"main"}
                  update={update}
                  color={rowColor}
                  iconProps={{ color: "info" }}
                  key={`${data.id}-render-main`}
                  vor={data}
                  {...props}
                />
              )}
            </Stack>
          );
        }
      },
      {
        headerName     : "Всп",
        hide           : type === "group",
        field          : "extra",
        minWidth       : 55,
        maxWidth       : 55,
        width          : 55,
        suppressMovable: true,
        cellRenderer   : ({ data }: ICellRendererParams<PositionVOR, unknown, unknown>) => {
          const rowColor = (data?.type === "main" && "info") || (data?.type === "extra" && "warning") || undefined;
          const props = {
            label: "Всп",
            style: {
              minWidth: CHECKBOX_CELL_WIDTH,
              maxWidth: CHECKBOX_CELL_WIDTH,
              width   : CHECKBOX_CELL_WIDTH
            }
          };
          return (
            <Stack display={"flex"} key={'extra'} alignItems={"center"} justifyContent={"center"}>
              {data && (
                <CheckboxCell
                  color={rowColor}
                  update={update}
                  align={"center"}
                  type={"extra"}
                  iconProps={{ color: "warning" }}
                  key={`${data.id}-render-extra`}
                  vor={data}
                  {...props}
                />
              )}
            </Stack>
          );
        }
      },
      {
        headerName  : "№",
        field       : "index",
        minWidth    : NUMBER_CELL_WIDTH,
        maxWidth    : NUMBER_CELL_WIDTH,
        width       : NUMBER_CELL_WIDTH,
        cellRenderer: ({ data }: ICellRendererParams<PositionVOR, unknown, unknown>) => {
          return <Cell float={"center"}>{data?.numbering}</Cell>;
        }
      },
      {
        headerName     : "Шифр ЛСР",
        headerComponent: HeaderComponent,
        field          : "lsr",
        minWidth       : LSR_CELL_WIDTH,
        maxWidth       : 500,
        cellRenderer   : ({ data }: ICellRendererParams<PositionVOR, unknown, unknown>) => {
          return <Cell>{data?.lsr}</Cell>;
        }
      },
      {
        headerName     : "№ по ЛСР",
        headerComponent: HeaderComponent,
        field          : "number",
        minWidth       : 150,
        maxWidth       : 150,
        width          : 150,
        // minWidth: LSR_NUMBER_CELL_WIDTH,
        // maxWidth: LSR_NUMBER_CELL_WIDTH,
        // width: LSR_NUMBER_CELL_WIDTH,
        cellRenderer: ({ data }: ICellRendererParams<PositionVOR, unknown, unknown>) => {
          return <Cell float={"center"}>{data?.number}</Cell>;
        }
      },
      {
        headerName     : "Раздел",
        headerComponent: HeaderComponent,
        field          : "chapter",
        minWidth       : CHAPTER_CELL_WIDTH,
        maxWidth       : 500,
        cellRenderer   : ({ data }: ICellRendererParams<PositionVOR, unknown, unknown>) => {
          return <Cell>{data?.chapter}</Cell>;
        }
      },
      {
        headerName     : "Заголовок",
        headerComponent: HeaderComponent,
        field          : "header",
        minWidth       : 150,
        maxWidth       : 350,
        cellRenderer   : ({ data }: ICellRendererParams<PositionVOR, unknown, unknown>) => {
          return <Cell>{data?.header}</Cell>;
        }
      },
      {
        headerName     : "Код расценки",
        headerComponent: HeaderComponent,
        field          : "code",
        minWidth       : CODE_CELL_WIDTH,
        maxWidth       : CODE_CELL_WIDTH,
        width          : CODE_CELL_WIDTH,
        cellRenderer   : ({ data }: ICellRendererParams<PositionVOR, unknown, unknown>) => {
          return <Cell float={"center"}>{data?.code}</Cell>;
        }
      },
      {
        headerName     : "Наименование",
        headerComponent: HeaderComponent,
        field          : "title",
        minWidth       : TITLE_CELL_WIDTH,
        maxWidth       : 550,
        cellRenderer   : ({ data }: ICellRendererParams<PositionVOR, unknown, unknown>) => {
          return <Cell>{data?.title}</Cell>;
        }
      },
      {
        headerName     : "Ед. изм",
        field          : "unit",
        minWidth       : UNIT_CELL_WIDTH,
        maxWidth       : UNIT_CELL_WIDTH,
        width          : UNIT_CELL_WIDTH,
        suppressMovable: true,
        cellRenderer   : ({ data }: ICellRendererParams<PositionVOR, unknown, unknown>) => {
          return <Cell float={"center"}>{data?.unit}</Cell>;
        }
      },
      {
        headerName     : "Кол-во",
        field          : "quantity",
        minWidth       : QUANTITY_CELL_WIDTH,
        maxWidth       : QUANTITY_CELL_WIDTH,
        width          : QUANTITY_CELL_WIDTH,
        suppressMovable: true,
        cellRenderer   : ({ data }: ICellRendererParams<PositionVOR, unknown, unknown>) => {
          return (
            <Cell float={"center"}>
              {data?.quantity?.toString()?.replace(/\./, ",")}
              {/*{data?.quantity?.toLocaleString('ru-RU', {*/}
              {/*  minimumFractionDigits: 0,*/}
              {/*  maximumFractionDigits: 8,*/}
              {/*})}*/}
            </Cell>
          );
        }
      },
      {
        headerName     : "ТЗ ОР, чел-ч",
        field          : "constructionEffort",
        minWidth       : CONSTRUCTIONS_CELL_WIDTH,
        maxWidth       : CONSTRUCTIONS_CELL_WIDTH,
        width          : CONSTRUCTIONS_CELL_WIDTH,
        suppressMovable: true,
        cellRenderer   : ({ data }: ICellRendererParams<PositionVOR, unknown, unknown>) => {
          return (
            <Cell float={"center"}>
              {data?.constructionEffort?.toLocaleString("ru-RU", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </Cell>
          );
        }
      },
      {
        headerName     : "ТЗМ, чел-ч",
        field          : "mechanicsEffort",
        minWidth       : MECHANICS_CELL_WIDTH,
        maxWidth       : MECHANICS_CELL_WIDTH,
        width          : MECHANICS_CELL_WIDTH,
        suppressMovable: true,
        cellRenderer   : ({ data }: ICellRendererParams<PositionVOR, unknown, unknown>) => {
          return (
            <Cell float={"right"}>
              {data?.mechanicsEffort?.toLocaleString("ru-RU", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </Cell>
          );
        }
      },
      {
        headerName     : "Всего стоимость",
        field          : "totalWorkCost",
        minWidth       : TOTAL_COST_CELL_WIDTH,
        maxWidth       : TOTAL_COST_CELL_WIDTH,
        width          : TOTAL_COST_CELL_WIDTH,
        suppressMovable: true,
        cellRenderer   : ({ data }: ICellRendererParams<PositionVOR, unknown, unknown>) => {
          return (
            <Cell float={"right"}>
              {data?.totalWorkCost?.toLocaleString("ru-RU", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </Cell>
          );
        }
      }
    ];
  }, [update, type]);
  const onClickVor = (vor: PositionVOR, t: typeof type) => {
    if (t === "group") {
      handleOpenModal();
      setSelectedVor(vor);
      dispatch(setVor(vor.id));
    }
  };
  useEffect(() => {
    localStorage.removeItem("check");
  }, []);

  const chips = useMemo(() => {
    const title: Record<Exclude<DrawerType, null>, string> = {
      title  : "Наименование",
      chapter: "Раздел",
      lsr    : "Шифр ЛСР",
      code   : "Код расценки",
      header : "Заголовок",
      number : "№ по ЛСР"
    };
    const names: Record<keyof CommonFiltersRequest, Exclude<DrawerType, null>> = {
      lsrCodeIDs  : "lsr",
      chapterIDs  : "chapter",
      lsrNumIDs   : "number",
      titleIDs    : "header",
      priceCodeIDs: "code",
      nameIDs     : "title"
    };
    const keys: Array<{ key: Exclude<DrawerType, null>, title: string, values: string; }> = [];
    const genKey: keyof FiltersVorBody = type === "list" ? "rowsFilter" : "formedFilter";
    let applyedFilterKey: keyof CommonFiltersRequest;
    for (applyedFilterKey in applyedFilter[genKey]) {
      const viewName = applyedFilterKey.replace("ID", "") as keyof CommonFiltersResponse;

      // console.log(viewName);
      // console.log(viewFilters[genKey][viewName]);

      const values = checkedData[genKey][viewName].map(_ => _.title || 'Пустые').join(', ').trim();
      // const values = viewFilters[genKey][viewName]
      //   .reduce((res, val) => {
      //     const ids = JSON.stringify(val.ids);
      //     const isChecked = checkedData[genKey][viewName].includes(ids);
      //     if (isChecked) {
      //       if (!res) {
      //         res += val.title;
      //       } else {
      //         res += ", " + val.title;
      //       }
      //     }
      //     return res;
      //   }, "")||'Пустые';
      if (applyedFilter[genKey][applyedFilterKey]?.length) {
        keys.push({
          key  : names[applyedFilterKey as keyof typeof names],
          title: title[names[applyedFilterKey as keyof typeof names]],
          values
        });
      }
    }
    return keys;
  }, [applyedFilter, type, checkedData, viewFilters]);

  return (
    <>
      <Portal container={container.current}>

        <ChipWrapper>
          {chips.map(item => {
            return (
              <Chip onClick={() => setOpenDrawer(item.key)}>
                {item.title}
                <Devide />
                <Tooltip title={item.values}>
                  <span>{item.values}</span>
                </Tooltip>
                <CloseIcon
                  fontSize={"small"}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    // dispatch(clearFilter(_.code as any));
                    checkFilter(type === "group" ? "formedFilter" : "rowsFilter", filterView[item.key], null, true);
                  }}
                />
              </Chip>
            );
          })}
        </ChipWrapper>
      </Portal>
      <PositionVorContext.Provider
        value={{ toggleDrawer,selectPositions:selectPosition, checkedData: applyedFilter[type === "group" ? "formedFilter" : "rowsFilter"] }}>

        <WrapperAgGrid className="ag-theme-material">
          <AgGridReact
            ref={Ref}
            onGridReady={(e) => setGridRef(e)}
            enableCellTextSelection={true}
            columnDefs={columnsDef}
            groupHeaderHeight={25}
            gridOptions={{
              navigateToNextHeader: () => null,
              tabToNextHeader     : () => null
            }}
            // pinnedTopRowData={total}
            rowData={data}
            onRowClicked={(event) => {
              onClickVor(event.data, type);
            }}
            suppressCellFocus={true}
            onFirstDataRendered={(event) => {
              event.api.sizeColumnsToFit();
            }}
            onGridSizeChanged={(event: GridSizeChangedEvent) => {
              event.api.sizeColumnsToFit();
            }}
            onViewportChanged={(event) => {
              event.api.sizeColumnsToFit();
            }}
            getRowId={(params) => {
              return params.data.id.toString();
            }}
            // getRowClass={getRowClass}
            getRowHeight={(params) => {
              if (params.node.rowPinned === "top") {
                return 50;
              }
              return 55;
            }}
            rowStyle={{
              padding: "0 !important"
            }}
            rowHeight={55}
            headerHeight={36}
            loadingOverlayComponent={Progress}
            noRowsOverlayComponent={NoRows}></AgGridReact>
        </WrapperAgGrid>
        <DialogFilters checkedData={checkedData} checkFilter={checkFilter}
                       type={type === "list" ? "rowsFilter" : "formedFilter"} handleApplyFilters={handleApplyFilters}
                       view={openDrawer ? currentFilters[filterView[openDrawer]] : []} open={openDrawer}
                       close={() => setOpenDrawer(null)} />
      </PositionVorContext.Provider>
    </>
  );
};
