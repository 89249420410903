import { LegendColors } from 'types';
import { UserRoleTabLabels, UserRoles, UserRolesEnToRu, UserRolesRuToEn } from './user';

export const userRolesRuToEn: UserRolesRuToEn = {
  Администратор: 'admin',
  Пользователь: 'client',
  'Без роли': 'none',
};

export const userRolesEnToRu: UserRolesEnToRu = {
  admin: 'Администратор',
  client: 'Пользователь',
  none: 'Без роли',
};

export const userRolesArray = Object.keys(userRolesEnToRu) as Array<keyof UserRolesEnToRu>;

export const userTabNamesArray: UserRoleTabLabels[] = [
  'Все пользователи',
  'Администраторы',
  'Пользователи',
  'Без роли',
];

export const UserRoleByTabLabel: Record<Exclude<UserRoleTabLabels, 'Все пользователи'>, UserRoles> = {
  Администраторы: 'admin',
  Пользователи: 'client',
  'Без роли': 'none',
};

export const UserRolesColors: Record<UserRoles, LegendColors> = {
  admin: 'blue',
  client: 'orange',
  none: 'disabled',
};
