import { Controller, useFormContext, useWatch } from "react-hook-form";
import { FormControl, FormHelperText, IconButton, Typography } from "@mui/material";
import { GetCoefficientsResponse } from "api/params/params.types";
import { ToggleButtonGroup } from "components/ToggleButtonGroup";
import { ToggleButton } from "components/ToggleButton";
import { CreatingItemProps } from "./CreatomgItem.types";
import { EstimateRangeSelect } from "../../../EstimateRangeSelect";
import { StyledClearableTextField } from "../../TabComponent.styles";
import { IndexesFields, ToTotalsFields } from "../../TabComponent.consts";
import { BaseTextField } from "../../../../../../../../../../components/BaseTextField";
import { NumericFormat } from "react-number-format";
import React, { useCallback, useContext, useMemo, useState } from "react";
import Button from "../../../../../../../../../../components/Button";
import CheckIcon from "@mui/icons-material/Check";
import { Delete } from "@mui/icons-material";
import { isEmpty } from "../../../../../../../../../../utils/isEmpty";
import { LoaderContext } from "../../../ParametersForm";

export const CreatingItem: React.FC<CreatingItemProps> = ({
  parentIndex,
  itemType,
  deleteItem,
  data: chapter,
  index,
  create,
  // last,
}) => {
  const { getValues, control } = useFormContext<GetCoefficientsResponse>();
  const { disabledGlobal } = useContext(LoaderContext);

  const fields = useWatch({
    name: `${itemType}` as 'created.indexes' | 'created.toTotals',
    control,
  });

  const last = useMemo(() => {
    return fields[`parent${parentIndex}`][fields[`parent${parentIndex}`]?.length - 1] || undefined;
  }, [fields, parentIndex]);

  const lastIndex = useMemo(() => {
    return fields[`parent${parentIndex}`]?.length - 1;
  }, [last]);

  const field = useWatch({
    name: `${itemType}.parent${parentIndex}.${index}` as 'created.indexes.parent0.0' | 'created.toTotals.parent0.0',
    control,
  });

  const data = useMemo(() => {
    const field = getValues(itemType);
    const key: keyof typeof field = `parent${parentIndex}`;
    return field[key][index];
  }, [parentIndex, fields, index]);

  const [updateIndexInputs, setUpdateIndexInputs] = useState(false);

  const isDisabledIndexInput = useCallback(
    (obj: any, controller: string) => {
      if (!obj || !Object.keys(obj).length) return false;
      if (controller === 'smr') {
        return isEmpty(obj, ['oz', 'em', 'zm', 'mt'], false);
      } else if (['oz', 'em', 'zm', 'mt'].includes(controller)) {
        return isEmpty(obj, ['smr'], false);
      } else return false;
    },
    [updateIndexInputs],
  );

  const IsPercent = (
    <Controller
      name={`created.toTotals.parent${parentIndex}.${index}.isPercent`}
      control={control}
      render={({ field: { onChange, ref, value, ...props }, fieldState }) => (
        <FormControl error={!!fieldState.error}>
          <ToggleButtonGroup
            {...props}
            exclusive
            fullWidth
            onChange={(_, value) => {
              value !== null && onChange(value === '%');
            }}>
            {['К', '%'].map((groupButton) => {
              const selected = groupButton === 'К' ? !value : value;
              return (
                <ToggleButton key={groupButton} value={groupButton} selected={selected}>
                  {groupButton}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
          {!!fieldState.error && <FormHelperText>{fieldState.error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );

  return (
    <>
      <Controller
        name={`${itemType}.parent${parentIndex}.${index}.chapterTitle` as any}
        control={control}
        render={({ field: { ref, ...props } }) => {
          return (
            <EstimateRangeSelect
              {...props}
              parentIndex={parentIndex}
              currentValue={('chapterTitle' in field && field.chapterTitle) || []}
              globalDisabled={disabledGlobal[`parent${parentIndex!}`]}
              index={index}
              parentID={getValues(`${itemType.replace(/^.+\.(.+)/, '$1')}.${parentIndex}.fileID` as any)}
              sx={{ paddingRight: '20px' }}
            />
          );
        }}
      />
      {itemType.includes('created.indexes')
        ? IndexesFields.map((controller) =>
            controller === 'recordTitle' ? (
              <Controller
                name={`created.indexes.parent${parentIndex}.${index}.recordTitle`}
                control={control}
                render={({ field: { onChange, ref, value, ...props } }) => (
                  <BaseTextField
                    {...props}
                    inputRef={ref}
                    value={field.recordTitle}
                    onChange={onChange}
                    sx={{ paddingRight: '20px' }}
                  />
                )}
              />
            ) : controller === 'coeff' ? (
              <Typography textAlign="center">Коэф.</Typography>
            ) : (
              <Controller
                name={`created.indexes.parent${parentIndex}.${index}.${controller}` as any}
                control={control}
                render={({ field: { ref, onChange, value, ...props }, fieldState }) => (
                  <NumericFormat
                    {...props}
                    value={field[controller as keyof typeof field] as string}
                    inputRef={ref}
                    isAllowed={(values) => {
                      const { formattedValue } = values;
                      return /^(\d{0,14})(?:,\d{0,15})?$/.test(formattedValue);
                      // return floatValue < MAX_LIMIT;
                    }}
                    customInput={StyledClearableTextField}
                    placeholder="К"
                    decimalScale={15}
                    //@ts-ignore
                    disabled={isDisabledIndexInput(data, controller)}
                    onChange={(event) => {
                      setUpdateIndexInputs((prevState) => !prevState);
                      onChange(event);
                    }}
                    decimalSeparator=","
                    error={!!fieldState.error}
                    onClear={() => {
                      setUpdateIndexInputs((prevState) => !prevState);
                      onChange('');
                    }}
                  />
                )}
              />
            ),
          )
        : ToTotalsFields.map((controller) =>
            controller === 'isPercent' ? (
              IsPercent
            ) : controller === 'recordTitle' ? (
              <Controller
                name={`created.toTotals.parent${parentIndex}.${index}.recordTitle`}
                control={control}
                render={({ field: { onChange, ref, value, ...props } }) => (
                  <BaseTextField
                    {...props}
                    inputRef={ref}
                    value={field.recordTitle}
                    onChange={onChange}
                    sx={{ paddingRight: '20px' }}
                  />
                )}
              />
            ) : (
              <Controller
                name={`created.toTotals.parent${parentIndex}.${index}.${controller}` as any}
                control={control}
                render={({ field: { ref, value, ...props }, fieldState }) => {
                  const isPercent = useWatch({
                    control,
                    name: `created.toTotals.parent${parentIndex}.${index}.isPercent` as any,
                  });

                  return (
                    <NumericFormat
                      {...props}
                      inputRef={ref}
                      value={field[controller as keyof typeof field] as string}
                      customInput={StyledClearableTextField}
                      isAllowed={(values) => {
                        const { formattedValue } = values;
                        return /^(\d{0,14})(?:,\d{0,15})?%?$/.test(formattedValue);
                        // return floatValue < MAX_LIMIT;
                      }}
                      placeholder="К"
                      suffix={isPercent ? '%' : ''}
                      decimalScale={15}
                      decimalSeparator=","
                      error={!!fieldState.error}
                      onClear={() => {
                        props.onChange('');
                      }}
                    />
                  );
                }}
              />
            ),
          )}
      {lastIndex === index ? (
        <Button
          style={{ minWidth: '36px', padding: '6px' }}
          color="success"
          disabled={last && 'chapterTitle' in last && !last.chapterTitle?.length}
          sx={{ marginLeft: '11px', width: '10px' }}
          onClick={create}>
          <CheckIcon />
        </Button>
      ) : (
        <IconButton onClick={() => deleteItem(index)} color="warning" sx={{ marginLeft: '20px' }}>
          <Delete />
        </IconButton>
      )}
    </>
  );
};
