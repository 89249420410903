/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import styled from "styled-components";

export const PageStyled = styled.div`
  width: 100%;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 20px;
  overflow-y: auto;
  overflow-x: auto;
  display: grid;
  height: 100%;
  grid-template-rows: min-content 1fr;
`;

export const WrapperAgGrid = styled.div`
    //--ag-selected-row-background-color: rgba(144, 243, 144, 0.66);
    --ag-cell-horizontal-border: 1px solid rgb(210, 220, 255);
    --ag-row-hover-color: #d1d8fa !important;
    --ag-header-column-separator-color: black !important;
    --ag-input-focus-border-color: #e2e2e2 !important;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    
    //border-top: 4px solid rgba(25, 118, 210, 0.08);
    .ag-sticky-label{
        position:sticky;
        left:10px;
        right:10px;
    }
    .ag-cell.cell-hover:hover {
        border-bottom: 1px solid #0044b4 !important;
    }

    .cell-hover:hover {
        border-bottom: 1px solid #0044b4 !important;
        cursor: pointer;
    }

    .level-1 {
        background: #87b4ff;
    }

    .level-2 {
        background: rgba(135, 180, 255, 0.9);
    }

    .level-3 {
        background: rgba(135, 180, 255, 0.8);
    }

    .level-4 {
        background: rgba(135, 180, 255, 0.7);
    }

    .level-5 {
        background: rgba(135, 180, 255, 0.6);
    }

    .level-6 {
        background: rgba(135, 180, 255, 0.5);
    }

    .level-7 {
        background: rgba(135, 180, 255, 0.4);
    }

    .level-8 {
        background: rgba(135, 180, 255, 0.3);
    }

    .level-9 {
        background: rgba(135, 180, 255, 0.2);
    }

    .level-10 {
        background: rgba(135, 180, 255, 0);
    }

    .level-11 {
        background: rgba(135, 180, 255, 0);
    }

    .rate {
        background: rgba(77, 102, 134, 0.1) !important;
    }

    .ag-header-cell {
        font-weight: 500 !important;
    }

    .ag-cell-wrapper {
        .MuiBox-root {
            height: 100% !important;
        }
    }

    .ag-cell-inline-editing {
        padding: 0;
    }

    .ag-cell.ag-cell-normal-height.ag-cell-inline-editing {
        background: transparent;
        border: none;
        width: 100%;
        height: 100%;
        box-shadow: none;
        //padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .ag-cell-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .remove-padding {
        padding: 0;

        .ag-cell-value {
            height: 100% !important;
        }
    }

    .ag-cell .border-left {
        border-left-color: black !important;
    }

    .ag-cell.ag-cell-normal-height.ag-cell-inline-editing {
        box-shadow: none !important;
    }

    .ag-cell.ag-cell-inline-editing:has(.ag-has-focus) {
        background: black !important;
    }

    .border-left-price-ref {
        border-left: 1px solid #e2e2e2 !important;
    }

    .border-right-price-ref {
        border-right: 1px solid #e2e2e2 !important;
    }

    .grouped-unit-resource {
        padding: 0 !important;

        span {
            height: 100%;
        }
    }

    //.uuid {
    //  outline-width: 2px !important;
    //  outline-color: transparent !important;
    //  outline-offset: -2px !important;
    //  outline-style: solid !important;
    //  transition: outline-color linear 3s;
    //}
    //.ag-row.uuid-on {
    //  outline-color: rgba(79, 227, 79, 0.6) !important;
    //}
    //.ag-row.uuid-off {
    //  outline-color: transparent !important;
    //}
`;
export const BadgeAct = styled.div<{ color: string; }>`
    line-height: 21px;
    padding: 1.5px 6px;
    color: white;
    border-radius: 4px;
    background: ${({ color }) => color};
`;
