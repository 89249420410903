import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { PageStyled } from './FullVor.styles';
import { Table } from './Table';
import Topbar from './Topbar/Topbar';

const FullVor: React.FC = () => {
  useBreadcrumbs([{ title: 'Ведомость укрупненных расценок' }]);

  return (
    <PageStyled>
      <Topbar />
      <Table />
    </PageStyled>
  );
};

export default FullVor;
