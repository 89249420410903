import { GetCoefficientsResponse } from 'api/params/params.types';
import { useFormContext, useFieldArray } from 'react-hook-form';
import TabComponent from '../../../../../TabComponent';
import { useEffect, useMemo, useState } from "react";

const Body: React.FC = () => {
  const { control} = useFormContext<GetCoefficientsResponse>();

  const [fields,setFields] = useState<typeof fieldsForm>([])

  const { fields:fieldsForm } = useFieldArray({
    name:'indexes',
    control
  })


  useEffect(() => {
    setFields(fieldsForm)
  }, [fieldsForm]);

  return (
    <div>
      <TabComponent fields={fields} itemType={'indexes'} />
    </div>
  );
};

export default Body;
