import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Button, FormControl, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { useCreateCalculationMutation, useUploadExcelMutation } from "api/calculations";
import { useGetLSRFilesQuery } from "api/lsr";
import Progress from "components/Progress";
import { Form, FormikProvider } from "formik";
import { useProjectId } from "hooks/useProjectId";
import { useSnackbar } from "notistack";
import React, {
  createContext,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Accept } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { CalcDataType } from "types";
import UploadForm from "../../../../components/UploadForm";
import { useForm } from "../../../../hooks/useForm";
import { useMutationHandlers } from "../../../../hooks/useMutationHandlers";
import { theme } from "../../../../styles/theme";
import {
  StyledFieldFormDesc,
  StyledFieldFormTitle,
  StyledFormControlLabel,
  StyledFormLabel
} from "./CalculationDrawerForm.styles";
import { CalculationDrawerFormProps, CalculationFormData, MethodsItemsType } from "./CalculationDrawerForm.types";
import { validationCalculation } from "./CalculationDrawerForm.validation";
import { FileList } from "./components";
import { initCalcWebsocket, socketCalc, websocketApi } from "api/websocketApi";
import { useAppDispatch, useTypedSelector } from "store/store";
import { wsContext } from "contexts/ws";
import { api } from "api/api";
import { CircularProgressPercent } from "components/CircularProgressPercent/CircularProgressPercent";
import { useCreateTanglProjectMutation, useGetModelListQuery, useGetStatusIntegrateQuery } from "../../../../api/tangl";
import { ICreateTanglRequest, ITangleModel, ITangleProjectResponse } from "../../../../api/tangl/tangl.types";
import TangleDrawer from "./components/TangleDrawer";
import { useNavigate } from "react-router-dom";

const methodsItems: MethodsItemsType[] = [
  {
    value: 'handbk',
    label: 'Справочник расценок',
  },
  {
    value: 'grandSmeta',
    label: 'Импорт из XML',
  },
  {
    value: 'tangl',
    label: 'Интеграция с Tangl',
  },
  {
    value: 'basisManual',
    label: 'Калькуляция',
  },
];

// const manualItems: MethodsItemsType[] = [
//   {
//     value: 'resourceManual',
//     label: 'В ручном режиме',
//   },
// ];
//
// const tangle: MethodsItemsType[] = [
// ];
export type TParent = Omit<Required<ITangleProjectResponse>, 'models' | 'folders' | 'name'>;
export type TCurrentModule = {
  module: ITangleModel;
  parent: TParent;
};
interface ITangleContext {
  setCurrentModel: (model: TCurrentModule) => void;
  models: ITangleProjectResponse[];
  open: boolean;
  currentModel: null | TCurrentModule;
  toggle: (flag: boolean) => void;
}
export const TanglContext = createContext<ITangleContext>({
  models: [],
  open: false,
  setCurrentModel: () => {},
  toggle: () => {},
  currentModel: null,
});

export const CalculationDrawerForm: React.FC<CalculationDrawerFormProps> = ({ onClose, calculation, onFormChange }) => {

  const counter = useRef(10);

  const projectID = useProjectId();
  const navigation = useNavigate()
  const { t } = useTranslation('user');
  const { enqueueSnackbar } = useSnackbar();
  const { description, title } = calculation || {};
  const [createCalculation, createCalculationResponse] = useCreateCalculationMutation();
  const [createTanglProject, createTanglProjectResponse] = useCreateTanglProjectMutation();
  const [methodsChecked, setMethodsChecked] = React.useState<CalcDataType | null>(null);
  const [error, setError] = useState<boolean>(false);
  const { data, isSuccess } = useGetLSRFilesQuery({ projectID });
  const { token } = useTypedSelector((state) => state.auth);
  const cWS = useContext(wsContext);

  const [modelList, setModelList] = useState<ITangleProjectResponse[]>([]);
  const [currentModel, setCurrentModel] = useState<TCurrentModule | null>(null);
  const [checkTangle, setCheckTangle] = useState<boolean | null>(null);
  const [isErrorList, setIsErrorList] = useState(false);

  const statusInteration = useGetStatusIntegrateQuery();
  const modelTangleList = useGetModelListQuery(undefined, { skip: !statusInteration.data?.status });

  const dispatch = useAppDispatch();

  const [progressPercent, setProgressPercent] = useState<number>(0);
  const [progressTime, setProgressTime] = React.useState<string>('');
  const [swipeDrawer, setSwipeDrawer] = useState(false);

  const toggleSwipeDrawer = (bool: boolean) => setSwipeDrawer(bool);
  const { singleTask } = websocketApi;

  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleRefresh = () => {
    dispatch(api.util.invalidateTags(['Calculations']));
    onClose(false, true);
  };

  useEffect(() => {
    if (socketCalc?.readyState !== 1) return;
    const authWS = {
      bodyJson: JSON.stringify({ token }),
      type: 'auth',
    };

    if (socketCalc) {
      socketCalc.send(JSON.stringify(authWS));

      socketCalc.onmessage = function (event) {
        let status = JSON.parse(event.data).type;
        let bodyJson = JSON.parse(event.data).bodyJson;
        if (bodyJson !== null && bodyJson.payload == 'pong') return;

        if (status === 'status_ok') {
          console.log('[socketChanel-open] Соединение открыто');
        }
        if (status === 'error') {
          let msg = JSON.parse(bodyJson).msg;
          console.log(`[socketChanel-error] Не авторизован, msg:${msg}`);
        }
      };
      socketCalc.onclose = function (event) {
        if (event.wasClean) {
          console.log(`[socketChanel-close] Соединение закрыто, причина=${event.reason}.`);
        } else {
          console.log(`[socketChanel-close] Соединение прервано, причина=${event.reason}.`);
        }
      };
    }

    return () => {
      // Очистка состояния при размонтировании компонента
      if (socketCalc?.readyState === WebSocket.OPEN) {
        const code = 3002;
        socketCalc?.close(code, `код закрытия ${code}`); // Закрываем соединение, если оно еще открыто
      }
    };
  }, [socketCalc?.readyState]);

  if (socketCalc)
    socketCalc.onmessage = function (event) {
      let parsed = JSON.parse(event.data);

      let status = parsed.type;

      let bodyJson = JSON.parse(parsed.bodyJson);

      if (bodyJson !== null && bodyJson.payload == 'pong') return;

      if (status === 'upload_progress' && projectID === cWS.socketCalc.projectIDInit) {
        cWS.setCalcUploaded('loading');
        let progress = bodyJson.progress;
        let remainTime = bodyJson.remainTime;
        setProgressTime!(String(remainTime));
        setProgressPercent!(Number(progress));
        /* let totalRecords = Number(JSON.parse(bodyJson).total);
      setUploadRecords!(totalRecords); */
      }

      if (status === 'status_ok' && cWS.socketCalc.isUploaded === 'loading') {
        cWS.setCalcUploaded('success');
        setProgressPercent(0);
        setProgressTime('');
        handleRefresh();
        if (bodyJson?.id) {
          navigation(`/projects/${projectID}/calculation/${bodyJson?.id}/edit?tab=0`)
        }
        //successHandler('Файл успешно загружен');
      }
      if (status === 'canceled') {
        cWS.setCalcUploaded('canceled');
      }

      if (status === 'error') {
        enqueueSnackbar('Непредвиденная ошибка в файле', { variant: 'error' });
        //setError(true);
        setProgressPercent(0);
        setProgressTime('');
        cWS.setCalcUploaded('error');
      }
    };

  const onSubmit = useCallback(
    (values: CalculationFormData) => {
      if (methodsChecked === 'grandSmeta') {
        if (socketCalc) {

          if (socketCalc?.readyState !== 1) {
            if (counter.current !== 0) {
              counter.current -= 1;
              initCalcWebsocket()
              onSubmit(values);
              return
            }else{
              counter.current = 10;
              enqueueSnackbar('Установка соединения. Повторите попытку через несколько секунд.', {
                variant: 'error',
                autoHideDuration: 15000,
              });
              return
            }
          }

          if (values.lsrIds.length !== 0) {
            const calcWS = {
              type: singleTask.types.createCalc,
              bodyJson: JSON.stringify({
                param: {
                  projectID,
                },
                calcBody: {
                  title: values.title,
                  description: values.description,
                  type: methodsChecked,
                  ...(values.lsrIds.length !== 0 && { lsrIds: values.lsrIds }),
                },
              }),
            };

            socketCalc.send(JSON.stringify(calcWS));
            cWS.setCalcUploaded('loading');
            cWS.setCalcProjectIDInit(projectID);
            socketCalc.onerror = function (error) {
              console.log(`[socketChanel-error]`, error);
            };
          }
        }
      } else if (methodsChecked === 'tangl' && currentModel) {
        const {
          parent: { companyId: tanglCompanyID, id: tanglProjectID },
          module: tanglModelInfo,
        } = currentModel;

        const createProject: ICreateTanglRequest = {
          params: {
            projectID,
          },
          body: {
            calc: {
              title: values.title,
              description: values.description ?? null,
              type: methodsChecked,
            },
            tanglData: {
              tanglCompanyID,
              tanglProjectID,
              tanglModelInfo,
            },
          },
        };

        createTanglProject(createProject);
      } else {
        createCalculation({
          params: {
            projectID,
          },
          body: {
            title: values.title,
            description: values.description,
            type: methodsChecked!,
            ...(values.lsrIds.length !== 0 && { lsrIds: values.lsrIds }),
          },
        });
      }
    },
    [methodsChecked, currentModel],
  );

  const initialValues: CalculationFormData = useMemo(
    () => ({
      title: title || '',
      description: description || undefined,
      formationMethod: 'basic',
      lsrIds: [],
    }),
    [calculation],
  );

  const { formik } = useForm({
    validationSchema: validationCalculation,
    enableReinitialize: true,
    initialValues,
    onSubmit,
  });

  const format = useMemo(() => {
    let format: Accept = {};
    switch (methodsChecked) {
      case 'excel':
        format = {
          'application/vnd.ms-excel': ['.xls'],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        };
        break;
      case 'grandSmeta':
        format = {
          'application/xml': ['.xml'],
        };
        break;
      default:
        break;
    }
    return format;
  }, [methodsChecked]);

  const [formData, setFormData] = useState<FormData | null>(null);

  const [uploadExcel, uploadExcelResponse] = useUploadExcelMutation();

  useMutationHandlers(uploadExcelResponse, () => {
    enqueueSnackbar(t('success.calculationUpload'), {
      variant: 'success',
    });
  });

  useMutationHandlers(createCalculationResponse, (data) => {
    if (data.type === 'excel' && formData) {
      uploadExcel({
        calcID: data.id,
        projectID,
        body: formData,
      });
    }
    onClose(false, true);
  });
  useMutationHandlers(createTanglProjectResponse, (response) => {
    if (response?.id) {
      navigation(`/projects/${projectID}/calculation/${response.id}/edit?tab=0`)
    }
    onClose(false, true);
  });

  const { values, dirty } = formik;

  useEffect(() => {
    onFormChange(methodsChecked === 'tangl' ? dirty || !!currentModel : dirty);
  }, [dirty, currentModel, methodsChecked]);

  // useMutationHandlers(statusInteration, (data) => {
  //   console.log(data);
  //   setCheckTangle(!data.status);
  // });

  useMutationHandlers(
    modelTangleList,
    (data) => {
      setModelList(data);
      setIsErrorList(false);
      setCheckTangle(false);
    },
    (data) => {
      if ('originalStatus' in data && data.originalStatus === 401) {
        setCheckTangle(true);
      } else {
        setCheckTangle(null);
      }
      setIsErrorList(true);
    },
  );
  useMutationHandlers(createCalculationResponse, (response) => {
    if (response?.id) {
      navigation(`/projects/${projectID}/calculation/${response.id}/edit?tab=0`)
    }
    onClose(false, true);
  });

  const handleChangeMethodsChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMethodsChecked(event.target.value as CalcDataType);
  };

  const colorTheme = theme.palette;

  const isSubmitButtonDisabled =!methodsChecked ||
    (methodsChecked === 'tangl' &&
      (checkTangle || !currentModel || isErrorList || createTanglProjectResponse.isLoading)) ||
    (methodsChecked === 'excel' ? !formData : methodsChecked === 'grandSmeta' ? values.lsrIds.length === 0 : false) ||
    createCalculationResponse.isLoading ||
    cWS.socketCalc?.isUploaded === 'loading';
  //
  // console.group('tangle data>>>');
  // console.log('modelList>>>', modelList);
  // console.log('checkTangle>>>', checkTangle);
  // console.groupEnd();

  useLayoutEffect(() => {
    initCalcWebsocket();
    // keepAlive();
  }, []);

  return (
    <FormikProvider value={formik}>
      <TanglContext.Provider
        value={{
          currentModel,
          open: swipeDrawer,
          toggle: toggleSwipeDrawer,
          models: modelList,
          setCurrentModel: (model) => setCurrentModel(model),
        }}>
        <Stack component={Form} flex={1} overflow="auto" onSubmit={formik.handleSubmit}>
          <Stack ref={containerRef} flex={1} p={2.5} pb={0} overflow="inherit">
            {createCalculationResponse.isLoading || cWS.socketCalc?.isUploaded === 'loading' ? (
              <>
                {(createCalculationResponse.isLoading || createTanglProjectResponse.isLoading) && <Progress />}
                {cWS.socketCalc?.isUploaded === 'loading' && (
                  <CircularProgressPercent thickness={3.6} value={progressPercent!} time={progressTime!} />
                )}
              </>
            ) : (
              <Box flex="1 1 auto">
                <Stack mb={2}>
                  <Stack spacing={2}>
                    <Stack spacing={2}>
                      <StyledFieldFormTitle
                        version="calculation"
                        name="title"
                        placeholder="Укажите краткое название расчета"
                        multiline
                        minRows={1}
                      />
                      <StyledFieldFormDesc
                        version="calculation"
                        name="description"
                        placeholder="Укажите полное название расчета или его описание"
                        multiline
                        minRows={2}
                      />
                    </Stack>
                    {!calculation && (
                      <Stack spacing={'20px'}>
                        <FormControl>
                          <StyledFormLabel
                            style={{
                              marginBottom: 12,
                              color: '#2b3648',
                              fontSize: 16,
                              fontFamily: 'Roboto',
                              fontWeight: 600,
                              letterSpacing: 0.15,
                            }}>
                            Выберите методику ценообразования
                          </StyledFormLabel>
                          {/*<Typography variant="body2" sx={{ fontWeight: 500, mb: '12px' }}>*/}
                          {/*  Базисно-индексный метод*/}
                          {/*</Typography>*/}
                          <RadioGroup name="methodsChecked" value="grandSmeta">
                            <Stack spacing={1.5}>
                              {methodsItems.map((item) => (
                                <StyledFormControlLabel
                                  key={item.value}
                                  value={item.value}
                                  onClick={() => item.value === 'tangl'&& toggleSwipeDrawer(true)}
                                  checked={methodsChecked === item.value}
                                  control={
                                    <Radio
                                      checkedIcon={<CheckCircleIcon />}
                                      onChange={handleChangeMethodsChecked}
                                      icon={
                                        <CheckCircleIcon color="disabled" style={{ color: colorTheme.bg.shades }} />
                                      }
                                    />
                                  }
                                  label={item.label}
                                />
                              ))}
                              {/*<Typography variant="body2" sx={{ fontWeight: 500, mb: '12px' }}>*/}
                              {/*  Ресурсный метод*/}
                              {/*</Typography>*/}
                              {/*{manualItems.map((item) => (*/}
                              {/*  <StyledFormControlLabel*/}
                              {/*    key={item.value}*/}
                              {/*    value={item.value}*/}
                              {/*    checked={methodsChecked === item.value}*/}
                              {/*    control={*/}
                              {/*      <Radio*/}
                              {/*        checkedIcon={<CheckCircleIcon />}*/}
                              {/*        onChange={handleChangeMethodsChecked}*/}
                              {/*        icon={*/}
                              {/*          <CheckCircleIcon color="disabled" style={{ color: colorTheme.bg.shades }} />*/}
                              {/*        }*/}
                              {/*      />*/}
                              {/*    }*/}
                              {/*    label={item.label}*/}
                              {/*  />*/}
                              {/*))}*/}
                              {/*{checkTangle !== null && (*/}
                              {/*  <>*/}
                              {/*    <Typography variant="body2" sx={{ fontWeight: 500, mb: '12px' }}>*/}
                              {/*      Интеграции*/}
                              {/*    </Typography>*/}
                              {/*    {tangle.map((item) => (*/}
                              {/*      <StyledFormControlLabel*/}
                              {/*        key={item.value}*/}
                              {/*        value={item.value}*/}
                              {/*        disabled={!!checkTangle}*/}
                              {/*        onClick={() => toggleSwipeDrawer(true)}*/}
                              {/*        checked={methodsChecked === item.value}*/}
                              {/*        control={*/}
                              {/*          <Radio*/}
                              {/*            checkedIcon={<CheckCircleIcon />}*/}
                              {/*            onChange={handleChangeMethodsChecked}*/}
                              {/*            icon={*/}
                              {/*              checkTangle ? (*/}
                              {/*                <WarningAmberIcon color={'warning'} />*/}
                              {/*              ) : (*/}
                              {/*                <CheckCircleIcon*/}
                              {/*                  color="disabled"*/}
                              {/*                  style={{ color: colorTheme.bg.shades }}*/}
                              {/*                />*/}
                              {/*              )*/}
                              {/*            }*/}
                              {/*          />*/}
                              {/*        }*/}
                              {/*        label={item.label}*/}
                              {/*      />*/}
                              {/*    ))}*/}
                                  {(checkTangle && checkTangle !== null) && (
                                    <Typography
                                      fontSize={12}
                                      variant={'caption'}
                                      sx={{ marginTop: '0 !important' }}
                                      color={'#ED6C02'}>
                                      Связь с Tangl потеряна. Обратитесь к администратору
                                    </Typography>
                                  )}
                              {/*  </>*/}
                              {/*)}*/}

                              {methodsChecked === 'tangl' && (
                                <>
                                  {!currentModel ? (
                                    <Typography onClick={()=>toggleSwipeDrawer(true)} color={theme1 => theme1.palette.primary.main} variant="body2" sx={{ fontWeight: 500, mb: '12px',cursor:'pointer' }}>
                                      {/*Выбрано: NAV-IPI-ET1_E07-ZZZ-M3D-EST_v1NAV-IPI-ET1_E07-ZZZ-M3D-EST_v1*/}
                                      Выберите проект для обработки
                                    </Typography>
                                  ) : (
                                    <>
                                      <Typography variant="body2" sx={{ fontWeight: 500, mb: '12px' }}>
                                        Выбрано:
                                      </Typography>
                                      <Typography variant="subtitle2" sx={{ fontWeight: 500, mb: '12px' }}>
                                        {currentModel.module.name}
                                      </Typography>
                                    </>
                                  )}
                                </>
                              )}
                            </Stack>
                          </RadioGroup>
                        </FormControl>
                      </Stack>
                    )}
                  </Stack>
                  {!calculation && (
                    <Stack>
                      {methodsChecked === 'excel' && (
                        <UploadForm
                          errorsMSG={null}
                          error={error}
                          setError={setError}
                          setFormData={setFormData}
                          format={format}
                          mode={'xls'}
                          isLoading={uploadExcelResponse.isLoading}
                          isWS={false}
                        />
                      )}
                    </Stack>
                  )}
                </Stack>
                {methodsChecked === 'grandSmeta' && <>{isSuccess ? <FileList data={data.files} /> : <Progress />}</>}
                {methodsChecked === 'tangl' && <TangleDrawer />}
              </Box>
            )}
          </Stack>
          <Stack p={2.5} direction="row" spacing={2} pt={2.5} mt="auto">
            <Button type="submit" color="success" fullWidth disabled={isSubmitButtonDisabled}>
              {!calculation ? 'Добавить' : 'Сохранить'}
            </Button>
            <Button
              fullWidth
              onClick={() =>
                onClose(
                  cWS.socketCalc?.isUploaded === 'loading'
                    ? false
                    : dirty || (methodsChecked === 'tangl' && !!currentModel),
                )
              }>
              Закрыть
            </Button>
          </Stack>
        </Stack>
      </TanglContext.Provider>
    </FormikProvider>
  );
};
