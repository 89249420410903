import AddIcon from "@mui/icons-material/Add";
import { Stack } from "@mui/material";
import { calculationsApi, useGetCalculationListQuery } from "api/calculations";
import { EmptyPage } from "components/EmptyPage";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { CalcData, CalcDataType, CalculationStatusesTabLabels, CalculationTabLabels } from "types";
import { EmptyPageData } from "../../components/EmptyPage/EmptyPage.types";
import Progress from "../../components/Progress";
import { TabData } from "../../components/Tabs/Tabs.types";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import useConfirmDialog, { UseExitConfirmProps } from "../../hooks/useConfirmDialog";
import useSearch from "../../hooks/useSearch";
import { useAppContext } from "../../layouts/Layout/context";
import { filterByFieldNames } from "../../utils/filterByFieldNames";
import AdminLegend from "../Administration/components/AdminLegend";
import { getEmptyPageData } from "../Home";

import CalculationDrawer from "./components/CalculationDrawer";
import { StyledGridContainer } from "./style";
import { CalculationCard } from "./components/CalculationCard";
import { useAppDispatch } from "../../store/store";
import { wsContext } from "contexts/ws";
import WSCustomSnackbar from "pages/Lsr/components/WSCustomSnackbar/WSCustomSnackbar";
import { api } from "api/api";
import { socketCalc } from "api/websocketApi";

const CalculationsCard: React.FC = () => {
  const { searchValue } = useSearch();
  const { projectID } = useParams();
  const { profile } = useAppContext();
  const cWS = useContext(wsContext);

  const dispatch = useAppDispatch();

  const { firstName } = profile;


  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [chosenCalculationId, setChosenCalculationId] = useState<number | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const openParam: string = searchParams.get('open') ?? '';

  const [currentTab, setCurrentTab] = useState<CalculationTabLabels>('Все расчеты');
  const [filters, setFilters] = useState<CalcDataType[] | null>(null);


  const handleRefresh = () => {
    dispatch(api.util.invalidateTags(['Calculations']));
    cWS.setCalcUploaded('start');
  };

  const tabNames: CalculationTabLabels[] = Object.values(CalculationStatusesTabLabels);

  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      setIsDrawerOpen(false);
    }
  }, []);

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,
  };

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog);

  const onTabChange = useCallback((e: React.SyntheticEvent, tabValue: CalculationTabLabels) => {
    setCurrentTab(tabValue);
  }, []);

  const onAddCalculation = useCallback(() => {
    setIsDrawerOpen(true);
    setChosenCalculationId(null);
  }, []);

  const onCalculationCardClick = useCallback((calculationId: number) => {
    setIsDrawerOpen(true);
    setChosenCalculationId(calculationId);
  }, []);

  const onDrawerClose = useCallback((dirty: boolean, immediately?: boolean) => {
    immediately || !dirty ? setIsDrawerOpen(false) : openConfirm();
  }, []);

  useEffect(() => {
    if (openParam) {
      setIsDrawerOpen(true);
    }
  }, [openParam]);


  useBreadcrumbs([{ title: `Расчеты / ${currentTab}` }], [currentTab]);

  useEffect(() => {
    if (currentTab === 'Все расчеты') {
      setFilters(null);
    } else if (currentTab === 'Базисно-индексный') {
      setFilters(['basisManual', 'excel', 'grandSmeta']);
    } else if (currentTab === 'Интеграция') {
      setFilters(['tangl']);
    } else {
      setFilters(['resourceManual', 'handbk']);
    }
  }, [currentTab]);

  /*********************************** Calculation Card List  ****************************************/

  const { data, isLoading, isFetching, isSuccess } = useGetCalculationListQuery({
    projectID: Number(projectID),
    filters,
    limit: 999,
    offset: 0,
  });

  const calculationsCardList = useMemo(()=>data ? data.data : [],[data]);

  const filteredCalculationSearch = useMemo(() => {
    return searchValue && calculationsCardList?.length
      ? filterByFieldNames<CalcData>(calculationsCardList, ['title'], searchValue)
      : calculationsCardList;
  }, [calculationsCardList, searchValue]);

  const tabsData: TabData<CalculationTabLabels>[] = useMemo(() => {
    return tabNames.map((tabName) => {
      return {
        value: tabName,
        label: tabName,
      };
    });
  }, [filteredCalculationSearch]);

  const emptyPageData: EmptyPageData = getEmptyPageData(
    <>
      Здравствуйте, {firstName}, у Вас еще нет проектов для отображения.
      <br />
      Давайте создадим ваш первый проект.
    </>,
    [
      {
        text: 'Добавить расчет',
        icon: AddIcon,
        onClick: () => onAddCalculation(),
      },
    ],
  );

  /******************************************************************************************************/

  /**
   * Очищается кеш у тегов ComplexList
   */
  useEffect(() => {
    dispatch(calculationsApi.util.invalidateTags([{ type: 'ComplexList' }]));
  }, []);

  return (
    <Stack flexGrow={1}>
      <AdminLegend
        currentTab={currentTab}
        tabsData={tabsData}
        onTabChange={onTabChange}
        onAddClick={onAddCalculation}
      />
      {isFetching && <Progress />}
      {!isFetching && isSuccess && (
        <>
          {/*TODO - потом убрать*/}
          {data.data.length > 0 ? (
            <StyledGridContainer>
              {data.data.map(({ id, ...rest }) => {
                return (
                  <Link key={id} to={`/projects/${projectID}/calculation/${id}/edit`}>
                    <CalculationCard {...rest} />
                  </Link>
                );
              })}
            </StyledGridContainer>
          ) : (
            <EmptyPage data={emptyPageData} />
          )}
        </>
      )}
      <CalculationDrawer open={isDrawerOpen} onClose={onDrawerClose} calculationId={chosenCalculationId} />
      <ConfirmDialog />
      {cWS.socketCalc.isUploaded !== 'start' && !cWS.socketCalc.isDrawerOpen && (
        <WSCustomSnackbar
          isDrawerOpen={cWS.socketCalc.isDrawerOpen ?? false}
          isUploaded={cWS.socketCalc.isUploaded}
          setUploaded={cWS.setCalcUploaded}
          projectIDEquals={cWS.socketCalc.projectIDInit == projectID}
          handleRefresh={handleRefresh}
          socket={socketCalc}
        />
      )}
    </Stack>
  );
};

export default CalculationsCard;
