import { Stack, Typography } from "@mui/material";
import React, { FC, useCallback } from "react";
import { StyledChapterItem } from "./LimitedCostsFrom.styles";
import { useFormContext } from "react-hook-form";
import {
  GetCoefficientsV3ActResponse,
  Indicator,
  LimitedCostsV3
} from "../../../../../../../../api/params/params.types";
import { chapterNames } from "./LimitedCostsForm.constants";
import { CircleButton } from "../../../../../../../../components/CircleButton";
import { Add } from "@mui/icons-material";
import { Divider } from "../../../../../../../../components/Divider";

export const LimitedCostsForm: FC = (): JSX.Element => {
  const { getValues, setValue } = useFormContext<GetCoefficientsV3ActResponse>();

  const appendRow = useCallback((e: React.MouseEvent, name: string | number) => {
    e.stopPropagation();
    e.preventDefault();

    let newData: any = getValues(`limitedCosts.${name}` as 'limitedCosts.8');
    if (!newData) return;

    const template: LimitedCostsV3 = {
      estimates: [],
      limitedCost: {
        title: '',
        type: 'coeff' as Indicator,
        value: '0',
        chapter: Number(name),
      },
    };

    newData = newData?.length ? [template, ...newData] : [template];
    setValue(`limitedCosts.${name}` as 'limitedCosts.8', newData, { shouldValidate: true });
    // }
  }, []);

  return (
    <>
      {chapterNames.map(({ title }, childIndex) => {
        const name = childIndex + 8;
        return (
          <Stack display={'flex'} flexDirection={'column'} gap={2} mb={3}>
            <Stack direction="row" alignItems="center" spacing={4}>
              <CircleButton size="small" disableElevation onClick={(e) => appendRow(e, name)}>
                <Add fontSize="small" />
              </CircleButton>
              <Typography variant="body1" color="text.secondary">
                {`Глава ${name} ${title}`}
              </Typography>
            </Stack>
            <Divider thickness="1px" />
            <StyledChapterItem childIndex={childIndex} title={`Глава ${name} ${title}`} name={name.toString()} />
          </Stack>
        );
      })}
    </>
  );
};
