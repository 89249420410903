import { Stack, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { MAX_WIDTH_USER_MANAGEMENT_BLOCK, MIN_WIDTH_USER_MANAGEMENT_BLOCK } from 'utils/constants';
import { UserRoleSelection } from '../../UserRoleSelection';
import { AccessStatusBlock } from './UserAccesses.styles';
import { theme } from 'styles/theme';
import { UserAccessesSelection } from '../../UserAccessesSelection';
import Button from 'components/Button';
import { UserAccessesProps } from './UserAccesses.types';
import { useFormikContext } from 'formik';
import { UserFormData } from '../UserForm.types';

export const UserAccesses: React.FC<UserAccessesProps> = ({ isEditUser }) => {
  const { values: formValues, isSubmitting, isValid } = useFormikContext<UserFormData>();
  const role = formValues.role;

  const renderAccessesBlock = useCallback(() => {
    switch (role) {
      case 'admin':
        return (
          <UserAccessesSelection>
            <AccessStatusBlock>
              <Typography variant="body1">Доступ ко всем проектам</Typography>
            </AccessStatusBlock>
          </UserAccessesSelection>
        );
      case 'client':
        return <UserAccessesSelection />;
      case 'none':
        return (
          <UserAccessesSelection>
            <AccessStatusBlock color={theme.palette.error.main}>
              <Typography variant="body1">Нет доступа к проектам</Typography>
            </AccessStatusBlock>
          </UserAccessesSelection>
        );
    }
  }, [role]);

  return (
    <Stack
      maxWidth={MAX_WIDTH_USER_MANAGEMENT_BLOCK}
      minWidth={MIN_WIDTH_USER_MANAGEMENT_BLOCK}
      flex={1}
      spacing={4.375}>
      <UserRoleSelection />

      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle1" color={theme.palette.text.dark} pt={1}>
          Проекты:
        </Typography>
        {renderAccessesBlock()}
      </Stack>

      <Button type="submit" disabled={isSubmitting || !isValid} color="success" width="100%" style={{ alignSelf: 'end' }}>
        {isEditUser ? 'Сохранить' : 'Создать'}
      </Button>
    </Stack>
  );
};
