import { useContext } from 'react';
import { TreeNodeContext } from './TreeNodeContext';

export const useTreeNodeContext = () => {
  const context = useContext(TreeNodeContext);

  if (context === undefined) {
    throw new Error('useTreeNodeContext must be used within a TreeNodeContextProvider');
  }

  return context;
};
