import Item from './components/Item/Item';
import { StyledTabComponent } from './TabComponent.styles';
import { TabComponentProps } from './TabComponent.types';

/**
 * @param fields Массив объектов вида ToTotals или Indexes.
 * @param itemType
 */

const TabComponent: React.FC<TabComponentProps> = ({ fields,itemType }) => {
  return (
    <StyledTabComponent>
      {fields.map((field, index) => (
        <Item key={index} field={field} parentIndex={index} itemType={itemType} />
      ))}
    </StyledTabComponent>
  );
};

export default TabComponent;
