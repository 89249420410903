import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as EmptyImage } from 'assets/empty.svg';
import { FC } from 'react';
import Button from '../Button';
import { EmptyPageWrapper } from './EmptyPage.styles';
import { EmptyPageProps } from './EmptyPage.types';

export const EmptyPage: FC<EmptyPageProps> = ({ data }) => {
  const { text, buttons } = data;

  return (
    <EmptyPageWrapper spacing={7.5} justifyContent="center" alignItems="center">
      <Stack spacing={2.5} alignItems="center">
        <Typography variant="h2">{text}</Typography>
        <Stack direction="row" spacing={1.5}>
          {buttons.map((button) => (
            <Button startIcon={<button.icon />} onClick={() => button.onClick()} key={button.text}>
              {button.text}
            </Button>
          ))}
        </Stack>
      </Stack>
      <Box>
        <EmptyImage width={630} height={400} />
      </Box>
    </EmptyPageWrapper>
  );
};
