import { GetCoefficientsResponse } from 'api/params/params.types';
import { useFormContext, useFieldArray } from 'react-hook-form';
import TabComponent from '../../../../../TabComponent/TabComponent';
import { useMemo } from "react";

const Body: React.FC = () => {
  const { control} = useFormContext<GetCoefficientsResponse>();

  const { fields } = useFieldArray({
    name:'toTotals',
    control
  })

  return (
    <div>
      <TabComponent fields={fields} itemType={'toTotals'} />
    </div>
  );
};

export default Body;
