import { Box, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { CalculationFormData } from '../../CalculationDrawerForm.types';
import { StyledButton, StyledFileItem } from './FileList.styles';
import { FileListProps } from './FileList.types';

export const FileList: FC<FileListProps> = ({ data }): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<CalculationFormData>();

  const setAll = () => {
    setFieldValue(
      'lsrIds',
      data.map((file) => file.id.toString()),
    );
  };

  const clearAllClickHandler = () => {
    setFieldValue('lsrIds', []);
  };

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1} px={0.5}>
        <Typography variant="body2">Выберите файлы</Typography>
        <Typography variant="body2">Выбрано ({values.lsrIds.length})</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
        <StyledButton variant="text" size="small" onClick={setAll}>
          Выбрать все
        </StyledButton>
        <StyledButton variant="text" size="small" onClick={clearAllClickHandler}>
          Очистить
        </StyledButton>
      </Stack>
      <Box>
        {data.map((file) => (
          <StyledFileItem key={file.id} file={file} />
        ))}
      </Box>
    </Box>
  );
};
