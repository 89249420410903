import { Box, Button, Stack, Typography } from '@mui/material';
import { useCalcId } from 'hooks/useCalcId';
import { useRenameLevel, useTransferElement } from 'pages/FullVor/Table/hooks';
import { FC, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useTreeNodeContext } from '../../../../context';
import { LevelSelect } from '../CreateLevelForm/components';
import { GroupCount } from '../GroupCount';
import { getLevelLeftOffset } from './GroupRow.service';
import { StyledGroupRowInfo, StyledGroupRowWrapper, StyledInput, StyledLevelFolder } from './GroupRow.styles';
import { GroupRowProps } from './GroupRow.types';
import { useGroupSum } from './hooks/useGroupSum';

export const GroupRow: FC<GroupRowProps> = ({
  index,
  parentGroupIds,
  parentChildrenLength,
  rowChildren,
  isHover,
  isEdit,
  onClose,
}): JSX.Element => {
  const calcID = useCalcId();
  const { rowID, level, rowTitle, childrenIds, groupIds, isExpanded, levelIds } = useTreeNodeContext();
  const { constructionEffortSum, mechanicsEffort, totalWorkCost } = useGroupSum(rowChildren);
  const { renameLevel } = useRenameLevel();
  const { transferElement } = useTransferElement();
  const [newTitle, setNewTitle] = useState<string>(rowTitle ?? '');
  const [selectedLevel, setSelectedLevel] = useState(level);

  const isRenameButtonDisabled = (!newTitle || newTitle === rowTitle) && selectedLevel === level;
  const isSelectLevelShowed = isEdit && (index !== 0 || level !== 1);
  const toLevelId =
    level < selectedLevel
      ? index - parentChildrenLength > 0
        ? parentGroupIds[index - parentChildrenLength - 1]
        : parentGroupIds[index - parentChildrenLength + 1]
      : levelIds[selectedLevel - 1] ?? undefined;

  const renameLevelClickHandler = () => {
    if (!rowID || !rowTitle) return;

    if (newTitle !== rowTitle) {
      renameLevel({
        calcID,
        groupID: rowID,
        title: newTitle,
      });
    }

    if (selectedLevel !== level) {
      transferElement({
        calcID,
        childrenIds: [],
        groupIds: [rowID],
        to: toLevelId,
      });
    }

    onClose();
  };

  const selectLevelHandler = (level: number) => {
    setSelectedLevel(level);
  };

  return (
    <StyledGroupRowWrapper>
      <StyledGroupRowInfo>
        {isSelectLevelShowed ? (
          <LevelSelect
            ml={`${getLevelLeftOffset(level)}px`}
            selectedLevel={selectedLevel}
            hideLevelsMore={parentGroupIds.length === 1}
            onSetLevel={selectLevelHandler}
          />
        ) : (
          <StyledLevelFolder level={level} size="large" isExpanded={isExpanded} isHover={isHover} />
        )}
        {!isEdit ? (
          <>
            <Typography variant="subtitle2">{rowTitle}</Typography>
            <GroupCount description="Сгруппировано" childrenCount={childrenIds.length} groupCount={groupIds.length} />
          </>
        ) : (
          <>
            <StyledInput
              onClick={(e) => e.stopPropagation()}
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
              fullWidth
            />
            <Stack ml={2} direction="row" spacing={2}>
              <Button
                color="success"
                size="small"
                disableElevation
                disabled={isRenameButtonDisabled}
                onClick={(e) => {
                  e.stopPropagation();
                  renameLevelClickHandler();
                }}>
                Сохранить
              </Button>
              <Button
                size="small"
                disableElevation
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}>
                Отменить
              </Button>
            </Stack>
          </>
        )}
      </StyledGroupRowInfo>
      <Box textAlign="right">
        <NumericFormat
          displayType="text"
          decimalSeparator=","
          decimalScale={2}
          value={constructionEffortSum}
          fixedDecimalScale
        />
      </Box>
      <Box></Box>
      <Box textAlign="right">
        <NumericFormat
          displayType="text"
          decimalSeparator=","
          decimalScale={2}
          value={mechanicsEffort}
          fixedDecimalScale
        />
      </Box>
      <Box></Box>
      <Box textAlign="right" pr={2}>
        <NumericFormat
          displayType="text"
          decimalSeparator=","
          decimalScale={2}
          value={totalWorkCost}
          fixedDecimalScale
        />
      </Box>
    </StyledGroupRowWrapper>
  );
};
