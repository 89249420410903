import { FocusEventHandler, useCallback, useMemo, useRef, useState } from 'react';

import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { Form, FormikProvider } from 'formik';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useCreateBasicLevelMutation } from '../../../../../../api/calculations';
import { useMutationHandlers } from '../../../../../../hooks/useMutationHandlers';

import { useForm } from '../../../../../../hooks/useForm';
import { RowAddFormData as AddLevelFormData } from './RowAddForm.types';
import { LevelIcon, LevelNameType } from '../../../../../Calculations/components/LevelIcon';
import { StyledLevelTd, StyledFieldForm } from '../styles';
import { validationTitleEditRow } from '../FormAddRow/validation';
import { theme } from '../../../../../../styles/theme';

export type FormAddLevelPropsType = {
  calculationId: number;
  levelNumber: number;
  parentId: number | null;
  firstForm?: string;
  handleShowFormNestedLevel: (isShowFormRowAdd: boolean) => void;
  handleShowFormAdjacentLevel: (isShowFormRowAdd: boolean) => void;
};

const FormAddLevel: React.FC<FormAddLevelPropsType> = ({
  calculationId,
  parentId,
  firstForm,
  levelNumber,
  handleShowFormNestedLevel,
  handleShowFormAdjacentLevel,
}) => {
  const [isAddSave, setIsAddSave] = useState('');
  const levelName = `level${levelNumber + 1}` as LevelNameType;
  const colorTheme = theme.palette;

  /************************************ Add Level  ************************************/

  const initialValues: AddLevelFormData = useMemo(
    () => ({
      parent: null,
      price: 0,
      quantity: 0,
      title: '',
      type: 'level',
      unit: '',
      unitPrice: 0,
    }),
    [] //calculation
  );

  const [addLevel, addLevelResponse] = useCreateBasicLevelMutation();

  const onSubmit = useCallback(
    (values: AddLevelFormData) => {
      addLevel({
        id: calculationId,
        parent: parentId, // id parent row
        price: 0,
        quantity: 0,
        title: values.title,
        type: 'level',
        unit: 0, // ед.изм
        unitPrice: 0,
      });
    },
    [] //calculation
  );

  useMutationHandlers(addLevelResponse, () => {
    if (isAddSave === 'SaveAdd') {
      handleShowFormAdjacentLevel(true);
      setIsAddSave('');
      handleClearForm();
    } else {
      handleShowFormNestedLevel(false);
      handleShowFormAdjacentLevel(false);
    }
  });

  const { formik, isSubmitDisabled } = useForm({
    validationSchema: validationTitleEditRow,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  const { handleSubmit } = formik;

  /************************************ Row Menu ************************************/
  const [isMenuRowOpened, setMenuRowOpened] = useState(false);
  const anchorEl = useRef<null | HTMLElement>(null);

  const handleClose = () => {
    anchorEl.current = null;
    setMenuRowOpened(false);
  };

  // Сохранить и добавить новую стр
  const handleSaveAddLevel = () => {
    setIsAddSave('SaveAdd');
    if (!formik.isValid) {
      setIsAddSave('');
    }
    anchorEl.current = null;
    setMenuRowOpened(false);
    handleSubmit();
  };

  // Сохранить стр
  const handleSaveLevel = () => {
    handleSubmit();
    setIsAddSave('');
  };

  const handleClearForm = () => {
    formik.resetForm({
      values: {
        parent: null,
        price: 0,
        quantity: 0,
        title: '',
        type: 'level',
        unit: '',
        unitPrice: 0,
      },
    });
    anchorEl.current = null;
    setMenuRowOpened(false);
  };

  const handleDeleteFormAddLevel = () => {
    handleShowFormNestedLevel(false);
    handleShowFormAdjacentLevel(false);
  };

  const handleOpenMenuRowClick = (event: React.MouseEvent<HTMLElement>) => {
    anchorEl.current = event.currentTarget;
    if (event.currentTarget) setMenuRowOpened(true);
  };

  /************************************** onKeyDown Enter **********************************/
  const [pressEnter, setPressEnter] = useState(false);

  const enterPressHandler = (e: { key: string }) => {
    if (e.key === 'Enter') {
      setPressEnter(true);
    }
  };

  /******************************** Focus Out *************************************/

  const formRef = useRef<HTMLFormElement>(null);

  const handleFocusOut: FocusEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      // @ts-ignore
      if (event.relatedTarget && formRef.current?.contains(event.relatedTarget)) return;

      if (!isMenuRowOpened) {
        return handleSubmit();
      }
    },
    [isMenuRowOpened]
  );

  /*************************************************************************/

  return (
    <>
      <Box sx={{ display: 'flex' }} className="FormAddLevel">
        <StyledLevelTd
          colorsBgLevel={levelName}
          style={{ width: '100px', paddingLeft: '9px', justifyContent: 'flex-start' }}
        >
          <Box
            style={{
              display: 'flex',
              minWidth: 24,
              marginRight: `${8 + levelNumber * 6}px`,
            }}
          ></Box>
          <IconButton
            disabled
            sx={{ width: 32, height: 32, marginRight: '0px' }}
            size="small"
          >
            <LevelIcon levelName={levelName} />
          </IconButton>
        </StyledLevelTd>
        <FormikProvider value={formik}>
          <Form
            style={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: 'minmax(206px, 1fr) repeat(4, minmax(135px, 206px)) 30px',
              gridAutoRows: '50px',
            }}
            ref={formRef}
            onBlur={handleFocusOut}
            onKeyDown={enterPressHandler}
          >
            <StyledLevelTd colorsBgLevel={levelName} style={{ justifyContent: 'flex-start' }}>
              <StyledFieldForm
                autoFocus
                version="calculation"
                name="title"
                placeholder={
                  formik.isValid ? 'Укажите название работ' : 'Поле обязательно к заполнению'
                }
              ></StyledFieldForm>
            </StyledLevelTd>
            <StyledLevelTd colorsBgLevel={levelName}></StyledLevelTd>
            <StyledLevelTd colorsBgLevel={levelName}></StyledLevelTd>
            <StyledLevelTd colorsBgLevel={levelName}></StyledLevelTd>
            <StyledLevelTd
              colorsBgLevel={levelName}
              style={{
                color: colorTheme.text.gray, //'#5C6E8C',
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '28px',
                justifyContent: 'flex-end',
              }}
            >
              0
            </StyledLevelTd>
            <StyledLevelTd colorsBgLevel={levelName} style={{ padding: 0, width: 30 }}>
              <IconButton
                onClick={(event) => handleOpenMenuRowClick(event)}
                sx={{ width: 30, height: 30 }}
                size="small"
              >
                <MoreVertIcon />
              </IconButton>
            </StyledLevelTd>
          </Form>
        </FormikProvider>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl.current}
        open={isMenuRowOpened}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleSaveLevel}>Сохранить </MenuItem>
        <MenuItem onClick={handleSaveAddLevel}>Сохранить и доб. статью </MenuItem>
        {firstForm !== 'firstForm' && (
          <>
            <MenuItem onClick={handleClearForm}>Очистить </MenuItem>
            <MenuItem onClick={handleDeleteFormAddLevel}>Удалить </MenuItem>
          </>
        )}
      </Menu>
    </>
  );
};

export default FormAddLevel;
