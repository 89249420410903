export function getTotalCount(rowID: number | undefined, level: number, childrenIds: number[], groupsIds: number[]) {
  if (!rowID) return;

  const mergedIds = [...childrenIds, ...groupsIds];
  const index = mergedIds.findIndex((id) => id === rowID);

  if (index === -1) return;

  return {
    childrenCount: index <= childrenIds.length ? childrenIds.length - index : 0,
    groupCount: index >= childrenIds.length ? mergedIds.length - index : level === 0 ? 0 : groupsIds.length,
  };
}
