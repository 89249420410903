import { IconButton, Stack, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LastPageSharpIcon from '@mui/icons-material/LastPageSharp';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import FirstPageSharpIcon from '@mui/icons-material/FirstPageSharp';
import { useGetComplexRowsListQuery } from '../../../../../../api/calculations';
import { BasePricesItem } from '../../../../../../api/calculations/types';

const getCountLevel = (array: BasePricesItem[]) => {
  let i = 0;
  while (array.length) {
    i++;
    array = array.reduce((level: BasePricesItem[], elem) => {
      if (elem.children.length > 0) {
        const isContainLevels = elem.children.some((item) => item.type === 'level');

        if (!isContainLevels) return level;
      }
      if (elem.children.length > 0) level.push(...elem.children);
      return level;
    }, []);
  }
  return i;
};
interface SwitchingLevelsProps {
  calculationId: number;
  setNumberLevel: (value: number) => void;
  numberLevel: number;
}

export const SwitchingLevels: React.FC<SwitchingLevelsProps> = ({
  calculationId,
  setNumberLevel,
  numberLevel,
}) => {
  const { data } = useGetComplexRowsListQuery({ id: calculationId });

  const countLevels = useMemo(() => getCountLevel(data?.rows ?? []), [data?.rows]);

  const levelCurrent = `УРОВЕНЬ ${numberLevel + 1}`;

  const handleClickFirst = () => {
    setNumberLevel(0);
  };

  const handleClickLast = () => {
    setNumberLevel(countLevels);
  };

  const handleClickPrev = () => {
    if (numberLevel > 0 && numberLevel <= countLevels) {
      setNumberLevel(numberLevel - 1);
    }
  };

  const handleClickNext = () => {
    if (numberLevel >= 0 && numberLevel < countLevels) {
      setNumberLevel(numberLevel + 1);
    }
  };

  return (
    <Stack className="SwitchingLevels" direction="row" alignItems="center">
      <IconButton
        sx={{ width: 28, height: 28 }}
        onClick={handleClickFirst}
        disabled={numberLevel === 0}
      >
        <FirstPageSharpIcon />
      </IconButton>
      <IconButton
        sx={{ width: 28, height: 28 }}
        onClick={handleClickPrev}
        disabled={numberLevel === 0}
      >
        <NavigateBeforeIcon />
      </IconButton>
      <Typography sx={{ margin: '0 5px' }}>{levelCurrent}</Typography>
      <IconButton
        sx={{ width: 28, height: 28 }}
        onClick={handleClickNext}
        disabled={numberLevel === countLevels}
      >
        <NavigateNextIcon />
      </IconButton>
      <IconButton
        sx={{ width: 28, height: 28 }}
        onClick={handleClickLast}
        disabled={numberLevel === countLevels}
      >
        <LastPageSharpIcon />
      </IconButton>
    </Stack>
  );
};
