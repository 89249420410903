import * as React from 'react';
import { useRef, useState } from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { UserMenuButton, StyledMenu, CompanyWrapper, UserInfo, UserData, UserName } from './styles';
import Button from '../../Button';
import { UserMenuProps } from './UserMenu.types';

const UserMenu: React.FC<UserMenuProps> = ({ profile, onChangeModule, onLogout }) => {
  const { firstName, middleName, lastName, avatarPreview, company } = profile;

  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const fullName = (
    <>
      {lastName}
      <br />
      {firstName} {middleName}
    </>
  );
  const partedName = (
    <>
      {lastName} {firstName}
    </>
  );
  const firstLetter = lastName?.[0];

  return (
    <Box className={'className-UserMenu'}>
      <UserMenuButton
        variant="text"
        startIcon={<Avatar src={avatarPreview}>{firstLetter}</Avatar>}
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        disableRipple
        ref={anchorRef}
        onClick={handleToggle}>
        <Typography variant="subtitle1" component="span">
          {partedName}
        </Typography>
      </UserMenuButton>
      <StyledMenu
        className={'className-StyledMenu'}
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
        <CompanyWrapper>{company?.companyName}</CompanyWrapper>

        <UserInfo>
          <Avatar src={avatarPreview}>{firstLetter}</Avatar>
          <UserData>
            <UserName>{fullName}</UserName>
            <div>{company?.userCompanyName}</div>
            <div>{company?.userPosition}</div>
          </UserData>
        </UserInfo>

        <Button onClick={onChangeModule} width="100%" style={{ maxWidth: 'none' }}>
          К выбору модуля
        </Button>
        <Button onClick={onLogout} width="100%" style={{ maxWidth: 'none' }}>
          Выйти из системы
        </Button>
      </StyledMenu>
    </Box>
  );
};

export default UserMenu;
