import { Box, Typography } from '@mui/material';
import _ from 'lodash';
import { FC, useMemo } from 'react';
import { getTotalWeight } from '../../Row.service';
import { StyledNodeRow } from '../../Row.styles';
import { GroupedSubRowsRowsEntries } from '../../Row.types';
import { GroupedRow } from '../GroupedRow';
import { SplittedRowProps } from './SplittedRow.types';

export const SplittedRow: FC<SplittedRowProps> = ({ splittedRows, groupedRowByTag, tableColumns }): JSX.Element => {
  const groupedRowsByTag = useMemo(() => _.groupBy(splittedRows, 'type'), [splittedRows]);
  return (
    <Box>
      <StyledNodeRow>
        <div></div>
        <Typography textAlign="left" variant="body2" color="primary.main">
          Ресурсы Заказчика
        </Typography>
        <div></div>
        <div></div>
      </StyledNodeRow>
      {(Object.entries(groupedRowsByTag) as GroupedSubRowsRowsEntries)
        .sort()
        .reverse()
        .map(([key, rows]) => (
          <GroupedRow
            key={key}
            rows={rows}
            type={key}
            groupWeight={getTotalWeight(groupedRowByTag[key])}
            tableColumns={tableColumns}
          />
        ))}
    </Box>
  );
};
