import { CalcDataType } from "types";

export const fileTypeNames: Record<CalcDataType, string> = {
  basisManual: 'калькуляция',
  excel: 'калькуляция',
  grandSmeta: 'xml',
  resourceManual: 'ручной',
  tangl: 'Tangl',
  handbk: 'справочник',
};
