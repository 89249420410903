export function Loader(props: any) {
  return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M10.1196 19.7486C9.59364 19.7486 9.16725 19.3222 9.16725 18.7962L9.16725 15.2045C9.16725 14.6786 9.59364 14.2522 10.1196 14.2522C10.6456 14.2522 11.072 14.6786 11.072 15.2045L11.072 18.7962C11.072 19.3222 10.6456 19.7486 10.1196 19.7486Z" fill="#6D9ADC" />
          <path fillRule="evenodd" clipRule="evenodd" d="M10.0234 5.89067C9.49745 5.89067 9.07106 5.46427 9.07106 4.93829L9.07106 1.34663C9.07106 0.820647 9.49745 0.394252 10.0234 0.394252C10.5494 0.394252 10.9758 0.820647 10.9758 1.34663L10.9758 4.93829C10.9758 5.46427 10.5494 5.89067 10.0234 5.89067Z" fill="#6D9ADC" />
          <path fillRule="evenodd" clipRule="evenodd" d="M19.7486 10.0234C19.7486 10.5494 19.3222 10.9758 18.7962 10.9758L15.2045 10.9758C14.6786 10.9758 14.2522 10.5494 14.2522 10.0234C14.2522 9.49745 14.6786 9.07106 15.2045 9.07106L18.7962 9.07106C19.3222 9.07106 19.7486 9.49745 19.7486 10.0234Z" fill="#6D9ADC" />
          <path fillRule="evenodd" clipRule="evenodd" d="M5.89067 10.1196C5.89067 10.6456 5.46427 11.072 4.93829 11.072L1.34663 11.072C0.820647 11.072 0.394252 10.6456 0.394252 10.1196C0.394251 9.59364 0.820647 9.16725 1.34663 9.16725L4.93829 9.16725C5.46427 9.16725 5.89067 9.59364 5.89067 10.1196Z" fill="#9AA2B0" fillOpacity="0.5" />
          <path fillRule="evenodd" clipRule="evenodd" d="M16.8802 3.19496C17.2522 3.56689 17.2522 4.1699 16.8802 4.54183L14.3406 7.08151C13.9686 7.45344 13.3656 7.45344 12.9937 7.08152C12.6218 6.70959 12.6218 6.10657 12.9937 5.73464L15.5334 3.19496C15.9053 2.82303 16.5083 2.82303 16.8802 3.19496Z" fill="#6D9ADC" />
          <path fillRule="evenodd" clipRule="evenodd" d="M7.14928 13.0617C7.5212 13.4336 7.5212 14.0366 7.14928 14.4085L4.60959 16.9482C4.23767 17.3201 3.63465 17.3201 3.26272 16.9482C2.8908 16.5763 2.8908 15.9733 3.26272 15.6013L5.80241 13.0617C6.17433 12.6897 6.77735 12.6897 7.14928 13.0617Z" fill="#6D9ADC" />
          <path fillRule="evenodd" clipRule="evenodd" d="M3.19496 3.26283C3.56689 2.8909 4.1699 2.8909 4.54183 3.26283L7.08152 5.80251C7.45344 6.17444 7.45344 6.77746 7.08152 7.14938C6.70959 7.52131 6.10657 7.52131 5.73465 7.14938L3.19496 4.6097C2.82303 4.23777 2.82303 3.63476 3.19496 3.26283Z" fill="#9AA2B0" fillOpacity="0.5" />
          <path fillRule="evenodd" clipRule="evenodd" d="M13.0617 12.9943C13.4336 12.6223 14.0366 12.6223 14.4085 12.9943L16.9482 15.534C17.3201 15.9059 17.3201 16.5089 16.9482 16.8808C16.5763 17.2528 15.9733 17.2528 15.6013 16.8808L13.0617 14.3411C12.6897 13.9692 12.6897 13.3662 13.0617 12.9943Z" fill="#6D9ADC" />
      </svg>
  )
}