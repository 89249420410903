import { Button, MenuItem, Stack, Typography } from '@mui/material';
import { useUploadLSRFilesMutation } from 'api/lsr';
import { Drawer } from 'components/Drawer';
import UploadForm, { uploadFileTypes } from 'components/UploadForm';
import { Form, FormikProvider } from 'formik';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { useForm } from 'hooks/useForm';
import { useProjectId } from 'hooks/useProjectId';
import { useSnackbar } from 'notistack';
import { FC, useContext, useEffect, useState } from 'react';
import { useAppDispatch, useTypedSelector } from 'store/store';
import { lsrFileStatusNames } from 'types';
import { statusOptions } from '../Table/Table.constants';
import { StyledFolderIcon, StyledStatusSelect } from './UploadLSRFilesDrawer.styles';
import {
  UploadLSRFilesDrawerProps,
  UploadLsrSocketFile,
  UploadLSRSocketFilesDrawer,
} from './UploadLSRFilesDrawer.types';
import { socketLsr, websocketApi } from 'api/websocketApi';
import { api } from 'api/api';
import { wsContext } from 'contexts/ws';
import { UploadType } from '../WSCustomSnackbar/WSCustomSnackbar.types';

export interface IErrorLSRData {
  successfullyUploaded: IErrorLSRDataMSG[];
  totalUploaded: number;
  uploadedWithErrors: IErrorLSRDataMSG[];
}
export interface IErrorLSRDataMSG {
  name: string;
  success: boolean;
  msg: string | null;
}

export const UploadLSRFilesDrawer: FC<UploadLSRFilesDrawerProps> = ({ open, onClose }): JSX.Element => {
  const projectID = useProjectId();
  const { token } = useTypedSelector((state) => state.auth);
  const [uploadLSRFile, uploadLSRFilesResponse] = useUploadLSRFilesMutation();
  const [formData, setFormData] = useState<FormData | null>(null);
  const [filesData, setFilesData] = useState<UploadLsrSocketFile[] | null>(null);
  const [error, setError] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const cWS = useContext(wsContext);

  const [errorData, setErrorData] = useState<IErrorLSRData | null>(null);

  const [isUploaded, setUploaded] = useState<UploadType>('start');
  const [progressPercent, setProgressPercent] = useState<number>(0);
  const [progressTime, setProgressTime] = useState<string>('');
  const [isListFiles, setIsListFiles] = useState<boolean>(false);

  const { singleTask } = websocketApi;

  const handleRefresh = () => {
    dispatch(api.util.invalidateTags(['LSR', 'ProjectDashboard']));
    resetAndCloseDrawer();
  };

  useEffect(() => {
    if (socketLsr.readyState !== 1) return;
    const authWS = {
      bodyJson: JSON.stringify({ token }),
      type: 'auth',
    };

    socketLsr.send(JSON.stringify(authWS));

    socketLsr.onmessage = function (event) {
      let status = JSON.parse(event.data).type;
      let bodyJson = JSON.parse(event.data).bodyJson;
      if (bodyJson !== null && bodyJson.payload == 'pong') return;

      if (status === 'status_ok') {
        console.log('[socketChanel-open] Соединение открыто');
      }
      if (status === 'error') {
        let msg = JSON.parse(bodyJson).msg;
        console.log(`[socketChanel-error] Не авторизован, msg:${msg}`);
      }
    };
    /* socketChanel.onclose = function (event) {
      if (event.wasClean) {
        console.log(`[socketChanel-close] Соединение закрыто, причина=${event.reason}.`);
      } else {
        console.log(`[socketChanel-close] Соединение прервано, причина=${event.reason}.`);
      }

    }; */
  }, [socketLsr.readyState]);

  const { enqueueSnackbar } = useSnackbar();

  if (socketLsr)
    socketLsr.onmessage = function (event) {
      let parsed = JSON.parse(event.data);

      let status = parsed.type;

      let bodyJson = JSON.parse(parsed.bodyJson);

      if (bodyJson !== null && bodyJson.payload == 'pong') return;

      if (status === 'upload_progress' && projectID === cWS.socketLsr.projectIDInit) {
        cWS.setLsrUploaded('loading');
        let progress = bodyJson.progress;
        let remainTime = bodyJson.remainTime;
        setProgressTime!(String(remainTime));
        setProgressPercent!(Number(progress));
        /* let totalRecords = Number(JSON.parse(bodyJson).total);
      setUploadRecords!(totalRecords); */
      }

      if (status === 'status_ok' && cWS.socketLsr.isUploaded === 'loading') {
        cWS.setLsrUploaded('success');
        setErrorData(null);
        setProgressPercent(0);
        setProgressTime('');
        handleRefresh();
        //successHandler('Файл успешно загружен');
      }
      if (status === 'canceled') {
        cWS.setLsrUploaded('canceled');
      }

      if (status === 'with_errors') {
        let msgValue = bodyJson as IErrorLSRData;
        msgValue.uploadedWithErrors.length && setErrorData(msgValue);
        // enqueueSnackbar(`Непредвиденная ошибка в файле: ${msgValue.replace('ws.parse_error ', '')}`, {
        //   variant: 'error',
        //   autoHideDuration: 5000,
        // });
        setError(true);
        setProgressPercent(0);
        setProgressTime('');
        cWS.setLsrUploaded('error');
      }
    };

  const initialValues: UploadLSRSocketFilesDrawer = {
    status: null,
    files: null,
  };

  const onSubmit = (values: UploadLSRSocketFilesDrawer) => {
    if (socketLsr.readyState !== 1) {
      enqueueSnackbar('Установка соединения. Повторите попытку через несколько секунд.', {
        variant: 'error',
        autoHideDuration: 15000,
      });
      return;
    }

    if (values.files) {
      setIsListFiles(false);

      const fileWS = {
        type: singleTask.types.uploadFile,
        bodyJson: JSON.stringify({
          param: {
            projectID,
          },
          files: filesData ?? [],
          status: values.status,
        }),
      };

      socketLsr.send(JSON.stringify(fileWS));
      cWS.setLsrUploaded('loading');
      cWS.setLsrProjectIDInit(projectID);
      socketLsr.onerror = function (error) {
        console.log(`[socketChanel-error]`, error);
      };
    }
  };

  const { formik } = useForm({
    initialValues,
    onSubmit,
  });

  const { openConfirm, ConfirmDialog } = useConfirmDialog({
    handleConfirm: (confirm) => {
      if (confirm) resetAndCloseDrawer();
    },
  });

  const resetAndCloseDrawer = () => {
    onClose();

    setTimeout(() => {
      formik.resetForm();
      setFilesData(null);
    }, 300);
  };

  const closeConfirmDrawer = () => {
    if (cWS.socketLsr.isUploaded === 'loading') {
      resetAndCloseDrawer();
      return;
    }
    formik.dirty ? openConfirm() : resetAndCloseDrawer();
  };

  useEffect(() => {
    cWS.setLsrIsDrawerOpen(open);
  }, [open]);
  return (
    <>
      <Drawer title="Загрузить документы" open={open} onClose={closeConfirmDrawer}>
        <FormikProvider value={formik}>
          <Stack p={2.5} component={Form} height="100%" overflow="auto">
            <Stack direction="row" alignItems="center" spacing={2.5}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <StyledFolderIcon />
                <Typography variant="body2">Статус:</Typography>
              </Stack>
              <StyledStatusSelect
                value={formik.values.status}
                displayEmpty
                disabled={cWS.socketLsr.isUploaded === 'loading'}
                fullWidth
                renderValue={(selected) => (selected === null ? 'Выберите статус' : lsrFileStatusNames[selected])}
                onChange={(e) => formik.setFieldValue('status', e.target.value)}>
                {statusOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </StyledStatusSelect>
            </Stack>
            <UploadForm
              errorsMSG={errorData}
              mode="xml"
              format={uploadFileTypes.xml}
              error={error}
              isLoading={cWS.socketLsr.isUploaded === 'loading'}
              setError={setError}
              setFormData={undefined}
              isWS={true}
              setFilesData={setFilesData}
              wsProgress={progressPercent}
              wsTime={progressTime}
              filesData={filesData}
              isListFiles={isListFiles}
              setIsListFiles={setIsListFiles}
            />
            {/* {lastJsonMessage?.type === 'upload_progress' && (
              <Box>
                <div>Прогресс: {progress.progress}%</div>
                <div>
                  Загружено файлов: {progress.completed} из {progress.total}
                </div>
                <div>Осталось времени: {progress.remainTime}</div>
              </Box>
            )} */}
            <Stack direction="row" spacing={2} mt="auto">
              <Button
                color="success"
                type="submit"
                disabled={!filesData || cWS.socketLsr.isUploaded === 'loading'}
                fullWidth>
                Обработать
              </Button>
              <Button fullWidth onClick={closeConfirmDrawer}>
                Закрыть
              </Button>
            </Stack>
          </Stack>
        </FormikProvider>
      </Drawer>
      <ConfirmDialog />
    </>
  );
};
