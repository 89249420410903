export function getFormatNumber(
  value: number | undefined,
  localization = 'ru',
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
) {
  if (!value) return value;

  const numberFormat = new Intl.NumberFormat(localization, { minimumFractionDigits, maximumFractionDigits });

  return numberFormat.format(value);
}
export function modifyNum(str?:string|number|null){
  if(!str) return str
  if(Number.isNaN(Number(str))) return str
  if(typeof str === 'number') str = str.toString();
  str = str.replace('.',',')
  let result = str;
  let indexLeft = str.indexOf(',') - 3;
  while(str[indexLeft]){
    result = str.slice(0,indexLeft) + ' ' + str.slice(indexLeft)
    indexLeft -= 4;
  }
  // let indexRight = result.indexOf(',') + 4;
  // while(result[indexRight]){
  //   result = result.slice(0,indexRight) + ' ' + result.slice(indexRight)
  //   indexRight += 4;
  // }
  return result;
}
