/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import React, { PropsWithChildren } from "react";
import { Cell as C, CellWrapper } from "./Cell.style";
import { StyledTooltip } from "../CellEditorSergey/CellEditor.style";

interface ICell extends PropsWithChildren {
  float?: 'left' | 'center' | 'right';
  click?: () => void;
  isError?: boolean;
  isNum?: boolean;
  isHide?: boolean;
  showTooltip?: boolean;
  weight?: boolean;
  color?: string;
  className?: string;
  originData?:string|number|null;
}

const Cell: React.FC<ICell> = ({
  weight = false,
  children,
  isHide,
  isError,
  float = 'left',
  click,
  showTooltip = true,
  originData = null,
  className,
  color,
}) => {
  return (
    <CellWrapper className={className} color={color} isError={isError} onClick={() => click?.()}>
      <StyledTooltip
        title={showTooltip ? originData!== undefined?originData||'':children : ''}
        PopperProps={{
          disablePortal: false,
        }}>
        <C color={color} isNum={false} float={float} weight={weight}>
          {isHide ? null : children}
        </C>
      </StyledTooltip>
    </CellWrapper>
  );
};

export default Cell;
