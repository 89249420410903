import { LevelFolderSize } from "./LevelFolder.types";

export const levelColors: Record<number, string> = {
  1: "#0044B4",
  2: "#0288D1",
  3: "#00BFA5",
  4: "#B968EC",
  5: "#FFB1B1",
};

export const levelNumberSize: Record<LevelFolderSize, string> = {
  inherit: "inherit",
  small: "8px",
  medium: "10px",
  large: "12px",
};

export const getLevelColor = (level: number) => {
  if (level < 0) return levelColors[1];

  const division = level % 10;
  const length = Object.keys(levelColors).length;

  switch (true) {
    case division === 0:
      return levelColors[length];
    case division <= length:
      return levelColors[division];
    default:
      return levelColors[division - length];
  }
};
