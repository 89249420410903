/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import { IEstimateReference, ResourceType } from "../../../../api/references/estimates/estimates.types";
import { UseFormReturn } from "react-hook-form";

export type TTabActive = 'codes' | 'groups' | 'resourceTypes' | 'titles' | null;

export type TPositionPlaceholder = CreatePatternPosition & { blockedInput: boolean };
export interface IToggleCreateDrawer {
  open: boolean;
  rowData: IEstimateReference | null;
  createdLevel: number | null;
}
export interface IPriceReferenceContext {
  clearRemovedIds: () => void;
  deleteRate: () => void;
  removedIds: { type: string; id: number; parent: number | null }[];
  collapse: (id: number) => void;
  collapseUUid: (id: string) => void;
  toggleDrawer: (data: IToggleCreateDrawer) => void;
  hiddenRows: number[];
  hiddenRowsUUid: string[];
  checkboxClick: (id: number, type: string, parent: number | null) => void;
  add: any;
  submit: (v: any) => void;
  deleteUnit: (v: number) => void;
  deleteGroup: (v: number) => void;
  submitPosition: (v: any, id: number, parent: number, folder: number) => void;
  methodsRate: UseFormReturn<FormPriceReference, any, undefined> | null;
  methodsPosition: UseFormReturn<FormPriceReferencePosition, any, undefined> | null;
  deleteFolderConfirm: (lvl: number, f: any) => void;
  errors: { rate: TFormStateValidationRate; position: TFormStateValidationPosition };
  getFormState?: () => {
    shifr: string | null;
    level1: string | null;
    level2: string | null;
    level3: string | null;
    unit: string | null;
  };
  changeDialog: (key?: 'codes' | 'groups' | 'resourceTypes' | 'titles') => void;
  denyCreated: () => void;
}

export interface IErrorData<T> {
  [key: string]: any;
  error?: {
    status: string;
    originalStatus: number;
    data: T;
    error: string;
  };
}
export interface FormPriceReferencePosition {
  [key: number]: CreatePatternPosition;
}
export interface FormPriceReference {
  level1: string;
  level2: string;
  level3: string;
  estimate: string;
  shifr: string;
  fot: string | null;
  mim: string | null;
  mtr: string | null;
  gen: null | number;
  unit: string;
  kfoUnit: string | null;
  kfoName: string | null;
  kfoCoefficient: string | null;
  unitPosition: string;
}
export interface CreatePatternPosition {
  fot: number | null;
  mim: number | null;
  mtr: number | null;
  pz: number | null;
  qty: number | null;
  title: string | null;
  type: ResourceType | null;
  unit: string | null;
  workTime: number | null;
}

export type TFormStateValidationPosition = { [key: string | number]: Record<string | number, boolean> };
export type TFormStateValidationRate = Record<string | number, boolean>;

export const template: any = {
  '1': [
    {
      uuid: -1,
      id: -1,
      parentID: null,
      rowType: 'FOLDER',
      create: true,
      lvl: 1,
      children: [
        // {
        //   uuid: -2,
        //   id: -2,
        //   parentID: -1,
        //   rowType: 'FOLDER',
        //   create: true,
        //   lvl: 2,
        //   children: [
        //     {
        //       uuid: -3,
        //       id: -3,
        //       parentID: -2,
        //       rowType: 'FOLDER',
        //       create: true,
        //       lvl: 3,
        //       children: [{ uuid: 1, id: -4, parentID: -3, rowType: 'RATE', create: true, lvl: 4, children: [] }],
        //     },
        //   ],
        // },
      ],
    },
    // {
    //   uuid: -4,
    //   id: -2,
    //   parentID: -1,
    //   rowType: 'FOLDER',
    //   create: true,
    //   lvl: 2,
    //   children: [
    //     {
    //       uuid: -5,
    //       id: -3,
    //       parentID: -2,
    //       rowType: 'FOLDER',
    //       create: true,
    //       lvl: 3,
    //       children: [{ uuid: -6, id: -4, parentID: -3, rowType: 'RATE', create: true, lvl: 4, children: [] }],
    //     },
    //   ],
    // },
    // {
    //   uuid: -7,
    //   id: -3,
    //   parentID: -2,
    //   rowType: 'FOLDER',
    //   create: true,
    //   lvl: 3,
    //   children: [{ uuid: -8, id: -4, parentID: -3, rowType: 'RATE', create: true, lvl: 4, children: [] }],
    // },
    // { uuid: -9, id: -4, parentID: -3, rowType: 'RATE', create: true, lvl: 4, children: [] },
  ],
  '2': [
    {
      uuid: -10,
      id: -2,
      parentID: -1,
      rowType: 'FOLDER',
      create: true,
      lvl: 2,
      children: [
        // {
        //   uuid: -11,
        //   id: -3,
        //   parentID: -2,
        //   rowType: 'FOLDER',
        //   create: true,
        //   lvl: 3,
        //   children: [{ uuid: 1, id: -4, parentID: -3, rowType: 'RATE', create: true, lvl: 4, children: [] }],
        // },
      ],
    },
    // {
    //   uuid: -12,
    //   id: -3,
    //   parentID: -2,
    //   rowType: 'FOLDER',
    //   create: true,
    //   lvl: 3,
    //   children: [{ uuid: -13, id: -4, parentID: -3, rowType: 'RATE', create: true, lvl: 4, children: [] }],
    // },
    // { uuid: -14, id: -4, parentID: -3, rowType: 'RATE', create: true, lvl: 4, children: [] },
  ],
  '3': [
    {
      uuid: -15,
      id: -3,
      parentID: -2,
      rowType: 'FOLDER',
      create: true,
      lvl: 3,
      children: [
        // { uuid: -16, id: -4, parentID: -3, rowType: 'RATE', create: true, lvl: 4, children: [] }
      ],
    },
    // { uuid: -17, id: -4, parentID: -3, rowType: 'RATE', create: true, lvl: 4, children: [] },
  ],
  '4': [{ uuid: -18, id: -4, parentID: -3, rowType: 'RATE', create: true, lvl: 4, children: [] }],
  '5': [{ uuid: -19, id: -105, parentID: -4, rowType: 'RATE_POSITION', create: true, lvl: 5, children: [] }],
};

export const filterChips = [
  {
    code: 'resourceTypes',
    name: 'Тип ресурса',
  },
  {
    code: 'codes',
    name: 'Шифр',
  },
  {
    code: 'titles',
    name: 'Наименование',
  },
  {
    code: 'groups',
    name: 'Группа КФО',
  },
];
export const positionPlaceholder: TPositionPlaceholder = {
  fot: null,
  mim: null,
  mtr: null,
  pz: null,
  qty: null,
  title: null,
  type: null,
  unit: null,
  workTime: null,
  blockedInput: false,
};

export const resetGenForm = {
  shifr: '',
  type: '',
  estimate: '',
  level1: '',
  level2: '',
  level3: '',
  gen: null,
  kfoUnit: '',
  description: '',
  kfoCoefficient: '',
  kfoName: '',
  unit: '',
};

export const initialErrorForm = {
  rate: {
    level1: false,
    level2: false,
    level3: false,
    estimate: false,
    shifr: false,
    gen: false,
    unit: false,
    unitPosition: false,
  },
  position: {},
};

export function flatCount(item: IEstimateReference) {
  let num = item.children.length;
  const f = (el: IEstimateReference) => {
    num += el.children.length;
    el.children.forEach(f);
  };
  item.children.forEach(f);
  return num;
}

export const errorsState = {
  shifr: 'Не заполнено поле Шифр',
  level1: 'Не заполнено поле Наименование папки первого уровня',
  level2: 'Не заполнено поле Наименование папки второго уровня',
  level3: 'Не заполнено поле Наименование папки третьего уровня',
  estimate: 'Не заполнено поле Наименование в расценке',
  type: 'Не заполнено поле Тип ресурса',
  title: 'Не заполнено поле Наименование в ресурсе',
  unit: 'Не заполнено поле Ед.Изм',
  qty: 'Не заполнено поле Количество',
  pz: 'Не заполнено поле ПЗ',
};
