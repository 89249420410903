import 'react-dadata/dist/react-dadata.css';
import './MapForm.scss';
import React, { FC } from 'react';
import { TabFormWrapper } from '../ManagementForm';
import { Stack } from '@mui/material';
import { FormTitledWrapper } from '../FormLeftBlock/FormLeftBlock.styles';
import { useFormikContext } from 'formik';
import { ProjectFormData } from '../../ProjectManagement.types';
import { MapWrapper } from './MapForm.styles';
import { googleKey } from 'utils/constants';
import { AddressBlock } from '../AddressBlock';
import { EmployeesBlock } from '../EmployeesBlock';

export const MapForm: FC = () => {
  const { values: formValues } = useFormikContext<ProjectFormData>();
  const { latitude, longitude } = formValues.addressData || {};

  const addressForMap =
    formValues.addressData.address?.value ||
    (latitude && longitude ? `${formValues.addressData.latitude}, ${formValues.addressData.longitude}` : 'Москва');

  return (
    <TabFormWrapper>
      <Stack flex={1} maxWidth={600} spacing={2.5}>
        <FormTitledWrapper title="Адрес проекта" variant="primary" contentWrapper="stack" bordered="border">
          <AddressBlock />
        </FormTitledWrapper>

        <EmployeesBlock />
      </Stack>

      <MapWrapper>
        <iframe
          loading="lazy"
          allowFullScreen
          referrerPolicy="no-referrer-when-downgrade"
          src={`https://www.google.com/maps/embed/v1/place?key=${googleKey}&q=${addressForMap}`}
        />
      </MapWrapper>
    </TabFormWrapper>
  );
};
