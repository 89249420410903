import { ChangeEvent, FC, useContext, useMemo } from "react";
import { PositionVorContext, SelectedPositionType } from "../../../PositionsTable";
import { StyledCheckbox } from "./CheckboxCell.styles";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CheckboxCellProps } from "./CheckboxCell.types";
import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import { PositionVOR } from "../../../../../../api/calculations/types";

const binarySearch = (data: MaybeDrafted<PositionVOR[]>, target: PositionVOR, start: number, end: number): number | undefined => {
  if (end < 1) return 0;
  const middle = Math.floor(start + (end - start) / 2);
  if (target.id === data[middle].id) return middle;
  if (target.id === data[start].id) return start;
  if (target.id === data[end].id) return end;
  if (target.id > data[middle].id) return binarySearch(data, target, middle, end);
  if (target.id < data[middle].id) return binarySearch(data, target, start, middle);
  return undefined;
};

export const CheckboxCell: FC<CheckboxCellProps> = ({ iconProps, update, vor, type }) => {

  const {selectPositions} = useContext(PositionVorContext)
  // const { calcID: calcIdStr } = useParams<{ calcID: string }>();
  // const {  } = useSelector((state: RootState) => state.vor.table);
  // const dispatch = useAppDispatch();
  //
  // const calcId = Number(calcIdStr);

  const updateTypeInStore = (type: SelectedPositionType | null/*,isShift?:boolean*/) => {
    // const localData = JSON.parse(localStorage.getItem("check") as any);
    // let firstCheck:number|undefined,t:SelectedPositionType | null|undefined;
    // if (localData) {
    //   firstCheck = localData[0];
    //   t = localData[1]
    // }
    // dispatch(
    //   calculationsApi.util.updateQueryData('getVorPositions', { calcId }, (draft) => {
    //     // const changedVorIndex = [...draft.data].findIndex((thisVor) => thisVor.id === vor.id);
    //     const changedVorIndex: any = binarySearch(draft.data, vor, 0, [...draft.data].length - 1);
    //     if (changedVorIndex !== undefined) {
    //       // const updated = { ...changedVorIndex, type };
    //       draft.data[changedVorIndex].type = type;
    //       update(vor, type);
    //     }
    //   }),
    // );
    // if (typeof firstCheck === 'number'  && isShift ) {
    //   dispatch(
    //     calculationsApi.util.updateQueryData('getVorPositions', { calcId }, (draft) => {
    //       // const changedVorIndex = [...draft.data].findIndex((thisVor) => thisVor.id === vor.id);
    //       const changedVorIndex: number|undefined = binarySearch(draft.data, vor, 0, [...draft.data].length - 1);
    //       const min = Math.min(firstCheck!, (changedVorIndex! || 0));
    //       const max = Math.max(firstCheck!, (changedVorIndex || 0));
    //       const count = max - min;
    //       console.log(min,max,count)
    //       if (changedVorIndex !== undefined) {
    //         for (let i = 0; i <= count;i++) {
    //           console.log(min + i,)
    //           // draft.data[min + i].type = t??null;
    //           update({ ...draft.data[min + i] }, t??null);
    //         }
    //         localStorage.setItem("check", JSON.stringify([changedVorIndex,type]));
    //       }
    //     }),
    //   );
    // }else{
    //       localStorage.setItem("check", JSON.stringify([vor,type]));
            update(vor, type);
      // dispatch(
      //   calculationsApi.util.updateQueryData('getVorPositions', { calcId }, (draft) => {
      //     // const changedVorIndex = [...draft.data].findIndex((thisVor) => thisVor.id === vor.id);
      //     const changedVorIndex = binarySearch(draft.data, vor, 0, [...draft.data].length - 1);
      //     localStorage.setItem("check", JSON.stringify([changedVorIndex,type]));
      //     if (changedVorIndex !== undefined) {
      //       // const updated = { ...changedVorIndex, type };
      //       // draft.data[changedVorIndex].type = type;
      //       update({ ...draft.data[changedVorIndex] }, type);
      //     }
      //   }),
      // );

    // }
  };

  const onChange = (type: SelectedPositionType) => (e: ChangeEvent<HTMLInputElement>) => {
    // const isShift = 'shiftKey' in e.nativeEvent?e.nativeEvent.shiftKey as boolean:undefined;
    // e.target.checked ? updateTypeInStore(type,isShift) : updateTypeInStore(null,isShift);
    e.target.checked ? updateTypeInStore(type) : updateTypeInStore(null);
  };

  const checked = useMemo(()=>{
    const find = selectPositions.find(_ => _.id === vor.id);
    if(!find) return undefined
    return find.type === type
  },[selectPositions,vor,type])

  return (
    // <StyledTableCell {...column} style={{ padding: 0, minWidth: '45px', maxWidth: '45px', width: '45px' }} >
    <StyledCheckbox
      key={type||''}
      checked={checked}
      onChange={onChange(type)}
      icon={<RadioButtonUncheckedIcon {...iconProps} />}
      checkedIcon={<CheckCircleIcon {...iconProps} />}
    />
    // </StyledTableCell >
  );
};
