import React from 'react';
import { IconButton, Radio } from '@mui/material';
import { StyledLevel, StyledLevelTd, StyledTypography } from './styles';
import { PositionVOR, UnitType } from '../../../api/calculations/types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveIcon from '@mui/icons-material/Remove';
import { ReactComponent as SvgNotifIcon } from '../../../assets/icons/notifIcon.svg';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import Tooltip from '../../../components/Tooltip';

export interface RowTableProps {
  level: PositionVOR;
  mode?: 'notCheckbox';
  keyWorkId: number | null;
  handleChangeKeyWork: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDelRow: (delId: PositionVOR) => void;
}

const isKilogramOrTonn = (selectUnit: UnitType | undefined, unitType: UnitType | null) => {
  if (selectUnit === 'kilogram' || selectUnit === 'tonn') {
    if (unitType === 'kilogram') {
      return false;
    }
    if (unitType === 'tonn') {
      return false;
    }
  }

  return unitType !== selectUnit;
};

export const RowTable: React.FC<RowTableProps> = ({ level, mode, handleChangeKeyWork, handleDelRow }) => {
  const { changeableFields: fieldState } = useSelector((state: RootState) => state.vor.table);
  const {
    number,
    lsr,
    chapter,
    header,
    code,
    title,
    unit,
    unitType,
    quantity,
    constructionEffort,
    mechanicsEffort,
    totalWorkCost,
    numbering,
  } = level;

  const { target, variant } = useSelector((state: RootState) => state.vor.table);

  const outQuantity = !!quantity
    ? quantity.toLocaleString('ru-RU', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 8,
      })
    : null;

  const outConstructionEffort = !!constructionEffort
    ? constructionEffort.toLocaleString('ru-RU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : null;

  const outMechanicsEffort = !!mechanicsEffort
    ? mechanicsEffort.toLocaleString('ru-RU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : null;

  const outTotalWorkCost = !!totalWorkCost
    ? totalWorkCost.toLocaleString('ru-RU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : null;
  return (
    <StyledLevel direction="row" className="Level-grid">
      <StyledLevelTd style={{ paddingLeft: '9px', justifyContent: 'flex-start' }}>
        {!mode && (
          <Radio
            checked={target?.id === level.id}
            onChange={handleChangeKeyWork}
            value={level.id}
            checkedIcon={<CheckCircleIcon />}
          />
        )}
      </StyledLevelTd>

      <StyledLevelTd>
        <StyledTypography variant="body2">
          {variant === 'grouped' ? numbering : numbering}
          {/* № п/п */}
        </StyledTypography>
      </StyledLevelTd>

      <StyledLevelTd>
        <Tooltip title={lsr ?? ''}>
          <StyledTypography variant="body2" style={{ textAlign: 'start', width: '100%' }}>
            {lsr}
          </StyledTypography>
        </Tooltip>
      </StyledLevelTd>

      <StyledLevelTd sx={{ fontSize: 14 }}>
        {number === 'null' ? '' : number}
        {/* № по ЛСР */}
      </StyledLevelTd>

      <StyledLevelTd>
        <Tooltip title={chapter ?? ''}>
          <StyledTypography
            variant="body2"
            style={{
              textAlign: 'start',
              width: '100%',
            }}>
            {chapter} {/* Раздел */}
          </StyledTypography>
        </Tooltip>
      </StyledLevelTd>

      <StyledLevelTd>
        <StyledTypography variant="body2" style={{ textAlign: 'start', width: '100%' }}>
          {header}
        </StyledTypography>
      </StyledLevelTd>

      <StyledLevelTd>
        <Tooltip title={code ?? ''}>
          <StyledTypography variant="body2">
            {code} {/* Шифр расценки */}
          </StyledTypography>
        </Tooltip>
      </StyledLevelTd>

      <StyledLevelTd>
        <Tooltip title={title ?? ''}>
          <StyledTypography
            variant="body2"
            style={{
              wordWrap: 'break-word',
              textAlign: 'start',
              width: '100%',
            }}>
            {title}
          </StyledTypography>
        </Tooltip>
      </StyledLevelTd>

      <StyledLevelTd style={{ position: 'relative' }}>
        <Tooltip title={unit ?? ''}>
          <StyledTypography variant="body2">{unit}</StyledTypography>
        </Tooltip>
        {!mode &&
          fieldState.selectUnit.value &&
          isKilogramOrTonn(fieldState.selectUnit.value as UnitType, unitType) && (
            <SvgNotifIcon width={13} height={13} style={{ position: 'absolute', right: '0px' }} />
          )}
      </StyledLevelTd>

      <StyledLevelTd>
        <StyledTypography variant="body2">{outQuantity}</StyledTypography>
      </StyledLevelTd>

      <StyledLevelTd>
        <StyledTypography variant="body2" style={{ textAlign: 'end', width: '100%' }}>
          {outConstructionEffort} {/*ТЗ ОР, чел-ч Трудозатраты на строительство */}
        </StyledTypography>
      </StyledLevelTd>

      <StyledLevelTd>
        <StyledTypography variant="body2" style={{ textAlign: 'end', width: '100%' }}>
          {outMechanicsEffort} {/*ТЗМ, чел-ч Трудозатраты механизаторов, человеко-часы */}
        </StyledTypography>
      </StyledLevelTd>

      <StyledLevelTd>
        <StyledTypography variant="body2" style={{ textAlign: 'end', width: '100%' }}>
          {outTotalWorkCost} {/* {'Всего ст-ть'} */}
        </StyledTypography>
      </StyledLevelTd>

      <StyledLevelTd>
        <IconButton
          sx={{
            '&.MuiButtonBase-root': {
              background: 'rgba(255, 177, 177, 0.15)',
              borderRadius: '20px',
              width: '20px',
              height: '20px',
              '&:hover': {
                width: '30px',
                height: '30px',
              },
            },
          }}
          color="error"
          onClick={() => handleDelRow(level)}>
          <RemoveIcon
            sx={{
              width: '20px',
              height: '20px',
            }}
          />
        </IconButton>
      </StyledLevelTd>
    </StyledLevel>
  );
};
