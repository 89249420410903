import * as yup from 'yup'

export const validationСoefficientRow = yup.object({
  firstGen: yup.string().matches(/(?=.*?\d)^\$?(([1-9]\d{0,3}(,\d{3})*)|\d+)?([\.\,]\d{1,3})?$/,`common:${''}${''}`),//.required(`common:${''}${''}`),
  firstSub: yup.string().matches(/(?=.*?\d)^\$?(([1-9]\d{0,3}(,\d{3})*)|\d+)?([\.\,]\d{1,3})?$/,`common:${''}${''}`),//.required(`common:${''}${''}`),
  secondGen: yup.string().matches(/(?=.*?\d)^\$?(([1-9]\d{0,3}(,\d{3})*)|\d+)?([\.\,]\d{1,3})?$/,`common:${''}${''}`),//.required(`common:${''}${''}`),
  secondSub: yup.string().matches(/(?=.*?\d)^\$?(([1-9]\d{0,3}(,\d{3})*)|\d+)?([\.\,]\d{1,3})?$/,`common:${''}${''}`),//.required(`common:${''}${''}`),
  thirdGen: yup.string().matches(/(?=.*?\d)^\$?(([1-9]\d{0,3}(,\d{3})*)|\d+)?([\.\,]\d{1,3})?$/,`common:${''}${''}`),//.required(`common:${''}${''}`),
  thirdSub: yup.string().matches(/(?=.*?\d)^\$?(([1-9]\d{0,3}(,\d{3})*)|\d+)?([\.\,]\d{1,3})?$/,`common:${''}${''}`),//.required(`common:${''}${''}`),
})

