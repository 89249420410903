import { Box } from '@mui/material';
import {
  StyledAnalysis,
  StyledAnalysisTitle,
  StyledAnalysisColumns,
  StyledHourlyCostIncluding,
  StyledContractTd,
  StyledTypographyHeadTd,
} from '../../styles';

export const Analysis = () => {
  return (
    <StyledAnalysis className="Analysis">
      <StyledAnalysisTitle sx={{ background: '#FE9B3F' }}>Анализ</StyledAnalysisTitle>
      <StyledAnalysisColumns className="analysisColumns">
        <StyledHourlyCostIncluding
          style={{ gridArea: 'hourlyCostIncluding', background: '#7890B2' }}
        >
          <Box
            sx={{
              gridArea: 'hourlyCostTitle',
              background: '#7890B2',
              boxShadow: 'inset 1px -1px 0px rgba(0, 36, 95, 0.1)',
            }}
          >
            Отпускная стоимость часа
          </Box>
          <StyledContractTd
            style={{
              gridArea: 'hourlyCostGen',
              background: '#7890B2',
              paddingTop: '6px',
              justifyContent: 'flex-start',
              boxShadow: 'rgb(0 26 77 / 8%) 1px 0px 0px inset',
            }}
          >
            <StyledTypographyHeadTd style={{ height: '32px' }}>ГП</StyledTypographyHeadTd>
          </StyledContractTd>
          <StyledContractTd
            style={{
              gridArea: 'hourlyCostSub',
              background: '#7890B2',
              paddingTop: '6px',
              justifyContent: 'flex-start',
              boxShadow: 'rgb(0 26 77 / 8%) 1px 0px 0px inset',
            }}
          >
            <StyledTypographyHeadTd style={{ height: '32px' }}>СП</StyledTypographyHeadTd>
          </StyledContractTd>
        </StyledHourlyCostIncluding>
        <StyledContractTd
          style={{
            gridArea: 'profit',
            background: '#7890B2',
            boxShadow: 'rgb(0 26 77 / 8%) 1px 0px 0px inset',
          }}
        >
          <StyledTypographyHeadTd>Прибыль</StyledTypographyHeadTd>
        </StyledContractTd>
        <StyledContractTd
          style={{
            gridArea: 'rentability',
            background: '#7890B2',
            boxShadow: 'rgb(0 26 77 / 8%) 1px 0px 0px inset',
          }}
        >
          <StyledTypographyHeadTd>Рентабельность</StyledTypographyHeadTd>
        </StyledContractTd>
      </StyledAnalysisColumns>
    </StyledAnalysis>
  );
};
