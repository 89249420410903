import { Box, IconButton, Menu, MenuItem, Stack, Tooltip } from '@mui/material/';
import { Form, FormikProvider } from 'formik';

import { StyledBodyTdRow, StyledFieldForm, StyledFieldFormMin, StyledTypographyRow } from '../styles';
import { ReactComponent as RowIcon } from '../../../../../../assets/icons/LeveLicon/RowIcon.svg'; // assets/icons/LeveLicon/ FirstLevelicon.svg
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { RowPropsType, useComplexRow } from './useComplexRow';

const ComplexRow: React.FC<RowPropsType> = (props) => {
  const {
    isInput,
    clickAddRow,
    formik,
    formRef,
    titleRef,
    mainSalaryRef,
    mimExploitationRef,
    machineOperatorSalaryRef,
    basicMaterialsRef,
    auxiliaryMaterialsRef,
    equipmentRef,
    overheadsRef,
    estimatedProfitRef,
    anchorEl,
    isMenuRowOpened,
    title,
    outMaterials,
    total,
    mainSalary,
    mimExploitation,
    machineOperatorSalary,
    basicMaterials,
    auxiliaryMaterials,
    equipment,
    overheads,
    estimatedProfit,
    handleFocusOut,
    enterPressHandler,
    handleSetIsInput,
    handleOpenMenuRowClick,
    handleSaveRow,
    handleCopyRow,
    handleClearForm,
    handleDeleteRow,
    handleClose,
    handleSaveAddRow,
    ConfirmDialogConfidence,
  } = useComplexRow(props);

  const { values } = formik;

  /************************************************************************************/

  return (
    <>
      <Box
        className="ComplexRow"
        style={{
          display: 'grid',
          gridTemplateColumns: '100px 1fr',
          gridAutoRows: '50px',
        }}>
        <StyledBodyTdRow colorBgRow={isInput ? 'shades' : 'white'} sx={{ width: '100px' }}>
          <Box
            sx={{
              minWidth: '40px',
              marginRight: '32px',
            }}></Box>
          <IconButton onClick={() => clickAddRow('row')} sx={{ width: 32, height: 32 }} size="small">
            <RowIcon style={{ width: 16, height: 16 }} />
          </IconButton>
        </StyledBodyTdRow>
        <FormikProvider value={formik}>
          <Form
            ref={formRef}
            onBlur={isInput ? handleFocusOut : undefined}
            onKeyDown={enterPressHandler}
            style={{
              display: 'grid',
              gridTemplateColumns: 'minmax(320px,1fr) repeat(10,minmax(140px, 190px)) 30px',
              gridAutoRows: '50px',
            }}>
            <StyledBodyTdRow colorBgRow={isInput ? 'shades' : 'white'} style={{ justifyContent: 'flex-start' }}>
              <Stack
                onDoubleClick={() => handleSetIsInput('title')}
                direction="row"
                sx={{ alignItems: 'center', width: '100%' }}>
                {isInput ? (
                  <StyledFieldForm
                    ref={titleRef}
                    version="calculation"
                    name="title"
                    type="text"
                    placeholder={formik.isValid ? 'Укажите название работ' : 'Обязательно к заполнению'}
                  />
                ) : (
                  <Tooltip title={title}>
                    <StyledTypographyRow style={{ textAlign: 'left' }}>{title}</StyledTypographyRow>
                  </Tooltip>
                )}
              </Stack>
            </StyledBodyTdRow>
            <StyledBodyTdRow
              onDoubleClick={() => handleSetIsInput('mainSalary')}
              colorBgRow={isInput ? 'shades' : 'white'}>
              <Stack direction="row" sx={{ alignItems: 'center', width: '100%' }}>
                <StyledTypographyRow>
                  {total.toLocaleString('ru-RU', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </StyledTypographyRow>
              </Stack>
            </StyledBodyTdRow>
            <StyledBodyTdRow
              colorBgRow={isInput ? 'shades' : 'white'}
              onDoubleClick={() => handleSetIsInput('mainSalary')}
              style={{ justifyContent: 'center' }}>
              <Stack direction="row" sx={{ alignItems: 'center', width: '100%' }}>
                {isInput ? (
                  <StyledFieldFormMin
                    ref={mainSalaryRef}
                    version="calculation"
                    value={values.mainSalary.toLocaleString('ru-RU').replace(/\s/g, '')}
                    name="mainSalary" // основная з/пл
                    placeholder={formik.isValid ? '' : 'Обязательно'}></StyledFieldFormMin>
                ) : (
                  <StyledTypographyRow>
                    {mainSalary.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                )}
              </Stack>
            </StyledBodyTdRow>
            <StyledBodyTdRow
              colorBgRow={isInput ? 'shades' : 'white'}
              onDoubleClick={() => handleSetIsInput('mimExploitation')}
              style={{ boxShadow: 'inset 1px -1px 0px rgba(0, 36, 95, 0.1)' }}>
              <Stack direction="row" sx={{ alignItems: 'center', width: '100%' }}>
                {isInput ? (
                  <StyledFieldFormMin
                    ref={mimExploitationRef}
                    value={values.mimExploitation.toLocaleString('ru-RU').replace(/\s/g, '')}
                    version="calculation"
                    name="mimExploitation" //Эксплуатация Машин&М
                    placeholder={formik.isValid ? '' : 'Обязательно'}></StyledFieldFormMin>
                ) : (
                  <StyledTypographyRow>
                    {mimExploitation.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                )}
              </Stack>
            </StyledBodyTdRow>
            <StyledBodyTdRow
              colorBgRow={isInput ? 'shades' : 'white'}
              onDoubleClick={() => handleSetIsInput('machineOperatorSalary')}>
              <Stack direction="row" sx={{ alignItems: 'center', width: '100%' }}>
                {isInput ? (
                  <StyledFieldFormMin
                    ref={machineOperatorSalaryRef}
                    value={values.machineOperatorSalary.toLocaleString('ru-RU').replace(/\s/g, '')}
                    version="calculation"
                    name="machineOperatorSalary" //Зарплата механизаторов
                    placeholder={formik.isValid ? '' : 'Обязательно'}></StyledFieldFormMin>
                ) : (
                  <StyledTypographyRow>
                    {machineOperatorSalary.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                )}
              </Stack>
            </StyledBodyTdRow>
            <StyledBodyTdRow
              colorBgRow={isInput ? 'shades' : 'white'}
              style={{ boxShadow: 'inset 1px -1px 0px rgba(0, 36, 95, 0.1)' }}
              onDoubleClick={() => handleSetIsInput('materials')}>
              <Stack direction="row" sx={{ alignItems: 'center', width: '100%' }}>
                <StyledTypographyRow>
                  {outMaterials.toLocaleString('ru-RU', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </StyledTypographyRow>
              </Stack>
            </StyledBodyTdRow>
            <StyledBodyTdRow
              colorBgRow={isInput ? 'shades' : 'white'}
              onDoubleClick={() => handleSetIsInput('basicMaterials')}>
              <Stack direction="row" sx={{ alignItems: 'center', width: '100%' }}>
                {isInput ? (
                  <StyledFieldFormMin
                    ref={basicMaterialsRef}
                    value={values.basicMaterials.toLocaleString('ru-RU').replace(/\s/g, '')}
                    version="calculation"
                    name="basicMaterials" //основные материалы
                    placeholder={formik.isValid ? '' : 'Обязательно'}></StyledFieldFormMin>
                ) : (
                  <StyledTypographyRow>
                    {basicMaterials.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                )}
              </Stack>
            </StyledBodyTdRow>
            <StyledBodyTdRow
              colorBgRow={isInput ? 'shades' : 'white'}
              style={{ boxShadow: 'inset -1px -1px 0px rgb(0 36 95 / 10%)' }}
              onDoubleClick={() => handleSetIsInput('auxiliaryMaterials')}>
              <Stack direction="row" sx={{ alignItems: 'center', width: '100%' }}>
                {isInput ? (
                  <StyledFieldFormMin
                    ref={auxiliaryMaterialsRef}
                    value={values.auxiliaryMaterials.toLocaleString('ru-RU').replace(/\s/g, '')}
                    version="calculation"
                    name="auxiliaryMaterials" //Вспомогательные
                    placeholder={formik.isValid ? '' : 'Обязательно'}></StyledFieldFormMin>
                ) : (
                  <StyledTypographyRow>
                    {auxiliaryMaterials.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                )}
              </Stack>
            </StyledBodyTdRow>
            <StyledBodyTdRow
              colorBgRow={isInput ? 'shades' : 'white'}
              onDoubleClick={() => handleSetIsInput('equipment')}>
              <Stack direction="row" sx={{ alignItems: 'center', width: '100%' }}>
                {isInput ? (
                  <StyledFieldFormMin
                    ref={equipmentRef}
                    value={values.equipment.toLocaleString('ru-RU').replace(/\s/g, '')}
                    version="calculation"
                    name="equipment" // Оборудование
                    placeholder={formik.isValid ? '' : 'Обязательно'}></StyledFieldFormMin>
                ) : (
                  <StyledTypographyRow>
                    {equipment.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                )}
              </Stack>
            </StyledBodyTdRow>
            <StyledBodyTdRow
              colorBgRow={isInput ? 'shades' : 'white'}
              onDoubleClick={() => handleSetIsInput('overheads')}>
              <Stack direction="row" sx={{ alignItems: 'center', width: '100%' }}>
                {isInput ? (
                  <StyledFieldFormMin
                    ref={overheadsRef}
                    value={values.overheads.toLocaleString('ru-RU').replace(/\s/g, '')}
                    version="calculation"
                    name="overheads" // Накладные расходы
                    placeholder={formik.isValid ? '' : 'Обязательно'}></StyledFieldFormMin>
                ) : (
                  <StyledTypographyRow>
                    {overheads.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                )}
              </Stack>
            </StyledBodyTdRow>
            <StyledBodyTdRow
              colorBgRow={isInput ? 'shades' : 'white'}
              onDoubleClick={() => handleSetIsInput('estimatedProfit')}>
              <Stack direction="row" sx={{ alignItems: 'center', width: '100%' }}>
                {isInput ? (
                  <StyledFieldFormMin
                    ref={estimatedProfitRef}
                    value={values.estimatedProfit.toLocaleString('ru-RU').replace(/\s/g, '')}
                    version="calculation"
                    name="estimatedProfit" //Сметная прибыль
                    placeholder={formik.isValid ? '' : 'Обязательно'}></StyledFieldFormMin>
                ) : (
                  <StyledTypographyRow>
                    {estimatedProfit.toLocaleString('ru-RU', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </StyledTypographyRow>
                )}
              </Stack>
            </StyledBodyTdRow>
            <StyledBodyTdRow colorBgRow={isInput ? 'shades' : 'white'} style={{ padding: 0, width: 30 }}>
              <IconButton onClick={handleOpenMenuRowClick} sx={{ width: 30, height: 30 }} size="small">
                <MoreVertIcon />
              </IconButton>
            </StyledBodyTdRow>
          </Form>
        </FormikProvider>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl.current}
        open={isMenuRowOpened}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {isInput && <MenuItem onClick={handleSaveRow}>Сохранить </MenuItem>}
        {isInput && <MenuItem onClick={handleSaveAddRow}>Сохранить и доб. статью </MenuItem>}
        {/* {!isInput && <MenuItem onClick={handleCopyRow}>Копировать </MenuItem>} */}
        <MenuItem onClick={handleClearForm}>Очистить </MenuItem>
        <MenuItem onClick={() => handleDeleteRow('Delete')}>Удалить</MenuItem>
      </Menu>
      <ConfirmDialogConfidence />
    </>
  );
};

export default ComplexRow;
