/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import styled from "styled-components";

export const ActTR = styled.tr`
  & th {
    width: 130px;
  }
  & th:first-child {
    text-align: left;
    width: 140px;
  }
`;
export const ActTable = styled.table`
  //max-width: 584px;
  max-width: 712px;
  width: 100%;
  //table-layout: fixed;
  //border-collapse: separate;
  //border-spacing: 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const ActRow = styled.div<{ border?: boolean; paddingTop?: boolean; active?: boolean,isIntegrate?:boolean }>`
  display: grid;
  grid-template-columns: 93.11px 140px 16px 140px 36px ${({ isIntegrate })=>isIntegrate && '164px'};
  padding: ${({ paddingTop }) => (paddingTop ? '8px' : '0')} 52px;
  gap: 16px;

  ${({ border }) => border ? 'border-bottom: 1px solid #D1D8FA;':'border-radius: 8px;'}
  ${({ active, theme }) => active && `box-shadow: 0 0 4px 0px ${theme.palette.primary.main};`}
`;
export const ActCol = styled.div<{
  align?: 'center' | 'flex-start' | 'flex-end';
  colspan?: boolean;  head?: boolean;
  caption?: boolean;
  color?:string
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => align || 'flex-start'};

  ${({ colspan }) => colspan && 'grid-area: auto / auto / auto / span 5;'}
  
  font-family: Roboto;
  font-weight: 400;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-style: normal;

  ${({ head, caption,color }) =>
    head
      ? `
    color: #7890b2;

    font-size: 14px;
    line-height: 140%;
  `
      : caption
      ? `
      color: #7890B2;

      font-size: 12px;
      line-height: normal;
      `
      : `
    color: ${color || '#2B3648'};

    font-size: 14px;
    line-height: normal;
  `}
`;
