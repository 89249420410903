import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import AvatarFieldForm from 'components/AvatarFieldForm';
import { FieldForm } from 'components/FieldForm';
import { AccountFormProps } from './AccountForm.types';
import { useTranslation } from 'react-i18next';
import {
  BASE_USER_MAX_LENGTH,
  COMPANY_NAME_MAX_LENGTH,
  POSITION_USER_MAX_LENGTH,
} from 'components/UserManagement/UserManagement.validation';

export const AccountForm: React.FC<AccountFormProps> = ({ name, isEditUser }) => {
  const { t } = useTranslation('profile');

  return (
    <Box>
      <Stack direction="row" spacing={2.5} mb={2.5}>
        <AvatarFieldForm
          name="avatar"
          needCrop
          isEditUser={isEditUser}
          placeholder={
            <Typography alignItems="center" textAlign="center" px={2} variant="body2">
              {t('common:buttons.avatar')}
            </Typography>
          }
        />
        <Stack flex={1} justifyContent="space-between">
          <FieldForm
            version="profile"
            name="lastName"
            title={t('lastName')}
            maxLength={BASE_USER_MAX_LENGTH}
            placeholder={t('placeholders.lastName')}
            disabled={isEditUser}
          />
          <FieldForm
            version="profile"
            name="firstName"
            title={t('firstName')}
            maxLength={BASE_USER_MAX_LENGTH}
            placeholder={t('placeholders.firstName')}
            disabled={isEditUser}
          />
          <FieldForm
            version="profile"
            name="middleName"
            title={t('middleName')}
            maxLength={BASE_USER_MAX_LENGTH}
            placeholder={t('placeholders.notRequired')}
            disabled={isEditUser}
          />
        </Stack>
      </Stack>
      <Stack spacing={2}>
        <FieldForm
          version="profile"
          name="companyName"
          title={t('companyName')}
          maxLength={COMPANY_NAME_MAX_LENGTH}
          placeholder={(name && `${name} `) + `${t('placeholders.notRequired')}`}
          disabled={isEditUser}
        />
        <FieldForm
          version="profile"
          name="position"
          title={t('position')}
          maxLength={POSITION_USER_MAX_LENGTH}
          placeholder={t('placeholders.position')}
          disabled={isEditUser}
        />
      </Stack>
    </Box>
  );
};
