import { RowType } from 'api/calculations/types';
import _ from 'lodash';
import { FormVisible, GroupForm } from 'pages/FullVor/Table/Table.types';
import { useChildrenIds } from 'pages/FullVor/Table/hooks';
import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { TreeNodeContext } from './TreeNodeContext';
import { LevelIds, SelectedIds, TreeNodeContextProviderProps } from './TreeNodeContext.types';

export const TreeNodeContextProvider: FC<PropsWithChildren<TreeNodeContextProviderProps>> = ({
  rowTitle,
  rowID,
  level,
  rowType,
  rowChildren,
  children,
  levelIds: levelIdsFromProps,
  maxDeep,
}) => {
  const { childrenIds, groupIds } = useChildrenIds(rowChildren);
  const [isExpanded, setIsExpanded] = useState(rowType === 'group' ? true : false);
  const [selectedIds, setSelectedIds] = useState<SelectedIds>({
    childrenIds: [],
    groupIds: [],
  });
  const [formVisible, setFormVisible] = useState<FormVisible>({
    create: false,
    move: false,
  });

  const selectedChildrenCount = selectedIds.childrenIds.length;
  const selectedGroupCount = level === 0 ? 0 : selectedIds.groupIds.length;
  const isAllSelected =
    selectedChildrenCount + selectedGroupCount === childrenIds.length + (level === 0 ? 0 : groupIds.length);
  const indeterminate = !isAllSelected && (selectedChildrenCount !== 0 || selectedGroupCount !== 0);

  const levelIds: LevelIds = useMemo(
    () => ({ ...levelIdsFromProps, ...(rowType === 'group' && { [level]: rowID ?? null }) }),
    [levelIdsFromProps, level, rowID],
  );

  useEffect(() => {
    if (formVisible.move) {
      setIsExpanded(true);
    } else {
      onClearSelectedIds();
    }
  }, [formVisible.move]);

  const onSetSelectedIds = useCallback((selectedIds: number[], rowType: RowType) => {
    if (selectedIds.length === 0) {
      setSelectedIds({
        childrenIds: [],
        groupIds: [],
      });
    } else {
      if (rowType === 'group') {
        setSelectedIds((prev) => ({ ...prev, groupIds: _.xor(prev.groupIds, selectedIds) }));
      }
      if (rowType === 'folded') {
        setSelectedIds((prev) => ({ ...prev, childrenIds: _.xor(prev.childrenIds, selectedIds) }));
      }
    }
  }, []);

  const onSelectAllIds = useCallback(
    ({ childrenIds, groupIds }: SelectedIds) => {
      if (isAllSelected) {
        onClearSelectedIds();
      } else {
        setSelectedIds({ childrenIds, groupIds: level === 0 ? [] : groupIds });
      }
    },
    [isAllSelected],
  );

  const onClearSelectedIds = useCallback(() => {
    setSelectedIds({ childrenIds: [], groupIds: [] });
  }, []);

  const onToggleFormVisible = useCallback((form: GroupForm) => {
    setFormVisible((prev) => ({ ...prev, [form]: !prev[form] }));
  }, []);

  const onToggleExpanded = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  return (
    <TreeNodeContext.Provider
      value={{
        maxDeep,
        rowTitle,
        rowID,
        levelIds,
        level,
        rowType,
        selectedIds,
        childrenIds,
        groupIds,
        formVisible,
        isExpanded,
        isAllSelected,
        indeterminate,
        onToggleFormVisible,
        onToggleExpanded,
        onSetSelectedIds,
        onSelectAllIds,
      }}>
      {children}
    </TreeNodeContext.Provider>
  );
};
