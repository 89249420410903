import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { Form, FormikProvider } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { unitTypeEnToRu } from "types";
import {
  useCreateKeyWorkMutation,
  useDeleteVorMutation,
  useEditVorMutation,
  useGetVorPositionsQuery,
  useLazyGetVorQuery
} from "../../../../api/calculations";
import { PositionVOR, PositionVORWithTarget, UnitType } from "../../../../api/calculations/types";
import CusomSelect from "../../../../components/CustomSelect/CusomSelect";
import { FieldForm } from "../../../../components/FieldForm";
import { useForm } from "../../../../hooks/useForm";
import { useMutationHandlers } from "../../../../hooks/useMutationHandlers";
import { IChangeableSectorWithPayload } from "../../../../store/slices/vor/types";
import {
  changeField,
  changeFieldVisible,
  changeModeVor,
  changeTargetWithChangeFields,
  clearWorks,
  initAddWorks,
  initFields,
  setTarget
} from "../../../../store/slices/vor/vor";
import { RootState, useAppDispatch } from "../../../../store/store";
import { FormContext } from "../ModalVOR";
import { StyledHeader } from "../styles";
import { StyledButton, StyledButtonContainer, StyledContainerForm } from "./styles";
import { validationForm } from "./validation";
import { TFunctionSelectPosition, TSelectPositionArr } from "../../Vor.types";
import useConfirmDialog from "../../../../hooks/useConfirmDialog";

export interface FormModalProps {
  selectPosition:TSelectPositionArr;
  resetFilters:()=>void;
  keyWork: PositionVOR;
  setSelectPosition:TFunctionSelectPosition;
  setKeyWork: (value: PositionVOR) => void;
  mainWorks: PositionVOR[];
  extraWorks: PositionVOR[];
  handleCloseModal: () => void;
  handleOpenUserGuide: () => void;
  setOpenModal: (value: boolean) => void;
  selectedVor: any;
  isEdit: boolean;
  setSelectUnit: (value: UnitType | undefined) => void;
  setSelectedTableVariant: any;
  selectUnit: UnitType | undefined;
  setKeyWorkId: any;
  dispatchVor: (vor: PositionVORWithTarget, cash: boolean) => void;
}

export const unitOptions: { value: UnitType; label: string }[] = [
  { value: 'count', label: 'шт' },
  { value: 'meter', label: 'м' },
  { value: 'square_meter', label: 'м2' },
  { value: 'cubic_meter', label: 'м3' },
  { value: 'kilogram', label: 'кг' },
  { value: 'tonn', label: 'т' },
];

interface ICashEditData {
  title: string;
  unitType: UnitType;
  quantity: number;
  target: number;
  mainArr: number[];
  secondArr: number[];
}

function removeZeros(str: string) {
  if (str.includes(',')) {
    return parseFloat(Number(str.replace(',', '.')).toFixed(6))
      .toString()
      .replace('.', ',');
  }
  return str;
}

export const FormModal: React.FC<FormModalProps> = ({
  setKeyWork,
  dispatchVor,
  mainWorks,
  extraWorks,
  handleCloseModal,
  handleOpenUserGuide,
  setOpenModal,
  selectedVor,
  setSelectedTableVariant,
  selectUnit,
  setSelectUnit,
  isEdit,
  resetFilters,
  setSelectPosition,
  selectPosition
}) => {
  const localTarget = React.useRef<any>(null);

  const {
    target: stateTarget,
    changeableFields: fieldState,
    grouped,
  } = useSelector((state: RootState) => state.vor.table);


  const { calcID: calcIdStr } = useParams<{ calcID: string }>();
  const calcId = Number(calcIdStr);
  const { t } = useTranslation('calculation');
  const { enqueueSnackbar } = useSnackbar();

  const { data } = useGetVorPositionsQuery({ calcId });

  const [errorSelect, setErrorSelect] = React.useState<boolean>(false);
  const savedWorks = React.useRef(0);
  const dispatch = useAppDispatch();
  const [isLoadingSaveKeyWork, setLoadingSaveKeyWork] = React.useState(false);

  const { target, savingData } = useSelector((state: RootState) => state.vor.table);
  const handleDeleteVor = React.useCallback((confirm:boolean)=>{
    if (confirm) {
      deleteVor({ calcId, vorId: selectedVor!.id });
      dispatch(clearWorks());
    }
  },[calcId,selectedVor?.id])

  const {ConfirmDialog,openConfirm} = useConfirmDialog({
      title:'Подтвердить удаление укрупненной расценки?',
      body:'Укрупненная расценка будет удалена безвозвратно.',
      handleConfirm:handleDeleteVor
  })


  const [createKeyWork, createKeyWorkResponse] = useCreateKeyWorkMutation();
  const [editVor, editVorResponse] = useEditVorMutation();
  const [deleteVor, deleteVorResponse] = useDeleteVorMutation();

  useMutationHandlers(createKeyWorkResponse, () => {
    enqueueSnackbar(t('success.saveGrouped'), {
      variant: 'success',
    });
    setSelectUnit(undefined);
    setOpenModal(false);
    setKeyWork({} as PositionVOR);
    resetFilters()
  });

  useMutationHandlers(deleteVorResponse, () => {
    enqueueSnackbar(t('success.deleteGrouped'), {
      variant: 'success',
    });

    setSelectUnit(undefined);
    setOpenModal(false);
    setKeyWork({} as PositionVOR);
    resetFilters()
  });

  useMutationHandlers(editVorResponse, () => {
    enqueueSnackbar(t('success.editGrouped'), {
      variant: 'success',
    });
    resetFilters()

    setSelectUnit(undefined);
    setOpenModal(false);
    setKeyWork({} as PositionVOR);
  });

  /**
   * Заменен хук useGetVorQuery на lazy
   * из-за старого хуку происходили лишние перерисовки и пропадание полезных данных
   *
   * Добавлено локальное состояние currentVor
   */
  const [getVor, { data: vorServer, isLoading: loadingVor }] = useLazyGetVorQuery();
  const [currentVor, setCurrentVor] = useState<typeof vorServer | null>(null);

  useEffect(() => {
    if (isEdit && calcId && selectedVor?.id) {
      getVor({
        calcId,
        vorId: selectedVor?.id,
      }).then((r) => {
        setCurrentVor(r.data);
      });
    }
  }, [calcId, selectedVor?.id, isEdit]);

  // Шифр ЛСР
  const allUniqLsr = React.useMemo(() => {
    let uniqueLsr: string[] = [];
    mainWorks
      .map((item) => item.lsr || '')
      .forEach((element) => {
        if (element && !uniqueLsr.includes(element)) {
          uniqueLsr.push(element);
        }
      });
    return uniqueLsr.join('; ');
  }, [mainWorks, isEdit, currentVor]);

  // Шифр ЛСР
  const summQuantity = React.useCallback(
    (select?: typeof selectUnit) => {
      const currentSelect = select || selectUnit;

      const arr = mainWorks.filter((item: PositionVOR) => {
        if (currentSelect === 'tonn') {
          return item.unitType === currentSelect || item.unitType === 'kilogram';
        }

        if (currentSelect === 'kilogram') {
          return item.unitType === currentSelect || item.unitType === 'tonn';
        }
        return item.unitType === currentSelect;
      });
      let numberValue = arr.reduce((acc, curr) => {
        if (curr.quantity && curr.unitValue) {
          if (curr.unitType === 'tonn' || curr.unitType === 'kilogram') {
            if (currentSelect === 'tonn') {
              if (curr.unitType === 'tonn') {
                acc += +curr.quantity * +curr.unitValue;
              } else if (curr.unitType === 'kilogram') {
                acc += (+curr.quantity * +curr.unitValue) / 1000;
              }
            } else if (currentSelect === 'kilogram') {
              if (curr.unitType === 'tonn') {
                acc += +curr.quantity * +curr.unitValue * 1000;
              } else if (curr.unitType === 'kilogram') {
                acc += +curr.quantity * +curr.unitValue;
              }
            } else {
              acc += +curr.quantity * +curr.unitValue;
            }
          } else {
            acc += +curr.quantity * +curr.unitValue;
          }
        }

        return acc;
      }, 0);
      let returnValue = numberValue.toString();

      if (returnValue.includes('.')) {
        returnValue = String(parseFloat(returnValue)).replace('.', ',');
      }

      return removeZeros(returnValue);
    },
    [selectUnit, target, mainWorks.length],
  );

  // filteredOptios
  const filteredOptions = React.useMemo(() => {
    let uniqueOptions: string[] = [];
    mainWorks
      .map((item) => item.unitType)
      .forEach((element) => {
        if (element && !uniqueOptions.includes(element)) {
          uniqueOptions.push(element);
        }
      });
    return unitOptions.map((_, index) => ({
      id: index + 1,
      name: _.label,
      value: _.value,
    }));
  }, [mainWorks, unitOptions]);

  //  Раздел
  const allUniqChapter = React.useMemo(() => {
    let targetChapter: string | null | undefined;
    if (target) {
      targetChapter = mainWorks.find((item) => item.chapter === target?.chapter)?.chapter;
    }

    let uniqueLsr: string[] = [];
    mainWorks
      .map((item) => item.chapter || '')
      .filter((item) => {
        return (targetChapter && item !== targetChapter) || item !== target?.chapter;
      })
      .forEach((element) => {
        if (element && !uniqueLsr.includes(element)) {
          uniqueLsr.push(element);
        }
      });

    if (targetChapter) {
      const divider = uniqueLsr.length + 1 < 2 ? '' : ';';
      return targetChapter + divider + uniqueLsr.join(';');
    } else {
      return uniqueLsr.join(';');
    }
  }, [mainWorks, target]);

  // Номер по ЛСР
  const allNumberLsr = React.useMemo(() => {
    let targetLsr: any;

    if (target) {
      targetLsr = mainWorks.filter((item) => item.number !== 'null').find((item) => item.number === target?.number);
    }
    console.log(target);
    console.log([...mainWorks, ...extraWorks]);
    let compilingArr: (string | null)[] = Array.from(
      [...mainWorks, ...extraWorks]
        .filter((_) => !!_ && _.id !== target?.id && _.number !== 'null')
        .map((item) => item.number),
    );
    if (targetLsr) {
      compilingArr = [targetLsr.number, ...compilingArr];
    }
    console.log(compilingArr);
    return compilingArr.join(';');
  }, [mainWorks, target]);

  //  Заголовок
  const allUniqHeader = React.useMemo(() => {
    let targetHeader: string | null | undefined;

    if (target) {
      targetHeader = mainWorks.find((item) => item.header === target?.header)?.header;
    }

    let compilingArr: (string | null)[] = Array.from(
      new Set([...mainWorks, ...extraWorks].map((item) => item.header).filter((_) => !!_ && _ !== target?.header)),
    );

    if (targetHeader) {
      compilingArr = [targetHeader, ...compilingArr];
    }
    return compilingArr.join(';');
  }, [mainWorks, target]);

  // Код расценки и коды ресурсов
  const allCode = React.useMemo(() => {
    let targetLsr: string | null | undefined;
    if (target) {
      targetLsr = mainWorks.find((item) => item.code === target?.code)?.code;
    }
    let compilingArr: (string | null)[] = Array.from(
      new Set([...mainWorks, ...extraWorks].map((item) => item.code).filter((_) => !!_ && _ !== target?.code)),
    );

    if (targetLsr) {
      compilingArr = [targetLsr, ...compilingArr];
    }
    return compilingArr.join(';');
  }, [mainWorks, target]);

  // сумма ТЗ ОР чел-час
  const sumConstructionEffort = React.useMemo(() => {
    const mainCount = mainWorks.reduce(
      (accumulator, currentValue) => accumulator + Number((currentValue.constructionEffort || 0).toFixed(2)),
      0,
    );

    const extraCount = extraWorks.reduce(
      (accumulator, currentValue) => accumulator + Number((currentValue.constructionEffort || 0).toFixed(2)),
      0,
    );

    return mainCount + extraCount;
  }, [mainWorks, target]);

  // сумма ТЗМ чел-час
  const sumMechanicsEffort = React.useMemo(() => {
    const mainCount = mainWorks.reduce(
      (accumulator, currentValue) => accumulator + Number((currentValue.mechanicsEffort || 0).toFixed(2)),
      0,
    );

    const extraCount = extraWorks.reduce(
      (accumulator, currentValue) => accumulator + Number((currentValue.mechanicsEffort || 0).toFixed(2)),
      0,
    );

    return mainCount + extraCount;
  }, [mainWorks, target]);

  // сумма Всего стоимость
  const sumTotalWorkCost = React.useMemo(() => {
    const mainCount = mainWorks.reduce(
      (accumulator, currentValue) => accumulator + Number((currentValue.totalWorkCost || 0).toFixed(2)),
      0,
    );

    const extraCount = extraWorks.reduce(
      (accumulator, currentValue) => accumulator + Number((currentValue.totalWorkCost || 0).toFixed(2)),
      0,
    );

    return mainCount + extraCount;
  }, [mainWorks, target]);

  /* Индикатор первоначального рендера */
  const isFirstLoad = useRef<boolean>(true);

  const initialValues = React.useMemo(() => {
    if (isEdit) {
      if (currentVor) {
        const target = currentVor?.rows?.find((item: any) => item.type === 'target');
        if (target && !stateTarget) {
          localTarget.current = target;
        }
        /**
         * Добавил проверку на наличие в стейте данных о основных и второстепенных работах
         * если нет длины ни у первого ни у второго
         * в этом случае диспатчутся данные с сервера в стейт
         */
        if (!grouped.mainWorks.length && !grouped.extraWorks.length) {
          const currentMainWorks = currentVor?.rows?.filter((item: any) => item.type === 'main');
          const currentExtraWorks = currentVor?.rows?.filter((item: any) => item.type === 'secondary');
          dispatch(initAddWorks({ extraWorks: currentExtraWorks, mainWorks: currentMainWorks }));
        }
        const setupInitFields: IChangeableSectorWithPayload = {
          title: { value: currentVor.title },
          quantity: {
            value: String(currentVor.quantity).replace('.', ','),
          },
          selectUnit: { value: currentVor.unitType },
          replaced: false,
        };
        dispatch(initFields(setupInitFields));
      }
    }

    return {
      chapter: currentVor?.chapter || '',
      constructionEffort: sumConstructionEffort || '',
      mechanicsEffort: sumMechanicsEffort || '',
      totalWorkCost: sumTotalWorkCost || '',
    };
  }, [currentVor, isEdit]);

  const { formik } = useForm({
    validationSchema: validationForm,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  const { errors, values, setFieldValue, handleSubmit } = formik;

  const quantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replace(/[^-0-9,]/g, '');
    dispatch(changeFieldVisible({ isEdit: true, field: 'selectUnit' }));
    dispatch(changeField({ value: e.target.value, isEdit: true, field: 'quantity' }));
  };

  const outQuantityNum = parseFloat((fieldState.quantity.value ?? 0).toString().replace(',', '.').replace(' ', ''));

  const onSubmit = React.useCallback(
    (values: any) => {
      const main = mainWorks.map((_) => _.id);
      const secondary = extraWorks.map((_) => _.id);
      if (isEdit) {
        setLoadingSaveKeyWork(true);
        editVor({
          calcId,
          vorId: selectedVor.id,
          body: {
            targetID: target?.id!,
            title: fieldState.title.value,
            code: allCode, // Шифр расценки и коды ресурсов
            chapter: allUniqChapter,
            header: allUniqHeader,
            quantity: outQuantityNum,
            constructionEffort: sumConstructionEffort || 0,
            mechanicsEffort: sumMechanicsEffort || 0,
            totalWorkCost: sumTotalWorkCost || 0,
            lsr: allUniqLsr, // Шифр ЛС
            number: allNumberLsr, // № по ЛСР
            unit: fieldState.selectUnit.value as UnitType,
            main,
            secondary,
          },
        }).then(() => {
          [...mainWorks, ...extraWorks].forEach((_) => {
            dispatchVor(_, true);
          });
          setSelectPosition([],undefined,selectPosition);
          dispatch(clearWorks());
        });
      } else {
        setLoadingSaveKeyWork(true);
        createKeyWork({
          id: calcId,
          targetID: target?.id!,
          title: fieldState.title.value,
          code: allCode, // Шифр расценки и коды ресурсов
          chapter: allUniqChapter,
          header: allUniqHeader,
          quantity: outQuantityNum,
          constructionEffort: values.constructionEffort || 0,
          mechanicsEffort: values.mechanicsEffort || 0,
          totalWorkCost: values.totalWorkCost || 0,
          lsr: allUniqLsr, // Шифр ЛС
          number: allNumberLsr, // № по ЛСР
          unit: fieldState.selectUnit.value as UnitType,
          main: mainWorks.map((item) => item.id),
          secondary: extraWorks.map((item) => item.id),
        }).then(() => {
          dispatch(clearWorks());
          setSelectPosition([],undefined,selectPosition);
        });
      }
      // dispatch(refreshChangeableFieldsState());
      setSelectUnit(undefined);
      isFirstLoad.current = true;
    },
    [
      currentVor,
      selectUnit,
      values,
      calcId,
      target,
      mainWorks,
      extraWorks,
      allCode,
      allNumberLsr,
      allUniqLsr,
      outQuantityNum,
      allUniqHeader,
      allUniqChapter,
      isEdit,
      savingData,
    ],
  );

  const addWorksInList = React.useCallback(() => {
    if (isEdit) {
      const main = mainWorks.map((_) => _.id);
      const secondary = extraWorks.map((_) => _.id);
      setOpenModal(false);
      setSelectedTableVariant('positions');
      setSelectPosition([...mainWorks,...extraWorks],undefined,selectPosition)
      dispatch(
        changeModeVor({
          isEdit: true,
          data: {
            calcId,
            vorId: selectedVor.id,
            body: {
              targetID: target?.id!,
              title: fieldState.title.value,
              code: allCode, // Шифр расценки и коды ресурсов
              chapter: allUniqChapter,
              header: allUniqHeader,
              quantity: outQuantityNum,
              constructionEffort: currentVor?.constructionEffort || 0,
              mechanicsEffort: currentVor?.mechanicsEffort || 0,
              totalWorkCost: currentVor?.totalWorkCost || 0,
              lsr: allUniqLsr, // Шифр ЛС
              number: allNumberLsr, // № по ЛСР
              unit: fieldState.selectUnit.value! as UnitType,
              main,
              secondary,
            },
          },
        }),
      );
    } else {
      const main = mainWorks.map((_) => _.id);
      const secondary = extraWorks.map((_) => _.id);
      setOpenModal(false);
      setSelectedTableVariant('positions');
      dispatch(
        changeModeVor({
          isEdit: false,
          data: {
            calcId,
            vorId: selectedVor.id,
            body: {
              targetID: target?.id!,
              title: fieldState.title.value,
              code: allCode, // Шифр расценки и коды ресурсов
              chapter: allUniqChapter,
              header: allUniqHeader,
              quantity: outQuantityNum,
              constructionEffort: currentVor?.constructionEffort || 0,
              mechanicsEffort: currentVor?.mechanicsEffort || 0,
              totalWorkCost: currentVor?.totalWorkCost || 0,
              lsr: allUniqLsr, // Шифр ЛС
              number: allNumberLsr, // № по ЛСР
              unit: fieldState.selectUnit.value as UnitType,
              main,
              secondary,
            },
          },
        }),
      );
    }
  }, [target, extraWorks, mainWorks, fieldState]);

  /************************************** onKeyDown Enter **********************************/

  const onClickSaveKeyWork = React.useCallback(() => {
    if (!target?.id) {
      enqueueSnackbar(t('common:errors.noSelectedKeyWork'), {
        variant: 'error',
        autoHideDuration: 3000,
      });
      if (!fieldState.selectUnit.value && !target?.unitType) {
        setTimeout(() => {
          enqueueSnackbar(t('common:errors.noUnit'), {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }, 3000);
      }
    } else {
      if ((fieldState.selectUnit.value && target?.unitType) || (isEdit && fieldState.selectUnit.value)) {
        handleSubmit();
      } else {
        setErrorSelect(true);
      }
    }
  }, [errors, extraWorks, mainWorks, target, isEdit, fieldState]);

  const handleChangeSelect = React.useCallback(
    (e: any) => {
      setSelectUnit(e.value as UnitType);
      setFieldValue('quantity', summQuantity(e.value));
      dispatch(changeField({ value: e.value, isEdit: true, field: 'selectUnit' }));
      dispatch(changeField({ value: summQuantity(e.value), isEdit: true, field: 'quantity' }));
    },
    [summQuantity],
  );

  /**
   *
   * isEdit - отвечает за то будет ли меняться значение в поле
   *
   * если true - поле при смене таргета не меняется
   * если false - меняется
   *
   * @param e - событие ввода
   */
  const changeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isEdit = !!e.target.value.trim();
    const value = e.target.value;
    dispatch(changeField({ value, isEdit, field: 'title' }));
    setFieldValue('title', value);
  };

  const currentLoad = useMemo(() => {
    return currentVor && !loadingVor ? currentVor : false;
  }, [currentVor, !loadingVor]);

  /**
   * Если пришли данные с сервера устанавливается таргет
   */
  React.useEffect(() => {
    if (currentLoad) {
      const target = currentLoad?.rows?.find((item: any) => item.type === 'target');
      if (target) {
        if (mainWorks.length) {
          const indexInMainWorks = mainWorks.findIndex((_) => _.id === target?.id);
          indexInMainWorks >= 0 && dispatch(setTarget(target));
        } else {
          dispatch(setTarget(target));
        }
      }
    }
  }, [currentLoad]);

  React.useEffect(() => {
    if (selectUnit) {
      setErrorSelect(false);
    }
  }, [selectUnit]);

  /**
   * Самый интересный эффект ....
   *
   * в начале создается переменная которая будет содержать обновляемые поля в стейте
   *
   * Первое "если" разграничивает по режиму редактирования
   * Если этот режим выключен, обновляются поля по таргету
   *
   * Если это все таки режим редактирования и нет таргета
   * устанавливаются пустые значения
   *
   * Если таргет есть и есть данные с сервера идет разграничение по тому,
   * первая это загрузка после старта компонента или нет
   * если она первая устанавливаются данные с сервера
   * иначе данные с таргета
   *
   * в конце собранная дата для обновления диспатчится
   *
   */
  React.useEffect(() => {
    if (isEdit) {
      if (target) {
        if (currentLoad) {
          if (isFirstLoad.current) {
            isFirstLoad.current = false;
            dispatch(
              changeTargetWithChangeFields([
                { value: currentLoad?.title!, field: 'title' },
                { value: String(currentLoad?.quantity).replace('.', ','), field: 'quantity' },
                { value: currentLoad?.unitType!, field: 'selectUnit' },
              ]),
            );
          } else {
            dispatch(
              changeTargetWithChangeFields([
                { value: target?.title!, field: 'title' },
                { value: summQuantity(target?.unitType!), field: 'quantity' },
                { value: target?.unitType!, field: 'selectUnit' },
              ]),
            );
          }
        }
      } else {
        dispatch(
          changeTargetWithChangeFields([
            { value: '', field: 'title' },
            { value: '', field: 'quantity' },
            { value: '', field: 'selectUnit' },
          ]),
        );
      }
    } else {
      dispatch(
        changeTargetWithChangeFields([
          { value: target?.title!, field: 'title' },
          { value: summQuantity(target?.unitType!), field: 'quantity' },
          { value: target?.unitType!, field: 'selectUnit' },
        ]),
      );
    }
    return () => {
      dispatch(
        changeTargetWithChangeFields([
          { value: '', field: 'title' },
          { value: '', field: 'quantity' },
          { value: '', field: 'selectUnit' },
        ]),
      );
    };
  }, [target, currentLoad, isEdit]);

  /**
   * Если это не первая загрузка компонента
   * при изменении длины mainWorks идет перерасчет количества по unitTyp'у с стора
   */
  React.useEffect(() => {
    if (!isFirstLoad.current && cacheData.current?.mainArr.length !== grouped.mainWorks.length) {
      dispatch(changeField({ field: 'quantity', value: summQuantity(fieldState.selectUnit.value as UnitType) }));
    }
  }, [grouped.mainWorks.length]);

  React.useEffect(() => {
    if (savedWorks.current !== mainWorks.length) {
      savedWorks.current = mainWorks.length;
      selectUnit && setFieldValue('quantity', summQuantity(target?.unitType!));
    }
  }, [mainWorks.length, savedWorks, summQuantity, selectUnit]);

  /**
   * ниже код для обработки закрытия модалки
   *
   * задача: сделать закрытие модалки осмысленным,
   * если у нас ничего не изменилось в режиме редактирования не показывать модалку поддверждения закрытия окна
   * в ином случае показывать
   * для режима создания показывается всегда, так как при закрытии модалки данные стираются и запись в базе не создается
   * о чем нужно предупредить пользователя
   *
   * был создан общий контекст в родителе и специальный локальный стейт, (showed,setShowed) который управляет поддверждением закрытия модалки,
   * и прокинут в контекст, в целом можно было бы прокинуть только функцию изменения локального состояния родителя, но прокинул значение как говорится на всякий случай
   *
   * для сравнения того что было с тем что пользователь изменил, была создана неизменяемая ссылка (Ref),
   * куда положили ключевые значения при отрисовке компонента
   *
   * в дальнейшем при изменении данных в модалке идет проверка старых данных с теми что меняет пользователь и сравнивается,
   * и при закрытиии окна редактирования и если имеются различия, то с помощью функции из контекста меняется состояние родителя и говорит о том что изменения есть,
   * и показывается окно поддверждения закрытия
   *
   * ниже два useEffect'а первый отвечает за первую отрисовку и создания неизменяемой ссылки (Ref), второй как раз сравнивает что и где изменилось
   */

  const { setShowed } = React.useContext(FormContext);

  const cacheData = React.useRef<ICashEditData | null>(null);

  React.useEffect(() => {
    if (isEdit && currentVor) {
      cacheData.current = !cacheData.current
        ? {
            mainArr: currentVor.rows.filter((_: any) => _.type === 'main').map((_: any) => _.id),
            secondArr: currentVor.rows.filter((_: any) => _.type === 'secondary').map((_: any) => _.id),
            quantity: currentVor.quantity,
            target: currentVor.rows.find((_: any) => _.type === 'target')?.id,
            title: currentVor.title,
            unitType: currentVor.unitType,
          }
        : null;
    }
  }, [isEdit, currentVor]);

  React.useEffect(() => {
    if (!isEdit && !mainWorks.length) setOpenModal(false);

    const title = fieldState.title.value;
    const newData: ICashEditData = {
      title,
      target: target?.id!,
      unitType: fieldState.selectUnit.value as UnitType,
      quantity: Number(fieldState.quantity.value.replace(',', '.')),
      mainArr: mainWorks.map((_) => _.id),
      secondArr: extraWorks.map((_) => _.id),
    };

    if (isEdit) {
      if (cacheData.current) {
        let isDifference = false;
        let key: keyof ICashEditData;
        for (key in cacheData.current) {
          const oldItem = cacheData.current[key];
          const newItem = newData[key];
          console.log(oldItem,newItem);
          if (Array.isArray(oldItem) && Array.isArray(newItem)) {
            if(oldItem.length !== newItem.length){
              isDifference = true;
            }else{
              oldItem.forEach((_) => {
                if (!newItem.includes(_)) {
                  isDifference = true;
                }
              });
            }
          } else {
            if (JSON.stringify(oldItem) !== JSON.stringify(newItem)) {
              isDifference = true;
              break;
            }
          }
        }
        setShowed(isDifference);
      }
    } else {
      setShowed(true);
    }
  }, [target, mainWorks.length, extraWorks.length, fieldState, cacheData.current, isEdit, currentVor]);

  return (
    <>
      <StyledHeader>
        <Stack direction="row" spacing={'40px'} sx={{ justifyContent: 'space-between' }}>
          <Button onClick={handleCloseModal} variant="text" type="button" sx={{ fontWeight: 500, fontSize: '13px' }}>
            <span style={{ display: 'flex', marginRight: '8px' }}>
              <ArrowBackIcon sx={{ height: '25px', width: '25px' }} />
            </span>
            Назад
          </Button>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography id="transition-modal-title" variant="h1" component="span" sx={{ color: '#6D9ADC' }}>
              {isEdit ? 'Редактирование укрупненной расценки' : 'Создание укрупненной расценки'}
            </Typography>
          </Box>
        </Stack>
        <Stack flex={1} direction="row" spacing={1.5} sx={{ maxWidth: '228px', height: '36px' }}>
          <IconButton
            sx={{
              '&.MuiButtonBase-root': {
                backgroundColor: 'rgba(25, 118, 210, 0.08)',
                borderRadius: '6px',
              },
            }}
            onClick={handleOpenUserGuide}
            color="primary">
            <HelpOutlineIcon />
          </IconButton>
          <Button
            sx={{ width: '180px' }}
            type="submit"
            color="success"
            onClick={onClickSaveKeyWork}
            disabled={isLoadingSaveKeyWork}>
            Сохранить
          </Button>
        </Stack>
      </StyledHeader>

      <StyledContainerForm>
        <Stack flex={1} direction="row" sx={{ justifyContent: 'space-between', height: '140px' }}>
          <FormikProvider value={formik}>
            <Form>
              <Stack
                direction="row" /* spacing={'20px'} */
                sx={{
                  gap: '20px',
                  flexWrap: 'wrap',
                  height: '140px',
                  alignContent: 'space-between',
                }}>
                <FieldForm
                  version="modal"
                  name="chapter"
                  title={'Раздел'}
                  placeholder={'Нет'}
                  sx={{
                    width: '420px',
                    '& .MuiFilledInput-input': { color: '#2B3648' },
                    '& .MuiFormHelperText-root.Mui-error': {
                      position: 'absolute',
                      top: '33px',
                      color: '#f46b6b',
                    },
                  }}
                  value={allUniqChapter}
                  disabled
                />
                <FieldForm
                  version="modal"
                  onChange={changeTitle}
                  name="title"
                  title={'Наименование укрупненной расценки'}
                  placeholder={'Выберите ключевую работу в списке или укажите наименование'}
                  sx={{
                    width: '758px',
                    '& .MuiFormHelperText-root.Mui-error': {
                      position: 'absolute',
                      top: '33px',
                      color: '#f46b6b',
                    },
                  }}
                  value={fieldState.title.value}
                />

                <FieldForm
                  version="modal"
                  name="numberLSR"
                  title={'Номер по ЛСР'}
                  placeholder={'Нет'}
                  sx={{ width: '200px' }}
                  value={allNumberLsr}
                  disabled
                />

                <FieldForm
                  version="modal"
                  name="header"
                  title={'Заголовок'}
                  placeholder={'Нет'}
                  sx={{
                    width: '200px',
                    '& .MuiFormHelperText-root.Mui-error': {
                      position: 'absolute',
                      top: '33px',
                      color: '#f46b6b',
                    },
                  }}
                  value={allUniqHeader}
                  disabled
                />

                <FieldForm
                  version="modal"
                  name="code"
                  title={'Код расценки'}
                  placeholder={'Нет'}
                  sx={{ width: '200px' }}
                  value={allCode}
                  disabled
                />

                <CusomSelect
                  onSelect={(e) => {
                    handleChangeSelect(e);
                  }}
                  label={'Единица измерения'}
                  selectedOption={unitTypeEnToRu[fieldState.selectUnit.value as keyof typeof unitTypeEnToRu]}
                  placeholder={'Укажите единицу изм.'}
                  options={filteredOptions}
                  error={errorSelect}
                />

                <NumericFormat
                  decimalSeparator={','}
                  decimalScale={6}
                  isAllowed={(values) => {
                    const count = values.formattedValue.includes(',') ? 16 : 14;
                    return values.formattedValue.length <= count;
                  }}
                  customInput={FieldForm}
                  version="modal"
                  name="quantity"
                  title={'Количество'}
                  placeholder={'—'}
                  onChange={quantityChange}
                  onKeyDown={(e) => {
                    if (e.key === ',' && fieldState.quantity.value.includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  sx={{
                    width: '120px',
                    '& .MuiFormHelperText-root.Mui-error': {
                      position: 'absolute',
                      top: '33px',
                      color: '#f46b6b',
                      width: '100%',
                      whiteSpace: 'nowrap',
                      right: '30px',
                    },
                  }}
                  value={fieldState.quantity.value}
                  isTooltip={false}
                />
                <FieldForm
                  version="modal"
                  name="constructionEffort"
                  title={'ТЗ ОР чел-час'}
                  placeholder={'—'}
                  sx={{ width: '120px' }}
                  value={(Number(sumConstructionEffort) || '').toLocaleString('ru-RU', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  disabled
                />
                <FieldForm
                  version="modal"
                  name="mechanicsEffort"
                  title={'ТЗМ чел-час'}
                  placeholder={'—'}
                  sx={{ width: '120px' }}
                  value={(Number(sumMechanicsEffort) || '').toLocaleString('ru-RU', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  disabled
                />
                <FieldForm
                  version="modal"
                  name="totalWorkCost"
                  title={'Всего стоимость'}
                  placeholder={'—'}
                  sx={{ width: '120px' }}
                  value={(Number(sumTotalWorkCost) || '').toLocaleString('ru-RU', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  disabled
                />
              </Stack>
            </Form>
          </FormikProvider>
          <StyledButtonContainer spacing={'12px'} mr={'20px'}>
            {isEdit && (
              <Box sx={{ position: 'relative' }}>
                <StyledButton
                  variant="text"
                  onClick={addWorksInList}
                  startIcon={<AddIcon sx={{ width: '23px', height: '23px' }} />}
                  disabled={!data?.data.length}>
                  Добавить позиции в список
                </StyledButton>
              </Box>
            )}
            {isEdit && (
              <StyledButton
                variant="text"
                onClick={openConfirm}
                startIcon={<DeleteForeverOutlinedIcon sx={{ width: '24px', height: '22px' }} />}>
                Удалить группу позиций
              </StyledButton>
            )}
          </StyledButtonContainer>
        </Stack>
      </StyledContainerForm>
      <ConfirmDialog/>
    </>
  );
};
