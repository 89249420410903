import { Stack } from "@mui/material";
import { useGetProjectDashboardQuery } from "api/projects";
import Progress from "components/Progress";
import { useProjectId } from "hooks/useProjectId";
import { StyledLink, StyledWrapper } from "./Dashboard.styles";
import { ProjectCard, StatsInfoPaper } from "./components";
import React from "react";

const Dashboard: React.FC = () => {
  const projectID = useProjectId();
  const { data, isLoading, isSuccess } = useGetProjectDashboardQuery({ projectID });

  const lsrSum = React.useMemo(() => {
    return data ?
      data.lsr.agreed + data.lsr.not_agreed + data.lsr.in_progress + data.lsr.undefiend + data.lsr.not_selected
      : 0;
  }, [data]);

  const options = React.useMemo(() => {
    if (!data) return [];

    return [
      { title: "Согласовано", value: data.lsr.agreed },
      { title: "Не согласовано", value: data.lsr.not_agreed },
      { title: "В работе", value: data.lsr.in_progress },
      { title: "Не определён", value: data.lsr.undefiend + data.lsr.not_selected }
    ];
  }, [data]);

  return (
    <StyledWrapper>
      {isLoading && <Progress />}
      {isSuccess && (
        <>
          <ProjectCard project={data} />
          <Stack flex={1} direction="row" spacing={2}>
            <StyledLink to={`/projects/${projectID}/lsr`}>
              <StatsInfoPaper
                title="Реестр ЛСР"
                total={lsrSum}
                options={options}
              />
            </StyledLink>
            <StyledLink to={`/projects/${projectID}/calculations`}>
              <StatsInfoPaper title="Расчеты" options={[{ title: "Всего", value: Number(data?.calcCount) }]} />
            </StyledLink>
          </Stack>
        </>
      )}
    </StyledWrapper>
  );
};

export default Dashboard;
