/**
 * @author Mr_FabiozZz[mr.fabiiozzz@gmail.com]
 */
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Button, ClickAwayListener, IconButton } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { IEstimateReference, RowType } from '../../../../../../api/references/estimates/estimates.types';
import {
  StyledButtonGroup,
  StyledDivider,
  StyledPopper,
} from '../../../../../Calculations/components/CalculationBasic/components/styles';
import { BadgeLevel } from '../Level/Level';
import LevelIcon from '../LevelIcon';

const AddLevelBtn: React.FC<{
  level: number | null;
  maxLevel?: number;
  isCreated: boolean;
  remove: () => void;
  isCalc?: boolean;
  type: RowType;
  data: IEstimateReference;
  filteredData?: IEstimateReference[] | null;
  clickAddLevel: (level: number | null, type: RowType) => void;
}> = ({ level, maxLevel = 4, isCalc, remove, clickAddLevel, type, isCreated, filteredData }) => {
  const [anchorElPoper, setAnchorElPoper] = useState<null | HTMLElement>(null);
  const [openPoper, setOpenPoper] = useState(false);
  const id = openPoper ? 'simple-popper' : undefined;

  const handleClickAway = () => {
    console.log('close popover');
    setOpenPoper(false);
  };

  const handleShowButton = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (filteredData) return;
      setAnchorElPoper(event.currentTarget);
      setOpenPoper(true);
    },
    [filteredData, isCreated],
  );

  const handleClickAddLevel = (level: number | null) => {
    setOpenPoper(false);
    clickAddLevel(level, type);
  };

  // const marginRight = useMemo(() => {
  //   switch (level) {
  //     case 1:
  //       return '22px';
  //     case 2:
  //       return '16px';
  //     case 3:
  //       return '12px';
  //     default:
  //       return '0';
  //   }
  // }, []);
  return (
    <>
      <IconButton tabIndex={-1} onClick={handleShowButton} sx={{ width: 32, height: 32 }} size="small">
        {type === 'FOLDER' ? <BadgeLevel>{level}</BadgeLevel> : <LevelIcon levelName={level} type={type} />}
      </IconButton>
      <>
        <StyledPopper
          placement={'right'}
          popperOptions={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 10], // Здесь можно настроить отступ, например, 10 пикселей вниз
                },
              },
            ],
          }}
          id={id}
          open={openPoper}
          anchorEl={anchorElPoper}>
          <ClickAwayListener onClickAway={handleClickAway}>
            <StyledButtonGroup
              variant="text"
              sx={{
                '& .MuiButtonGroup-root': {
                  width: '100%',
                },
                '& button': {
                  border: 'none !important',
                  minWidth: '36px !important',
                },
              }}>
              {/*{level === 4 && <StyledDivider style={{ marginLeft: '10px' }} orientation="vertical" />}*/}
              {type === 'RATE' && isCalc && (
                <Button
                  // onClick={() => level && handleClickAddLevel(level)}
                  onClick={() => {
                    handleClickAddLevel(type === 'RATE' ? -1 : level! - 1);
                    // toggleDrawer({ open: true, createdLevel: level, rowData: data });
                  }}
                  sx={{ height: '100%', marginRight: '0px' }}
                  size="small">
                  <LevelIcon levelName={4} />
                </Button>
              )}
              <Button
                // onClick={() => level && handleClickAddLevel(level)}
                onClick={() => {
                  handleClickAddLevel(type === 'RATE' ? null : level! > maxLevel ? -1 : level);
                  // toggleDrawer({ open: true, createdLevel: level, rowData: data });
                }}
                sx={{ height: '100%', marginRight: '0px' }}
                size="small">
                {isCalc ? (
                  type === 'RATE' ? (
                    <LevelIcon levelName={5} />
                  ) : level! > maxLevel ? (
                    <LevelIcon levelName={4} />
                  ) : (
                    <BadgeLevel>{level}</BadgeLevel>
                  )
                ) : (
                  <LevelIcon levelName={level} />
                )}
              </Button>
              {isCalc
                ? level &&
                  level <= maxLevel &&
                  type === 'FOLDER' && (
                    <>
                      <Button
                        // onClick={() => level && handleClickAddLevel(level + 1)}
                        onClick={() => {
                          if (level) {
                            handleClickAddLevel(level + 1);
                            // toggleDrawer({ open: true, createdLevel: level + 1, rowData: data });
                          }
                        }}
                        style={{
                          height: '100%',

                          marginRight: '0px',
                          border: 'none !important',
                        }}
                        size="small">
                        <BadgeLevel>{level + 1}</BadgeLevel>
                      </Button>
                      {/*{type !== 'RATE' && (*/}
                      {/*  <>*/}
                      <StyledDivider style={{ marginLeft: '10px' }} orientation="vertical" />
                      <Button
                        // onClick={() => level && handleClickAddLevel(level + 1)}
                        onClick={() => {
                          if (level) {
                            handleClickAddLevel(-1);
                            // toggleDrawer({ open: true, createdLevel: 4, rowData: data });
                          }
                        }}
                        sx={{
                          height: '100%',
                          width: 32,
                          marginRight: '0px',
                          border: 'none',
                        }}
                        size="small">
                        <LevelIcon levelName={4} />
                      </Button>
                      {/*  </>*/}
                      {/*)}*/}
                    </>
                  )
                : level &&
                  level <= maxLevel && (
                    <>
                      {/*{level === 3 && <StyledDivider style={{ marginLeft: '10px' }} orientation="vertical" />}*/}
                      <Button
                        // onClick={() => level && handleClickAddLevel(level + 1)}
                        onClick={() => {
                          if (level) {
                            handleClickAddLevel(level + 1);
                            // toggleDrawer({ open: true, createdLevel: level + 1, rowData: data });
                          }
                        }}
                        style={{
                          height: '100%',

                          marginRight: '0px',
                          border: 'none !important',
                        }}
                        size="small">
                        {isCalc ? <BadgeLevel>{level + 1}</BadgeLevel> : <LevelIcon levelName={level + 1} />}
                      </Button>
                      {isCalc ? (
                        <>
                          <StyledDivider style={{ marginLeft: '10px' }} orientation="vertical" />
                          <Button
                            // onClick={() => level && handleClickAddLevel(level + 1)}
                            onClick={() => {
                              if (level) {
                                handleClickAddLevel(-1);
                                // toggleDrawer({ open: true, createdLevel: 4, rowData: data });
                              }
                            }}
                            sx={{
                              height: '100%',
                              width: 32,
                              marginRight: '0px',
                              border: 'none',
                            }}
                            size="small">
                            <LevelIcon levelName={4} />
                          </Button>
                          {type === 'RATE' && (
                            <Button
                              // onClick={() => level && handleClickAddLevel(level + 1)}
                              onClick={() => {
                                if (level) {
                                  handleClickAddLevel(-2);
                                  // toggleDrawer({ open: true, createdLevel: 4, rowData: data });
                                }
                              }}
                              sx={{
                                height: '100%',
                                width: 32,
                                marginRight: '0px',
                                border: 'none',
                              }}
                              size="small">
                              <LevelIcon levelName={5} />
                            </Button>
                          )}
                        </>
                      ) : (
                        level === 3 && (
                          <>
                            {/*<StyledDivider style={{ marginLeft: '10px' }} orientation="vertical" />*/}
                            {/*<Button*/}
                            {/*  // onClick={() => level && handleClickAddLevel(level + 1)}*/}
                            {/*  onClick={() => {*/}
                            {/*    if (level) {*/}
                            {/*      handleClickAddLevel(level + 1);*/}
                            {/*      // toggleDrawer({ open: true, createdLevel: 4, rowData: data });*/}
                            {/*    }*/}
                            {/*  }}*/}
                            {/*  sx={{*/}
                            {/*    height: '100%',*/}
                            {/*    width: 32,*/}
                            {/*    marginRight: '0px',*/}
                            {/*    border: 'none',*/}
                            {/*  }}*/}
                            {/*  size="small">*/}
                            {/*  <LevelIcon levelName={4} />*/}
                            {/*</Button>*/}
                          </>
                        )
                      )}
                      {/*{level === maxLevel && (*/}
                      {/*  <>*/}
                      {/*    <StyledDivider orientation="vertical" />*/}
                      {/*  </>*/}
                      {/*)}*/}
                    </>
                  )}

              <StyledDivider sx={{ marginLeft: '6px !important' }} orientation="vertical" />
              <Box
                ml={'6px'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                  cursor: 'pointer',
                  height: '100%',
                  width: '36px',
                  borderRadius: '0px 6px 6px 0px',
                  svg: {
                    path: {
                      fill: '#5C6E8C',
                      transition: 'fill linear 0.2s',
                    },
                  },
                  transition: 'background linear 0.2s',
                  '&:hover': {
                    path: {
                      fill: 'white',
                    },
                    background: '#F46B6B',
                  },
                }}
                onClick={remove}>
                <DeleteOutlineIcon width={'11.6px'} height={'13.3px'} fontSize={'small'} />
              </Box>
            </StyledButtonGroup>
          </ClickAwayListener>
        </StyledPopper>
      </>
    </>
  );
};

export default AddLevelBtn;
