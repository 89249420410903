import styled from 'styled-components';

export const StyledTable = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`;

export const StyledHeader = styled('div')<{ $headerWidth: string; $headerArea: string; $infoArea: string }>(
  ({ $headerWidth, $headerArea, $infoArea }) => `
  display: grid;
  grid-template-columns: ${$headerWidth};
  grid-template-rows: 55px 44px;
  //grid-area: header;
  grid: auto-flow;
  grid-template-areas:
      "${$headerArea}"
      "${$infoArea}";
  font-size: 12px;
  letter-spacing: 0.1px;
  color: #00358c;
  position:sticky;
  top: 49px;
  background-color: #fff;
  z-index:100;
)`,
);

export const StyledLoading = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const StyledBody = styled('div')`
  //height: calc(100vh - 310px);
`;

export const StyledTd = styled('div')<{ $align: 'left' | 'center' | 'right' }>(
  ({ $align }) => `
  padding: 0 16px 10px 16px;
  display:flex;
  align-items: flex-end;
  justify-content: ${$align};
  line-height: 110%;

  & + & {
    border-left: 1px solid #d2dcff;
  }
`,
);

export const StyledMulticolTd = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  border-left: 1px solid #d2dcff;

  &:nth-last-child {
    border-right: 1px solid #d2dcff;
  }

  & + & {
    border-right: 1px solid #d2dcff;
  }

  div {
    flex: 1;
    display: flex;
    align-self: flex-end;
    padding-bottom: 4px;
    justify-content: center;

    div + div {
      border-left: 1px solid #d2dcff;
    }
  }
`;

export const StyledMulticolTdTop = styled('div')`
  border-bottom: 1px solid #d2dcff;
  align-self: flex-end;
`;
