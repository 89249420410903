import { Box, Button, Tabs, TextField, Tooltip, TooltipProps, Typography, TypographyProps } from '@mui/material'
import styled, { css } from 'styled-components'
import { NumericFormat, NumericFormatProps } from 'react-number-format'
import { BaseType } from 'typescript'
import { CARD_TITLE_SIZES, ICardTitleProps, IControllerInputWrapperProps } from './CellEditor.types';

const BORDER_RADIUS = '10px'

export const OverflowableTypography = styled(Typography)<TypographyProps & { rows?: number }>`
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => props.rows || 1};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    background: inherit;
    font-size: inherit;
    line-height: inherit;
    /* color: inherit; */
`

export const PageWrapper = styled(Box)`
    display: grid;
    grid-template-columns: 450px minmax(900px, 1fr);
    grid-template-rows: 1fr;
    padding: 12px;
    height: 100%;
    width: 100%;
    /* overflow: hidden; */
    overflow-y: hidden;
    overflow-x: auto;
    gap: 12px;
`

export const CardWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    padding: ${(props) => props.theme.spacing(4)};
    background-color: white;
    border-radius: ${(props) => BORDER_RADIUS};
    gap: ${(props) => props.theme.spacing(2)};
    position: relative;
`

export const CardTitle = styled(OverflowableTypography).attrs({ rows: 2 })<ICardTitleProps>`
    font-size: ${(props) => CARD_TITLE_SIZES[props.size]};
`

export const CardItemsGroup = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing(2)};
    border-radius: ${(props) => BORDER_RADIUS};
`

export const StatItemGroup = styled(Box)`
    display: flex;
    justify-content: space-between;
    > * {
        flex: 1 1 50%;
        max-width: 50%;
    }
    > *:first-of-type {
        padding-right: ${(props) => props.theme.spacing(1)};
    }
    > *:last-of-type {
        padding-left: ${(props) => props.theme.spacing(1)};
    }
`

CardTitle.defaultProps = {
  size: 'large',
  color: 'primary',
}

export const SubPageWrapper = styled(Box)`
    gap: 12px;
    display: flex;
    flex-direction: column;
`

export const TabsContainedStyle = css`
    min-height: 30px;
    border-radius: ${(props) => props.theme.spacing(0.5)};
    &.MuiTabs-flexContainer {
        gap: 0 !important;
    }
    & .MuiTabs-indicator {
        display: none;
    }
    & .MuiTab-root {
        ${(props) => ({
  borderTop: `1px solid ${props.theme.palette.primary.main}`,
  borderBottom: `1px solid ${props.theme.palette.primary.main}`,
  borderLeft: `1px solid ${props.theme.palette.primary.main}`,
  ...(props.theme.typography.buttonSmall as { [key: string]: CSSRule }),
  fontSize: 12,
})}
        &:first-of-type {
            border-radius: ${(props) => props.theme.spacing(0.5, 0, 0, 0.5)};
        }
        &:last-of-type {
            border-radius: ${(props) => props.theme.spacing(0, 0.5, 0.5, 0)};
            border-right: 1px solid ${(props) => props.theme.palette.primary.main};
        }
        &.MuiButtonBase-root {
            ${(props) => ({
  color: props.theme.palette.primary.main,
  lineHeight: '20px',
  minHeight: 'auto',
  minWidth: 'auto',
  padding: props.theme.spacing(0.5, 1.5),
})}
        }
        &.Mui-disabled {
            ${(props) => ({
  color: props.theme.palette.disabled,
  borderColor: props.theme.palette.disabled,
})}
        }
        &[aria-selected='true'] {
            &.Mui-disabled {
                background-color: ${(props) => props.theme.palette.secondary.gray};
            }
            background: rgba(25, 118, 210, 0.08);
        }
    }
`

export const TabsContained = styled(Tabs)`
    ${TabsContainedStyle}
`

export const TextTabs = styled(Tabs)`
    min-height: 40px;
    & .MuiButtonBase-root {
        min-height: 40px;
        padding: ${(props) => props.theme.spacing(0.5, 1)};
    }
`

export const StyledBtn = styled(Button)`
    min-width: auto;
    ${({ variant, color, size, theme, startIcon, children }) => {
  let targetStyles = {}
  if (variant == 'outlined' && color == 'secondary') {
    targetStyles = {
      background: 'rgba(25, 118, 210, 0.08)',
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    }
  }
  if (size == 'small') {
  }
  if (startIcon && !children) {
    targetStyles = {
      ...targetStyles,
      minWidth: 'auto',
      '& .MuiButton-startIcon': {
        margin: '0 !important',
      },
    }
  }
  return targetStyles
}}
`

export const StyledNumberFormat = styled((props: NumericFormatProps) => <NumericFormat {...props} />)`
    width: 100%;
    text-align: center;
    border: none;
    background: inherit;
    font-size: inherit;
    color: inherit;
`

StyledNumberFormat.defaultProps = {
  decimalScale: 6,
}

export const StyledTooltip = styled(Tooltip).attrs((props) => {
  return {
    componentsProps: {
      tooltip: {
        sx: {
          backgroundColor: props.color == 'error' ? COLORS.error.color : 'rgb(151, 162, 181,0.85)',
          maxWidth: 300,
          fontSize: '14px',
        },
      },
    },
    PopperProps: {
      disablePortal: true,
    },
    ...props,
  } as TooltipProps
})``

export const ControllerInputWrapper = styled(Box)<IControllerInputWrapperProps>`
    height: 10px !important;
    position: relative;
    width: inherit;
    ::after {
        content: '';
        position: absolute;
        height: 40px !important;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        /* z-index: -1; */
        ${(props) =>
  props.error && {
    background: `${COLORS.error.backgroundColor} !important`,
    boxShadow: `inset 0px -2px 0px 0px ${COLORS.error.color} !important`,
    '> *': {
      color: `${COLORS.error.color} !important`,
    },
  }}
    }
    input {
        padding: 0px 8px !important;
    }
`

export const EditorTextField = styled(TextField)`
    .MuiInputBase-root {
        padding: 0;
        font-size: inherit;
        line-height: inherit;
    }
    input {
        /* padding: 0 !important; */
        width: 100%;
    }
    fieldset {
        border: none;
    }
`

export type TColors = "error" | "warning" | "default"

export const COLORS = {
  error: {
    color: "#F23C3C",
    backgroundColor: "rgba(242, 60, 60, 0.1)"
  },
  warning: {
    color: "#FE9B3F",
    backgroundColor: "rgba(254, 155, 63, 0.1)"
  },
  default: {
    color: "#5C87CE",
    backgroundColor: "rgba(92, 135, 206, 0.1)"
  }
}
export interface IStatValueProps {
  value: number,
  NumericFormatProps ?: NumericFormatProps ,
  TypographyProps?: TypographyProps
}

export interface IPercentStatusValue extends IStatValueProps {
  variant: 'error' | 'warning' | 'default',
}

export const PercentStatusValueWrapper = styled(Box)<Partial<IPercentStatusValue>>`
    padding: ${props => props.theme.spacing(0.5, 1)};
    width: 70px;
    ${props => ({
  ...COLORS[props.variant || 'default']
})};
`
