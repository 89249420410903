import { i18n } from 'i18n';
import { userRolesArray } from 'types';
import { MIN_PASSWORD_LENGTH_VALIDATION } from 'utils/constants';
import * as yup from 'yup';

export const BASE_USER_MAX_LENGTH = 20;
export const POSITION_USER_MAX_LENGTH = 80;
export const COMPANY_NAME_MAX_LENGTH = 80;
export const EMAIL_USER_MAX_LENGTH = 128;
export const LOGIN_USER_MAX_LENGTH = 128;

const commonValidation = {
  lastName: yup
    .string()
    .trim()
    .max(BASE_USER_MAX_LENGTH, i18n.t('common:errors.max', { length: BASE_USER_MAX_LENGTH }))
    .required('common:errors.required'),
  firstName: yup
    .string()
    .trim()
    .max(BASE_USER_MAX_LENGTH, i18n.t('common:errors.max', { length: BASE_USER_MAX_LENGTH }))
    .required('common:errors.required'),
  middleName: yup
    .string()
    .trim()
    .max(BASE_USER_MAX_LENGTH, i18n.t('common:errors.max', { length: BASE_USER_MAX_LENGTH })),
  companyName: yup
    .string()
    .trim()
    .max(COMPANY_NAME_MAX_LENGTH, i18n.t('common:errors.max', { length: COMPANY_NAME_MAX_LENGTH })),
  position: yup
    .string()
    .trim()
    .max(POSITION_USER_MAX_LENGTH, i18n.t('common:errors.max', { length: POSITION_USER_MAX_LENGTH }))
    .required('common:errors.required'),
  phone: yup
    .string()
    .matches(/^\+?7(\d{10})$/, 'common:errors.profile.invalid_phone')
    .required('common:errors.required'),
  email: yup
    .string()
    .email('common:errors.email')
    .max(EMAIL_USER_MAX_LENGTH, i18n.t('common:errors.max', { length: EMAIL_USER_MAX_LENGTH }))
    .required('common:errors.required'),
  login: yup
    .string()
    .trim()
    .max(LOGIN_USER_MAX_LENGTH, i18n.t('common:errors.max', { length: LOGIN_USER_MAX_LENGTH })),
  role: yup.string().oneOf(userRolesArray),
  projects: yup.array(),
};

export const validationProfile = yup.object({
  ...commonValidation,
  password: yup
    .string()
    .min(
      MIN_PASSWORD_LENGTH_VALIDATION,
      i18n.t('common:errors.min', { fieldName: 'пароля', length: MIN_PASSWORD_LENGTH_VALIDATION }),
    )
    .required('common:errors.required'),
});

export const editValidationProfile = yup.object({
  ...commonValidation,
});
