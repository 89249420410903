import { FocusEventHandler, useCallback, useMemo, useRef, useState } from 'react';
import { useMutationHandlers } from '../../../../../../hooks/useMutationHandlers';

import { useSnackbar } from 'notistack';
import useConfirmDialogСonfidence, {
  UseExitConfirmPropsConfidence,
} from '../../../../../../hooks/useConfirmDialogСonfidence';

import { useTranslation } from 'react-i18next';
import { BasePricesItem } from '../../../../../../api/calculations/types';
import {
  useCopyComplexLevelMutation,
  useDeleteComplexRowMutation,
  useEditComplexRowMutation,
} from '../../../../../../api/calculations';

import { RowEditFormData } from './RowEditForm.types';
import { validationTitleEdit as validationEditTitleLevel } from './validation';
import { useForm } from '../../../../../../hooks/useForm';
import { LevelNameType } from '../../../LevelIcon';

export type LevelPropsType = {
  level: BasePricesItem;
  calculationId: number;
  levelNumber: number;
  handleShowFormAdjacent: (isShowFormRowAdd: boolean) => void;
  handleShowFormNested: (isShowFormRowAdd: boolean) => void;
};

export const useComplexLevel = ({
  level,
  handleShowFormAdjacent,
  handleShowFormNested,
  calculationId,
  levelNumber,
}: LevelPropsType) => {
  const {
    id: rowId,
    title,
    mainSalary,
    mimExploitation,
    machineOperatorSalary,
    materials,
    basicMaterials,
    auxiliaryMaterials,
    equipment,
    overheads,
    estimatedProfit,
  } = level;

  const [isAddSave, setIsAddSave] = useState('');

  const levelName: LevelNameType = `level${levelNumber}` as LevelNameType;

  /************************************ Calculate Row  ************************************/
  const outTotal =
    Number(equipment) +
    Number(estimatedProfit) +
    Number(mainSalary) +
    Number(materials) +
    Number(mimExploitation) +
    Number(overheads);

  /*****************************Show/Hide Form Add Nested/вложенный Row****************************************/

  const [isShowFormAddNestedLevel, setIsShowFormAddNestedLevel] = useState(false);
  const [isShowFormAddRow, setIsShowFormAddRow] = useState(false);

  const handleShowFormNestedLevel = useCallback((isShowFormRowAdd: boolean) => {
    setIsShowFormAddNestedLevel(isShowFormRowAdd);
  }, []);

  const handleShowFormAddRow = useCallback((isShowFormAddRow: boolean) => {
    setIsShowFormAddRow(isShowFormAddRow);
  }, []);

  /*****************************Show/Hide Form Add Adjacent/соседний Row****************************************/

  const [isShowFormAddAdjacentLevel, setIsShowFormAddAdjacentLevel] = useState(false);

  const handleShowFormAdjacentLevel = useCallback((isShowFormRowAdd: boolean) => {
    setIsShowFormAddAdjacentLevel(isShowFormRowAdd);
  }, []);

  /************************************************** Show/Hide Nested Row **************************************/
  const [isShowNestedLevel, setIsShowNestedLevel] = useState(true);

  const handleShowNestedLevel = (isShowNestedRow: boolean) => {
    setIsShowNestedLevel(!isShowNestedRow);
  };

  /******************************************* Edit Title Level *******************************************/

  const [editComplexRow, editComplexRowResponse] = useEditComplexRowMutation();

  const [isInput, setIsInput] = useState(title === '');

  const initialValues: RowEditFormData = useMemo(
    () => ({
      title,
      mainSalary,
      mimExploitation,
      machineOperatorSalary,
      materials,
      basicMaterials,
      auxiliaryMaterials,
      equipment,
      overheads,
      estimatedProfit,
      total: Number(outTotal),
    }),
    [], //calculation
  );

  const { formik, isSubmitDisabled } = useForm({
    validationSchema: validationEditTitleLevel,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values: RowEditFormData) => {
      onSubmit(values);
    },
  });

  const onSubmit = useCallback(
    (values: RowEditFormData) => {
      editComplexRow({
        calculationId,
        rowId,
        title: values.title,
        mainSalary,
        mimExploitation,
        machineOperatorSalary,
        materials,
        basicMaterials,
        auxiliaryMaterials,
        equipment,
        overheads,
        estimatedProfit,
        total: Number(outTotal),
        hourlyCostGen: 0,
        hourlyCostSub: 0,
        machinesPerHourGen: 0,
        machinesPerHourSub: 0,
        peoplePerHourGen: 0,
        peoplePerHourSub: 0,
      });
    },
    [], //calculation
  );

  const { handleSubmit } = formik;

  /************************************ Menu Row ************************************/
  const anchorEl = useRef<null | HTMLElement>(null);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('user');

  const handleSaveLevel = () => {
    handleShowFormAdjacent(false);
    handleSubmit();
    anchorEl.current = null;
    setMenuRowOpened(false);
  };

  const handleClose = () => {
    anchorEl.current = null;
    setMenuRowOpened(false);
  };

  const [isMenuRowOpened, setMenuRowOpened] = useState(false);

  const handleOpenMenuRowClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    anchorEl.current = event.currentTarget;
    if (event.currentTarget) setMenuRowOpened(true);
  }, []);

  const handleSaveAddLevel = () => {
    setIsAddSave('SaveAdd');
    handleSubmit();
    anchorEl.current = null;
    setMenuRowOpened(false);
    handleShowFormAdjacent(true);
  };

  useMutationHandlers(editComplexRowResponse, () => {
    setIsInput(false);
    setMenuRowOpened(false);
    if (isAddSave === 'SaveAdd') {
      setIsAddSave('');
      handleShowFormAdjacent(true);
    }
  });

  const handleClearForm = () => {
    setIsInput(true);
    formik.resetForm({
      values: {
        title: '',
        mainSalary,
        mimExploitation,
        machineOperatorSalary,
        materials,
        basicMaterials,
        auxiliaryMaterials,
        equipment,
        overheads,
        estimatedProfit,
        total: Number(outTotal),
      },
    });
    anchorEl.current = null;
    setMenuRowOpened(false);
  };

  /************************** Show Button If **************************/

  const [isShowButtonGroup, setIsShowButtonGroup] = useState(true);
  const [isShowButtonRow, setIsShowButtonRow] = useState(true);

  const handleShowButton = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElPoper(event.currentTarget);
    setOpenPoper(true);
    if (level.children.length > 0) {
      const isRow = level.children.some((rowItem) => rowItem.type === 'row');
      setIsShowButtonRow(isRow);
    }
    if (level.children.length === 0) {
      setIsShowButtonRow(true);
    }
    if (level.children.length > 0) {
      const isGroup = level.children.some((rowItem) => rowItem.type === 'level');
      setIsShowButtonGroup(isGroup);
    }
    if (level.children.length === 0) {
      setIsShowButtonGroup(true);
    }
  };

  /*********************************** Menu Button Level****************************************/

  const [anchorElPoper, setAnchorElPoper] = useState<null | HTMLElement>(null);
  const [openPoper, setOpenPoper] = useState(false);

  const id = openPoper ? 'simple-popper' : undefined;

  const clickAddLevel = useCallback(
    (level: 'adjacent' | 'nested' | 'row') => {
      setOpenPoper(false);
      switch (level) {
        case 'adjacent':
          if (levelNumber === 1) handleShowFormAdjacent(true);
          else handleShowFormNested(true);
          break;
        case 'nested':
          handleShowFormNestedLevel(true); // вложенный
          break;
        case 'row':
          handleShowFormAddRow(true);
          break;
        default:
          break;
      }
    },
    [levelNumber],
  );

  const handleClickAway = () => {
    setOpenPoper(false);
  };

  /************************* Delete Row ******************************/

  const [deleteComplexRow, deleteComplexRowResponse] = useDeleteComplexRowMutation();

  const handleDeleteRowClick = useCallback((actionName: string) => {
    openConfirm(actionName);
  }, []);

  useMutationHandlers(deleteComplexRowResponse, () => {
    enqueueSnackbar(t('success.rowGroupDelete'), {
      variant: 'success',
    });
  });

  /************************************ Copy Level ************************************/

  const [copyLevel, copyLevelResponse] = useCopyComplexLevelMutation();

  const handleCopyLevelClick = () => {
    copyLevel({
      id: calculationId,
      rowId: rowId,
    });
  };

  useMutationHandlers(copyLevelResponse, () => {
    anchorEl.current = null;
    setMenuRowOpened(false);
  });

  // ******* dialog Confirm *******

  const handleConfirmDelete = useCallback((confirm: boolean) => {
    if (confirm) {
      deleteComplexRow({ calculationId, rowId });
    }
  }, []);

  const handleConfirmCopy = useCallback((confirm: boolean) => {
    if (confirm) {
    }
  }, []);

  const dialogTitle = 'Вы действительно хотите удалить статью?';

  const dataForConfirmDialog: UseExitConfirmPropsConfidence = {
    handleConfirmDelete,
    handleConfirmCopy,
    dialogTitle,
  };
  const { ConfirmDialogConfidence, openConfirmConfidence: openConfirm } = useConfirmDialogСonfidence(
    dataForConfirmDialog,
    dialogTitle,
  );

  /************************************** DoubleClick Enter **********************************/

  const doubleClick = () => {
    setIsInput(true);
  };

  /******************************** auto Focus *************************************/
  const titleRef = useRef<HTMLDivElement>(null);

  const handleSetIsInput = (nameInput: string) => {
    setIsInput(true);
    setTimeout(() => {
      if (titleRef.current) {
        if (titleRef.current.querySelector('input')?.name === nameInput)
          titleRef.current.querySelector('input')?.focus();
      }
    }, 1);
  };

  /************************************** Focus Out *******************************************/

  const formRef = useRef<HTMLFormElement>(null);

  const handleFocusOut: FocusEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      // @ts-ignore
      if (event.relatedTarget && formRef.current?.contains(event.relatedTarget)) return;

      if (!isMenuRowOpened) {
        return handleSubmit();
      }
    },
    [isMenuRowOpened],
  );

  return {
    level,
    handleCopyLevelClick,
    handleShowFormAdjacent,
    handleShowFormNested,
    calculationId,
    levelNumber,
    handleFocusOut,
    formRef,
    titleRef,
    levelName,
    isShowNestedLevel,
    handleShowNestedLevel,
    handleShowButton,
    id,
    openPoper,
    anchorElPoper,
    handleClickAway,
    clickAddLevel,
    isShowButtonGroup,
    isShowButtonRow,
    formik,
    doubleClick,
    handleSetIsInput,
    isInput,
    title,
    outTotal,
    mainSalary,
    mimExploitation,
    machineOperatorSalary,
    materials,
    basicMaterials,
    auxiliaryMaterials,
    equipment,
    overheads,
    estimatedProfit,
    handleOpenMenuRowClick,
    anchorEl,
    isMenuRowOpened,
    handleClose,
    handleSaveLevel,
    handleSaveAddLevel,
    handleClearForm,
    handleDeleteRowClick,
    handleShowFormAddRow,
    handleShowFormAdjacentLevel,
    handleShowFormNestedLevel,
    isShowFormAddNestedLevel,
    isShowFormAddRow,
    rowId,
    isShowFormAddAdjacentLevel,
    ConfirmDialogConfidence,
  };
};
