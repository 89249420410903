import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Breadcrumbs as MuiBreadcrumbs, Stack, Typography } from "@mui/material";
import React, { useCallback, useContext } from "react";
import { BreadcrumbsItem, uiContext } from "../../contexts/ui";
import { BreadcrumbLink } from "./styles";

export const Breadcrumbs: React.FC = () => {
  const cUI = useContext(uiContext);

  const getItem = useCallback((item: BreadcrumbsItem, isLast: boolean) => {
    const key = item.title + item.url;

    let element: React.ReactNode;
    switch (item.type) {
      case 'project': {
        element = (
          <Stack
            direction="row"
            alignItems="center"
            onClick={(e: React.MouseEvent<HTMLSpanElement>) => item?.onClick?.(e)}
            key={key}
            style={{
              cursor: 'pointer',
            }}>
            <Typography
              variant="h1"
              component="span"
              style={{
                maxWidth: 200,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}>
              {item.title}
            </Typography>
            {item?.MenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Stack>
        );
        break;
      }
      case 'calculation': {
        element = (
          <Stack
            direction="row"
            alignItems="center"
            onClick={(e: React.MouseEvent<HTMLSpanElement>) => item?.onClick?.(e)}
            key={key}
            style={{
              cursor: 'pointer',
            }}>
            <Typography
              variant="h1"
              component="span"
              style={{
                maxWidth: 200,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}>
              {item.title}
            </Typography>
            {item?.MenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Stack>
        );
        break;
      }

      default: {
        if (isLast || item.url == null) {
          element = (
            <Typography
              variant="h1"
              component="span"
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
              key={key}>
              {item.title}
            </Typography>
          );
          break;
        }

        element = (
          <BreadcrumbLink
            to={[item.url, item.query].filter(Boolean).join('?')}
            key={key}
            style={{
              maxWidth: 200,
            }}>
            <Typography
              variant="h1"
              component="span"
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}>
              {item.title}
            </Typography>
          </BreadcrumbLink>
        );
        break;
      }
    }

    return element;
  }, []);
  
  return (
    <Box className="className-Breadcrumbs" sx={{ marginLeft: '11px' }}>
      <MuiBreadcrumbs>
        {cUI.breadcrumbsItems.map((item, i) => {
          const isLast = cUI.breadcrumbsItems.length === i + 1;
          return getItem(item, isLast);
        })}
      </MuiBreadcrumbs>
    </Box>
  );
};

export default Breadcrumbs;
