import { SnackbarCloseReason as MUISnackbarCloseReason } from '@mui/material/Snackbar/Snackbar';
import { config } from '../core/config';

export const LINK_TO_CORE = config.coreURL;
//export const LINK_TO_CORE = 'https://dev.core.pragma.info'
//export const LINK_TO_CORE = 'http://localhost:3000'

export const DATE_FORMAT = 'dd.MM.yyyy';

export const CODE_LENGTH = 6;
export const MIN_PASSWORD_LENGTH_VALIDATION = 6;
export const DEFAULT_DISPLAY_PASSWORD_VALUE = '';

export const MAX_WIDTH_USER_MANAGEMENT_BLOCK = '476px';
export const MIN_WIDTH_USER_MANAGEMENT_BLOCK = '396px';

export const NUMBER_OF_ROWS_PER_USERS_PAGE = [
  { value: 100, title: '100' },
  { value: 50, title: '50' },
  { value: 25, title: '25' },
];

export const NUMBER_OF_ROWS_PER_RD_PAGE = [...NUMBER_OF_ROWS_PER_USERS_PAGE];

export const ERRORS_PHONE = ['company.user_creation.invalid_phone', 'profile.invalid_phone'];

export const ERRORS_COINCIDENCE = {
  'company.user_creation.phone_taken': 'phone' as const,
  'company.user_creation.email_taken': 'email' as const,
  'company.user_creation.login_taken': 'login' as const,
  'profile.phone_taken': 'phone' as const,
  'profile.email_taken': 'email' as const,
  'profile.login_taken': 'login' as const,
};

export type SnackbarCloseReason = 'click' | MUISnackbarCloseReason;

export const XXL_FOR_LAYOUT = '2300px';

export const dadataUrl = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs';
export const dadataToken = '74de3eea4ab677c0251ff914f42d32cd01800ca0';
export const dadataSecret = '9781f3b99c4f14b4a4414bc9c886bba45a68264f';
export const googleKey = 'AIzaSyDU_CJGCtatX5LY7gFYoitl15tqcrp2Ujg';
