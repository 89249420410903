/**
 * @author Mr_FabiozZz[fabiozzz.dev@gmail.com]
 */
import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { IEstimateReference } from "../../../../../../../api/references/estimates/estimates.types";
import { ICalculationDictionary, useInsertRateMutation } from "../../../../../../../api/calculationDictionary";
import { useCalcId } from "../../../../../../../hooks/useCalcId";
import { useMutationHandlers } from "../../../../../../../hooks/useMutationHandlers";
import { Badge } from "../../../Parameters/Parameters.style";
import { Box, Typography } from "@mui/material";
import Tooltip from "../../../../../../../components/Tooltip";
import Button from "../../../../../../../components/Button";
import Arrow from "@mui/icons-material/ArrowDropUpOutlined";
import { CalculationDirectoryContext } from "../../../CalculationDirectory";
import ListReference from "./ListReference";
import styled from "styled-components";

const RowReferenceWrapper = styled(Box)`
    height: 50px;
    padding: 5px 10px;
    &:hover{
        background: ${({theme})=>theme.palette.bgRow.gray};
    }
`

const RowReference: React.FC<{ row: IEstimateReference; defaultOpenRow: boolean; isRoot: boolean }> = memo(
  ({ row, defaultOpenRow, isRoot }) => {
    const [open, setOpen] = useState(defaultOpenRow);

    const [showingSuccess, setShowingSuccess] = useState(false);

    const { selectRow, tableData,setSelectRow } = useContext(CalculationDirectoryContext);

    const [insert, { data, error, isLoading }] = useInsertRateMutation();

    const calcID = useCalcId();

    useMutationHandlers(
      { data, error },
      (response) => {
        console.log(response);
      },
      (errorResponse) => {
        console.log(errorResponse);
      },
    );

    const insertRate = useCallback(
      (rowData: ICalculationDictionary, handbkRateID: number) => {
        let folderID: undefined | number;
        if (rowData.rowType === 'FOLDER') {
          folderID = rowData.id;
        } else {
          const f = tableData?.find((el) => el.id === rowData.parentID);
          if (f) insertRate(f, handbkRateID);
          return;
        }
        insert({ calcID, folderID, handbkRateID,insertAfterID:selectRow?.rowType === 'FOLDER'?null:selectRow?.id??null }).then(response=>{
          if ("data" in response) {
            setSelectRow(response.data);
          }
        });
        // let folderID: undefined | number;
        // let insertAfterID: number | undefined;
        // if (rowData.rowType === 'FOLDER') {
        //   folderID = rowData.id;
        // } else {
        // function getFolder(id:number|null) {
        //   const f = tableData?.find((el) => el.id === id);
        //   if (f) {
        //     if (f.rowType === "FOLDER") {
        //       return f;
        //     }else{
        //       getFolder(f.parentID);
        //     }
        //   }else{
        //     return null;
        //   }
        // }
        //
        // const folderID = getFolder(rowData.id)?.id!;
        //   if (f)
        //     insertRate(rowData, handbkRateID);
        //   return;
        // }
        // insert({ calcID, folderID,insertAfterID:selectRow?.rowType === 'FOLDER'?null:selectRow?.rowType === 'RATE_POSITION'?selectRow?.parentID??null:selectRow?.id??null, handbkRateID }).then(response=>{
        //   if ("data" in response) {
        //     setSelectRow(response.data);
        //   }
        // });
      },
      [calcID, tableData, selectRow],
    );

    const LvlIcon = useMemo(() => {
      switch (row.rowType) {
        case 'RATE':
          return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.5556 4H5.77778C4.8 4 4 4.8 4 5.77778V18.2222C4 19.2 4.8 20 5.77778 20H18.2222C19.2 20 20 19.2 20 18.2222V8.44444L15.5556 4ZM7.55556 7.55556H12V9.33333H7.55556V7.55556ZM16.4444 16.4444H7.55556V14.6667H16.4444V16.4444ZM16.4444 12.8889H7.55556V11.1111H16.4444V12.8889ZM14.6667 9.33333V5.77778L18.2222 9.33333H14.6667Z"
                fill="#7890B2"
              />
            </svg>
          );
        case 'FOLDER':
          return <Badge>УР {row.lvl}</Badge>;
        default:
          return <div style={{ width: '14px' }} />;
      }
    }, [row]);

    useEffect(() => {
      if (isLoading) {
        setShowingSuccess(true);
        setTimeout(() => {
          setShowingSuccess(false);
        }, 1000);
      }
    }, [isLoading]);

    return (
      <React.Fragment>
      <RowReferenceWrapper display={'grid'} gridTemplateColumns={'10fr 1fr 1fr 2fr 2fr 2fr 2fr 3fr 1fr'}
            sx={{cursor:row.children?.length?'pointer':'default'}}
           onClick={() => {
             if (!row.children.length) return;
             setOpen((prevState) => !prevState);
           }}>
        <Box
          paddingLeft={`${
            row.lvl! > 1 ? 12 * (row.lvl! - 1) + (row.rowType === 'RATE' || !row.children.length ? 10 : 0) : 0
            // row.lvl! > 1 ? 12 * (row.lvl! - 1) + 10 : 0
          }px`}
          overflow={'hidden'}
          gap={'10px'}
          display={'flex'}
          alignItems={'center'}>
          <Box display={'flex'} whiteSpace={'nowrap'} alignItems={'center'} gap={'4px'}>
            <Box
              sx={{ cursor: row.children.length ? 'pointer' : 'default' }}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}

              // mt={'auto'}
            >
              {!!row.children.length ? (
                <Arrow sx={{ transition: 'rotate', rotate: open ? '180deg' : '0deg' }} color={'primary'} />
              ):<div style={{width:'23.98px'}}/>}
            </Box>

            {LvlIcon}
          </Box>
          <Tooltip title={row.title}>
            <Typography
              sx={{ margin: 'auto 0' }}
              whiteSpace={'nowrap'}
              variant={'body2'}
              overflow={'hidden'}
              textOverflow={'ellipsis'}
              color={'#5C6E8C'}
              fontWeight={'500'}>
              {row.title}
            </Typography>
          </Tooltip>
          {/*{row.title}*/}
        </Box>
        <Typography
          sx={{ margin: 'auto 0' }}
          whiteSpace={'nowrap'}
          variant={'body2'}
          color={'#2B3648'}
          fontWeight={'500'}>
          {row.unit}
        </Typography>
        <Typography
          sx={{ margin: 'auto 0' }}
          whiteSpace={'nowrap'}
          variant={'body2'}
          color={'#2B3648'}
          fontWeight={'500'}>
          {row.qty}
        </Typography>
        <Typography
          sx={{ margin: 'auto 0' }}
          whiteSpace={'nowrap'}
          variant={'body2'}
          color={'#2B3648'}
          fontWeight={'500'}>
          {row.pz}
        </Typography>
        <Typography
          sx={{ margin: 'auto 0' }}
          whiteSpace={'nowrap'}
          variant={'body2'}
          color={'#2B3648'}
          fontWeight={'500'}>
          {row.fot}
        </Typography>
        <Typography
          sx={{ margin: 'auto 0' }}
          whiteSpace={'nowrap'}
          variant={'body2'}
          color={'#2B3648'}
          fontWeight={'500'}>
          {row.mim}
        </Typography>
        <Typography
          sx={{ margin: 'auto 0' }}
          whiteSpace={'nowrap'}
          variant={'body2'}
          color={'#2B3648'}
          fontWeight={'500'}>
          {row.mtr}
        </Typography>
        <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
          {row.rowType === 'RATE' ? (
            showingSuccess ? (
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="36" height="36" rx="6" fill="#8DD4C8" fillOpacity="0.15" />
                <path
                  d="M15.5 21.475L12.025 18L10.8417 19.175L15.5 23.8333L25.5 13.8333L24.325 12.6583L15.5 21.475Z"
                  fill="#2E7D32"
                />
              </svg>
            ) : (
              <Button
                onClick={(event) => {
                  event.stopPropagation()
                  insertRate(selectRow!, row.id);
                }}
                sx={{ padding: '8px !important', minWidth: '36px' }}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.00004 1.66663H11.6667L16.6667 6.66663V9.99996H15V7.49996H10.8334V3.33329H5.00004V16.6666H8.33337V18.3333H4.99171C4.07504 18.3333 3.33337 17.5833 3.33337 16.6666L3.34171 3.33329C3.34171 2.41663 4.08337 1.66663 5.00004 1.66663Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.5 15.8333V18.3333H14.1667V15.8333H16.6667V14.1666H14.1667V11.6666H12.5V14.1666H10V15.8333H12.5Z"
                    fill="white"
                  />
                </svg>
              </Button>
            )
          ) : null}
        </Box>
      </RowReferenceWrapper>
        {open && <ListReference table={row.children} defaultOpenRow={defaultOpenRow} isRoot={false} />}
      </React.Fragment>
    );
  },
);

RowReference.displayName = 'RowReference';

export default RowReference;
