import styled from 'styled-components'
import { Stack } from '@mui/material'

export const SelectedProjectItem = styled(Stack)`
  justify-content: center;
  width: 100%;
  height: 44px;
  padding: 0 12px;
  background-color: ${props => props.theme.palette.bg.shades};
  border-radius: 6px;
  
  & .MuiTypography-root {
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`
