import { FocusEventHandler, Fragment, useCallback, useMemo, useRef, useState } from 'react';
import { useMutationHandlers } from '../../../../../../hooks/useMutationHandlers';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSnackbar } from 'notistack';
import useConfirmDialogСonfidence, {
  UseExitConfirmPropsConfidence,
} from '../../../../../../hooks/useConfirmDialogСonfidence';
import { useTranslation } from 'react-i18next';
import { BasicLevelType } from '../../../../../../api/calculations/types';
import {
  useCopyBasicLevelMutation,
  useDeleteRowMutation,
  useEditTitleLevelMutation,
} from '../../../../../../api/calculations';
import { Box, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { Form, FormikProvider } from 'formik';
import { RowEditFormData } from './RowEditForm.types';
import { useForm } from '../../../../../../hooks/useForm';
import { StyledFieldForm, StyledIconButton, StyledLevelTd, StyledTypographyLevel } from '../styles';
import { theme } from '../../../../../../styles/theme';

import FormAddLevel from '../FormAddLevel';
import BasicRow from '../BasicRow';
import FormAddRow from '../FormAddRow';
import { LevelNameType } from '../../../../../Calculations/components/LevelIcon';
import { AddLevelButtons } from '../../../AddLevelButtons/AddLevelButtons';
import { validationTitleEditRow } from '../BasicRow/validation';

export type LevelPropsType = {
  level: BasicLevelType;
  calculationId: number;
  levelNumber: number;
  handleShowFormAdjacent: (isShowFormRowAdd: boolean) => void;
  handleShowFormNested: (isShowFormRowAdd: boolean) => void;
};

const BasicLevel: React.FC<LevelPropsType> = ({
  level,
  handleShowFormAdjacent,
  handleShowFormNested,
  calculationId,
  levelNumber,
}) => {
  const { id: rowId, price, title, parent: parentId } = level;

  const levelName: LevelNameType = `level${levelNumber}` as LevelNameType;

  const [isAddSave, setIsAddSave] = useState('');

  const colorText = theme.palette.text;

  /*****************************Show/Hide Form Add Nested/вложенный Row****************************************/

  const [isShowFormAddNestedLevel, setIsShowFormAddNestedLevel] = useState(false);
  const [isShowFormAddRow, setIsShowFormAddRow] = useState(false);

  const handleShowFormNestedLevel = useCallback((isShowFormRowAdd: boolean) => {
    setIsShowFormAddNestedLevel(isShowFormRowAdd);
  }, []);

  const handleShowFormAddRow = useCallback((isShowFormAddRow: boolean) => {
    setIsShowFormAddRow(isShowFormAddRow);
  }, []);

  /*****************************Show/Hide Form Add Adjacent/соседний Row****************************************/

  const [isShowFormAddAdjacentLevel, setIsShowFormAddAdjacentLevel] = useState(false);

  const handleShowFormAdjacentLevel = useCallback((isShowFormRowAdd: boolean) => {
    setIsShowFormAddAdjacentLevel(isShowFormRowAdd);
  }, []);

  /************************************ Show/Hide Nested Row ************************************/
  const [isShowNestedLevel, setIsShowNestedRow] = useState(true);

  const handleShowNestedLevel = (isShowNestedRow: boolean) => {
    setIsShowNestedRow(!isShowNestedRow);
  };

  /***************** Edit Title Level ******************************/

  const [editTitleLevel, editTitleLevelResponse] = useEditTitleLevelMutation();

  const [isInput, setIsInput] = useState(title === '');

  const initialValues: RowEditFormData = useMemo(
    () => ({
      title: title,
    }),
    [], //calculation
  );

  const { formik, isSubmitDisabled } = useForm({
    validationSchema: validationTitleEditRow,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values: RowEditFormData) => {
      onSubmit(values);
    },
  });

  const { handleSubmit } = formik;

  const onSubmit = useCallback(
    (values: RowEditFormData) => {
      editTitleLevel({ calculationId, rowId, title: values.title });
    },
    [], //calculation
  );

  /************************************ Menu Row ************************************/
  const anchorEl = useRef<null | HTMLElement>(null);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('user');

  const handleClose = () => {
    anchorEl.current = null;
    setMenuRowOpened(false);
  };

  const [isMenuRowOpened, setMenuRowOpened] = useState(false);

  const handleOpenMenuRowClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    anchorEl.current = event.currentTarget;
    if (event.currentTarget) setMenuRowOpened(true);
  }, []);

  const handleSaveLevel = () => {
    handleShowFormAdjacent(false);
    handleSubmit();
    anchorEl.current = null;
    setMenuRowOpened(false);
  };

  const handleSaveAddLevel = () => {
    setIsAddSave('SaveAdd');
    handleSubmit();
    anchorEl.current = null;
    setMenuRowOpened(false);
  };

  useMutationHandlers(editTitleLevelResponse, () => {
    setIsInput(false);
    setMenuRowOpened(false);
    if (isAddSave === 'SaveAdd') {
      handleShowFormAdjacent(true);
      setIsAddSave('');
    }
  });

  const handleClearForm = () => {
    setIsInput(true);
    formik.resetForm({
      values: { title: '' },
    });
    anchorEl.current = null;
    setMenuRowOpened(false);
  };

  const clickAddLevel = useCallback(
    (level: 'adjacent' | 'nested' | 'row') => {
      switch (level) {
        case 'adjacent':
          if (levelNumber === 1) handleShowFormAdjacent(true);
          else handleShowFormNested(true);
          break;
        case 'nested':
          handleShowFormNestedLevel(true); // вложенный
          break;
        case 'row':
          handleShowFormAddRow(true);
          break;
        default:
          break;
      }
    },
    [levelNumber],
  );

  /************************* Delete Row ******************************/

  const [deleteRow, deleteRowResponse] = useDeleteRowMutation();

  const handleDeleteRowClick = useCallback((actionName: string) => {
    openConfirm(actionName);
  }, []);

  useMutationHandlers(deleteRowResponse, () => {
    enqueueSnackbar(t('success.rowGroupDelete'), {
      variant: 'success',
    });
  });

  /************************************ Copy Level ************************************/

  const [copyLevel, copyLevelResponse] = useCopyBasicLevelMutation();

  const handleCopyLevel = () => {
    copyLevel({
      id: calculationId,
      rowId: rowId,
    });
  };

  useMutationHandlers(copyLevelResponse, () => {
    setMenuRowOpened(false);
  });

  // ******* dialog Confirm *******

  const handleConfirmDelete = useCallback((confirm: boolean) => {
    if (confirm) {
      deleteRow({ calculationId, rowId });
    }
  }, []);

  const handleConfirmCopy = useCallback((confirm: boolean) => {
    if (confirm) {
    }
  }, []);

  const dialogTitle = 'Вы действительно хотите удалить статью?';

  const dataForConfirmDialog: UseExitConfirmPropsConfidence = {
    handleConfirmDelete,
    handleConfirmCopy,
    dialogTitle,
  };
  const { ConfirmDialogConfidence, openConfirmConfidence: openConfirm } = useConfirmDialogСonfidence(
    dataForConfirmDialog,
    dialogTitle,
  );

  /******************************** auto Focus *************************************/
  const titleRef = useRef<HTMLDivElement>(null);

  const handleSetIsInput = (nameInput: string) => {
    setIsInput(true);
    setTimeout(() => {
      if (titleRef.current) {
        if (titleRef.current.querySelector('input')?.name === nameInput)
          titleRef.current.querySelector('input')?.focus();
      }
    }, 1);
  };

  /******************************** Focus Out *************************************/

  const formRef = useRef<HTMLFormElement>(null);

  const handleFocusOut: FocusEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      // @ts-ignore
      if (event.relatedTarget && formRef.current?.contains(event.relatedTarget)) return;
      if (!isMenuRowOpened) {
        return handleSubmit();
      }
    },
    [isMenuRowOpened],
  );

  /******************************** Filtered Level/Row *************************************/
  const filteredLevelArr = useMemo(() => {
    if (level.type === 'level') {
      return level.children?.filter((row) => {
        return row.type === 'level';
      });
    }
  }, [level]);

  const filteredRowArr = useMemo(() => {
    if (level.type === 'level') {
      return level.children?.filter((row) => {
        return row.type === 'row';
      });
    }
  }, [level]);

  /************************************************************************/

  return (
    <>
      <Box
        className={`Level-${levelNumber}`}
        style={{
          display: 'grid',
          gridTemplateColumns: '100px 1fr',
          gridAutoRows: '50px',
        }}>
        <StyledLevelTd
          colorsBgLevel={levelName}
          style={{
            paddingLeft: '9px',
            justifyContent: 'flex-start',
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginRight: `${8 + (levelNumber - 1) * 6}px`,
              minWidth: 24,
            }}>
            {level.type === 'level' && level.children.length > 0 && (
              <StyledIconButton onClick={() => handleShowNestedLevel(isShowNestedLevel)} size="small">
                {isShowNestedLevel ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </StyledIconButton>
            )}
          </Box>
          <AddLevelButtons levelNumber={levelNumber} levelName={levelName} clickAddLevel={clickAddLevel} />
        </StyledLevelTd>
        <FormikProvider value={formik}>
          <Form
            onBlur={isInput ? handleFocusOut : undefined}
            style={{
              display: 'grid',
              gridTemplateColumns: 'minmax(206px, 1fr) repeat(4, minmax(135px, 206px)) 30px',
              gridAutoRows: '50px',
            }}
            ref={formRef}>
            <StyledLevelTd
              colorsBgLevel={levelName}
              onDoubleClick={() => handleSetIsInput('title')}
              style={{ justifyContent: 'flex-start' }}>
              {isInput ? (
                <StyledFieldForm
                  ref={titleRef}
                  version="calculation"
                  name="title"
                  placeholder={
                    formik.isValid ? 'Укажите название работ' : 'Поле обязательно к заполнению'
                  }></StyledFieldForm>
              ) : (
                <Tooltip title={title}>
                  <StyledTypographyLevel
                    style={{
                      color: `${levelName === 'level1' ? colorText.main : colorText.dark}`,
                    }}>
                    {title}
                  </StyledTypographyLevel>
                </Tooltip>
              )}
            </StyledLevelTd>
            <StyledLevelTd colorsBgLevel={levelName}></StyledLevelTd>
            <StyledLevelTd colorsBgLevel={levelName}></StyledLevelTd>
            <StyledLevelTd colorsBgLevel={levelName}></StyledLevelTd>
            <StyledLevelTd
              colorsBgLevel={levelName}
              style={{
                color: `${levelName === 'level1' ? colorText.main : colorText.gray}`,
                justifyContent: 'flex-end',
                fontWeight: 600,
              }}>
              {price.toLocaleString('ru-RU', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </StyledLevelTd>
            <StyledLevelTd colorsBgLevel={levelName} style={{ padding: 0, width: 30 }}>
              <IconButton onClick={handleOpenMenuRowClick} sx={{ width: 30, height: 30 }} size="small">
                <MoreVertIcon />
              </IconButton>
            </StyledLevelTd>
          </Form>
        </FormikProvider>
      </Box>
      {/***************************** level 2 *****************************/}
      {isShowNestedLevel && (
        <>
          {filteredRowArr?.map((rowLevelNext) => {
            return (
              <Fragment key={rowLevelNext.id}>
                {rowLevelNext.type === 'row' && (
                  <BasicRow
                    key={rowLevelNext.id}
                    row={rowLevelNext}
                    calculationId={calculationId}
                    handleShowFormAddRow={handleShowFormAddRow}></BasicRow>
                )}
              </Fragment>
            );
          })}
          {isShowFormAddRow && (
            <FormAddRow
              handleShowFormAddRow={handleShowFormAddRow}
              calculationId={calculationId}
              parentId={rowId}></FormAddRow>
          )}
          {filteredLevelArr?.map((rowLevelNext) => {
            return (
              <Fragment key={rowLevelNext.id}>
                {rowLevelNext.type === 'level' && (
                  <BasicLevel
                    key={rowLevelNext.id}
                    level={rowLevelNext}
                    calculationId={calculationId}
                    levelNumber={levelNumber + 1}
                    handleShowFormAdjacent={handleShowFormAdjacentLevel}
                    handleShowFormNested={handleShowFormNestedLevel}></BasicLevel>
                )}
              </Fragment>
            );
          })}
        </>
      )}
      {isShowNestedLevel && isShowFormAddNestedLevel && (
        <FormAddLevel
          handleShowFormNestedLevel={handleShowFormNestedLevel}
          handleShowFormAdjacentLevel={handleShowFormAdjacentLevel}
          calculationId={calculationId}
          parentId={rowId}
          levelNumber={levelNumber}></FormAddLevel>
      )}
      {isShowNestedLevel && isShowFormAddAdjacentLevel && (
        <FormAddLevel
          handleShowFormNestedLevel={handleShowFormNestedLevel}
          handleShowFormAdjacentLevel={handleShowFormAdjacentLevel}
          calculationId={calculationId}
          parentId={rowId}
          levelNumber={levelNumber}></FormAddLevel>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl.current}
        open={isMenuRowOpened}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {isInput && <MenuItem onClick={handleSaveLevel}>Сохранить</MenuItem>}
        {isInput && <MenuItem onClick={handleSaveAddLevel}>Сохранить и доб. статью </MenuItem>}
        {/* {!isInput && <MenuItem onClick={handleCopyLevel}>Копировать </MenuItem>} */}
        <MenuItem onClick={handleClearForm}>Очистить </MenuItem>
        <MenuItem onClick={() => handleDeleteRowClick('Delete')}>Удалить</MenuItem>
      </Menu>
      <ConfirmDialogConfidence />
    </>
  );
};

export default BasicLevel;
