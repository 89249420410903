/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import React, { useCallback, useEffect, useState } from 'react';
import { IHeaderGroupParams } from 'ag-grid-community';
import { Title, Wrapper } from './CustomTableHeader.style';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

const CustomTableHeader = (props: IHeaderGroupParams) => {
  const [expandState, setExpandState] = useState<'expanded' | 'collapsed'>('collapsed');

  const expandOrCollapse = useCallback(() => {
    let currentState = props.columnGroup.getProvidedColumnGroup().isExpanded();
    props.setExpanded(!currentState);
  }, []);

  const syncExpandButtons = useCallback(() => {
    setExpandState(props.columnGroup.getProvidedColumnGroup().isExpanded() ? 'expanded' : 'collapsed');
  }, []);

  useEffect(() => {
    props.columnGroup.getProvidedColumnGroup().addEventListener('expandedChanged', syncExpandButtons);
    syncExpandButtons();
    // return () => {
    //   props.columnGroup.getProvidedColumnGroup().removeEventListener('expandedChanged', syncExpandButtons);
    // };
  }, []);
  return (
    <Wrapper onClick={expandOrCollapse} isOpen={expandState === 'expanded'}>
      <Title isOpen={expandState === 'expanded'}>{props.displayName}</Title>
      <ExpandCircleDownIcon />
    </Wrapper>
  );
};

export default CustomTableHeader;
