import AddIcon from '@mui/icons-material/Add';
import Tooltip from 'components/Tooltip';
import { StyledAddButton } from './CreateLevelButton.styles';
import { CreateLevelButtonProps } from './CreateLevelButton.types';

export const CreateLevelButton: React.FC<CreateLevelButtonProps> = ({ onClick, ...rest }) => {
  return (
    <Tooltip title="Создать уровень" placement="right">
      <StyledAddButton onClick={onClick} size="small" {...rest}>
        <AddIcon />
      </StyledAddButton>
    </Tooltip>
  );
};
