import { FC, useCallback, useState } from 'react';
import { Checkbox, FormControl, Stack, Typography } from '@mui/material';
import { useGetProjectsQuery } from 'api/projects';
import { theme } from 'styles/theme';
import Button from 'components/Button';
import { UserAccessesSelectionProps } from './UserAccessesSelection.types';
import { AccessStatusBlock } from '../UserForm';
import { FieldArray, useFormikContext } from 'formik';
import { UserFormData } from '../UserForm';
import { MultiSelect, MultiSelectMenuItem } from 'styles/global/MultiSelect';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { SelectedProjectItem } from './UserAccessesSelection.styles';
import { ROLE_SELECT_WIDTH } from 'components/UserManagement/components/UserRoleSelection';

export const UserAccessesSelection: FC<UserAccessesSelectionProps> = ({ children }) => {
  const { values: formValues, setFieldValue } = useFormikContext<UserFormData>();
  const isProjectsSelected = !!formValues.projects?.length;
  const isAllProjectsSelected = formValues.allProjects;

  const [selectHidden, setSelectHidden] = useState<boolean>(isProjectsSelected || isAllProjectsSelected);
  const [open, setOpen] = useState<boolean>(false);

  const { data } = useGetProjectsQuery();

  const onAddClick = useCallback(() => {
    setSelectHidden(false);
    setOpen(true);
  }, []);

  const onSelectOpen = useCallback(() => {
    setOpen(true);
    setSelectHidden(false);
  }, []);

  const onSelectClose = useCallback(() => {
    setOpen(false);
    if (isProjectsSelected || isAllProjectsSelected) {
      setSelectHidden(true);
    }
  }, [formValues.projects]);

  if (children) {
    return <>{children}</>;
  }

  return (
    <Stack flex={1} alignItems="flex-end" spacing={2}>
      <FieldArray
        name="projects"
        validateOnChange={false}
        render={() => {
          return (
            <>
              {isAllProjectsSelected && (
                <SelectedProjectItem maxWidth={276}>
                  <Typography>Доступ ко всем проектам</Typography>
                </SelectedProjectItem>
              )}

              {isProjectsSelected && !isAllProjectsSelected && (
                <Stack width={276} spacing={1}>
                  {formValues.projects.map((project) => (
                    <SelectedProjectItem key={project.id}>
                      <Typography>{project.name}</Typography>
                    </SelectedProjectItem>
                  ))}
                </Stack>
              )}

              {data?.projects.length ? (
                (isProjectsSelected || isAllProjectsSelected) && selectHidden ? (
                  <Button
                    startIcon={<ModeEditIcon />}
                    variant="text"
                    bgColor={theme.palette.bg.shades}
                    onClick={onAddClick}
                    style={{ maxWidth: '276px', width: '100%', height: '44px' }}
                    sx={{
                      '& .MuiButton-startIcon': { paddingTop: '1px' },
                    }}>
                    Изменить доступ
                  </Button>
                ) : (
                  <FormControl style={{ width: '100%', maxWidth: ROLE_SELECT_WIDTH }}>
                    <MultiSelect
                      version="select"
                      name="helper"
                      value={formValues.projects.map((project) => project.id)}
                      SelectProps={{
                        multiple: true,
                        displayEmpty: true,
                        MenuProps: {
                          PaperProps: {
                            style: { maxWidth: ROLE_SELECT_WIDTH },
                          },
                        },
                        renderValue: () => <Typography component="span">Выберите проект</Typography>,

                        onChange: (e) => {
                          const value = e.target.value as (number | 'all')[];
                          if (value[value.length - 1] === 'all') {
                            setFieldValue(`allProjects`, !isAllProjectsSelected);
                            setFieldValue(
                              `projects`,
                              !isAllProjectsSelected
                                ? data?.projects.map((project) => ({
                                    id: project.id,
                                    name: project.shortName,
                                  }))
                                : [],
                              false,
                            );
                            return;
                          }

                          // if (isAllProjectsSelected) {
                          //   setFieldValue(
                          //     `projects`,
                          //     data?.projects.map((project) => ({
                          //       id: project.id,
                          //       name: project.shortName,
                          //     })),
                          //   );
                          //   setFieldValue(`allProjects`, false);
                          //   return;
                          // }

                          if (value?.length === data?.projects.length) {
                            setFieldValue(
                              `projects`,
                              data?.projects.map((project) => ({
                                id: project.id,
                                name: project.shortName,
                              })),
                            );
                            setFieldValue(`allProjects`, true);
                            return;
                          }

                          if (isAllProjectsSelected) {
                            setFieldValue('allProjects', false);
                          }
                          setFieldValue(
                            `projects`,
                            value.sort().map(
                              (id) => ({
                                id,
                                name: data?.projects.find((project) => project.id === id)?.shortName,
                              }),
                              true,
                            ),
                          );
                        },
                        open: open,
                        onOpen: onSelectOpen,
                        onClose: onSelectClose,
                      }}>
                      <MultiSelectMenuItem value="all">
                        <Checkbox checked={isAllProjectsSelected} />
                        <Typography variant="body1">Все проекты</Typography>
                      </MultiSelectMenuItem>
                      {data?.projects.map((project) => (
                        <MultiSelectMenuItem value={project.id} key={project.id}>
                          <Checkbox
                            checked={
                              isAllProjectsSelected
                                ? true
                                : formValues.projects.findIndex((rootProject) => rootProject.id === project.id) > -1
                            }
                          />
                          <Typography variant="body1">{project.shortName}</Typography>
                        </MultiSelectMenuItem>
                      ))}
                    </MultiSelect>
                  </FormControl>
                )
              ) : (
                <AccessStatusBlock color={theme.palette.text.dark}>
                  <Typography variant="body1">Проекты отсутствуют</Typography>
                </AccessStatusBlock>
              )}
            </>
          );
        }}
      />
    </Stack>
  );
};
