/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */

import React, { useRef, useState } from "react";
import { Drawer } from "../../../../../../components/Drawer";
import { WarnBlock } from "./ImportDrawer.style";
import { Box, Typography } from "@mui/material";
import { useForm } from "../../../../../../hooks/useForm";
import { FormikProvider } from "formik";
import CopyUploadForm from "./components/CopyUploadForm";
import Button from "../../../../../../components/Button";
import { initPriceReferenceWebsocket, socketPriceReference, websocketApi } from "../../../../../../api/websocketApi";
import { useAppDispatch, useTypedSelector } from "../../../../../../store/store";
import { enqueueSnackbar } from "notistack";
import { wsContext } from "../../../../../../contexts/ws";
import { api } from "../../../../../../api/api";
import { changeCreated } from "../../../../../../store/slices/references/prices/prices";

interface IImportProps {
  open: boolean;
  toggleDrawer: (b?: boolean) => void;
}

interface UploadFile {
  base64: string;
  name: string;
}
export interface IErrorData {
  address: string;
  msg: string;
}
const ImportDrawer: React.FC<IImportProps> = ({ toggleDrawer, open }) => {

  const counter = useRef(10)

  const { token } = useTypedSelector((state) => state.auth);
  const { singleTask } = websocketApi;
  const dispatch = useAppDispatch();
  const [errorData, setErrorData] = useState<IErrorData[] | null>(null);
  const cWS = React.useContext(wsContext);
  const [progressPercent, setProgressPercent] = useState<number>(0);
  const [progressTime, setProgressTime] = useState<string>('');

  const [done, setDone] = useState(false);

  const [disabledDrag, setDisabledDrag] = useState(false);

  const [uploadFile, setUploadFile] = React.useState<UploadFile | null>(null);
  const resetAndCloseDrawer = () => {
    formik.resetForm();
    setUploadFile(null);
    toggleDrawer(false);
  };
  const handleRefresh = () => {
    console.log('close');
    dispatch(api.util.invalidateTags(['PriceReference']));
    // resetAndCloseDrawer();
  };
  const onSubmit = React.useCallback(
    (values: { files: File[] }) => {
      if (socketPriceReference) {
        if (socketPriceReference?.readyState !== 1) {
          if (counter.current === 0) {
            enqueueSnackbar('Установка соединения. Повторите попытку через несколько секунд.', {
              variant: 'error',
              autoHideDuration: 3000,
            });
            
            counter.current = 10;

            return;
          }else{
            counter.current -= 1
            initPriceReferenceWebsocket();
            onSubmit(values)
            return;
          }
        }
        if (values.files.length !== 0) {
          const calcWS = {
            type: singleTask.types.importHandbook,
            bodyJson: JSON.stringify({
              file: uploadFile,
            }),
          };

          socketPriceReference.send(JSON.stringify(calcWS));
          cWS.setImportHandbookUploaded('loading');
          socketPriceReference.onerror = function (error) {
            console.log(`[socketChanel-error]`, error);
          };
        }
      }
    },
    [uploadFile],
  );

  const { formik } = useForm<{ files: File[] }>({
    // validationSchema: validationCalculation,
    enableReinitialize: true,
    initialValues: { files: [] },
    onSubmit,
  });
  const { values, dirty } = formik;
  const [error, setError] = React.useState<boolean>(false);
  const [formData, setFormData] = React.useState<FormData | null>(null);
  // console.log(formik);
  // console.log(values);

  React.useEffect(() => {
    if (values.files && values.files.length) {
      const base64File = { base64: '', name: '' };
      const file = values.files[0];
      const reader = new FileReader();
      reader.onload = ({ target }) => {
        let base64String = (target!.result as string).replace('data:', '').replace(/^.+,/, '');

        base64File.base64 = base64String;
        base64File.name = file.name;
      };
      reader.readAsDataURL(file);
      setUploadFile(base64File);
    }
  }, [values]);

  React.useEffect(() => {
    if (socketPriceReference?.readyState !== 1) return;
    const authWS = {
      bodyJson: JSON.stringify({ token }),
      type: 'auth',
    };

    if (socketPriceReference) {
      socketPriceReference.send(JSON.stringify(authWS));

      socketPriceReference.onmessage = function (event) {
        let status = JSON.parse(event.data).type;
        let bodyJson = JSON.parse(event.data).bodyJson;
        if (bodyJson !== null && bodyJson.payload == 'pong') return;

        if (status === 'status_ok') {
          console.log('[socketChanel-open] Соединение открыто');
        }
        if (status === 'error') {
          let msg = JSON.parse(bodyJson).msg;
          console.log(`[socketChanel-error] Не авторизован, msg:${msg}`);
        }
      };
      socketPriceReference.onclose = function (event) {
        if (event.wasClean) {
          console.log(`[socketChanel-close] Соединение закрыто, причина=${event.reason}.`);
        } else {
          console.log(`[socketChanel-close] Соединение прервано, причина=${event.reason}.`);
        }
      };
    }

    // return () => {
    //   // Очистка состояния при размонтировании компонента
    //   if (socketPriceReference?.readyState === WebSocket.OPEN) {
    //     const code = 3002;
    //     socketPriceReference?.close(code, `код закрытия ${code}`); // Закрываем соединение, если оно еще открыто
    //   }
    // };
  }, [socketPriceReference?.readyState]);

  React.useLayoutEffect(() => {
    cWS.setImportHandbookDrawerOpen(open);
    if (open) {
      setProgressTime('');
      setProgressPercent(0);
    } else {
      setDisabledDrag(false);
      setDone(false);
    }
  }, [open]);

  // useEffect(() => {
  if (socketPriceReference) {
    socketPriceReference.onmessage = function (event) {
      let parsed = JSON.parse(event.data);
      console.log(event);
      let status = parsed.type;

      let bodyJson = JSON.parse(parsed.bodyJson);

      if (bodyJson !== null && bodyJson.payload == 'pong') return;
      console.log(status);
      if (status === 'upload_handbook' || status === 'import_progress') {
        cWS.setImportHandbookUploaded('loading');
        let progress = bodyJson.progress;
        let remainTime = bodyJson.remainTime;
        console.log(remainTime);
        setProgressTime!(String(remainTime));
        setProgressPercent!(Number(progress));
        /* let totalRecords = Number(JSON.parse(bodyJson).total);
          setUploadRecords!(totalRecords); */
      }
      console.log(cWS.socketImportHandbook.isUploaded);
      if (
        status === 'status_ok' &&
        (cWS.socketImportHandbook.isUploaded === 'loading' || cWS.socketImportHandbook.isUploaded === 'success')
      ) {
        cWS.setImportHandbookUploaded('start');
        setErrorData(null);
        setProgressPercent(0);
        setProgressTime('');
        handleRefresh();
        setDone(true);
        setDisabledDrag(true);
        dispatch(changeCreated(false));

        //successHandler('Файл успешно загружен');
      }
      if (status === 'canceled') {
        cWS.setImportHandbookUploaded('canceled');
      }
      if (status === 'import_failed') {
        let msgValue = bodyJson as IErrorData[];
        msgValue.length && setErrorData(msgValue);
        console.log(msgValue);
        // enqueueSnackbar(`Непредвиденная ошибка в файле: ${msgValue.replace('ws.parse_error ', '')}`, {
        //   variant: 'error',
        //   autoHideDuration: 5000,
        // });
        setError(true);
        setProgressPercent(0);
        setProgressTime('');
        cWS.setImportHandbookUploaded('error');
      }
    };
  }
  // }, []);

  // console.log(progressPercent, progressTime, socketPriceReference);

  return (
    <Drawer title={'Импорт'} open={open} onClose={() => toggleDrawer(false)}>
      <FormikProvider value={formik}>
        <Box
          onSubmit={formik.handleSubmit}
          component={'form'}
          display="grid"
          gridTemplateColumns={'1fr'}
          height={'100%'}
          gridTemplateRows={'1fr min-content'}
          px={'20px'}>
          <div>
            <WarnBlock>
              <Typography variant="buttonMedium" align={'center'} color="#ED6C02">
                ВНИМАНИЕ!
              </Typography>
              <Typography variant="buttonMedium" align={'center'} textTransform={'none'} color={'#2B3648'}>
                При загрузке файла, данные будут полностью перезаписаны.
              </Typography>
            </WarnBlock>
            <CopyUploadForm
              disabledClick={disabledDrag}
              done={done}
              wsProgress={progressPercent}
              wsTime={progressTime}
              errorsMSG={errorData}
              error={error}
              setError={setError}
              setFormData={setFormData}
              format={{
                'application/vnd.ms-excel': ['.xls'],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
              }}
              mode={'xml'}
              isLoading={cWS.socketImportHandbook.isUploaded === 'loading'}
              isWS={true}
            />
          </div>
          <Box display={'grid'} gridTemplateColumns={done ? '1fr' : '1fr 1fr'} gap={'16px'} pb={'20px'}>
            {done ? (
              <>
                <Button
                  fullWidth={true}
                  sx={{ '&': { maxWidth: '900px !important' } }}
                  type={'button'}
                  color={'success'}
                  onClick={() => toggleDrawer(false)}>
                  Готово
                </Button>
              </>
            ) : (
              <>
                <Button
                  disabled={!values.files?.length || !dirty || cWS.socketImportHandbook.isUploaded === 'loading'}
                  type={'submit'}
                  color={'success'}>
                  обработать
                </Button>
                <Button onClick={() => toggleDrawer(false)}>закрыть</Button>
              </>
            )}
          </Box>
        </Box>
      </FormikProvider>
    </Drawer>
  );
};

export default ImportDrawer;
