import { Box, Tooltip } from '@mui/material';
import { BasicLevelType } from '../../../../../../api/calculations/types';
import { StyledLevelTd, StyledTypographyLevel } from '../styles';
import FunctionsIcon from '@mui/icons-material/Functions';
import { theme } from '../../../../../../styles/theme';

interface LevelBasicSumProps {
  rowsList: BasicLevelType[];
}

export const LevelBasicSum: React.FC<LevelBasicSumProps> = ({ rowsList }) => {
  let initialValue = 0;
  let sumTotal = rowsList.reduce((accumulator, currentValue) => accumulator + currentValue.price, initialValue);

  const colorTheme = theme.palette;

  /************************************************************************/

  return (
    <Box
      className="LevelSum"
      style={{
        display: 'grid',
        gridTemplateColumns: '100px 1fr',
        gridAutoRows: '50px',
      }}>
      <StyledLevelTd
        style={{
          paddingLeft: '9px',
          justifyContent: 'flex-start',
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            marginRight: `11px`,
            minWidth: 24,
          }}></Box>
        <FunctionsIcon></FunctionsIcon>
      </StyledLevelTd>
      <Box
        style={{
          display: 'grid',
          gridTemplateColumns: 'minmax(206px, 1fr) repeat(4, minmax(135px, 206px)) 30px',
          gridAutoRows: '50px',
        }}>
        <StyledLevelTd style={{ justifyContent: 'flex-start' }}>
          <Tooltip title={'Итого'}>
            <StyledTypographyLevel style={{ color: colorTheme.primary.main }}>Итого</StyledTypographyLevel>
          </Tooltip>
        </StyledLevelTd>
        <StyledLevelTd></StyledLevelTd>
        <StyledLevelTd></StyledLevelTd>
        <StyledLevelTd></StyledLevelTd>
        <StyledLevelTd
          style={{
            color: colorTheme.primary.main, // '#0044B4',
            justifyContent: 'flex-end',
            fontWeight: 600,
          }}>
          {sumTotal.toLocaleString('ru-RU', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </StyledLevelTd>
        <StyledLevelTd style={{ padding: 0, width: 30 }}></StyledLevelTd>
      </Box>
    </Box>
  );
};
