import { AutocompleteProps, BaseTextFieldProps, TextFieldProps } from '@mui/material';
import { ICellEditorParams } from "ag-grid-community"
import { RegisterOptions, FieldValues } from "react-hook-form"
import { NumberFormatBaseProps, NumericFormatProps } from 'react-number-format';
import { BoxProps, TypographyProps } from "@mui/material"

export const CARD_TITLE_SIZES = {
  small: '16px',
  medium: '20px',
  large: '22px',
} as { [key in TSize]: string }

export interface ICustomCellEditorParams extends ICellEditorParams {
  dataType?: TInputType
  AutocompleteProps?: AutocompleteProps<IAutocompleteOption, any, any, any, any>
  withNewOptionCreation?: boolean
  NumberFormatProps?: NumberFormatBaseProps
  TextFieldProps?: BaseTextFieldProps
  options?: IAutocompleteOption[],
  validation?:
    | Omit<RegisterOptions<FieldValues, 'input'>, 'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
    | undefined
}
export interface IAutocompleteOption {
  label: string
  value: string,
  [key: string]: any
}

export interface ICustomCellEditorParams extends ICellEditorParams {
  dataType?: TInputType
  AutocompleteProps?: AutocompleteProps<IAutocompleteOption, any, any, any, any>
  withNewOptionCreation?: boolean
  NumericFormatProps?: NumericFormatProps
  TextFieldProps?: BaseTextFieldProps
  options?: IAutocompleteOption[],
  validation?:
    | Omit<RegisterOptions<FieldValues, 'input'>, 'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
    | undefined
}

export type TSize = 'small' | 'medium' | 'large'

export interface ICardTitleProps extends TypographyProps {
  size: TSize
}

export interface IControllerInputWrapperProps extends BoxProps {
  error: boolean
}

export type TInputType = "text" | "number" | "date" | "autocomplete" | "switch" | "textarea"
