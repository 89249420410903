import styled from 'styled-components';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { checkboxClasses } from '@mui/material';
import { MenuItem, alpha, menuItemClasses, svgIconClasses } from '@mui/material';

export const StyledLSRFilesTable = styled(DataGrid)(({ theme }) => ({
  border: 'none',

  [`& .${gridClasses.main}`]: {
    borderRadius: theme.spacing(1),
  },

  [`& .${gridClasses.columnHeaders}`]: {
    backgroundColor: theme.palette.secondary.gray,
    color: theme.palette.text.white,

    [`& .${checkboxClasses.root}`]: {
      color: theme.palette.text.white,
    },
  },

  [`& .${gridClasses.iconSeparator}`]: {
    display: 'none',
  },

  [`& .${gridClasses.virtualScroller}`]: {
    backgroundColor: theme.palette.bg.white,
    color: theme.palette.text.dark,

    '&::-webkit-scrollbar': {
      borderLeft: `1px solid ${theme.palette.bg.gray}`,
    },

    '&::-webkit-scrollbar:horizontal': {
      borderTop: `1px solid ${theme.palette.bg.gray}`,
    },
  },

  [`& .${gridClasses.cell}`]: {
    borderColor: theme.palette.bg.gray,

    span: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
})) as typeof DataGrid;

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  [`&.${menuItemClasses.focusVisible}, &:hover`]: {
    color: theme.palette.primary.main,
  },

  [`&.${menuItemClasses.focusVisible}`]: {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
  },

  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },

  [`&:not(.${menuItemClasses.focusVisible}) .${svgIconClasses.root}`]: {
    color: theme.palette.primary.light,
  },

  [`&:hover .${svgIconClasses.root}`]: {
    color: theme.palette.primary.main,
  },
}));
