import React, { FC, ReactElement } from 'react'
import { TitledWrapperProps, TitledWrapperVariant } from './TitledWrapper.types'
import { Stack, Typography } from '@mui/material'
import {
  RegularBoxWrapper,
  RegularScrollableWrapper,
  RegularStackWrapper,
  TitledWrapperAccordion,
  TitledWrapperAccordionDetails,
  TitledWrapperAccordionSummary
} from './TitledWrapper.styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export const TitledWrapper: FC<TitledWrapperProps> = ({
  title,
  children,
  endAdornment,
  variant = 'primary',
  contentWrapper = 'none',
  bordered = 'none',
  defaultExpanded,
  className,
}) => {

  const RegularContentChildren: Record<NonNullable<typeof contentWrapper>, ReactElement> = {
    box: (
      <RegularBoxWrapper bordered={bordered} className='box-wrapper'>
        {children}
      </RegularBoxWrapper>
    ),
    stack: (
      <RegularStackWrapper bordered={bordered} className='stack-wrapper'>
        {children}
      </RegularStackWrapper>
    ),
    scrollable: (
      <RegularScrollableWrapper bordered={bordered} hideScroll spacing={0.75} className='scrollable-wrapper'>
        {children}
      </RegularScrollableWrapper>
    ),
    none: (
      <>
        {children}
      </>
    ),
  }

  const RegularContent = (
    <Stack
      spacing={0.75}
      overflow={contentWrapper === 'scrollable' ? 'hidden' : 'visible'}
      className={className}
    >
      {title && (
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1} pr={0.5} pl={1.25}>
          <Typography variant='body2' className='title'>
            {title}
          </Typography>

          {endAdornment}
        </Stack>
      )}


      {RegularContentChildren[contentWrapper]}
    </Stack>
  )

  const AccordionContent = (
    <TitledWrapperAccordion defaultExpanded={defaultExpanded} disableGutters>
      <TitledWrapperAccordionSummary
        expandIcon={<ExpandMoreIcon fontSize='medium' color='secondary' />}
      >
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
          <Typography variant='body2'>
            {title}
          </Typography>

          {endAdornment}
        </Stack>
      </TitledWrapperAccordionSummary>

      <TitledWrapperAccordionDetails>
        {children}
      </TitledWrapperAccordionDetails>
    </TitledWrapperAccordion>
  )

  const Content: Record<TitledWrapperVariant, ReactElement> = {
    'primary': RegularContent,
    'accordion': AccordionContent,
  }

  return Content[variant]
}
