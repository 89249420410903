/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ActCol, ActRow, ActTable } from "./ActsTab.style";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { IconDate } from "../../../../../Accomplishment/components/ActDialog/ActDialog.style";
import { DatePickerRenderInput } from "../../../../../../../../components/FieldForm/styles";
import { Delete } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { GetCoefficientsResponse } from "api/params/params.types";
import { formatDateToString, formatToDate } from "utils/formatDate";
import { ActList, TActStats } from "../../../../../../../../types";
import { add, isAfter, isBefore, isDate, lastDayOfMonth, sub } from "date-fns";
import { ActListContext } from "../../../../../CalculationСomplicated";

const statuses:Record<TActStats, {title:string,color:string}> = {
  CLOSED: { color:"#2E7D32",title:'Период закрыт' },
  COLLECTING: { color:"#A14DE7",title:'Получение факта' },
  PREPARED: { color:"#FF9800",title:'Период изменен' },
  NEW: { color:"white",title:'' },
  REOPENED: { color:"#FF9800",title:'Период изменен' },
}

const ActsTab = () => {
  const { control } = useFormContext<GetCoefficientsResponse>();
  const [fields, setFields] = useState<typeof fieldsForm>([]);

  const {calculation} = useContext(ActListContext)

  const fieldsForm = useWatch({
    name: 'acts',
    control,
  });

  useEffect(() => {
    setFields(fieldsForm);
  }, [fieldsForm]);

  return (
    <ActTable>
      <ActRow isIntegrate={!!calculation?.integrationInfo} border paddingTop>
        <ActCol head>Сформирован</ActCol>
        <ActCol head align={'center'}>
          Начало периода
        </ActCol>
        <span />
        <ActCol head align={'center'}>
          Окончание периода
        </ActCol>
        <span />
        {/*{calculation?.integrationInfo && (*/}
        {/*  <ActCol head align={'flex-start'}>*/}
        {/*    Статус*/}
        {/*  </ActCol>*/}
        {/*)}*/}
      </ActRow>
      {fields.map((estimate, estimateIndex) => {
        return (
          <React.Fragment key={'estimate' + estimateIndex + estimate.label}>
            {!!estimate.fields.length && (
              <Typography
                style={{ cursor: 'default' }}
                whiteSpace={'nowrap'}
                textOverflow={'ellipsis'}
                width={'100%'}
                overflow={'hidden'}
                pt={2}
                fontSize={14}
                color={'#7890b2'}>
                {estimate.label || 'Общие'}
              </Typography>
            )}
            {estimate.fields.map((row, rowIndex) => (
              <React.Fragment key={'parent' + estimateIndex + estimate.label}>
                <Row parentIndex={estimateIndex} rowIndex={rowIndex} row={row} />
              </React.Fragment>
            ))}
          </React.Fragment>
        );
      })}
    </ActTable>
  );
};
// const Group: React.FC<{ parentIndex: number; estimateIndex: number }> = ({ parentIndex, estimateIndex }) => {
//   const { getValues, watch } = useFormContext<GetCoefficientsResponse>();
//   const [group, setGroup] = useState<LabelsGroup | null>(null);
//
//   useEffect(() => {
//     setGroup(getValues(`acts.${estimateIndex}.groups.${parentIndex}`) || null);
//   }, [watch(`acts.${estimateIndex}.groups.${parentIndex}`)]);
//
//   return !group || !group.fields.length ? null : (
//     <>
//       <ActRow paddingTop>
//         <ActCol colspan caption>
//           {group.label}
//         </ActCol>
//       </ActRow>
//       {group.fields.map((row, rowIndex) => {
//         return (
//           <Row
//             key={'row' + rowIndex + row.id + parentIndex}
//             row={row}
//             estimateIndex={estimateIndex}
//             rowIndex={rowIndex}
//             parentIndex={parentIndex}
//           />
//         );
//       })}
//     </>
//   );
// };
const Row: React.FC<{ row: ActList; rowIndex: number; parentIndex: number }> = ({ row, rowIndex, parentIndex }) => {
  const { current, setCurrent,calculation } = useContext(ActListContext);
  const { control, getValues, setValue } = useFormContext<GetCoefficientsResponse>();

  const startDate = useWatch({
    name: `acts.${parentIndex}.fields.${rowIndex}.startDate` as 'acts.0.fields.0.startDate',
    control,
  });

  const endDate = useWatch({
    name: `acts.${parentIndex}.fields.${rowIndex}.endDate` as 'acts.0.fields.0.endDate',
    control,
  });
  // const startDate = useWatch({
  //   name: `acts.${estimateIndex}.groups.${parentIndex}.fields.${rowIndex}.startDate` as any,
  //   control,
  // });
  //
  // const endDate = useWatch({
  //   name: `acts.${estimateIndex}.groups.${parentIndex}.fields.${rowIndex}.endDate` as any,
  //   control,
  // });

  /**
   * являются ли даты одинаковыми
   * @param dateA
   * @param dateB
   */
  const isSameDate = (dateA: Date, dateB: Date) =>
    dateA.getDate() === dateB.getDate() &&
    dateA.getMonth() === dateB.getMonth() &&
    dateA.getFullYear() === dateB.getFullYear();

  /**
   * разбирается список дат для вывода на страницу, делается плоская структура
   */
  const unFlatRanges = useMemo(() => {
    const fields = getValues('acts') || [];
    return fields
      .reduce((acc: { start: string | Date; end: string | Date }[], item) => {
        acc.push(...item.fields.flatMap((dates) => ({ start: dates.startDate||'', end: dates.endDate||'' })));
        return acc;
      }, [])
      .filter(
        (d) =>
          !isSameDate(d.start as Date, new Date(row.startDate||'')) && !isSameDate(d.end as Date, new Date(row.endDate||'')),
      );
  }, [row]);

  /**
   * массив недоступных дат
   */
  const disabledDates: { start: Date; end: Date }[] = useMemo(() => {
    const fields = getValues('acts') || [];
    if (!fields) return [];
    return unFlatRanges.reduce(
      (disabled, dateRange) => {
        const { start, end } = dateRange;
        disabled.push({ start: new Date(start), end: new Date(end) });
        return disabled;
      },
      [] as { start: Date; end: Date }[],
    );
  }, [startDate, endDate]);

  /**
   * Здесь идет расчет
   * вычисляется какое это поле и устанавливает максимальную и минимальную даты
   */
  const disabledRange = useCallback(
    (type: 'startDate' | 'endDate' | 'onDate') => {
      const startD = startDate as Date;
      const endD = endDate as Date;

      const sortedDates = [...disabledDates, {} as { start: Date; end: Date }]
        .sort((a, b) => a?.start?.getTime() - b?.start?.getTime())
        .map((d, i, array) => {
          if (i == 0) {
            return { start: null, end: d.start ?? null };
          } else
            return {
              start: array[i - 1].end,
              end: d?.start ?? null,
            };
        });
      const dates: { minDate: null | Date; maxDate: null | Date } = {
        minDate: null,
        maxDate: null,
      };
      if (!startD && !endD) return dates;
      if (sortedDates.length === 1) {
        if (type === 'startDate') {
          if (endD) {
            const date = endD.getDate();
            const year = endD.getFullYear();
            const month = endD.getMonth();
            dates.maxDate = new Date(year, month, date - 1);
          }
        }
        if (type === 'endDate') {
          if (startD) {
            const date = startD.getDate();
            const year = startD.getFullYear();
            const month = startD.getMonth();
            dates.minDate = new Date(year, month, date + 1);
          }
        }
        return dates;
      }
      for (const { start, end } of sortedDates) {
        if(start  === null) continue;
        if (type === 'startDate') {
          let d: Date | undefined;
          if (endD) {
            const date = endD.getDate();
            const year = endD.getFullYear();
            const month = endD.getMonth();
            d = new Date(year, month, date - 1);
          }
          if (start === null && endD && isBefore(endD, end)) {
            dates.minDate = add(start, { days: 1 });
            dates.maxDate = d ?? end;
            break;
          } else if (end === null && isAfter(endD as Date, start)) {
            dates.minDate = add(start, { days: 1 });
            dates.maxDate = d ?? end;
            break;
          } else {
            if (isAfter(endD as Date, start) && isBefore(endD as Date, end)) {
              dates.minDate = add(start, { days: 1 });
              dates.maxDate = d ?? end;
              break;
            }
          }
        }
        if (type === 'endDate') {
          let d: Date | undefined;
          if (startD) {
            const date = startD.getDate();
            const year = startD.getFullYear();
            const month = startD.getMonth();
            d = new Date(year, month, date + 1);
          }
          if (start === null && startD && isBefore(startD, end)) {
            dates.minDate = d ?? start;
            dates.maxDate = sub(end, { days: 1 });
            break;
          } else if (end === null && isAfter(startD as Date, start)) {
            dates.minDate = d ?? start;
            dates.maxDate = sub(end, { days: 1 });
            break;
          } else {
            if (isAfter(startD as Date, start) && isBefore(startD as Date, end)) {
              dates.minDate = d ?? start;
              dates.maxDate = sub(end, { days: 1 });
              break;
            }
          }
        }
      }
      return dates;
    },
    [disabledDates, startDate, endDate],
  );

  const handleRemove = useCallback(
    (updateItem: ActList) => {
      const fields = getValues('acts') || [];
      const copyFields = [...fields];
      /**
       * функция фильтрации по recordID
       */
      const filter = (i: ActList) => {
        return i.id !== updateItem.id;
      };

      const currentParent = copyFields[parentIndex];
      console.log(currentParent);

      /**
       * новый список для отрисовки, фильтрация
       */
      currentParent.fields = currentParent.fields.filter(filter);

      /* Получение полного списка к удалению */
      const removedArr: ActList[] = getValues(`removed.acts`);
      if (updateItem.id === current?.id) {
        const condidate = getValues('acts').map((_) => _.fields.find((a) => a.id));
        if (condidate[0]) {
          setCurrent?.(condidate[0]);
        } else {
          setCurrent?.(null);
        }
      }

      /* пушится новый объект и перезаписывается значение */
      removedArr.push({
        ...updateItem,
      });
      setValue(`removed.acts`, removedArr, { shouldDirty: true });

      /* Обновляется родитель, а затем обновляются значения */
      setValue(`acts.${parentIndex}` as 'acts.0', currentParent, { shouldDirty: true });
    },
    [rowIndex, parentIndex, current, setCurrent],
  );
  const checkDate = (inputDate: Date | null, start: Date | null, end: Date | null) => {
    if (!inputDate || !start || !end) return false;
    return isSameDate(inputDate, start) || (inputDate > start && inputDate < end) || isSameDate(inputDate, end);
  };
  const shouldDisabled = (dayOrMonth: unknown, render: 'startDate' | 'endDate') => {
    if (dayOrMonth) {
      if (disabledDates.length) {
        if (render === 'startDate') {
          return disabledDates.some(({ start, end }) => checkDate(dayOrMonth as Date, start, end));
        }
        if (render === 'endDate') {
          return disabledDates.some(({ start, end }) => checkDate(dayOrMonth as Date, start, end));
        }
      }
    }
    return false;
  };
  console.log(getValues('acts'))
  return (
    <ActRow isIntegrate={!!calculation?.integrationInfo} active={row.id === current?.id}>
      <ActCol>{formatDateToString(new Date(row.onDate||''), 'dd.MM.yyyy')}</ActCol>
      <ActCol>
        <Controller
          name={`acts.${parentIndex}.fields.${rowIndex}.startDate` as 'acts.0.fields.0.startDate'}
          control={control}
          render={({ field: { ref, ...props } }) => (
            <DesktopDatePicker
              ref={ref}
              {...props}
              value={startDate}
              openTo="year"
              views={['year', 'month', 'day']}
              shouldDisableMonth={(d) => shouldDisabled(d, 'startDate')}
              shouldDisableDate={(d) => shouldDisabled(d, 'startDate')}
              onChange={(e, keyboardInputValue) => {
                const userValue = keyboardInputValue
                  ? keyboardInputValue
                  : e && e instanceof Date && !isNaN(e.getTime()) && !keyboardInputValue
                  ? e.toLocaleDateString()
                  : '';

                if (/^\d{2}\.\d{2}\.\d{4}/.test(userValue)) {
                  setValue(`acts.${parentIndex}.fields.${rowIndex}.startDate` as 'acts.0.fields.0.startDate', e as Date, {
                    shouldDirty: true,
                  });
                  const lastDay = lastDayOfMonth(e as Date);
                  const endDate =
                    disabledRange('endDate').maxDate && sub(disabledRange('endDate').maxDate as Date, { days: 1 });
                  if (endDate && isDate(endDate) && e instanceof Date && endDate.getMonth() === e.getMonth()) {
                    setValue(`acts.${parentIndex}.fields.${rowIndex}.endDate` as 'acts.0.fields.0.endDate', endDate, {
                      shouldDirty: true,
                    });
                  } else {
                    if (!isNaN(lastDay.getTime())) {
                      setValue(`acts.${parentIndex}.fields.${rowIndex}.endDate` as 'acts.0.fields.0.endDate', lastDay, {
                        shouldDirty: true,
                      });
                    } else {
                      setValue(`acts.${parentIndex}.fields.${rowIndex}.endDate` as 'acts.0.fields.0.endDate', null, {
                        shouldDirty: true,
                      });
                    }
                  }
                }
              }}
              components={{
                OpenPickerIcon: IconDate,
              }}
              renderInput={(params) => (
                <DatePickerRenderInput
                  ref={ref}
                  {...params}
                  {...props}
                  value={startDate}
                  onChange={(e) => {
                    e.stopPropagation();
                    if (!e.target.value) {
                      setValue(`acts.${parentIndex}.fields.${rowIndex}.startDate` as 'acts.0.fields.0.startDate', null, {
                        shouldDirty: true,
                      });
                      return;
                    }
                    if (/\^(\d{2})\.(\d{2})\.(\d{4})$/.test(e.target.value)) {
                      const date = new Date(formatToDate(e!.target!.value! as string, 'yyyy-MM-dd')!);
                      setValue(
                        `acts.${parentIndex}.fields.${rowIndex}.startDate` as any,
                        !isNaN(date.getTime()) ? date : e.target.value,
                        { shouldDirty: true },
                      );
                    }
                  }}
                  inputProps={{
                    ...params.inputProps,
                    style: { padding: '6px 6px 6px 8px' },
                    placeholder: 'дд.мм.гггг',
                    autoComplete: 'off',
                  }}
                />
              )}
            />
          )}
        />
      </ActCol>
      <ActCol align={'center'}>&mdash;</ActCol>
      <ActCol>
        <Controller
          name={`acts.${parentIndex}.fields.${rowIndex}.endDate` as 'acts.0.fields.0.endDate'}
          control={control}
          render={({ field: { ref, value, ...props } }) => {
            return (
              <DesktopDatePicker
                ref={ref}
                {...props}
                value={endDate}
                components={{
                  OpenPickerIcon: IconDate,
                }}
                maxDate={disabledRange('endDate').maxDate}
                minDate={disabledRange('endDate').minDate}
                openTo="year"
                views={['year', 'month', 'day']}
                shouldDisableMonth={(d) => shouldDisabled(d, 'endDate')}
                shouldDisableDate={(d) => shouldDisabled(d, 'endDate')}
                onChange={(e: any, keyboardInputValue) => {
                  console.log(keyboardInputValue);
                  const userValue = keyboardInputValue
                    ? keyboardInputValue
                    : e && !isNaN(e.getTime()) && !keyboardInputValue
                    ? e.toLocaleDateString()
                    : '';

                  if (/^\d{2}\.\d{2}\.\d{4}/.test(userValue)) {
                    setValue(`acts.${parentIndex}.fields.${rowIndex}.endDate` as any, e, {
                      shouldDirty: true,
                    });
                  }
                }}
                renderInput={(params) => (
                  <DatePickerRenderInput
                    ref={ref}
                    // helperText={isError ? t(error as string, { maxLength }) : undefined}
                    {...params}
                    {...props}
                    value={endDate}
                    onChange={(e) => {
                      e.stopPropagation();
                      if (!e.target.value) {
                        setValue(`acts.${parentIndex}.fields.${rowIndex}.endDate` as any, null, {
                          shouldDirty: true,
                        });
                        return;
                      }
                      if (/\^(\d{2})\.(\d{2})\.(\d{4})$/.test(e.target.value)) {
                        const date = new Date(formatToDate(e!.target!.value! as string, 'yyyy-MM-dd')!);
                        setValue(
                          `acts.${parentIndex}.fields.${rowIndex}.endDate` as any,
                          !isNaN(date.getTime()) ? date : e.target.value,
                          { shouldDirty: true },
                        );
                      }
                    }}
                    inputProps={{
                      ...params.inputProps,
                      style: { padding: '6px 6px 6px 8px' },
                      placeholder: 'дд.мм.гггг',
                      // maxLength,
                      // minLength,
                      autoComplete: 'off',
                    }}
                  />
                )}
              />
            );
          }}
        />
      </ActCol>
      <ActCol align={'center'}>
        <IconButton
          onClick={() => handleRemove(getValues(`acts.${parentIndex}.fields.${rowIndex}` as 'acts.0.fields.0'))}
          color="warning">
          <Delete />
        </IconButton>
      </ActCol>
      {/*{calculation?.integrationInfo && (*/}
      {/*  <ActCol color={statuses[row.status!].color} align={"center"}>*/}
      {/*    {statuses[row.status!].title}*/}
      {/*  </ActCol>)}*/}
    </ActRow>
  );
};

export default ActsTab;
