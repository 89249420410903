import { FC } from 'react';
import { StyledBox, StyledTypography } from './ColoredTitle.styles';
import { ColoredTitleProps } from './ColoredTitle.types';

export const ColoredTitle: FC<ColoredTitleProps> = ({ body, color, ...props }) => {
  return (
    <StyledBox bgColor={color} {...props}>
      <StyledTypography variant="tooltip">{body}</StyledTypography>
    </StyledBox>
  );
};
