import { Box, Menu, MenuItem } from '@mui/material';
import { LevelFolder } from 'components/LevelFolder';
import { useTreeNodeContext } from 'pages/FullVor/Table/components/TreeNodeRow/context';
import { FC, MouseEvent, useState } from 'react';
import { StyledMenuButton } from './LevelSelect.styles';
import { LevelSelectProps } from './LevelSelect.types';

export const LevelSelect: FC<LevelSelectProps> = ({ selectedLevel, hideLevelsMore, onSetLevel }): JSX.Element => {
  const { level, levelIds } = useTreeNodeContext();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);

  const listItemClickHandler = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const menuItemClickHandler = (level: number) => {
    onSetLevel(level);
    setAnchorEl(null);
  };

  const closeMenuHandler = () => {
    setAnchorEl(null);
  };

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <StyledMenuButton onClick={listItemClickHandler}>
        <LevelFolder size="large" level={selectedLevel} />
      </StyledMenuButton>
      <Menu anchorEl={anchorEl} open={open} onClose={closeMenuHandler}>
        <MenuItem selected={selectedLevel === 1} onClick={() => menuItemClickHandler(1)}>
          <LevelFolder level={1} />
        </MenuItem>
        {Object.keys(levelIds)
          .filter((key) => (hideLevelsMore ? +key < level : true))
          .filter((key) => +key <= 13)
          .map((key) => (
            <MenuItem key={key} selected={+key + 1 === selectedLevel} onClick={() => menuItemClickHandler(+key + 1)}>
              <LevelFolder level={+key + 1} />
            </MenuItem>
          ))}
      </Menu>
    </Box>
  );
};
