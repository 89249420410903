/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box, createFilterOptions, IconButton, MenuItem } from "@mui/material";
import {
  ColDef,
  ColGroupDef,
  EditableCallbackParams,
  ICellRendererParams,
  ValueGetterParams,
  ValueSetterParams
} from "ag-grid-community";
import { FC, useContext, useMemo, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { IEstimateReference, resourceEng, resourceRus } from "../../../../api/references/estimates/estimates.types";
import { PRStyledBaseTextField } from "../../../../components/BaseTextField/BaseTextField.styles";
import { getPricesState } from "../../../../store/slices/references/prices/prices";
import { UnitWeight } from "../../../../store/slices/references/prices/types";
import {
  PRStyledSelect
} from "../../../Calculations/components/Accomplishment/components/CaptionTable/CaptionTable.style";
import Cell from "../../../Calculations/components/Accomplishment/components/Cell";
import { PricesReferenceContext } from "./PricesReference";
import { FormPriceReference, FormPriceReferencePosition } from "./PricesReference.types";
import CellEditor from "./components/CellEditor";
import HeaderComponent from "./components/HeaderComponent";
import Level from "./components/Level";
import { NoRowsTitle, PRNumericField, StyledAutocomplete, StyledPopper } from "./components/PricesReferences.style";
import UnitCell from "./components/UnitCell";

const filterGroup = createFilterOptions<{ id: number; title: string } & { label: string }>();
const filterUnit = createFilterOptions<UnitWeight & { label: string }>();

const fotRequired = ['WORKERS'];
const mimRequired = ['MiM', 'MACHINE_OPERATORS'];
const mtrRequired = ['SERVICES', 'EQUIPMENT', 'MATERIALS'];

export function modifyValue(val: number | null | undefined, toLocal: boolean = true) {
  if (toLocal) {
    return val?.toLocaleString('ru-RU', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return val?.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 6 }) || null;
}

function checkEditable(row:IEstimateReference,isClass = false){
  switch (row.rowType) {
    case 'FOLDER':{
      return isClass?'':false
    }
    case 'RATE_POSITION':{
      return isClass?!!row!.resourceType&&fotRequired.includes(row!.resourceType!):!!row!.resourceType&&fotRequired.includes(row!.resourceType!) ? 'class-hover':'';
    }
    case 'RATE':{
      return isClass?
        (('hasChildren' in row && row.hasChildren !== null) ? !row.hasChildren ? 'class-hover':'' : !row.children.length ? 'class-hover':'') || (!!row!.resourceType&&fotRequired.includes(row!.resourceType!)? 'class-hover':''):
        (('hasChildren' in row&& row.hasChildren !== null) ? !row.hasChildren : !row.children.length) || !!row.resourceType&&fotRequired.includes(row!.resourceType!)
    }
  }
  return isClass?'':false
}

export function useTableData(valueSetter: (params: ValueSetterParams<IEstimateReference>) => false) {
  const columnsDef: (ColDef<IEstimateReference, any> | ColGroupDef<IEstimateReference>)[] = useMemo(() => {
    return [
      {
        field: 'id',
        width: 124,
        maxWidth: 124,
        minWidth: 124,
        headerName: '',

        cellRenderer: Level,
      },
      {
        field: 'resourceType',
        width: 180,
        maxWidth: 180,
        minWidth: 180,
        headerName: 'Тип ресурса',
        headerComponent: HeaderComponent,
        editable: (props: EditableCallbackParams<IEstimateReference, any>) => {
          // return -100 > (props.data?.id || 0);
          return (
            (props.data?.rowType === 'RATE' && !props.data?.create && props.data?.hasChildren !== null?!props.data?.hasChildren:!props.data?.children.length) ||
            (props.data?.rowType === 'RATE_POSITION' && !props.data?.create)
          );
        },
        suppressKeyboardEvent: (params) => {
          return !!params.data?.create;
        },
        cellEditor: CellEditor,
        valueGetter: (params: ValueGetterParams) => {
          return params?.data?.resourceType;
        },
        cellClass: (params) => {
          const hover =
            (params.data?.rowType === 'RATE' && !params.data?.create && params.data?.hasChildren !== null?!params.data?.hasChildren:!params.data?.children.length) ||
            (params.data?.rowType === 'RATE_POSITION' && !params.data?.create)
              ? 'cell-hover '
              : '';
          return params.data?.create ? hover + 'remove-padding' : hover + '';
        },
        valueSetter,
        cellRenderer: ({ data, api }: ICellRendererParams<IEstimateReference, any, any>) => {
          const { submitPosition, errors, methodsPosition, methodsRate, submit } = useContext(PricesReferenceContext);
          const { setValue: setValuePosition } = useFormContext<FormPriceReferencePosition>();
          const { setValue: setValueRate } = useFormContext<FormPriceReference>();
          // const { isCreated } = useSelector(getPricesState);
          const error = !!errors.position[Math.abs(data!.id) as any]?.type;

          const isRate = data?.rowType === 'RATE';

          const setValue = isRate ? setValueRate : setValuePosition;

          const name = isRate ? 'type' : `${Math.abs(data?.id!)}.type`;
          const methods = isRate ? methodsRate : methodsPosition;

          const onSubmit = methods?.handleSubmit((v) =>
            isRate ? submit(v) : submitPosition(v, data!.id, data!.parentID!, data!.folder!),
          );

          return data?.create && (data?.lvl || 0) >= 4 && data.id < 0 ? (
            <Box
              display={'flex'}
              width={'100%'}
              height={'100%'}
              alignItems={'center'}
              justifyContent={'flex-start'}
              component={'form'}
              id={`references-estimate-position`}
              onSubmit={onSubmit}>
              <Controller
                control={methods?.control as any}
                render={({ field: { ref, value, ...props } }) => {
                  return (
                    <PRStyledSelect
                      SelectProps={{
                        renderValue: () => value || 'Не выбрано',
                        autoFocus: true,
                      }}
                      // disabled={!isCreated}
                      error={error}
                      sx={{
                        ...(error && { background: '#FFB1B126' }),
                      }}
                      select
                      nullableValues={[value, 'Не выбрано']}
                      onClear={() => {}}
                      ref={ref}
                      {...props}
                      onChange={(e) => {
                        const blockedInput = ['Рабочие', 'Механизаторы', 'МиМ'];
                        const machineHour = ['МиМ'];
                        // const humanHour = ['Рабочие', 'Механизаторы'];
                        props.onChange(e.target.value);
                        if (!isRate) {
                          if (blockedInput.includes(e.target.value)) {
                            // @ts-ignore
                            setValue(`${Math.abs(data?.id!)}.blockedInput` as never, true as never);
                            const value = machineHour.includes(e.target.value) ? 'маш.час' : 'чел.час';
                            // @ts-ignore
                            setValue(`${Math.abs(data?.id!)}.unit` as never, value as never);
                          } else {
                            // @ts-ignore
                            setValue(`${Math.abs(data?.id!)}.blockedInput` as never, false as never);
                          }
                        } else {
                          // @ts-ignore
                          setValue('type', value);
                        }
                        api.applyTransaction({
                          update: [{ ...data, resourceType: resourceEng[e.target.value as keyof typeof resourceEng] }],
                        });
                      }}
                      value={value || 'Не выбрано'}>
                      <MenuItem value={'Рабочие'}>Рабочие</MenuItem>
                      <MenuItem value={'Механизаторы'}>Механизаторы</MenuItem>
                      <MenuItem value={'МиМ'}>МиМ</MenuItem>
                      <MenuItem value={'Материалы'}>Материалы</MenuItem>
                      <MenuItem value={'Оборудование'}>Оборудование</MenuItem>
                      <MenuItem value={'Услуги'}>Услуги</MenuItem>
                    </PRStyledSelect>
                  );
                }}
                name={name as never}
              />
              <button hidden type={'submit'} />
            </Box>
          ) : (
            <Cell color={'#2B3648'} float={'left'}>
              {data!.resourceType && resourceRus[data!.resourceType]}
            </Cell>
          );
        },
      },
      {
        field: 'code',
        width: 145,
        maxWidth: 145,
        minWidth: 145,
        headerName: 'Шифр',
        headerComponent: HeaderComponent,
        suppressKeyboardEvent: (params) => {
          return !!params.data?.create;
        },
        editable: (props: EditableCallbackParams<IEstimateReference, any>) => {
          // return -100 > (props.data?.id || 0);
          return props.data?.rowType === 'FOLDER' && props.data?.id < 0 && !props.data?.create;
        },
        cellClass: (params) => {
          const hover =
            params.data?.rowType === 'FOLDER' && params.data?.id < 0 && !params.data?.create ? 'cell-hover ' : '';
          return params.data?.create ? hover + 'remove-padding' : hover + '';
        },
        cellRenderer: ({ data }: ICellRendererParams<any, any, any>) => {
          const { submit, errors, methodsRate } = useContext(PricesReferenceContext);
          const error = errors.rate.shifr;
          const refPopper = useRef<HTMLFormElement | null>(null);
          // const { isCreated } = useSelector(getPricesState);
          return data.create && data.lvl === 1 ? (
            <Box
              component={'form'}
              height={'100%'}
              ref={refPopper}
              id="references-estimate"
              onSubmit={methodsRate?.handleSubmit(submit)}>
              <Controller
                control={methodsRate?.control}
                rules={{
                  validate: {
                    maxLength(v) {
                      if (v && v.length > 15) return 'Максимально 15 символов';
                      return true;
                    },
                    acceptLetters(v) {
                      if (v && /[^a-zа-яё\-\d\s]/gi.test(v)) {
                        return 'Только латиница/кирилица, цифры, пробелы и "-"';
                      }
                      return true;
                    },
                  },
                }}
                render={({ field: { ref, ...props }, formState }) => {
                  const errorMessage = formState.errors?.shifr?.message;

                  return (
                    <>
                      <PRStyledBaseTextField
                        fullWidth
                        // disabled={isCreated && data!.id < -100}
                        placeholder={error ? 'Обязательно для заполнения' : 'Введите шифр'}
                        InputProps={{
                          ...((error || !!errorMessage) && { sx: { background: '#FFB1B16F !important' } }),
                        }}
                        sx={{ borderWidth: '5px' }}
                        error={error || !!errorMessage}
                        inputRef={ref}
                        {...props}
                        onBlur={(e) => {
                          if (e.target.value) {
                            const btn = document.createElement('button');
                            btn.type = 'submit';
                            refPopper.current!.append(btn);
                            btn.click();
                            btn.remove();
                          }
                        }}
                      />
                      <PopperComponent message={errorMessage} refItem={refPopper.current} />
                    </>
                  );
                }}
                name={'shifr'}
              />
            </Box>
          ) : (
            <Cell color={'#2B3648'} showTooltip={false} float={'center'}>
              {data.code}
            </Cell>
          );
        },
      },
      {
        field: 'title',
        width: 452,
        maxWidth: 452,
        minWidth: 452,
        headerName: 'Наименование',
        headerComponent: HeaderComponent,

        editable: (props) => !props.data?.create,
        cellEditor: CellEditor,
        valueGetter: (params: ValueGetterParams) => {
          return params?.data?.title;
        },
        suppressKeyboardEvent: (params) => {
          return !!params.data?.create;
        },
        valueSetter,
        cellClass: (params) => {
          return params.data?.create ? 'cell-hover remove-padding' : 'cell-hover';
        },

        cellRenderer: ({ data }: ICellRendererParams<IEstimateReference, any, any>) => {
          const { submitPosition, submit, errors, methodsPosition, methodsRate } = useContext(PricesReferenceContext);
          // const { isCreated } = useSelector(getPricesState);
          let name = '';
          switch (data?.lvl) {
            case 1:
            case 2:
            case 3:
              name = 'level' + data?.lvl;
              break;
            default:
              name = 'estimate';
              break;
          }
          const isPosition = data?.rowType === 'RATE_POSITION';
          const control = isPosition ? methodsPosition?.control : methodsRate?.control;
          name = isPosition ? `${Math.abs(data?.id!)}.title` : name;
          const handleSubmit = isPosition
            ? methodsPosition?.handleSubmit((v) => submitPosition(v, data?.id!, data.parentID!, data.folder!))
            : methodsRate?.handleSubmit(submit);

          const error = isPosition
            ? !!errors.position[Math.abs(data!.id) as any]?.title
            : (data!.lvl === 1 && !!errors.rate[`level1` as any]) ||
              (data!.lvl === 2 && !!errors.rate[`level2` as any]) ||
              (data!.lvl === 3 && !!errors.rate[`level3` as any]) ||
              ((data!.lvl === 4 || data!.lvl === null) && errors.rate.estimate);
          const refPopper = useRef<HTMLDivElement | null>(null);
          // console.log(name);
          // console.log(isPosition);
          return data?.create ? (
            <Controller
              control={control as any}
              rules={{
                validate: {
                  maxLength(v) {
                    if (v && v.length > 300) return 'Максимум 300 символов';
                    return true;
                  },
                  // max: (v: string) => {
                  //   const num = Number((v as string)?.replace(/\s/g, '').replace(/,/g, '.'));
                  //   if (num >= 999999999.99) {
                  //     return 'Максимальное значение 999 999 999,99';
                  //   }
                  //   return true;
                  // },
                  // min: (v: string) => {
                  //   const num = Number((v as string)?.replace(/\s/g, '').replace(/,/g, '.'));
                  //   if (num <= -999999999.99) {
                  //     return 'Минимальное значение -999 999 999,99';
                  //   }
                  //   return true;
                  // },
                },
              }}
              render={({ field: { ref, ...props }, formState }) => {
                const errorMessage =
                  // @ts-ignore
                  formState.errors?.[name]?.message || formState.errors?.[Math.abs(data?.id)]?.title?.message;
                return (
                  <>
                    <PRStyledBaseTextField
                      size="small"
                      hiddenLabel
                      fullWidth
                      placeholder={error ? 'Поле обязательно к заполнению' : 'Введите наименование'}
                      // disabled={isCreated && data!.id < -100}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleSubmit?.(e);
                        }
                      }}
                      sx={{
                        ...((error || !!errorMessage) && { background: '#FFB1B126 !important' }),
                        width: '100%',
                        height: '100%',
                        border: 'none !important',
                      }}
                      InputProps={{
                        sx: {
                          ...((error || !!errorMessage) && { background: '#FFB1B126 !important' }),
                          width: '100%',
                          height: '100%',
                          border: 'none  !important',
                        },
                      }}
                      error={error || errorMessage}
                      inputRef={refPopper}
                      {...props}
                      onChange={({ target: { value } }) => {
                        isPosition
                          ? methodsPosition?.setValue(name as never, value as never, { shouldValidate: true })
                          : methodsRate?.setValue(name as never, value as never, { shouldValidate: true });
                      }}
                    />
                    <PopperComponent message={errorMessage} refItem={refPopper.current} />
                  </>
                );
              }}
              name={name as any}
            />
          ) : (
            <Cell color={'#2B3648'} showTooltip={!data?.create} float={'left'}>
              {data?.title}
            </Cell>
          );
        },
      },
      {
        headerName: 'Ед. изм.',
        width: 280,
        children: [
          {
            field: 'unit',
            width: 280,
            // colSpan: (params) => (params.data?.rowType === 'RATE' ? 2 : 1),
            maxWidth: 280,
            minWidth: 280,
            headerName: 'Расход ресурса',
            cellClass: (params) => {
              return params.data!.rowType !== 'FOLDER' ? 'grouped-unit-resource border-right-price-ref' : '';
            },
            editable: (props) => {
              return false;
            },
            suppressKeyboardEvent: (params) => {
              return true;
            },
            cellEditor: CellEditor,
            valueSetter,

            cellRenderer: (props: any) => <UnitCell props={props} setter={valueSetter} />,
          },
        ],
      },
      {
        field: 'pz',
        width: 140,
        maxWidth: 140,
        minWidth: 140,
        headerName: 'ПЗ',
        editable: (props) => props.data?.rowType === 'RATE_POSITION' && !props.data?.create,
        cellEditor: CellEditor,
        valueGetter: (params: ValueGetterParams) => {
          return params?.data?.pz;
        },
        cellClass: (params) => {
          const hover = params.data?.rowType === 'RATE_POSITION' && !params.data?.create ? 'cell-hover ' : '';
          return params.data?.create ? hover + 'remove-padding' : hover + '';
        },
        suppressKeyboardEvent: (params) => {
          return !!params.data?.create;
        },
        valueSetter,
        cellRenderer: ({ data, value }: ICellRendererParams<IEstimateReference, any, any>) => {
          const { submitPosition, errors, methodsPosition } = useContext(PricesReferenceContext);
          const { isCreated } = useSelector(getPricesState);
          const error = !!errors.position[Math.abs(data!.id) as any]?.pz;
          const ref = useRef<HTMLInputElement | null>(null);
          return data?.create && data.rowType === 'RATE_POSITION' ? (
            <Box
              component={'form'}
              width={'100%'}
              height={'100%'}
              id={`references-estimate-position`}
              onSubmit={methodsPosition?.handleSubmit((v) => {
                submitPosition(v, data.id, data.parentID!, data.folder!);
              })}>
              <Controller
                control={methodsPosition?.control}
                name={`${Math.abs(data?.id!)}.pz` as never}
                rules={{
                  validate: {
                    max: (v: string) => {
                      const num = Number((v as string)?.replace(/\s/g, '').replace(/,/g, '.'));
                      if (num >= 999999999.99) {
                        return 'Максимальное значение 999 999 999,99';
                      }
                      return true;
                    },
                    min: (v: string) => {
                      const num = Number((v as string)?.replace(/\s/g, '').replace(/,/g, '.'));
                      if (num <= -999999999.99) {
                        return 'Минимальное значение -999 999 999,99';
                      }
                      return true;
                    },
                  },
                }}
                render={({ field: { ref: refControl, onChange, ...props }, formState }) => {
                  const errorMessage = formState.errors[Math.abs(data?.id!)]?.pz?.message;
                  return (
                    <>
                      <PRNumericField
                        disabled={isCreated && data!.id > 0}
                        getInputRef={(refItem: any) => {
                          ref.current = refItem;
                        }}
                        {...props}
                        value={props.value}
                        aria-errormessage={errorMessage}
                        onValueChange={(event) => {
                          onChange(event.formattedValue.replace(/\./, ','));
                          // setFormValue('input', event.floatValue, { shouldValidate: true });
                        }}
                        style={{
                          textAlign: 'center',
                          ...((error || !!errorMessage) && {
                            background: '#FFB1B126',
                            borderColor: '#F46B6B',
                          }),
                        }}
                        onFocus={(e: { target: { select: () => void } }) => e.target.select()}
                        allowedDecimalSeparators={[',', '.']}
                        allowNegative={true}
                        decimalSeparator={','}
                        decimalScale={2}
                        thousandSeparator={' '}
                      />
                      <PopperComponent message={errorMessage} refItem={ref.current} />
                    </>
                  );
                }}
              />
            </Box>
          ) : (
            <Cell color={'#2B3648'} float={'right'}>
              {modifyValue(value, true)}
            </Cell>
          );
        },
      },
      {
        field: 'fot',
        width: 140,
        maxWidth: 140,
        minWidth: 140,
        headerName: 'ФОТ ОР',
        editable: (params)=>!!checkEditable(params.data!,false),
        // editable: (params) => params.data?.rowType === 'RATE'
        //   ? !params.data?.hasChildren||!params.data.children||params.data!.resourceType!?fotRequired.includes(params.data!.resourceType!):!params.data?.hasChildren||!params.data.children?.length||!params.data?.hasChildren:false,
        // editable: (params) => params.data?.rowType === 'RATE'
        //   ? params.data!.resourceType!?fotRequired.includes(params.data!.resourceType!):!params.data.children?.length
        //   : params.data?.rowType === 'RATE_POSITION' && fotRequired.includes(params.data!.resourceType!),
        cellEditor: CellEditor,
        valueGetter: (params: ValueGetterParams) => {
          return params?.data?.fot;
        },
        suppressKeyboardEvent: (params) => {
          return !!params.data?.create;
        },
        cellClass: (params) => {
          const hover =checkEditable(params.data!,true) as string;
          return params.data?.create ? hover + 'remove-padding' : hover + '';
        },
        // cellClass: (params) => {
        //   const hover =
        //     params.data?.rowType === 'RATE'
        //       ? params.data!.resourceType!?fotRequired.includes(params.data!.resourceType!)&&'cell-hover':!params.data.children?.length
        //         ? 'cell-hover'
        //         : ''
        //       : params.data?.rowType === 'RATE_POSITION' && fotRequired.includes(params.data!.resourceType!)
        //         ? 'cell-hover'
        //         : '';
        //   return params.data?.create ? hover + 'remove-padding' : hover + '';
        // },
        valueSetter,
        cellRenderer: ({ data, value }: ICellRendererParams<IEstimateReference, any, any>) => {
          const { methodsRate, submit } = useContext(PricesReferenceContext);
          return data?.create && data?.id! < 0 && data.rowType === 'RATE' && !data?.children?.length ? (
            <Box
              component={'form'}
              width={'100%'}
              height={'100%'}
              id={`references-estimate-position`}
              onSubmit={methodsRate!.handleSubmit(submit)}>
              <Controller
                control={methodsRate!.control}
                name={`fot` as never}
                render={({ field: { ref, onChange, ...props } }) => {
                  return (
                    <PRStyledBaseTextField
                      onChange={(e) => {
                        e.target.value = e.target.value.replace(/\D/g, '');
                        onChange(e);
                      }}
                      inputRef={ref}
                      {...props}
                    />
                  );
                }}
              />
            </Box>
          ) : (
            <Cell color={'#2B3648'} float={'right'}>
              {modifyValue(value)}
            </Cell>
          );
        },
      },
      {
        field: 'mim',
        width: 140,
        maxWidth: 140,
        minWidth: 140,
        headerName: 'МиМ',
        editable: (params) => !!checkEditable(params.data!),
        // editable: (params) => params.data?.rowType === 'RATE'
        //   ? params.data!.resourceType!?mimRequired.includes(params.data!.resourceType!):!params.data.children?.length
        //   : params.data?.rowType === 'RATE_POSITION' && mimRequired.includes(params.data!.resourceType!),
        cellEditor: CellEditor,
        valueGetter: (params: ValueGetterParams) => {
          return params?.data?.mim;
        },
        suppressKeyboardEvent: (params) => {
          return !!params.data?.create;
        },
        cellClass: (params) => {
          const hover =checkEditable(params.data!,true) as string;
        // cellClass: (params) => {
        //   const hover =
        //   params.data?.rowType === 'RATE'
        //     ? params.data!.resourceType!?mimRequired.includes(params.data!.resourceType!)&&'cell-hover':!params.data.children?.length
        //       ? 'cell-hover'
        //       : ''
        //     : params.data?.rowType === 'RATE_POSITION' && mimRequired.includes(params.data!.resourceType!)
        //       ? 'cell-hover'
        //       : '';
          return params.data?.create ? hover + 'remove-padding' : hover + '';
        },
        valueSetter,
        cellRenderer: ({ data, value }: ICellRendererParams<IEstimateReference, any, any>) => {
          const { submit, methodsRate } = useContext(PricesReferenceContext);

          return data?.create && data?.id! < 0 && data.rowType === 'RATE' && !data?.children?.length ? (
            <Box
              component={'form'}
              width={'100%'}
              height={'100%'}
              id={`references-estimate-position`}
              onSubmit={methodsRate!.handleSubmit(submit)}>
              <Controller
                control={methodsRate!.control}
                name={`mim` as never}
                render={({ field: { ref, onChange, ...props } }) => {
                  return (
                    <PRStyledBaseTextField
                      onChange={(e) => {
                        e.target.value = e.target.value.replace(/\D/g, '');
                        onChange(e);
                      }}
                      inputRef={ref}
                      {...props}
                    />
                  );
                }}
              />
            </Box>
          ) : (
            <Cell color={'#2B3648'} float={'right'}>
              {modifyValue(value)}
            </Cell>
          );
        },
      },
      {
        field: 'mtr',
        width: 140,
        maxWidth: 140,
        minWidth: 140,
        headerName: 'МТР',
        cellClass: (params) => {
          const hover =checkEditable(params.data!,true) as string;
        // cellClass: (params) => {
        //   const hover =
        //     params.data?.rowType === 'RATE'
        //       ? params.data!.resourceType!?mtrRequired.includes(params.data!.resourceType!)&&'cell-hover':!params.data.children?.length
        //         ? 'cell-hover'
        //         : ''
        //       : params.data?.rowType === 'RATE_POSITION' && mtrRequired.includes(params.data!.resourceType!)
        //         ? 'cell-hover'
        //         : '';
          return params.data?.create ? hover + 'remove-padding' : hover + '';
        },
        editable: (params) => !!checkEditable(params.data!),
        // editable: (params) => params.data?.rowType === 'RATE'
        //   ? params.data!.resourceType!?mtrRequired.includes(params.data!.resourceType!):!params.data.children?.length
        //   : params.data?.rowType === 'RATE_POSITION' && mtrRequired.includes(params.data!.resourceType!),
        cellEditor: CellEditor,
        valueGetter: (params: ValueGetterParams) => {
          return params?.data?.mtr;
        },
        suppressKeyboardEvent: (params) => {
          return !!params.data?.create;
        },
        valueSetter,
        cellRenderer: ({ data, value }: ICellRendererParams<IEstimateReference, any, any>) => {
          const { submit, methodsRate } = useContext(PricesReferenceContext);
          return data?.create && data?.id! < 0 && data.rowType === 'RATE' && !data.children.length ? (
            <Box
              component={'form'}
              width={'100%'}
              height={'100%'}
              id={`references-estimate-position`}
              onSubmit={methodsRate!.handleSubmit(submit)}>
              <Controller
                control={methodsRate!.control}
                name={`mtr` as never}
                render={({ field: { ref, onChange, ...props } }) => {
                  return (
                    <PRStyledBaseTextField
                      onChange={(e) => {
                        e.target.value = e.target.value.replace(/\D/g, '');
                        onChange(e);
                      }}
                      inputRef={ref}
                      {...props}
                    />
                  );
                }}
              />
            </Box>
          ) : (
            <Cell color={'#2B3648'} float={'right'}>
              {modifyValue(value)}
            </Cell>
          );
        },
      },
      {
        field: 'totalWorkTime',
        width: 140,
        maxWidth: 140,
        minWidth: 140,
        headerName: 'Общее рабочее время',
        editable: (props) =>
          props.data?.rowType === 'RATE' ?
            ('hasChildren' in props.data&& props.data?.hasChildren !== null)?!props.data.hasChildren:!props.data.children?.length
            : props.data?.rowType === 'RATE_POSITION',
        cellEditor: CellEditor,
        valueGetter: (params: ValueGetterParams) => {
          return params?.data?.totalWorkTime;
        },
        suppressKeyboardEvent: (params) => {
          return !!params.data?.create;
        },
        cellClass: (params) => {
          const hover =
            params.data?.rowType === 'RATE'
              ?
              ('hasChildren' in params.data && params.data?.hasChildren !== null)?
                !params.data.hasChildren?
                  'cell-hover '
                  : ''
                :!params.data.children?.length?
                  'cell-hover '
                  : ''
              : params.data?.rowType === 'RATE_POSITION'
              ? 'cell-hover '
              : '';
          return params.data?.create ? hover + 'remove-padding' : hover + '';
        },
        valueSetter,
        cellRenderer: ({ data, value }: ICellRendererParams<IEstimateReference, any, any>) => {
          const { submitPosition, methodsPosition } = useContext(PricesReferenceContext);
          const { isCreated } = useSelector(getPricesState);

          const refPopper = useRef<HTMLInputElement | null>(null);

          return data?.create &&
            ((data?.rowType === 'RATE' && !data?.children?.length) || data?.rowType === 'RATE_POSITION') ? (
            <Box
              component={'form'}
              width={'100%'}
              height={'100%'}
              id={`references-estimate-position`}
              onSubmit={methodsPosition?.handleSubmit((v) => submitPosition(v, data.id, data.parentID!, data.folder!))}>
              <Controller
                control={methodsPosition?.control}
                name={`${Math.abs(data?.id!)}.workTime` as never}
                rules={{
                  validate: {
                    max: (v: string) => {
                      const num = Number((v as string)?.replace(/\s/g, '').replace(/,/g, '.'));
                      if (num >= 999999999.99) {
                        return 'Максимальное значение 999 999 999,99';
                      }
                      return true;
                    },
                    min: (v: string) => {
                      const num = Number((v as string)?.replace(/\s/g, '').replace(/,/g, '.'));
                      if (num <= -999999999.99) {
                          return 'Минимальное значение -999 999 999,99';
                      }
                      return true;
                    },
                  },
                }}
                render={({ field: { ref, onChange, ...props }, formState }) => {
                  const errorMessage = formState.errors[Math.abs(data?.id!)]?.workTime?.message;
                  return (
                    <>
                      <PRNumericField
                        {...props}
                        getInputRef={(refItem: any) => {
                          refPopper.current = refItem;
                        }}
                        disabled={isCreated && data!.id > 0}
                        value={props.value}
                        aria-errormessage={errorMessage}
                        onValueChange={(event) => {
                          onChange(event.formattedValue.replace(/\./, ','));
                        }}
                        style={{
                          textAlign: 'center',
                          ...(!!errorMessage && {
                            background: '#FFB1B126',
                            borderColor: '#F46B6B',
                          }),
                        }}
                        onFocus={(e: { target: { select: () => void } }) => e.target.select()}
                        allowedDecimalSeparators={[',', '.']}
                        allowNegative={true}
                        decimalSeparator={','}
                        decimalScale={2}
                        thousandSeparator={' '}
                      />
                      <PopperComponent message={errorMessage} refItem={refPopper.current} />
                    </>
                  );
                }}
              />
            </Box>
          ) : (
            <Cell color={'#2B3648'} float={'right'}>
              {modifyValue(value)}
            </Cell>
          );
        },
      },
      {
        field: 'kfo',
        headerName: 'КФО',
        children: [
          {
            field: 'kfoName',
            width: 200,
            maxWidth: 200,
            minWidth: 200,
            headerName: 'Группа',
            headerComponent: HeaderComponent,
            editable: (props) => {
              return props.data?.rowType === 'RATE' && !props.data.create;
            },
            cellEditor: CellEditor,
            valueGetter: (params: ValueGetterParams) => {
              return params?.data?.kfoName;
            },
            valueSetter,
            cellClass: (params) => {
              const hover = params.data?.rowType === 'RATE' && !params.data.create ? 'cell-hover ' : '';
              return params.data?.create ? hover + 'remove-padding' : hover + '';
            },
            suppressKeyboardEvent: (params) => {
              return !!params.data?.create;
            },
            cellRenderer: (params: ICellRendererParams<any, any, any>) => {
              const { submit: submitRate, deleteGroup, errors, methodsRate } = useContext(PricesReferenceContext);
              const { groupList } = useSelector(getPricesState);
              let error: boolean = false;
              error = !!params.data!.create && errors.rate.kfoName;
              const refPopper = useRef<HTMLDivElement | null>(null);
              return params.data?.create && params.data?.rowType === 'RATE' ? (
                <Box
                  component={'form'}
                  onSubmit={methodsRate?.handleSubmit((v) => submitRate(v))}
                  display={'flex'}
                  alignItems={'center'}
                  width={'100%'}
                  height={'100%'}>
                  <Controller
                    control={methodsRate?.control}
                    name={'kfoName'}
                    rules={{
                      validate: {
                        maxLength(v) {
                          if (v && v.length > 10) {
                            return 'максимум 10 символов';
                          }
                          return true;
                        },
                      },
                    }}
                    render={({ field: { ref, ...props }, formState }) => {
                      const errorMessage = formState.errors?.kfoName?.message;
                      return (
                        <>
                          <StyledAutocomplete
                            ref={refPopper}
                            {...props}
                            sx={{ width: '100%', display: 'flex', alignItems: 'center' }}
                            options={groupList.map((_) => ({ ..._, label: _.title }))}
                            aria-valuemax={10}
                            filterOptions={(options: any[], params) => {
                              const filtered = filterGroup(options, params);
                              const { inputValue } = params;
                              const isExisting = options.some((option) => inputValue === option.title);
                              if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                  id: -1,
                                  title: 'Нет совпадений',
                                  label: 'Нет совпадений',
                                });
                              }
                              return filtered;
                            }}
                            componentsProps={{
                              paper: {
                                sx: {
                                  width: 200,
                                },
                              },
                            }}
                            value={props.value || ''}
                            inputValue={props.value || ''}
                            freeSolo={true}
                            noOptionsText="Будет создана новая запись"
                            disableClearable={true}
                            clearOnBlur={false}
                            autoSelect={true}
                            onChange={(event, val) => {
                              if (typeof val === 'string') {
                                props.onChange(val);
                              }
                            }}
                            openOnFocus
                            onHighlightChange={() => {}}
                            onInputChange={(event, val) => {
                              if (typeof val === 'string') {
                                methodsRate?.setValue('kfoName', val?.toLowerCase());
                              }
                            }}
                            renderInput={(p) => (
                              <PRStyledBaseTextField
                                {...p}
                                hiddenLabel
                                placeholder={'Не выбрано'}
                                $textAlign={'center'}
                                InputProps={{
                                  ...p.InputProps,
                                  ...((error || !!errorMessage) && { sx: { background: '#FFB1B126 !important' } }),
                                }}
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                                inputProps={{
                                  ...p?.inputProps,
                                  maxLength: 10,
                                }}
                                size="small"
                                error={error}
                              />
                            )}
                            renderOption={(props, option: any) => {
                              return (
                                <MenuItem sx={{ width: '100%' }} {...props} disabled={option.id < 0} key={option.id}>
                                  <Box
                                    width={'100%'}
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}>
                                    {option.title}
                                    <IconButton
                                      onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        deleteGroup(option.id);
                                      }}>
                                      <DeleteOutlineIcon fontSize={'small'} />
                                    </IconButton>
                                  </Box>
                                </MenuItem>
                              );
                            }}
                          />
                          <PopperComponent message={errorMessage} refItem={refPopper.current} />
                        </>
                      );
                    }}
                  />
                </Box>
              ) : (
                <Cell color={'#2B3648'} float={'center'}>
                  {params.value?.toLocaleString('ru-RU', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Cell>
              );
            },
          },
          {
            field: 'kfoUnit',
            width: 140,
            maxWidth: 140,
            minWidth: 140,
            headerName: 'Ед. изм.',
            editable: (props) => {
              return props.data?.rowType === 'RATE' && !props.data.create;
            },
            cellEditor: CellEditor,
            valueGetter: (params: ValueGetterParams) => {
              return params?.data?.kfoUnit;
            },
            valueSetter,
            cellClass: (params) => {
              const hover = params.data?.rowType === 'RATE' && !params.data.create ? 'cell-hover ' : '';
              return params.data?.create ? hover + 'remove-padding' : hover + '';
            },
            suppressKeyboardEvent: (params) => {
              return !!params.data?.create && params.data?.rowType === 'RATE';
            },

            cellRenderer: (params: ICellRendererParams<any, any, any>) => {
              const { submit: submitRate, deleteUnit, errors, methodsRate } = useContext(PricesReferenceContext);
              const { unitList } = useSelector(getPricesState);
              const handleSubmit = methodsRate?.handleSubmit((v) => submitRate(v));
              const control = methodsRate?.control;
              const name = 'kfoUnit';
              let error: boolean = false;
              error = !!params.data!.create && errors.rate.kfoUnit;
              const refPopper = useRef<HTMLDivElement | null>(null);
              return params.data?.create && params.data?.rowType === 'RATE' ? (
                <Box
                  component={'form'}
                  onSubmit={handleSubmit}
                  display={'flex'}
                  alignItems={'center'}
                  width={'100%'}
                  height={'100%'}>
                  <Controller
                    control={control as any}
                    name={name}
                    rules={{
                      validate: {
                        maxLength(v) {
                          if (v && v.length > 10) {
                            return 'максимум 10 символов';
                          }
                          return true;
                        },
                      },
                    }}
                    render={({ field: { ref, ...props }, formState }) => {
                      const errorMessage = formState.errors?.kfoUnit?.message as string | undefined;

                      return (
                        <>
                          <StyledAutocomplete
                            ref={refPopper}
                            {...props}
                            sx={{ width: '100%', display: 'flex', alignItems: 'center' }}
                            options={unitList.map((_) => ({ ..._, label: _.name }))}
                            aria-valuemax={10}
                            filterOptions={(options: any[], params) => {
                              const filtered = filterUnit(options, params);
                              const { inputValue } = params;
                              const isExisting = options.some((option) => inputValue === option.name);
                              if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                  id: -1,
                                  name: 'Нет совпадений',
                                  label: 'Нет совпадений',
                                });
                              }
                              return filtered;
                            }}
                            componentsProps={{
                              paper: {
                                sx: {
                                  width: 200,
                                },
                              },
                            }}
                            freeSolo={true}
                            noOptionsText="Будет создана новая запись"
                            disableClearable={true}
                            clearOnBlur={false}
                            autoSelect={false}
                            onChange={(event, val) => {
                              if (typeof val === 'string') {
                                props.onChange(val);
                              }
                            }}
                            openOnFocus
                            onHighlightChange={() => {}}
                            onInputChange={(event, val) => {
                              if (typeof val === 'string') {
                                props.onChange(val);
                              }
                            }}
                            autoHighlight={false}
                            renderInput={(p) => (
                              <PRStyledBaseTextField
                                {...p}
                                hiddenLabel
                                placeholder={'Не выбрано'}
                                InputProps={{
                                  ...p.InputProps,
                                  ...((error || !!errorMessage) && { sx: { background: '#FFB1B126 !important' } }),
                                }}
                                $textAlign={'center'}
                                inputProps={{
                                  ...p?.inputProps,
                                  maxLength: 10,
                                }}
                                size="small"
                                error={error}
                              />
                            )}
                            renderOption={(props, option: any) => {
                              return (
                                <MenuItem sx={{ width: '100%' }} {...props} disabled={option.id < 0} key={option.id}>
                                  <Box
                                    width={'100%'}
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}>
                                    {option.name}
                                    {option.id > 10 ? (
                                      <IconButton
                                        onClick={(event) => {
                                          event.preventDefault();
                                          event.stopPropagation();
                                          deleteUnit(option.id);
                                        }}>
                                        <DeleteOutlineIcon fontSize={'small'} />
                                      </IconButton>
                                    ) : (
                                      <span />
                                    )}
                                  </Box>
                                </MenuItem>
                              );
                            }}
                          />
                          <PopperComponent message={errorMessage} refItem={refPopper.current} />
                        </>
                      );
                    }}
                  />
                </Box>
              ) : (
                <Cell color={'#2B3648'} float={'center'}>
                  {params.value?.toLocaleString('ru-RU', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Cell>
              );
            },
          },
          {
            field: 'kfoCoefficient',
            width: 140,
            maxWidth: 140,
            minWidth: 140,
            headerName: 'Коэффициент',
            editable: (props) => {
              return props.data?.rowType === 'RATE' && !props.data.create;
            },
            cellEditor: CellEditor,
            valueGetter: (params: ValueGetterParams) => {
              return params?.data?.kfoCoefficient;
            },
            valueSetter,
            cellClass: (params) => {
              const hover = params.data?.rowType === 'RATE' && !params.data.create ? 'cell-hover ' : '';
              return params.data?.create ? hover + 'remove-padding' : hover + '';
            },
            suppressKeyboardEvent: (params) => {
              return !!params.data?.create;
            },

            cellRenderer: ({ data, value }: ICellRendererParams<any, any, any>) => {
              const { submit, methodsRate } = useContext(PricesReferenceContext);
              // const { isCreated } = useSelector(getPricesState);
              const refPopper = useRef<HTMLDivElement | null>(null);
              return data?.create && data?.rowType === 'RATE' ? (
                <Box
                  component={'form'}
                  width={'100%'}
                  height={'100%'}
                  id={`references-estimate`}
                  onSubmit={methodsRate?.handleSubmit((v) => submit(v))}>
                  <Controller
                    control={methodsRate?.control}
                    name={`kfoCoefficient` as never}
                    rules={{
                      validate: {
                        max: (v: string) => {
                          const num = Number((v as string)?.replace(/\s/g, '').replace(/,/g, '.'));
                          if (num >= 999999999.999999) {
                            return 'Максимальное значение 999 999 999,999 999';
                          }
                          return true;
                        },
                        min: (v: string) => {
                          const num = Number((v as string)?.replace(/\s/g, '').replace(/,/g, '.'));
                          if (num <= -999999999.999999) {
                            return 'Минимальное значение -999 999 999,999 999';
                          }
                          return true;
                        },
                      },
                    }}
                    render={({ field: { ref, onChange, ...props }, formState }) => {
                      const errorMessage = formState.errors?.kfoCoefficient?.message;
                      return (
                        <>
                          <PRNumericField
                            {...props}
                            // disabled={isCreated && data!.id < -100}
                            getInputRef={(refItem: any) => {
                              refPopper.current = refItem;
                            }}
                            value={props.value}
                            aria-errormessage={errorMessage}
                            onValueChange={(event) => {
                              onChange(event.formattedValue.replace(/\./, ','));
                              // setFormValue('input', event.floatValue, { shouldValidate: true });
                            }}
                            style={{
                              textAlign: 'center',
                              ...(!!errorMessage && {
                                background: '#FFB1B126',
                                borderColor: '#F46B6B',
                              }),
                            }}
                            onFocus={(e: { target: { select: () => void } }) => e.target.select()}
                            allowedDecimalSeparators={[',', '.']}
                            allowNegative={true}
                            decimalSeparator={','}
                            decimalScale={6}
                            thousandSeparator={' '}
                          />
                          <PopperComponent message={errorMessage} refItem={refPopper.current} />
                        </>
                      );
                    }}
                  />
                </Box>
              ) : (
                <Cell color={'#2B3648'} float={'right'}>
                  {modifyValue(value, false)}
                </Cell>
              );
            },
          },
        ],
      },
      {
        field: 'description',
        width: 420,
        maxWidth: 420,
        minWidth: 420,
        headerName: 'Описание',
        editable: (props) => (props.data?.rowType === 'RATE' ? !props.data?.create : false),
        cellClass: (params) => {
          const hover = (params.data?.rowType === 'RATE' ? !params.data?.create : false) ? 'cell-hover ' : '';
          return params.data?.create ? hover + 'remove-padding' : hover + '';
        },
        cellEditor: CellEditor,
        valueGetter: (params: ValueGetterParams) => {
          return params?.data?.description || '';
        },
        suppressKeyboardEvent: (params) => {
          return !!params.data?.create;
        },
        valueSetter,
        cellRenderer: ({ data, value }: ICellRendererParams<IEstimateReference, any, any>) => {
          const { submit, methodsRate } = useContext(PricesReferenceContext);
          // const { isCreated } = useSelector(getPricesState);
          const refPopper = useRef<HTMLDivElement | null>(null);
          return data?.create && data?.rowType === 'RATE' ? (
            <Box
              component={'form'}
              display={'flex'}
              width={'100%'}
              height={'100%'}
              justifyContent={'flex-start'}
              id={`references-estimate`}
              onSubmit={methodsRate?.handleSubmit(submit)}>
              <Controller
                control={methodsRate?.control}
                name={`description` as never}
                rules={{
                  validate: {
                    maxLength(v) {
                      if (v && (v as string).length > 500) {
                        return 'Максимально 500 символов';
                      }
                      return true;
                    },
                  },
                }}
                render={({ field: { ref, ...props }, formState }) => {
                  //@ts-ignore
                  const errorMessage = formState.errors?.description?.message;
                  return (
                    <>
                      <PRStyledBaseTextField
                        // disabled={isCreated && data!.id < -100}
                        ref={refPopper}
                        inputRef={ref}
                        {...props}
                        onChange={(e) => methodsRate?.setValue(props.name, e.target.value! as never)}
                      />
                      <PopperComponent message={errorMessage || undefined} refItem={refPopper.current} />
                    </>
                  );
                }}
              />
            </Box>
          ) : (
            <Cell color={'#2B3648'} float={'left'}>
              {value}
            </Cell>
          );
        },
      },
    ];
  }, [valueSetter]);

  return columnsDef;
}

export const NoRows = () => {
  return <NoRowsTitle>Нет строк для отображения</NoRowsTitle>;
};
export const PopperComponent: FC<{ message: string | undefined; refItem: any; offset?: number[] }> = ({
  message,
  refItem,
  offset,
}) => {
  const style: any = { zIndex: 1301 };
  return (
    <StyledPopper
      style={style}
      popperOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: offset ?? [0, 4],
            },
          },
        ],
      }}
      open={!!message}
      anchorEl={refItem}
      placement={'bottom-end'}>
      {message}
    </StyledPopper>
  );
};
