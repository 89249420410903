import styled from 'styled-components'
import { Box, IconButton, Stack } from '@mui/material'

export const ImageItemWrapper = styled(Box)`
  position: relative;
  width: fit-content;
  & img {
    border-radius: 16px;
  }
`

export const ImageWrapper = styled(Stack)`
  position: absolute;
  top: 10px;
  right: 10px;
`

export const ImageItemDeleteButton = styled(IconButton)`

  padding: 4px;
  background-color: ${props => props.theme.palette.bg.white};
  border-radius: 6px;
  
  &:hover {
  background-color: ${props => props.theme.palette.bg.white};
    
  }
`