/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box, Tab, Tabs } from "@mui/material";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { GridSizeChangedEvent, RowClassParams, ValueSetterParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { ReactComponent as ExportIcon } from "assets/icons/price-reference/download-xls.svg";
import { ReactComponent as ImportIcon } from "assets/icons/price-reference/upload-xls.svg";
import { enqueueSnackbar } from "notistack";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { api } from "../../../../api/api";
import {
  useCreateEstimateMutation,
  useCreateFolderMutation,
  useCreateFolderOrRateMutation,
  useCreateKFOGroupMutation,
  useCreatePositionMutation,
  useCreateUnitWeightMutation,
  useDeleteKFOGroupMutation,
  useDeleteRatesMutation,
  useDeleteUnitWeightMutation,
  useGetDataWithFiltersMutation,
  useGetEstimateReferenceQuery,
  useGetKFOGroupQuery,
  useGetUnitListQuery,
  useLazyExportPriceReferenceQuery,
  useUpdateFolderMutation,
  useUpdateRateMutation,
  useUpdateRatePositionMutation
} from "../../../../api/references/estimates";
import {
  IDeleteRateParams,
  IEstimateReference,
  IUpdateRate,
  IUpdateRatePosition,
  resourceEng
} from "../../../../api/references/estimates/estimates.types";
import { socketPriceReference } from "../../../../api/websocketApi";
import Button from "../../../../components/Button";
import Progress from "../../../../components/Progress";
import { wsContext } from "../../../../contexts/ws";
import useConfirmDialog from "../../../../hooks/useConfirmDialog";
import {
  addGroup,
  addUnit,
  changeCreated,
  changeUUID,
  clearFilter,
  getPricesState,
  initialDataSet,
  removeGroup,
  removeItemInStoreByID,
  removeParentLevel,
  removeUnit,
  setTableFilter,
  updateTable
} from "../../../../store/slices/references/prices/prices";
import { useAppDispatch } from "../../../../store/store";
import { downloadBase64File } from "../../../../utils/downloadBase64File";
import { PageStyled, WrapperAgGrid } from "../../../Calculations/components/Accomplishment/Accomplishment.styles";
import WSCustomSnackbar from "../../../Lsr/components/WSCustomSnackbar/WSCustomSnackbar";
import {
  errorsState,
  filterChips,
  flatCount,
  FormPriceReference,
  FormPriceReferencePosition,
  IErrorData,
  initialErrorForm,
  IPriceReferenceContext,
  IToggleCreateDrawer,
  positionPlaceholder,
  resetGenForm,
  template,
  TFormStateValidationPosition,
  TFormStateValidationRate,
  TTabActive
} from "./PricesReference.types";
import DialogFilters from "./components/DialogFilters";
import ImportDrawer from "./components/ImportDrawer";
import { Chip, ChipWrapper, Devide, ImportButton } from "./components/PricesReferences.style";
import { usePriceReferencePageEffect } from "./usePriceReferencePageEffect";
import { NoRows, useTableData } from "./useTableData";
import { getNameField } from "../../../Calculations/components/CalculationDirectory/CalculationDirectory.types";
import Tooltip from "../../../../components/Tooltip";

const ProviderPositionForm = FormProvider;

const baseConfirm = {
  title:'Подтвердите удаление',
  body:'Возможно это значение используется в расчетах. Данные удалятся безвозвратно',
  isRedButton:true,
  handleConfirm:(confirm:boolean,fn?:()=>void)=>{
    if (confirm) {
      fn?.()
    }
  }
}

interface IDiff {
  [key: string]: any;
  uuid?: string;
  id: number;
}

export function diffArray<T extends IDiff>(
  targetArr: T[],
  searchedArr: T[],
  isArrReturn: boolean = false,
): T | T[] | undefined {
  const result: T[] = [];
  const uuidsArr = searchedArr.map((item) => ('uuid' in item ? item.uuid : item.id));
  if (isArrReturn) {
    targetArr.forEach((item) => {
      if (!uuidsArr.includes('uuid' in item ? item.uuid : item.id)) {
        result.push(item);
      }
    });
    return result;
  }
  return targetArr.find((item) => !uuidsArr.includes('uuid' in item ? item.uuid : item.id));
}

export const PricesReferenceContext = createContext<IPriceReferenceContext>({
  clearRemovedIds: () => {
    return;
  },
  collapse: () => {
    return;
  },
  collapseUUid: () => {
    return;
  },
  toggleDrawer: () => {},
  deleteRate: () => {},
  hiddenRows: [],
  hiddenRowsUUid: [],
  removedIds: [],
  checkboxClick: () => {
    return;
  },
  add: () => {
    return;
  },
  submit: () => {
    return;
  },
  submitPosition: () => {
    return;
  },
  deleteUnit: () => {
    return;
  },
  methodsRate: null,
  methodsPosition: null,
  deleteGroup: () => {
    return;
  },
  deleteFolderConfirm: () => {
    return;
  },
  errors: { rate: {}, position: {} },
  denyCreated: () => {
    return;
  },
  changeDialog: () => {
    return;
  },
});

const PricesReference = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation('references');

  const [searchParams, setSearchParams] = useSearchParams();

  /** ссылка на таблицу AgGrid вместе с функцией обновлением */

  const Ref = useRef<AgGridReact<IEstimateReference> | null>(null);

  const updateGrid = useCallback(
    (update: IEstimateReference[]) => {
      if (Ref.current) {
        const updateData = new Map();
        update.forEach((updated) => {
          updateData.set(updated.uuid, updated);
        });
        const rowData: IEstimateReference[] = [];
        Ref.current?.api.forEachNode(function (node) {
          if (node.data) {
            if (updateData.has(node.data.uuid)) {
              rowData.push(updateData.get(node.data.uuid));
            } else {
              rowData.push(node.data!);
            }
          }
        });
        Ref.current?.api?.applyTransaction({ update: rowData });
        Ref.current?.api?.refreshCells({ force: true });
      }
    },
    [Ref.current],
  );

  /** ------------------------------------------------------ */

  /** Блок функций-запросов */

  const [createFolder] = useCreateFolderMutation();
  const [createFolderOrRate] = useCreateFolderOrRateMutation();
  const [createEstimate] = useCreateEstimateMutation();
  const [createPosition] = useCreatePositionMutation();

  const [updateRate, { isLoading: isFetchingRate }] = useUpdateRateMutation();
  const [updateRatePosition, { isLoading: isFetchingPosition }] = useUpdateRatePositionMutation();
  const [updateFolder, { isLoading: isFetchingFolder }] = useUpdateFolderMutation();

  const [exportReference] = useLazyExportPriceReferenceQuery();

  const [deleteRates] = useDeleteRatesMutation();

  const { data: unitWeightData } = useGetUnitListQuery({ nameLike: '' });
  const [createUnit] = useCreateUnitWeightMutation();
  const [deleteUnit] = useDeleteUnitWeightMutation();

  const { data: groupData } = useGetKFOGroupQuery({ nameLike: '' });
  const [createGroup] = useCreateKFOGroupMutation();
  const [deleteGroup] = useDeleteKFOGroupMutation();

  const [getFilteredData] = useGetDataWithFiltersMutation();

  const { data, isFetching, refetch } = useGetEstimateReferenceQuery({});

  /** --------------------- */

  const exportHandler = useCallback(() => {
    exportReference().then((response) => {
      if ('data' in response && response.data) {
        downloadBase64File(response.data, 'справочник.xlsx');
      }
    });
  }, []);

  const {
    template: templateLvl,
    parentLevel,
    data: Od,
    filteredData: applyedFiltersData,
    unitList,
    activeFilters,
    groupList,
    uuid,
    neighbor,
  } = useSelector(getPricesState);

  /** Блок инициализации состояний */

  const cWS = useContext(wsContext);

  const [importDrawer, setImportDrawer] = useState(false);

  const toggleImportDrawer = useCallback((bool?: boolean) => {
    if (bool !== undefined) {
      setImportDrawer(bool);
    } else {
      setImportDrawer((prevState) => !prevState);
    }
  }, []);

  const [clickRefetch, setClickRefetch] = useState(false);

  const [active, setActive] = useState<TTabActive>(null);

  const [filteredData, setFilteredData] = useState<IEstimateReference[] | undefined>(undefined);

  const [hiddenRowsIds, setHiddenRowsIds] = useState<number[]>([]);
  const [hiddenRowsUuids, setHiddenRowsUuids] = useState<string[]>([]);

  const [removedIds, setRemovedIds] = useState<{ type: string; id: number; parent: number | null }[]>([]);

  const [, setCreate] = useState<IToggleCreateDrawer>({ open: false, rowData: null, createdLevel: null });

  const [errorsForm, setErrorsForm] = useState<{
    position: TFormStateValidationPosition;
    rate: TFormStateValidationRate;
  }>(initialErrorForm);

  const methods = useForm<FormPriceReference>({
    mode: 'all',
    reValidateMode: 'onBlur',
    // resolver: yupResolver(schemaLevel),
    defaultValues: resetGenForm,
  });

  const methodsPositions = useForm<FormPriceReferencePosition>({
    mode: 'all',
    reValidateMode: 'onBlur',
    // resolver: yupResolver(schemaLevel),
    defaultValues: {},
  });

  const [selectedTab, setSelectedTab] = useState<number>(Number(searchParams.get('tab')));

  const toggleDrawer = (data: IToggleCreateDrawer) => setCreate(data);

  const removedState = useMemo(() => {
    const isRate = removedIds.some((el) => el.type === 'RATE');
    const countResources = removedIds.filter((el) => el.type === 'RATE_POSITION' && el.id > 0).length;
    console.log(
      isRate,
      removedIds.some((el) => el.type === 'RATE'),
    );
    return {
      title: isRate ? 'Подтвердите удаление расценки' : 'Подтвердите удаление ресурсов',
      body: isRate
        ? `Выбранная Вами расценка удалится вместе с ресурсами безвозвратно.
Количество ресурсов в расценке: ${countResources}.`
        : `Выбранные Вами ресурсы удалятся безвозвратно.\nКоличество: ${countResources}.`,
    };
  }, [removedIds]);

  /* Модалки подтверждения */
  const {openConfirm:openConfirmDeleteGroupOrUnit,ConfirmDialog:ConfirmDeleteGroupOrUnit} = useConfirmDialog(baseConfirm)

  const { openConfirm: openConfirmCreateRatePosition, ConfirmDialog: CreateRatePositionConfirm } = useConfirmDialog({
    handleConfirm: (confirm, fn) => {
      if (confirm) {
        fn?.();
      }
    },
    title: 'Подтвердите создание ресурса',
    denyButtonText: 'Отменить',
    confirmButtonText: 'Создать',
    body: (
      <span>
        Данные расценки в столбцах “Тип ресурса”
        <br /> “ПЗ”, “ФОТ ОР”, “МиМ”, “МТР”,
        <br /> “Общее рабочее время” перезапишутся
      </span>
    ),
  });
  const { openConfirm: openDeleteFolderFirstLevelConfirm, ConfirmDialog: DeleteFolderFirstLevelConfirmDialog } =
    useConfirmDialog({
      isRedButton: true,
      handleConfirm: (confirm, fn) => {
        if (confirm) {
          fn?.();
        }
      },
      title: 'Подтвердите удаление папки первого уровня с ресурсами',
      body: 'Выбранная Вами папка удалится безвозвратно',
    });
  const { openConfirm: openDeleteFolderSecondLevelConfirm, ConfirmDialog: DeleteFolderSecondLevelConfirmDialog } =
    useConfirmDialog({
      isRedButton: true,
      handleConfirm: (confirm, fn) => {
        if (confirm) {
          fn?.();
        }
      },
      title: 'Подтвердите удаление папки второго уровня с ресурсами',
      body: 'Выбранная Вами папка удалится безвозвратно',
    });
  const { openConfirm: openDeleteFolderThirdLevelConfirm, ConfirmDialog: DeleteFolderThirdLevelConfirmDialog } =
    useConfirmDialog({
      isRedButton: true,
      handleConfirm: (confirm, fn) => {
        if (confirm) {
          fn?.();
        }
      },
      title: 'Подтвердите удаление папки третьего уровня с ресурсами',
      body: 'Выбранная Вами папка удалится безвозвратно',
    });
  const { openConfirm: openDeleteRateConfirm, ConfirmDialog: DeleteRateConfirmDialog } = useConfirmDialog({
    isRedButton: true,
    handleConfirm: (confirm, fn) => {
      if (confirm) {
        fn?.();
      }
    },
    ...removedState,

    abortHandler: () => {
      console.log('close');
      setRemovedIds([]);
    },
  });
  // const { openConfirm: openDenyCreatedConfirm, ConfirmDialog: DenyCreatedConfirm } = useConfirmDialog({
  //   handleConfirm: (confirm) => {
  //     if (confirm) {
  //       dispatch(removeTemplate());
  //       dispatch(changeCreated(false));
  //       methods.reset(resetGenForm);
  //     }
  //   },
  //   title: 'Подтвердите выход из редактирования',
  //   body: 'Введенная информация не сохранится при переходе на другую страницу или при удалении создаваемых уровней',
  // });
  const getConfirmDialog = (lvl: number, fn: () => void) => {
    switch (lvl) {
      case 1:
        openDeleteFolderFirstLevelConfirm(fn);
        break;
      case 2:
        openDeleteFolderSecondLevelConfirm(fn);
        break;
      case 3:
        openDeleteFolderThirdLevelConfirm(fn);
        break;
      default:
        openDeleteRateConfirm(fn);
        break;
    }
  };
  /* --------------------- */

  /** ---------------------------- */

  /**
   * Здесь хранятся данные с сервера, в зависимости от того
   * какой запрос прошел, если есть активные фильтры то возвращаются отфильтрованные данные
   * иначе первоначальные данные полученные ранее
   */
  const originData = useMemo(() => {
    if (applyedFiltersData?.length === 0) {
      return undefined;
    }
    return applyedFiltersData || Od;
  }, [applyedFiltersData, Od]);

  const changeDialog = (key: TTabActive = null) => {
    setActive(key);
  };

  /**
   * Функция применения фильтров
   * берутся данные с redux и "мапятся" определенным образом
   * обновляется при обновлении активных фильтров
   */
  const applyFilters = useCallback(() => {
    const filters = {
      titles: activeFilters.titles.map((_) => _.id) as number[],
      codes: activeFilters.codes.map((_) => _.id) as number[],
      groups: activeFilters.groups.map((_) => _.id) as string[],
      resourceTypes: activeFilters.resourceTypes.map(
        (_) => resourceEng[_.name as keyof typeof resourceEng],
      ) as string[],
    };
    const isLength = Object.entries(filters).some((entry) => {
      const [, arr] = entry;
      return !!arr.length;
    });
    if (!isLength) {
      dispatch(setTableFilter(null));
      changeDialog();
      return;
    }
    getFilteredData(filters).then((response) => {
      if ('data' in response) {
        dispatch(setTableFilter(response.data));
        changeDialog();
      }
    });
  }, [activeFilters]);

  /**
   * Функция создания единицы измерения
   */
  const createUnitFn = useCallback(
    (unit: string) => {
      console.log(unit)
      const index = unitList.findIndex((_) => _.name === unit);
      if (index < 0) {
        createUnit({ name: unit }).then((response) => {
          if ('data' in response) {
            dispatch(addUnit(response.data));
          }
        });
      }
    },
    [unitList],
  );

  /**
   * Функция удаления группы КФО
   */
  const deleteGroupKFO = useCallback(
    (idGroup: number) => {
      openConfirmDeleteGroupOrUnit(()=>{
        deleteGroup({ groupID: idGroup }).then(() => {
          // console.log(resp);
          dispatch(removeGroup(idGroup));
        });
      })
      // console.log('removed group');
    },
    [groupList],
  );

  /**
   * Функция удаления еденицы измерения
   */
  const deleteUnitWeight = useCallback(
    (idUnit: number) => {
      openConfirmDeleteGroupOrUnit(()=>{
        deleteUnit({ unitID: idUnit }).then(() => {
          dispatch(removeUnit(idUnit));
        });
      })
    },
    [unitList],
  );

  /**
   * Суммирующая функция состояния отправки данных на обновление,
   * собирает состояние загрузки от обновления папки, расценки и позиции расценки
   */
  const isFetchingUpdate = useMemo(() => {
    return isFetchingFolder || isFetchingPosition || isFetchingRate;
  }, [isFetchingFolder, isFetchingPosition, isFetchingRate]);

  /**
   * Функция удаления расценки и позиции расценки
   */
  const deleteRatesWithPositions = useCallback(() => {
    const body: IDeleteRateParams = {
      rateIDs: [],
      ratesPositionIDs: [],
    };
    removedIds.forEach((candidate) => {
      switch (candidate.type) {
        case 'RATE':
          body.rateIDs.push(candidate.id);
          break;
        default:
          body.ratesPositionIDs.push(candidate.id);
          break;
      }
    });
    if (body.ratesPositionIDs.length > 0) {
      dispatch(removeItemInStoreByID(body.ratesPositionIDs));
    }
    deleteRates(body).then((resp) => {
      let refetch = true;
      if ('error' in resp) {
        refetch = false;
        const message = (resp as IErrorData<string>)?.error?.data;
        if (message) enqueueSnackbar({ variant: 'error', message: t(`estimates.${message}`), autoHideDuration: 5000 });
      } else {
        enqueueSnackbar({ variant: 'success', message: 'Успешно удалено' });
      }
      dispatch(removeParentLevel());

      setRemovedIds([]);
      // console.log(refetch);
      if (applyedFiltersData && refetch) {
        setTimeout(() => setClickRefetch(true));
      }
    });
  }, [removedIds, applyedFiltersData]);

  /**
   * Функция клика по чекбоксу, в зависимости от условий
   * либо добавляет в массив к удалению расценку\позицию, либо убирает
   */
  const clickCheckbox = useCallback(
    (id: number, type: string, parent: number | null) => {
      setRemovedIds((prevState) => {
        const candidate = originData?.find((_) => _.id === id);
        const removedCandidates: typeof prevState = [];

        const indexId = prevState.findIndex((_) => _.id === id);
        let copyRemoved = [...prevState];

        if (candidate?.rowType === 'RATE') {
          removedCandidates.push({ id: candidate!.id, type, parent });
          candidate.children.forEach((_) => removedCandidates.push({ id: _.id, type: _!.rowType, parent: _.parentID }));
          if (indexId >= 0) {
            copyRemoved = copyRemoved.filter((el) => {
              return removedCandidates.findIndex((_) => el.id === _.id) < 0;
            });
          } else {
            copyRemoved = [...new Set([...copyRemoved, ...removedCandidates])];
          }
        } else {
          if (indexId >= 0) {
            copyRemoved.splice(indexId, 1);
          } else {
            copyRemoved.push({ id, type, parent });
          }
        }
        copyRemoved = copyRemoved.filter((_) => _.id > 0);
        return copyRemoved;
      });
    },
    [originData],
  );

  /**
   * Получение текущего состояния формы создания темплейта (уровней с вложенной расценкой)
   */
  const getFormState = useCallback(() => {
    return {
      shifr: methods.getValues('shifr'),
      level1: methods.getValues('level1'),
      level2: methods.getValues('level2'),
      level3: methods.getValues('level3'),
      unit: methods.getValues('unit'),
    };
  }, [methods]);

  /**
   * Функция вставки темплейта между строк и папок
   * принимает:
   * @param {number|string} templateKey - номер в объекте template
   * @param {number} idx - порядковый номер позиции в таблице
   * @param {number} lvl - уровень объекта на котором произошел клик
   * @param {number} parent - id родительского объекта
   */
  const addRow = useCallback(
    (templateKey: number | string, idx: number, lvl: number, parent: number) => {
      /* Копируется актуальный (полный) массив данных таблицы */
      const copy = [...(originData || [])];

      function getFolder(id: string | null): number | null {
        const target = copy.find((estimate) => estimate.uuid === id);
        if (target) {
          if (target.rowType === 'FOLDER') {
            return target.id;
          }
          return getFolder(target.parentUUID);
        }
        return null;
      }

      /**
       * Ищется текущий объект, поиск ведется по отфильтрованным данным
       * это делается на случай если во время клика были свернуты уровни
       */
      const currentTarget = filteredData?.[idx];
      console.log(template, templateKey, template[templateKey as any]);
      /* Подготавливаются данные для обновления */
      let updateData = template[templateKey as keyof typeof template].map((_: any) => ({
        ..._,
        parentID: parent,
        folder: getFolder(currentTarget!.uuid),
        id: -100 - (currentTarget?.id ?? 0),
      }));

      console.log(updateData);

      /**
       * Определяется актуальный индекс в основном массиве
       */
      let originIdx = originData?.findIndex((item) => item.id === currentTarget?.id && currentTarget.lvl === item.lvl);

      /* если не был найден в него записывается ноль */
      if (!originIdx) {
        originIdx = 0;
      }
      (async () => {
        try {
          if (templateKey === 1) {
            if (currentTarget && originIdx >= 0) {
              if (templateKey === currentTarget.lvl) {
                copy.splice(flatCount(currentTarget) + 1 + originIdx, 0, ...updateData);
                setTimeout(() => {
                  Ref.current?.api.ensureIndexVisible(flatCount(currentTarget) + 1 + originIdx!, 'middle');
                }, 200);
              } else {
                // console.log(2);
                copy.splice(originIdx + 1, 0, ...updateData);
              }
              dispatch(changeCreated(true));
            }
            // console.log(copy);
            dispatch(initialDataSet(copy));
          } else {
            const keys: Array<keyof IEstimateReference> = ['resourceType', 'fot', 'mim', 'mtr', 'totalWorkTime'];
            const isShowModal = keys.some((key) => {
              return !!currentTarget?.[key];
            });
            if (
              currentTarget?.rowType === 'RATE' &&
              currentTarget?.children?.length === 0 &&
              isShowModal &&
              updateData[0].rowType === 'RATE_POSITION'
            ) {
              openConfirmCreateRatePosition(() => {
                if (currentTarget && originIdx !== null && originIdx! >= 0) {
                  if (templateKey === 5) {
                    createPosition({
                      body: {
                        insertAfterID: currentTarget!.rowType === 'RATE' ? null : currentTarget!.id,
                        title: null,
                        fot: null,
                        mtr: null,
                        mim: null,
                        qty: null,
                        type: null,
                        pz: null,
                        unit: null,
                        workTime: null,
                      },
                      folderID: updateData[0]!.folder,
                      rateID: currentTarget!.rowType === 'RATE' ? currentTarget!.id : updateData[0]!.parentID,
                    }).then((response) => submitThenHandler(response, undefined, undefined, true));
                  } else {
                    createFolderOrRate({
                      body: {
                        folder:
                          templateKey < 4
                            ? {
                                ...updateData[0],
                                title: null,
                                code: null,
                              }
                            : null,
                        insertAfterID: templateKey === currentTarget!.lvl ? null : currentTarget!.id || null,
                        rate:
                          templateKey === 4
                            ? {
                                type: null,
                                insertAfterID: templateKey === currentTarget!.lvl ? null : currentTarget!.id || null,
                                title: null as any,
                                unit: null as any,
                                mim: null,
                                fot: null,
                                mtr: null,
                                description: null,
                                kfoCoefficient: null,
                                kfoGroup: null,
                                kfoUnit: null,
                              }
                            : null,
                      },
                      folderID: templateKey === currentTarget!.lvl ? currentTarget!.parentID! : currentTarget!.id,
                    }).then((resp) => submitThenHandler(resp));
                  }
                }
              });
            } else {
              if (templateKey === 5) {
                createPosition({
                  body: {
                    insertAfterID: currentTarget!.rowType === 'RATE' ? null : currentTarget!.id,
                    title: null,
                    fot: null,
                    mtr: null,
                    mim: null,
                    qty: null,
                    type: null,
                    pz: null,
                    unit: null,
                    workTime: null,
                  },
                  folderID: updateData[0]!.folder,
                  rateID: currentTarget!.rowType === 'RATE' ? currentTarget!.id : updateData[0]!.parentID,
                }).then((response) => submitThenHandler(response, undefined, undefined, true));
              } else {
                console.log(currentTarget);
                createFolderOrRate({
                  body: {
                    folder:
                      templateKey < 4
                        ? {
                            ...updateData[0],
                            title: null,
                            code: null,
                          }
                        : null,
                    insertAfterID: templateKey === currentTarget!.lvl ? currentTarget!.id || null : null,
                    rate:
                      templateKey === 4
                        ? {
                            type: null,
                            title: null as any,
                            insertAfterID: templateKey === currentTarget!.lvl ? currentTarget!.id || null : null,
                            unit: null as any,
                            mim: null,
                            fot: null,
                            mtr: null,
                            description: null,
                            kfoCoefficient: null,
                            kfoGroup: null,
                            kfoUnit: null,
                          }
                        : null,
                  },
                  folderID: templateKey === currentTarget!.lvl ? currentTarget!.parentID! : currentTarget!.id,
                }).then((resp) => submitThenHandler(resp));
              }
            }
          }
        } catch (e) {
          console.log(e);
        }
      })();
    },
    [originData, filteredData, hiddenRowsIds, hiddenRowsUuids],
  );

  /**
   * Функция клика по табу, соответственно записывает в соответствующее локальное состояние
   */
  const handleSelectedTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    setSearchParams(
      (prev) => {
        let newParams = { ...prev };
        const state = prev.get('state');
        const tab = String(newValue);
        if (state) {
          newParams.set('state', state);
        }
        newParams.set('tab', tab);

        return newParams || prev;
      },
      { replace: true },
    );
  };

  /**
   * Функция сворачивания строк в таблице
   * @param {number} id - id строки по которой пришелся клик
   */
  const collapse = (id: number) => {
    setHiddenRowsIds((d) => {
      const copy = [...d];
      const addIndex = copy.findIndex((hrId) => hrId === id);
      if (addIndex >= 0) {
        copy.splice(addIndex, 1);
      } else {
        copy.push(id);
      }
      return copy;
    });
  };

  /**
   * Функция сворачивания строк в таблице
   * @param {number} id - id строки по которой пришелся клик
   */
  const collapseUUid = (id: string) => {
    setHiddenRowsUuids((d) => {
      const copy = [...d];
      const addIndex = copy.findIndex((hrId) => hrId === id);
      if (addIndex >= 0) {
        copy.splice(addIndex, 1);
      } else {
        copy.push(id);
      }
      return copy;
    });
  };

  /**
   * Функция обновления данных в таблице
   * @param {{ data: IEstimateReference | IEstimateReference[] } | { error: FetchBaseQueryError | SerializedError }} resp - данные для обновления
   * @param { string? } name - необязательное поле, колонка на которой был вызван апдейт, нужно для отправки запроса на создание единицы измерения
   * @param {string|number} value - значение для обновления единицы измерения
   * @returns
   */
  const updateThenHandler = (
    resp: { data: IEstimateReference | IEstimateReference[] } | { error: FetchBaseQueryError | SerializedError },
    name?: string,
    value?: string | number,
  ) => {
    console.group('update name');
    console.log(name);
    console.log(value);
    console.log(resp);
    console.groupEnd();
    if ('data' in resp) {
      const result: IEstimateReference[] = [];
      const flatChildren = (data: IEstimateReference) => {
        result.push(data);
        if (data.children?.length) {
          data.children.forEach((child) => flatChildren(child));
        }
      };
      if (Array.isArray(resp.data)) {
        resp.data.forEach(flatChildren);
      } else {
        flatChildren(resp.data);
      }
      updateGrid(result);
      dispatch(updateTable(result));
      if ((name === 'unit' || name === 'kfoUnit') && value !== undefined && value !== null)
        createUnitFn(value as string);
      return true;
    } else {
      const message = (resp as IErrorData<string>)?.error?.data;
      if (message) enqueueSnackbar({ variant: 'error', message: t(`estimates.${message}`), autoHideDuration: 5000 });
      return false;
    }
  };

  /**
   * Функция для редактирования ячеек таблицы
   */
  const valueSetter = useCallback(
    (params: ValueSetterParams<IEstimateReference>) => {
      let flag = false;
      if (!params.data) return false;
      const {
        fot,
        title,
        unit,
        parentID,
        lvl,
        resourceType,
        code,
        qty,
        totalWorkTime,
        description,
        mim,
        pz,
        mtr,
        kfoName,
        kfoUnit,
        kfoCoefficient,
      } = params.data;
      console.log(params);
      let kfoGroup: number | null = null;
      const candidate = groupList.find((_) =>
        params.colDef.field === 'kfoName' ? _.title === params.newValue : _.title === kfoName,
      );
      if (candidate) {
        kfoGroup = candidate.id;
      }
      (async () => {
        try {
          if (params.data.rowType === 'RATE' && params.colDef.field === 'kfoName' && params.newValue.input0) {
            const candidate = groupList.find((_) => _.title === params.newValue.input0);
            if (candidate) {
              kfoGroup = candidate.id;
            } else {
              kfoGroup = await createGroup({ name: params.newValue.input0 }).then((response) => {
                if ('data' in response) {
                  dispatch(addGroup({ title: response.data.name, id: response.data.id! }));
                  return response.data.id!;
                }
                return kfoGroup;
              });
            }
          }
          switch (params.data.rowType) {
            case 'FOLDER': {
              updateFolder({
                folderID: params.data.id,
                body: {
                  lvl: lvl!,
                  code,
                  parentID,
                  title: params.newValue.input0,
                },
              }).then((response) => {
                flag = updateThenHandler(response);
              });
              break;
            }
            case 'RATE': {
              let name = params.colDef.field as keyof IUpdateRate;
              let value =
                typeof params.newValue === 'object' && 'input0' in params.newValue
                  ? params.newValue?.input0
                  : params.newValue;
              if (params.colDef.field === 'kfoName') {
                name = 'kfoGroup';
                value = kfoGroup;
              }
              if (params.colDef.field === 'totalWorkTime') {
                value = value ?? null;
              }
              if (params.colDef.field === 'resourceType') {
                // console.log(params.newValue.input0);
                value = resourceEng[params.newValue.input0];

                name = 'type';
              }
              if (params.colDef.field === 'kfoUnit') {
                value = params.newValue.input0 || null;
              }
              // @ts-ignore
              if (params.colDef.field === 'unit1') {
                name = 'unit';
                value = params.newValue || null;
              }
              if (['mtr', 'fot', 'mim'].includes(params.colDef.field!)) {
                value = params.newValue.input0 || null;
              }
              console.log(name, value);

              const resource = resourceEng[params.newValue.input0 as keyof typeof resourceEng]
              const nameResourceField = resource?getNameField(resource,true):'';
              console.log(nameResourceField);
              const valueResourceField = params.data?.[nameResourceField as keyof typeof params.data];

              const replaceRows = params.colDef.field === 'resourceType'?{fot:null,mim:null,mtr:null,[nameResourceField]:valueResourceField}:{};

              const humanHours = ['WORKERS', 'MACHINE_OPERATORS'];
              const machineHours = ['MiM'];
              const unitVal =
                params.colDef.field === 'resourceType'
                  ? humanHours.includes(value)
                    ? 'чел.час'
                    : machineHours.includes(value)
                    ? 'маш.час'
                    : unit! || null
                  : unit! || null;

              updateRate({
                folderID: params.data.parentID!,
                rateID: params.data.id,
                body: {
                  description,
                  title,
                  fot,
                  mim,
                  pz,
                  mtr,
                  unit: unitVal!,
                  kfoCoefficient,
                  kfoGroup,
                  kfoUnit,
                  type: params.data?.resourceType ?? null,
                  totalWorkTime,
                  [name]: value,
                  ...replaceRows
                },
              }).then((response) => {
                console.log(params.newValue.input0);
                flag = updateThenHandler(response, name, value);
              });
              break;
            }
            case 'RATE_POSITION': {
              let name = params.colDef.field as keyof IUpdateRatePosition;
              let value =
                typeof params.newValue === 'object' && 'input0' in params.newValue
                  ? params.newValue?.input0
                  : params.newValue;
              let data: { [key: string]: any } | undefined;

              if (params.colDef.field === 'resourceType') {
                const humanHours = ['WORKERS', 'MACHINE_OPERATORS'];
                const machineHours = ['MiM'];
                const unitVal =
                  params.colDef.field === 'resourceType'
                    ? humanHours.includes(resourceEng[params.newValue.input0])
                      ? 'чел.час'
                      : machineHours.includes(resourceEng[params.newValue.input0])
                      ? 'маш.час'
                      : unit!
                    : unit!;
                data = { type: resourceEng[params.newValue.input0], unit: unitVal };
              }
              if (params.colDef.field === 'totalWorkTime') {
                name = 'workTime';
              }
              if (params.colDef.field === 'title') {
                value = value || null;
              }
              // @ts-ignore
              if (name === 'unit1') {
                data = {
                  unit: params.newValue,
                };
              }
              // @ts-ignore
              if (name === 'unit2') {
                const val = Number(params.newValue?.toString()?.replace(/\s/g, '')?.replace(/,/g, '.')) || null;
                data = {
                  qty: !val ? null : val > 999999999.999999 || val < -999999999.999999 ? qty : val,
                };
              }
              let rate:IEstimateReference|undefined
              if (!params.data.folderID && !params.data.parentID) {
                rate = originData?.find((_) => _.id === parentID && _.rowType === 'RATE');
              }
              const resource = resourceEng[params.newValue as keyof typeof resourceEng]
              const nameResourceField = getNameField(resource,true);
              const valueResourceField = params.data?.mtr || params.data?.mim || params.data?.fot;

              const replaceRows = params.colDef.field === 'resourceType'?{fot:null,mim:null,mtr:null,[nameResourceField]:valueResourceField}:{};

              updateRatePosition({
                folderID: params.data?.folderID||rate!.parentID!,
                rateID: params.data.parentID!,
                positionID: params.data.id,
                body: {
                  //@ts-ignore
                  unit: unit!,
                  title,
                  mtr,
                  mim,
                  pz: pz!,
                  //@ts-ignore
                  qty: qty!,
                  kfoCoefficient,
                  kfoUnit,
                  kfoGroup,
                  fot,
                  type: resourceType,
                  workTime: totalWorkTime,
                  ...(data ? data : { [name]: value }),
                  ...replaceRows
                },
              }).then((response) => {
                flag = updateThenHandler(response, name, params.newValue?.input0 || params.newValue);
              });

              break;
            }
          }
        } catch (e) {
          console.log(e);
        }
      })();

      // await updateEstimate({})
      return flag;
    },
    [originData, unitList, createUnitFn, groupList],
  );

  /**
   * Колонки таблицы вместе с функцией для обновления таблицы
   */
  const columnsDef = useTableData(valueSetter);

  /**
   * Функция которая будет отрабатывать после успешной отправки данных на сервер при создании
   */
  const submitThenHandler = useCallback(
    (
      resp: { data: any } | { error: FetchBaseQueryError | SerializedError },
      unit?: string,
      keyForm?: string,
      isPosition: boolean = false,
    ) => {
      if ('data' in resp) {
        if (!isPosition) {
          methods.reset(resetGenForm);
          dispatch(changeCreated(false));
        } else {
          methodsPositions.setValue(`${keyForm}` as never, positionPlaceholder as never);
        }
        unit && createUnitFn(unit);
        dispatch(removeParentLevel());
        const result: IEstimateReference[] = [];
        const flatChildren = (data: IEstimateReference) => {
          result.push(data);
          if (data.children?.length) {
            data.children.forEach((child) => flatChildren(child));
          }
        };
        dispatch(changeCreated(false));
        refetch().then((response) => {
          flatChildren(resp.data as IEstimateReference);
          let index: number | undefined;
          Ref.current?.api?.applyTransaction({ update: result.flat() });
          if (Array.isArray(resp.data)) {
            index = response.data?.findIndex((_) => _.id === resp.data[0]?.id) + resp.data[0]?.children?.length;
            const targetParent = originData?.find((_) => _.id === resp.data[0]?.id);
            if (targetParent && targetParent.uuid === resp.data[0]?.uuid) {
              // console.log(targetParent, resp.data);
              const createdObject = diffArray<IEstimateReference>(resp.data.slice(1), targetParent!.children) as
                | IEstimateReference
                | undefined;
              if (createdObject) {
                index = response.data?.findIndex((item) => item.uuid === createdObject.uuid);
                // console.log(response.data?.[index!], index);
              }
            }
          } else {
            // index = response.data?.findIndex((_) => _.id === ) + resp.data?.children?.length;
            const targetParent = originData?.find((_) => _.id === resp.data?.id);
            if (targetParent && targetParent.uuid === resp.data?.uuid) {
              // console.log(targetParent, resp.data);
              const createdObject = diffArray<IEstimateReference>(resp.data!.children, targetParent!.children) as
                | IEstimateReference
                | undefined;
              if (createdObject) {
                index = response.data?.findIndex((item) => item.uuid === createdObject.uuid);
                // console.log(response.data?.[index!], index);
              }
            }

            // console.log(resp.data);
          }
          if (index! >= 0) {
            setTimeout(() => {
              Ref.current?.api.ensureIndexVisible(index!, 'middle');
              const target = response.data![index!];
              console.log(target);
              if (target && hiddenRowsUuids.includes(target.parentUUID!)) {
                dispatch(changeUUID(target.uuid!));
                target.parentUUID && collapseUUid(target.parentUUID);
                setTimeout(() => dispatch(changeUUID(null)), 3000);
              }
            }, 200);
          }
          Ref.current?.api?.refreshCells({ force: true, suppressFlash: true });
        });
      } else {
        const message = (resp as IErrorData<string>)?.error?.data;
        if (message) enqueueSnackbar({ variant: 'error', message: t(`estimates.${message}`), autoHideDuration: 5000 });
      }
    },
    [methods, methodsPositions, createUnitFn, refetch, Ref.current, originData, hiddenRowsUuids],
  );

  /**
   * Функция создания расценки, корневой папки (папки первого уровня) и просто уровней с расценкой вложенных в первый уровень
   * в зависимости от условий
   */
  const submitHandler = useCallback(
    async (v: any) => {
      const requiredNames = [];

      let flag = true;
      if (templateLvl === 1 || templateLvl === null) {
        requiredNames.push('shifr');
      }

      let data: { [key: string]: boolean } = {};
      const popupState: any[] = [];
      // console.log(requiredNames);
      for (const vKey in v) {
        if (requiredNames.includes(vKey)) {
          const trimValue = v[vKey]?.trim();
          data[vKey] = !trimValue;
          if (!trimValue) {
            if (vKey in errorsState) {
              popupState.push(<span>{errorsState[vKey as keyof typeof errorsState]}</span>);
            }
            flag = false;
          }
        }
      }
      setErrorsForm((prevState) => {
        let rate = { ...prevState.rate, ...data };
        return {
          ...prevState,
          rate,
        };
      });
      setTimeout(() => {
        setErrorsForm(initialErrorForm);
      }, 5000);
      if (popupState.length) {
        enqueueSnackbar({
          autoHideDuration: 5000,
          variant: 'error',
          message: (
            <Box display={'flex'} flexDirection={'column'} gap={'5px'}>
              {popupState}
            </Box>
          ),
        });
      }
      if (!flag) {
        return;
      }

      const index = groupList.find((_) => _.title === v.kfoName);

      try {
        let kfoGroup: number | null = index?.id || null;
        if (v.kfoName?.trim() && kfoGroup === null) {
          kfoGroup = await createGroup({ name: v.kfoName!.trim() }).then((response) => {
            if ('data' in response) {
              dispatch(addGroup({ title: response.data.name, id: response.data.id! }));
              return response.data.id!;
            }
            return null;
          });
        }
        const kfoCoefficient = v.kfoCoefficient ? Number(v.kfoCoefficient.replace(/\s/g, '').replace(/,/g, '.')) : null;
        if (templateLvl && templateLvl > 3) {
          await createEstimate({
            body: {
              type: v.type ? resourceEng[v.type] : null,
              title: v.estimate,
              insertAfterID: neighbor,
              unit: v.unit?.trim() || null,
              description: v.description?.trim(),
              mim: v.mim || null,
              fot: v.fot || null,
              mtr: v.mtr || null,
              kfoCoefficient,
              kfoGroup,
              kfoUnit: v.kfoUnit?.trim() || null,
            },
            folderID: parentLevel!,
          }).then((response) => submitThenHandler(response, v.unit?.trim() || null));
        } else {
          if (parentLevel === null) {
            console.log('create root');
            await createFolder({
              folder: {
                lvl: templateLvl ?? 1,
                title: v[`level${templateLvl ?? 1}`].trim(),
                code: v.shifr.trim() ?? null,
                parentID: null,
              },
              insertAfterID: neighbor || null,
            }).then((response: any) => submitThenHandler(response, v.unit?.trim() || null));
          } else {
            console.log('create other template');
            const rootFolder = {
              lvl: 1,
              title: v[`level${templateLvl ?? 1}`].trim(),
              code: v.shifr.trim() ?? null,
              parentID: null,
            };
            if (templateLvl === 1) {
              await createFolder({
                folder: { ...rootFolder },
                insertAfterID: neighbor || null,
              }).then((response: any) => submitThenHandler(response, v.unit?.trim() || null));
            } else {
              await createFolderOrRate({
                body: {
                  folder:
                    templateLvl! <= 3
                      ? {
                          lvl: templateLvl ?? 1,
                          title: v[`level${templateLvl ?? 1}`].trim(),
                          code: null,
                          parentID: parentLevel,
                        }
                      : null,
                  insertAfterID: neighbor,
                  rate:
                    templateLvl! <= 3
                      ? null
                      : {
                          type: v.type ? resourceEng[v.type] : null,
                          title: v.estimate?.trim(),
                          unit: v.unit?.trim() || null,
                          mim: v.mim || null,
                          fot: v.fot || null,
                          mtr: v.mtr || null,
                          description: v.description?.trim(),
                          kfoCoefficient,
                          kfoGroup,
                          kfoUnit: v.kfoUnit?.trim() || null,
                        },
                },
                folderID: parentLevel,
              }).then((response: any) => submitThenHandler(response, v.unit?.trim() || null));
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    [parentLevel, templateLvl, createUnitFn, groupList, neighbor],
  );

  /**
   * Функция создания позиции расценки
   */
  const submitHandlerPosition = useCallback(
    (v: any, id: number, parent: number, folder: number) => {
      // console.log(folder, parent, id);
      const keyForm = Math.abs(id);
      let findedFolder = Od.find((_) => _.id === parent);
      while (findedFolder && findedFolder.rowType !== 'FOLDER') {
        findedFolder = Od.find((_) => _.id === findedFolder!.parentID);
        console.log('iteration');
      }
      // console.log(v);
      // console.log(findedFolder);
      const current = v[keyForm];
      // console.log(current);
      let errorData: { [name: keyof typeof current]: boolean } = {};

      const numberField = ['pz', 'qty', 'workTime'];
      const popupState: any[] = [];

      const actualNames = ['pz', 'qty', 'title', 'type', 'unit'];
      if (current) {
        let flag = true;
        for (const currentKey in current) {
          if (numberField.includes(currentKey) && current[currentKey]) {
            current[currentKey] = Number(current[currentKey].replace(/\s/g, '').replace(/,/g, '.'));
          }
          if (actualNames.includes(currentKey) && !current[currentKey]) {
            flag = false;
            break;
          }
        }
        for (const currentKey in current) {
          errorData[currentKey] = actualNames.includes(currentKey) && !current[currentKey];
          if (currentKey in errorsState && !current[currentKey]) {
            popupState.push(<span>{errorsState[currentKey as keyof typeof errorsState]}</span>);
          }
        }
        if (popupState.length) {
          enqueueSnackbar({
            autoHideDuration: 5000,
            variant: 'error',
            message: (
              <Box display={'flex'} flexDirection={'column'} gap={'5px'}>
                {popupState}
              </Box>
            ),
          });
        }
        setErrorsForm((prevState) => {
          let copy = { ...prevState.position };
          const target = copy[keyForm as any];
          if (target) {
            copy[keyForm as any] = { ...copy[keyForm as any], ...errorData };
          } else {
            copy[keyForm as any] = errorData;
          }
          return { position: copy, rate: prevState.rate };
        });
        setTimeout(() => {
          setErrorsForm(initialErrorForm);
        }, 3000);
        if (flag) {
          createPosition({
            body: { ...current, type: resourceEng[current.type] },
            folderID: folder || findedFolder!.id,
            rateID: parent,
          }).then((response) => submitThenHandler(response, current.unit, keyForm.toString(), true));
        }
      }
    },
    [createUnitFn, Ref.current, Od],
  );

  const getRowClass = useCallback(
    (params: RowClassParams<IEstimateReference, any>) => {
      return params.data?.rowType === 'FOLDER' ? `level-${params.data?.lvl}` : 'level-12';
    },
    [uuid, Ref.current],
  );

  usePriceReferencePageEffect({
    hiddenRowsUUids: hiddenRowsUuids,
    originData,
    isFetching,
    data,
    clickRefetch,
    applyFilters,
    setClickRefetch,
    isFetchingUpdate,
    unitWeightData,
    setFilteredData,
    Ref,
    groupData,
  });
  const handleRefresh = () => {
    dispatch(api.util.invalidateTags(['PriceReference']));
    cWS.setImportHandbookUploaded('start');
  };
  // useEffect(() => {
  //   console.log('refresh');
  //   Ref.current?.api?.refreshCells({ force: true });
  // }, [Od, Ref.current]);

  useEffect(() => {
    Ref.current?.api?.refreshCells({force:true})
  }, [filteredData]);

  console.log(filteredData)

  return (
    <>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Tabs value={selectedTab} onChange={handleSelectedTab}>
          <Tab label="Расценки" />
          {/*<Tab label="Операции" />*/}
          {/*<Tab label="Ед. изм." />*/}
          {/*<Tab label="еще что-то" />*/}
        </Tabs>
        <Box display={'flex'} width={'456px'} gap={'12px'} alignItems={'center'}>
          <Button
            onClick={() => openDeleteRateConfirm(deleteRatesWithPositions)}
            disabled={!removedIds.length}
            fullWidth
            variant={'text'}>
            <Box display={'flex'} gap={'8px'} alignItems={'center'}>
              <DeleteOutlineIcon fontSize={'small'} />
              Удалить
            </Box>
          </Button>
          <ImportButton disabled={!data?.length} fullWidth variant={'text'} onClick={exportHandler}>
            <div>
              <ExportIcon />
              Экспорт .XLS
            </div>
          </ImportButton>
          <ImportButton fullWidth variant={'text'} onClick={() => toggleImportDrawer()}>
            <div>
              <ImportIcon />
              Загрузить .XLS
            </div>
          </ImportButton>
        </Box>
      </Box>
      {/* блок для chips */}
      <ChipWrapper>
        {filterChips.map((_) => {
          const currentFilters = activeFilters[_.code as keyof typeof activeFilters];

          return currentFilters?.length ? (
            <Chip onClick={() => setActive(_.code as any)}>
              {_.name}
              <Devide />
              <Tooltip title={currentFilters.map((_) => _.name).join(', ')}>
              <span>{currentFilters.map((_) => _.name).join(', ')}</span>
              </Tooltip>
              <CloseIcon
                fontSize={'small'}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  dispatch(clearFilter(_.code as any));
                  setTimeout(() => setClickRefetch(true));
                }}
              />
            </Chip>
          ) : (
            <></>
          );
        })}
      </ChipWrapper>

      <FormProvider {...methods}>
        <ProviderPositionForm {...methodsPositions}>
          <PricesReferenceContext.Provider
            value={{
              collapse,
              toggleDrawer,
              hiddenRows: hiddenRowsIds,
              removedIds,
              clearRemovedIds: () => setRemovedIds([]),
              checkboxClick: clickCheckbox,
              add: addRow,
              submit: submitHandler,
              submitPosition: submitHandlerPosition,
              deleteUnit: deleteUnitWeight,
              deleteGroup: deleteGroupKFO,
              deleteFolderConfirm: getConfirmDialog,
              deleteRate: deleteRatesWithPositions,
              errors: errorsForm,
              getFormState,
              denyCreated: ()=>{},
              changeDialog,
              methodsRate: methods,
              hiddenRowsUUid: hiddenRowsUuids,
              collapseUUid: collapseUUid,
              methodsPosition: methodsPositions,
            }}>
            <PageStyled>
              <div />
              <WrapperAgGrid className="ag-theme-material reference-prices">
                <AgGridReact
                  ref={Ref}
                  enableCellTextSelection={true}
                  ensureDomOrder={true}
                  maintainColumnOrder={true}
                  columnDefs={columnsDef}
                  groupHeaderHeight={25}
                  singleClickEdit
                  gridOptions={{
                    navigateToNextHeader: () => null,
                    tabToNextHeader: () => null,
                  }}
                  rowData={filteredData}
                  suppressCellFocus={true}
                  onFirstDataRendered={(event) => {
                    event.api.sizeColumnsToFit();
                  }}
                  onGridSizeChanged={(event: GridSizeChangedEvent) => {
                    event.api.sizeColumnsToFit();
                  }}
                  onViewportChanged={(event) => {
                    event.api.sizeColumnsToFit();
                  }}
                  getRowId={(params) => {
                    return params.data?.uuid + params.data?.id;
                  }}
                  getRowClass={getRowClass}
                  rowStyle={{
                    padding: '0 !important',
                  }}
                  rowHeight={55}
                  headerHeight={36}
                  noRowsOverlayComponent={NoRows}
                  loadingOverlayComponent={Progress}></AgGridReact>
              </WrapperAgGrid>
            </PageStyled>
          </PricesReferenceContext.Provider>
        </ProviderPositionForm>
        <DeleteFolderFirstLevelConfirmDialog />
        <DeleteFolderSecondLevelConfirmDialog />
        <DeleteFolderThirdLevelConfirmDialog />
        <ConfirmDeleteGroupOrUnit/>
        {/*<DenyCreatedConfirm />*/}
        <DeleteRateConfirmDialog />
        <CreateRatePositionConfirm />
        <ImportDrawer open={importDrawer} toggleDrawer={toggleImportDrawer} />
        <DialogFilters variant={active} apply={applyFilters} close={() => changeDialog()} />
        {cWS.socketImportHandbook.isUploaded !== 'start' &&
          cWS.socketImportHandbook.isUploaded !== 'success' &&
          !importDrawer && (
            <WSCustomSnackbar
              isDrawerOpen={cWS.socketImportHandbook.isDrawerOpen ?? false}
              isUploaded={cWS.socketImportHandbook.isUploaded}
              setUploaded={cWS.setImportHandbookUploaded}
              projectIDEquals={true}
              handleRefresh={handleRefresh}
              socket={socketPriceReference}
            />
          )}
      </FormProvider>
    </>
  );
};

export default PricesReference;
