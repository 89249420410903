/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Checkbox, Dialog } from "@mui/material";
import { TResourceType, useLazyGetFilterDataQuery } from "api/calculationDictionary";
import { OrNull, resourceEng } from "api/references/estimates/estimates.types";
import { useCalcId } from "hooks/useCalcId";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  clearFilter as clearFilterCalc,
  getCalculationDictionaryState,
  setFilter as setFilterCalc,
  setGroupFilter as setGroupFilterCalc
} from "store/slices/calculationDictionary/calculationDictionary";
import { useGetFiltersRateQuery } from "../../../../../../api/references/estimates";
import { BaseTextField } from "../../../../../../components/BaseTextField";
import Button from "../../../../../../components/Button";
import {
  clearFilter,
  getPricesState,
  setFilter,
  setGroupFilter
} from "../../../../../../store/slices/references/prices/prices";
import { useAppDispatch } from "../../../../../../store/store";
import { EmptyText, ListItem, ListWrapper, SmallText, Text, Title, WrapperDialog } from "./DialogFilters.style";
import { StateFiltersSlice } from "../../../../../../store/slices/references/prices/types";

interface IDialogFiltersProps {
  variant: 'codes' | 'groups' | 'resourceTypes' | 'titles' | null;
  apply: () => void;
  close: () => void;
}

const resourceTypesList = [
  { id: 1, title: 'Рабочие' },
  { id: 2, title: 'Механизаторы' },
  { id: 3, title: 'МиМ' },
  { id: 6, title: 'Материалы' },
  { id: 5, title: 'Оборудование' },
  { id: 4, title: 'Услуги' },
];

const DialogFilters: FC<IDialogFiltersProps> = ({ variant, apply, close }) => {
  const dispatch = useAppDispatch();

  const startFilters = useRef<StateFiltersSlice>({
    codes:[],
    groups:[],
    resourceTypes:[],
    titles:[]
  })

  const [dirty,setDirty] = useState(false)

  const calcID = useCalcId();

  // const setFilter = useCallback((params:any)=>{

  // },[calcID])

  const storeFilter = useRef<{ name: string; id: string | number }[]>([]);

  const { groupList, activeFilters: activeFiltersPrice } = useSelector(getPricesState);
  const { activeFilters: activeFiltersCalc } = useSelector(getCalculationDictionaryState);

  const activeFilters = useMemo(() => {
    return calcID ? activeFiltersCalc : activeFiltersPrice;
  }, [calcID, activeFiltersPrice, activeFiltersCalc]);

  const [search, setSearch] = useState('');
  const { data: filtersData } = useGetFiltersRateQuery({});
  const [getFilterCalc, { data: filtersDataCalc }] = useLazyGetFilterDataQuery();

  const filter = (data: { id: number; title?: OrNull<string>; code?: OrNull<string> }, s: string) => {
    const lowerSearch = s?.toLowerCase();
    // if (typeof data === 'string') {
    // return data?.toLowerCase().includes(lowerSearch);
    // }
    if (data) {
      if ('code' in data) {
        return data?.title?.toLowerCase()?.includes(lowerSearch) || data?.code?.toLowerCase()?.includes(lowerSearch);
      } else {
        return data?.title?.toLowerCase().includes(lowerSearch);
      }
    }
    return false
  };

  const currentState = useMemo(() => {
    if (!calcID) {
      switch (variant) {
        case 'codes':
          return (filtersData || [])
            .map((_) => ({ id: _.id, title: _.code }))
            .filter((item) => filter(item, search))
            .sort((a, b) => (a?.title || '').localeCompare(b?.title || ''));
        case 'titles':
          return (filtersData || [])
            .filter((item) => filter(item, search))
            .sort((a, b) => (a?.title || '').localeCompare(b?.title || ''));
        case 'resourceTypes':
          return resourceTypesList.filter((item) => filter(item, search));
        case 'groups':
          return groupList
            .filter((item) => filter(item, search))
            .sort((a, b) => (a?.title || '').localeCompare(b?.title || ''));
      }
    }
    // console.log(filtersDataCalc);
    switch (variant) {
      case 'codes':
        return (filtersDataCalc?.codes || [])
          .map((_:any) => ({ id: _?.rowID, title: _?.code }))
          .filter((item) => filter(item, search))
          .sort((a, b) => (a?.title || '').localeCompare(b?.title || ''));
      case 'titles':
        return (filtersDataCalc?.titles || [])
          .map((_:any) => ({..._, id: _?.rowID, }))
          .filter((item:any) => filter(item, search))
          .sort((a, b) => (a?.title || '').localeCompare(b?.title || ''));
      case 'resourceTypes':
        return resourceTypesList.filter((item) => filter(item, search));
      case 'groups':
        return (filtersDataCalc?.groups || []).map(item=>({ ...item,title:item.name }))
          .map((_:any) => ({ ..._,id: _?.groupID, }))
          .filter((item) => filter(item, search))
          .sort((a, b) => (a?.name || '').localeCompare(b?.name || ''));
    }
  }, [groupList, filtersData, filtersDataCalc, variant, search, calcID]);

  const key = useMemo(() => {
    switch (variant) {
      case 'codes':
      case 'titles':
        return 'id';
      default:
        return 'title';
    }
  }, [variant]);

  const title = useMemo(() => {
    switch (variant) {
      case 'codes':
        return 'Шифр';
      case 'titles':
        return 'Наименование';
      case 'resourceTypes':
        return 'Тип ресурса';
      case 'groups':
        return 'Группа КФО';
    }
  }, [variant]);

  const selectAll = useCallback(() => {
    console.log(currentState)
    console.log(filtersDataCalc);
    const data = (currentState || []).map(
      (_: any, index) => {
        let returnData = {
          id: _?.id??_?.groupID??_?.rowID??index,
          name: '',
        };
        if (typeof _ === 'string') {
          returnData.name = _;
        } else if ('title' in _) {
          returnData = { ...returnData, name: _.title || '' };
        } else {
          returnData = { ...returnData, name: _.name || '' };
        }
        return returnData;
      },
    );
    console.log(data)
    if (variant) {
      !calcID ? dispatch(setGroupFilter({ key: variant, data,replace:true })) : dispatch(setGroupFilterCalc({ key: variant, data,replace:true }));
    }
  }, [currentState, variant, key, calcID]);

  const denyAll = useCallback(() => {
    if (variant) {
      !calcID ? dispatch(clearFilter(variant)) : dispatch(clearFilterCalc(variant));
    }
  }, [currentState, variant, calcID]);

  useEffect(() => {
    if (calcID && variant !== null) {
      console.log(activeFilters.groups);
      getFilterCalc({calcID,body:{
        codes: activeFilters.codes.map(_=>_.id) as number[],
        titles: activeFilters.codes.map((_:any)=>_.rowID),
        types: activeFilters.resourceTypes.map(_=>resourceEng[_.name]) as TResourceType[],
        groups: activeFilters.groups.map((_:any)=>_.groupID??_.id) as number[],
        }});
    }
  }, [calcID, variant,activeFilters]);

  useEffect(() => {
    setSearch('');
    storeFilter.current = activeFilters[variant as keyof typeof activeFilters];
  }, [variant]);

  useEffect(() => {
    if(variant){
      startFilters.current = activeFilters
    }else{
      startFilters.current = {
        codes:[],
        groups:[],
        resourceTypes:[],
        titles:[]
      }
      setDirty(false)
    }
  }, [variant]);

  const checkPreviousState = useMemo(()=>{
    const idsChecks = activeFilters[variant as keyof typeof activeFilters]?.map((_:any) => _.id) || [];
    if (startFilters.current?.[variant as keyof typeof startFilters.current]?.length === idsChecks.length) {
      let flag = true
      for (let i = 0; i < startFilters.current![variant as keyof typeof startFilters.current]!.length; i++) {
          const elID = startFilters.current![variant as keyof typeof startFilters.current][i].id
        if (elID !== idsChecks[i]) {
          flag = false;
          break;
        }
      }
      return flag
    }else{
      return false
    }

    if (!startFilters.current?.[variant as keyof typeof startFilters.current]?.length) {
      console.log(1);
      return !activeFilters?.[variant as keyof typeof startFilters.current]?.length;
    }
    if (activeFilters[variant as keyof typeof activeFilters]?.length === startFilters.current[variant as keyof typeof activeFilters]?.length) {
      return true
    }
    const some = startFilters.current[variant as keyof typeof startFilters.current];
    let flag = false
    for (const itemFilter of activeFilters[variant as keyof typeof activeFilters]) {

    }
    console.log(2)
    return activeFilters[variant as keyof typeof activeFilters]?.some(_=>!idsChecks.includes(_.id))
  },[startFilters.current,variant,activeFilters])
  return (
    <Dialog
      open={!!variant}
      maxWidth={false}
      onClose={async () => {
        if (variant) {
          !calcID
            ? await dispatch(setGroupFilter({ key: variant, data: storeFilter.current, replace: true }))
            : await dispatch(setGroupFilterCalc({ key: variant, data: storeFilter.current, replace: true }));
        }
        close();
      }}>
      <WrapperDialog hideField={variant === 'resourceTypes'}>
        <Title>
          <FilterAltIcon fontSize={'small'} />
          {title}
        </Title>
        {variant !== 'resourceTypes' && (
          <BaseTextField
            placeholder={'Поиск'}
            InputProps={{
              startAdornment: <SearchIcon style={{ marginRight: '10px' }} />,
            }}
            sx={{
              '.MuiInputBase-root': {
                background: 'none !important',
              },
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        )}
        <Box display={'flex'} alignItems={'stretch'} justifyContent={'flex-start'} gap={'10px'}>
          <Button disabled={!currentState?.length} variant={'text'} onClick={selectAll}>
            Выбрать все
          </Button>
          <Button
            disabled={!activeFilters[variant as keyof typeof activeFilters]?.length}
            variant={'text'}
            onClick={denyAll}>
            Сбросить
            {activeFilters?.[variant as keyof typeof activeFilters]?.length?` (${activeFilters?.[variant as keyof typeof activeFilters].length})`:''}
          </Button>
        </Box>
        {currentState?.length ? (
          <ListWrapper>
            {currentState.map((item:any, idx) => {
              return (
                <ListItem
                  key={typeof item !== 'string' && 'id' in item ? item.id : idx}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    variant &&
                      (!calcID
                        ? dispatch(
                            setFilter({
                              type: variant,
                              id: item.id ?? item.rowID ?? item.groupID,
                              name: 'name' in item ? item?.name || '' : item?.title || '',
                            }),
                          )
                        : dispatch(
                            setFilterCalc({
                              type: variant,
                              id: item.id ?? item.rowID ?? item.groupID,
                              name: 'name' in item ? item?.name || '' : item?.title || '',
                            }),
                          ));
                  }}>
                  <Checkbox
                    checked={
                      activeFilters?.[variant as keyof typeof activeFilters]?.findIndex((_) => _.id === item?.id||_.id === item?.groupID) >= 0
                    }
                  />
                  <Text>
                    <span>{typeof item === 'string' ? item : 'title' in item && item?.title}</span>
                    {typeof item !== 'string' && 'code' in item && <SmallText>{item.code as string}</SmallText>}
                  </Text>
                </ListItem>
              );
            })}
          </ListWrapper>
        ) : (
          <EmptyText>Нет результатов</EmptyText>
        )}
        <Box display={'flex'} gap={'10px'}>
          <Button
            disabled={checkPreviousState}
            onClick={apply}>
            применить
          </Button>
          <Button
            color={'error'}
            sx={{ color: 'white' }}
            // disabled={!activeFilters[variant as keyof typeof activeFilters]?.length || !currentState?.length}
            onClick={close}>
            Закрыть
          </Button>
        </Box>
      </WrapperDialog>
    </Dialog>
  );
};

export default DialogFilters;
