import styled from 'styled-components';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { TABLE_CELL_HEIGHT } from './PositionsTable.types';

interface StyledTableRowProps {
  color?: 'info' | 'warning';
}

export const StyledTableCell = styled(TableCell)`
  background-color: transparent;
  padding: inherit;
  background-color: ${(props) => {
    return props?.color === 'info' && 'rgba(2, 136, 209, 0.05) !important';
  }};
  background-color: ${(props) => props?.color === 'warning' && 'rgba(254, 155, 63, 0.05) !important'};
`;

export const StyledHeadTitle = styled(Typography)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #00358c;
  line-height: 13px;
  letter-spacing: 0.1px;
  text-align: center;
`;

export const StyledTableRow = styled(TableRow)<StyledTableRowProps>`
  min-height: 44px;
  height: ${TABLE_CELL_HEIGHT}px;
  & ${StyledTableCell} {
    background-color: ${(props) => {
      return props?.color === 'info' && 'rgba(2, 136, 209, 0.05) !important';
    }};
    background-color: ${(props) => props?.color === 'warning' && 'rgba(254, 155, 63, 0.05) !important'};

    box-shadow: ${(props) => `inset 0 -1px 0px ${props.theme.palette.legends.gray}`};
  }
`;

export const StyledTableBody = styled(TableBody)<StyledTableRowProps>`
  & ${StyledTableCell} {
    padding: 6px 6px 7px;
    background-color: ${(props) => props.theme.palette.bg.white};
    border-bottom: none;
    // border-bottom: 1px solid rgba(224, 224, 224, 1);

    & .MuiTypography-root {
      color: ${(props) => props.theme.palette.text.dark};
      overflow: hidden;
      line-height: 110%; // 15px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-wrap: break-word;
      hyphens: auto;
    }
  }
`;
// padding: 20px 8px 10px;
export const StyledTableHead = styled(TableHead)`
  position: sticky;
  top: 0;
  filter: drop-shadow(0px 1px 4px rgba(109, 154, 220, 0.5));
  & ${StyledTableRow} {
    height: ${TABLE_CELL_HEIGHT}px;
    background-color: ${(props) => props.theme.palette.bg.white};
    & ${StyledTableCell} {
      padding: 20px 8px 10px;
      box-shadow: ${(props) => `inset 1px -1px 0px ${props.theme.palette.legends.gray}`};
      &:first-child {
        box-shadow: ${(props) => `inset 0 -1px 0px ${props.theme.palette.legends.gray}`};
      }
      &:last-child {
        box-shadow: ${(props) => `
          inset 1px -1px 0px ${props.theme.palette.legends.gray},
          inset -1px 0 0px ${props.theme.palette.legends.gray}
        `};
      }
    }
  }
  & ${StyledTableCell} {
    background-color: ${(props) => props.theme.palette.bg.white};
    & .MuiTypography-root {
      font-weight: 400;
      font-size: 12px;
      color: ${(props) => props.theme.palette.primary.dark};
    }
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  max-height: none;
  height: 100%;
  position: absolute;
  top: 0;
`;

export const StyledTable = styled(Table)`
  & * {
    border: 0;
  }
`;

export const StyledTablePaper = styled(Paper)`
  width: 100%;
  flex-grow: 1;
  border-radius: 8px;
  box-shadow: none;
  overflow-x: auto;
  height: 100%;
  position: relative;
`;
