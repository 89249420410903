import { Box, Stack } from '@mui/material';
import styled from 'styled-components';
import { TitleSizes } from './Dashboard.types';
import { Link } from 'react-router-dom';

interface StyledTitleProps {
  size?: TitleSizes;
}

export const StyledWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  padding: 20px 0;
  flex-wrap: wrap;
  flex: 1 1 auto;
`;

export const StyledTitle = styled.div<StyledTitleProps>`
  font-size: ${({ size = 'normal' }) => (size === 'normal' ? '22px' : '16px')};
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const StyledBoxWrapper = styled(Box)`
  word-break: break-all;
  text-align: left;
  padding: ${({ theme }) => theme.spacing(2.5)};
  background-color: ${({ theme }) => theme.palette.bg.white};
  border-radius: 20px;
  min-width: 300px;
  border: 1px solid transparent;
`;

export const StyledBoxHeader = styled(Stack)<StyledTitleProps>`
  margin-bottom: ${({ size = 'normal' }) => (size === 'normal' ? '30px' : '20px')};
`;

export const StyledLink = styled(Link)`
  display: inherit;
`;
