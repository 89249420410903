import * as yup from 'yup'


export const validationCalculation = yup.object({
  customer: yup.string().trim().required('common:errors.required'),
  description: yup.string().trim().required('common:errors.required'),
  title: yup.string().trim().required('common:errors.required'),

})
export const validationTitleEdit = yup.object({
  title: yup.string().trim().required(`common:${''}${''}`),
})
