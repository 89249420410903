import { formatMaskPhone } from '../PhoneFieldForm/utils';
import i18n from 'i18next';
import { CreateUserResponse, UserBindCandidate } from '../../api/users/users.types';

export const getLabelBindField = (candidates: CreateUserResponse['candidates']) => {
  return candidates?.[0]?.bindFields
    ?.map((field: 'email' | 'login' | 'phone') =>
      field === 'phone' ? formatMaskPhone(candidates[0]?.profile[field]) : candidates[0]?.profile[field],
    )
    .join(', ');
};

export const getLabelBindFields = (bindFields: UserBindCandidate['bindFields']) => {
  return bindFields
    ?.map((field) => i18n.t(`user:status.${field}`))
    .join(' И ')
    .toUpperCase();
};
