import { FormControlLabel, FormLabel, Tab } from "@mui/material";
import styled from "styled-components";
import { FieldForm } from "../../../../components/FieldForm";

export const StyledFieldFormTitle = styled(FieldForm)`
  & .MuiFilledInput-input {
    font-weight: 400;
    font-size: 14px;
  }
  & .MuiFormHelperText-root.Mui-error {
    position: absolute;
      top: 41px;
    color: #f46b6b;
  }
`;
export const StyledFieldFormDesc = styled(FieldForm)`
  & .MuiFilledInput-input {
    font-weight: 400;
    font-size: 14px;
  }
  & .MuiFormHelperText-root.Mui-error {
    position: absolute;
    top: 62px;
    color: #f46b6b;
  }
`;
export const StyledFieldFormClient = styled(FieldForm)`
  & .MuiFilledInput-input {
    font-weight: 400;
    font-size: 14px;
  }
  & .MuiFormHelperText-root.Mui-error {
    position: absolute;
    top: 35px;
    color: #f46b6b;
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  border: 1px solid rgba(25, 118, 210, 0.04);
  border-radius: 8px;
  margin: 0;
  padding: 10px 8px 10px 13px;

  border: 1px solid ${(props) => (props.checked === true ? props.theme.palette.primary.light : '#EDEFF3')};
  background: ${(props) => (props.checked === true ? 'rgba(25, 118, 210, 0.04)' : '#FFFFFF')};

  & .MuiTypography-root {
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.17px;
    color: #2b3648;
  }

  & .MuiCheckbox-root,
  .MuiRadio-root {
    padding: 0;
    margin-right: 13px;
  }
`;

export const StyledFormLabel = styled(FormLabel)`
  margin-bottom: 12px;
  color: #0044b4;
  font-weight: 500;
  font-size: 14px;
`;
export const StyledTab = styled(Tab)`
  padding: 16px;
  padding-left: 22px;
  flex-direction: row;
  align-content: flex-start;
  background: #ffffff;
  border: 1px solid #6d9adc;
  border-radius: 8px;
  color: #5c6e8c;
  font-weight: 400;
  font-size: 14px;
  text-transform: unset;
  text-align: left;

  &.Mui-selected {
    background: rgba(25, 118, 210, 0.08);
    svg > * {
      fill: #0044b4;
    }
  }
  .MuiTab-iconWrapper {
    margin: 0;
    margin-right: 19px;
    width: 38px;
  }
`;
