import React from 'react'
import { Stack, Typography } from '@mui/material'
import { theme } from '../../styles/theme'
import { InfoRowProps } from './InfoRow.types'

const InfoRow: React.FC<InfoRowProps> = ({ rowData: row }) => {
  const defaultColor: string = theme.palette.text.dark!

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center'>
      <Stack direction='row' spacing={1} alignItems='center'>
        <row.icon style={{ fontSize: row.iconFontSize || 20, color: row.iconColor || theme.palette.secondary.dark }} />
        <Typography variant='body2' component='span'>{row.title}:</Typography>
      </Stack>
      <Typography variant='subtitle2' component='span'
                  color={row.color ?? defaultColor}
      >
        {row.value}
      </Typography>
    </Stack>
  )
}

export default InfoRow