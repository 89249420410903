import { config } from '../core/config';

//const webSocketUrl = 'wss://dev.api.offer.pragma.info';

const webSocketUrl = config.apiHost.replace('https://', 'wss://');

export const websocketApi = {
  singleTask: {
    url: `${webSocketUrl}/single-task`,
    types: {
      uploadFile: 'upload_file_lsr',
      cancelUpload: 'cancel_upload_lsr',
      createCalc: 'create_calc',
      importHandbook: 'upload_handbook',
      ping: 'ping',
    },
  },
};

export let socketLsr: WebSocket;
export let socketCalc: WebSocket;
// export let socketTangl: WebSocket;
export let socketPriceReference: WebSocket;

export function initLsrWebsocket() {
  socketLsr = new WebSocket(`${config.apiHost.replace('https://', 'wss://')}/single-task`);
  socketLsr.onclose = function (e) {
    console.log('Connection is closed.');
    console.log('Reason: ' + (e.code === 1011 ? 'files error' : 'unexpected error'));
    console.log('Code: ' + e.code);
    setTimeout(initLsrWebsocket, 20000);
  };
}
// export function initTanglWebsocket() {
//   socketLsr = new WebSocket(`${config.apiHost.replace('https://', 'wss://')}/single-task`);
//   socketLsr.onclose = function (e) {
//     console.log('Connection is closed.');
//     console.log('Reason: ' + (e.code === 1011 ? 'files error' : 'unexpected error'));
//     console.log('Code: ' + e.code);
//     setTimeout(initLsrWebsocket, 20000);
//   };
// }

export function initPriceReferenceWebsocket() {
  socketPriceReference = new WebSocket(`${config.apiHost.replace('https://', 'wss://')}/single-task`);
  socketPriceReference.onclose = function (e) {
    console.log('Connection is closed.');
    console.log('Reason: ' + (e.code === 1011 ? 'files error' : 'unexpected error'));
    console.log('Code: ' + e.code);
    // setTimeout(initPriceReferenceWebsocket, 20000);
  };
}

export function initCalcWebsocket() {
  socketCalc = new WebSocket(`${config.apiHost.replace('https://', 'wss://')}/single-task`);
  socketCalc.onclose = function (e) {
    console.log('Connection is closed.');
    console.log('Reason: ' + (e.code === 1011 ? 'data error' : 'unexpected error'));
    console.log('Code: ' + e.code);
    setTimeout(initCalcWebsocket, 20000);
  };
}

let timerId: ReturnType<typeof setTimeout>;

const pingWS = {
  type: websocketApi.singleTask.types.ping,
  bodyJson: JSON.stringify({
    payload: 'ping',
  }),
};

export function keepAlive(timeout = 10000) {
  if (socketLsr?.readyState == socketLsr?.OPEN) {
    socketLsr.send(JSON.stringify(pingWS));
  }
  if (socketCalc?.readyState == socketCalc.OPEN) {
    socketCalc?.send(JSON.stringify(pingWS));
  }
  timerId = setTimeout(keepAlive, timeout);
}

// function cancelKeepAlive() {
//   if (timerId) {
//     clearTimeout(timerId);
//   }
// }

initPriceReferenceWebsocket();
initLsrWebsocket();
initCalcWebsocket();
// keepAlive();
