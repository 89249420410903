import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICalculationDictionary } from 'api/calculationDictionary';
import { RootState } from '../../store';
import { StateFiltersSlice } from '../references/prices/types';

const initialState: { activeFilters: StateFiltersSlice; filteredData: ICalculationDictionary[]; applyed: boolean } = {
  filteredData: [],
  activeFilters: {
    codes: [],
    groups: [],
    resourceTypes: [],
    titles: [],
  },
  applyed: false,
};

const calculationDictionary = createSlice({
  name: 'calculationDictionary',
  initialState,
  reducers: {
    // changeUUID(state, action: PayloadAction<string | null>) {
    //   state.uuid = action.payload;
    // },
    // setNeighbor(state, { payload }: PayloadAction<number | null>) {
    //   state.neighbor = payload;
    // },
    // updateTable(state, { payload }: PayloadAction<IEstimateReference | IEstimateReference[]>) {
    //   const d = [...state.data];
    //   if (Array.isArray(payload)) {
    //     for (const iEstimateReference of payload) {
    //       const idx = d.findIndex((_) => _.uuid === iEstimateReference.uuid);
    //       if (idx >= 0) {
    //         d.splice(idx, 1, iEstimateReference);
    //       }
    //     }
    //   } else {
    //     const idx = state.data.findIndex((_) => _.uuid === payload.uuid);
    //     if (idx) {
    //       d.splice(idx, 1, payload);
    //     }
    //   }
    //   state.data = d;
    //   state.parentLevel = null;
    //   state.isCreated = false;
    // },
    setGroupFilter(
      state,
      {
        payload,
      }: PayloadAction<{
        key: keyof typeof state.activeFilters;
        data:
          | StateFiltersSlice['codes']
          | StateFiltersSlice['groups']
          | StateFiltersSlice['resourceTypes']
          | StateFiltersSlice['titles'];
        replace?: boolean;
      }>,
    ) {
      if (payload.replace) {
        state.activeFilters[payload.key] = payload.data;
      } else {
        state.activeFilters[payload.key] = [...new Set([...state.activeFilters[payload.key], ...payload.data])];
      }
    },
    // setInitialFilters(state, { payload }: PayloadAction<IResponseFilterCalculation>) {
    //   state.activeFilters = {
    //     ...payload,
    //     resourceTypes: (payload.resourceTypes || []).map((item: string, idx: number) => ({ id: idx + 1, name: item })),
    //   };
    // },
    clearFilter(state, { payload }: PayloadAction<keyof typeof state.activeFilters>) {
      state.activeFilters[payload] = [];
      const clearAll =
        !state.activeFilters.codes.length &&
        !state.activeFilters.groups.length &&
        !state.activeFilters.resourceTypes.length &&
        !state.activeFilters.titles.length;
      if (clearAll) {
        state.filteredData = [];
        state.applyed = false;
      }
    },
    setFilter(
      state,
      {
        payload: { type, id, name },
      }: PayloadAction<{ type: keyof typeof state.activeFilters; id: number | string; name: string }>,
    ) {
      const copyFilters: StateFiltersSlice = { ...state.activeFilters };
      let currentFilter = [...copyFilters[type]];
      const index = currentFilter.findIndex((item) => item.id === id);
      if (index >= 0) {
        currentFilter = currentFilter.filter((_) => _.id !== id);
      } else {
        currentFilter.push({ id, name });
      }
      copyFilters[type] = currentFilter as
        | StateFiltersSlice['codes']
        | StateFiltersSlice['groups']
        | StateFiltersSlice['resourceTypes']
        | StateFiltersSlice['titles'];
      state.activeFilters = copyFilters;
      const clearAll =
        !state.activeFilters.codes.length &&
        !state.activeFilters.groups.length &&
        !state.activeFilters.resourceTypes.length &&
        !state.activeFilters.titles.length;
      if (clearAll) {
        state.applyed = false;
      }
    },
    setTableFilter(state, { payload }: PayloadAction<ICalculationDictionary[]>) {
      state.filteredData = payload;
    },
    changeApplyed(state, { payload }: PayloadAction<boolean>) {
      state.applyed = payload;
    },
    // removeItemInStoreByID(state, { payload }: PayloadAction<number | number[]>) {
    //   if (Array.isArray(payload)) {
    //     console.log(payload);
    //     payload.forEach((item) => {
    //       console.log(state.data);
    //       const itemInStore = state.data?.find((i) => {
    //         return i.id === item;
    //       });
    //       console.log(itemInStore);
    //       if (itemInStore) {
    //         const el = state.data?.find((i) => i.uuid === itemInStore.parentUUID);
    //         console.log({ ...el });
    //       }
    //     });
    //   } else {
    //   }
    // },
    // removeParentLevel(state) {
    //   state.parentLevel = null;
    // },
    // removeTemplate(state) {
    //   state.data = state.data.filter((row) => !(row.id < 0));
    //   state.isCreated = false;
    // },
    // changeCreated(state, { payload }: PayloadAction<boolean | undefined>) {
    //   if (payload !== undefined) {
    //     console.log('payload ', payload);
    //     state.isCreated = payload;
    //   } else {
    //     state.isCreated = !state.isCreated;
    //   }
    // },
    // initialUnit(state, { payload }: PayloadAction<Array<UnitWeight> | UnitWeight>) {
    //   if (Array.isArray(payload)) {
    //     state.unitList = payload;
    //   } else {
    //     state.unitList.push(payload);
    //   }
    // },
    // initialGroup(state, { payload }: PayloadAction<Array<IKFOGroup> | IKFOGroup>) {
    //   if (Array.isArray(payload)) {
    //     state.groupList = payload.map((_) => ({ title: _.name, id: _.id! }));
    //   } else {
    //     state.groupList.push({ title: payload.name, id: payload.id! });
    //   }
    // },
    // removeUnit(state, { payload }: PayloadAction<number>) {
    //   if (payload > 10) {
    //     const index = state.unitList.findIndex((_) => _?.id === payload);
    //     index >= 0 && state.unitList.splice(index, 1);
    //   }
    // },
    // addUnit(state, { payload }: PayloadAction<UnitWeight>) {
    //   state.unitList.push(payload);
    // },
    // removeGroup(state, { payload }: PayloadAction<number>) {
    //   if (payload > 10) {
    //     const index = state.groupList.findIndex((_) => _?.id === payload);
    //     index >= 0 && state.groupList.splice(index, 1);
    //   }
    // },
    // addGroup(state, { payload: { title, id } }: PayloadAction<{ title: string; id: number }>) {
    //   state.groupList.push({ id: id!, title });
    // },
    // initialDataSet(state, { payload }: PayloadAction<IEstimateReference[]>) {
    //   if (payload.length) {
    //     state.data = payload;
    //   } else {
    //     state.isCreated = true;
    //     state.data = [
    //       {
    //         parentUUID: null,
    //         id: -1,
    //         hash: -1,
    //         uuid: '-1',
    //         rowType: 'FOLDER',
    //         create: true,
    //         lvl: 1,
    //         fot: null,
    //         mim: null,
    //         mtr: null,
    //         pz: null,
    //         qty: null,
    //         title: '',
    //         resourceType: null,
    //         unit: null,
    //         parentID: null,
    //         code: '',
    //         kfoUnit: null,
    //         kfoName: null,
    //         kfoCoefficient: null,
    //         description: null,
    //         children: [],
    //         totalWorkTime: null,
    //       },
    //       // {
    //       //   id: -2,
    //       //   hash: -2,
    //       //   uuid: '-2',
    //       //   rowType: 'FOLDER',
    //       //   create: true,
    //       //   lvl: 2,
    //       //   fot: null,
    //       //   mim: null,
    //       //   mtr: null,
    //       //   pz: null,
    //       //   qty: null,
    //       //   title: '',
    //       //   resourceType: null,
    //       //   unit: null,
    //       //   parentID: null,
    //       //   code: '',
    //       //   kfoUnit: null,
    //       //   kfoName: null,
    //       //   kfoCoefficient: null,
    //       //   description: null,
    //       //   children: [],
    //       //   totalWorkTime: null,
    //       // },
    //       // {
    //       //   id: -3,
    //       //   hash: -3,
    //       //   uuid: '-3',
    //       //   rowType: 'FOLDER',
    //       //   create: true,
    //       //   lvl: 3,
    //       //   fot: null,
    //       //   mim: null,
    //       //   mtr: null,
    //       //   pz: null,
    //       //   qty: null,
    //       //   title: '',
    //       //   resourceType: null,
    //       //   unit: null,
    //       //   parentID: null,
    //       //   code: '',
    //       //   kfoUnit: null,
    //       //   kfoName: null,
    //       //   kfoCoefficient: null,
    //       //   description: null,
    //       //   children: [],
    //       //   totalWorkTime: null,
    //       // },
    //       // {
    //       //   id: -4,
    //       //   hash: -4,
    //       //   uuid: '-4',
    //       //   rowType: 'RATE',
    //       //   create: true,
    //       //   lvl: null,
    //       //   fot: null,
    //       //   mim: null,
    //       //   mtr: null,
    //       //   pz: null,
    //       //   qty: null,
    //       //   title: '',
    //       //   resourceType: null,
    //       //   unit: null,
    //       //   parentID: null,
    //       //   code: '',
    //       //   kfoUnit: null,
    //       //   kfoName: null,
    //       //   kfoCoefficient: null,
    //       //   description: null,
    //       //   children: [],
    //       //   totalWorkTime: null,
    //       // },
    //     ];
    //   }
    // },
    // removeDataByID(state, action: PayloadAction<IEstimateReference>) {
    //   if (action.payload && action.payload.parentID) {
    //     const indexParent = state.data.findIndex((_) => _.id === action.payload.parentID);
    //     let parentTarget = state.data.find((item) => item.id === action.payload.parentID);
    //     if (parentTarget) {
    //       parentTarget.children = parentTarget.children.filter((_) => _.id === action.payload.id);
    //       state.data[indexParent] = parentTarget;
    //     }
    //   }
    //   state.template = null;
    //   state.isCreated = false;
    //   state.parentLevel = null;
    // },
    // setParentLevel(state, action: PayloadAction<{ id: number | null; template: number }>) {
    //   state.parentLevel = action.payload.id;
    //   state.template = action.payload.template;
    // },
  },
});

export const getCalculationDictionaryState = (state: RootState) => state.calcDictionary;

export const { clearFilter, changeApplyed, setFilter, setTableFilter, setGroupFilter } = calculationDictionary.actions;
export default calculationDictionary.reducer;
