import { AutocompleteInputChangeReason, TextField, createFilterOptions } from '@mui/material';
import { StyledSelectMenuItem } from 'components/UserManagement/components/UserRoleSelection';
import { useField, useFormikContext } from 'formik';
import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutocompleteFormControl, StyledAutocompleteField } from './AutocompleteField.styles';
import { AutocompleteFieldProps, OptionType } from './AutocompleteField.types';
import { BaseTextField } from 'components/BaseTextField';

const filter = createFilterOptions<OptionType>();

export const AutocompleteField: FC<AutocompleteFieldProps> = ({
  fieldName,
  data,
  placeholder,
  onInputChange: parentOnInputChange,
  groupBy,
  readOnly,
  helperText,
  ...props
}) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<any>();

  const [{ onBlur: onFieldBlur, ...field }, { value, touched, error }] = useField({
    name: fieldName,
  });

  const formattedValue = useMemo(() => {
    const selectedValue = data.find((item) => item.value === value)?.label || value || '';

    const splitTedValue = selectedValue.split('&')[1];

    return splitTedValue ?? selectedValue;
  }, [value]);

  const onInputChange = (e: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
    parentOnInputChange?.(e, value, reason);
  };

  const isError = touched && !!error;

  return (
    <AutocompleteFormControl>
      <StyledAutocompleteField
        {...field}
        readOnly={readOnly}
        value={formattedValue}
        onChange={(event, newValue: any) => {
          if (typeof newValue === 'string') {
            setFieldValue(fieldName, newValue);
          } else if (newValue && newValue.inputValue) {
            setFieldValue(fieldName, newValue.inputValue);
          } else if (newValue === null) {
            setFieldValue(fieldName, '');
          } else {
            setFieldValue(fieldName, newValue.value);
          }
        }}
        onInputChange={onInputChange}
        options={data}
        renderInput={(params) => (
          <BaseTextField
            {...params}
            size="small"
            hiddenLabel
            name={fieldName}
            placeholder={placeholder}
            helperText={isError && helperText ? t(error as string) : undefined}
            onBlur={onFieldBlur}
            error={isError}
          />
        )}
        renderOption={(props, option: any) => {
          return (
            <StyledSelectMenuItem {...props} key={option.key}>
              {option.label}
            </StyledSelectMenuItem>
          );
        }}
        /*  filterOptions={(options: any, params) => {
          const filteredBySelected = options.filter((item: any) => !item.removeFromSelect);
          const filtered = filter(filteredBySelected, params);
          if (!props.freeSolo) return filtered;

          const { inputValue } = params;
          const isExisting = options.some((option: any) => option.label.includes(inputValue));
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              label: `Добавить "${inputValue}"`,
            });
          }

          return filtered;
        }} */
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        noOptionsText="Ничего не найдено"
        clearText="Удалить"
        groupBy={groupBy}
        {...props}
      />
    </AutocompleteFormControl>
  );
};
