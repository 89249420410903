import { Box, ButtonGroup, Divider, IconButton, Popper, TextField, Typography } from '@mui/material';
import styled from 'styled-components';
import { ColorsBgLevelType, ColorsBgRowType } from 'types';
import { FieldForm } from '../../../../../components/FieldForm';

export const StyledBoxRow = styled(Box)`
  width: 100%;
  display: flex;
  background: #0044b41a;
  color: #fff;
  align-items: center;
`;

export const StyledFieldForm = styled(FieldForm)`
  width: 100%;
  & .MuiFilledInput-root {
    border: none;
  }

  & .MuiInputBase-input {
    padding: 4px 10px;
    background: #fff;
    border-radius: 4px;
    color: #2b3648;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    border: 2px solid rgba(92, 110, 140, 0.2);
    border-radius: 4px;
    &:hover {
      border: 2px solid #0044b4;
    }

    &::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #9aa2b0;
    }
    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #9aa2b0;
    }
    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #9aa2b0;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #9aa2b0;
    }
  }

  .Mui-focused {
    .MuiInputBase-input {
      &::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #ffffff;
      }
    }
  }

  .Mui-error {
    .MuiInputBase-input {
      border: 1px solid red;
      &::-webkit-input-placeholder {
        /* WebKit browsers */
        color: red;
      }
      &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: red;
      }
      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: red;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: red;
      }
    }
  }

  & .MuiFormHelperText-root.Mui-error {
    position: absolute;
    bottom: 12px;
    left: 15px;
    color: #f46b6b;
    margin: 0;
  }
  & .MuiFormHelperText-root.MuiFormHelperText-filled {
    bottom: 14px;
    right: 12px;
    left: revert;
  }
`;
export const StyledTextField = styled(TextField)`
  border: none;
  background-color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  color: #2b3648;
  & .MuiInputBase-input {
    background-color: #ffffff;
    border: none;
    text-align: center;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

export const StyledFieldFormMin = styled(FieldForm)`
  width: 100%;
  position: relative;

  & .MuiFilledInput-root {
    border: none;
    background-color: #fff;
  }

  & .MuiInputBase-input {
    padding: 4px 10px;
    border-radius: 4px;
    color: #2b3648;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    z-index: 1;

    border: 2px solid rgba(92, 110, 140, 0.2);

    &:hover {
      border: 2px solid #0044b4;
    }

    &::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #ffffff;
    }
    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #ffffff;
    }
    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #ffffff;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #ffffff;
    }
  }
  .Mui-focused {
    .MuiInputBase-input {
      &::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #ffffff;
      }
    }
  }

  .Mui-error {
    .MuiInputBase-input {
      border: 1px solid red;
      &::-webkit-input-placeholder {
        /* WebKit browsers */
        color: red;
      }
      &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: red;
      }
      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: red;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: red;
      }
    }
  }

  & .MuiFormHelperText-root.Mui-error {
    position: absolute;
    bottom: 3px;
    left: 15px;
    color: #f46b6b;
    margin: 0;
    z-index: 0;
  }
  & .MuiFormHelperText-root.MuiFormHelperText-filled {
    bottom: 14px;
    left: 120px;
  }
`;

export const StyledIconButton = styled(IconButton)`
  width: 24px;
  height: 24px;
  padding: 0px;
`;

interface StyledTypographyLevelProps {
  colorBgRow: ColorsBgRowType;
}

export const StyledTypographyLevel = styled(Typography)`
  color: #2b3648;
  font-size: 14px;

  line-height: 20px;
  margin-left: 12px;
  width: 100%;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
export const StyledTypographyRow = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  text-align: left;
  color: #2b3648;
  padding: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const StyledPopper = styled(Popper)`
  inset: 0px auto auto -45px !important;
  width: auto;
  height: 32px;
  background: #ffffff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  text-align: center;
`;
export const StyledButtonGroup = styled(ButtonGroup)`
  height: 32px;
  width: 100%;
  align-items: center;
`;
export const StyledDivider = styled(Divider)`
  margin: revert;
  height: 20px;
  width: 2px;
  background-color: rgba(122, 145, 247, 0.3);
  border-radius: 7px 7px 7px 7px;
  border-right-width: inherit;
`;
export const StyledLevel = styled(Box)`
  display: grid;
  grid-template-columns:
    100px minmax(206px, 1fr) repeat(4, minmax(135px, 206px))
    30px;
  grid-auto-rows: 50px;
`;

interface StyledLevelTdProps {
  colorsBgLevel?: ColorsBgLevelType;
}

export const StyledLevelTd = styled(Box)<StyledLevelTdProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  box-shadow: inset 0px -1px 0px rgba(0, 36, 95, 0.1);
  background-color: ${(props) => {
    return props.theme.palette.bgLevel[props.colorsBgLevel!];
  }};
`;
interface StyledRowProps {
  colorBgRow: ColorsBgRowType;
}

export const StyledRow = styled(Box)<StyledRowProps>`
  background-color: ${(props) => props.theme.palette.bgRow[props.colorBgRow]};
  display: flex;
  height: 50px;
`;
export const StyledRowTd = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  box-shadow: inset 0px -1px 0px rgba(0, 36, 95, 0.1);
`;
