import { Box } from '@mui/material';
import styled, { css } from 'styled-components';
import { getLevelColor, levelNumberSize } from './LevelFolder.service';
import { LevelFolderSize } from './LevelFolder.types';

interface StyledLevelFolderProps {
  $level: number | undefined;
  $isHover?: boolean;
  $isExpanded?: boolean;
  $size?: LevelFolderSize;
}

export const StyledLevelFolder = styled(Box)<StyledLevelFolderProps>`
  display: flex;
  position: relative;

  ${({ $level }) => {
    if ($level) {
      return css`
        color: ${getLevelColor($level)};
      `;
    }
  }}
  ${({ $level, $size }) => {
    return css`
      &::after {
        content: '${$level}';
        position: absolute;
        color: white;
        font-size: ${$size ? levelNumberSize[$size] : 'inherit'};
        top: 55%;
        left: 47%;
        transform: translate(-50%, -50%);
      }
    `;
  }}

  &:hover {
    ${({ $isHover, $isExpanded, $level, $size }) => {
      return css`
        &::after {
          content: '';
          width: 10px;
          height: 10px;
          border-top: 2px solid white;
          border-right: 2px solid white;
          position: absolute;
          top: ${$isExpanded ? '60%' : '48%'};
          left: 47%;
          transform: translate(-50%, -50%) ${`rotate(${$isExpanded ? '-45deg' : '135deg'})`};
        }
      `;
    }};
  }
`;
