import { ColDef, ColGroupDef, ICellRendererParams, ValueGetterParams, ValueSetterParams } from "ag-grid-community";
import { useMemo } from "react";
import { ICalculationDictionary, ICreateCalcRowRequest, TResourceType } from "../../../../api/calculationDictionary";
import { resourceRus } from "../../../../api/references/estimates/estimates.types";
import { CalcData } from "../../../../types";
import HeaderComponent from "../../../Administration/AdminReferences/Prices/components/HeaderComponent";
import { modifyValue } from "../../../Administration/AdminReferences/Prices/useTableData";
import Cell from "../Accomplishment/components/Cell";
import CustomTableHeader from "../Accomplishment/components/CustomTableHeader";
import cellEditor from "./components/CellEditor";
import Level from "./components/Level/Level";
import { modifyNum } from "../../../../utils/getFormatNumber";

export interface ICalculationDirectory {
  calculation?: CalcData;
  openEdit: () => void;
}
export function getNameField(resource:TResourceType,isPrice:boolean=false): string {
  switch (resource) {
    case "WORKERS":
      return isPrice?'fot':'fotPerUnit'
    case "MiM":
    case "MACHINE_OPERATORS":
      return isPrice?'mim':'mimPerUnit'
    default:
      return isPrice?'mtr':'mtrPerUnit'
  }
}
export interface ICalculationDirectoryContext {
  confirmCreateRate:(fn:any)=>void;
  setSelectRow:(row:null | ICalculationDictionary)=>void
  setDeletedLvlTitle?: (lvl: number) => void;
  clearRemovedIDs: () => void;
  unCollapseRows: () => void;
  deleteFolderConfirm: (type: 'FOLDER' | 'RATE' | 'RATE_POSITION', f: any) => void;
  selectRow: null | ICalculationDictionary;
  tableData: ICalculationDictionary[];
  rows: number[];
  removedIds: { type: string; id: number; parent: number | null }[];
  collapse: (params: ICellRendererParams<ICalculationDictionary>) => void;
  checkboxClick: (id: number, type: string, parent: number | null) => void;
}
// TODO типизировать
export const paramsField: Record<
  any,
  { float?: 'left' | 'center' | 'right'; value?: string; color?: string; modify?: (v: any) => any }
> = {
  order: {},
  resourceType: {
    float: 'left',
    modify(v) {
      return resourceRus[v as keyof typeof resourceRus] ?? v;
    },
  },
  num: {
    float: 'center',
  },
  code: {
    float: 'center',
  },
  title: {
    float: 'left',
  },
  unit: {
    float: 'center',
  },
  qtyPerUnit: {
    float: 'center',
    modify: (v) => modifyValue(v, false),
  },
  qtyTotal: {
    float: 'center',
    modify: (v) => modifyValue(v, false),
  },
  'curr.fotPerUnit': {
    float: 'right',
    modify: (v) => modifyValue(v, true),
  },
  'curr.mimPerUnit': {
    float: 'right',
    modify: (v) => modifyValue(v, true),
  },
  'curr.mtrPerUnit': {
    float: 'right',
    modify: (v) => modifyValue(v, true),
  },
  'curr.costPerUnit': {
    float: 'right',
    modify: (v) => modifyValue(v, true),
  },
  'curr.kzPerUnit': {
    float: 'right',
    modify: (v) => modifyValue(v, true),
  },
  'curr.totalPerUnit': {
    float: 'right',
    modify: (v) => modifyValue(v, true),
  },
  'curr.fotTotal': {
    float: 'right',
    modify: (v) => modifyValue(v, true),
  },
  'curr.mimTotal': {
    float: 'right',
    modify: (v) => modifyValue(v, true),
  },
  'curr.mtrTotal': {
    float: 'right',
    modify: (v) => modifyValue(v, true),
  },
  'curr.costTotal': {
    float: 'right',
    modify: (v) => modifyValue(v, true),
  },
  'curr.kzTotal': {
    float: 'right',
    modify: (v) => modifyValue(v, true),
  },
  'curr.total': {
    float: 'right',
    modify: (v) => modifyValue(v, true),
  },
  'base.fotPerUnit': {
    float: 'right',
    modify: (v) => modifyValue(v, true),
  },
  'base.mimPerUnit': {
    float: 'right',
    modify: (v) => modifyValue(v, true),
  },
  'base.mtrPerUnit': {
    float: 'right',
    modify: (v) => modifyValue(v, true),
  },
  'base.costPerUnit': {
    float: 'right',
    modify: (v) => modifyValue(v, true),
  },
  'base.kzPerUnit': {
    float: 'right',
    modify: (v) => modifyValue(v, true),
  },
  'base.totalPerUnit': {
    float: 'right',
    modify: (v) => modifyValue(v, true),
  },
  'base.fotTotal': {
    float: 'right',
    modify: (v) => modifyValue(v, true),
  },
  'base.mimTotal': {
    float: 'right',
    modify: (v) => modifyValue(v, true),
  },
  'base.mtrTotal': {
    float: 'right',
    modify: (v) => modifyValue(v, true),
  },
  'base.costTotal': {
    float: 'right',
    modify: (v) => modifyValue(v, true),
  },
  'base.kzTotal': {
    float: 'right',
    modify: (v) => modifyValue(v, true),
  },
  'base.total': {
    float: 'right',
    modify: (v) => modifyValue(v, true),
  },
  tzPerUnit: {
    float: 'right',
    modify: (v) => modifyValue(v, true),
  },
  tzTotal: {
    float: 'right',
    modify: (v) => modifyValue(v, true),
  },
  tzmPerUnit: {
    float: 'right',
    modify: (v) => modifyValue(v, true),
  },
  tzmTotal: {
    float: 'right',
    modify: (v) => modifyValue(v, true),
  },
  workTimePerUnit: {
    float: 'center',
    modify: (v) => modifyValue(v, true),
  },
  workTimeTotal: {
    float: 'center',
    modify: (v) => modifyValue(v, true),
  },
  kfoGroup: {
    float: 'left',
  },
  kfoUnit: {
    float: 'center',
  },
  kfoCoef: {
    float: 'center',
    modify: (v) => modifyValue(v, true),
  },
};
const excludeBaseNamesBaseCurr = [
  'fotPerUnit',
  'mimPerUnit',
  'mtrPerUnit',
  'costPerUnit',
  'kzPerUnit',
  'tzPerUnit',
  'tzmPerUnit',
  'totalPerUnit']
export function cellRenderer(params: ICellRendererParams<ICalculationDictionary>) {
  const value = useMemo(() => {
    if (params.colDef?.field?.startsWith('base')) {
      const name = params.colDef?.field.split('.')[1];
      if (params.data?.rowType === 'FOLDER'&&excludeBaseNamesBaseCurr.includes(name)) {
        return null
      }
      return params.data?.base?.[name as keyof typeof params.data.base];
    }
    if (params.colDef?.field?.startsWith('curr')) {
      const name = params.colDef?.field.split('.')[1];
      if (params.data?.rowType === 'FOLDER'&&excludeBaseNamesBaseCurr.includes(name)) {
        return null
      }
      return params.data?.curr?.[name as keyof typeof params.data.base];
    }
    if (excludeBaseNamesBaseCurr.includes(params.colDef?.field || '')&& params.data?.rowType === 'FOLDER') {
      return null
    }
    return params.value;
  }, [params]);

  const props =
    params.colDef?.field && params.colDef.field in paramsField
      ? paramsField[params.colDef.field as keyof typeof paramsField]
      : {};

  const renderValue = params.data?.rowType === 'RATE' && params.colDef?.field === 'qtyPerUnit'
    ? props?.modify?.(params.data?.qtyTotal) ?? params.data?.qtyTotal ?? 0
    : value === null ?null:props?.modify?.(value) ?? value;

  const renderToolTip = params.data?.rowType === 'RATE' && params.colDef?.field === 'qtyPerUnit'
    ? params.data?.qtyTotal ?? params.data?.qtyTotal ?? 0
    : value ;

  return (
    <Cell {...props} originData={modifyNum(renderToolTip)}>
      {renderValue}
    </Cell>
  );
}
export const fotRequired = ['WORKERS'];
export const mimRequired = ['MiM', 'MACHINE_OPERATORS'];
export const mtrRequired = ['SERVICES', 'EQUIPMENT', 'MATERIALS'];
export const fieldsGroupTyping = {
  "fotPerUnit":fotRequired,
  "mimPerUnit":mimRequired,
  "mtrPerUnit":mtrRequired
};
const requiredForEditableWorkHour: Array<TResourceType> = ['SERVICES', 'WORKERS', 'MACHINE_OPERATORS', 'MiM'];

export const useTable = (valueSetter: (params: ValueSetterParams<ICalculationDictionary>) => boolean) => {
  const columnsDef: (ColDef<ICalculationDictionary> | ColGroupDef<ICalculationDictionary>)[] = useMemo(
    () => [
      {
        field: 'lvl',
        headerName: '',
        colSpan: (params: any) => {
          return params.data?.id === -2500 ? 6 : 1;
        },
        cellRenderer: Level,
      },
      {
        field: 'num',
        headerName: '№ п/п',
        cellRenderer,
      },
      {
        field: 'resourceType',
        headerName: 'Тип ресурса',
        headerComponent: HeaderComponent,
        valueSetter,
        valueGetter: (params: ValueGetterParams) => {
          return params?.data?.resourceType;
        },
        cellClass: ({ data }) => {
          return data?.rowType === 'RATE'
            ? !data?.children?.length
              ? 'cell-hover'
              : ''
            : data?.rowType === 'RATE_POSITION'
            ? 'cell-hover'
            : '';
        },
        editable: ({ data }) => {
          return data?.rowType === 'RATE' ? !data?.children?.length : data?.rowType === 'RATE_POSITION';
        },
        width: 188,
        cellRenderer,
        cellEditor,
      },
      {
        field: 'code',
        headerName: 'Шифр',
        headerComponent: HeaderComponent,
        editable: (params) => params.data?.rowType === 'RATE',
        valueSetter,
        valueGetter: (params: ValueGetterParams) => {
          return params?.data?.code;
        },
        cellClass: ({ data }) => {
          return data?.rowType === 'RATE' ? 'cell-hover' : '';
        },

        cellRenderer,
        cellEditor,
      },
      {
        field: 'title',
        headerName: 'Наименование',
        headerComponent: HeaderComponent,
        width: 428,
        editable: true,
        valueSetter,
        valueGetter: (params: ValueGetterParams) => {
          return params?.data?.title;
        },
        cellClass: () => {
          return 'cell-hover';
        },
        cellRenderer,
        cellEditor,
      },
      {
        field: 'unit',
        headerName: 'Ед.изм.',
        width: 148,
        valueSetter,
        valueGetter: (params: ValueGetterParams) => {
          return params?.data?.unit;
        },
        cellClass: ({ data }) => {
          return data?.rowType !== 'FOLDER' ? 'cell-hover' : '';
        },
        editable: ({ data }) => data?.rowType !== 'FOLDER',
        cellRenderer,
        cellEditor,
      },
      {
        headerName: 'Количество',
        children: [
          {
            field: 'qtyPerUnit',
            headerName: 'На единицу',
            valueSetter,
            valueGetter: (params: ValueGetterParams) => {
              if (params.data?.rowType === 'RATE') {
                return params?.data?.qtyTotol;
              }
              return params?.data?.qtyPerUnit;
            },
            cellClass: ({ data }) => {
              return data?.rowType !== 'FOLDER' ? 'cell-hover' : '';
            },
            editable: (params) => params.data?.rowType !== 'FOLDER',
            colSpan: (params: any) => {
              return params.data?.rowType !== 'RATE_POSITION' ? 2 : 1;
            },
            cellRenderer,
            cellEditor,
          },
          {
            field: 'qtyTotal',
            headerName: 'Всего',
            valueSetter,
            valueGetter: (params: ValueGetterParams) => {
              return params?.data?.qtyTotal;
            },
            // cellClass: ({ data }) => {
            //   return data?.rowType === 'RATE_POSITION' ? 'cell-hover' : '';
            // },
            editable: (params) => false,
            cellRenderer,
            cellEditor,
          },
        ],
      },
      {
        headerName: 'Базовая стоимость',
        suppressMovable: true,
        // headerClass: (params: any) => (part.actID === params?.context?.current?.id ? 'header-top-border' : ''),
        headerGroupComponent: CustomTableHeader,
        headerGroupComponentParams: {
          weight: true,
        },
        children: [
          {
            headerName: 'Стоимость единицы',
            columnGroupShow: 'closed',
            children: [
              {
                field: 'base.costPerUnit',
                headerName: 'Всего',
                // valueSetter,
                // valueGetter: (params: ValueGetterParams) => {
                //   return params?.data?.costPerUnit;
                // },
                cellRenderer,
                // cellEditor,
              },
            ],
          },
          {
            headerName: 'Общая стоимость',
            columnGroupShow: 'closed',
            children: [
              {
                field: 'base.costTotal',
                headerName: 'Всего',
                cellRenderer,
              },
            ],
          },
          {
            headerName: 'Стоимость единицы',
            headerClass:'ag-sticky-labelass',
            columnGroupShow: 'open',
            children: [
              {
                field: 'base.fotPerUnit',
                headerName: 'ФОТ ОР',
                editable: (params) =>
                  params.data?.rowType === 'RATE'
                    ? params.data!.resourceType!?fotRequired.includes(params.data!.resourceType!):!params.data.children?.length
                    : params.data?.rowType === 'RATE_POSITION' && fotRequired.includes(params.data!.resourceType!),
                cellClass: ({ data }) => {
                  return data?.rowType === 'RATE'
                    ? data!.resourceType!?fotRequired.includes(data!.resourceType!)&&'cell-hover':!data.children?.length
                      ? 'cell-hover'
                      : ''
                    : data?.rowType === 'RATE_POSITION' && fotRequired.includes(data!.resourceType!)
                    ? 'cell-hover'
                    : '';
                },
                valueSetter,
                valueGetter: (params: ValueGetterParams) => {
                  return params?.data?.base?.fotPerUnit;
                },
                cellRenderer,
                cellEditor,
              },
              {
                field: 'base.mimPerUnit',
                headerName: 'МиМ',
                editable: (params) =>
                  params.data?.rowType === 'RATE'
                    ? params.data!.resourceType!?mimRequired.includes(params.data!.resourceType!):!params.data.children?.length
                    : params.data?.rowType === 'RATE_POSITION' && mimRequired.includes(params.data!.resourceType!),
                cellClass: ({ data }) => {
                  return data?.rowType === 'RATE'
                    ? data!.resourceType!?mimRequired.includes(data!.resourceType!)&&'cell-hover':!data.children?.length
                      ? 'cell-hover'
                      : ''
                    : data?.rowType === 'RATE_POSITION' && mimRequired.includes(data!.resourceType!)
                    ? 'cell-hover'
                    : '';
                },
                valueSetter,
                valueGetter: (params: ValueGetterParams) => {
                  return params?.data?.base?.mimPerUnit;
                },
                cellRenderer,
                cellEditor,
              },
              {
                field: 'base.mtrPerUnit',
                headerName: 'МТР',
                editable: (params) =>
                  params.data?.rowType === 'RATE'
                    ? params.data!.resourceType!?mtrRequired.includes(params.data!.resourceType!):!params.data.children?.length
                    : params.data?.rowType === 'RATE_POSITION' && mtrRequired.includes(params.data!.resourceType!),
                cellClass: ({ data }) => {
                  return data?.rowType === 'RATE'
                    ? data!.resourceType!?mtrRequired.includes(data!.resourceType!)&&'cell-hover':!data.children?.length
                      ? 'cell-hover'
                      : ''
                    : data?.rowType === 'RATE_POSITION' && mtrRequired.includes(data!.resourceType!)
                    ? 'cell-hover'
                    : '';
                },
                valueSetter,
                valueGetter: (params: ValueGetterParams) => {
                  return params?.data?.base?.mtrPerUnit;
                },
                cellRenderer,
                cellEditor,
              },
              {
                field: 'base.costPerUnit',
                headerName: 'Всего',
                // valueSetter,
                // valueGetter: (params: ValueGetterParams) => {
                //   return params?.data?.costPerUnit;
                // },
                cellRenderer,
                // cellEditor,
              },
              {
                field: 'base.kzPerUnit',
                headerName: 'КЗ',
                cellRenderer,
              },
              {
                field: 'base.totalPerUnit',
                headerName: 'Всего с КЗ',
                cellRenderer,
              },
            ],
          },
          {
            headerName: 'Общая стоимость',
            columnGroupShow: 'open',
            children: [
              {
                field: 'base.fotTotal',
                headerName: 'ФОТ ОР',
                cellRenderer,
              },
              {
                field: 'base.mimTotal',
                headerName: 'МиМ',
                cellRenderer,
              },
              {
                field: 'base.mtrTotal',
                headerName: 'МТР',
                cellRenderer,
              },
              {
                field: 'base.costTotal',
                headerName: 'Всего',
                cellRenderer,
              },
              {
                field: 'base.kzTotal',
                headerName: 'КЗ',
                cellRenderer,
              },
              {
                field: 'base.total',
                headerName: 'Всего с КЗ',
                cellRenderer,
              },
            ],
          },
        ],
      },
      {
        headerName: 'Текущая стоимость',
        suppressMovable: true,
        // headerClass: (params: any) => (part.actID === params?.context?.current?.id ? 'header-top-border' : ''),
        headerGroupComponent: CustomTableHeader,
        headerGroupComponentParams: {
          weight: true,
        },
        children: [
          {
            headerName: 'Стоимость единицы',
            columnGroupShow: 'closed',
            children: [
              {
                field: 'curr.costPerUnit',
                headerName: 'Всего',
                // valueSetter,
                // valueGetter: (params: ValueGetterParams) => {
                //   return params?.data?.costPerUnit;
                // },
                cellRenderer,
                // cellEditor,
              },
            ],
          },
          {
            headerName: 'Общая стоимость',
            columnGroupShow: 'closed',
            children: [
              {
                field: 'curr.costTotal',
                headerName: 'Всего',
                cellRenderer,
              },
            ],
          },
          {
            headerName: 'Стоимость единицы',
            columnGroupShow: 'open',
            children: [
              {
                field: 'curr.fotPerUnit',
                headerName: 'ФОТ ОР',
                // editable: (params) =>
                //   params.data?.rowType === 'RATE'
                //     ? !params.data.children?.length
                //     : params.data?.rowType === 'RATE_POSITION' && fotRequired.includes(params.data!.resourceType!),
                // cellClass: ({ data }) => {
                //   return data?.rowType === 'RATE'
                //     ? !data.children?.length
                //       ? 'cell-hover'
                //       : ''
                //     : data?.rowType === 'RATE_POSITION' && fotRequired.includes(data!.resourceType!)
                //     ? 'cell-hover'
                //     : '';
                // },
                // valueSetter,
                // valueGetter: (params: ValueGetterParams) => {
                //   return params?.data?.fotPerUnit;
                // },
                cellRenderer,
                cellEditor,
              },
              {
                field: 'curr.mimPerUnit',
                headerName: 'МиМ',
                // editable: (params) =>
                //   params.data?.rowType === 'RATE'
                //     ? !params.data.children?.length
                //     : params.data?.rowType === 'RATE_POSITION' && mimRequired.includes(params.data!.resourceType!),
                // cellClass: ({ data }) => {
                //   return data?.rowType === 'RATE'
                //     ? !data.children?.length
                //       ? 'cell-hover'
                //       : ''
                //     : data?.rowType === 'RATE_POSITION' && mimRequired.includes(data!.resourceType!)
                //     ? 'cell-hover'
                //     : '';
                // },
                // valueSetter,
                // valueGetter: (params: ValueGetterParams) => {
                //   return params?.data?.mimPerUnit;
                // },
                cellRenderer,
                cellEditor,
              },
              {
                field: 'curr.mtrPerUnit',
                headerName: 'МТР',
                // editable: (params) =>
                //   params.data?.rowType === 'RATE'
                //     ? !params.data.children?.length
                //     : params.data?.rowType === 'RATE_POSITION' && mtrRequired.includes(params.data!.resourceType!),
                // cellClass: ({ data }) => {
                //   return data?.rowType === 'RATE'
                //     ? !data.children?.length
                //       ? 'cell-hover'
                //       : ''
                //     : data?.rowType === 'RATE_POSITION' && mtrRequired.includes(data!.resourceType!)
                //     ? 'cell-hover'
                //     : '';
                // },
                // valueSetter,
                // valueGetter: (params: ValueGetterParams) => {
                //   return params?.data?.mtrPerUnit;
                // },
                cellRenderer,
                cellEditor,
              },
              {
                field: 'curr.costPerUnit',
                headerName: 'Всего',
                // valueSetter,
                // valueGetter: (params: ValueGetterParams) => {
                //   return params?.data?.costPerUnit;
                // },
                cellRenderer,
                // cellEditor,
              },
              {
                field: 'curr.kzPerUnit',
                headerName: 'КЗ',
                cellRenderer,
              },
              {
                field: 'curr.totalPerUnit',
                headerName: 'Всего с КЗ',
                cellRenderer,
              },
            ],
          },
          {
            headerName: 'Общая стоимость',
            columnGroupShow: 'open',
            children: [
              {
                field: 'curr.fotTotal',
                headerName: 'ФОТ ОР',
                cellRenderer,
              },
              {
                field: 'curr.mimTotal',
                headerName: 'МиМ',
                cellRenderer,
              },
              {
                field: 'curr.mtrTotal',
                headerName: 'МТР',
                cellRenderer,
              },
              {
                field: 'curr.costTotal',
                headerName: 'Всего',
                cellRenderer,
              },
              {
                field: 'curr.kzTotal',
                headerName: 'КЗ',
                cellRenderer,
              },
              {
                field: 'curr.total',
                headerName: 'Всего с КЗ',
                cellRenderer,
              },
            ],
          },
        ],
      },

      {
        headerName: 'ТЗ',
        children: [
          {
            field: 'tzPerUnit',
            headerName: 'На единицу',
            editable: (props)=>props.data?.rowType === 'RATE_POSITION' || props.data?.rowType === 'FOLDER'||props.data?.children?.length?false:props.data?.resourceType === null?true:!props.data?.children ?props.data?.resourceType !== 'MACHINE_OPERATORS':false,
            cellClass: (props) => {
              return props.data?.rowType === 'RATE_POSITION'|| props.data?.rowType === 'FOLDER'||props.data?.children?.length?'':props.data?.resourceType === null?'cell-hover':!props.data?.children ?props.data?.resourceType !== 'MACHINE_OPERATORS' && 'cell-hover':'';
            },
            valueSetter,
            valueGetter: (params: ValueGetterParams) => {
              return params?.data?.tzPerUnit;
            },
            cellEditor,
            cellRenderer,
          },
          {
            field: 'tzTotal',
            headerName: 'Всего',
            cellRenderer,
          },
        ],
      },
      {
        headerName: 'ТЗМ',
        children: [
          {
            field: 'tzmPerUnit',
            headerName: 'На единицу',
            editable: (props)=>props.data?.rowType === 'RATE_POSITION' || props.data?.rowType === 'FOLDER'||props.data?.children?.length?false: props.data?.resourceType === null?true:!props.data?.children ?props.data?.resourceType !== 'WORKERS':false ,
            cellClass: (props) => {
              return props.data?.rowType === 'RATE_POSITION' || props.data?.rowType === 'FOLDER'||props.data?.children?.length?'':props.data?.resourceType === null?'cell-hover':!props.data?.children ?props.data?.resourceType !== 'WORKERS' && 'cell-hover':'';
            },
            valueSetter,
            valueGetter: (params: ValueGetterParams) => {
              return params?.data?.tzmPerUnit;
            },
            cellRenderer,
            cellEditor,
          },
          {
            field: 'tzmTotal',
            headerName: 'Всего',
            cellRenderer,
          },
        ],
      },
      {
        headerName: 'Общее рабочее время',
        children: [
          {
            field: 'workTimePerUnit',
            headerName: 'На единицу',
            valueSetter,
            valueGetter: (params: ValueGetterParams) => {
              return params?.data?.workTimePerUnit;
            },
            editable: (params) =>

              params.data?.rowType === 'RATE_POSITION' ?requiredForEditableWorkHour.includes(params.data?.resourceType || 'MATERIALS'):
              params.data?.rowType === 'RATE'
                ? !params.data?.children?.length : false,
            //   params.data?.rowType === 'RATE_POSITION' ?requiredForEditableWorkHour.includes(params.data?.resourceType || 'MATERIALS'):
            //   params.data?.rowType === 'RATE'
            //     ? !params.data?.children?.length &&
            //       requiredForEditableWorkHour.includes(params.data?.resourceType || 'MATERIALS')
            //     : false,
            cellClass: ({ data }) => {
              return data?.rowType === 'RATE_POSITION' ?requiredForEditableWorkHour.includes(data?.resourceType || 'MATERIALS') && 'cell-hover':
              data?.rowType === 'RATE'
                ? !data?.children?.length && 'cell-hover' : ''
              // return data?.rowType === 'RATE_POSITION' ?requiredForEditableWorkHour.includes(data?.resourceType || 'MATERIALS') && 'cell-hover':
              // data?.rowType === 'RATE'
              //   ? !data?.children?.length &&
              //   requiredForEditableWorkHour.includes(data?.resourceType || 'MATERIALS')&& 'cell-hover'
              //   : false
            },
            cellRenderer,
            cellEditor,
          },
          {
            field: 'workTimeTotal',
            headerName: 'Всего',
            cellRenderer,
          },
        ],
      },
      {
        headerName: 'КФО',
        children: [
          {
            field: 'kfoGroup',
            headerName: 'Группа',
            width: 200,
            headerComponent: HeaderComponent,
            valueSetter,
            valueGetter: (params: ValueGetterParams) => {
              return params?.data?.kfoGroup;
            },
            cellClass: ({ data }) => {
              return data?.rowType === 'RATE' ? 'cell-hover' : '';
            },
            editable: (params) => params.data?.rowType === 'RATE',
            cellRenderer,
            cellEditor,
          },
          {
            field: 'kfoUnit',
            headerName: 'Ед. изм.',
            width: 148,
            valueSetter,
            valueGetter: (params: ValueGetterParams) => {
              return params?.data?.kfoUnit;
            },
            cellClass: ({ data }) => {
              return data?.rowType === 'RATE' ? 'cell-hover' : '';
            },
            editable: (params) => params.data?.rowType === 'RATE',
            cellRenderer,
            cellEditor,
          },
          {
            field: 'kfoCoef',
            headerName: 'Коэффициент',
            width: 148,
            valueSetter,
            valueGetter: (params: ValueGetterParams) => {
              return params?.data?.kfoCoef;
            },
            cellClass: ({ data }) => {
              return data?.rowType === 'RATE' ? 'cell-hover' : '';
            },
            editable: (params) => Boolean(params.data?.rowType === 'RATE'),
            cellRenderer,
            cellEditor,
          },
        ],
      },
    ],
    [valueSetter],
  );
  return {
    columnsDef,
  };
};
export function flatChildren(data: ICalculationDictionary) {
  const result: number[] = [];
  const flat = (d: ICalculationDictionary) => {
    result.push(d.id);
    if (d.children?.length > 0) {
      d.children.forEach(flat);
    }
  };
  result.push(data?.id);
  if (data?.children?.length) {
    data.children.forEach(flat);
  }
  return result;
}
export const rootRow: ICreateCalcRowRequest = {
  tzmPerUnit: null,
  code: null,
  costPerUnit: null,
  fotPerUnit: null,
  kfoCoef: null,
  kfoUnit: null,
  insertAfterID: null,
  insertBeforeID: null,
  kzPerUnit: null,
  mimPerUnit: null,
  mtrPerUnit: null,
  qtyPerUnit: null,
  qtyTotal: null,
  kfoGroupID: null,
  tzPerUnit: null,
  unit: null,
  lvl: 1,
  rowType: 'FOLDER',
  title: null,
  workTimePerUnit: null,
  resourceType: null,
  parentID: null,
};
