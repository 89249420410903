import styled from 'styled-components';
import {  CircularProgress, Typography } from '@mui/material';


export const StyledCircularProgress = styled(CircularProgress)`
    height: 100% !important;
    width: 100% !important;
    color: #0288d1;
    stroke-linecap: round;
    top: 0;
    left: 0;
    position: absolute;
`;

export const StyledTimeLabel = styled(Typography)`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    color: #7890b2;
`;