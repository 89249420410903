import { Box, Stack, TextField, Typography } from '@mui/material';
import styled from 'styled-components';
import { ColorsBgLevelType } from 'types';

export const StyledTableContainer = styled(Box)`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background: #fff;
`;
export const StyledTableHead = styled(Box)`
  display: grid;
  overflow: hidden;
  grid-template-columns:
    100px minmax(400px, 100%) minmax(636px, 1fr) 64px minmax(636px, 1fr)
    13px;
  grid-auto-rows: 100px;

  height: 100%;
  max-height: 100px;

  background-color: #7890b2;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  letter-spacing: 0.15px;
  color: #ffffff;
`;
export const StyledTableBody = styled(Box)`
  overflow: scroll;
  height: 100%;

  &::-webkit-scrollbar {
    box-shadow: inset 1px 0px 0px rgba(0, 36, 95, 0.1);
  }
`;
interface StyledBgLevelProps {
  colorsBgLevel: ColorsBgLevelType;
}

export const StyledBodyTd = styled(Box)<StyledBgLevelProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
  box-shadow: inset 0px -1px 0px rgba(0, 36, 95, 0.1);
  background-color: ${(props) => {
    return props.theme.palette.bgLevel[props.colorsBgLevel];
  }};
`;

export const StyledTypography = styled(Typography)`
  color: #5c6e8c;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  text-align: center;
  font-weight: 500;
`;
export const StyledHeadRow = styled(Stack)`
  align-items: flex-end;
  width: 100%;
  height: 100px;
`;
export const StyledHeadTd = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;
export const StyledGridBox = styled(Stack)`
  display: grid;
  grid-template-columns: repeat(3, 212px);
  margin: 0 auto;
  justify-content: center;
`;
export const StyledTitle = styled(Stack)<StyledBgLevelProps>`
  align-items: start;
  min-width: 400px;
  width: 100%;
  height: 100%;
  border-top: 2px solid #d2dcff;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  background-color: ${(props) => {
    return props.theme.palette.bgLevel[props.colorsBgLevel];
  }};
`;
export const StyledTitleChild = styled(Stack)`
  padding-left: 32px;
  align-items: start;
  min-width: 400px;
  width: 100%;
  height: 100%;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;
export const StyledContractStack = styled(Stack)`
  display: grid;
  grid-template-columns: repeat(3, minmax(165px, 265px));
  margin: 0 auto;
`;

export const StyledFieldFormMin = styled(TextField)`
  width: 100%;
  height: 48px;
  position: relative;
  border-radius: 4px;

  span {
    line-height: 12px;
  }

  & .MuiFilledInput-root {
    border: none;
    background-color: #fff;
  }

  & .MuiInputBase-input {
    padding: 14px 11px;
    color: #2b3648;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    border-radius: 4px;
    z-index: 1;

    &::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #ffffff;
    }
    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #ffffff;
    }
    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #ffffff;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #ffffff;
    }
  }

  .Mui-focused {
    .MuiInputBase-input {
      &::-webkit-input-placeholder {
        color: #ffffff;
      }
    }
  }

  & .MuiOutlinedInput-root {
    &:hover {
      & .MuiOutlinedInput-notchedOutline {
        border: 1px solid #0288d1;
      }
    }
  }
  & .Mui-focused {
    & .MuiOutlinedInput-notchedOutline {
      border: 1px solid #0288d1;
    }
  }

  .Mui-error {
    .MuiInputBase-input {
      border: 1px solid red;
      &::-webkit-input-placeholder {
        /* WebKit browsers */
        color: red;
      }
      &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: red;
      }
      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: red;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: red;
      }
    }
  }

  & .MuiFormHelperText-root.Mui-error {
    position: absolute;
    bottom: 3px;
    left: 15px;
    color: #f46b6b;
    margin: 0;
    z-index: 0;
  }
  & .MuiFormHelperText-root.MuiFormHelperText-filled {
    bottom: 14px;
    left: 120px;
  }
`;
