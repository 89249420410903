import { Box, Typography } from '@mui/material';
import Tooltip from 'components/Tooltip';
import { FC, memo } from 'react';
import { getGroupTotalCountMessage } from './GroupCount.service';
import { GroupCountProps } from './GroupCount.types';

export const GroupCount: FC<GroupCountProps> = memo(({ description, childrenCount, groupCount }): JSX.Element => {
  return (
    <Tooltip
      title={
        <Box width="220px">
          <Box>Все укрупненные расценки ниже, будут сгруппированы в уровень</Box>
        </Box>
      }>
      <Typography variant="body2">{getGroupTotalCountMessage(description, childrenCount, groupCount)}</Typography>
    </Tooltip>
  );
});

GroupCount.displayName = 'GroupCount';
