/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { BtnWrapper, Close, HeadAct, WarningText, WrapperDialog } from "./ActDialog.style";
import { Box, Dialog } from "@mui/material";
import Button from "../../../../../../components/Button";
import { useCreateActMutation } from "api/calculations";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import {
  CreateActForm,
  inputs
} from "pages/Calculations/components/Accomplishment/components/ActDialog/ActDialog.types";
import { useCalcId } from "hooks/useCalcId";
import CreateActInput from "pages/Calculations/components/Accomplishment/components/ActDialog/CreateActInput";
import { toLocalString } from "utils/formatDate";
import { ActListContext } from "../../../CalculationСomplicated";
import { enqueueSnackbar } from "notistack";
import { isEmpty } from "../../../../../../utils/isEmpty";
import { TDisableDate, validateDates } from "./utils";

const ActDialog: React.FC<{ open: boolean; close: () => void; update: (flag: boolean) => void }> = ({
  open,
  update,
  close,
}) => {
  const { data, setCurrent } = useContext(ActListContext);
  const calcID = useCalcId();

  const [validateFields, setValidateFields] = useState({
    startDate: '',
    endDate: '',
    onDate: '',
  });

  const [create, { isLoading }] = useCreateActMutation();

  const { ...methods } = useForm<CreateActForm>({
    mode: 'all',
  });

  /**
   * Хранит массив дат которые нельзя будет выбрать в селекторе
   */
  const disabledDates: TDisableDate[] = useMemo(() => {
    if (!data) return [];
    // return [];
    return (data?.actList || []).reduce(
      (disabled, dateRange) => {
        const { startDate, endDate } = dateRange;
        disabled.push({ start: new Date(startDate||''), end: new Date(endDate||'') });
        return disabled;
      },
      [] as TDisableDate[],
    );
  }, [data]);

  const { handleSubmit, formState } = methods;
  const submit: SubmitHandler<CreateActForm> = useCallback(
    (data) => {
      const body = {
        startDate: toLocalString(data.startDate!),
        endDate: toLocalString(data.endDate!),
        onDate: toLocalString(data.onDate!),
      };

      let errorText = validateDates(data,disabledDates)

      if (isEmpty(errorText, ['start', 'end', 'on', 'split'], false)) {
        enqueueSnackbar(
          <Box gap={1} display={'flex'} flexDirection={'column'}>
            {Object.values(errorText)}
          </Box>,
          {
            variant: 'error',
            autoHideDuration: 3000,
          },
        );
        return;
      }
      create({ body, calcID }).then((resp) => {
        methods.reset({
          onDate: null,
          startDate: null,
          endDate: null,
        });
        setValidateFields({
          onDate: '',
          startDate: '',
          endDate: '',
        });
        update(true);
        if ('data' in resp && resp.data) {
          setCurrent?.(resp.data);
        }
        close();
      });
    },
    [calcID, disabledDates],
  );
  const validate = useMemo(() => {
    let flag = true;
    let validateFieldsKey: keyof typeof validateFields;
    console.log(validateFields);
    for (validateFieldsKey in validateFields) {
      console.log(validateFieldsKey);
      if (validateFieldsKey in validateFields && !/^\d{2}\.\d{2}\.\d{4}$/.test(validateFields[validateFieldsKey])) {
        flag = false;
        break;
      }
    }
    return !flag || !formState.isValid;
  }, [validateFields, formState.isValid]);

  useEffect(() => {
    methods.reset({
      onDate: null,
      startDate: null,
      endDate: null,
    });
    setValidateFields({
      onDate: '',
      startDate: '',
      endDate: '',
    });
  }, [open]);

  return (
    <Dialog open={open}>
      <WrapperDialog onSubmit={handleSubmit(submit)}>
        <FormProvider {...methods}>
          <HeadAct>
            Сформировать новый акт
            <Close onClick={close} />
          </HeadAct>

          <WarningText>
            Внимание!
            <br />
            Период не может совпадать с существующим периодом или затрагивать диапазон дат уже существующих периодов
          </WarningText>
          {inputs.map(({ id, ...props }) => (
            <CreateActInput
              disabledDates={disabledDates}
              setValidate={(key: keyof typeof validateFields, str: string) => {
                setValidateFields((prevState) => ({ ...prevState, [key]: str }));
              }}
              key={id}
              {...props}
            />
          ))}

          <BtnWrapper>
            <Button disabled={validate || isLoading} color={'success'} type={'submit'}>
              Сохранить
            </Button>
            <Button disabled={isLoading} color={'primary'} onClick={close}>
              Отменить
            </Button>
          </BtnWrapper>
        </FormProvider>
      </WrapperDialog>
    </Dialog>
  );
};

export default ActDialog;
