import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useLoginByTokenMutation } from '../../api/auth';
import { useMutationHandlers } from '../../hooks/useMutationHandlers';
import useQuery from '../../hooks/useQuery';
import { isAuthSelector, setCredentials } from '../../store/slices/auth';
import { useTypedSelector } from '../../store/store';
import { LINK_TO_CORE } from '../../utils/constants';
import AppLayout from '../AppLayout';

export const Layout: React.FC = () => {
  const dispatch = useDispatch();
  const tokenHandler = useQuery();
  const tokenQuery: string | null = tokenHandler.get('token');
  const [token, setToken] = useState<string | null>(tokenQuery);
  const [isLogout, setIsLogout] = useState<boolean>(false);

  useEffect(() => {
    if (token) {
      dispatch(setCredentials({}));
    }
  }, [token]);

  const isAuth = useTypedSelector(isAuthSelector);

  const [loginByToken, loginByTokenResponse] = useLoginByTokenMutation();

  //comment useEffect and copypaste token to local storage
  //DON'T PUSH THAT useEffect commented
  useEffect(() => {
    if (!isAuth && !isLogout) {
      token ? loginByToken({ token: token! }) : window.location.replace(`${LINK_TO_CORE}?source=offer`);
    }
  }, [isAuth, token, isLogout]);

  const onLogout = useCallback(() => {
    setIsLogout(true);
    dispatch(setCredentials({}));
    window.location.replace(LINK_TO_CORE);
  }, []);

  useMutationHandlers(loginByTokenResponse, () => {
    tokenHandler.remove('token');
    setToken(null);
  });

  return isAuth ? (
    <AppLayout onLogout={onLogout}>
      <Outlet />
    </AppLayout>
  ) : null;
};
