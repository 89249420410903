import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 20px 20px 15px;
  text-align: center;
`;

export const StyledSubtitle = styled(StyledTitle)`
  color: ${({ theme }) => theme.palette.text.dark};
  font-size: 15px;
`;

export const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`;

export const StyledTitleButtonWrapper = styled(Box)<{ positionTitle?: 'left' | 'right' }>`
  position: absolute;
  top: 50%;
  ${({ positionTitle }) => positionTitle || 'right'}: 10px;
  transform: translateY(-50%);
`;
