import { Divider } from 'components/Divider';
import React from 'react';
import { UserCardProps } from './UserCard.types';
import UserCardContent from './UserCardContent';
import { UserCardContentData } from './UserCardContent/UserCardContent.types';
import UserCardHeader from './UserCardHeader';
import { UserCardHeaderData } from './UserCardHeader/UserCardHeader.types';
import { StyledCard } from './styles';

const UserCard: React.FC<UserCardProps> = ({ data, onClick }) => {
  const { id, firstName, middleName, lastName, avatarPreview, avatar, company, email, phone, role, access } = data;
  const headerData: UserCardHeaderData = {
    firstName,
    middleName,
    lastName,
    avatarPreview,
    email,
    phone,
    role,
  };
  const contentData: UserCardContentData = { access, company };

  return (
    <StyledCard onClick={() => onClick(id)} textColor="dark">
      <UserCardHeader data={headerData} />
      <Divider rounded />
      <UserCardContent data={contentData} />
    </StyledCard>
  );
};

export default UserCard;
