import { Button, Stack, Typography } from '@mui/material';
import { GroupFolder } from 'assets/SVG/GroupFolder';
import { useCalcId } from 'hooks/useCalcId';
import { useCreateLevel } from 'pages/FullVor/Table/hooks';
import { ChangeEvent, FC, useMemo, useState } from 'react';
import { GroupCount } from '..';
import { useTreeNodeContext } from '../../../../context';
import { getTotalCount } from './CreateLevelForm.service';
import { StyledCreateFormWrapper, StyledInput } from './CreateLevelForm.styles';
import { CreateLevelFormProps } from './CreateLevelForm.types';
import { LevelSelect } from './components';

export const CreateLevelForm: FC<CreateLevelFormProps> = ({ childrenIds, groupIds }): JSX.Element => {
  const calcID = useCalcId();
  const { rowID, level, rowType, levelIds, onToggleFormVisible } = useTreeNodeContext();
  const [selectedLevel, setSelectedLevel] = useState(level);
  const [title, setTitle] = useState('');
  const { createLevel } = useCreateLevel();

  const isLevelSelectShowed = rowType === 'folded' && level !== 0 && childrenIds[0] !== rowID;

  const groupedTotalCount = useMemo(
    () => getTotalCount(rowID, level, childrenIds, groupIds),
    [rowID, level, childrenIds, groupIds],
  );

  const createLevelClickHandler = () => {
    if (!calcID || !rowID) return;

    createLevel({
      calcID,
      title,
      rowType,
      rowID,
      level: selectedLevel,
      childrenIds,
      groupIds,
      levelIds,
    });

    closeForm();
  };

  const selectLevelHandler = (level: number) => {
    setSelectedLevel(level);
  };

  const titleChangeHandler = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const closeForm = () => {
    onToggleFormVisible('create');
  };

  return (
    <StyledCreateFormWrapper>
      {isLevelSelectShowed ? (
        <LevelSelect selectedLevel={selectedLevel} onSetLevel={selectLevelHandler} />
      ) : (
        <GroupFolder color="primary" fontSize="large" />
      )}
      <Typography variant="subtitle2" pl={2}>
        Создание уровня
      </Typography>
      <StyledInput value={title} onChange={titleChangeHandler} placeholder="Введите название уровня" />
      {groupedTotalCount && (
        <GroupCount
          description="Будет сгруппировано"
          childrenCount={groupedTotalCount.childrenCount}
          groupCount={groupedTotalCount.groupCount}
        />
      )}
      <Stack ml={2} direction="row" spacing={2}>
        <Button color="success" size="small" disableElevation disabled={!title} onClick={createLevelClickHandler}>
          Сохранить
        </Button>
        <Button size="small" disableElevation onClick={closeForm}>
          Отменить
        </Button>
      </Stack>
    </StyledCreateFormWrapper>
  );
};
