import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import PersonIcon from '@mui/icons-material/Person';
import { Divider, List, ListItemIcon, ListItemText } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { NavBarItem } from './AdminNavBar.types';
import AdminNavBarItem from './AdminNavBarItem';
import { BottomBox, BottomItemButton, LogoWrapper, OfferLogo, StyledDrawer, TopBox } from './styles';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { ReactComponent as Integration } from 'assets/icons/integration.svg';

const items: NavBarItem[] = [
  {
    label: 'Пользователи',
    icon: PersonIcon,
    link: '/administration/users',
  },
  {
    label: 'Проекты',
    icon: DashboardOutlinedIcon,
    link: '/administration/projects',
  },
  {
    label: 'Справочники',
    icon: DescriptionOutlinedIcon,
    link: '/administration/references',
  },
  {
    label: 'Интеграции',
    icon: Integration,
    link: '/administration/integration',
  },
];

const AdminNavBar: React.FC = () => {
  const _navbarIsOpen = localStorage.getItem('navBarIsOpen') === 'true';
  const [open, setOpen] = useState(_navbarIsOpen);
  const [overrideOpen, setOverrideOpen] = useState(_navbarIsOpen);

  const mouseHandler = (value: boolean) => {
    if (overrideOpen) return;
    setOpen(value);
  };

  const onOverride = () => {
    setOverrideOpen((value) => {
      localStorage.setItem('navBarIsOpen', String(!value));
      return !value;
    });
    setOpen(true);
  };

  const style = {
    width: overrideOpen ? '236px' : '80px',
    '> .MuiPaper-root': {
      width: open ? '236px' : '80px',
    },
  };

  return (
    <StyledDrawer
      variant="permanent"
      className="navbar"
      onMouseMove={() => mouseHandler(true)}
      onMouseEnter={() => mouseHandler(true)}
      onMouseLeave={() => mouseHandler(false)}
      sx={style}>
      <TopBox>
        <Link to="/home">
          <LogoWrapper>
            <OfferLogo />
          </LogoWrapper>
        </Link>

        <List>
          {items.map((item) => (
            <AdminNavBarItem
              key={item.label}
              label={item.label}
              icon={item.icon}
              selectedIcon={item.selectedIcon}
              link={item.link}
            />
          ))}
        </List>
      </TopBox>

      <BottomBox>
        <List>
          <Divider className="Divider" />
          <BottomItemButton onClick={onOverride}>
            {overrideOpen ? (
              <>
                <ListItemIcon>
                  <KeyboardDoubleArrowLeftIcon style={{ fontSize: 35 }} />
                </ListItemIcon>
                <ListItemText primary="Открепить меню" />
              </>
            ) : (
              <>
                <ListItemIcon>
                  <KeyboardDoubleArrowRightIcon style={{ fontSize: 35 }} />
                </ListItemIcon>
                <ListItemText primary="Закрепить меню" />
              </>
            )}
          </BottomItemButton>
        </List>
      </BottomBox>
    </StyledDrawer>
  );
};

export default AdminNavBar;
