/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */

import { IEstimateReference, IKFOGroup } from '../../../../api/references/estimates/estimates.types';
import React, { useEffect, useLayoutEffect } from 'react';
import { UnitWeight } from '../../../../store/slices/references/prices/types';
import { AgGridReact } from 'ag-grid-react';
import { useAppDispatch } from '../../../../store/store';
import { FormPriceReferencePosition, positionPlaceholder } from './PricesReference.types';
import { initialDataSet, initialGroup, initialUnit } from '../../../../store/slices/references/prices/prices';

export function usePriceReferencePageEffect({
  hiddenRowsUUids,
  originData,
  isFetching,
  data,
  clickRefetch,
  applyFilters,
  setClickRefetch,
  isFetchingUpdate,
  unitWeightData,
  setFilteredData,
  Ref,
  groupData,
}: {
  hiddenRowsUUids: string[];
  originData?: IEstimateReference[];
  isFetching?: boolean;
  data?: IEstimateReference[];
  clickRefetch: boolean;
  applyFilters: () => void;
  setClickRefetch: (value: React.SetStateAction<boolean>) => void;
  isFetchingUpdate: boolean;
  unitWeightData?: UnitWeight[];
  setFilteredData: (value: React.SetStateAction<IEstimateReference[] | undefined>) => void;
  Ref: React.MutableRefObject<AgGridReact<IEstimateReference> | null>;
  groupData?: IKFOGroup[];
}) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // if (!isFetching) {
    setFilteredData(() => {
      if (originData) {
        // const indexes: number[] = [];
        const indexesUUIDS: string[] = [];
        const filledArr = originData.reduce((acc: IEstimateReference[], curr) => {
          const newItem: IEstimateReference = Object.assign({}, curr);
          let idx = false;
          if (newItem && newItem.parentID && newItem.id) {
            // idx = hiddenRowsIds.includes(newItem.parentID) || indexes.includes(newItem.id);
            idx = hiddenRowsUUids.includes(newItem.parentUUID!) || indexesUUIDS.includes(newItem.uuid!);
            if (idx && newItem.children.length) {
              // indexes.push(...newItem.children.map((item) => item.id));
              indexesUUIDS.push(...newItem.children.map((item) => item.uuid));
            }
          }
          return !idx ? [...acc, newItem] : acc;
        }, []);

        // gridRef?.api.hideOverlay();

        // setUpdateLoader(false);
        return filledArr?.length ? filledArr : originData;
      } else {
        // gridRef?.api.hideOverlay();
        // setUpdateLoader(false);
        return [];
      }
    });
    // }
  }, [hiddenRowsUUids, originData]);

  useEffect(() => {
    const update: FormPriceReferencePosition = {};
    originData
      ?.filter((_) => _.rowType === 'RATE_POSITION')
      ?.forEach((_) => {
        update[Math.abs(_.id)] = positionPlaceholder;
      });
    // methodsPositions.reset(update);
  }, [originData]);

  useEffect(() => {
    if (isFetchingUpdate !== undefined && Ref.current) {
      if (isFetchingUpdate) {
        Ref.current?.api?.showLoadingOverlay();
      } else {
        Ref.current?.api?.hideOverlay();
      }
    }
  }, [isFetchingUpdate]);

  useLayoutEffect(() => {
    if (unitWeightData) {
      dispatch(initialUnit(unitWeightData));
    }
  }, [unitWeightData]);
  useLayoutEffect(() => {
    if (groupData) {
      dispatch(initialGroup(groupData));
    }
  }, [groupData]);

  useLayoutEffect(() => {
    if (!isFetching && data) {
      dispatch(initialDataSet(data));
    }
  }, [data, isFetching]);

  useEffect(() => {
    if (clickRefetch) {
      applyFilters();
      setClickRefetch(false);
    }
  }, [clickRefetch, applyFilters]);
}
// useEffect(() => {
//   // if (!isFetching) {
//   setFilteredData(() => {
//     if (originData) {
//       const indexes: number[] = [];
//       const filledArr = originData.reduce((acc: IEstimateReference[], curr) => {
//         const newItem: IEstimateReference = Object.assign({}, curr);
//         let idx = false;
//         if (newItem && newItem.parentID && newItem.id) {
//           idx = hiddenRowsIds.includes(newItem.parentID) || indexes.includes(newItem.id);
//           if (idx && newItem.children.length) {
//             indexes.push(...newItem.children.map((item) => item.id));
//           }
//         }
//         return !idx ? [...acc, newItem] : acc;
//       }, []);
//
//       // gridRef?.api.hideOverlay();
//
//       // setUpdateLoader(false);
//       return filledArr?.length ? filledArr : originData;
//     } else {
//       // gridRef?.api.hideOverlay();
//       // setUpdateLoader(false);
//       return [];
//     }
//   });
//   // }
// }, [hiddenRowsIds, originData]);
//
// useEffect(() => {
//   const update: FormPriceReferencePosition = {};
//   originData
//     ?.filter((_) => _.rowType === 'RATE_POSITION')
//     ?.forEach((_) => {
//       update[Math.abs(_.id)] = positionPlaceholder;
//     });
//   // methodsPositions.reset(update);
// }, [originData]);
//
// useEffect(() => {
//   if (isFetchingUpdate !== undefined && Ref.current) {
//     if (isFetchingUpdate) {
//       Ref.current?.api?.showLoadingOverlay();
//     } else {
//       Ref.current?.api?.hideOverlay();
//     }
//   }
// }, [isFetchingUpdate]);
//
// useLayoutEffect(() => {
//   if (unitWeightData) {
//     dispatch(initialUnit(unitWeightData));
//   }
// }, [unitWeightData]);
// useLayoutEffect(() => {
//   if (groupData) {
//     dispatch(initialGroup(groupData));
//   }
// }, [groupData]);
//
// useLayoutEffect(() => {
//   if (!isFetching && data) {
//     dispatch(initialDataSet(data));
//   }
// }, [data, isFetching]);
//
// useEffect(() => {
//   if (clickRefetch) {
//     applyFilters();
//     setClickRefetch(false);
//   }
// }, [clickRefetch, applyFilters]);
