import React, { useMemo } from "react";
import { IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Stack } from "@mui/material"
import { StyledButtonListItem, StyledListItem } from "./style"
import DeleteIcon from '@mui/icons-material/Delete';
import { SvgFileXml } from '../../../assets/SVG/SVG';
import { ListNameType } from "..";
import AddIcon from '@mui/icons-material/Add';


interface ListFilesProps {
  listName: ListNameType[];
  onDeleteFile: (idx: number) => void;
  setIsListFiles?: (value: boolean) => void;
}

export const ListFiles: React.FC<ListFilesProps> = ({ listName, onDeleteFile, setIsListFiles }) => {


  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        height: 300,
        padding: 2,
        paddingRight: 1,
        backgroundColor: 'rgb(246 247 251)',
        '& ul': { padding: 0 },
      }}
    /* subheader={<li />} */
    >
      <>
        <StyledButtonListItem disablePadding        >
          <ListItemButton role={undefined} onClick={() => setIsListFiles?.(false)} dense>
            <Stack direction='row'>
              <ListItemIcon>
                <AddIcon></AddIcon>
              </ListItemIcon>
              <ListItemText primary={'Добавить еще файлы'} sx={{}} />
            </Stack>
          </ListItemButton>
        </StyledButtonListItem>

        {listName.map((value) => {
          return (
            <StyledListItem
              key={value.name}
              secondaryAction={
                <IconButton edge="end">
                  <DeleteIcon onClick={() => onDeleteFile(value.idx)} />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton role={undefined} dense>
                <ListItemIcon>
                  <SvgFileXml />
                </ListItemIcon>
                <ListItemText primary={value.name} sx={{}} />
              </ListItemButton>
            </StyledListItem>
          );
        })}
      </>
    </List>
  )
}