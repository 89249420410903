import {
  useDeleteActMutation,
  useGetCoefficientsV3ActQuery,
  useGetCoefficientsV3Query,
  useGetLimitedListQuery,
  useLazySyncCoefficientsActQuery,
  useUpdateActMutation,
  useUpdateCoefficientsActV3Mutation,
  useUpdateCoefficientsV3Mutation
} from "api/params";
import {
  ChaptersToTotals,
  Coefficients,
  EstimateList,
  GetCoefficientsResponse,
  GetCoefficientsV3ActResponse,
  GetCoefficientsV3Response,
  IndexesRequest,
  LabelsGroup,
  LimitedCostsV3,
  LimitedList,
  ToTotalsRequest
} from "api/params/params.types";
import Progress from "components/Progress";
import { isAfter, isBefore } from "date-fns";
import { useCalcId } from "hooks/useCalcId";
import { sortBy } from "lodash";
import { useSnackbar } from "notistack";
import { ActListContext } from "pages/Calculations/components/CalculationСomplicated";
import React, {
  createContext,
  FC,
  Fragment,
  JSX,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { FieldErrors, useFormContext, useWatch } from "react-hook-form";
import { formatDateToString, isSameDate, toLocalString } from "utils/formatDate";
import { ActList } from "../../../../../../../../types";
import { isEmpty } from "../../../../../../../../utils/isEmpty";
import { SideMenuNames } from "../../ParametersDialog.types";
import { CoefficientsResults } from "../CoefficientsResults";
import { CostIndexForm } from "../CostIndexForm";
import { LimitedCostsForm } from "../LimitedCostsForm";
import { MenuPaper } from "../MenuPaper";
import { NRSPCoefficientForm } from "../NRSPCoefficientForm";
import { StyledForm, StyledMenuItem, StyledMenuList } from "./ParameterForm.styles";
import { menuItemLabels, menuItemLabelsExecuted, menuItems, menuItemsExecuted } from "./ParametersForm.constants";
import { ParametersFormProps } from "./ParametersForm.types";
import ActsTab from "../ActsTab";
import { Box } from "@mui/material";

function shitFixShitCode(value: string | number | null) {
  return value?.toString()?.replace(',','.').replace('%','') || null
  // if (value == null || value === '') return rv;
  // if (typeof value === 'string' && converter === Number) {
  //   return converter(value.replace('%', '').replace(',', '.'));
  // }
  // if (typeof value === 'number' && converter === String) {
  //   return converter(value).replace(',', '.');
  // }
  // return converter(value);
}
function replaceStringFromServer(num:string|null):string {
  if(!num) return "";
  const replacedNum = num.replace(".", ",");
  let commaIndex = replacedNum.indexOf(",");
  if (commaIndex >= 0) {
    commaIndex = commaIndex + 1;
  }else{
    return num
  }
  const endNums = replacedNum.substring(commaIndex);
  const arrLetters = [];
  for (let i = 0; i < endNums.length ; i++) {
    arrLetters.push(endNums[i]);
  }
  const rightDigits = arrLetters.reverse().reduce((acc, letter)=>{
    if (letter === "0") {
      if (acc.length) {
        acc.push(letter);
      }
    }else{
      acc.push(letter)
    }
    return acc
  },[] as string[]).reverse().join('')
  if (rightDigits.length) {
    return replacedNum.slice(0,commaIndex ) + rightDigits
  }else{
    return replacedNum.slice(0,commaIndex - 1);
  }

}
interface LoaderInterface {
  loading: boolean | undefined;
  disabledGlobal: { [key: string]: boolean };
  disabledArray: { current: { [key: string]: { [key: string]: string[] } } };
  disabledArrayServer: { current: { [key: string]: { [key: string]: string[] } } };
  addStr?: (pI: number, i: number, data: string[], removed?: boolean) => void;
  addStrServer?: (pI: number, i: number, data: string[], removed?: boolean) => void;
  check?: (pI: number, i: number, data: string, isUpdate?: boolean) => boolean;
  // setValue?: (path: string, value: any) => void;
  // getValue?: (path: string) => any;
  state?: GetCoefficientsResponse;
  limitedList: LimitedList[];
  isExecution?: boolean;
  estimates: EstimateList[];
}

export const LoaderContext = createContext<LoaderInterface>({
  loading: undefined,
  disabledArray: { current: {} },
  disabledGlobal: {},
  disabledArrayServer: { current: {} },
  limitedList: [],
  estimates: [],
});

interface IErrorState {
  name: string;
  errors: JSX.Element[];
}

export const ParametersForm: FC<ParametersFormProps> = ({
  setLoaderParent,
  selectedMenu,
  isExecution = false,
  onSetSelectedMenu,
  closeWindow,
}): JSX.Element => {
  const { data: actList, current, getTable, setCurrent } = useContext(ActListContext);

  const { enqueueSnackbar } = useSnackbar();

  const calcID = useCalcId();

  /**
   * Следующие три ссылки используются для хранения данных
   * о выключенных пунктах выподающего списка
   * на табе "Индексы изменения сметной стоимости"
   */
  const disabledState = useRef<{ [key: string]: { [key: string]: string[] } }>({});
  const disabledStateServer = useRef<{ [key: string]: { [key: string]: string[] } }>({});
  const globalDisabled = useRef<{ [key: string]: boolean }>({});

  /**
   * Функция для обновления данных в ссылке выше disabledStateServer
   */
  const addArrToDisabledServer = useCallback(
    (pI: number, i: number, data: string[], removed = false) => {
      if (removed) {
        delete disabledStateServer.current[`parent${pI}`][i];
        const newData: typeof disabledStateServer.current = {};
        for (const parentKey in disabledStateServer.current) {
          let index = 0;
          for (const childKey in disabledStateServer.current[parentKey]) {
            newData[parentKey] = {
              ...newData[parentKey],
              [index]: disabledStateServer.current[parentKey][childKey],
            };
            index++;
          }
        }
        disabledStateServer.current = newData;
      } else {
        disabledStateServer.current = {
          ...disabledStateServer.current,
          [`parent${pI}`]: {
            ...disabledStateServer.current[`parent${pI}`],
            [i]: data,
          },
        };
      }
      setContextState((prevState) => ({
        ...prevState,
        disabledArrayServer: disabledStateServer,
      }));
    },
    [disabledStateServer.current],
  );

  /**
   * Функция для обновления данных в ссылке выше disabledState
   */
  const addArrToDisabled = useCallback(
    (pI: number, i: number, data: string[], removed = false) => {
      if (removed) {
        delete disabledState.current[`parent${pI}`][i];
        const newData: typeof disabledState.current = {};
        for (const parentKey in disabledState.current) {
          let index = 0;
          for (const childKey in disabledState.current[parentKey]) {
            newData[parentKey] = {
              ...newData[parentKey],
              [index]: disabledState.current[parentKey][childKey],
            };
            index++;
          }
        }
        disabledState.current = newData;
      } else {
        disabledState.current = {
          ...disabledState.current,
          [`parent${pI}`]: {
            ...disabledState.current[`parent${pI}`],
            [i]: data,
          },
        };
      }
      setContextState((prevState) => ({
        ...prevState,
        disabledArray: disabledState,
      }));
    },
    [disabledState.current],
  );

  const checkExistString = useCallback(
    (pI: number, index: number, str: string, isUpdate = false) => {
      const arr: string[] = [];
      for (const arrKey in disabledState.current) {
        if (Number(arrKey.replace(/([^1-9]+)/, '')) === pI) {
          for (const indexChildren in disabledState.current[arrKey]) {
            if (isUpdate) {
              arr.push(...(disabledState.current[arrKey][indexChildren] || []));
            } else if (Number(indexChildren) !== index) {
              arr.push(...(disabledState.current[arrKey][indexChildren] || []));
            }
          }
        }
      }
      for (const arrKey in disabledStateServer.current) {
        if (Number(arrKey.replace(/([^1-9]+)/, '')) === pI) {
          for (const indexChildren in disabledStateServer.current[arrKey]) {
            if (!isUpdate) {
              arr.push(...(disabledStateServer.current[arrKey][indexChildren] || []));
            } else if (Number(indexChildren) !== index) {
              arr.push(...(disabledStateServer.current[arrKey][indexChildren] || []));
            }
          }
        }
      }

      return arr.includes(str);
    },
    [disabledState.current, disabledStateServer.current],
  );

  const [contextState, setContextState] = useState<LoaderInterface>({
    loading: undefined,
    disabledArray: { current: {} },
    disabledGlobal: {},
    disabledArrayServer: { current: {} },
    addStr: addArrToDisabled,
    addStrServer: addArrToDisabledServer,
    check: checkExistString,
    limitedList: [],
    estimates: [],
  });

  const { formState, control, setValue, handleSubmit, getValues, reset } =
    useFormContext<GetCoefficientsV3ActResponse>();

  const acts = useWatch({
    name: 'acts',
    control,
  });

  /**
   * Все что здесь происходит это перевод в Data даты актов с сервера
   */
  const disabledDates: { start: Date; end: Date; id: number }[] = useMemo(() => {
    if (!acts) return [];
    return acts.reduce((acc: { start: Date; end: Date; id: number }[], item) => {
      acc.push(
        ...item.fields.flatMap((dates) => ({
          start: new Date(dates.startDate||''),
          end: new Date(dates.endDate||''),
          id: dates.id,
        })),
      );
      // acc.push(
      //   ...item.groups.flatMap((_) =>
      //     _.fields.flatMap((dates) => ({
      //       start: new Date(dates.startDate),
      //       end: new Date(dates.endDate),
      //       id: dates.id,
      //     })),
      //   ),
      // );
      return acc;
    }, []);
  }, [acts]);

  /**
   * Функция проверки валидности входящих дат
   */
  const checkDates = useCallback(
    (data: ActList) => {
      /* Получаем актуальный список дат, все кроме входящей даты */
      const filteredDisabledDates = disabledDates.filter((d) => d.id !== data.id);

      /**
       * Объект для формирования ошибок
       * start - попадает сюда, если есть ошибка в поле "Дата начала"
       * end - попадает сюда, если есть ошибка в поле "Дата окончания"
       * on - попадает сюда, если есть ошибка в поле "Сформирован на дату"
       * split - попадает сюда, если даты перепутаны местами, например, дата начала идет после даты конца
       */
      const errorText: {
        start: null | ReactElement;
        end: null | ReactElement;
        on: null | ReactElement;
        split: null | ReactElement;
      } = { start: null, end: null, on: null, split: null };

      if ((data.startDate && isNaN((data.startDate as Date).getTime())) || data.startDate === null) {
        errorText.start = <span>Дата начала некорректна</span>;
      }
      if ((data.endDate && isNaN((data.endDate as Date).getTime())) || data.endDate === null) {
        errorText.end = <span>Дата окончания некорректна</span>;
      }
      if ((data.onDate && isNaN((data.onDate as Date).getTime())) || data.onDate === null) {
        errorText.on = <span>Дата формирования некорректна</span>;
      }
      if (
        data.startDate &&
        !isNaN((data.startDate as Date).getTime()) &&
        data.endDate &&
        !isNaN((data.endDate as Date).getTime()) &&
        data.onDate &&
        !isNaN((data.onDate as Date).getTime())
      ) {
        for (const { start, end } of filteredDisabledDates) {
          if (
            (!errorText.start &&
              (isSameDate((data.startDate as Date), start) || isAfter((data.startDate as Date), start)) &&
              (isSameDate((data.startDate as Date), end) || isBefore((data.startDate as Date), end))) ||
            (isBefore((data.startDate as Date), start) && isAfter((data.endDate as Date), end))
          ) {
            errorText.start = <span>Дата начала пересекает существующие акты</span>;
          }
          if (
            !errorText.end &&
            (isSameDate((data.endDate as Date), start) || isAfter((data.endDate as Date), start)) &&
            (isSameDate((data.endDate as Date), end) || isBefore((data.endDate as Date), end))
          ) {
            errorText.end = <span>Дата окончания пересекает существующие акты</span>;
          }
          if (!errorText.on && isSameDate((data.startDate as Date), (data.endDate as Date))) {
            errorText.on = <span>Даты не могут быть установлены одним днем</span>;
          }
          if (
            !errorText.split &&
            (isBefore((data.endDate as Date), (data.startDate as Date)) ||
              isAfter((data.startDate as Date), (data.endDate as Date)))
          ) {
            errorText.split = <span>Дата начала не может идти после даты окончания</span>;
          }
        }
      }

      if (isEmpty(errorText, ['start', 'end', 'on', 'split'], false)) {
        return errorText;
      } else {
        return {} as typeof errorText;
      }
    },
    [disabledDates],
  );

  const {
    data: coefficientsData,
    isFetching: coeffIsLoad,
    refetch: refetchCoeff,
  } = useGetCoefficientsV3Query({ calcID }, { skip: isExecution });

  const [updateCoefficients, { isLoading: loadUpdate }] = useUpdateCoefficientsV3Mutation();

  const { data: limitedList } = useGetLimitedListQuery(calcID, { skip: !calcID || !isExecution });

  const {
    data: coefficientsActData,
    isFetching: actIsLoad,
    refetch: refetchAct,
  } = useGetCoefficientsV3ActQuery({ calcID, actID: current?.id as number }, { skip: !current?.id || !isExecution || !current?.id });

  const [updateCoefficientsAct] = useUpdateCoefficientsActV3Mutation();
  const [updateAct] = useUpdateActMutation();

  const [deleteAct] = useDeleteActMutation();
  const [syncAct] = useLazySyncCoefficientsActQuery();

  const update = isExecution ? (current ? updateCoefficientsAct : null) : updateCoefficients;

  const isLoading = isExecution ? actIsLoad : coeffIsLoad;

  const data: GetCoefficientsV3Response | GetCoefficientsV3ActResponse | undefined = useMemo(() => {
    return isExecution && coefficientsActData && 'synchronized' in coefficientsActData
      ? coefficientsActData
      : coefficientsData;
  }, [current, isExecution, coefficientsData, coefficientsActData]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setContextState((prevState) => ({ ...prevState, check: checkExistString }));
  }, [checkExistString]);

  useEffect(() => {
    setContextState((prevState) => ({
      ...prevState,
      disabledArray: disabledState,
      disabledArrayServer: disabledStateServer,
    }));
  }, [disabledState.current, disabledStateServer.current]);

  useEffect(() => {
    setContextState((prevState) => ({
      ...prevState,
      loading,
    }));
  }, [loading]);

  useEffect(() => {
    setContextState((prevState) => ({
      ...prevState,
      disabledGlobal: globalDisabled.current,
    }));
  }, [globalDisabled.current]);

  useEffect(() => {
    if (limitedList?.dynamicRows) {
      setContextState((prevState) => ({
        ...prevState,
        limitedList: limitedList?.dynamicRows,
      }));
    }
  }, [limitedList]);

  useEffect(() => {
    setContextState((prevState) => ({
      ...prevState,
      isExecution,
    }));
  }, [isExecution]);

  useEffect(() => {
    if (!data || actList === undefined || isLoading) return;

    const { limitedCosts: rawLimitedCosts, estimateList, nrsp, indexes, toTotals } = data;

    setContextState((prevState) => ({ ...prevState, estimates: estimateList }));

    disabledState.current = {};

    disabledStateServer.current = {};

    /**
     * При получении данных с сервера,
     * массив nrsp дополняется пустым значением для создания разделов
     * ниже по аналогии происходит и с остальными данными
     * кроме этого числовые значения переводятся в строку если они не null
     *
     * в конце происходит reset формы с свежими данными
     */
    const updateNRSP: typeof nrsp = nrsp.map((chapter) => {
      return {
        ...chapter,
        nr: replaceStringFromServer(chapter.nrAsString),
        sp: replaceStringFromServer(chapter.spAsString),
        chapters: [
          ...chapter.chapters.map((v) => ({
            ...v,
            nr: replaceStringFromServer(v.nrAsString),
            sp: replaceStringFromServer(v.spAsString),
          })),
        ],
      };
    });

    const updateIndexes = indexes.map((chapter, idx) => {
      globalDisabled.current[`parent${idx}`] = !!chapter.indexes.length || !!chapter.chapters.length;

      return {
        ...chapter,
        indexes: [
          ...chapter.indexes.map((v) => {
            return {
              ...v,
              smr: replaceStringFromServer(v.smrAsString),
              zm: replaceStringFromServer(v.zmAsString),
              mt: replaceStringFromServer(v.mtAsString),
              em: replaceStringFromServer(v.emAsString),
              oz: replaceStringFromServer(v.ozAsString),
            };
          }),
        ],
        chapters: [
          ...chapter.chapters.map((chap, cI) => {
            const chapterTitle = Array.isArray(chap.chapterTitle) ? chap.chapterTitle : [chap.chapterTitle];
            disabledStateServer.current = {
              ...disabledStateServer.current,
              [`parent${idx}`]: {
                ...disabledStateServer.current[`parent${idx}`],
                [cI]: chapterTitle,
              },
            };
            return {
              ...chap,
              chapterTitle,
              indexes: chap.indexes.map((v) => ({
                ...v,
                smr: replaceStringFromServer(v.smrAsString),
                zm: replaceStringFromServer(v.zmAsString),
                mt: replaceStringFromServer(v.mtAsString),
                em: replaceStringFromServer(v.emAsString),
                oz: replaceStringFromServer(v.ozAsString),
              })),
            };
          }),
        ],
      };
    });

    const updateToTotals = toTotals.map((chapter) => {
      const newChapters = sortBy(chapter.chapters, ['chapterTitle']);
      return {
        ...chapter,
        coefficients: [
          ...chapter.coefficients.map((v) => {
            return {
              ...v,
              isEdit: false,
              em: replaceStringFromServer(v.emAsString),
              zm: replaceStringFromServer(v.zmAsString),
              mt: replaceStringFromServer(v.mtAsString),
              oz: replaceStringFromServer(v.ozAsString),
              pz: replaceStringFromServer(v.pzAsString),
            };
          }),
        ],
        chapters: [
          ...newChapters.map((c) => {
            return {
              ...c,
              chapterTitle: Array.isArray(c.chapterTitle) ? c.chapterTitle : [],
              coefficients: c.coefficients.map((v) => {
                return {
                  ...v,
                  isEdit: false,
                  em: replaceStringFromServer(v.emAsString),
                  zm: replaceStringFromServer(v.zmAsString),
                  mt: replaceStringFromServer(v.mtAsString),
                  oz: replaceStringFromServer(v.ozAsString),
                  pz: replaceStringFromServer(v.pzAsString),
                };
              }),
            };
          }),
        ],
      };
    });

    const initIndexes: {[key:string]:{
        fileID: number,
        chapterTitle: (string|never)[],
        recordTitle: string,
        smr: string,
        zm: string,
        mt: string,
        em: string,
        oz: string,
      }[]} = {};
    const initToTotals: {[key:string]:{
        fileID: number,
        isPercent: boolean,
        recordTitle: string,
        chapterTitle: string[],
        isEdit: boolean,
        em: string,
        zm: string,
        mt: string,
        oz: string,
        pz: string,
      }[]} = {};

    updateIndexes.forEach((_, index) => {
      initIndexes['parent' + index] = [
        {
          fileID: _.fileID,
          chapterTitle: [],
          recordTitle: '',
          smr: '',
          zm: '',
          mt: '',
          em: '',
          oz: '',
        },
      ];
    });
    updateToTotals.forEach((_, index) => {
      initToTotals['parent' + index] = [
        {
          fileID: _.fileID,
          isPercent: false,
          recordTitle: '',
          chapterTitle: [],
          isEdit: false,
          em: '',
          zm: '',
          mt: '',
          oz: '',
          pz: '',
        },
      ];
    });

    const removedActs = getValues('removed.acts') || [];
    const removedActsIdx = removedActs.map((act) => act.id);
    const acts = actList?.actList
      ?.filter((act) => !removedActsIdx.includes(act.id))
      ?.reduce((acc, prev) => {
        // const title = prev.estimateName || 'Общие';
        const label = 'Акты за ' + formatDateToString(new Date(prev.onDate||''), 'yyyy');

        const idxGroup = acc.findIndex((_) => _.label === label);
        //
        // prev.forEach((_) => {

        const update = {
          ...prev,
          onDate: new Date(prev.onDate||''),
          startDate: new Date(prev.startDate||''),
          endDate: new Date(prev.endDate||''),
        };
        if (idxGroup >= 0) {
          acc[idxGroup].fields = [...acc[idxGroup].fields, update];
        } else {
          acc.push({ label, fields: [update] });
        }
        // });
        return acc;
      }, [] as LabelsGroup[]);
    // const acts = actList?.estimateActs
    //   ?.map((g) => ({ ...g, acts: g.acts.filter((act) => !removedActsIdx.includes(act.id)) }))
    //   ?.filter((g) => g.acts.length)
    //   ?.reduce((acc, prev) => {
    //     const title = prev.estimateName || 'Общие';
    //     acc.push({
    //       title,
    //       groups: [],
    //     });
    //     const idxGroup = acc.findIndex((_) => _.title === title);
    //
    //     prev.acts.forEach((_) => {
    //       const label = 'Акты за ' + formatDateToString(new Date(_.onDate), 'yyyy');
    //       let idxLabel = acc?.[idxGroup]?.groups?.findIndex((value) => value.label === label);
    //
    //       const update = {
    //         ..._,
    //         onDate: new Date(_.onDate),
    //         startDate: new Date(_.startDate),
    //         endDate: new Date(_.endDate),
    //       };
    //       if (idxLabel >= 0) {
    //         acc[idxGroup].groups[idxLabel].fields = [...acc[idxGroup].groups[idxLabel].fields, update];
    //       } else {
    //         acc[idxGroup].groups.push({ label, fields: [update] });
    //       }
    //     });
    //     return acc;
    //   }, [] as IActForm[]);

    const limitedCosts = {
      8: [] as LimitedCostsV3[],
      9: [] as LimitedCostsV3[],
      10: [] as LimitedCostsV3[],
      11: [] as LimitedCostsV3[],
      12: [] as LimitedCostsV3[],
      13: [] as LimitedCostsV3[],
      14: [] as LimitedCostsV3[],
      15: [] as LimitedCostsV3[],
      ...rawLimitedCosts,
    };
    Object.keys(rawLimitedCosts).forEach((key : number | string)=>{
      if (key in limitedCosts) {
        const itemRawLimit = rawLimitedCosts[key]
        limitedCosts[key as keyof typeof limitedCosts] = itemRawLimit.map(_=>{
          return { ..._,limitedCost:{
            ..._.limitedCost,
              value:replaceStringFromServer(_.limitedCost?.valueAsPlainString??null),
            }, }
        })
      }
    });
    console.log(limitedCosts);
    console.log(updateIndexes);
    if (current || !isExecution)
      (async () => {
        console.log(updateNRSP)
        reset({
          indexes: updateIndexes,
          nrsp: updateNRSP,
          toTotals: updateToTotals,
          limitedCosts: limitedCosts,
          acts,
          synchronized: 'synchronized' in data ? (data.synchronized as boolean) || false : false,
          removed: {
            indexes: [],
            nrsp: [],
            toTotals: [],
            limitedCosts: [],
            acts: current ? removedActs : [],
          },
          created: {
            indexes: initIndexes,
            toTotals: initToTotals,
          },
        });
      })().then(() => {
        setLoading(false);
        setLoaderParent(false);
      });
  }, [data, actList, current, isLoading, isExecution]);

  const setSelectedMenuItem = (value: SideMenuNames) => {
    onSetSelectedMenu(value);
  };

  const submitHandler = useCallback(
    (values: GetCoefficientsV3ActResponse) => {
      setLoaderParent(true);
      const nrspChapters = values.nrsp.flatMap((item) => {
        const returnValue = {
          chapterTitle: null,
          fileID: item.fileID,
          nr: item.nr?.toString().replace(',','.')?.replace('%','') || null,
          sp: item.sp?.toString().replace(',','.')?.replace('%','') || null,
          // nr: shitFixShitCode(item.nr) as number | null,
          // sp: shitFixShitCode(item.sp) as number | null,
          ...(item.recordID && { recordID: item.recordID }),
        };
        const chapters = item?.chapters
          .filter((_) => _.chapterTitle)
          .flatMap((chapter) => {
            const { recordID, sp, nr, ...rest } = chapter;
            return {
              ...rest,
              nr: item.nr?.toString().replace(',','.')?.replace('%','') || null,
              sp: item.sp?.toString().replace(',','.')?.replace('%','') || null,
              // nr: shitFixShitCode(nr) as number | null,
              // sp: shitFixShitCode(sp) as number | null,
              fileID: chapter.fileID || item.fileID,
              ...(recordID && { recordID }),
            };
          });
        return [returnValue, ...chapters];
      });

      const finalErrors: IErrorState[] = [];
      const erToTotals: IErrorState = {
        name: 'Коэффициенты к итогам сметы',
        errors: [],
      };
      const erIndexes: IErrorState = {
        name: 'Индексы изменения сметной стоимости',
        errors: [],
      };
      const erLimited: IErrorState = {
        name: 'Лимитированные затраты',
        errors: [],
      };
      const erCreatedIndexes: IErrorState = {
        name: 'Создание индекса сметной стоимости',
        errors: [],
      };
      const erCreatedToTotals: IErrorState = {
        name: 'Создание коэффициента к итогам сметы',
        errors: [],
      };

      const errorsLimitedCoast: Record<number, string> = {
        13: 'Непредвиденные затраты',
        14: 'Дополнительные работы и затраты',
        15: 'Налоги и обязательные платежи',
      };

      const formatedLimitedValues = Object.keys(values.limitedCosts).flatMap((list) => {
        return values.limitedCosts[list as keyof typeof values.limitedCosts].flatMap((l) => {
          const fileIDs = l.estimates.map((_) => _.estimateID);
          const { fileID, ...rest } = l.limitedCost;
          if (!rest.title || rest.value === null || !l.estimates.length) {
            erLimited.errors.push(
              <p>Не заполнены значения в главе: {(rest.chapter&&rest.chapter in errorsLimitedCoast&&errorsLimitedCoast[rest.chapter]) ?? rest.chapter}</p>,
            );
          }
          return {
            ...rest,
            value:shitFixShitCode(rest.value) || '',
            valueAsString:shitFixShitCode(rest.value) || '',
            valueAsPlainString:shitFixShitCode(rest.value) || '',
            fileIDs,
          };
        });
        // });
      });
      const formatedLimitedValuesRemove:any[] = values.removed.limitedCosts.flatMap((limit) => {
        return {...limit,value:shitFixShitCode(limit.value)}
      });

      if (erLimited.errors.length) {
        finalErrors.push(erLimited);
      }
      /**
       * formatedToTotals === formatedIndexes
       *
       * идет формирование отправляемых данных из
       *
       * // formatedIndexes
       * @example
       * [{
       *   fileTitle:number,
       *   fileID: number,
       *   chapters:[
       *     {
       *       chapterTitle:string[]|null
       *       indexes: [{
       *          recordID: number,
       *          recordTitle: string,
       *          smr: string,
       *          zm: string,
       *          mt: string,
       *          em: string,
       *          oz: string
       *       }]
       *     }
       *   ],
       *   indexes: [{
       *          recordID: number,
       *          recordTitle: string,
       *          smr: string,
       *          zm: string,
       *          mt: string,
       *          em: string,
       *          oz: string
       *       }]
       * ]
       *
       * в
       *
       * @example
       * [
       *   {
       *     recordTitle:string,
       *     chapterTitle?: string[] | null,
       *     em: number|null,
       *     mt: number|null,
       *     oz: number|null,
       *     smr: number|null,
       *     zm: number|null,
       *     fileID: fileID,
       *     recordID: number | null
       *   }
       * ]
       *
       * при этом в массивве chapters на нулевом индексе присутствует изначально пустой объект
       * для создания индекса в разделе или смете в зависимости от переданного или нет chapterTitle'a
       */
      console.log('to totals send', values.toTotals);
      const formatedToTotals = values.toTotals
        .map((item) => {
          const { fileID } = item;
          const mappedItems = item.coefficients
            // .filter((_) => _.isEdit)
            .map((c) => {
              const { em, mt, oz, isPercent, pz, zm, recordTitle, recordID } = c;
              const newCoefficient = {
                recordTitle,
                isPercent,
                ...(!isEmpty(c, ['em', 'mt', 'oz', 'pz', 'zm', 'recordTitle'], false) && { delete: true }),
                em: shitFixShitCode(em),
                mt: shitFixShitCode(mt),
                oz: shitFixShitCode(oz),
                pz: shitFixShitCode(pz),
                zm: shitFixShitCode(zm),
                fileID: fileID,
                ...(recordID && { recordID }),
              };

              return newCoefficient;
            });
          const copyItems = item.chapters.filter((_) => _.isEdit);

          /**
           * здесь идет проверка на создаваемый раздел или смету
           *
           * при необходимости расширить условие
           */
          const mappedChapters = copyItems.reduce((acc, subC, row) => {
            if (subC.chapterTitle && !subC.chapterTitle.length) {
              erToTotals.errors.push(
                <p>
                  Смета: {item.fileTitle}
                  <br />
                  Строка: {row + 1}
                </p>,
              );
            }
            const mappedSubItem = subC.coefficients
              .filter((_) => _.isEdit)
              .map((subCC) => {
                const { em, mt, oz, isPercent, pz, zm, recordTitle, recordID } = subCC;
                const newCoefficient: Partial<Coefficients> & Pick<ChaptersToTotals,'fileID'|'chapterTitle'> = {
                  fileID: fileID,
                  recordTitle,
                  isPercent,
                  chapterTitle: subC.chapterTitle?.[0] === 'null' ? null : subC.chapterTitle || null,
                  em: shitFixShitCode(em),
                  mt: shitFixShitCode(mt),
                  oz: shitFixShitCode(oz),
                  pz: shitFixShitCode(pz),
                  zm: shitFixShitCode(zm),
                  recordID: recordID??undefined,
                };

                return newCoefficient;
              });
            acc.push(...mappedSubItem.flat());
            return acc;
          }, [] as Array<Partial<Coefficients> & Pick<ChaptersToTotals,'fileID'|'chapterTitle'>>);
          return [...mappedItems.flat(), ...mappedChapters.flat()];
        })
        .flat();

      if (erToTotals.errors.length) {
        finalErrors.push(erToTotals);
      }
      const formatedIndexes = values.indexes
        .map((item) => {
          const { fileID } = item;
          const mappedItems = item.indexes.length
            ? item.indexes.map((c) => {
                const { smr, zm, mt, em, oz, recordTitle, recordID } = c;
                const newCoefficient = {
                  recordTitle,
                  ...(!isEmpty(c, ['smr', 'zm', 'mt', 'em', 'oz', 'recordTitle'], false) && { delete: true }),
                  chapterTitle: c.chapterTitle?.[0] === 'null' ? null : c.chapterTitle || null,
                  smr: shitFixShitCode(smr),
                  zm: shitFixShitCode(zm),
                  mt: shitFixShitCode(mt),
                  em: shitFixShitCode(em),
                  oz: shitFixShitCode(oz),
                  fileID: fileID,
                  ...(recordID && { recordID }),
                };

                return newCoefficient;
              })
            : [];

          const copyItems = item.chapters;

          const mappedChapters = copyItems.reduce((acc, subC, row) => {
            if (subC.chapterTitle && !subC.chapterTitle.length) {
              erIndexes.errors.push(
                <p>
                  Смета: {item.fileTitle}
                  <br />
                  Строка: {row + 1}
                </p>,
              );
            }
            const mappedSubItem = subC.indexes.map((subCC) => {
              const { smr, zm, mt, em, oz, recordTitle, recordID } = subCC;
              return {
                recordTitle,
                chapterTitle: subC.chapterTitle?.[0] === 'null' ? null : subC.chapterTitle || null,
                smr: shitFixShitCode(smr),
                zm: shitFixShitCode(zm),
                mt: shitFixShitCode(mt),
                em: shitFixShitCode(em),
                oz: shitFixShitCode(oz),
                fileID: fileID,
                ...(recordID && { recordID }),
              };
            });
            acc.push(...mappedSubItem.flat());
            return acc;
          }, [] as Partial<IndexesRequest>[]);
          return [...mappedItems.flat(), ...mappedChapters.flat()];
        })
        .flat();

      if (erIndexes.errors.length) {
        finalErrors.push(erIndexes);
      }

      values.removed.nrsp = values.removed.nrsp.map((_) => {
        return {
          ..._,
          nr: shitFixShitCode(_.nr),
          sp: shitFixShitCode(_.sp),
        };
      });

      const createdArrIndexes: Array<Partial<IndexesRequest>> = [];
      const createdArrToTotals: Array<Partial<Coefficients> & Pick<ChaptersToTotals,'fileID'|'chapterTitle'>> = [];

      for (const key in values.created.indexes) {
        const item = values.created.indexes[key];
        item.pop();
        item
          .filter((chapter) => chapter.chapterTitle === null || Array.isArray(chapter.chapterTitle))
          .forEach((chapter, idx) => {
            if (chapter.chapterTitle !== '') {
              if (
                chapter.chapterTitle !== null &&
                !chapter.chapterTitle?.length &&
                item.length > 1 &&
                idx !== item.length - 1 &&
                !erCreatedIndexes.errors.length
              ) {
                erCreatedIndexes.errors.push(<p>Не выбраны разделы</p>);
              }
              createdArrIndexes.push({
                fileID: chapter.fileID??undefined,
                chapterTitle: chapter.chapterTitle?.[0] === 'null' ? null : chapter.chapterTitle,
                recordTitle: chapter.recordTitle,
                smr: shitFixShitCode(chapter.smr),
                zm: shitFixShitCode(chapter.zm),
                mt: shitFixShitCode(chapter.mt),
                em: shitFixShitCode(chapter.em),
                oz: shitFixShitCode(chapter.oz),
              });
            }
          });
      }
      for (const key in values.created.toTotals) {
        const item = values.created.toTotals[key];
        item.pop();
        item
          .filter(
            (chapter) => ('fileID' in chapter && chapter.chapterTitle === null) || Array.isArray(chapter.chapterTitle),
          )
          .forEach((chapter, idx) => {
            if ('chapterTitle' in chapter && 'fileID' in chapter) {
              if (
                chapter.chapterTitle !== null &&
                !chapter.chapterTitle?.length &&
                item.length > 1 &&
                idx !== item.length - 1 &&
                !erCreatedToTotals.errors.length
              ) {
                erCreatedToTotals.errors.push(<p>Не выбраны разделы</p>);
              }
              createdArrToTotals.push({
                fileID: chapter.fileID as number,
                chapterTitle: chapter.chapterTitle?.[0] === 'null' ? null : chapter.chapterTitle,
                recordTitle: chapter.recordTitle,
                isPercent: chapter.isPercent,
                em: shitFixShitCode(chapter.em),
                mt: shitFixShitCode(chapter.mt),
                oz: shitFixShitCode(chapter.oz),
                pz: shitFixShitCode(chapter.pz),
                zm: shitFixShitCode(chapter.zm),
              });
            }
          });
      }

      if (erCreatedIndexes.errors.length) {
        finalErrors.push(erCreatedIndexes);
      }
      if (erCreatedToTotals.errors.length) {
        finalErrors.push(erCreatedToTotals);
      }

      if (finalErrors.length) {
        const renderError = (
          <div>
            {finalErrors.map((item, idx) => {
              return (
                <Fragment key={idx}>
                  {item.name}
                  {item.errors}
                </Fragment>
              );
            })}
          </div>
        );
        enqueueSnackbar(renderError, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        setLoaderParent(false);

        return;
      }

      const isUpdatedActForm = !!current && values.removed.acts.map((_) => _.id).includes(current.id);

      const errors: {[key:string]: { er:ReturnType<typeof checkDates> }} = {};
      values.acts.forEach((labels) => {
        labels.fields.forEach((act, idx) => {
          const er = checkDates(act);

          const key = `За ${new Date(act.onDate || '')?.getFullYear()} год, строка ${idx + 1}`;
          if (er?.end||er?.on||er?.split||er?.start) {
            if (errors[key]) {
              errors[key] = { ...errors[key], er };
            } else {
              errors[key] = { er };
            }
          }
        });
      });

      // values.acts.forEach((group, idxLabels) => {
      //   group.groups.forEach((labels) => {
      //     labels.fields.forEach((act, idx) => {
      //       const er = checkDates(act);
      //
      //       const key = `В ${
      //         labels.label === 'Общие' ? 'Общей' : `${idxLabels + 1}-${idxLabels + 1 === 3 ? 'ей' : 'ой'} смете`
      //       }, за ${new Date(act.onDate)?.getFullYear()} год, строка ${idx + 1}`;
      //       if (er) {
      //         if (errors[key]) {
      //           errors[key] = { ...errors[key], ...er };
      //         } else {
      //           errors[key] = { er };
      //         }
      //       }
      //     });
      //   });
      // });

      const keysErrors = Object.keys(errors);
      console.log(keysErrors);
      if (isExecution && keysErrors.length) {
        enqueueSnackbar(
          <Box display={'flex'} flexDirection={'column'} gap={0.05}>
            Ошибка в Актах по периодам:
            {keysErrors.map((label) => {
              const err = errors[label].er;
              return (
                <Box key={label} display={'flex'} flexDirection={'column'}>
                  <p>{label}</p>
                  <Box display={'flex'} gap={0.2} flexDirection={'column'}>
                    {err?.start}
                    {err?.end}
                    {err?.on}
                    {err?.split}
                  </Box>
                </Box>
              );
            })}
          </Box>,
          {
            variant: 'error',
            autoHideDuration: 3000,
          },
        );
        setLoaderParent(false);

        return;
      }
      console.log(calcID);
      Promise.allSettled([
        isExecution && values.removed.acts.flatMap((act) => deleteAct({ calcID, actID: act.id })),
        isExecution &&
          updateAct({
            calcID,
            values: (values.acts || []).flatMap((label) => {
              // _.groups.flatMap(() => {
              return label.fields.flatMap((act) => {
                const { id, startDate, endDate } = act;
                if (current?.id === id) {
                  setCurrent?.({
                    id: current.id,
                    endDate: toLocalString(endDate as Date),
                    startDate: toLocalString(startDate as Date),
                    onDate: current.onDate,
                  });
                }
                return {
                  id,
                  endDate: toLocalString(endDate as Date),
                  startDate: toLocalString(startDate as Date),
                };
              });
              // }),
            }),
          }),
        !isUpdatedActForm &&
          !values.synchronized &&
          update?.({
            calcID,
            actID: current?.id as number,
            values: {
              nrsp: [...nrspChapters, ...values.removed.nrsp],
              toTotals: [
                ...formatedToTotals as ToTotalsRequest[],
                ...values.removed.toTotals.map((v:any) => ({
                  ...v,
                  em: shitFixShitCode(v.em),
                  mt: shitFixShitCode(v.mt),
                  oz: shitFixShitCode(v.oz),
                  pz: shitFixShitCode(v.pz),
                  zm: shitFixShitCode(v.zm),
                })) as ToTotalsRequest[],
                ...createdArrToTotals as ToTotalsRequest[],
              ],
              indexes: [
                ...formatedIndexes as Partial<IndexesRequest>[],
                ...values.removed.indexes.map((v:any) => ({
                  ...v,
                  smr: shitFixShitCode(v.smr),
                  zm: shitFixShitCode(v.zm),
                  mt: shitFixShitCode(v.mt),
                  em: shitFixShitCode(v.em),
                  oz: shitFixShitCode(v.oz),
                })) as  Partial<IndexesRequest>[],
                ...createdArrIndexes,
              ],
              // limitedCosts: [],
              limitedCosts: [...formatedLimitedValues, ...formatedLimitedValuesRemove],
            },
          }),
        isExecution && values.synchronized && current?.id && syncAct({ calcID, actID: current.id }),
      ])
        .then(() => {
          enqueueSnackbar('Параметры расчета сметы успешно сохранены', {
            variant: 'success',
            autoHideDuration: 3000,
          });
          setValue('removed.acts', []);
          const activeActs = values.acts;

          const lengthActiveAtcs = activeActs.flatMap((labels) => labels.fields.flatMap((act) => act)).length;

          if (!lengthActiveAtcs) {
            closeWindow();
          }
          isExecution ? refetchAct() : refetchCoeff();
        })
        .catch((e) => {
          if ('originalStatus' in e && e.originalStatus === 500) {
            enqueueSnackbar('Один или несколько коэффициентов введены некорректно!', {
              variant: 'error',
              autoHideDuration: 3000,
            });
          }
        })
        .finally(() => {
          isExecution && getTable?.(calcID);
          setLoading(true);
          setLoaderParent(true);
        });
    },
    [formState],
  );

  const submitErrorHandler = (errors: FieldErrors<GetCoefficientsResponse>) => {
    if (selectedMenu !== 'act') {
      if (errors[selectedMenu]) {
        enqueueSnackbar('Проверьте остальные вкладки на ошибки', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  };

  const resetFormHandler = () => {
    reset({
      ...getValues(),
      limitedCosts: formState.defaultValues?.['limitedCosts'],
    });
  };

  const prevent = (e: React.KeyboardEvent<HTMLFormElement>) => {
    e.key === 'Enter' && e.preventDefault();
  };

  const menu = isExecution ? menuItemsExecuted : menuItems;
  const labels = isExecution ? menuItemLabelsExecuted : menuItemLabels;

  return (
    <StyledForm
      id="parameters-form"
      noValidate
      onKeyDown={prevent}
      onSubmit={handleSubmit(submitHandler, submitErrorHandler)}>
      <StyledMenuList>
        {menu
          .filter((menu) => (!isExecution ? menu.value !== 'act' : true))
          .map((menuItem) => (
            <StyledMenuItem
              key={menuItem.value}
              disabled={isExecution && !current}
              selected={selectedMenu === menuItem.value}
              onClick={() => setSelectedMenuItem(menuItem.value)}>
              {menuItem.label}
            </StyledMenuItem>
          ))}
      </StyledMenuList>
      <LoaderContext.Provider value={contextState}>
        <MenuPaper title={labels[selectedMenu]} onResetFrom={resetFormHandler}>
          {isLoading || loadUpdate || loading ? (
            <Progress />
          ) : (
            {
              ...(isExecution && { act: <ActsTab /> }),
              nrsp: <NRSPCoefficientForm />,
              toTotals: <CoefficientsResults />,
              indexes: <CostIndexForm />,
              limitedCosts: <LimitedCostsForm />,
            }[selectedMenu]
          )}
        </MenuPaper>
      </LoaderContext.Provider>
    </StyledForm>
  );
};
// ...(isExecution && { act: <ActsTab /> }),
