import { Delete } from "@mui/icons-material";
import { Box, Collapse, FormControl, FormHelperText, IconButton, Typography } from "@mui/material";
import { Coefficients, GetCoefficientsResponse } from "api/params/params.types";
import { BaseTextField } from "components/BaseTextField";
import { ToggleButton } from "components/ToggleButton";
import { ToggleButtonGroup } from "components/ToggleButtonGroup";
import Tooltip from "components/Tooltip";
import React, { Fragment, memo, useCallback, useContext, useMemo, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { EstimateRangeSelect } from "../../../EstimateRangeSelect";
import { StyledCircle } from "../../../NRSPCoefficientForm/components/Table/components/Estimete/Estimate.styles";
import { LoaderContext } from "../../../ParametersForm";
import { IndexesFields, ToTotalsFields } from "../../TabComponent.consts";
import { LengthArr, StyledClearableTextField, StyledIconArrow, StyledItem } from "../../TabComponent.styles";
import { CreatingItem } from "../CreatingItem";
import { ItemProps } from "./Item.types";

const Item: React.FC<ItemProps> = memo(({ itemType, parentIndex, arrow = true, index, isCoeff }) => {
  const itemChild = useMemo(() => {
    return itemType === 'indexes' ? 'indexes' : 'coefficients';
  }, [itemType]);

  const { control, getValues, setValue } = useFormContext<GetCoefficientsResponse>();

  const fields = useWatch({
    name: itemType,
  });

  const { disabledGlobal, addStrServer } = useContext(LoaderContext);

  const [open, setOpen] = useState<boolean>(false);

  const namespace = useMemo(() => {
    return !arrow && index !== undefined
      ? `${itemType}.${parentIndex}.chapters.${index}`
      : `${itemType}.${parentIndex}`;
  }, [arrow, itemType, index, parentIndex]);

  const tempToTotal = useMemo(() => {
    if (index !== undefined) {
      return fields[parentIndex].chapters[index];
    }
    return fields[parentIndex];
  }, [fields, parentIndex, index]);

  const [updateIndexInputs, setUpdateIndexInputs] = useState(false);

  const isDisabledIndexInput = useCallback(
    (obj: any, controller: string) => {
      if (!obj || !Object.keys(obj).length) return false;
      if (controller === 'smr') {
        return !!obj['oz'] || !!obj['em'] || !!obj['zm'] || !!obj['mt'];
      } else if (['oz', 'em', 'zm', 'mt'].includes(controller)) {
        return !!obj['smr'];
      } else return false;
    },
    [updateIndexInputs],
  );

  /**
   * удаление данных для отправки
   */
  const handleRemove = useCallback(
    (updateItem: Coefficients) => {
      let copyFields: typeof fields = JSON.parse(JSON.stringify(fields));
      /**
       * функция фильтрации по recordID
       */
      const filter = (i: any, mode: 'indexes' | 'chapters') => {
        if (mode === 'chapters') {
          if ('indexes' in i) {
            return i.indexes[0].recordID !== updateItem.recordID;
          } else {
            return i.coefficients[0].recordID !== updateItem.recordID;
          }
        } else {
          return i.recordID !== updateItem.recordID;
        }
      };
      /* Смета */
      let currentParent = copyFields[parentIndex];

      /**
       * Условия для фильтрации
       * если в объекте есть свойство indexes фильтруется по нему
       * если нет то по coefficients
       */
      currentParent.chapters = currentParent.chapters.filter((_: any) => filter(_, 'chapters'));

      if ('indexes' in currentParent) {
        currentParent.indexes = currentParent.indexes.filter((_: any) => filter(_, 'indexes'));

        if (!currentParent.chapters.length && !currentParent.indexes.length) {
          disabledGlobal[`parent${parentIndex}`] = false;
        }
        if (index !== undefined && addStrServer) {
          addStrServer(parentIndex, index, [], true);
        }
      } else {
        currentParent.coefficients = currentParent.coefficients.filter((_: any) => filter(_, 'indexes'));
      }

      /* Получение полного списка к удалению */
      const removedArr: any[] = getValues(`removed.${itemType}`);

      /* пушится новый объект и перезаписывается значение */
      removedArr.push({
        ...updateItem,
        delete: true,
      });
      setValue(`removed.${itemType}`, removedArr, { shouldDirty: true });

      /* Обновляется родитель, а затем обновляются значения */
      setValue(`${itemType}.${parentIndex}` as any, currentParent, { shouldDirty: true });
    },
    [fields, itemType, itemChild, parentIndex, index, tempToTotal],
  );

  const [isRedact, setIsRedact] = useState<null | number>(null);

  console.log(fields);

  return (
    <div>
      {tempToTotal && !!tempToTotal[itemChild].length
        ? tempToTotal[itemChild].map((chapter: any, innerIndex: number) => {
            const title =
              'chapters' in tempToTotal && !isCoeff
                ? tempToTotal.fileTitle
                : tempToTotal.chapterTitle?.[0] || undefined;
            let count = 0;
            if (Array.isArray(tempToTotal.chapterTitle) && tempToTotal.chapterTitle.length > 1) {
              count = tempToTotal.chapterTitle.length - 1;
            }
            return (
              <StyledItem key={innerIndex}>
                <Fragment>
                  {innerIndex === 0 ? (
                    <>
                      {!isCoeff && !arrow ? <StyledCircle /> : null}
                      {isRedact !== chapter.recordID ? (
                        <Tooltip
                          title={
                            'chapters' in tempToTotal && !isCoeff ? tempToTotal.fileTitle : tempToTotal.chapterTitle
                          }>
                          <>
                            <Typography
                              onClick={() => {
                                setOpen((prevState) => !prevState);
                                if ('chapters' in tempToTotal && !isCoeff) return;
                                setIsRedact(chapter.recordID);
                              }}
                              overflow="hidden"
                              textOverflow="ellipsis"
                              whiteSpace="nowrap"
                              position={'relative'}
                              sx={{ paddingLeft: '60px', cursor: 'pointer' }}>
                              {'chapters' in tempToTotal && arrow && (
                                <StyledIconArrow sx={{ rotate: `${open ? '90deg' : 0}` }} />
                              )}
                              {title === null || title === 'null'
                                ? 'Глобальный'
                                : title === undefined
                                ? 'Не выбраны разделы'
                                : title}
                            </Typography>
                            {!!count && <LengthArr>{count} +</LengthArr>}
                          </>
                        </Tooltip>
                      ) : (
                        <>
                          <Controller
                            name={
                              itemType === 'indexes'
                                ? `${namespace}.chapterTitle`
                                : (`${namespace}.chapterTitle` as any)
                            }
                            control={control}
                            defaultValue={tempToTotal.chapterTitle}
                            render={({ field: { ref, ...props } }) => (
                              <EstimateRangeSelect
                                {...props}
                                isUpdate={true}
                                globalDisabled={disabledGlobal[`parent${parentIndex!}`]}
                                closeCallback={() => setIsRedact(null)}
                                parentIndex={parentIndex}
                                index={index}
                                currentValue={tempToTotal.chapterTitle}
                                parentID={getValues(`${itemType}.${parentIndex}.fileID` as any)}
                                sx={{ paddingRight: '20px' }}
                              />
                            )}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <span></span>
                  )}

                  {itemType === 'indexes'
                    ? IndexesFields.map((controller) =>
                        controller === 'recordTitle' ? (
                          <Controller
                            key={controller}
                            name={`${namespace}.indexes.${innerIndex}.${controller}` as any}
                            control={control}
                            render={({ field: { onChange, ref, ...props } }) => (
                              <BaseTextField
                                {...props}
                                inputRef={ref}
                                onChange={onChange}
                                sx={{ paddingRight: '20px' }}
                              />
                            )}
                          />
                        ) : controller === 'coeff' ? (
                          <Typography textAlign="center">Коэф.</Typography>
                        ) : (
                          <Controller
                            name={`${namespace}.indexes.${innerIndex}.${controller}` as any}
                            control={control}
                            render={({ field: { onChange, ref, value, ...props }, fieldState }) => {
                              return (
                                <NumericFormat
                                  {...props}
                                  inputRef={ref}
                                  customInput={StyledClearableTextField}
                                  isAllowed={(values) => {
                                    const { formattedValue } = values;
                                    return /^(\d{0,6})(?:,\d{0,15})?$/.test(formattedValue);
                                    // return floatValue < MAX_LIMIT;
                                  }}
                                  placeholder="К"
                                  //@ts-ignore
                                  disabled={isDisabledIndexInput(
                                    index !== undefined
                                      ? fields[parentIndex]['chapters'][index]['indexes'][innerIndex]
                                      : (fields[parentIndex] as any)['indexes'][innerIndex],
                                    controller,
                                  )}
                                  onChange={(event) => {
                                    setUpdateIndexInputs((prevState) => !prevState);
                                    onChange(event);
                                  }}
                                  value={value}
                                  decimalSeparator=","
                                  decimalScale={15}
                                  error={!!fieldState.error}
                                  onClear={() => {
                                    setUpdateIndexInputs((prevState) => !prevState);
                                    onChange('');
                                  }}
                                />
                              );
                            }}
                          />
                        ),
                      )
                    : ToTotalsFields.map((controller) =>
                        controller === 'isPercent' ? (
                          <Controller
                            key={controller}
                            name={`${namespace}.coefficients.${innerIndex}.isPercent`}
                            render={({ field: { onChange, ref, value, ...props }, fieldState }) => (
                              <FormControl error={!!fieldState.error}>
                                <ToggleButtonGroup
                                  {...props}
                                  exclusive
                                  fullWidth
                                  onChange={(_, value) => {
                                    value !== null && onChange(value === '%');
                                  }}>
                                  {['К', '%'].map((groupButton) => {
                                    const selected = groupButton === 'К' ? !value : value;
                                    return (
                                      <ToggleButton key={groupButton} value={groupButton} selected={selected}>
                                        {groupButton}
                                      </ToggleButton>
                                    );
                                  })}
                                </ToggleButtonGroup>
                                {!!fieldState.error && <FormHelperText>{fieldState.error.message}</FormHelperText>}
                              </FormControl>
                            )}
                          />
                        ) : controller === 'recordTitle' ? (
                          <Controller
                            name={`${namespace}.coefficients.${innerIndex}.${controller}` as any}
                            control={control}
                            render={({ field: { onChange, ref, ...props } }) => (
                              <BaseTextField
                                {...props}
                                inputRef={ref}
                                onChange={onChange}
                                sx={{ paddingRight: '20px' }}
                              />
                            )}
                          />
                        ) : (
                          <Controller
                            name={`${namespace}.coefficients.${innerIndex}.${controller}` as any}
                            control={control}
                            render={({ field: { ref, ...props }, fieldState }) => {
                              const isPercent = useWatch({
                                control,
                                name: `${namespace}.coefficients.${innerIndex}.isPercent` as any,
                              });

                              return (
                                <NumericFormat
                                  {...props}
                                  inputRef={ref}
                                  customInput={StyledClearableTextField}
                                  isAllowed={(values) => {
                                    const { formattedValue } = values;
                                    return /^(\d{0,6})(?:,\d{0,15})?%?$/.test(formattedValue);
                                    // return floatValue < MAX_LIMIT;
                                  }}
                                  placeholder="К"
                                  suffix={isPercent ? '%' : ''}
                                  decimalSeparator=","
                                  decimalScale={15}
                                  error={!!fieldState.error}
                                  onClear={() => props.onChange('')}
                                />
                              );
                            }}
                          />
                        ),
                      )}

                  {
                    <IconButton
                      onClick={() => {
                        setUpdateIndexInputs((prevState) => !prevState);
                        handleRemove(chapter);
                      }}
                      color="warning"
                      sx={{ marginLeft: '20px' }}>
                      <Delete />
                    </IconButton>
                  }
                </Fragment>
              </StyledItem>
            );
          })
        : arrow && (
            <StyledItem>
              <Tooltip title={'chapters' in tempToTotal && !isCoeff ? tempToTotal.fileTitle : tempToTotal.chapterTitle}>
                <Typography
                  onClick={() => setOpen((prevState) => !prevState)}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  sx={{ paddingLeft: '60px' }}>
                  {'chapters' in tempToTotal && arrow && <StyledIconArrow sx={{ rotate: `${open ? '90deg' : 0}` }} />}
                  {'chapters' in tempToTotal && !isCoeff ? tempToTotal.fileTitle : tempToTotal.chapterTitle}
                </Typography>
              </Tooltip>
            </StyledItem>
          )}

      {arrow && 'chapters' in tempToTotal && (
        <Collapse in={open}>
          {tempToTotal.chapters.map((chapter: any, index: any) => (
            <Box key={index} sx={{ position: 'relative' }}>
              <Item itemType={itemType} arrow={false} index={index} parentIndex={parentIndex} />
            </Box>
          ))}
          <CombineCreating itemType={itemType} parentID={parentIndex} id={tempToTotal} />
        </Collapse>
      )}
    </div>
  );
});

Item.displayName = 'Item';

const CombineCreating: React.FC<any> = ({ itemType, parentID, id }) => {
  const { setValue, control } = useFormContext<GetCoefficientsResponse>();

  const fields = useWatch({
    name: `created.${itemType}.parent${parentID}` as 'created.toTotals.parent0' | 'created.indexes.parent0',
    control,
  });
  const { disabledGlobal, disabledArray, addStr } = useContext(LoaderContext);

  const appendIndexes = {
    fileID: id.fileID,
    chapterTitle: [],
    recordTitle: '',

    smr: '',
    zm: '',
    mt: '',
    em: '',
    oz: '',
  };
  const appendToTotals = {
    fileID: id.fileID,
    chapterTitle: [],
    recordTitle: '',

    isPercent: false,
    em: '',
    zm: '',
    mt: '',
    oz: '',
    pz: '',
  };

  const create = useCallback(() => {
    const newData = JSON.parse(JSON.stringify(fields));
    newData.push(itemType === 'indexes' ? appendIndexes : appendToTotals);

    setValue(
      `created.${itemType}.parent${parentID}` as 'created.toTotals.parent0' | 'created.indexes.parent0',
      newData,
      { shouldDirty: true },
    );
  }, [itemType, parentID, fields]);

  const deleteItem = useCallback(
    (idx: number) => {
      const newData = JSON.parse(JSON.stringify(fields));
      const item = newData.splice(idx, 1);

      const deletedItem = item[0].chapterTitle;
      if (itemType === 'indexes') {
        if (deletedItem.includes('null')) {
          disabledGlobal[`parent${parentID}`] = false;
        }

        newData.forEach((item: any, indexItem: number) => {
          disabledArray.current[`parent${parentID}`][indexItem] = item.chapterTitle;
        });
        for (const key in disabledArray.current[`parent${parentID!}`]) {
          if (disabledArray.current[`parent${parentID!}`][key].length) {
            disabledGlobal[`parent${parentID}`] = true;
            break;
          }
          disabledGlobal[`parent${parentID}`] = false;
        }
        addStr?.(parentID, idx, [], true);
      }
      setValue(
        `created.${itemType}.parent${parentID}` as 'created.toTotals.parent0' | 'created.indexes.parent0',
        newData,
        {
          shouldDirty: true,
        },
      );
      // replace(newData);
    },
    [fields, disabledGlobal, disabledArray],
  );

  return (
    <>
      {fields?.map((cr: any, index: number) => {
        const last = fields?.length - 1 === index ? fields[fields.length - 1] : undefined;
        return (
          <StyledItem key={parentID + index * 10}>
            <CreatingItem
              deleteItem={() => deleteItem(index)}
              data={cr}
              create={create}
              parentIndex={parentID}
              index={index}
              itemType={`created.${itemType}` as any}
              last={last as any}
            />
          </StyledItem>
        );
      })}
    </>
  );
};

export default Item;
