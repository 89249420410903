import { useSnackbar } from 'notistack';
import { FocusEventHandler, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useCreateComplexRowMutation,
  useDeleteComplexRowMutation,
  useEditComplexRowMutation,
} from '../../../../../../api/calculations';
import { BasePricesItem } from '../../../../../../api/calculations/types';
import { useForm } from '../../../../../../hooks/useForm';
import { RowEditFormData } from './RowEditForm.types';
import { validationRow } from './validation';
import { useMutationHandlers } from '../../../../../../hooks/useMutationHandlers';
import useConfirmDialogСonfidence, {
  UseExitConfirmPropsConfidence,
} from '../../../../../../hooks/useConfirmDialogСonfidence';

export type RowPropsType = {
  row: BasePricesItem;
  calculationId: number;
  handleShowFormAddRow: (isShowFormRowAdd: boolean) => void;
};

export const useComplexRow = ({ row, calculationId, handleShowFormAddRow }: RowPropsType) => {
  const {
    id: rowId,
    title,
    auxiliaryMaterials,
    basicMaterials,
    machineOperatorSalary,
    materials,
    equipment,
    mimExploitation,
    overheads,
    estimatedProfit,
    mainSalary,
    total,
    type,
    parent,
  } = row;

  const [isAddSave, setIsAddSave] = useState('');
  /**************************************** Edit Row ********************************************/

  const [editComplexRow, editComplexRowResponse] = useEditComplexRowMutation();

  const [isInput, setIsInput] = useState(title === '');
  // total = Основная з/п, Эксплуатация МиМ, Материалы, Оборудование, Накладные расходы, Сметная прибыль
  const initialValues: RowEditFormData = useMemo(
    () => ({
      title,
      auxiliaryMaterials: auxiliaryMaterials, //вспомогательные материалы
      basicMaterials: basicMaterials, // основные материалы
      equipment: equipment, //оборудование
      estimatedProfit: estimatedProfit, // сметная прибыль
      machineOperatorSalary: machineOperatorSalary, // зарплата механизаторов
      mainSalary: mainSalary, //основная зарплата
      materials: materials, // материалы
      mimExploitation: mimExploitation, //Эксплуатация МиМ
      overheads: overheads, //накладные расходы
      total,
    }),
    [row], //calculation
  );

  const { formik, isSubmitDisabled } = useForm({
    validationSchema: validationRow,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values: RowEditFormData) => {
      onSubmit(values);
    },
  });

  const { values, handleReset, setFieldValue, setFieldError, dirty, handleSubmit } = formik;

  /************************************ Calculate Row  ************************************/
  const outMaterials =
    parseFloat(values.basicMaterials.toString().replace(',', '.').replace(' ', '')) +
    parseFloat(values.auxiliaryMaterials.toString().replace(',', '.').replace(' ', ''));
  const outTotal =
    parseFloat(values.equipment.toString().replace(',', '.').replace(' ', '')) + //оборудование
    parseFloat(values.estimatedProfit.toString().replace(',', '.').replace(' ', '')) + // сметная прибыль
    parseFloat(values.mainSalary.toString().replace(',', '.').replace(' ', '')) + //основная зарплата
    // parseFloat(values.materials.toString().replace(',', '.').replace(' ', '')) + // материалы
    // outMaterials + // материалы
    parseFloat(values.mimExploitation.toString().replace(',', '.').replace(' ', '')) + //Эксплуатация МиМ
    parseFloat(values.overheads.toString().replace(',', '.').replace(' ', '')); //накладные расходы

  const onSubmit = useCallback(
    (values: RowEditFormData) => {
      editComplexRow({
        calculationId,
        rowId,
        title: values.title,
        mainSalary: parseFloat(values.mainSalary.toString().replace(',', '.').replace(' ', '')),
        mimExploitation: parseFloat(values.mimExploitation.toString().replace(',', '.').replace(' ', '')),
        machineOperatorSalary: parseFloat(values.machineOperatorSalary.toString().replace(',', '.').replace(' ', '')),
        materials: outMaterials,
        basicMaterials: parseFloat(values.basicMaterials.toString().replace(',', '.').replace(' ', '')),
        auxiliaryMaterials: parseFloat(values.auxiliaryMaterials.toString().replace(',', '.').replace(' ', '')),
        equipment: parseFloat(values.equipment.toString().replace(',', '.').replace(' ', '')),
        overheads: parseFloat(values.overheads.toString().replace(',', '.').replace(' ', '')),
        estimatedProfit: parseFloat(values.estimatedProfit.toString().replace(',', '.').replace(' ', '')),
        total: outTotal,
      });
    },
    [outTotal], //calculation
  );

  /************************************ Row Menu ************************************/
  const anchorEl = useRef<null | HTMLElement>(null);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('user');

  const handleClose = () => {
    anchorEl.current = null;
    setMenuRowOpened(false);
  };

  const [isMenuRowOpened, setMenuRowOpened] = useState(false);

  const handleOpenMenuRowClick = (event: React.MouseEvent<HTMLElement>) => {
    anchorEl.current = event.currentTarget;
    if (event.currentTarget) setMenuRowOpened(true);
  };
  const handleSaveRow = () => {
    handleShowFormAddRow(false);
    handleSubmit();
    anchorEl.current = null;
    setMenuRowOpened(false);
  };

  const handleSaveAddRow = () => {
    setIsAddSave('SaveAdd');
    handleSubmit();
    anchorEl.current = null;
    setMenuRowOpened(false);
  };

  useMutationHandlers(editComplexRowResponse, () => {
    setIsInput(false);
    setMenuRowOpened(false);
    if (isAddSave === 'SaveAdd') {
      handleShowFormAddRow(true);
      setIsAddSave('');
    }
  });

  const handleClearForm = () => {
    setIsInput(true);
    formik.resetForm({
      values: {
        title: '',
        mainSalary: 0,
        mimExploitation: 0,
        machineOperatorSalary: 0,
        materials: 0,
        basicMaterials: 0,
        auxiliaryMaterials: 0,
        equipment: 0,
        overheads: 0,
        estimatedProfit: 0,
        total: Number(outTotal),
      },
    });
    anchorEl.current = null;
    setMenuRowOpened(false);
  };

  /**************************** Copy Row **************************/
  const [addRow, addRowResponse] = useCreateComplexRowMutation();

  const handleCopyRow = () => {
    addRow({
      id: calculationId,
      parent, // id parent
      title,
      mainSalary,
      mimExploitation,
      machineOperatorSalary,
      materials,
      basicMaterials,
      auxiliaryMaterials,
      equipment,
      overheads,
      estimatedProfit,
      total: outTotal,
      type: 'row',
    });
  };

  useMutationHandlers(addRowResponse, () => {
    anchorEl.current = null;
    setMenuRowOpened(false);
  });

  // ******* Delete Row *******
  const [deleteComplexRow, deleteComplexRowResponse] = useDeleteComplexRowMutation();

  const handleDeleteRow = useCallback((actionName: string) => {
    openConfirm(actionName);
  }, []);

  useMutationHandlers(deleteComplexRowResponse, () => {
    enqueueSnackbar(t('success.rowDelete'), {
      variant: 'success',
    });
  });

  // ******* Dialog Confirm *******

  const handleConfirmDelete = useCallback((confirm: boolean) => {
    if (confirm) {
      deleteComplexRow({ calculationId, rowId });
    }
  }, []);

  const handleConfirmCopy = useCallback((confirm: boolean) => {
    if (confirm) {
    }
  }, []);

  const dialogTitle = 'Вы действительно хотите удалить статью?';

  const dataForConfirmDialog: UseExitConfirmPropsConfidence = {
    handleConfirmDelete,
    handleConfirmCopy,
    dialogTitle,
  };
  const { ConfirmDialogConfidence, openConfirmConfidence: openConfirm } = useConfirmDialogСonfidence(
    dataForConfirmDialog,
    dialogTitle,
  );

  /****************************** Menu Button Level****************************************/

  const clickAddRow = useCallback((level: string) => {
    switch (level) {
      case 'row':
        handleShowFormAddRow(true);
        break;
      default:
        break;
    }
  }, []);

  /******************************** auto Focus *************************************/

  const titleRef = useRef<HTMLDivElement>(null);
  const mainSalaryRef = useRef<HTMLDivElement>(null);
  const mimExploitationRef = useRef<HTMLDivElement>(null);
  const machineOperatorSalaryRef = useRef<HTMLDivElement>(null);
  const materialsRef = useRef<HTMLDivElement>(null);
  const basicMaterialsRef = useRef<HTMLDivElement>(null);
  const auxiliaryMaterialsRef = useRef<HTMLDivElement>(null);
  const equipmentRef = useRef<HTMLDivElement>(null);
  const overheadsRef = useRef<HTMLDivElement>(null);
  const estimatedProfitRef = useRef<HTMLDivElement>(null);

  const arrRefs = [
    titleRef,
    mainSalaryRef,
    mimExploitationRef,
    machineOperatorSalaryRef,
    materialsRef,
    basicMaterialsRef,
    auxiliaryMaterialsRef,
    equipmentRef,
    overheadsRef,
    estimatedProfitRef,
  ];
  const handleSetIsInput = (nameInput: string) => {
    setIsInput(true);
    setTimeout(() => {
      arrRefs.forEach((elem) => {
        if (elem.current) {
          if (elem.current.querySelector('input')?.name === nameInput) elem.current.querySelector('input')?.focus();
        }
      });
    }, 1);
  };

  /************************************** onKeyDown Enter **********************************/

  const enterPressHandler = (e: { key: string }) => {
    if (e.key === 'Enter') {
      setIsInput(false);
      handleSubmit();
    }
  };

  /**************************************** Focus Out *************************************/

  const formRef = useRef<HTMLFormElement>(null);

  const handleFocusOut: FocusEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      // @ts-ignore
      if (event.relatedTarget && formRef.current?.contains(event.relatedTarget)) return;
      if (!isMenuRowOpened) {
        return handleSubmit();
      }
    },
    [isMenuRowOpened],
  );

  return {
    calculationId,
    rowId,
    formRef,
    titleRef,
    mainSalaryRef,
    mimExploitationRef,
    machineOperatorSalaryRef,
    materialsRef,
    basicMaterialsRef,
    auxiliaryMaterialsRef,
    equipmentRef,
    overheadsRef,
    estimatedProfitRef,
    formik,
    isInput,
    title,
    outTotal,
    outMaterials,
    total: total + outMaterials, // sheet fix
    mainSalary,
    mimExploitation,
    machineOperatorSalary,
    materials,
    basicMaterials,
    auxiliaryMaterials,
    equipment,
    overheads,
    estimatedProfit,
    handleSetIsInput,
    handleFocusOut,
    handleOpenMenuRowClick,
    handleSaveRow,
    handleCopyRow,
    handleClearForm,
    handleDeleteRow,
    handleShowFormAddRow,
    handleSaveAddRow,
    enterPressHandler,
    clickAddRow,
    ConfirmDialogConfidence,
    anchorEl,
    isMenuRowOpened,
    handleClose,
  };
};
