import React, { FC, useCallback, useState } from 'react';
import { ManagementLegendProps } from './ManagementLegend.types';
import { Box, Button, Stack, Tab, Tabs, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TabData } from 'components/Tabs/Tabs.types';
import { ProjectFormData, ProjectManagementTabs } from '../../ProjectManagement.types';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDeleteProjectMutation } from 'api/projects';
import { useMutationHandlers } from 'hooks/useMutationHandlers';
import useConfirmDialog, { UseExitConfirmProps } from 'hooks/useConfirmDialog';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';

export const ManagementLegend: FC<ManagementLegendProps> = ({ tabValue, onTabChange }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { dirty, isSubmitting } = useFormikContext<ProjectFormData>();
  const { projectId: projectIdString } = useParams();
  const projectID = Number(projectIdString);
  const [confirmDialogTrigger, setConfirmDialogTrigger] = useState<'delete' | 'exit'>('exit');

  const tabsData: TabData<ProjectManagementTabs>[] = [
    { value: 'info', label: 'О проекте' },
    { value: 'additional', label: 'Дополнительно' },
  ];

  const onChange = (e: React.SyntheticEvent, tabValue: ProjectManagementTabs) => {
    onTabChange(tabValue);
  };

  const onReturnClick = useCallback(() => {
    setConfirmDialogTrigger('exit');

    dirty ? openConfirm() : navigate(-1);
    // : navigate('/administration/projects')
  }, [dirty]);

  const [deleteProject, deleteProjectResponse] = useDeleteProjectMutation();

  const onDeleteClick = useCallback(() => {
    setConfirmDialogTrigger('delete');
    openConfirm();
  }, []);

  const handleExitConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      navigate(-1);
    }
  }, []);

  const handleDeleteConfirm = useCallback(
    (confirm: boolean) => {
      if (confirm && projectID) {
        deleteProject({ projectID });
      }
    },
    [projectID],
  );

  const dataForConfirmDialog: Record<NonNullable<typeof confirmDialogTrigger>, UseExitConfirmProps> = {
    delete: {
      handleConfirm: handleDeleteConfirm,
      title: 'Удалить проект?',
      body: 'Проект будет удален из модуля Estimation.',
    },
    exit: {
      handleConfirm: handleExitConfirm,
    },
  };

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog[confirmDialogTrigger]);

  useMutationHandlers(deleteProjectResponse, () => {
    navigate('/administration/projects');
    enqueueSnackbar('Проект успешно удален.', {
      variant: 'success',
    });
  });

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ py: 1.5, pr: 2.5 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Tabs value={tabValue} onChange={onChange}>
          {tabsData.map((tabData) => (
            <Tab key={tabData.value} {...tabData} />
          ))}
        </Tabs>
      </Stack>

      <Stack direction="row" alignItems="center" spacing={1.25}>
        {!!projectID && (
          <Button
            onClick={onDeleteClick}
            startIcon={<DeleteIcon fontSize="medium" />}
            size="small"
            variant="text"
            color="error">
            Удалить проект
          </Button>
        )}
        <Button
          type="submit"
          disabled={isSubmitting}
          size="small"
          variant="outlined"
          color="success"
          sx={{ backgroundColor: 'rgba(141, 212, 200, 0.15)' }}>
          Сохранить проект
        </Button>
      </Stack>
      <ConfirmDialog />
    </Stack>
  );
};
