/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import React, { useCallback, useContext, useMemo } from "react";
import { SetupItem, SetupRow, SetupTable, StyledSelect } from "./CaptionTable.style";
import { MenuItem, Stack } from "@mui/material";
import {
  StyledParametersButton
} from "../../../CalculationBasic/components/CalculationLegend/CalculationLegend.styles";
import { Divider } from "../../../../../../components/Divider";
import SwitchBtn from "../../../../../../components/SwitchBtn";
import { IPricesSwitch } from "../../Accomplishment.types";
import { getFormatDate } from "utils/formatDate";
import { ActList, TActStats } from "types";
import { ActListContext } from "pages/Calculations/components/CalculationСomplicated";
import { useLazyGetKS2V2Query, useLazyGetKS6V2Query } from "../../../../../../api/params";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { enqueueSnackbar } from "notistack";
import { KSResponse } from "../../../../../../api/params/params.types";
import { OrNull } from "../../../../../../api/references/estimates/estimates.types";
import { BadgeAct } from "../../Accomplishment.styles";

interface ICaptionTable {
  prices: 'curr' | 'base';
  disableCurr: boolean;
  changePrices: (price: IPricesSwitch) => void;
  priceSwitches: IPricesSwitch[];
  act: () => void;
  exportKS: (str: string) => void;
}

interface Stats{
  title?:string,
  color?:string
}

const statusesAct: Record<TActStats,OrNull<Stats>> = {
  NEW:null,
  PREPARED:{
    title:'Период изменен',
    color:'#FF9800'
  },
  CLOSED:{
    title:'Период закрыт',
    color:'#2E7D32'
  },
  COLLECTING:{
    title:'Получение факта',
    color:'#A14DE7'
  },
  REOPENED:{
    title:'Период изменен',
    color:'#FF9800'
  }
}

const Badge:React.FC<Stats> = (props)=>{
  if(!props) return null
  return (
    <BadgeAct color={props?.color||'white'}>{props?.title}</BadgeAct>
  )
}

export const formatDate = (act: ActList) => {
  return getFormatDate({ date: act.startDate||'' }) + ' — ' + getFormatDate({ date: act.endDate||'' });
};
const CaptionTable: React.FC<ICaptionTable> = ({ prices, disableCurr, changePrices, priceSwitches, act }) => {
  const { data, current, setCurrent } = useContext(ActListContext);
  const { calcID } = useParams();



  const [getKS2V2, { isFetching: fetchKS2V2 }] = useLazyGetKS2V2Query();
  const [getKS6V2, { isFetching: fetchKS6V2 }] = useLazyGetKS6V2Query();

  // const isFetching = useMemo(() => {
  //   return fetchKS2V2 || fetchKS6V2;
  // }, [fetchKS2V2, fetchKS6V2]);

  const download = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const key = e.currentTarget.dataset?.key;
      const getKS = key === 'ks-2' ? getKS2V2 : getKS6V2;
      if (calcID && current && key) {
        enqueueSnackbar('Ожидайте загрузку архива, около 2 минут. Не покидайте вкладку.', {
          autoHideDuration: 10000,
          variant: 'info',
        });
        getKS({ calcID: Number(calcID), actID: current.id })
          .then((response) => {
            const res = { data: response.data! as KSResponse };
            // if ('archiveBase64' in res.data) {
            // if (res?.error && 'data' in res.error) {
            enqueueSnackbar('Загрузка завершена', {
              autoHideDuration: 2000,
              variant: 'success',
            });
            let sliceSize = 1024;
            let byteCharacters = atob(res?.data?.archiveBase64 as string);
            let byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
              let slice = byteCharacters.slice(offset, offset + sliceSize);
              let byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }
              let byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }
            const blob = new Blob(byteArrays, { type: 'application/zip' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${key === 'ks-2' ? 'КС-2' : 'КС-6A'}.zip`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // }
            // }
          })
          .catch((er) => {
            console.log(er);
            enqueueSnackbar('При загрузке архива произошла ошибка, попробуйте позже', {
              autoHideDuration: 2000,
              variant: 'error',
            });
          });
      }
    },
    [calcID, current],
  );

  const renderAct = useMemo(() => {
    if (!current) return 'Выберите диапазон';
    return formatDate(current);
  }, [current]);
  // console.log(responseData);
  // useEffect(() => {
  //   return () => {
  //     if (isFetching) {
  //       enqueueSnackbar('Загрузка КС-2 отменена', {
  //         autoHideDuration: 2000,
  //         variant: 'error',
  //       });
  //     }
  //   };
  // }, [isFetching]);
  return (
    <SetupTable>
      <SetupRow>
        {!data?.actList?.length ? (
          <StyledParametersButton
            style={{ marginLeft: 20 }}
            onClick={act}
            fullWidth
            sx={{ fontSize: '14px' }}
            variant={'outlined'}>
            Создать новый акт
          </StyledParametersButton>
        ) : (
          <SetupItem hideBefore>
            Акт:
            <StyledSelect
              width={235}
              onClear={() => {}}
              nullableValues={[renderAct, 'Выберите диапазон']}
              SelectProps={{
                renderValue: () => renderAct,
                MenuProps: {
                  sx: {
                    maxHeight: 500,
                    width: 235,
                  },
                  placeholder: 'Выберите диапазон',
                },
                value: renderAct || 'Выберите диапазон',
                placeholder: 'Выберите диапазон',
              }}
              value={renderAct || 'Выберите диапазон'}
              placeholder={'Выберите диапазон'}
              fullWidth
              select>
              <Stack direction="row" px={1} py={1}>
                <StyledParametersButton onClick={act} fullWidth sx={{ fontSize: '14px' }} variant={'outlined'}>
                  Создать новый акт
                </StyledParametersButton>
              </Stack>
              <Divider />
              <MenuItem
                style={{ height: 0, overflow: 'hidden', padding: 0 }}
                selected
                hidden={true}
                value={'Выберите диапазон'}>
                Выберите диапазон
              </MenuItem>
              {data?.actList?.map((item) => {
                return (
                  <MenuItem
                    key={item.id}
                    onClick={() => {
                      if (current?.id !== item.id) {
                        setCurrent?.(item);
                      }
                    }}
                    selected={formatDate(item) === renderAct && item.id === current?.id}
                    value={formatDate(item)}>
                    {formatDate(item)}
                  </MenuItem>
                );
              })}
              {/*{idx !== data?.actList.length - 1 && <Divider />}*/}
            </StyledSelect>
          </SetupItem>
        )}

        <SetupItem>
          Экспорт:
          <StyledParametersButton
            data-key={'ks-2'}
            disabled={fetchKS2V2 || !current}
            onClick={download}
            fullWidth
            sx={{ fontSize: '14px' }}
            variant={'outlined'}>
            {fetchKS2V2 ? <CircularProgress size={20} /> : 'КС-2'}
          </StyledParametersButton>
          <StyledParametersButton
            data-key={'ks-6'}
            disabled={fetchKS6V2 || !current}
            onClick={download}
            fullWidth
            sx={{ fontSize: '14px' }}
            variant={'outlined'}>
            {fetchKS6V2 ? <CircularProgress size={20} /> : 'КС-6а'}
          </StyledParametersButton>
        </SetupItem>

        {/*<SetupItem>*/}
        {/*  Импорт:*/}
        {/*  <StyledParametersButton sx={{ minWidth: 32, fontSize: '14px', padding: '6px' }} variant={'outlined'}>*/}
        {/*    <AssignmentOutlinedIcon width={20} height={20} />*/}
        {/*  </StyledParametersButton>*/}
        {/*</SetupItem>*/}
        <SetupItem>
          Цены:
          <SwitchBtn disableCurr={disableCurr} value={prices} onClick={changePrices} options={priceSwitches} />
        </SetupItem>
        {/*<SetupItem>*/}
        {/*  <TableSetup />*/}
        {/*</SetupItem>*/}
      </SetupRow>

      {/* лейбл со статусом на таблице */}
      {/*<SetupRow>{current&&current.status && <Badge {...statusesAct[current.status||'NEW']}/>}</SetupRow>*/}

    </SetupTable>
  );
};

export default CaptionTable;

// {data?.estimateActs
//   ?.filter((g) => g.acts.length)
//   .map((g, idx) => {
//     return (
//       <React.Fragment key={'' + g.estimateName + g.estimateID}>
//         <StyledTooltip
//           PopperProps={{
//             disablePortal: false,
//           }}
//           title={g.estimateName}>
//           <Typography
//             style={{ cursor: 'default' }}
//             whiteSpace={'nowrap'}
//             textOverflow={'ellipsis'}
//             width={'100%'}
//             overflow={'hidden'}
//             color={({ palette: { primary } }) => primary.main}
//             p={1}>
//             {g.estimateName || 'Общие'}
//           </Typography>
//         </StyledTooltip>
//         {g?.acts?.map((item) => {
//           return (
//             <MenuItem
//               key={item.id}
//               onClick={() => {
//                 if (current?.id !== item.id) {
//                   setCurrent?.(item);
//                 }
//               }}
//               selected={formatDate(item) === renderAct && item.id === current?.id}
//               value={formatDate(item)}>
//               {formatDate(item)}
//             </MenuItem>
//           );
//         })}
//         {idx !== data?.estimateActs.length - 1 && <Divider />}
//       </React.Fragment>
//     );
//   })}
