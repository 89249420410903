export type TTab = 'indirect_costs' | 'value_changes';

export const popperSetup = {
  sx: {
    width: '96.8%',
    height: '96.8%',
    maxWidth: '96.8%',
    maxHeight: '96.8%',
    borderRadius: '16px',
  },
};
