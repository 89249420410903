import { TextField , Box, Drawer, Stack, Button,  Table, TableBody, IconButton , Typography} from '@mui/material'
import styled from 'styled-components'


export const StyledIconButton = styled(IconButton)`
  width: 24px;
  height: 24px;
  padding: 0px;
  margin-right: 26px;
`

export const StyledButton = styled(Button)`
  background: #F6F7FB;
  border-radius: 6px;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  padding: 4px 16px 4px 18px;
  margin-right: 12px;
`
 export const StyledTypographyForm = styled(Typography)`
 font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: #9AA2B0;
`


export const StyledTableContainer= styled(Box)`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  overflow-x: auto;
  background: #fff;
`
export const StyledTableHead = styled(Box)`
  display: grid;
  overflow: hidden;
  grid-template-columns: 100px minmax(206px, 1fr) repeat(4, minmax(105px, 206px)) 30px 12px;

  height: 100%;
  max-height: 60px;

  grid-auto-rows: 60px;
  background-color: #7890B2;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  letter-spacing: 0.15px;
  color: #FFFFFF;
`
export const StyledHeadTd = styled(Box)`
  display: flex;
  align-items: center;
  padding: 11px 8px;
  justify-content: center;
`


export const StyledTableBodyContainer = styled(Box)`
  height: 100%;
  overflow-x: auto;
  overflow-y: scroll;
  max-height: 100%;

  &::-webkit-scrollbar {
    box-shadow: inset 1px 0px 0px rgba(0, 36, 95, 0.1);
  }
`

export const StyledBoxScrollShow= styled(Box)`
&::-webkit-scrollbar {
  box-shadow: inset 1px 0px 0px rgba(0, 36, 95, 0.1);
}
`
